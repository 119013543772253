var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "relative mt-n4 mb-4 mx-n4",
          staticStyle: {
            height: "16px",
            overflow: "hidden",
            "border-radius": "4px 4px 0 0",
          },
        },
        [
          _vm.isLoading
            ? _c("v-progress-linear", {
                attrs: {
                  indeterminate: "",
                  absolute: "",
                  color: "secondary darken-2",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "12", md: "6" } }, [
            _c(
              "div",
              { staticClass: "my-2" },
              [
                _c(
                  "div",
                  { staticClass: "d-flex" },
                  [
                    _c(
                      "v-icon",
                      { attrs: { color: "primary lighten-3", size: "16" } },
                      [_vm._v("mdi-filter")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "text-body-2 primary--text text--lighten-2 pl-1",
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("intents.filterTypeLabel")) + " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                !_vm.isLoading
                  ? _c("ChipFilter", {
                      attrs: {
                        options: _vm.filterTypeItems,
                        disableNoAmount: "",
                      },
                      on: { onChange: _vm.changeFilterType },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "my-2" },
              [
                _c(
                  "div",
                  { staticClass: "d-flex" },
                  [
                    _c(
                      "v-icon",
                      { attrs: { color: "primary lighten-3", size: "16" } },
                      [_vm._v("mdi-filter")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "text-body-2 primary--text text--lighten-2 pl-1",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("intents.filterStatusLabel")) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                !_vm.isLoading
                  ? _c("ChipFilter", {
                      attrs: { options: _vm.filterStatusItems },
                      on: { onChange: _vm.changeFilterStatus },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c("div", { staticClass: "my-2 hidden-md-and-up" }, [
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c(
                    "v-icon",
                    { attrs: { color: "primary lighten-3", size: "16" } },
                    [_vm._v("mdi-filter")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-body-2 primary--text text--lighten-2 pl-1",
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.useAISearch
                              ? _vm.$t("intents.filterSearchLabelAI")
                              : _vm.$t("intents.filterSearchLabel")
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex gap-6 items-center py-1 font-weight-regular",
                  staticStyle: { width: "400px", height: "48px" },
                },
                [
                  _c("v-text-field", {
                    attrs: {
                      placeholder: _vm.useAISearch
                        ? _vm.$t("intents.filterSearchPlaceholderAI")
                        : _vm.$t("intents.filterSearchPlaceholder"),
                      "single-line": "",
                      outlined: "",
                      dense: "",
                      "prepend-inner-icon": _vm.searchIcon,
                      "hide-details": "",
                      readonly: _vm.isLoading,
                    },
                    on: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.handleSearchKeyDown.apply(null, arguments)
                      },
                      "click:prepend-inner": _vm.handleSearchbarIconClick,
                    },
                    model: {
                      value: _vm.searchValue,
                      callback: function ($$v) {
                        _vm.searchValue = $$v
                      },
                      expression: "searchValue",
                    },
                  }),
                  _vm.canUseAISearch
                    ? _c("v-switch", {
                        attrs: {
                          label: _vm.$t("intents.aiSearch"),
                          inset: "",
                          color: "rgb(87,0,208)",
                        },
                        model: {
                          value: _vm.useAISearch,
                          callback: function ($$v) {
                            _vm.useAISearch = $$v
                          },
                          expression: "useAISearch",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]),
          _vm.allTags?.length
            ? _c(
                "v-col",
                {
                  staticClass: "d-flex flex-col",
                  class: { "items-end": _vm.isSmallSize },
                  attrs: { cols: "12", md: "6" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex my-2" },
                    [
                      _c(
                        "v-icon",
                        { attrs: { color: "primary lighten-3", size: "16" } },
                        [_vm._v("mdi-filter")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "text-body-2 primary--text text--lighten-2 pl-1",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("intents.tags.filterLabel")) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-chip-group",
                    {
                      key: `${_vm.showAllTags}-${_vm.activeTagIndexes.join(
                        "-"
                      )}`,
                      staticClass: "mb-4 justify-group-end",
                      attrs: {
                        column: "",
                        multiple: "",
                        "active-class": "primary lighten-2",
                      },
                      model: {
                        value: _vm.activeTagIndexes,
                        callback: function ($$v) {
                          _vm.activeTagIndexes = $$v
                        },
                        expression: "activeTagIndexes",
                      },
                    },
                    [
                      _vm._l(
                        _vm.showAllTags ? _vm.allTags : _vm.popularTags,
                        function (tag) {
                          return _c(
                            "v-chip",
                            {
                              key: tag,
                              attrs: {
                                color: "primary lighten-3",
                                outlined: !_vm.activeTags.includes(tag),
                              },
                            },
                            [
                              _c("span", [_vm._v(_vm._s(tag))]),
                              _c(
                                "span",
                                {
                                  staticClass: "pl-2",
                                  staticStyle: { opacity: "0.6" },
                                },
                                [_vm._v("(" + _vm._s(_vm.tagCounts[tag]) + ")")]
                              ),
                            ]
                          )
                        }
                      ),
                      _vm.showAllTags
                        ? _c(
                            "v-chip",
                            {
                              key: null,
                              attrs: {
                                color: "primary lighten-3",
                                outlined: !_vm.activeTags.includes(null),
                              },
                            },
                            [
                              _c("em", [
                                _vm._v(_vm._s(_vm.$t("intents.tags.untagged"))),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      !_vm.showAllTags
                        ? _c(
                            "v-chip",
                            {
                              staticClass:
                                "text-body-2 primary--text text--lighten-2",
                              staticStyle: { width: "fit-content" },
                              attrs: { outlined: "" },
                              on: {
                                "~click": function ($event) {
                                  return _vm.enableAllTags.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "v-icon",
                                { attrs: { color: "primary lighten-3" } },
                                [_vm._v(" mdi-chevron-down ")]
                              ),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("intents.tags.showAll")) +
                                  " "
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      !_vm.isLoading && _vm.allIntents && _vm.tableOptions
        ? _c("v-data-table", {
            attrs: {
              items: _vm.allIntentsFiltered,
              headers: _vm.tableHeaders,
              options: _vm.tableOptions,
              page: _vm.page,
              "item-class": (item) =>
                _vm.selectedIntent && item.intent === _vm.selectedIntent.intent
                  ? "item item-active"
                  : "item",
              "items-per-page": 20,
              "footer-props": {
                "items-per-page-options": [20, 50, -1],
              },
              "hide-default-footer":
                _vm.allIntentsFiltered && _vm.allIntentsFiltered.length < 20,
              "no-data-text": _vm.$t("intents.noDataFound"),
              "custom-sort": _vm.customSort,
            },
            on: {
              "click:row": _vm.selectIntent,
              "update:options": _vm.setTableOptions,
              "update:page": _vm.setCurrentPage,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: `header.status`,
                  fn: function () {
                    return [
                      _vm.filterChannelItems &&
                      _vm.filterChannelItems.length > 1
                        ? _c(
                            "div",
                            { staticClass: "py-2" },
                            [
                              _c("v-select", {
                                staticClass: "d-inline-block text-body-2",
                                attrs: {
                                  outlined: "",
                                  label: _vm.$t("intents.channelDropdownLabel"),
                                  items: _vm.filterChannelItems,
                                  value: _vm.selectedFilterChannel,
                                  "item-text": "displayName",
                                  "item-value": "channelId",
                                  dense: "",
                                  "hide-details": "",
                                },
                                on: { change: _vm.changeFilterChannel },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: `header.displayName`,
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "search-container items-center gap-2" },
                        [
                          _c("v-data-table-header", {
                            staticClass: "pr-4",
                            attrs: {
                              headers: [
                                {
                                  text: _vm.$t("intents.headers.name"),
                                  sortable: false,
                                },
                              ],
                            },
                          }),
                          _c(
                            "div",
                            {
                              staticClass:
                                "inner-search-container justify-center items-center gap-6 grow hidden-sm-and-down",
                            },
                            [
                              _vm.canUseAISearch
                                ? _c("v-switch", {
                                    attrs: {
                                      label: _vm.$t("intents.aiSearch"),
                                      inset: "",
                                      color: "rgb(87,0,208)",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                      },
                                    },
                                    model: {
                                      value: _vm.useAISearch,
                                      callback: function ($$v) {
                                        _vm.useAISearch = $$v
                                      },
                                      expression: "useAISearch",
                                    },
                                  })
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  staticClass: "py-1 font-weight-regular",
                                  staticStyle: {
                                    width: "320px",
                                    height: "48px",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                    },
                                  },
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      placeholder: _vm.useAISearch
                                        ? _vm.$t(
                                            "intents.filterSearchPlaceholderAI"
                                          )
                                        : _vm.$t(
                                            "intents.filterSearchPlaceholder"
                                          ),
                                      "single-line": "",
                                      outlined: "",
                                      dense: "",
                                      "prepend-inner-icon": _vm.searchIcon,
                                      "hide-details": "",
                                      readonly: _vm.isLoading,
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                      },
                                      "click:prepend-inner":
                                        _vm.handleSearchbarIconClick,
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.handleSearchKeyDown.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.searchValue,
                                      callback: function ($$v) {
                                        _vm.searchValue = $$v
                                      },
                                      expression: "searchValue",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: `item.type`,
                  fn: function ({ item }) {
                    return [
                      _c(
                        "v-icon",
                        {
                          staticClass: "mr-2",
                          attrs: {
                            size: "20",
                            color:
                              item.type && _vm.typeIcons[item.type].iconColor,
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.intentIcon(item)) + " ")]
                      ),
                    ]
                  },
                },
                {
                  key: `item.displayName`,
                  fn: function ({ item }) {
                    return [
                      _c(
                        "div",
                        { staticClass: "py-2", attrs: { id: item.intent } },
                        [
                          item.type === _vm.typeTypes.SMALLTALK
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "font-weight-bold primary--text text--lighten-3 d-inline-block mr-2",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("intent.smalltalk.label")) +
                                      ": "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          item.type === _vm.typeTypes.AI_SUGGESTION
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "font-weight-bold primary--text text--lighten-3 d-inline-block mr-2",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("intent.aiSuggestion.label")
                                      ) +
                                      ": "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          item.type === _vm.typeTypes.AI_TEMPLATE
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "font-weight-bold primary--text text--lighten-3 d-inline-block mr-2",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("intent.aiTemplate.label")
                                      ) +
                                      ": "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c("span", { staticClass: "text-body-1" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.displayName(
                                    item.intent,
                                    _vm.user.language
                                  )
                                ) +
                                " "
                            ),
                          ]),
                          item.inReview ? _c("v-icon") : _vm._e(),
                          _vm.$vuetify.breakpoint.lgAndUp
                            ? _c(
                                "div",
                                { staticClass: "text-body-2 grey--text" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.description(
                                          item.intent,
                                          _vm.user.language
                                        ) &&
                                          _vm.description(
                                            item.intent,
                                            _vm.user.language
                                          ).length > 0
                                          ? _vm.description(
                                              item.intent,
                                              _vm.user.language
                                            )
                                          : ""
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          item.tags?.length
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex gap-1 flex-wrap pt-2 pb-1",
                                },
                                _vm._l(item.tags, function (tag) {
                                  return _c(
                                    "v-chip",
                                    {
                                      key: tag,
                                      attrs: {
                                        color: "primary lighten-3",
                                        outlined: !_vm.activeTags.includes(tag),
                                        small: "",
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(tag) + " ")]
                                  )
                                }),
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: `item.relevanceFrequent`,
                  fn: function ({ item }) {
                    return [
                      item.type !== _vm.typeTypes.SMALLTALK &&
                      item.status !== _vm.statusTypes.IN_REVIEW &&
                      item.suggested !== true
                        ? _c("IntentsRelevanceBar", { attrs: { intent: item } })
                        : _vm._e(),
                    ]
                  },
                },
                {
                  key: `item.status`,
                  fn: function ({ item }) {
                    return [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-space-between" },
                        [
                          item.type !== _vm.typeTypes.AI_SUGGESTION
                            ? _c(
                                "div",
                                { staticClass: "d-flex align-center" },
                                [
                                  item.status
                                    ? [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "mr-2",
                                            attrs: {
                                              size: "20",
                                              color:
                                                _vm.statusIcons[item.status]
                                                  .iconColor,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.statusIcons[item.status]
                                                    .icon
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "text-body-2" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    `intentStatus.${item.status}.label`
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    : [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-body-2 primary--text",
                                          },
                                          [_vm._v("...")]
                                        ),
                                      ],
                                ],
                                2
                              )
                            : _c("div"),
                          _c(
                            "div",
                            [
                              item.type !== _vm.typeTypes.AI_SUGGESTION
                                ? _c("BtnSquare", {
                                    staticClass: "ml-4",
                                    attrs: {
                                      icon: "edit",
                                      "data-cy": "edit-intent-btn",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.goToEdit(item.intent)
                                      },
                                    },
                                  })
                                : _vm._e(),
                              item.type === _vm.typeTypes.AI_SUGGESTION &&
                              _vm.isCreateBtnShown
                                ? _c("BtnSquare", {
                                    staticClass: "ml-4",
                                    attrs: {
                                      icon: "mdi-plus-box",
                                      colorIcon: "secondary darken-2",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openCreateDialog(item)
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          })
        : _vm._e(),
      _c("IntentsDialogSuggestion", {
        attrs: {
          isOpen: _vm.isSuggestionDialogOpen,
          intent: _vm.suggestedIntent,
        },
        on: {
          onClose: function ($event) {
            _vm.isSuggestionDialogOpen = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }