var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { staticStyle: { "max-height": "100vh", height: "100vh" } },
    [
      _c("HubMenu"),
      _c("Appbar"),
      _c("HubEditorFrame", {
        ref: "hubeditor",
        attrs: {
          title: _vm.$t("botSettings.privacy.title"),
          loading: _vm.isLoading,
          draft: _vm.isChanged,
          saveIcon: "cloud_upload",
          back: "/settings",
          "sidebar-items": [{ title: "", icon: "info", name: "info" }],
        },
        on: { onReset: _vm.reset, onSave: _vm.save },
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function ({ isLoading }) {
                return [
                  _c(
                    "v-container",
                    { staticClass: "white pa-6", attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", lg: "6", xl: "4" } },
                            [
                              _c("h2", { staticClass: "text-h6" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("botSettings.keywordFeature.title")
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("div", { staticClass: "text-body-2" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "botSettings.keywordFeature.description"
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c("v-switch", {
                                attrs: {
                                  label: _vm.$t(
                                    "botSettings.keywordFeature.labelActive"
                                  ),
                                  color: "green",
                                  inset: "",
                                  dense: "",
                                  disabled: isLoading,
                                },
                                model: {
                                  value: _vm.isKeywordsActive,
                                  callback: function ($$v) {
                                    _vm.isKeywordsActive = $$v
                                  },
                                  expression: "isKeywordsActive",
                                },
                              }),
                              _c("InfoBox", {
                                staticClass: "my-4",
                                attrs: {
                                  text: _vm.$t(
                                    "botSettings.keywordFeature.infoBox"
                                  ),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-container",
                    { staticClass: "white pa-6", attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", lg: "6", xl: "4" } },
                            [
                              _c("h2", { staticClass: "text-h6" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "botSettings.privacy.deleteConversations.title"
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("div", { staticClass: "text-body-2" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "botSettings.privacy.deleteConversations.description"
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  label: _vm.$t(
                                    "botSettings.privacy.deleteConversations.selectLabel"
                                  ),
                                  items: _vm.optionsConversations,
                                  "item-text": "title",
                                  "item-value": "value",
                                  "prepend-inner-icon":
                                    "mdi-delete-clock-outline",
                                  disabled: isLoading,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.changeConversationsSettings()
                                  },
                                },
                                model: {
                                  value: _vm.selectedConversationsOption,
                                  callback: function ($$v) {
                                    _vm.selectedConversationsOption = $$v
                                  },
                                  expression: "selectedConversationsOption",
                                },
                              }),
                              _vm.conversationsOptionIsSmaller
                                ? _c("InfoBox", {
                                    staticClass: "mb-6",
                                    attrs: {
                                      iconColor: "warning",
                                      text: _vm.$t(
                                        "botSettings.privacy.deleteConversations.warning",
                                        {
                                          x: _vm.selectedConversationsOption,
                                        }
                                      ),
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.highPrivacy || _vm.isAdmin
                    ? [
                        _c("v-divider"),
                        _c(
                          "v-container",
                          { staticClass: "white pa-6", attrs: { fluid: "" } },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", lg: "6", xl: "4" } },
                                  [
                                    _c("h2", { staticClass: "text-h6" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "botSettings.highPrivacy.title"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                    _c("div", { staticClass: "text-body-2" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "botSettings.highPrivacy.description"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "6" } },
                                  [
                                    _c("v-switch", {
                                      attrs: {
                                        "input-value":
                                          _vm.isHighPrivacyActiveLocal,
                                        label: _vm.$t(
                                          "botSettings.highPrivacy.switchLabel"
                                        ),
                                        color: "green",
                                        inset: "",
                                        dense: "",
                                        readonly: !_vm.isAdmin,
                                        disabled: isLoading,
                                      },
                                      on: {
                                        change: function ($event) {
                                          _vm.isHighPrivacyActiveLocal = $event
                                        },
                                      },
                                    }),
                                    _vm.isAdmin
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-body-2 admin--text",
                                          },
                                          [
                                            _vm._v(
                                              " (Änderung nur als Admin möglich) "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.isAdmin && _vm.highPrivacy
                              ? _c(
                                  "v-card",
                                  {
                                    staticClass: "mt-6 pa-4",
                                    attrs: { outlined: "" },
                                  },
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c("v-col", [
                                          _c(
                                            "div",
                                            { staticClass: "text-body-2" },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "admin--text" },
                                                [_vm._v("vocabId:")]
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.highPrivacy.vocabId
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "text-body-2" },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "admin--text" },
                                                [_vm._v("language:")]
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.highPrivacy.language
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ]
              },
            },
            {
              key: `sidebar.info`,
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "pa-4" },
                    [
                      _c("HubBoxSimple", {
                        staticClass: "mb-4",
                        attrs: {
                          title: _vm.$t(
                            "botSettings.privacy.deleteConversations.title"
                          ),
                          icon: "mdi-delete-clock-outline",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "content",
                            fn: function () {
                              return [
                                _c("p", { staticClass: "text-body-1" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "botSettings.privacy.deleteConversations.infoDescription"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "toolbar",
              fn: function () {
                return [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({
                            on: onTooltip,
                            attrs: attrsTooltip,
                          }) {
                            return [
                              _c(
                                "span",
                                _vm._g(
                                  _vm._b({}, "span", attrsTooltip, false),
                                  onTooltip
                                ),
                                [
                                  _c("BtnSquare", {
                                    attrs: { icon: "info_outline" },
                                    on: {
                                      click: function ($event) {
                                        _vm.isSidebarOpen = !_vm.isSidebarOpen
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.$t("common.moreInfo")))])]
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: `saveDialog.content`,
              fn: function () {
                return [
                  _vm.conversationsOptionIsSmaller
                    ? _c("InfoBox", {
                        staticClass: "mt-6 mb-6",
                        attrs: {
                          iconColor: "warning",
                          text: _vm.$t(
                            "botSettings.privacy.deleteConversations.warning",
                            {
                              x: _vm.selectedConversationsOption,
                            }
                          ),
                        },
                      })
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
        model: {
          value: _vm.isSidebarOpen,
          callback: function ($$v) {
            _vm.isSidebarOpen = $$v
          },
          expression: "isSidebarOpen",
        },
      }),
      _c(
        "HubDialog",
        {
          attrs: {
            "primary-icon": "delete",
            "primary-label": _vm.$t("editor.common.discard"),
            modal: "",
            title: _vm.$t("editor.widget.unsaved.title"),
          },
          on: { onSecondary: _vm.lostCancel, onPrimary: _vm.lostOk },
          model: {
            value: _vm.isLostDialogOpen,
            callback: function ($$v) {
              _vm.isLostDialogOpen = $$v
            },
            expression: "isLostDialogOpen",
          },
        },
        [
          _vm._v(
            " " + _vm._s(_vm.$t("editor.widget.unsaved.description")) + " "
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }