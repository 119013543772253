var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-fade-transition",
        [
          _vm.isBotSelectShown
            ? _c(
                "v-app",
                [
                  _c("AppBotSelect", {
                    on: {
                      onBotChange: function ($event) {
                        return _vm.loadBot($event)
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-fade-transition",
        [
          _vm.isLoading
            ? _c("v-app", [_c("HubLoader", { attrs: { fixed: "" } })], 1)
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }