var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-navigation-drawer",
    {
      attrs: { app: "" },
      scopedSlots: _vm._u([
        {
          key: "append",
          fn: function () {
            return [
              _vm.integrationShown
                ? _c(
                    "div",
                    { staticStyle: { "padding-right": "1px" } },
                    [
                      _c("v-divider", { staticClass: "grey lighten-2" }),
                      _c(
                        "v-expansion-panels",
                        { attrs: { flat: "" } },
                        [
                          _c(
                            "v-expansion-panel",
                            [
                              _c(
                                "v-expansion-panel-header",
                                [
                                  _c("v-card-title", { staticClass: "pa-0" }, [
                                    _c(
                                      "h5",
                                      {
                                        staticClass:
                                          "text-primary text-lighten-2",
                                        staticStyle: {
                                          "font-weight": "normal",
                                        },
                                        attrs: { color: "primary" },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "preview.teaser.integration.title"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-expansion-panel-content",
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-body-2 mb-4 primary--text text--lighten-2",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("preview.teaser.description")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mb-4",
                                      attrs: {
                                        color: "secondary darken-2",
                                        depressed: "",
                                        block: "",
                                        to: "/wt/teaser",
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("widgetTeaser.teaser.title")
                                          ) +
                                          " "
                                      ),
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { color: "white", right: "" },
                                        },
                                        [_vm._v(" mdi-message-text-outline ")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("div", {
                                    staticClass:
                                      "text-body-2 mb-4 primary--text text--lighten-2",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$t("preview.teaser.descriptionCode")
                                      ),
                                    },
                                  }),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mb-4",
                                      attrs: {
                                        color: "secondary darken-2",
                                        outlined: "",
                                        small: "",
                                        block: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.hideTeaser()
                                          _vm.teaserCode = true
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("preview.teaser.generate")
                                          ) +
                                          " "
                                      ),
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            color: "secondary darken-2",
                                            right: "",
                                          },
                                        },
                                        [_vm._v(" integration_instructions ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c("HubMenuProfile", {
                attrs: { myProfileHidden: !_vm.isTester, preview: "" },
              }),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.drawer,
        callback: function ($$v) {
          _vm.drawer = $$v
        },
        expression: "drawer",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "text-center",
          staticStyle: { height: "64px", "padding-top": "4px" },
        },
        [
          _c("img", {
            attrs: { src: require("@/assets/moin-logo.svg"), height: "50px" },
          }),
        ]
      ),
      _c("v-divider"),
      _vm.avatarUrl
        ? _c("v-img", {
            staticClass: "avatar ml-auto mr-auto mt-6",
            attrs: { width: "96px", height: "96px", src: _vm.avatarUrl },
          })
        : _vm._e(),
      _c(
        "v-list",
        { staticClass: "pt-0" },
        [
          _vm.loggedIn
            ? _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "pt-2" },
                    [
                      _c("v-list-item-title", { staticClass: "text-center" }, [
                        _vm._v(" " + _vm._s(_vm.avatarName) + " "),
                      ]),
                      _c(
                        "v-list-item-subtitle",
                        { staticClass: "text-center" },
                        [_vm._v(" " + _vm._s(_vm.avatarTitle) + " ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.loggedIn && _vm.channels && _vm.channels.length > 1
            ? _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "pt-2" },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.channels,
                          value: _vm.selectedChannelId,
                          "item-text": "displayName",
                          "item-value": "channelId",
                        },
                        on: { change: _vm.changeChannel },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("v-divider", { staticClass: "mt-6 mb-4" }),
          _vm.teaserCode
            ? _c(
                "HubDialog",
                {
                  attrs: {
                    hideSecondary: "",
                    modal: "",
                    title: _vm.$t("preview.teaser.integration.title"),
                    primaryLabel: _vm.$t("common.close"),
                    primaryIcon: "close",
                  },
                  model: {
                    value: _vm.teaserCode,
                    callback: function ($$v) {
                      _vm.teaserCode = $$v
                    },
                    expression: "teaserCode",
                  },
                },
                [
                  _c("v-card-text", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("preview.teaser.integration.description")
                        ) +
                        " "
                    ),
                  ]),
                  _c(
                    "ssh-pre",
                    {
                      attrs: { language: "js", "copy-button": "" },
                      on: {
                        copied: function ($event) {
                          _vm.codeCopied = "check"
                        },
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "copy-button",
                            fn: function () {
                              return [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v(_vm._s(_vm.codeCopied)),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        838676579
                      ),
                    },
                    [_vm._v(" " + _vm._s(_vm.code) + " ")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "h3",
            {
              staticClass: "pa-4 mt-4 text-primary text-lighten-2",
              staticStyle: { "font-weight": "normal" },
              attrs: { color: "primary" },
            },
            [_vm._v(" " + _vm._s(_vm.$t("preview.teaser.title")) + " ")]
          ),
          _c(
            "v-list-item",
            [
              _c("v-textarea", {
                attrs: {
                  rows: "2",
                  outlined: "",
                  label: _vm.$t("preview.teaser.speech"),
                  value: _vm.teaser.message,
                },
                on: { input: _vm.updateTeaserMessage },
              }),
            ],
            1
          ),
          _vm._l(_vm.teaser.quickActions, function (answer, index) {
            return _c(
              "v-list-item",
              { key: answer.intent },
              [
                _c(
                  "v-list-item-content",
                  [
                    _c("v-text-field", {
                      staticClass: "mb-4",
                      attrs: {
                        dense: "",
                        "append-outer-icon":
                          _vm.loggedIn && _vm.botAvailable
                            ? "remove_circle_outline"
                            : "",
                        "hide-details": "",
                        outlined: "",
                        label: `Action Button ${index + 1}`,
                        value: answer.text,
                      },
                      on: {
                        "click:append-outer": function ($event) {
                          return _vm.removeTeaserButton(answer.intent)
                        },
                        input: (text) =>
                          _vm.updateQuickText(answer.intent, text),
                      },
                    }),
                    _vm.loggedIn && _vm.botAvailable
                      ? _c(
                          "span",
                          { staticClass: "text-caption mt-n3" },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "ml-1",
                                attrs: { small: "", left: "" },
                              },
                              [_vm._v("east")]
                            ),
                            _vm._v(
                              " " + _vm._s(_vm.displayName(answer.intent)) + " "
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          !_vm.startButton && _vm.loggedIn
            ? _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c(
                        "v-btn",
                        { attrs: { text: "" }, on: { click: _vm.addStandard } },
                        [
                          _c(
                            "v-icon",
                            {
                              staticStyle: { "text-transform": "none" },
                              attrs: { color: "grey darken-2", left: "" },
                            },
                            [_vm._v(" add_circle_outline ")]
                          ),
                          _vm._v(" Standard-Button "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }