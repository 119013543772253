var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-fade-transition", [
    _c(
      "div",
      { staticClass: "moin-fresh fill-height" },
      [
        _c(
          "v-list-item-title",
          {
            staticClass: "primary--text text--lighten-2 font-weight-bold",
            staticStyle: { "padding-left": "64px" },
          },
          [_vm._v(" " + _vm._s(_vm.$t("welcome.expertTips")) + " ")]
        ),
        _c(
          "v-carousel",
          {
            attrs: {
              height: _vm.$vuetify.breakpoint.xs ? 200 : 110,
              "hide-delimiter-background": "",
              "hide-delimiters": "",
            },
            scopedSlots: _vm._u([
              {
                key: "prev",
                fn: function ({ on, attrs }) {
                  return [
                    _c(
                      "v-btn",
                      _vm._g(
                        _vm._b(
                          {
                            attrs: {
                              text: "",
                              color: "primary",
                              width: "36",
                              "min-width": "0",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.trackIt("prev-tip")
                              },
                            },
                          },
                          "v-btn",
                          attrs,
                          false
                        ),
                        on
                      ),
                      [_c("v-icon", [_vm._v("chevron_left")])],
                      1
                    ),
                  ]
                },
              },
              {
                key: "next",
                fn: function ({ on, attrs }) {
                  return [
                    _c(
                      "v-btn",
                      _vm._g(
                        _vm._b(
                          {
                            attrs: {
                              text: "",
                              color: "primary",
                              width: "36",
                              "min-width": "0",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.trackIt("next-tip")
                              },
                            },
                          },
                          "v-btn",
                          attrs,
                          false
                        ),
                        on
                      ),
                      [_c("v-icon", [_vm._v("chevron_right")])],
                      1
                    ),
                  ]
                },
              },
            ]),
          },
          _vm._l(_vm.bestPractices, function (current, i) {
            return _c(
              "v-carousel-item",
              { key: i },
              [
                _c(
                  "v-card",
                  { staticClass: "fill-height px-12", attrs: { flat: "" } },
                  [
                    _c(
                      "v-card-text",
                      {
                        staticClass: "text-subtitle-1 primary--text py-1",
                        staticStyle: { "max-width": "450px" },
                      },
                      [_vm._v(" " + _vm._s(current.tipBody) + " ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }