var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "intent-statistic" },
    [
      _c(
        "h2",
        { staticClass: "text-h6 primary--text mb-3" },
        [
          _vm.icon
            ? _c(
                "v-icon",
                {
                  staticClass: "mr-3",
                  attrs: { left: "", color: "primary lighten-3" },
                },
                [_vm._v(" " + _vm._s(_vm.icon) + " ")]
              )
            : _vm._e(),
          _c("span", { domProps: { innerHTML: _vm._s(_vm.title) } }),
          _vm.currentChannels.length > 1
            ? _c(
                "span",
                { staticClass: "primary--text text--lighten-3 text-body-2" },
                [_vm._v(" (" + _vm._s(_vm.channelName) + ") ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pb-4" },
        [
          _c("small", {
            staticClass: "mb-3 text-body-2 d-block",
            domProps: { innerHTML: _vm._s(_vm.progressTitle) },
          }),
          _vm._t("progressBar"),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "chart" },
        [
          _c("h3", { staticClass: "text-body-2" }, [
            _vm._v(_vm._s(_vm.chartTitle)),
          ]),
          _c("p", { staticClass: "primary--text text--lighten-3" }, [
            _c("small", [_vm._v(_vm._s(_vm.chartSubline))]),
          ]),
          !_vm.loading
            ? _vm._t("chart")
            : _c(
                "v-card",
                {
                  staticClass: "text-center",
                  attrs: { "min-height": "140px", flat: "" },
                },
                [
                  _c("v-progress-circular", {
                    attrs: {
                      size: 24,
                      width: 2,
                      indeterminate: "",
                      color: "secondary darken-2",
                    },
                  }),
                ],
                1
              ),
        ],
        2
      ),
      false
        ? _c(
            "v-alert",
            {
              attrs: {
                icon: "mdi-lightbulb",
                "close-text": "Close Alert",
                dismissible: "",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "close",
                    fn: function ({ toggle }) {
                      return [
                        _c("v-icon", { on: { click: toggle } }, [
                          _vm._v("mdi-close"),
                        ]),
                      ]
                    },
                  },
                ],
                null,
                false,
                4220021982
              ),
            },
            [
              _c("small", { staticClass: "primary--text text--lighten-3" }, [
                _vm._v(
                  " Nutze diese Ansicht um besser einzuschätzen, wie die Bewertungen des Themas sich in letzter Zeit entwickelt haben "
                ),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }