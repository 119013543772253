var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-btn",
    _vm._g(
      {
        staticClass: "pa-0",
        class: {
          "moin-admin-box":
            _vm.opts.colorBtn === "admin" || _vm.opts.preset === "admin",
          [`${_vm.opts.preset}-preset`]: !!_vm.opts.preset,
          "px-2": !!_vm.opts.preset,

          // Depending on if you use a present, with icon and/or text, in normal size
          "pr-4":
            !!_vm.opts.preset &&
            !!_vm.$slots.default &&
            _vm.opts.icon &&
            !_vm.opts.iconFirst &&
            !_vm.opts.small,
          "pl-4":
            !!_vm.opts.preset &&
            !!_vm.$slots.default &&
            _vm.opts.icon &&
            _vm.opts.iconFirst &&
            !_vm.opts.small,
        },
        style: _vm.opts.small ? "min-width: 24px" : "min-width: 36px",
        attrs: {
          text: _vm.opts.text,
          disabled: _vm.opts.disabled,
          to: _vm.opts.to,
          append: _vm.opts.append,
          exact: _vm.opts.exact,
          color: _vm.opts.colorBtn,
          small: _vm.opts.small,
          outlined: _vm.opts.outlined,
          tile: _vm.opts.tile,
        },
      },
      _vm.$listeners
    ),
    [
      _vm.opts.icon && _vm.opts.iconFirst
        ? _c(
            "v-icon",
            {
              attrs: {
                color: _vm.opts.colorIcon,
                small: _vm.opts.small,
                left: _vm.opts.withSlot,
              },
            },
            [_vm._v(" " + _vm._s(_vm.opts.icon) + " ")]
          )
        : _vm._e(),
      _vm.opts.withSlot ? _vm._t("default") : _vm._e(),
      _vm.opts.icon && !_vm.opts.iconFirst
        ? _c(
            "v-icon",
            {
              attrs: {
                color: _vm.opts.colorIcon,
                small: _vm.opts.small,
                right: _vm.opts.withSlot,
              },
            },
            [_vm._v(" " + _vm._s(_vm.opts.icon) + " ")]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }