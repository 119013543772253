var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.notification
    ? _c(
        "div",
        {
          staticClass:
            "d-flex rounded pa-2 justify-space-between align-center w-full",
          class: [`notification--${_vm.notification.styleType}`],
          staticStyle: { height: "52px" },
        },
        [
          _c(
            "div",
            { staticClass: "overflow-hidden d-flex align-center" },
            [
              _vm.icon
                ? _c(
                    "v-icon",
                    { staticClass: "mr-2", attrs: { color: "primary" } },
                    [_vm._v(" " + _vm._s(_vm.icon) + " ")]
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "text-body-2 primary--text",
                  staticStyle: { "line-height": "1.1" },
                },
                [
                  _vm.notification.messageType
                    ? _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                `notifications.${_vm.notification.messageType}.message`
                              )
                            ) +
                            " "
                        ),
                      ])
                    : _c("span", [
                        _vm._v(
                          " " + _vm._s(_vm.notification.messageString) + " "
                        ),
                      ]),
                ]
              ),
            ],
            1
          ),
          _vm.notification.action || _vm.notification.actionSecondary
            ? _c(
                "div",
                [
                  _vm.notification.actionSecondary
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: {
                            outlined: "",
                            color: "primary",
                            depressed: "",
                          },
                          on: { click: _vm.notification.actionSecondary },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  `notifications.${_vm.notification.messageType}.actionSecondaryTitle`
                                )
                              ) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", depressed: "" },
                      on: { click: _vm.notification.action },
                    },
                    [
                      _vm.notification.messageType
                        ? _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    `notifications.${_vm.notification.messageType}.actionTitle`
                                  )
                                ) +
                                " "
                            ),
                          ])
                        : _c("span", [_vm._v(_vm._s(_vm.$t("common.ok")))]),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }