var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c("HubMenu"),
      _c("Appbar"),
      _c("HubContent", {
        attrs: { "is-sidebar-open": _vm.isSidebarOpen },
        scopedSlots: _vm._u(
          [
            {
              key: "navbar",
              fn: function () {
                return [
                  _c("HubContentNavbar", {
                    attrs: { title: _vm.$t("ai.title") },
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "content",
              fn: function () {
                return [
                  _c(
                    "v-container",
                    { staticClass: "pa-6" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              attrs: { cols: "12", lg: "8", "offset-lg": "2" },
                            },
                            [
                              _c("AiFeedback", {
                                attrs: { text: _vm.text },
                                on: {
                                  onAiFetched: _vm.updateSidebar,
                                  onAiFetching: _vm.loading,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
            _vm.isAdmin
              ? {
                  key: "sidebar",
                  fn: function () {
                    return [
                      _c(
                        "HubContentSidebarSimple",
                        {
                          attrs: {
                            isOpen: _vm.isSidebarOpen,
                            title: "K2 Index",
                            icon: "info",
                          },
                          on: {
                            onClose: function ($event) {
                              return _vm.closeSidebar()
                            },
                          },
                        },
                        _vm._l(_vm.k2Intents, function (intent, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              class: { "ai-loading": _vm.isLoading },
                            },
                            [
                              _c(
                                "v-card",
                                { staticClass: "pa-2" },
                                [
                                  _c("strong", [
                                    _vm._v(_vm._s(intent.displayName)),
                                  ]),
                                  _vm._v(" (" + _vm._s(intent.intent) + ") "),
                                  _c("br"),
                                  _vm._l(
                                    intent.k2names,
                                    function (name, index) {
                                      return _c("span", { key: index }, [
                                        _vm._v(" " + _vm._s(name)),
                                        index + 1 < intent.k2names.length
                                          ? _c("span", [_vm._v(", ")])
                                          : _vm._e(),
                                      ])
                                    }
                                  ),
                                  _c("br"),
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.formatPercentPrecision(intent.score)
                                      )
                                    ),
                                  ]),
                                ],
                                2
                              ),
                              _c("v-divider"),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }