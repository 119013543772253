import { set } from 'vue';

const state = {
  isMenuOpen: false,
  snackbarStack: [],
};

const getters = {
  isMenuOpen: (state) => state.isMenuOpen,
  isTouchDevice: () => {
    return matchMedia('(hover: none)').matches;
  },
  /**
   * A function that will give you alerts for your desired scopes/namespace.
   * @callback AlertsInScope
   * @param {string[]} scopes If an alert has a "scope" that is contained within this array, it will be returned.
   * You can use a wildcard `["*"]` to get all alerts.
   * @returns {Array<Object>}
   * @example
   * const scopes = ["intents", "intent-view"];
   * const alerts = alertsInScope(scopes);
   * console.log(alerts); // [{message: "hi", scopes: ["intents","asd"], ...}]
   *
   * const alerts = alertsInScope(["*"]);
   * console.log(alerts.length); // 10
   *
   * // You could use this for basic route matching too:
   * const alerts = alertsInScope([this.$route.name]);
   * console.log(alerts); // [{message: "hi", scopes: ["intents","asd"], ...}]
   */
  /**
   * Return a function that will return all alerts within a given scope
   * @param state
   * @returns {AlertsInScope}
   */
  alertsInScope: (state) => (scope) => {
    const alerts = [];
    const getAll = scope.includes("*");

    for (let i=0; i<state.snackbarStack.length; i++) {
      // Passed scope contains "*"
      let addAlert = getAll;
      // Alert has no scope, thus scope "*"
      if (!addAlert && !state.snackbarStack[i].scopes?.length) addAlert = true;
      // Alert wants to use a scope "*", or...
      // Alert contains a scope matching one in the argument
      if (!addAlert && state.snackbarStack[i].scopes.some(s => s === "*" || scope.includes(s))) addAlert = true;

      if (!addAlert) continue;

      alerts.push(state.snackbarStack[i]);
    }

    return alerts;
  },
};

const actions = {};

const mutations = {
  setValue(state, { key, value }) {
    state[key] = value;
  },
  /**
   * Pushes an alert to the snackbar stack
   * @param state
   * @param {string} message The text message to display in this alert
   * @param {string} [type="info"] Alert type, e.g., 'success', 'error', 'warning', 'info'
   * @param {?number} [timeout=7000] For how long this alert will be visible.
   * Minimum 1000ms
   * @param {?string | ?{name: string, params?: Record<string, string|number>, query: Record<string, any>}} to Router link to wrap the alert in
   * @param {?string[]} [scopes=["*"]] A list of arbitrary scopes. Places where the alert will be displayed.
   * If empty, displayed anywhere the component is used
   * @param {?string} [scope] A single scope. Automatically merged with `scopes` array if provided
   */
  addAlert(state, { message, type, timeout, to , scopes, scope }) {
    const time = timeout ? Math.max(timeout, 1000) : 7000;
    if (!scopes) scopes = scope ? [] : ['*'];
    if (scope && !scopes.includes(scope)) scopes.push(scope);

    const newItem = {
      id: Date.now() + Math.random().toString(36).slice(2),
      duration: time,
      type: type,
      message,
      to,
      scopes: scopes || ['*'],
      timeout: null,
      visible: true,
    };

    // We must copy instead of mutate, for reactivity :(
    newItem.timeout = setTimeout(() => {
      set(state, 'snackbarStack', state.snackbarStack.filter((alert) => alert.id !== newItem.id));
    }, time + 300); // 300ms for the fade out animation
    set(state, 'snackbarStack', [...state.snackbarStack, newItem]);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};