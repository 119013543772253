import { connectPrimus } from '@/utils/primus';

import { sortUpdated, sortOffline } from '@/utils/livechat';
import LivechatService from '@/services/livechat';
import TranslationService from '@/services/translation';
import { chatStatus, incomeTypes, statusTypes, agentForOrganizationStatusTypes, extractMsgText, toProperMsgFormat } from '@/utils/livechat';
import store from '../index';
import DialogService from '@/services/dialogs';
import BotFormsService from '@/services/botForms';
import _ from 'lodash';
import i18n from '@/i18n';

let primus = null;

const tabIdSessionKey = 'moin.livechat.tabIdSession';

const randomString = () => {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}

let broadcastChannel = null;

const getFromStorage = (key, defaultValue, storage = "local") => {
  const storageValue = window[`${storage}Storage`].getItem(key);
  if (storageValue) {
    try {
      return JSON.parse(storageValue);
    } catch (error) { //value is not json 
      return storageValue;
    }
  }
  return defaultValue;
}

const setToStorage = (key, value, storage = "local") => {
  window[`${storage}Storage`].setItem(key, JSON.stringify(value));
}

const state = {
  loading: false,
  primusInitialized: false, // primus initialized
  uniqueBotId: null,
  botHasLivechat: false, // bot livechat setting is enabled and configured
  activeTabId: null,
  currentTabId: null,
  agent: null, // current user agent object
  agentForOrganizationStatus: null, // user is either an agent for the current organization, an agent for another organization or not an agent at all
  organisation: null,
  isChatOpen: false,
  isLivechatVisible: false,
  activeChatId: null,
  chatsOtherAgents: {},
  chatsOpen: {},
  chatsUnassigned: {},
  chatsClosed: {},
  /**
   * Local Store Object to track important chat changes
   * Key is chat.uuid. Value (object) can hold any info the logic needs related to the chat.
   * @type {{
   * [key: string]: {
   *   isMessageNew: boolean,
   *   [key: string]: any,
   * }}}
   */
  localChatsInfo: {}, // Object to track chat changes
  activeTabTicker: null,
  chatHistories: {},
  isTabVisible: true,
  chatTranslations: {},
  agentLanguages: ["DE"],
  activeChatLanguage: "EN",
  hubSettings: {
    soundNewMessage: '/sounds/blooom.mp3',
    soundNewChat: '/sounds/din-don.mp3',
    volume: 0.5,
    notificationAudio: false,
    notificationBrowser: true,
  },
  translationServiceError: false,
  isAgentInactive: false,
};

const getters = {
  loading: (state) => state.loading,
  primusInitialized: (state) => state.primusInitialized,
  botHasLivechat: (state) => state.botHasLivechat,
  isCurrentTabActive: (state) => state.activeTabId === state.currentTabId,
  agent: (state) => state.agent,
  agentStatus: (state, getters) => {
    if (!state.agent) return;

    if (getters.isCurrentTabActive) {
      return statusTypes.online;
    } else if (state.activeTabId) {
      return statusTypes.otherTab;
    } else {
      return statusTypes.offline;
    }
  },
  agentForOrganizationStatus: (state) => state.agentForOrganizationStatus,
  livechatProfile: (state) => state.livechatProfile,
  isChatOpen: (state) => state.isChatOpen,
  chatsOtherAgents: (state) => Object.values(state.chatsOtherAgents),
  // Net line has nothing to deal with changeToChat
  activeChat: (state, _, __, rootGetters) => {
    const isSupervisor = rootGetters['auth/isSupervisor'];

    let chat = state.chatsOpen[state.activeChatId] || state.chatsUnassigned[state.activeChatId] || null;

    // For Supervisor also check 'others' and 'closed' chats
    if (!chat && isSupervisor) {
      chat = state.chatsOtherAgents[state.activeChatId] || state.chatsClosed[state.activeChatId] || null;
    }

    return chat;
  },
  inSupervisorMode: (state, getters, __, rootGetters) => {
    // Conditions to supervisor mode:
    //  - User has 'supervisor' feature
    //  - Is 'offline' as an agent
    const isSupervisor = rootGetters['auth/isSupervisor'];
    const isOffline = getters.agentStatus === statusTypes.offline;

    return isSupervisor && isOffline;
  },
  chatsUnassigned: (state) => {
    const chats = Object.values(state.chatsUnassigned);
    chats.sort(sortUpdated);
    chats.sort(sortOffline);

    return chats;
  },
  chatsOpen: (state) => {
    const chats = Object.values(state.chatsOpen);
    chats.sort(sortUpdated);
    chats.sort(sortOffline);
    return chats;
  },
  chatsClosed: (state) => {
    return Object.values(state.chatsClosed);
  },
  incomeType: (state, getters) => {
    if (!getters.isCurrentTabActive) {
      return null;
    }
    if (Object.keys(state.chatsUnassigned).length > 0) {
      return incomeTypes.newChat
    }

    let hasNewMessages = Object.values(state.chatsOpen).some((c) => {
      if (c.uuid === state.activeChatId && getters.activeChatIsVisible) {
        //if the chat with the new message is open, don't show the notification
        return false;
      }

      return c.isMessageNew;
    });
    if (hasNewMessages) {
      return incomeTypes.newMessage;
    }
    return incomeTypes.activeTab;
  },
  chatHistoryById: (state) => (chatUuid) => {
    return state.chatHistories[chatUuid];
  },
  activeChatIsVisible: (state) => {
    return state.isLivechatVisible && state.isTabVisible && state.isChatOpen;
  },
  chatTranslations: (state) => state.chatTranslations,
  useTranslation: (state, _, __, rootGetters) => {
    // If auto-translate is not explicitly enabled in bot settings, do not use it
    const botSettings = rootGetters["bots/getBotSettings"](state.uniqueBotId);
    if (!botSettings?.moin_livechat?.autoTranslate) {
      return false;
    }
    // Else translate if we can find the language key
    return !state.agentLanguages.map(l => l.toLowerCase()).includes(state.activeChatLanguage.toLowerCase());
  },
  activeChatLanguage: (state) => state.activeChatLanguage?.toUpperCase(),
  activeChatGroups: (state, getters) => {
    const chatGroups = getters.activeChat?.groups || [];
    return _.intersection(chatGroups, state.agent?.groups || []);
  },
  organization: (state) => state.organisation,
  shortcodeContext: (state) => {
    return {
      name: state.agent.name,
    };
  },
  activeChatFiles: (state, getters) => {
    return state.organisation.groups.filter(g => getters.activeChatGroups.includes(g.uuid)).map(g => {
      return g.files?.map(f => {
        return {
          ...f,
          group: g.name,
        };
      
      }) || [];
    
    }).flat();
  },
  activeChatShortcodes: (state, getters) => {
    return state.organisation.groups.filter(g => getters.activeChatGroups.includes(g.uuid)).map(g => {
      return g.shortcodes?.map(s => {
        return {
          ...s,
          group: g.name,
        };
      
      }) || [];
    
    }).flat();
  },
  getLocalChatsInfo: (state) => state.localChatsInfo,
  translationServiceError: (state) => state.translationServiceError,
  defaultHubSettings: (state) => state.hubSettings,
  activeHubSettings: (state) => state.agent?.hubSettings || state.hubSettings,
  isAgentInactive: (state) => state.isAgentInactive,
};

const actions = {
  async init({ dispatch, state, commit, rootGetters, getters }, { botId, routePath }) {
    state.loading = true;
    commit('resetLivechat');

    if (!botId) {
      // console.log('livechat 🤌 – no init, because of no botId');
      return false;
    }

    if (routePath && routePath.includes('/preview')) {
      // console.log('livechat 🤌 – no init in preview');
      return false;
    }

    // clearInterval(state.activeTabTicker);

    // check if bot setting is set, call getter getBotSettings from bots store
    const botSettings = rootGetters['bots/getBotSettings'](botId);
    
    if (!botSettings.moin_livechat) {
      state.botHasLivechat = false;
      return false;
    }

    const { exists, uuid } = await LivechatService.getBotHasLivechat(botId);

    state.botHasLivechat = exists || false;

    if (!state.botHasLivechat) {
      return false;
    }
    
    //set initial agent language
    state.agentLanguages = [rootGetters['auth/user'].language];

    // load agent
    await dispatch('loadAgent', {orgUuid: uuid});
    if (state.agentForOrganizationStatus === agentForOrganizationStatusTypes.isNotAgent) {
      return;
    } else if (state.agentForOrganizationStatus === agentForOrganizationStatusTypes.isAgentForCurrentOrganization) {
      // load organization
      state.organisation = await LivechatService.getOrganizatioByUuid(uuid);
    }

    // Set tab check interval
    // state.activeTabTicker = setInterval(async () => {
    //   await dispatch('checkIsCorrectTab');
    //   dispatch('checkIncomeType');
    // }, tabCheckInterval);

    // commit('resetLivechat'); moved to top
    state.uniqueBotId = botId;

    dispatch('loadTabId');
    dispatch('initBroadcastChannel');

    if (getters.agentStatus === statusTypes.online) {
      await dispatch('initChat');
    }
    if (state.organisation?.timeoutLimit) {
      commit('inactivityTracker/setTimeoutDuration', { timeoutDuration: state.organisation?.timeoutLimit * 1000 * 60}, { root: true });
      dispatch('inactivityTracker/restartTimeoutTimer', null, { root: true });
    }
    
    state.loading = false;
  },

  initBroadcastChannel({ state, dispatch, commit }) {
    if (!broadcastChannel) {
      broadcastChannel = new BroadcastChannel('moin-livechat');
    }
    
    broadcastChannel.onmessage = (ev) => {
      dispatch('handleBroadcastChannelMessage', ev);
    };
  },
  
  handleVisibilityChange({ state, getters, commit }) { // handles tab visibility changes
    state.isTabVisible = document.visibilityState === 'visible';
    if (getters.activeChatIsVisible && state.activeChatId) {
      // if the tab is visible and the chat is open, set the active chat to read
      if (state.chatsOpen[state.activeChatId]) {
        state.chatsOpen[state.activeChatId].isMessageNew = false;
        commit('setLocalChatsInfo', { chat: state.activeChatId, data: { isMessageNew: false } });
      }
    }
  },
  async initChat({ dispatch }) {
    store.dispatch('notificationNative/initNotificationNative');

    await dispatch('loadChats');
    await dispatch('initPrimus');
  },
  async initPrimus({ state, dispatch, getters }) {
    if (!state.agent?.uuid) {
      state.primusInitialized = false;
    }
    const apiUrl = process.env.VUE_APP_LIVECHAT_API_URL;
    const query = `agentUuid=${state.agent.uuid}`;
    primus = await connectPrimus({ apiUrl, query });

    primus.on('open', (ev) => console.log('Connected to primus server', apiUrl));
    primus.on('error', (err) => console.log(err));
    primus.on('end', (ev) => console.log('Primus connection ended',ev));
    primus.on('reconnect', (ev) => console.log('Primus reconnect', ev));
    primus.on('reconnect scheduled', (ev) => console.log('Primus reconnect scheduled', ev));
    primus.on('reconnected', (ev) => console.log('Primus reconnected', ev));

    function handleData(data) {
      // console.log('handleData() - Received primus data', data);
      if (!getters.isCurrentTabActive) {
        return;
      }
      switch (data.type) {
        case 'message':
          dispatch('addMessage', data);
          break;
        case 'chat_created':
          dispatch('addCreatedChat', data);
          break;
        case 'chat_assigned':
          dispatch('assignChat', data);
          break;
        case 'chat_reassigned':
          dispatch('reassignChat', data);
          break;
        case 'user_disconnected':
          dispatch('userStatusChanged', data);
          break;
        case 'user_connected':
          dispatch('userStatusChanged', data);
          break;
        default:
          console.error('Unknown primus data type');
      }
    }

    primus.on('data', handleData);

    state.primusInitialized = true;
  },
  async loadAgent({ state, commit }, { orgUuid }) {
    const agentObj = await LivechatService.getAgentByToken();
    
    if (!agentObj) {
      commit('setAgentForOrganizationStatus', agentForOrganizationStatusTypes.isNotAgent);
      return;
    }

    if (agentObj.organizationUuid === orgUuid) {
      commit('setAgent', agentObj);
      commit('setAgentForOrganizationStatus', agentForOrganizationStatusTypes.isAgentForCurrentOrganization);
    } else {
      commit('setAgentForOrganizationStatus', agentForOrganizationStatusTypes.isAgentForOtherOrganization);
    }
  },
  async loadChats({ state, dispatch, commit }) {
    if (!state.agent?.uuid || !state.uniqueBotId) {
      return false;
    }

    commit('resetLivechatChats');

    const chats = await LivechatService.getAllChats({
      agentUuid: state.agent.uuid,
      uniqueBotId: state.uniqueBotId,
    });

    dispatch('prepareOpenChats', chats.open);
    dispatch('prepareUnassignedChats', chats.unassigned);
    dispatch('prepareClosedChats', chats.closed);

    /**
     * Start loading last used forms, prioritizing unassigned, then open.
     * Closed & other agents can be ignored, unless you need those.
     */
    
    for (const id in state.chatsUnassigned) {
      dispatch('populateCompletedForm', state.chatsUnassigned[id]).then((lastForm) => {
        commit('setChatsUnassigned', {...state.chatsUnassigned, [id]: {...state.chatsUnassigned[id], lastForm}});
      });
    }

    for (const id in state.chatsOpen) {
      dispatch('populateCompletedForm', state.chatsOpen[id]).then((lastForm) => {
        commit('setChatsOpen', {...state.chatsOpen, [id]: {...state.chatsOpen[id], lastForm}});
      });
    }
  },

  /**
   * Fetch Chats for Organization. Is used for Supervisors
   * @param {*} param0 - Vue 
   * @returns 
   */
  async loadChatsOrganization({ state, dispatch, commit }) {
    const organizationUuid = state.organisation?.uuid

    if (!organizationUuid) return false;

    commit('resetLivechatChats');
    // If we set status to 'all' then backend return already formatted { open: [], closed: [], unassigned: [] } 
    const { success, data: chats, error } = await LivechatService.getAllChatsOrganization({
      organizationUuid,
      status: 'all'
    });

    if (!success) {
      console.log('Something went wrong when fetching chats for organization. Error:', error);
      return;
    }

    const agentChats = chats.open.filter(chat => chat.agent === state.agent?._id);
    // const otherAgentChats = chats.open.filter(chat => chat.agent !== state.agent?._id);

    if (!chats || chats.error) return false

    dispatch('prepareOpenChats', agentChats);
    dispatch('prepareUnassignedChats', chats.unassigned);
    dispatch('prepareClosedChats', chats.closed);
    dispatch('prepareOtherAgentsChats', chats.open);

    /**
     * Start loading last used forms, prioritizing unassigned, then open.
     * Closed & other agents can be ignored, unless you need those.
     */
    for (const id in state.chatsUnassigned) {
      dispatch('populateCompletedForm', state.chatsUnassigned[id]).then((lastForm) => {
        commit('setChatsUnassigned', {...state.chatsUnassigned, [id]: {...state.chatsUnassigned[id], lastForm}});
      });
    }

    for (const id in state.chatsOpen) {
      dispatch('populateCompletedForm', state.chatsOpen[id]).then((lastForm) => {
        commit('setChatsOpen', {...state.chatsOpen, [id]: {...state.chatsOpen[id], lastForm}});
      });
    }

    for (const id in state.chatsOtherAgents) {
      dispatch('populateCompletedForm', state.chatsOtherAgents[id]).then((lastForm) => {
        commit('setChatsOtherAgents', {...state.chatsOtherAgents, [id]: {...state.chatsOtherAgents[id], lastForm}});
      });
    }

    for (const id in state.chatsClosed) {
      dispatch('populateCompletedForm', state.chatsClosed[id]).then((lastForm) => {
        commit('setChatsClosed', {...state.chatsClosed, [id]: {...state.chatsClosed[id], lastForm}});
      });
    }
  },
  prepareOpenChats({ rootGetters, commit, getters }, chats) {
    const localChatsInfo = getters.getLocalChatsInfo;
    
    chats.forEach((c) => {
      const { isMessageNew } = localChatsInfo[c.uuid] || {};
      c.isMessageNew = isMessageNew ?? false;
    });

    const currentChannels = rootGetters['bots/currentChannels'];

    if (currentChannels.length > 1) {
      // Find channels
      chats.forEach((chat) => {
        if (!currentChannels.length) return;
        const channelSlug = chat.uniqueUserId.split('.')[1];
        const channel = currentChannels.find((c) => c.channel === channelSlug);

        if (channel) {
          chat.channel = {
            id: channel.channelId,
            slug: channel.channel,
            displayName: channel.displayName,
            language: channel.languages[0] || 'de',
          };
        }
      });
    }

    const chatsMap = chats.reduce((map, chat) => {
      map[chat.uuid] = chat;
      return map;
    }, {});

    commit('setChatsOpen', chatsMap);
    return true;
  },
  prepareUnassignedChats({ commit }, chats) {
    
    const chatsMap = chats.reduce((map, chat) => {
      map[chat.uuid] = chat;
      return map;
    }, {});

    commit('setChatsUnassigned', chatsMap);
    return true;
  },
  async loadClosedChats({ state, dispatch}) {
    if (!state.agent?.uuid || !state.uniqueBotId) {
      return false;
    }
    const chats = await LivechatService.getClosedChats({
      agentUuid: state.agent.uuid,
      uniqueBotId: state.uniqueBotId,
    });
    
    dispatch('prepareClosedChats', chats);
  },
  prepareClosedChats({ commit }, chats) {
    const chatsMap = chats.reduce((map, chat) => {
      map[chat.uuid] = chat;
      return map;
    }, {});

    commit('setChatsClosed', chatsMap);
    return true;
  },
  prepareOtherAgentsChats({ commit }, chats) {
    const chatsMap = chats.reduce((map, chat) => {
      map[chat.uuid] = chat;
      return map;
    }, {});

    commit('setChatsOtherAgents', chatsMap);
    return true;
  },
  async addCreatedChat({ state, dispatch, commit, rootGetters }, { chatUuid, data, groups }) {
    const chat = {
      chatUuid,
      lastForm: null,
      groups,
      ...JSON.parse(data),
    };

    const lastForm = await dispatch('populateCompletedForm', chat);
    dispatch('playAudioNewChat');
    commit('setChatsUnassigned', {...state.chatsUnassigned, [chatUuid]: {...chat, lastForm}});
  },
  /**
   * Fetches and populates (mutates object) the most recently completed form into this chat instance
   * @param rootGetters
   * @param {Object} chat
   * @returns {Promise<void>}
   */
  async populateCompletedForm({ rootGetters }, chat) {
    /**
     * Get the display name of the channel
     * @param {RecentlyCompletedFormBot} bot
     * @returns {string}
     */
    const getChannelDisplayName = (bot) => {
      const details = rootGetters['bots/getBot'](bot.botId);
      return details.channels.find(ch => ch.channelId === bot.channelId)?.displayName || bot.channelId;
    }

    const forms = await BotFormsService.searchCompleted(chat.uniqueBotId, chat.uniqueUserId);

    forms[0].bot.channelDisplay = getChannelDisplayName(forms[0].bot);
    // chat.lastForm = forms[0];
    return forms[0];
  },
  // @ chat
  async startChat({ state, dispatch }, { uuid, uniqueUserId }) {
    const chat = await LivechatService.assignChat({
      chatUuid: uuid,
      uniqueUserId,
      agentUuid: state.agent.uuid,
    });

  },
  // Next line has nothing to deal with sate activeChat
  async changeToChat({ state, dispatch, commit, rootGetters, getters }, { chatUuid }) {
    let chat = state.chatsOpen[chatUuid] || state.chatsUnassigned[chatUuid];

    const isSupervisor = rootGetters['auth/isSupervisor'];
    if (!chat || isSupervisor && state.chatsOtherAgents[chatUuid]) {
      chat = state.chatsOtherAgents[chatUuid] || state.chatsClosed[chatUuid];
    }

    if (!chat) {
      if (state.chatsOtherAgents[chatUuid]) {
        // chat is handled by another agent
        console.info('changeToChat - chat is handled by another agent');
      } else {
        console.error('changeToChat - chat not found');
      }
      return;
    }

    await dispatch('loadChatHistory', {
      chatUuid,
      uniqueBotId: state.uniqueBotId,
      uniqueUserId: chat.uniqueUserId,
    });

    state.activeChatId = chatUuid;
    const chatChannelId = chat?.lastForm?.bot?.channelId;
    const currentBot = rootGetters['bots/currentBot'];
    const stagingChannels = rootGetters['bots/getBot'](currentBot.stagingBot).channels;
    const allChannels = [...currentBot.channels, ...stagingChannels];
    const channelLanguage = chatChannelId ? allChannels.find(c => c.channelId === chatChannelId)?.languages[0] : currentBot.languages[0];
    
    state.activeChatLanguage = channelLanguage || state.agentLanguages[0];
    chat.isMessageNew = false;
      // In case if we need to store the whole chat object, just use data: chat
    commit('setLocalChatsInfo', { chat: chatUuid, data: { isMessageNew: false } });

    if (getters.useTranslation) {
      //translate chat history
      const untranslatedMessages = {};

      // there are probably already translated messages
      // check which are translated and request a translation for the rest
      const allMsgs = await dispatch('prepareActiveChatMessages');
      Object.values(allMsgs).forEach((message) => {
        if (!state.chatTranslations[message.data]) {
          untranslatedMessages[message.data] = message;
        }
      });

      dispatch('translate', { messages: Object.values(untranslatedMessages), language: state.agentLanguages[0] });
    }

    commit('setIsChatOpen', true);
  },

  prepareActiveChatMessages({ getters }) {
    const activeChat = getters.activeChat;
    const historyMsgs = getters.chatHistoryById(activeChat.uuid).map((m) => toProperMsgFormat(m, activeChat));
    
    const chatMsgs = activeChat.messages.map((m) => toProperMsgFormat(m, activeChat));
    return [...historyMsgs, ...chatMsgs];
  },
  // @ chat
  async closeChat({ state, dispatch, commit }, { uuid, uniqueUserId, isStaging }) {
    const closedChat = await LivechatService.closeChat({
      chatUuid: uuid,
      uniqueUserId,
      isStaging,
    });
    if (closedChat.error) {
      console.error('closeChat', closedChat.error);
      return;
    }
    commit('removeLocalChatInfo', { chat: uuid });
    const _chatsOpen = {...state.chatsOpen};
    delete _chatsOpen[uuid];
    commit('setChatsOpen', _chatsOpen);
    if (state.activeChatId === uuid) state.activeChatId = null;
    const _chatHistories = {...state.chatHistories};
    delete _chatHistories[uuid];
    state.chatHistories = _chatHistories;
    dispatch('loadClosedChats');
  },
  async assignChat({ state, dispatch, commit }, { chatUuid, data }) {
    const { uniqueUserId, groups } = state.chatsUnassigned[chatUuid];
    const parsedData = JSON.parse(data);

    // Re-fetch form information for this chat
    const lastForm = await dispatch('populateCompletedForm', parsedData);
    parsedData.lastForm = lastForm;
    
    if (parsedData.agent.uuid !== state.agent.uuid) {
      //chat is assigned to another agent => move chat to chats
      const chat = {...parsedData};
      const _chatsUnassigned = {...state.chatsUnassigned};
      delete _chatsUnassigned[chatUuid];
      commit('setChatsUnassigned', _chatsUnassigned);
      commit('setChatsOtherAgents', {...state.chatsOtherAgents, [chatUuid]: chat});
    } else {
      //chat is assigned to current agent => change to chat
      const chat = {
        status: chatStatus.open,
        ...parsedData,
        groups,
        agent: state.agent._id,
      };
  
      commit('setChatsOpen', {...state.chatsOpen, [chatUuid]: chat});
      const _chatsUnassigned = {...state.chatsUnassigned};
      delete _chatsUnassigned[chatUuid];
      commit('setChatsUnassigned', _chatsUnassigned);
  
      dispatch('changeToChat', { chatUuid, uniqueUserId });
    }
  },
  async addMessage({ state, dispatch, commit, getters }, payload) {
    const { chatUuid, data, type, sender, created_at, attachments } = payload;
    const chat = state.chatsOpen[chatUuid];
    
    if (!chat) return;
    
    const isAgent = sender.role === 'agent';
    const isActiveChat = state.activeChatId === chatUuid;

    if (isAgent) {
      // Change timestamp only if last message was sent by agent
      chat.updated_at = created_at;
      chat.isMessageNew = false;
      // In case if we need to store the whole chat object, just use data: chat
      commit('setLocalChatsInfo', { chat: chatUuid, data: { isMessageNew: false } });
      if (getters.useTranslation && data) {
        //translate outgoing message
        const hadTranslationErrorsAlready = state.translationServiceError;
        const translations = await dispatch('translate', { messages: [payload], language: state.activeChatLanguage, reversed: true });
        if (state.translationServiceError && !hadTranslationErrorsAlready) {
          throw new Error('Translation service error');
        }
        if (translations?.length) {
          payload.data = translations[0];
        }

      }
    } else if (getters.useTranslation) {
      dispatch('translate', { messages: [payload], language: state.agentLanguages[0] });
    }

    if (attachments?.length) {
      for (const attachment of attachments) {
        chat.messages.unshift({
          sender,
          type,
          data: attachment.url,
          created_at,
          updated_at: created_at,
          chatUuid,
          attachments: [attachment],
        });
      }
    }

    if (data) {
      // Add message to the end of the dialog (view)
    chat.messages.unshift({
        sender,
        type,
        data: payload.data,
        created_at,
        updated_at: created_at,
        chatUuid,
      });
    }

    if (
      !isAgent &&
      (!isActiveChat || !getters.activeChatIsVisible)
    ) {
      chat.isMessageNew = true;
      // In case if we need to store the whole chat object, just use data: chat
      commit('setLocalChatsInfo', { chat: chat.uuid, data: { isMessageNew: true } });
      chat.updated_at = created_at;
      dispatch('playAudioNewMessage');
    }

    if (!state.isTabVisible && getters.activeHubSettings.notificationBrowser) {
      dispatch(
        'notificationNative/showNotificationNative',
        {
          title: i18n.t('settingsLivechat.notifications.newMessageTitle'),
          body: i18n.t('settingsLivechat.notifications.newMessageBody'),
        },
        { root: true }
      );
    }

    commit('setChatsOpen', {...state.chatsOpen, [chat.uuid]: chat});

    primus.write(payload);
  },

  /**
   * Returns Chat Archive in HTML format (as default)
   * Modify if file is needed
   * @param {*} params0
   * @param {Object} params
   * @param {Object} params.uniqueBotId - Bot ID
   * @param {Object} params.uniqueUserId - Unique User ID
   * @returns {string} - HTML
   */
  async getChatArchive({ state, dispatch, commit }, { uniqueBotId, uniqueUserId }) {
    const historyHtml = await LivechatService.getChatArchive({ botId: uniqueBotId, uniqueUserId });
    return historyHtml;
  },

  /**
   * Sends Archive PDF file via Email
   * @param {*} param0
   * @param {Object} params
   * @param {Object} params.uniqueBotId - Bot ID
   * @param {Object} params.uniqueUserId - Unique User ID
   * @returns {Promise{ success?: boolean, error?: any }}
   */
  async sendEmailArchive({ state, dispatch, commit }, params) {
    return await LivechatService.sendEmailArchive(params);
  },

  async addWebChatCmd({ state }, payload) {
    const activeChatUuid = state.activeChatId;

    const chat = state.chatsOpen[activeChatUuid];
    if (!chat) return;

    const data = {
      type: 'webchat_cmd',
      data: payload,
      agentUuid: state.agent.uuid,
      chatUuid: activeChatUuid,
      sender: {
        role: 'agent',
        uniqueId: state.agent.uuid,
      },
    };

    primus.write(data);
  },

  async userStatusChanged({ state, commit }, { chatUuid, data, created_at, type, sender}) {
    const chat = state.chatsOpen[chatUuid];
    if (!chat) return;
    chat.messages.unshift({
      sender,
      type,
      data,
      created_at,
      updated_at: created_at,
      chatUuid,
    });

    commit('setChatsOpen', {...state.chatsOpen, [chatUuid]: chat});
  },
  async updateAgent({ state, commit }, { name, profilePicture, hubSettings, title, languages, isAgentSelf = false }) {
    const updatedAgent = await LivechatService.updateAgent({
      uuid: state.agent.uuid,
      name,
      profilePicture,
      hubSettings,
      title,
      languages,
      isAgentSelf
    });
    
    if (isAgentSelf) {
      commit('setAgent', updatedAgent);
    }

    return true;
  },
  async goOnline({ getters, dispatch }) {
    if (!primus) {
      if (!getters.isCurrentTabActive) {
        dispatch('activateCurrentTab');
      }
      await dispatch('initChat');
      dispatch('inactivityTracker/restartTimeoutTimer', null, { root: true });
    }
  },
  async goOffline({ dispatch, getters, state }, payload) {
    if (primus) {
      primus.end();
      primus = null;
      if (getters.isCurrentTabActive && payload?.broadcast !== false) {
        dispatch('deactivateCurrentTab');
      }
    }
    state.isAgentInactive = false;
    dispatch('inactivityTracker/removeTimeoutTimer', null, { root: true });
  },
  loadTabId({ commit }) {
    let tabIdSession = getFromStorage(tabIdSessionKey, null, 'session');
    

    if (!tabIdSession) {
      tabIdSession = randomString();
      setToStorage(tabIdSessionKey, tabIdSession, 'session');
    }

    commit('setCurrentTabId', tabIdSession);
  },
  registerTabClosed({ getters }) {
    if (getters.isCurrentTabActive) {
      broadcastChannel.postMessage({ activeTab: null });
    }
  },
  activateCurrentTab({ state, commit, dispatch }) {
    // check if current tab is not active
    if (state.currentTabId !== state.activeTabId) {
      
      // save the changes
      const currentTab = state.currentTabId;
      broadcastChannel.postMessage({ activeTab: currentTab });
      commit('setActiveTabId', state.currentTabId);
      dispatch('inactivityTracker/restartTimeoutTimer', null, { root: true });
    }
  },
  deactivateCurrentTab({ commit, dispatch }) {
    broadcastChannel.postMessage({ activeTab: null });
    commit('setActiveTabId', null);
    dispatch('inactivityTracker/removeTimeoutTimer', null, { root: true });
  },
  handleBroadcastChannelMessage({ state, dispatch, commit, getters }, ev) {
    const data = ev.data;
    const newActiveTabId = data?.activeTab;

    //if the current tab is becoming active don't do anything
    if (newActiveTabId && newActiveTabId === state.currentTabId) return;
    
    // if another tab has stolen the session
    if (getters.isCurrentTabActive) {
      dispatch('goOffline', {broadcast: false});
    }
    
    commit('setActiveTabId', newActiveTabId);
  },
  playAudioNewMessage({ state, dispatch, getters }) {
    if (!getters.activeHubSettings?.notificationAudio) {
      return;
    }
    dispatch(
      'audio/playAudio',
      {
        url: getters.activeHubSettings?.soundNewMessage,
        volume: getters.activeHubSettings?.volume,
      },
      { root: true }
    );
  },
  playAudioNewChat({ state, dispatch, getters }) {
    if (!getters.activeHubSettings?.notificationAudio) {
      return;
    }
    dispatch(
      'audio/playAudio',
      {
        url: getters.activeHubSettings?.soundNewChat,
        volume: getters.activeHubSettings?.volume,
      },
      { root: true }
    );
  },
  async loadChatHistory({ state }, { uniqueUserId, uniqueBotId, chatUuid }) {
    if (state.chatHistories[chatUuid]) {
      return true;
    }

    const result = await DialogService.getChat(uniqueUserId, uniqueBotId);
    if (!result?.chat) {
      console.error(`No chat history found (${(uniqueUserId, uniqueBotId)})`);
    }
    state.chatHistories[chatUuid] = result.chat;

    return true;
  },
  setIsLivechatVisible({ commit, getters, state }, isVisible) {
    commit('setIsLivechatVisible', isVisible);

    if (getters.activeChatIsVisible && state.activeChatId) {
      state.chatsOpen[state.activeChatId].isMessageNew = false;
      // In case if we need to store the whole chat object, just use data: state.chatsOpen[state.activeChatId]
      commit('setLocalChatsInfo', { chat: state.activeChatId, data: { isMessageNew: false } });
    }
  },
  /**
   * @typedef {object} TranslationPayload
   * @property {object[]} messages - Array of messages to translate
   * @property {string} language - The language to translate to
   * @property {boolean} reversed - If true, the translation will be from the agent language to the original language
   *  
   */
  /**
   * 
   * @param {object} context 
   * @param {TranslationPayload} translationPayload 
   * @returns 
   */
  async translate({ state, commit, getters }, { messages, language, reversed = false }) {
    if (!messages || messages.length === 0 || !getters.useTranslation || messages.every( m => typeof m === 'string')) return;
    const texts = messages.map(extractMsgText);
    try {
      const response = await TranslationService.getTranslations(state.uniqueBotId, texts, language);
      
      messages.forEach((message, index) => {
        commit('addTranslatedMessage', 
        {
          language,
          // usually the incoming message will be translated to the agent language
          // and the translation will be stored in `translated` and the original message in `original`
          // if reversed is true, the original message will be stored in `translated` and the translation in `original`
          // this is useful when translating outgoing messages from the agent language to the original language
          // thus ensuring that in the chat translations the original message is always the one that the user sees 
          // and the translated message is the one that the agent sees
          original: reversed ? response.translation[index].text : message.data,
          translated: reversed ? message.data : response.translation[index].text,
        });
      });
      state.translationServiceError = false;
      return response.translation.map((t) => t.text);
    } catch (error) {
      state.translationServiceError = true;
    }
  },
  async createLivechat({ state }, { uniqueBotId }) {

    const { exists } =
      (await LivechatService.getBotHasLivechat(uniqueBotId)) || false;

    state.botHasLivechat = exists || false;

    if (!state.botHasLivechat) {

      // create org without agent, agent will be added later
      const organisation = await LivechatService.createOrganization({
        uniqueBotId,
      });

      if (organisation) {
        state.botHasLivechat = true;
        return organisation;
      }

      return false;
    }

    return state.organisation;
  },
  setAgentInactive({ getters, state }) {
    // if the agent is active in the current tab, go offline
    if (getters.isCurrentTabActive) {
      state.isAgentInactive = true;
    }
  },
  resetAgentInactive({ state }) {
    state.isAgentInactive = false;
  },
  async addNoteToChat({ state, commit }, { chatUuid, note }) {
    const chat = state.chatsOpen[chatUuid];
    const newNote = await LivechatService.addNoteToChat(chatUuid, note);
    chat.agentNotes = chat.agentNotes || [];
    chat.agentNotes.push(newNote);
    commit('setChatsOpen', {...state.chatsOpen, [chatUuid]: chat});
  },

  async removeNoteFromChat({ state, commit }, { chatUuid, noteId }) {
    const chat = state.chatsOpen[chatUuid];
    try {
      await LivechatService.removeNoteFromChat(chatUuid, noteId);
      chat.agentNotes = chat.agentNotes.filter((note) => note._id !== noteId);
      commit('setChatsOpen', {...state.chatsOpen, [chatUuid]: chat});
    } catch (error) {
      console.error('removeNoteFromChat', error);
    }
  }
};

const mutations = {
  setValue(state, { key, value }) {
    state[key] = value;
  },
  setIsChatOpen(state, isChatOpen) {
    state.isChatOpen = isChatOpen;
  },
  setCurrentTabId(state, tabId) {
    state.currentTabId = tabId;
  },
  setActiveTabId(state, activeTabId) {
    state.activeTabId = activeTabId;
  },
  setAgent(state, agent) {
    state.agent = agent;
    state.agentLanguages = agent?.languages?.length ? agent?.languages : state.agentLanguages;
  },
  setAgentForOrganizationStatus(state, status) {
    state.agentForOrganizationStatus = status;
  },
  setChatsUnassigned(state, chats) {
    state.chatsUnassigned = chats;
  },
  setChatsOpen(state, chats) {
    state.chatsOpen = chats;
  },
  setChatsOtherAgents(state, chats) {
    state.chatsOtherAgents = chats;
  },
  setChatsClosed(state, chats) {
    state.chatsClosed = chats;
  },
  updateStatus(state, status) {
    state.agentStatus = status;
  },
  setIsLivechatVisible(state, isVisible) {
    state.isLivechatVisible = isVisible;
  },
  setChatTranslations(state, translations) {
    state.chatTranslations = translations;
  },
  addTranslatedMessage(state, { language, original, translated }) {
    state.chatTranslations = {
      ...state.chatTranslations,
      [original]: translated,
    };
  },
  resetLivechatChats(state) {
    state.chatsClosed = {};
    state.chatsOpen = {};
    state.chatsOtherAgents = {};
    state.chatsUnassigned = {};
    state.activeChatId = null;
    state.isChatOpen = false;
    state.isLivechatVisible = false;
    state.chatTranslations = {};
    state.chatHistories = {};
    state.localChatsInfo = {};
  },
  resetLivechat(state) {
    state.agent = null;
    state.organisation = null;
    state.chatsClosed = {};
    state.chatsOpen = {};
    state.chatsOtherAgents = {};
    state.chatsUnassigned = {};
    state.activeChatId = null;
    state.isChatOpen = false;
    state.isLivechatVisible = false;
    // state.botHasLivechat = false;
    state.primusInitialized = false;
    state.chatTranslations = {};
    state.chatHistories = {};
    state.localChatsInfo = {};
    state.isAgentInactive = false;
  },

  /**
   * @param {any} state
   * @param {object} param
   * @param {string} param.chat - Unique Chat ID (uuid)
   * @param {string} param.data - Object that which includes new Values to be saved locally for chat
   * @returns {void}
   */
  setLocalChatsInfo(state, { chat, data }) {
    if (!chat) return;

    const local = state.localChatsInfo[chat]
      ? _.cloneDeep(state.localChatsInfo[chat])
      : {};

    // Upsert or overwrite
    state.localChatsInfo[chat] = _.cloneDeep({ ...local, ...data });
  },
  /**
   * @param {any} state 
   * @param {{
    *  chat: string,
    * }} param
    * @returns {void}
    */
   removeLocalChatInfo(state, { chat }) {
     if (state.localChatsInfo[chat]) {
      const newLocalChatsInfo = { ...state.localChatsInfo };
      delete newLocalChatsInfo[chat];
      state.localChatsInfo = newLocalChatsInfo;
     }
   }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
