var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex flex-column justify-space-between full-height" },
    [_c("LivechatInfo"), _c("LivechatEmpty"), _c("LivechatChat")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }