var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isUsable("text", 0) ||
      _vm.isUsable("image", 0) ||
      _vm.isUsable("cards", 0)
        ? _c(
            "v-timeline-item",
            {
              attrs: { color: "white" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "icon",
                    fn: function () {
                      return [_vm._v("+")]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                3562683517
              ),
            },
            [
              _c(
                "v-card",
                { staticClass: "mb-12", attrs: { flat: "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-card-actions",
                        [
                          _vm.isUsable("text", 0)
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    small: "",
                                    tile: "",
                                    outlined: "",
                                    color: "primary lighten-3",
                                    "data-cy": "add-text-input-button",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addText()
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", { attrs: { left: "" } }, [
                                    _vm._v("short_text"),
                                  ]),
                                  _c(
                                    "span",
                                    { staticClass: "d-none d-sm-inline" },
                                    [_vm._v("Text")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.isUsable("image", 0)
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    small: "",
                                    tile: "",
                                    outlined: "",
                                    color: "primary lighten-3",
                                    "data-cy": "add-image-button",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addImage()
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", { attrs: { left: "" } }, [
                                    _vm._v("insert_photo"),
                                  ]),
                                  _c(
                                    "span",
                                    { staticClass: "d-none d-sm-inline" },
                                    [_vm._v("Media")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.isUsable("cards", 0)
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    disabled: _vm.isUserlike,
                                    small: "",
                                    tile: "",
                                    outlined: "",
                                    "data-cy": "add-cards-button",
                                    color: "primary lighten-3",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addCards()
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", { attrs: { left: "" } }, [
                                    _vm._v("view_carousel"),
                                  ]),
                                  _c(
                                    "span",
                                    { staticClass: "d-none d-sm-inline" },
                                    [_vm._v("Slides")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.isAdmin && _vm.isUsable("webhook", 0)
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    small: "",
                                    tile: "",
                                    outlined: "",
                                    color: "admin",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addWebhook()
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", { attrs: { left: "" } }, [
                                    _vm._v("http"),
                                  ]),
                                  _c(
                                    "span",
                                    { staticClass: "d-none d-sm-inline" },
                                    [_vm._v("Webhook")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.isAdmin && _vm.isUsable("userlike_check", 0)
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    small: "",
                                    tile: "",
                                    outlined: "",
                                    color: "admin",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addUserlikeCheck()
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", { attrs: { left: "" } }, [
                                    _vm._v("http"),
                                  ]),
                                  _c(
                                    "span",
                                    { staticClass: "d-none d-sm-inline" },
                                    [_vm._v("Userlike Agenten")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._l(_vm.contentActions, function (action, index) {
        return _c(
          "v-timeline-item",
          {
            key: action.name,
            attrs: { color: "white" },
            scopedSlots: _vm._u(
              [
                {
                  key: "icon",
                  fn: function () {
                    return [_vm._v(_vm._s(index + 1))]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          },
          [
            _c(
              "v-card",
              { staticClass: "mb-12", attrs: { flat: "" } },
              [
                _c(
                  "v-row",
                  [
                    _c("ActionEdit", {
                      attrs: {
                        channelType: _vm.channelType,
                        variations: _vm.variations,
                        quickreplies:
                          _vm.quickreplies &&
                          index === _vm.contentActions.length - 1,
                        action: action,
                        deletable:
                          _vm.deletableAction &&
                          action.content.quick.length === 0 &&
                          _vm.isDeletable(index),
                      },
                      on: {
                        onUpdated: _vm.update,
                        onDeleted: function ($event) {
                          return _vm.deleteAction(action)
                        },
                      },
                    }),
                    _c(
                      "v-card-actions",
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              disabled: _vm.linkDisabled(action),
                              small: "",
                              outlined: "",
                              tile: "",
                              "data-cy": "add-link-button",
                              color: "primary lighten-3",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.addLink(action)
                              },
                            },
                          },
                          [
                            _c("v-icon", { attrs: { left: "" } }, [
                              _vm._v("link"),
                            ]),
                            _c("span", { staticClass: "d-none d-sm-inline" }, [
                              _vm._v("Links"),
                            ]),
                          ],
                          1
                        ),
                        action.content.quick.length === 0 &&
                        _vm.isUsable("text", index + 1)
                          ? _c(
                              "v-btn",
                              {
                                attrs: {
                                  small: "",
                                  tile: "",
                                  outlined: "",
                                  color: "primary lighten-3",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.addText(action)
                                  },
                                },
                              },
                              [
                                _c("v-icon", { attrs: { left: "" } }, [
                                  _vm._v("short_text"),
                                ]),
                                _c(
                                  "span",
                                  { staticClass: "d-none d-sm-inline" },
                                  [_vm._v("Text")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        action.content.quick.length === 0 &&
                        _vm.isUsable("image", index + 1)
                          ? _c(
                              "v-btn",
                              {
                                attrs: {
                                  small: "",
                                  tile: "",
                                  outlined: "",
                                  color: "primary lighten-3",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.addImage(action)
                                  },
                                },
                              },
                              [
                                _c("v-icon", { attrs: { left: "" } }, [
                                  _vm._v("insert_photo"),
                                ]),
                                _c(
                                  "span",
                                  { staticClass: "d-none d-sm-inline" },
                                  [_vm._v("Media")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        action.content.quick.length === 0 &&
                        _vm.isUsable("cards", index + 1) &&
                        !_vm.withoutCards
                          ? _c(
                              "v-btn",
                              {
                                attrs: {
                                  disabled: _vm.isUserlike,
                                  small: "",
                                  tile: "",
                                  outlined: "",
                                  color: "primary lighten-3",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.addCards(action)
                                  },
                                },
                              },
                              [
                                _c("v-icon", { attrs: { left: "" } }, [
                                  _vm._v("view_carousel"),
                                ]),
                                _c(
                                  "span",
                                  { staticClass: "d-none d-sm-inline" },
                                  [_vm._v("Slides")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.isAdmin &&
                        action.content.quick.length === 0 &&
                        _vm.isUsable("webhook", index + 1)
                          ? _c(
                              "v-btn",
                              {
                                attrs: {
                                  small: "",
                                  tile: "",
                                  outlined: "",
                                  color: "admin",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.addWebhook(action)
                                  },
                                },
                              },
                              [
                                _c("v-icon", { attrs: { left: "" } }, [
                                  _vm._v("http"),
                                ]),
                                _c(
                                  "span",
                                  { staticClass: "d-none d-sm-inline" },
                                  [_vm._v("Webhook")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.isAdmin && _vm.isUsable("userlike_check", index + 1)
                          ? _c(
                              "v-btn",
                              {
                                attrs: {
                                  small: "",
                                  tile: "",
                                  outlined: "",
                                  color: "admin",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.addUserlikeCheck()
                                  },
                                },
                              },
                              [
                                _c("v-icon", { attrs: { left: "" } }, [
                                  _vm._v("http"),
                                ]),
                                _c(
                                  "span",
                                  { staticClass: "d-none d-sm-inline" },
                                  [_vm._v("Userlike Agenten")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }