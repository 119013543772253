var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading
        ? _c("v-skeleton-loader", {
            staticClass: "relative",
            attrs: { type: "image", width: "100%", height: 80 },
          })
        : _c("div", { staticClass: "action-user-row" }, [
            _c(
              "div",
              { class: { "pa-2 action-user-row-box": !_vm.readMode } },
              [
                _c("div", [_vm._t("title")], 2),
                _c(
                  "div",
                  {
                    staticClass: "d-flex justify-space-between max-width-full",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "flex-grow-1 max-width-full" },
                      [_vm._t("left")],
                      2
                    ),
                    _c("div", [_vm._t("right")], 2),
                  ]
                ),
                _c("div", [_vm._t("content")], 2),
              ]
            ),
          ]),
      !_vm.noLine && !_vm.readMode
        ? _c("div", { staticStyle: { height: "20px" } }, [_c("ActionLine")], 1)
        : _vm._e(),
      _vm.readMode
        ? _c("ActionGapSeparator", { attrs: { "double-height": true } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }