var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("HubBoxSimple", {
    attrs: {
      title: _vm.$t("stats.activeFilters"),
      icon: "filter_alt",
      titleHeightAuto: "",
    },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c("div", { staticClass: "mb-3" }, [
              _c("label", { staticClass: "text-body-2 grey--text" }, [
                _vm._v(" " + _vm._s(_vm.$t("dateRange.labelDateRange")) + " "),
              ]),
              _c("div", { staticClass: "text-body-1" }, [
                _vm._v(_vm._s(_vm.dateRangeFormatted)),
              ]),
            ]),
            _vm.dateRangeCompare
              ? _c("div", { staticClass: "mb-3" }, [
                  _c("label", { staticClass: "text-body-2 grey--text" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("dateRange.labelDateRangeCompare")) +
                        " "
                    ),
                  ]),
                  _c("div", { staticClass: "text-body-1" }, [
                    _vm._v(_vm._s(_vm.dateRangeCompareFormatted)),
                  ]),
                ])
              : _vm._e(),
            _vm.currentChannels.length > 1
              ? _c("div", { staticClass: "mb-3" }, [
                  _c("label", { staticClass: "text-body-2 grey--text" }, [
                    _vm._v(" " + _vm._s(_vm.$t("common.channel")) + " "),
                  ]),
                  !_vm.channel
                    ? _c("div", { staticClass: "text-body-1" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("common.allChannelsLabel")) + " "
                        ),
                      ])
                    : _c("div", { staticClass: "text-body-1" }, [
                        _vm._v(_vm._s(_vm.channelName)),
                      ]),
                ])
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }