var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "relative mx-auto", staticStyle: { width: "391px" } },
      [
        !_vm.startScreen
          ? _c(
              "div",
              {
                staticClass:
                  "mx-auto pt-10 pa-4 text-body-2 grey--text text--darken-2 text-center",
                staticStyle: { "max-width": "300px" },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("editor.widget.startscreen.notActivated")) +
                    " "
                ),
              ]
            )
          : _c("div", [
              _c(
                "svg",
                {
                  attrs: {
                    width: "391",
                    height: "732",
                    viewBox: "0 0 391 732",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c(
                    "g",
                    { attrs: { filter: "url(#filter0_d_4070_221503)" } },
                    [
                      _c("rect", {
                        attrs: {
                          x: "8",
                          y: "6",
                          width: "375",
                          height: "716",
                          fill: _vm.bgColor,
                        },
                      }),
                    ]
                  ),
                  _c(
                    "text",
                    {
                      staticStyle: { "white-space": "pre" },
                      attrs: {
                        fill: _vm.textColor,
                        "xml:space": "preserve",
                        "font-family": "Helvetica",
                        "font-size": "22",
                        "font-weight": "500",
                        "letter-spacing": "0em",
                      },
                    },
                    [
                      _c("tspan", { attrs: { x: "36", y: "50" } }, [
                        _vm._v(_vm._s(_vm.widgetTitle)),
                      ]),
                    ]
                  ),
                  _c("path", {
                    attrs: {
                      d: "M360.615 31.7278L358.941 30.0544L352.307 36.6885L345.673 30.0544L344 31.7278L350.634 38.3618L344 44.9959L345.673 46.6692L352.307 40.0352L358.941 46.6692L360.615 44.9959L353.981 38.3618L360.615 31.7278Z",
                      fill: _vm.textColor,
                    },
                  }),
                  _c("path", {
                    attrs: {
                      d: "M254.537 144C254.537 141.791 256.328 140 258.537 140H294.168C296.377 140 298.168 141.791 298.168 144V169.365C298.168 171.574 296.377 173.365 294.168 173.365H246L254.537 164.178V144Z",
                      fill: "white",
                    },
                  }),
                  _c(
                    "text",
                    {
                      staticStyle: { "white-space": "pre" },
                      attrs: {
                        fill: "black",
                        "xml:space": "preserve",
                        "font-family": "Helvetica",
                        "font-size": "21",
                        "letter-spacing": "0em",
                      },
                    },
                    [
                      _c("tspan", { attrs: { x: "265", y: "166.03" } }, [
                        _vm._v("👋"),
                      ]),
                    ]
                  ),
                  _c("defs", [
                    _c(
                      "filter",
                      {
                        attrs: {
                          id: "filter0_d_4070_221503",
                          x: "0",
                          y: "0",
                          width: "391",
                          height: "732",
                          filterUnits: "userSpaceOnUse",
                          "color-interpolation-filters": "sRGB",
                        },
                      },
                      [
                        _c("feFlood", {
                          attrs: {
                            "flood-opacity": "0",
                            result: "BackgroundImageFix",
                          },
                        }),
                        _c("feColorMatrix", {
                          attrs: {
                            in: "SourceAlpha",
                            type: "matrix",
                            values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
                            result: "hardAlpha",
                          },
                        }),
                        _c("feOffset", { attrs: { dy: "2" } }),
                        _c("feGaussianBlur", { attrs: { stdDeviation: "4" } }),
                        _c("feComposite", {
                          attrs: { in2: "hardAlpha", operator: "out" },
                        }),
                        _c("feColorMatrix", {
                          attrs: {
                            type: "matrix",
                            values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0",
                          },
                        }),
                        _c("feBlend", {
                          attrs: {
                            mode: "normal",
                            in2: "BackgroundImageFix",
                            result: "effect1_dropShadow_4070_221503",
                          },
                        }),
                        _c("feBlend", {
                          attrs: {
                            mode: "normal",
                            in: "SourceGraphic",
                            in2: "effect1_dropShadow_4070_221503",
                            result: "shape",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "wpp-btn",
                  style: {
                    background: _vm.btnBgColor,
                    color: _vm.btnTextColor,
                  },
                },
                [_vm._v(" " + _vm._s(_vm.startScreen.buttonLabel) + " ")]
              ),
              _c(
                "div",
                { staticClass: "wpp-texts" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "wpp-greeting",
                      style: { color: _vm.textColor },
                    },
                    [_vm._v(" " + _vm._s(_vm.startScreen.greeting) + " ")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "wpp-text",
                      style: { color: _vm.textColor },
                    },
                    [_vm._v(" " + _vm._s(_vm.startScreen.text) + " ")]
                  ),
                  _vm._l(_vm.startScreen.links, function (link) {
                    return _c(
                      "div",
                      {
                        key: link.id,
                        staticClass: "wpp-text-link",
                        style: { color: _vm.textColor },
                      },
                      [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.parseUrl(link.href),
                              target: "_blank",
                            },
                          },
                          [_vm._v(_vm._s(link.title))]
                        ),
                      ]
                    )
                  }),
                ],
                2
              ),
              _c("div", { staticClass: "wpp-avatar" }, [
                _c("img", {
                  staticClass: "wpp-avatar-img",
                  attrs: { src: _vm.avatarUrl },
                }),
                _c("div", { staticClass: "wpp-avatar-ring" }),
              ]),
            ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }