var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { staticStyle: { "max-height": "100vh", height: "100vh" } },
    [
      _c("HubMenu"),
      _c("Appbar"),
      _c("v-main", { staticClass: "full-height" }, [_c("RouterView")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }