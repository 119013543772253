<template>
    <v-dialog :value="open" :scrollable="scrollable" :max-width="maxWidth" @click:outside="cancel">
        <v-card>
            <v-card-title>
                <slot name="title">{{ title }}</slot>
                <v-spacer></v-spacer>
                <v-btn :disabled="isDisabled" icon @click="cancel">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-progress-linear
                v-if="loading"
                indeterminate
            ></v-progress-linear>
            <v-card-subtitle>
                <slot name="subtitle">{{ subtitle }}</slot>
            </v-card-subtitle>
            <v-card-text>
                <slot name="content">{{ content }}</slot>
                <slot name="error">
                    <v-alert
                        v-if="error"
                        type="error"
                        dense
                        text
                    >
                        {{ error }}
                    </v-alert>
                </slot>
            </v-card-text>
            <v-card-actions class="d-flex flex-row justify-space-between">
                <slot name="actions">
                    <v-btn :disabled="isDisabled" outlined @click="cancel">{{ $t('common.cancel') }}</v-btn>
                    <v-btn :disabled="isDisabled" color="primary" @click="save">{{ saveButtonLabel || $t('common.save') }}</v-btn>
                </slot>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'EditModal',
    props: {
        open: {
            type: Boolean,
            default: false
        },
        saveButtonLabel: {
            type: String,
            default: null
        },
        title: {
            type: String,
            default: null
        },
        subtitle: {
            type: String,
            default: null
        },
        content: {
            type: String,
            default: null
        },
        error: {
            type: String,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        },
        maxWidth: {
            type: String,
            default: '600px'
        },
        scrollable: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        isDisabled() {
            return this.loading
        }
    },
    methods: {
        save() {
            this.$emit('save');
        },
        cancel() {
            this.$emit('cancel');
        }
    }
};
</script>
