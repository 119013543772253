var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: this.width,
        viewBox: "0 0 72 119",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("rect", {
        attrs: { x: "0.5", width: "71", height: "108", fill: this.colors.bg },
      }),
      _c("rect", {
        attrs: {
          x: "0.5",
          width: "71",
          height: "13",
          fill: this.colors.header,
        },
      }),
      _c("rect", {
        attrs: {
          x: "0.5",
          y: "13",
          width: "71",
          height: "13",
          fill: this.colors.bot,
        },
      }),
      _c("rect", {
        attrs: {
          x: "0.5",
          y: "108",
          width: "71",
          height: "11",
          fill: this.colors.footer,
        },
      }),
      _c("rect", {
        attrs: {
          x: "14.8145",
          y: "65",
          width: "52.6774",
          height: "16",
          fill: this.colors.user,
        },
      }),
      _c("rect", {
        attrs: {
          x: "3.93555",
          y: "87",
          width: "52.6774",
          height: "16",
          fill: this.colors.bot,
        },
      }),
      _c("rect", {
        attrs: {
          x: "3.93555",
          y: "43",
          width: "52.6774",
          height: "16",
          fill: this.colors.bot,
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }