var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "HubBox",
    {
      attrs: {
        title: "Genauigkeit",
        icon: "school",
        boxId: _vm.boxId,
        adminOnly: "",
      },
    },
    [
      _c("IntentStatistic", {
        staticClass: "mb-4",
        attrs: {
          "progress-title": "Wie oft lag die KI richtig?",
          "chart-title": _vm.$t("intents.details.often.title"),
          "chart-subline": "",
          loading: _vm.loading,
          channel: _vm.channel,
        },
        scopedSlots: _vm._u([
          {
            key: "progressBar",
            fn: function () {
              return [
                _c("v-progress-linear", {
                  staticClass: "v-progress-linear--outer-value",
                  attrs: {
                    value: _vm.progressValue,
                    "buffer-value": "100",
                    "background-color":
                      _vm.noData || _vm.loading ? null : "grey",
                    color:
                      _vm.noData || _vm.loading ? "grey lighten-2" : "primary",
                    height: "10",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ value }) {
                        return [
                          _c(
                            "strong",
                            {
                              staticClass: "text-caption",
                              style: _vm.getProgressValueStyle(value),
                            },
                            [_vm._v(" " + _vm._s(Math.ceil(value)) + "% ")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                !_vm.noData
                  ? _c("div", { staticClass: "text-caption pt-2" }, [
                      _vm._v(
                        " " + _vm._s(_vm.totalData) + " Anfragen mit Rückfrage "
                      ),
                    ])
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
          {
            key: "chart",
            fn: function () {
              return [
                !_vm.noData && _vm.chart.series.length === 1
                  ? _c("apexchart", {
                      attrs: {
                        type: "bar",
                        options: _vm.chart.options,
                        series: _vm.chart.series,
                        height: "140px",
                      },
                    })
                  : !_vm.loading && _vm.noData
                  ? _c(
                      "div",
                      { staticClass: "d-flex justify-center" },
                      [
                        _c(
                          "v-card-subtitle",
                          [
                            _c("v-icon", { attrs: { small: "", left: "" } }, [
                              _vm._v("warning"),
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("intents.details.often.nodata")) +
                                " "
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }