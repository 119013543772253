var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fill-height" },
    [
      _c(
        "v-card",
        { staticClass: "fill-height pa-4", attrs: { outlined: "" } },
        [
          _c("h2", { staticClass: "text-h6" }, [_vm._v("Vorschläge")]),
          _c("v-divider", { staticClass: "my-2" }),
          _c("v-data-table", {
            attrs: {
              headers: _vm.tableHeaders,
              items: _vm.suggested,
              "custom-sort": _vm.customSort,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: `item.displayName`,
                  fn: function ({ item }) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "py-2 d-flex",
                          attrs: { id: item.intent },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "mr-2" },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    size: "20",
                                    color: "secondary darken-2",
                                  },
                                },
                                [_vm._v("auto_awesome")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c("span", { staticClass: "text-body-1" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.displayName(
                                        item.intent,
                                        _vm.user.language
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                              item.inReview ? _c("v-icon") : _vm._e(),
                              _vm.$vuetify.breakpoint.lgAndUp
                                ? _c(
                                    "div",
                                    { staticClass: "text-body-2 grey--text" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.description(
                                              item.intent,
                                              _vm.user.language
                                            ) &&
                                              _vm.description(
                                                item.intent,
                                                _vm.user.language
                                              ).length > 0
                                              ? _vm.description(
                                                  item.intent,
                                                  _vm.user.language
                                                )
                                              : ""
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  },
                },
                {
                  key: `item.relevance`,
                  fn: function ({ item }) {
                    return [
                      _c("IntentsRelevanceBar", { attrs: { intent: item } }),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }