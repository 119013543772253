<template>
  <div class="hub-frame white">
    <div class="hub-frame-wrapper">
      <div class="hub-frame-content relative">
        <v-progress-linear
          class="hub-frame-progress"
          v-if="isLoading"
          indeterminate
          absolute
          color="secondary darken-2"
        />
        <div class="hub-frame-header">
          <slot name="header"></slot>
        </div>
        <div class="pa-4">
          <slot name="content"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HubFrameSimple',
  components: {},
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss">
$footer-height: $spacer * 17;
$border-radius: 4px;
$spacing: 3 * $spacer;

.hub-frame {
  position: relative;
  min-height: 100%;
  max-height: 100%;
}

.hub-frame-wrapper {
  min-height: 100%;
  max-height: 100%;
  overflow: hidden;
  overflow-y: auto;
  //padding: $spacing;
}

.hub-frame-header {
  border-radius: $border-radius $border-radius 0 0;
  overflow: hidden;
}

.hub-frame-content {
  border-radius: $border-radius;
}

.hub-frame-progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.hub-frame-full-width {
  width: 100%;
}
</style>