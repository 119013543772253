var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "list-item moin-fade-in",
      class: {
        "list-item--selected": _vm.selected,
        "inactive-chat": _vm.isChatInactive,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "d-flex px-3 pa-2 moin-cursor-pointer full-width",
          on: {
            click: function ($event) {
              return _vm.$emit("onSelect", _vm.item.uuid)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "pr-2 pt-1 moin-chat-avatar" },
            [
              _c("LivechatAvatar", {
                attrs: {
                  avatar: _vm.item.user.avatar,
                  incoming: _vm.item.incoming,
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "flex-grow-1 d-flex flex-col gap-2" }, [
            _c("div", { staticClass: "full-width" }, [
              _c(
                "div",
                {
                  staticClass: "d-flex justify-space-between full-width",
                  style: { opacity: _vm.isOffline ? 0.5 : 1 },
                },
                [
                  _vm.item.incoming
                    ? _c("div", { staticClass: "grey--text" }, [
                        _vm._v(_vm._s(_vm.$t("livechat.chats.incomingChat"))),
                      ])
                    : _c(
                        "div",
                        {
                          staticClass: "primary--text text--lighten-2",
                          class: [_vm.item.isMessageNew && "font-weight-bold"],
                        },
                        [
                          _vm.hasAvatar
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      `${_vm.item.user.avatar.color.name} ${_vm.item.user.avatar.icon.name}`
                                    ) +
                                    " " +
                                    _vm._s(
                                      _vm.isChatInactive
                                        ? _vm.$t("livechat.chats.inactive")
                                        : ""
                                    ) +
                                    " "
                                ),
                              ])
                            : _c("span", [_vm._v("...")]),
                        ]
                      ),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-end" },
                    [
                      _vm.isOther && !_vm.isClosed && !_vm.isOffline
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "primary--text text--lighten-4 text-body-2 text-right",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("livechat.chats.inChatWith")) +
                                  " " +
                                  _vm._s(
                                    _vm.item.agent && _vm.item.agent.name
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.isClosed
                        ? [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-end items-center grey--text",
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-1",
                                    attrs: { size: "16", color: "grey" },
                                  },
                                  [_vm._v(" mdi-check-circle-outline ")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "text-body-2 text-right" },
                                  [_vm._v(" " + _vm._s(_vm.lastUpdated) + " ")]
                                ),
                              ],
                              1
                            ),
                          ]
                        : _vm.isOwn || _vm.isUnassigned
                        ? [
                            _c(
                              "div",
                              {
                                staticClass: "text-body-2 text-right",
                                class: [
                                  _vm.item.isMessageNew &&
                                    "font-weight-bold primary--text text--lighten-2",
                                ],
                              },
                              [_vm._v(" " + _vm._s(_vm.lastUpdated) + " ")]
                            ),
                            _vm.isOffline
                              ? _c(
                                  "v-icon",
                                  {
                                    staticClass: "ml-1",
                                    attrs: { small: "", color: "black" },
                                  },
                                  [_vm._v(" mdi-broadcast-off ")]
                                )
                              : _vm._e(),
                            !_vm.isUnassigned && _vm.item.isMessageNew
                              ? _c("PulseBoeble", { staticClass: "ml-2 mt-1" })
                              : _vm._e(),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex items-center base gap-2 primary--text text--lighten-2",
                },
                [
                  _c(
                    "span",
                    { staticClass: "text-body-2 align-center d-flex gap-1" },
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: { "x-small": "", color: "primary lighten-2" },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.infoCardIcon(_vm.infoTagTypes.channel))
                          ),
                        ]
                      ),
                      _vm._v(
                        " " +
                          _vm._s(_vm.item.lastForm?.bot?.channelDisplay) +
                          " "
                      ),
                    ],
                    1
                  ),
                  _vm.channelLanguage
                    ? _c(
                        "span",
                        {
                          staticClass: "text-body-2 align-center d-flex gap-1",
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                "x-small": "",
                                color: "primary lighten-2",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.infoCardIcon(_vm.infoTagTypes.language)
                                )
                              ),
                            ]
                          ),
                          _c("span", [_vm._v(_vm._s(_vm.channelLanguage))]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.item.isStaging
                    ? _c(
                        "v-chip",
                        {
                          staticClass: "moin-cursor-pointer",
                          attrs: {
                            color: "secondary",
                            outlined: "",
                            "x-small": "",
                          },
                        },
                        [_vm._v(" STAGING ")]
                      )
                    : _vm._e(),
                  _vm.item.notes || !"Condition for other indicators here"
                    ? [
                        _c("v-divider", { attrs: { vertical: "" } }),
                        _vm.item.notes
                          ? _c(
                              "v-icon",
                              {
                                attrs: {
                                  small: "",
                                  color: "primary lighten-2",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.infoCardIcon(_vm.infoTagTypes.note)
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ]),
            _vm.item.lastForm
              ? _c(
                  "div",
                  { staticClass: "flex primary--text text--lighten-2" },
                  [
                    _c(
                      "v-icon",
                      { attrs: { small: "", color: "primary lighten-2" } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.infoCardIcon(_vm.infoTagTypes.form)) +
                            " "
                        ),
                      ]
                    ),
                    _vm._v(" " + _vm._s(_vm.item.lastForm?.displayName) + " "),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]
      ),
      !_vm.item.agent || _vm.isOwn
        ? _c(
            "div",
            { staticClass: "text-right px-3" },
            [
              _vm.agentStatus === _vm.statusTypes.online &&
              _vm.item.status === _vm.chatStatus.unassigned
                ? _c(
                    "v-btn",
                    {
                      staticClass: "ml-2 mb-3",
                      attrs: { color: "secondary", depressed: "", small: "" },
                      on: {
                        click: function ($event) {
                          return _vm.startChat()
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("livechat.chats.startChatButton")) +
                          " "
                      ),
                      _c("v-icon", { attrs: { right: "" } }, [
                        _vm._v("mdi-forum"),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isChatInactive
                ? _c("LivechatCloseChat", {
                    staticClass: "ml-2 mb-3",
                    attrs: { chat: _vm.item },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c("v-divider"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }