var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-col",
    {
      staticClass: "fill-height pt-0 pl-2 flex-column d-flex",
      attrs: {
        lg: _vm.isEmbedded ? "12" : "9",
        md: _vm.isEmbedded ? "12" : "8",
        cols: "12",
      },
    },
    [
      _vm.attachmentItems.length
        ? _c(
            "v-row",
            [
              _c("v-col", { staticClass: "py-6" }, [
                _c("h2", { staticClass: "text-h6" }, [
                  _vm._v(_vm._s(_vm.$t("common.attachments"))),
                ]),
                _c(
                  "div",
                  _vm._l(_vm.attachmentItems, function (item, index) {
                    return _c("FileCard", {
                      key: index,
                      attrs: { border: "", item: item },
                    })
                  }),
                  1
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      !_vm.isEmbedded
        ? _c(
            "v-row",
            [
              _c(
                "v-btn",
                {
                  staticClass: "d-flex d-md-none ml-2 mt-2",
                  attrs: { icon: "", link: "", to: "/chats" },
                },
                [_c("v-icon", [_vm._v("keyboard_arrow_left")])],
                1
              ),
              !_vm.notFound && _vm.chat && _vm.chat.length > 0
                ? _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        { staticClass: "text-center" },
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("conversation.singletitle")) +
                                " " +
                                _vm._s(_vm.$t("conversation.started")) +
                                " " +
                                _vm._s(
                                  _vm.formatDateTime(
                                    _vm.chat[0].date,
                                    _vm.$t("common.dateTimeFormat")
                                  )
                                ) +
                                " " +
                                _vm._s(_vm.$t("common.clock")) +
                                " "
                            ),
                          ]),
                          _vm.isAdmin
                            ? _c("v-list-item-subtitle", [
                                _c("span", { staticClass: "moin-text-admin" }, [
                                  _vm._v(
                                    "Chat " + _vm._s(_vm.$t("common.on")) + " "
                                  ),
                                ]),
                                _c(
                                  "span",
                                  {
                                    staticClass: "copy-link moin-text-admin",
                                    attrs: {
                                      "data-url": _vm.chat[0].url,
                                      "data-copied": "false",
                                    },
                                    on: { click: _vm.copyLink },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.getHost(_vm.chat[0].url)) +
                                        " "
                                    ),
                                    _c(
                                      "v-icon",
                                      {
                                        attrs: {
                                          small: "",
                                          right: "",
                                          color: "var(--v-admin-base)",
                                        },
                                      },
                                      [_vm._v("mdi-content-copy")]
                                    ),
                                    _c(
                                      "v-icon",
                                      {
                                        attrs: {
                                          small: "",
                                          right: "",
                                          color: "var(--v-admin-base)",
                                        },
                                      },
                                      [_vm._v("mdi-check")]
                                    ),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.notFound || (_vm.chat && _vm.chat.length === 0)
                ? _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        { staticClass: "text-center" },
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("conversation.singletitle")) +
                                " " +
                                _vm._s(_vm.$t("conversation.notfound")) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.chat === false
        ? _c(
            "v-row",
            [
              _c("v-skeleton-loader", {
                attrs: { type: "list-item-two-line" },
              }),
            ],
            1
          )
        : _vm._e(),
      !_vm.notFound
        ? _c(
            "v-row",
            {
              ref: "scrollContainer",
              staticClass: "overflow-y-auto",
              attrs: { id: "chat-history" },
            },
            [
              _vm.chat
                ? _c(
                    "v-list",
                    {
                      directives: [
                        {
                          name: "scroll",
                          rawName: "v-scroll:#chat-history",
                          value: _vm.onScroll,
                          expression: "onScroll",
                          arg: "#chat-history",
                        },
                        {
                          name: "mutate",
                          rawName: "v-mutate.child",
                          value: _vm.onMutate,
                          expression: "onMutate",
                          modifiers: { child: true },
                        },
                      ],
                      attrs: { "two-line": "" },
                      on: { "hook:mounted": _vm.jumpToUUID },
                    },
                    [
                      _vm._l(_vm.chat, function (message) {
                        return _c(
                          "v-list-item",
                          { key: message.uuid, staticClass: "white" },
                          [
                            message.user === false
                              ? _c(
                                  "v-list-item-icon",
                                  [
                                    !message.livechat
                                      ? _c(
                                          "v-icon",
                                          { attrs: { color: "secondary" } },
                                          [_vm._v(" chat_bubble ")]
                                        )
                                      : _vm._e(),
                                    message.livechat
                                      ? _c(
                                          "v-icon",
                                          { attrs: { color: "primary" } },
                                          [_vm._v("headset_mic")]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            message.command === true
                              ? _c(
                                  "v-list-item-content",
                                  { staticClass: "text-center" },
                                  [
                                    message.type === "livechat_started"
                                      ? _c("v-list-item-title", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "conversation.livechat.started"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ])
                                      : _vm._e(),
                                    message.type === "livechat_ended"
                                      ? _c("v-list-item-title", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "conversation.livechat.ended"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            message.user === true
                              ? _c(
                                  "v-list-item-content",
                                  { staticClass: "ml-12" },
                                  [
                                    _c(
                                      "v-card",
                                      {
                                        attrs: {
                                          outlined: "",
                                          dark: "",
                                          color: "primary",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-card-text",
                                          { ref: message.uuid, refInFor: true },
                                          [_vm._v(_vm._s(message.text))]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-item-subtitle",
                                      { staticClass: "mt-2" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.formatTime(message.date)
                                            ) +
                                            " " +
                                            _vm._s(_vm.$t("common.clock")) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            message.user === false &&
                            message &&
                            message.message &&
                            message.message.message
                              ? _c(
                                  "v-list-item-content",
                                  {
                                    ref: message.uuid,
                                    refInFor: true,
                                    staticClass: "mr-10",
                                  },
                                  [
                                    _c(
                                      "v-card",
                                      {
                                        class: {
                                          secondary: message.livechat,
                                          "darken-3": message.livechat,
                                        },
                                        attrs: {
                                          outlined: "",
                                          dark: message.livechat,
                                        },
                                      },
                                      [
                                        message.message.message.text
                                          ? _c("v-card-text", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    message.message.message.text
                                                  ) +
                                                  " "
                                              ),
                                            ])
                                          : message.message.message
                                              .attachment &&
                                            message.message.message.attachment
                                              .type === "button"
                                          ? _c("v-card-text", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    message.message.message
                                                      .attachment.payload.text
                                                  ) +
                                                  " "
                                              ),
                                            ])
                                          : _vm._e(),
                                        message.message.message.attachment &&
                                        message.message.message.attachment
                                          .type === "button"
                                          ? _vm._l(
                                              message.message.message.attachment
                                                .payload.buttons,
                                              function (btn) {
                                                return _c(
                                                  "v-btn",
                                                  {
                                                    key: btn.title,
                                                    staticClass: "ml-4 mb-2",
                                                    attrs: {
                                                      outlined: "",
                                                      disabled: "",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(btn.title) +
                                                        " "
                                                    ),
                                                    btn.type === "web_url"
                                                      ? _c(
                                                          "v-icon",
                                                          {
                                                            staticClass:
                                                              "ml-2 mb-4",
                                                            attrs: {
                                                              small: "",
                                                            },
                                                          },
                                                          [_vm._v(" link ")]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                )
                                              }
                                            )
                                          : _vm._e(),
                                        message.message.message.attachment &&
                                        message.message.message.attachment
                                          .type === "cards"
                                          ? _c(
                                              "v-carousel",
                                              {
                                                attrs: {
                                                  continuous: false,
                                                  "show-arrows": true,
                                                  "hide-delimiters": "",
                                                  light: "",
                                                },
                                              },
                                              _vm._l(
                                                message.message.message
                                                  .attachment.payload.elements,
                                                function (card, index) {
                                                  return _c(
                                                    "v-carousel-item",
                                                    { key: index },
                                                    [
                                                      _c(
                                                        "v-sheet",
                                                        {
                                                          attrs: {
                                                            height: "100%",
                                                            tile: "",
                                                          },
                                                        },
                                                        [
                                                          _c("v-img", {
                                                            attrs: {
                                                              "aspect-ratio":
                                                                16 / 9,
                                                              "max-height":
                                                                "220",
                                                              src: card.image_url,
                                                            },
                                                          }),
                                                          _c("v-card-title", [
                                                            _vm._v(
                                                              _vm._s(card.title)
                                                            ),
                                                          ]),
                                                          _c(
                                                            "v-card-subtitle",
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  card.subtitle
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._l(
                                                            card.buttons,
                                                            function (btn) {
                                                              return _c(
                                                                "v-btn",
                                                                {
                                                                  key: btn.title,
                                                                  staticClass:
                                                                    "ml-4 mb-2",
                                                                  attrs: {
                                                                    outlined:
                                                                      "",
                                                                    disabled:
                                                                      "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        btn.title
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                  btn.type ===
                                                                  "web_url"
                                                                    ? _c(
                                                                        "v-icon",
                                                                        {
                                                                          staticClass:
                                                                            "ml-2 mb-4",
                                                                          attrs:
                                                                            {
                                                                              small:
                                                                                "",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " link "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                1
                                                              )
                                                            }
                                                          ),
                                                        ],
                                                        2
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._l(
                                          message.message.quick_replies,
                                          function (qr) {
                                            return _c(
                                              "v-chip",
                                              {
                                                key: qr.label,
                                                staticClass: "ml-4 mb-2",
                                                attrs: { disabled: "" },
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(qr.label) + " "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                    _c(
                                      "v-list-item-subtitle",
                                      { staticClass: "mt-2" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.formatTime(message.date)
                                            ) +
                                            " " +
                                            _vm._s(_vm.$t("common.clock")) +
                                            " "
                                        ),
                                        _vm.isAdmin
                                          ? _c(
                                              "span",
                                              {
                                                staticClass: "moin-text-admin",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("common.on")
                                                    ) +
                                                    " "
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "copy-link moin-text-admin",
                                                    attrs: {
                                                      "data-url": message.url,
                                                      "data-copied": "false",
                                                    },
                                                    on: { click: _vm.copyLink },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.getHost(
                                                            message.url
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          small: "",
                                                          right: "",
                                                          color:
                                                            "var(--v-admin-base)",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "mdi-content-copy"
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          small: "",
                                                          right: "",
                                                          color:
                                                            "var(--v-admin-base)",
                                                        },
                                                      },
                                                      [_vm._v("mdi-check")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            message.user === true
                              ? _c(
                                  "v-list-item-icon",
                                  [
                                    _c(
                                      "v-icon",
                                      { attrs: { color: "primary" } },
                                      [_vm._v("person")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      }),
                      _c("v-list-item", {
                        directives: [
                          {
                            name: "intersect",
                            rawName: "v-intersect",
                            value: _vm.onIntersect,
                            expression: "onIntersect",
                          },
                        ],
                        ref: "lastItem",
                      }),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      !_vm.isEmbedded
        ? _c(
            "v-tooltip",
            {
              attrs: { color: "secondary", left: "", value: _vm.newMessages },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function ({}) {
                      return [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              "v-show": true,
                              fab: "",
                              dark: "",
                              fixed: "",
                              bottom: "",
                              small: "",
                              right: "",
                              color: "primary",
                            },
                            on: { click: _vm.toTop },
                          },
                          [
                            !_vm.newMessages && _vm.fab
                              ? _c("v-icon", [_vm._v("keyboard_arrow_up")])
                              : _vm._e(),
                            _vm.newMessages || !_vm.fab
                              ? _c("v-icon", [_vm._v("keyboard_arrow_down")])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                2066811363
              ),
            },
            [
              _c(
                "span",
                { staticClass: "primary--text" },
                [
                  _c("v-icon", { attrs: { small: "" } }, [_vm._v("message")]),
                  _vm._v(" Neue Nachrichten... "),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }