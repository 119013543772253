var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "moin-bg-ci-primary",
      staticStyle: {
        position: "relative",
        width: "100%",
        height: "792px",
        overflow: "hidden",
      },
    },
    [
      _c("img", {
        staticStyle: {
          position: "absolute",
          height: "35px",
          top: "40px",
          right: "40px",
        },
        attrs: { src: "/moin_logo_white.png" },
      }),
      _c(
        "div",
        {
          staticStyle: {
            position: "absolute",
            top: "-100px",
            left: "0",
            transform: "scale(0.7)",
          },
        },
        [_c("HubBraces", { attrs: { color: "#EF7457" } })],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            position: "absolute",
            top: "300px",
            right: "0",
            transform: "scale(0.7) rotate(270deg)",
          },
        },
        [_c("HubBraces", { attrs: { color: "#EFF2FF" } })],
        1
      ),
      _c(
        "div",
        {
          staticClass: "d-flex flex-column justify-center",
          staticStyle: {
            position: "absolute",
            top: "0",
            left: "35%",
            "max-width": "55%",
            height: "100%",
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "moin-text-ci-secondary text-uppercase mb-2" },
                [_vm._v(" " + _vm._s(`{ ${_vm.$t("stats.title")} }`) + " ")]
              ),
              _c("div", { staticClass: "pdf-title white--text mb-2" }, [
                _vm._v(" " + _vm._s(_vm.currentBot.name) + " "),
              ]),
              _vm.currentChannels.length > 1
                ? _c(
                    "div",
                    { staticClass: "text-h5 white--text text-uppercase mb-1" },
                    [
                      _vm._v(" " + _vm._s(_vm.$t("common.channel")) + ": "),
                      _c("span", { staticClass: "moin-text-ci-secondary" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.channel || _vm.$t("common.allChannelsLabel")
                            ) +
                            " "
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.isAnualReview
                ? [
                    _c("div", { staticClass: "text-h4 white--text mb-1" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("stats.anualReview")) +
                          " " +
                          _vm._s(_vm.anualReviewYear) +
                          " "
                      ),
                    ]),
                  ]
                : [
                    _c(
                      "div",
                      {
                        staticClass: "text-h5 white--text text-uppercase mb-1",
                      },
                      [_vm._v(" " + _vm._s(_vm.dateRangeFormatted) + " ")]
                    ),
                    _vm.dateRangeCompareFormatted
                      ? _c(
                          "div",
                          { staticClass: "text-h5 white--text text-uppercase" },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("common.comparison")) + ":"),
                            ]),
                            _vm._v(
                              " " + _vm._s(_vm.dateRangeCompareFormatted) + " "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
            ],
            2
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "text-h6 white--text text-uppercase",
          staticStyle: { position: "absolute", bottom: "32px", right: "40px" },
        },
        [_vm._v(" " + _vm._s(_vm.dateToday) + " ")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }