var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "moin-fresh" },
    [
      _c(
        "v-dialog",
        {
          attrs: {
            value: _vm.open,
            scrollable: "",
            persistent: "",
            "max-width": "600px",
          },
        },
        [
          _c(
            "v-card",
            {
              staticClass: "d-flex flex-cols answer-dialog",
              staticStyle: { "min-height": "80vh", background: "#e2e2e2" },
              attrs: { flat: "" },
            },
            [
              _c(
                "div",
                { staticClass: "answer-dialog-header text-right pa-4" },
                [
                  _c("BtnSquare", {
                    attrs: { icon: "close" },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("onClose")
                      },
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "answer-dialog-content relative" }, [
                _c("div", { staticClass: "answer-dialog-message-bot" }, [
                  _c("div", [
                    _vm._v(
                      " Moin, ich bin dein neuer Chatbot. Du kannst mir gerne eine Frage stellen! "
                    ),
                  ]),
                ]),
                _vm.questionLocal
                  ? _c("div", { staticClass: "answer-dialog-message-human" }, [
                      _c("div", [
                        _vm._v(" " + _vm._s(_vm.questionLocal) + " "),
                      ]),
                    ])
                  : _vm._e(),
                _vm.loadingMessageSend
                  ? _c(
                      "div",
                      {
                        staticStyle: {
                          position: "absolute",
                          bottom: "8px",
                          left: "8px",
                        },
                      },
                      [_c("TypingIndicator")],
                      1
                    )
                  : _vm._e(),
                _vm.answer && !_vm.loadingMessageSend
                  ? _c("div", { staticClass: "answer-dialog-message-bot" }, [
                      _c("div", [_vm._v(" " + _vm._s(_vm.answer) + " ")]),
                    ])
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "answer-dialog-footer pa-4" }, [
                _c(
                  "div",
                  { staticClass: "d-flex align-end" },
                  [
                    _c("div", { staticClass: "answer-dialog-input" }, [
                      _vm._v(" " + _vm._s(_vm.input) + " "),
                    ]),
                    _c(
                      "v-btn",
                      {
                        staticClass: "primary lighten-2 ml-2",
                        attrs: {
                          icon: "",
                          color: "white",
                          disabled: _vm.loadingMessageSend,
                        },
                        on: { click: _vm.sendMessage },
                      },
                      [_c("v-icon", [_vm._v("mdi-send")])],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm.loading
                ? [
                    _c(
                      "div",
                      { staticClass: "answer-dialog-loading moin-fresh" },
                      [
                        _c(
                          "v-card",
                          {
                            staticClass: "pa-4 pb-6 relative",
                            staticStyle: { width: "320px" },
                            attrs: { flat: "" },
                          },
                          [
                            _vm.loading
                              ? _c("v-progress-linear", {
                                  staticStyle: {
                                    top: "0",
                                    left: "0",
                                    right: "0",
                                  },
                                  attrs: {
                                    absolute: "",
                                    indeterminate: "",
                                    color: "secondary",
                                  },
                                })
                              : _vm._e(),
                            _c("DemoStars", {
                              staticStyle: { transform: "scale(0.5)" },
                            }),
                            _c("div", { staticClass: "mt-4" }, [
                              _c("h1", {
                                staticClass: "text-h6 moinblue--text my-4",
                                staticStyle: { "line-height": "1" },
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$t(`demo.answerDialog.loading.title`)
                                  ),
                                },
                              }),
                              _c("div", {
                                staticClass: "text-body-2",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$t(`demo.answerDialog.loading.body`)
                                  ),
                                },
                              }),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }