// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/fonts/JosefinSans-VariableFont_wght.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("@/assets/fonts/Lato-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("@/assets/fonts/silka-semibold-webfont.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: \"Josefin Sans\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n@font-face {\n  font-family: \"Lato\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n@font-face {\n  font-family: \"silkaHeading\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n.reply-area[data-v-80e656b8] {\n  background-color: #ececec;\n}\n.input-field[data-v-80e656b8] {\n  line-height: 1em;\n  /* From the widget itself */\n  border: 1px solid;\n  border-color: var(--moin-textinput-textarea-borderColor, rgba(150, 150, 150, 0));\n  background: #fff;\n  resize: none;\n  width: 100%;\n  max-height: 85px;\n  min-height: 0;\n  font-family: \"Rubik\", Helvetica, Arial, sans-serif;\n  font-size: 1.6em;\n  color: #33333378;\n  margin: 0 4px 0 0;\n  padding: 10px 15px;\n  border-radius: 20px;\n  -webkit-transition: border 0.15s, height 0.15s;\n  transition: border 0.15s, height 0.15s;\n  outline: none !important;\n}\n.quick-reply[data-v-80e656b8] {\n  line-height: 2.5em;\n  /* From the widget itself */\n  background: #fff;\n  border: 1px solid #E2E2E2;\n  border-radius: 20px;\n  height: auto;\n  min-height: 35px;\n  padding: 0 12px;\n  margin-left: 3px;\n  margin-bottom: 3px;\n  font-family: \"Rubik\", Helvetica, Arial, sans-serif;\n  font-size: 1.5em;\n  background: var(--moin-quickreplybar-pill-background, #FFFFFF);\n  color: var(--moin-quickreplybar-pill-textcolor, #333333);\n  text-transform: none !important;\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
