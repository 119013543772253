var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.text
    ? _c("div", {}, [
        _c(
          "div",
          {
            staticClass: "d-flex flex-column items-center justify-end pb-4",
            staticStyle: { height: "150px" },
          },
          [
            _c("div", { staticClass: "avatar-bubble" }, [
              _c("div", {
                staticClass: "mx-auto avatar-bubble-bubble text-body-2",
                domProps: { innerHTML: _vm._s(_vm.text) },
              }),
              _c("div", { staticClass: "avatar-bubble-zipple mx-auto" }),
            ]),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }