<template>
  
  <HubBoxSimple :title="$t('stats.csat.title')" :isLoading="isLoading" v-if="!noData && totalResponses > 0">
    <template v-slot:content>
      <div class="text-center mb-4">
          <span class="d-flex">
            <v-icon v-for="star in 5" :key="star" :color="star <= Math.round(averageRating) ? 'yellow darken-3' : 'grey lighten-1'">
              mdi-star
            </v-icon>
            <span class="ml-2 font-weight-bold">{{ $t('stats.csat.average') }}: {{ averageRating.toFixed(1) }} ({{ totalResponses }})</span>
            <span v-if="dateRangeCompare" class="ml-2">
              <v-icon small :color="previousAvgRating < averageRating ? 'green' : 'red'">
                {{ previousAvgRating < averageRating ? 'mdi-arrow-up' : 'mdi-arrow-down' }}
              </v-icon>
              {{ previousAvgRating.toFixed(1) }} ({{ previousTotalResponses }})
            </span>
          </span>
        </div>
        <v-divider></v-divider>
        <v-row class="mt-2">
          <v-col v-for="(value, index) in ratings" :key="index" class="text-left d-flex align-center col-12">
            <v-icon v-for="star in 5" :key="star" :color="star <= index + 1 ? 'yellow darken-3' : 'grey lighten-1'">
              mdi-star
            </v-icon>
            <span class="ml-2">{{ value.label }} ({{value.count}})</span>
            <span v-if="dateRangeCompare" class="ml-2">
              <v-icon small :color="previousRatings[index]?.count < value.count ? 'green' : 'red'">
                {{ previousRatings[index]?.count < value.count ? 'mdi-arrow-up' : 'mdi-arrow-down' }}
              </v-icon>
              {{ previousRatings[index]?.count || 0 }}
            </span>
          </v-col>
        </v-row>
    </template>
  </HubBoxSimple>
</template>
<script>
import { mapGetters } from 'vuex';
import HubBoxSimple from '@/components/hub/HubBoxSimple.vue';
import StatsBarCountFeedback from '@/components/stats/components/StatsBarCountFeedback.vue';
import StatsBarCountConversion from '@/components/stats/components/StatsBarCountConversion.vue';
import StatsService from '@/services/stats';
import { statsTypes } from '@/components/stats/statsTypes';


export default {
  name: 'StatsCSAT',
  components: { HubBoxSimple, StatsBarCountFeedback, StatsBarCountConversion },
  data() {
    return {
      items: [],
      statsTypes,
      isLoading: true,
      isFailed: false,
      noData: true,
      amount: 5,
      ratings: [
        { label: "1 Stern", count: 3 },
        { label: "2 Sterne", count: 10 },
        { label: "3 Sterne", count: 20 },
        { label: "4 Sterne", count: 50 },
        { label: "5 Sterne", count: 100 },
      ],
      previousRatings: [],
    };
  },
  computed: {
    ...mapGetters('bots', ['currentBotId']),
    ...mapGetters('stats', ['dateRange', 'dateRangeCompare', 'channel']),
    ...mapGetters('auth', ['user']),
    averageRating() {
      const totalRatings = this.ratings.reduce((sum, rating, index) => sum + rating.count * (index + 1), 0);
      const totalResponses = this.ratings.reduce((sum, rating) => sum + rating.count, 0);
      return totalResponses > 0 ? totalRatings / totalResponses : 0;
    },
    totalResponses() {
      return this.ratings.reduce((sum, rating) => sum + rating.count, 0);
    },
    previousAvgRating() {
      if (!this.dateRangeCompare) return 0;
      const previousTotalRatings = this.previousRatings.reduce((sum, rating, index) => sum + rating.count * (index + 1), 0);
      const previousTotalResponses = this.previousRatings.reduce((sum, rating) => sum + rating.count, 0);
      const previousAverageRating = previousTotalResponses > 0 ? previousTotalRatings / previousTotalResponses : 0;
      return previousAverageRating;
    },
    previousTotalResponses() {
      return this.previousRatings.reduce((sum, rating) => sum + rating.count, 0);
    }
  },
  created() {
    this.init();
  },
  watch: {
    dateRange() {
      this.init();
    },
  },
  methods: {
    async init() {
      if (!this.dateRange) return;
      this.isLoading = true;

      const csatRatings = await StatsService.getCSATReport(this.currentBotId, this.dateRange);
      if (!csatRatings || typeof csatRatings !== 'object') {
        this.noData = true;
      } else {
        this.ratings = csatRatings.ratings.map(rating => ({
          label: this.$tc(`stats.csat.stars`, rating.value),
          count: rating.count
        }));
        this.noData = false;
      }

      if (this.dateRangeCompare) {
        const previousCsatRatings = await StatsService.getCSATReport(this.currentBotId, this.dateRangeCompare);
        if (previousCsatRatings && typeof previousCsatRatings === 'object') {
          this.previousRatings = previousCsatRatings.ratings.map(rating => ({
            label: this.$tc(`stats.csat.stars`, rating.value),
            count: rating.count
          }));
        }
      } else {
        this.previousRatings = [];
      }

      this.isLoading = false;
    }
  },
};
</script>
