var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass:
            "mb-4 d-flex align-center primary--text text--lighten-2 justify-space-between",
        },
        [
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c(
                "v-icon",
                { attrs: { color: "primary lighten-2", left: "" } },
                [_vm._v("mdi-information-outline")]
              ),
              _c("h2", { staticClass: "text-h6" }, [
                _vm._v(_vm._s(_vm.$t("welcome.stats.quotaTitle"))),
              ]),
              _vm.contactForm.status.message
                ? _c(
                    "p",
                    {
                      staticClass: "ml-4",
                      class: _vm.contactForm.status.success
                        ? "success--text"
                        : "error--text",
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: {
                            color: _vm.contactForm.status.success
                              ? "success"
                              : "error",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.contactForm.status.success
                                  ? "check"
                                  : "warning"
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _vm._v(
                        " " + _vm._s(_vm.contactForm.status.message) + " "
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          false
            ? _c("BtnSquare", {
                attrs: {
                  icon: "mdi-cog",
                  to: "/settings/license",
                  outlined: "",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex gap-4 mb-4" },
        [
          _c(
            "WelcomeInfoTeaser",
            {
              staticClass: "column",
              attrs: {
                title: _vm.$t("welcome.stats.activePackage"),
                icon: _vm.package.icon,
                value: _vm.package.name,
                loading: false,
              },
            },
            [
              _c(
                "BtnSquare",
                {
                  staticClass: "grow-0 mx-auto px-2",
                  attrs: {
                    color: "gray",
                    exact: "",
                    outlined: "",
                    "with-slot": "",
                    small: "",
                  },
                  on: { click: () => _vm.openForm("upgrade") },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("welcome.buttons.changePackage")) + " "
                  ),
                ]
              ),
            ],
            1
          ),
          _c("v-divider", { attrs: { vertical: "" } }),
          _c(
            "WelcomeInfoTeaser",
            {
              staticClass: "column",
              attrs: {
                title: _vm.$t("welcome.stats.intents"),
                loading: false,
                icon: "topic",
                value: `${_vm.intentQuota[0]}/${_vm.intentQuota[1]}`,
                "critical-value": _vm.intentQuota[0] >= _vm.intentQuota[1],
              },
            },
            [
              _c(
                "BtnSquare",
                {
                  staticClass: "grow-0 mx-auto px-2",
                  attrs: {
                    color: "gray",
                    exact: "",
                    outlined: "",
                    "with-slot": "",
                    small: "",
                  },
                  on: { click: () => _vm.openForm("intents") },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("welcome.buttons.increaseIntents")) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "WelcomeInfoTeaser",
            {
              staticClass: "column",
              attrs: {
                title: _vm.$t("welcome.stats.channels"),
                loading: false,
                icon: "filter_alt",
                value: `${_vm.channelQuota[0]}/${_vm.channelQuota[1]}`,
                "critical-value": _vm.channelQuota[0] >= _vm.channelQuota[1],
              },
            },
            [
              _c(
                "BtnSquare",
                {
                  staticClass: "grow-0 mx-auto px-2",
                  attrs: {
                    color: "gray",
                    exact: "",
                    outlined: "",
                    "with-slot": "",
                    small: "",
                  },
                  on: { click: () => _vm.openForm("channels") },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("welcome.buttons.addChannels")) + " "
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "WelcomeInfoTeaser",
            {
              staticClass: "column",
              attrs: {
                title: _vm.$t("welcome.stats.languages"),
                loading: false,
                icon: "language",
                value: `${_vm.languageQuota[0]}/${_vm.languageQuota[1]}`,
                "critical-value": _vm.languageQuota[0] >= _vm.languageQuota[1],
              },
            },
            [
              _c(
                "BtnSquare",
                {
                  staticClass: "grow-0 mx-auto px-2",
                  attrs: {
                    color: "gray",
                    exact: "",
                    outlined: "",
                    "with-slot": "",
                    small: "",
                  },
                  on: { click: () => _vm.openForm("languages") },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("welcome.buttons.addLanguages")) + " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "HubDialog",
        {
          attrs: {
            disabled: !_vm.contactForm.content.length,
            title: _vm.$t("welcome.adjustPackage.title"),
            "primary-label": _vm.$t("common.send"),
            "async-dialog": "",
          },
          on: {
            onSecondary: function ($event) {
              _vm.contactForm.shown = false
            },
            onPrimary: _vm.sendForm,
          },
          model: {
            value: _vm.contactForm.shown,
            callback: function ($$v) {
              _vm.$set(_vm.contactForm, "shown", $$v)
            },
            expression: "contactForm.shown",
          },
        },
        [
          _c("p", [
            _vm._v(
              " " + _vm._s(_vm.$t("welcome.adjustPackage.description")) + " "
            ),
          ]),
          _c(
            "div",
            { staticClass: "d-flex flex-col gap-2 mb-4" },
            [
              _c("v-checkbox", {
                staticClass: "mt-0",
                attrs: {
                  label: _vm.$t("welcome.adjustPackage.upgrade"),
                  "hide-details": "",
                },
                model: {
                  value: _vm.contactForm.topics.upgrade,
                  callback: function ($$v) {
                    _vm.$set(_vm.contactForm.topics, "upgrade", $$v)
                  },
                  expression: "contactForm.topics.upgrade",
                },
              }),
              _c("v-checkbox", {
                staticClass: "mt-0",
                attrs: {
                  label: _vm.$t("welcome.stats.intents"),
                  "hide-details": "",
                },
                model: {
                  value: _vm.contactForm.topics.intents,
                  callback: function ($$v) {
                    _vm.$set(_vm.contactForm.topics, "intents", $$v)
                  },
                  expression: "contactForm.topics.intents",
                },
              }),
              _c("v-checkbox", {
                staticClass: "mt-0",
                attrs: {
                  label: _vm.$t("welcome.stats.channels"),
                  "hide-details": "",
                },
                model: {
                  value: _vm.contactForm.topics.channels,
                  callback: function ($$v) {
                    _vm.$set(_vm.contactForm.topics, "channels", $$v)
                  },
                  expression: "contactForm.topics.channels",
                },
              }),
              _c("v-checkbox", {
                staticClass: "mt-0",
                attrs: {
                  label: _vm.$t("welcome.stats.languages"),
                  "hide-details": "",
                },
                model: {
                  value: _vm.contactForm.topics.languages,
                  callback: function ($$v) {
                    _vm.$set(_vm.contactForm.topics, "languages", $$v)
                  },
                  expression: "contactForm.topics.languages",
                },
              }),
            ],
            1
          ),
          _c("v-textarea", {
            attrs: {
              placeholder: _vm.$t("welcome.adjustPackage.placeholder"),
              filled: "",
              outlined: "",
              "auto-grow": "",
              autofocus: "",
              "hide-details": "",
              maxlength: 4096,
            },
            model: {
              value: _vm.contactForm.content,
              callback: function ($$v) {
                _vm.$set(_vm.contactForm, "content", $$v)
              },
              expression: "contactForm.content",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }