var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: {
            value: _vm.dialogVisible,
            "max-width": _vm.maxWidth,
            persistent: _vm.modal,
            scrollable: "",
          },
          on: { input: _vm.changeValue },
        },
        [
          _c(
            "v-card",
            [
              _vm.closing || _vm.loading
                ? _c("v-progress-linear", {
                    attrs: {
                      indeterminate: "",
                      absolute: "",
                      color: "secondary darken-2",
                    },
                  })
                : _vm._e(),
              _vm.title || !_vm.modal
                ? _c(
                    "v-card-title",
                    {
                      staticClass: "text-h5 pt-6",
                      staticStyle: { "word-break": "break-word" },
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.title) + " "),
                      !_vm.modal ? _c("v-spacer") : _vm._e(),
                      !_vm.modal
                        ? _c("BtnSquare", {
                            attrs: { icon: "close" },
                            on: { click: _vm.close },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _c("v-divider"),
              _c(
                "v-card-text",
                { staticClass: "text--primary pt-4" },
                [_vm._t("default")],
                2
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-container",
                    { staticClass: "pa-3", attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        { class: [_vm.btnsRight && "hub-dialog-right-row"] },
                        [
                          _c(
                            "v-col",
                            {
                              class: [_vm.btnsRight && "hub-dialog-right-col"],
                            },
                            [
                              !_vm.hideSecondary
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "px-4 primary--text",
                                      attrs: {
                                        disabled: _vm.closing,
                                        outlined: "",
                                        color: "primary lighten-2",
                                        block: _vm.$vuetify.breakpoint.xs,
                                      },
                                      on: { click: _vm.secondaryButton },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.secondaryLabel) + " "
                                      ),
                                      _c("v-icon", { attrs: { right: "" } }, [
                                        _vm._v(_vm._s(_vm.secondaryIcon)),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "text-right",
                              class: [_vm.btnsRight && "hub-dialog-right-col"],
                            },
                            [
                              !_vm.hidePrimary
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "px-4 white--text",
                                      attrs: {
                                        disabled: _vm.disabled || _vm.closing,
                                        block: _vm.$vuetify.breakpoint.xs,
                                        depressed: "",
                                        color: _vm.primaryColor,
                                        "data-cy": "hub-dialog-primary-btn",
                                      },
                                      on: { click: _vm.primaryButton },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.primaryLabel) + " "
                                      ),
                                      _c("v-icon", { attrs: { right: "" } }, [
                                        _vm._v(_vm._s(_vm.primaryIcon)),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }