var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "100%", width: "100%", "overflow-y": "auto" } },
    [
      _c(
        "div",
        { staticClass: "demo-start px-6 pb-6 d-flex items-center" },
        [
          _c(
            "v-form",
            {
              ref: "form",
              attrs: { "lazy-validation": "" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.next()
                },
              },
              model: {
                value: _vm.valid,
                callback: function ($$v) {
                  _vm.valid = $$v
                },
                expression: "valid",
              },
            },
            [
              _c("div", [
                _c(
                  "div",
                  {
                    staticClass:
                      "py-4 text-center d-flex flex-column justify-center",
                    staticStyle: { "min-height": "50vh" },
                  },
                  [
                    _c("h1", { staticClass: "text-h2 mb-6 moinblue--text" }, [
                      _vm._v(
                        " " + _vm._s(_vm.$t("demo.stepCompany.title")) + " "
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "text-body-1 primary--text text-lighten-2",
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("demo.stepCompany.body")) + " "
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "mb-0" },
                  [
                    _c("v-text-field", {
                      ref: "urlInputRef",
                      attrs: {
                        outlined: "",
                        rules: _vm.companyUrlRules,
                        required: "",
                        validateOnBlur: _vm.valid,
                        label: _vm.$t("demo.stepCompany.labelCompanyUrl"),
                        autofocus: "",
                        "error-messages": _vm.hasError
                          ? [_vm.$t("demo.stepCompany.errorCompanyUrl")]
                          : [],
                      },
                      on: {
                        change: function ($event) {
                          return _vm.handleChange()
                        },
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.next()
                        },
                        blur: () =>
                          !_vm.localCompanyUrl.length &&
                          _vm.$refs.form.resetValidation(),
                      },
                      model: {
                        value: _vm.localCompanyUrl,
                        callback: function ($$v) {
                          _vm.localCompanyUrl = $$v
                        },
                        expression: "localCompanyUrl",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "text-body-2 primary--text text-lighten-2 text-center",
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("demo.stepCompany.inputHint")) + " "
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "text-center py-4" },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "secondary", depressed: "" },
                        on: {
                          click: function ($event) {
                            return _vm.next()
                          },
                        },
                      },
                      [_vm._v(" Los geht's ")]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }