var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { staticStyle: { "max-height": "100vh", height: "100vh" } },
    [
      _c("HubMenu"),
      _c("Appbar"),
      _c("HubEditorFrame", {
        ref: "hubeditor",
        attrs: {
          title: _vm.$t("editor.widget.title"),
          back: "/wt",
          channels: _vm.channels,
          "save-icon": "cloud_upload",
          "save-label": "editor.common.deploy",
          draft: _vm.isDirty,
          loading: !!(_vm.isLoading || _vm.isSaving),
          "save-confirmation-dialog-title":
            "editor.widget.saveConfirmationTitle",
          "save-confirmation-dialog-description":
            "editor.widget.saveConfirmationDescription",
        },
        on: {
          onChannelChanged: _vm.channelChanged,
          onReset: _vm.reset,
          onSave: _vm.save,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ isLoading }) {
              return [
                _vm.isAdmin && !isLoading && _vm.configAvailable
                  ? _c(
                      "v-expansion-panels",
                      [
                        _c(
                          "v-expansion-panel",
                          [
                            _c("v-expansion-panel-header", [
                              _c(
                                "h3",
                                { staticClass: "text-h6 primary--text" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("editor.widget.admin.title"))
                                  ),
                                ]
                              ),
                            ]),
                            _c(
                              "v-expansion-panel-content",
                              [
                                _c("p", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$t("editor.widget.admin.description")
                                    ),
                                  },
                                }),
                                _c(
                                  "v-row",
                                  [
                                    _c("v-card-title", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "editor.widget.admin.poweredBy"
                                          )
                                        )
                                      ),
                                    ]),
                                    _c("v-switch", {
                                      attrs: {
                                        color: _vm.gotConfig?.config?.poweredBy
                                          ? "green"
                                          : "grey",
                                        inset: "",
                                        label: _vm.$t(
                                          "editor.widget.admin.poweredByInfo"
                                        ),
                                      },
                                      on: {
                                        change: (v) => _vm.$emit("onChange", v),
                                      },
                                      model: {
                                        value: _vm.gotConfig.config.poweredBy,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.gotConfig.config,
                                            "poweredBy",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "gotConfig.config.poweredBy",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                !isLoading
                  ? _c(
                      "v-card",
                      { attrs: { flat: "", rounded: "0" } },
                      [
                        _c("v-card-title", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("editor.widget.integration.title")
                              ) +
                              " "
                          ),
                        ]),
                        _c("v-card-subtitle", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("editor.widget.integration.description")
                              ) +
                              " "
                          ),
                        ]),
                        _c("WidgetActivation", {
                          attrs: { widgetActive: _vm.widgetActive },
                          on: { onChange: (v) => (_vm.widgetActive = v) },
                        }),
                        _c("v-row", [_c("v-col", [_c("v-divider")], 1)], 1),
                        !isLoading && _vm.currentBotId
                          ? _c("WidgetIntegrationCode", {
                              attrs: {
                                botId: _vm.currentBotId,
                                channelId: _vm.channelId,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                !isLoading && !_vm.configAvailable
                  ? _c(
                      "v-card",
                      {
                        staticClass: "rounded-0 fill-height",
                        attrs: { flat: "" },
                      },
                      [
                        _c("v-card-title", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("editor.widget.appearance.title")) +
                              " "
                          ),
                        ]),
                        _c("v-card-subtitle", [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$t(
                                  "editor.widget.appearance.na_description"
                                )
                              ),
                            },
                          }),
                        ]),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "text-center",
                                attrs: { cols: "6" },
                              },
                              [
                                _c("WidgetPreview", {
                                  staticClass: "elevation-4",
                                  attrs: {
                                    headline: "Nicht unterstützt",
                                    title: "Chatbot",
                                    name: "Name",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "6" } },
                              [
                                _c(
                                  "v-card-text",
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        attrs: {
                                          small: "",
                                          left: "",
                                          color: "warning",
                                        },
                                      },
                                      [_vm._v("warning")]
                                    ),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("editor.widget.notsupported")
                                        ) +
                                        " "
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.configAvailable && !isLoading
                  ? _c(
                      "v-card",
                      {
                        staticClass: "rounded-0 fill-height",
                        attrs: { flat: "" },
                      },
                      [
                        _c("v-card-title", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("editor.widget.appearance.title")) +
                              " "
                          ),
                        ]),
                        _c("v-card-subtitle", [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$t("editor.widget.appearance.description")
                              ),
                            },
                          }),
                        ]),
                        _c(
                          "v-row",
                          {
                            on: {
                              dragover: function ($event) {
                                $event.preventDefault()
                              },
                              dragleave: _vm.dragleave,
                              dragenter: _vm.dragenter,
                              drop: _vm.drop,
                            },
                          },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "text-center hidden-sm-and-down",
                                attrs: { cols: "12", md: "6" },
                              },
                              [
                                _vm.gotConfig && _vm.appeareance
                                  ? _c("WidgetPreview", {
                                      staticClass: "elevation-4",
                                      attrs: {
                                        "message-contrast":
                                          _vm.usermessage.contrast,
                                        message:
                                          _vm.getThemeColors[
                                            _vm.usermessage.color
                                          ].color,
                                        avatar: _vm.avatar,
                                        header:
                                          _vm.getThemeColors[
                                            _vm.widgetheader.color
                                          ].color,
                                        "header-contrast":
                                          _vm.widgetheader.contrast,
                                        headline: _vm.gotConfig.header.name,
                                        name: _vm.gotConfig.operator.name,
                                        title: _vm.gotConfig.operator.titel,
                                        inputPlaceholder:
                                          _vm.appeareance.inputPlaceholder,
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", md: "6" } },
                              [
                                _c(
                                  "v-overlay",
                                  {
                                    attrs: {
                                      value: _vm.hoveringContent,
                                      color: "success",
                                      opacity: "0.8",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "align-self-top",
                                            attrs: {
                                              color: "white",
                                              "x-large": "",
                                            },
                                          },
                                          [_vm._v(" image ")]
                                        ),
                                        _vm._v(" Avatar Bild hinzufügen "),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c("v-card-title", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "editor.widget.appearance.names.title"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("v-text-field", {
                                  staticClass: "pl-4 pr-4",
                                  attrs: {
                                    outlined: "",
                                    label: _vm.$t(
                                      "editor.widget.appearance.names.header"
                                    ),
                                  },
                                  model: {
                                    value: _vm.gotConfig.header.name,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.gotConfig.header,
                                        "name",
                                        $$v
                                      )
                                    },
                                    expression: "gotConfig.header.name",
                                  },
                                }),
                                _c("v-text-field", {
                                  staticClass: "pl-4 pr-4",
                                  attrs: {
                                    outlined: "",
                                    label: _vm.$t(
                                      "editor.widget.appearance.names.chatbot"
                                    ),
                                  },
                                  model: {
                                    value: _vm.gotConfig.operator.name,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.gotConfig.operator,
                                        "name",
                                        $$v
                                      )
                                    },
                                    expression: "gotConfig.operator.name",
                                  },
                                }),
                                _c("v-text-field", {
                                  staticClass: "pl-4 pr-4",
                                  attrs: {
                                    outlined: "",
                                    label: _vm.$t(
                                      "editor.widget.appearance.names.chatbotTitle"
                                    ),
                                  },
                                  model: {
                                    value: _vm.gotConfig.operator.titel,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.gotConfig.operator,
                                        "titel",
                                        $$v
                                      )
                                    },
                                    expression: "gotConfig.operator.titel",
                                  },
                                }),
                                _c("v-text-field", {
                                  staticClass: "pl-4 pr-4",
                                  attrs: {
                                    outlined: "",
                                    label: _vm.$t(
                                      "editor.widget.appearance.placeholderLabel"
                                    ),
                                  },
                                  model: {
                                    value: _vm.appeareance.inputPlaceholder,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.appeareance,
                                        "inputPlaceholder",
                                        $$v
                                      )
                                    },
                                    expression: "appeareance.inputPlaceholder",
                                  },
                                }),
                                _c("v-file-input", {
                                  staticClass: "pl-4 pr-4",
                                  attrs: {
                                    accept:
                                      "image/png, image/jpeg, image/bmp, image/gif",
                                    "prepend-icon": "",
                                    outlined: "",
                                    label: _vm.$t(
                                      "editor.widget.appearance.avatar.newimage"
                                    ),
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "append-outer",
                                        fn: function () {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "avatar mt-n3",
                                                staticStyle: {
                                                  "min-width": "48px",
                                                  width: "48px",
                                                  "min-height": "48px",
                                                  height: "48px",
                                                },
                                              },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    width: "48px",
                                                    src: _vm.avatar,
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                  model: {
                                    value: _vm.avatarFile,
                                    callback: function ($$v) {
                                      _vm.avatarFile = $$v
                                    },
                                    expression: "avatarFile",
                                  },
                                }),
                                _c(
                                  "v-card-text",
                                  { staticClass: "pt-0 mb-2" },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          outlined: "",
                                          color: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.generateAvatarDialog = true
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "editor.widget.appearance.avatar.btnNewAvatar"
                                              )
                                            ) +
                                            " "
                                        ),
                                        _c("v-icon", { attrs: { right: "" } }, [
                                          _vm._v("account_circle"),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "hub-dialog",
                                      {
                                        attrs: {
                                          title: _vm.$t(
                                            "editor.widget.appearance.avatar.titleCreateOwn"
                                          ),
                                          "max-width": "800px",
                                          "primary-label": _vm.$t(
                                            "editor.widget.appearance.avatar.btnSaveAvatar"
                                          ),
                                          "async-dialog": "",
                                          secondaryLabel:
                                            _vm.$t("common.cancel"),
                                        },
                                        on: {
                                          onPrimary: _vm.onClosingAvatarEditor,
                                          onSecondary: function ($event) {
                                            _vm.generateAvatarDialog = null
                                          },
                                        },
                                        model: {
                                          value: _vm.generateAvatarDialog,
                                          callback: function ($$v) {
                                            _vm.generateAvatarDialog = $$v
                                          },
                                          expression: "generateAvatarDialog",
                                        },
                                      },
                                      [
                                        _c("avatar-editor", {
                                          ref: "avatarEditor",
                                          attrs: { "is-in-popup": "" },
                                          on: {
                                            onCreateAvatarBase64:
                                              _vm.onCreateAvatarBase64,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c("v-card-title", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "editor.widget.appearance.colors.title"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c(
                                  "v-row",
                                  { staticClass: "pl-4 pr-4" },
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("HubColorInput", {
                                          attrs: {
                                            label: _vm.$t(
                                              "editor.widget.primarycolor"
                                            ),
                                          },
                                          model: {
                                            value: _vm.themecolors.primary,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.themecolors,
                                                "primary",
                                                $$v
                                              )
                                            },
                                            expression: "themecolors.primary",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("HubColorInput", {
                                          attrs: {
                                            label: _vm.$t(
                                              "editor.widget.secondarycolor"
                                            ),
                                          },
                                          model: {
                                            value: _vm.themecolors.secondary,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.themecolors,
                                                "secondary",
                                                $$v
                                              )
                                            },
                                            expression: "themecolors.secondary",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c("v-card-title", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "editor.widget.appearance.brightness.title"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c(
                                  "v-btn-toggle",
                                  {
                                    staticClass: "pl-4",
                                    attrs: {
                                      mandatory: "",
                                      borderless: "",
                                      color: "primary lighten-2",
                                    },
                                    model: {
                                      value: _vm.themetype,
                                      callback: function ($$v) {
                                        _vm.themetype = $$v
                                      },
                                      expression: "themetype",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      { attrs: { value: "regular" } },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              left: "",
                                              color: "primary lighten-2",
                                            },
                                          },
                                          [_vm._v(" brightness_medium ")]
                                        ),
                                        _c("span", [_vm._v("Neutral")]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { value: "light", disabled: "" },
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              left: "",
                                              color: "primary lighten-2",
                                            },
                                          },
                                          [_vm._v("brightness_low")]
                                        ),
                                        _c("span", [_vm._v("Light")]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { value: "dark", disabled: "" },
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              left: "",
                                              color: "primary lighten-2",
                                            },
                                          },
                                          [_vm._v(" brightness_high ")]
                                        ),
                                        _c("span", [_vm._v("Dark")]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-card-text",
                                  { staticClass: "text-left mt-0 pt-0" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "editor.widget.appearance.brightness.info"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("v-row", [_c("v-col", [_c("v-divider")], 1)], 1),
                        _c("v-card-title", [
                          _vm._v(
                            _vm._s(_vm.$t("editor.widget.elements.title"))
                          ),
                        ]),
                        _c("v-card-subtitle", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("editor.widget.elements.description")
                              ) +
                              " "
                          ),
                        ]),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "text-center align-self-center hidden-sm-and-down",
                                attrs: { cols: "12", md: "6" },
                              },
                              [
                                _c("WidgetButtonPreview", {
                                  staticClass: "mx-auto",
                                  attrs: {
                                    color:
                                      _vm.getThemeColors[_vm.widgetbutton.color]
                                        .color,
                                    contrast: _vm.widgetbutton.contrast,
                                    alignLeft:
                                      _vm.appeareance.alignmentOrigin ===
                                      "bottom left",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "pt-2",
                                attrs: { cols: "12", md: "6" },
                              },
                              [
                                _c("v-card-title", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "editor.widget.elements.button.title"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("div", [
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "v-card-text",
                                        { staticClass: "pb-0" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("editor.common.color")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-btn-toggle",
                                        {
                                          staticClass: "pl-4",
                                          attrs: {
                                            mandatory: "",
                                            borderless: "",
                                            color: "primary lighten-2",
                                          },
                                          model: {
                                            value: _vm.widgetbutton.color,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.widgetbutton,
                                                "color",
                                                $$v
                                              )
                                            },
                                            expression: "widgetbutton.color",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            { attrs: { value: "primary" } },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    left: "",
                                                    color:
                                                      _vm.getThemeColors.primary
                                                        .color,
                                                  },
                                                },
                                                [_vm._v(" stop_circle ")]
                                              ),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "editor.widget.primarycolor"
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-btn",
                                            { attrs: { value: "secondary" } },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    left: "",
                                                    color:
                                                      _vm.getThemeColors
                                                        .secondary.color,
                                                  },
                                                },
                                                [_vm._v(" stop_circle ")]
                                              ),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "editor.widget.secondarycolor"
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "v-card-text",
                                        { staticClass: "pb-0" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "editor.common.brighness"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-btn-toggle",
                                        {
                                          staticClass: "pl-4",
                                          attrs: {
                                            mandatory: "",
                                            borderless: "",
                                            color: "primary lighten-2",
                                          },
                                          model: {
                                            value: _vm.widgetbutton.contrast,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.widgetbutton,
                                                "contrast",
                                                $$v
                                              )
                                            },
                                            expression: "widgetbutton.contrast",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            { attrs: { value: "dark" } },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    left: "",
                                                    color: "primary",
                                                  },
                                                },
                                                [_vm._v("stop_circle")]
                                              ),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("editor.common.dark")
                                                  )
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-btn",
                                            { attrs: { value: "light" } },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    left: "",
                                                    color: "primary",
                                                  },
                                                },
                                                [_vm._v("panorama_fish_eye")]
                                              ),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "editor.common.light"
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.appeareance
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "v-card-text",
                                            { staticClass: "pb-0" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "editor.common.alignment"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-btn-toggle",
                                            {
                                              staticClass: "pl-4",
                                              attrs: {
                                                value:
                                                  _vm.appeareance
                                                    .alignmentOrigin ||
                                                  "bottom right",
                                                mandatory: "",
                                                borderless: "",
                                                color: "primary lighten-2",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    value: "bottom left",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.changeAlignmentOrigin(
                                                        "bottom left"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        left: "",
                                                        color: "primary",
                                                      },
                                                    },
                                                    [_vm._v("south_west")]
                                                  ),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "editor.common.alignLeft"
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    value: "bottom right",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.changeAlignmentOrigin(
                                                        "bottom right"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        left: "",
                                                        color: "primary",
                                                      },
                                                    },
                                                    [_vm._v("south_east")]
                                                  ),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "editor.common.alignRight"
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "pl-4 mt-4" },
                                  [
                                    _c("ContrastChecker", {
                                      staticClass: "mt-2",
                                      attrs: {
                                        colorA:
                                          _vm.getThemeColors[
                                            _vm.widgetbutton.color
                                          ].color,
                                        colorB:
                                          _vm.widgetbutton.contrast === "light"
                                            ? "#ffffff"
                                            : "#484848",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("v-row", [_c("v-col", [_c("v-divider")], 1)], 1),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "text-center align-self-center hidden-sm-and-down",
                                attrs: { cols: "12", md: "6" },
                              },
                              [
                                _c("WidgetPreview", {
                                  staticClass: "elevation-4",
                                  attrs: {
                                    name: _vm.gotConfig.operator.name,
                                    title: _vm.gotConfig.operator.titel,
                                    "only-header": "",
                                    "message-contrast":
                                      _vm.usermessage.contrast,
                                    avatar: _vm.avatar,
                                    message:
                                      _vm.getThemeColors[_vm.usermessage.color]
                                        .color,
                                    header:
                                      _vm.getThemeColors[_vm.widgetheader.color]
                                        .color,
                                    "header-contrast":
                                      _vm.widgetheader.contrast,
                                    headline: _vm.gotConfig.header.name,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", md: "6" } },
                              [
                                _c("v-card-title", [_vm._v("Header")]),
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      [
                                        _c(
                                          "v-card-text",
                                          { staticClass: "pb-0" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("editor.common.color")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-btn-toggle",
                                          {
                                            staticClass: "pl-4",
                                            attrs: {
                                              mandatory: "",
                                              borderless: "",
                                              color: "primary lighten-2",
                                            },
                                            model: {
                                              value: _vm.widgetheader.color,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.widgetheader,
                                                  "color",
                                                  $$v
                                                )
                                              },
                                              expression: "widgetheader.color",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-btn",
                                              { attrs: { value: "primary" } },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      left: "",
                                                      color:
                                                        _vm.getThemeColors
                                                          .primary.color,
                                                    },
                                                  },
                                                  [_vm._v(" stop_circle ")]
                                                ),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "editor.widget.primarycolor"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-btn",
                                              { attrs: { value: "secondary" } },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      left: "",
                                                      color:
                                                        _vm.getThemeColors
                                                          .secondary.color,
                                                    },
                                                  },
                                                  [_vm._v(" stop_circle ")]
                                                ),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "editor.widget.secondarycolor"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      [
                                        _c(
                                          "v-card-text",
                                          { staticClass: "pb-0" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "editor.common.brighness"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-btn-toggle",
                                          {
                                            staticClass: "pl-4",
                                            attrs: {
                                              mandatory: "",
                                              borderless: "",
                                              color: "primary lighten-2",
                                            },
                                            model: {
                                              value: _vm.widgetheader.contrast,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.widgetheader,
                                                  "contrast",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "widgetheader.contrast",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-btn",
                                              { attrs: { value: "dark" } },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      left: "",
                                                      color: "primary",
                                                    },
                                                  },
                                                  [_vm._v("stop_circle")]
                                                ),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "editor.common.dark"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-btn",
                                              { attrs: { value: "light" } },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      left: "",
                                                      color: "primary",
                                                    },
                                                  },
                                                  [_vm._v("panorama_fish_eye")]
                                                ),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "editor.common.light"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "pl-4 mt-4" },
                                  [
                                    _c("ContrastChecker", {
                                      staticClass: "mt-2",
                                      attrs: {
                                        colorA:
                                          _vm.getThemeColors[
                                            _vm.widgetheader.color
                                          ].color,
                                        colorB:
                                          _vm.widgetheader.contrast === "light"
                                            ? "#ffffff"
                                            : "#484848",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("v-row", [_c("v-col", [_c("v-divider")], 1)], 1),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "text-center align-self-center hidden-sm-and-down",
                                attrs: { cols: "12", md: "6" },
                              },
                              [
                                _c("WidgetPreview", {
                                  staticClass: "elevation-4",
                                  attrs: {
                                    name: _vm.gotConfig.operator.name,
                                    title: _vm.gotConfig.operator.titel,
                                    "message-contrast":
                                      _vm.usermessage.contrast,
                                    message:
                                      _vm.getThemeColors[_vm.usermessage.color]
                                        .color,
                                    "only-message": "",
                                    avatar: _vm.avatar,
                                    header:
                                      _vm.getThemeColors[_vm.widgetheader.color]
                                        .color,
                                    "header-contrast":
                                      _vm.widgetheader.contrast,
                                    headline: _vm.gotConfig.header.name,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "pt-2",
                                attrs: { cols: "12", md: "6" },
                              },
                              [
                                _c("v-card-title", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "editor.widget.elements.messages.title"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { staticClass: "pt-0" },
                                      [
                                        _c(
                                          "v-card-text",
                                          { staticClass: "pb-0" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("editor.common.color")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-btn-toggle",
                                          {
                                            staticClass: "pl-4",
                                            attrs: {
                                              mandatory: "",
                                              borderless: "",
                                              color: "primary lighten-2",
                                            },
                                            model: {
                                              value: _vm.usermessage.color,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.usermessage,
                                                  "color",
                                                  $$v
                                                )
                                              },
                                              expression: "usermessage.color",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-btn",
                                              { attrs: { value: "primary" } },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      left: "",
                                                      color:
                                                        _vm.getThemeColors
                                                          .primary.color,
                                                    },
                                                  },
                                                  [_vm._v(" stop_circle ")]
                                                ),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "editor.widget.primarycolor"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-btn",
                                              { attrs: { value: "secondary" } },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      left: "",
                                                      color:
                                                        _vm.getThemeColors
                                                          .secondary.color,
                                                    },
                                                  },
                                                  [_vm._v(" stop_circle ")]
                                                ),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "editor.widget.secondarycolor"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { staticClass: "pt-0" },
                                      [
                                        _c(
                                          "v-card-text",
                                          { staticClass: "pb-0" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "editor.common.brighness"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-btn-toggle",
                                          {
                                            staticClass: "pl-4",
                                            attrs: {
                                              mandatory: "",
                                              borderless: "",
                                              color: "primary lighten-2",
                                            },
                                            model: {
                                              value: _vm.usermessage.contrast,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.usermessage,
                                                  "contrast",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "usermessage.contrast",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-btn",
                                              { attrs: { value: "dark" } },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      left: "",
                                                      color: "primary",
                                                    },
                                                  },
                                                  [_vm._v("stop_circle")]
                                                ),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "editor.common.dark"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-btn",
                                              { attrs: { value: "light" } },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      left: "",
                                                      color: "primary",
                                                    },
                                                  },
                                                  [_vm._v("panorama_fish_eye")]
                                                ),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "editor.common.light"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "pl-4 mt-4" },
                                  [
                                    _c("ContrastChecker", {
                                      staticClass: "mt-2",
                                      attrs: {
                                        colorA:
                                          _vm.getThemeColors[
                                            _vm.usermessage.color
                                          ].color,
                                        colorB:
                                          _vm.usermessage.contrast === "light"
                                            ? "#ffffff"
                                            : "#484848",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("v-row", [_c("v-col", [_c("v-divider")], 1)], 1),
                        _vm.isRAG && _vm.gotConfig?.config?.aiIndicator
                          ? _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass:
                                      "text-center align-self-center hidden-sm-and-down",
                                    attrs: { cols: "12", md: "6" },
                                  },
                                  [
                                    _c("WidgetRAGPReview", {
                                      staticClass: "elevation-4 mx-auto",
                                      attrs: {
                                        avatar: _vm.avatar,
                                        "generated-text":
                                          _vm.gotConfig.config.aiIndicator
                                            .generated,
                                        "sources-text":
                                          _vm.gotConfig.config.aiIndicator
                                            .sources,
                                        "disclaimer-text":
                                          _vm.gotConfig.config.aiIndicator
                                            .disclaimer,
                                        "pdf-pages-text":
                                          _vm.gotConfig.config.aiIndicator
                                            .pages,
                                        enabled:
                                          _vm.gotConfig.config.aiIndicator
                                            .enabled,
                                        "message-contrast":
                                          _vm.usermessage.contrast,
                                        message:
                                          _vm.getThemeColors[
                                            _vm.usermessage.color
                                          ].color,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "pt-2",
                                    attrs: { cols: "12", md: "6" },
                                  },
                                  [
                                    _c("v-card-title", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "editor.widget.ragResponse.title"
                                          )
                                        )
                                      ),
                                    ]),
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "px-4" },
                                              [
                                                _c("v-switch", {
                                                  attrs: {
                                                    color: _vm.widgetActive
                                                      ? "green"
                                                      : "grey",
                                                    inset: "",
                                                    label: _vm.$t(
                                                      "editor.widget.ragResponse.indicatorLabel"
                                                    ),
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.gotConfig.config
                                                        .aiIndicator.enabled,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.gotConfig.config
                                                          .aiIndicator,
                                                        "enabled",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "gotConfig.config.aiIndicator.enabled",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c("v-text-field", {
                                              staticClass: "pl-4 pr-4",
                                              attrs: {
                                                disabled:
                                                  !_vm.gotConfig.config
                                                    .aiIndicator.enabled,
                                                outlined: "",
                                                label: _vm.$t(
                                                  "editor.widget.ragResponse.aiLabel"
                                                ),
                                              },
                                              model: {
                                                value:
                                                  _vm.gotConfig.config
                                                    .aiIndicator.generated,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.gotConfig.config
                                                      .aiIndicator,
                                                    "generated",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "gotConfig.config.aiIndicator.generated",
                                              },
                                            }),
                                            _c("v-text-field", {
                                              staticClass: "pl-4 pr-4",
                                              attrs: {
                                                disabled:
                                                  !_vm.gotConfig.config
                                                    .aiIndicator.enabled,
                                                outlined: "",
                                                label: _vm.$t(
                                                  "editor.widget.ragResponse.sourceLabel"
                                                ),
                                              },
                                              model: {
                                                value:
                                                  _vm.gotConfig.config
                                                    .aiIndicator.sources,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.gotConfig.config
                                                      .aiIndicator,
                                                    "sources",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "gotConfig.config.aiIndicator.sources",
                                              },
                                            }),
                                            _c("v-text-field", {
                                              staticClass: "pl-4 pr-4",
                                              attrs: {
                                                disabled:
                                                  !_vm.gotConfig.config
                                                    .aiIndicator.enabled,
                                                outlined: "",
                                                label: _vm.$t(
                                                  "editor.widget.ragResponse.pagesLabel"
                                                ),
                                              },
                                              model: {
                                                value:
                                                  _vm.gotConfig.config
                                                    .aiIndicator.pages,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.gotConfig.config
                                                      .aiIndicator,
                                                    "pages",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "gotConfig.config.aiIndicator.pages",
                                              },
                                            }),
                                            _c("v-text-field", {
                                              staticClass: "pl-4 pr-4",
                                              attrs: {
                                                disabled:
                                                  !_vm.gotConfig.config
                                                    .aiIndicator.enabled,
                                                outlined: "",
                                                label: _vm.$t(
                                                  "editor.widget.ragResponse.disclaimerLabel"
                                                ),
                                              },
                                              model: {
                                                value:
                                                  _vm.gotConfig.config
                                                    .aiIndicator.disclaimer,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.gotConfig.config
                                                      .aiIndicator,
                                                    "disclaimer",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "gotConfig.config.aiIndicator.disclaimer",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.isRAG && _vm.gotConfig?.config?.aiIndicator
                          ? _c("v-row", [_c("v-col", [_c("v-divider")], 1)], 1)
                          : _vm._e(),
                        _c(
                          "div",
                          [
                            _c("v-card-title", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("editor.widget.privacy.title")
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("v-card-subtitle", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("editor.widget.privacy.subTitle")
                                  ) +
                                  " "
                              ),
                            ]),
                          ],
                          1
                        ),
                        _vm.gotConfig
                          ? _c("WidgetPrivacy", {
                              attrs: {
                                avatar: _vm.avatar,
                                bgColor:
                                  _vm.getThemeColors[_vm.widgetheader.color]
                                    .color,
                                widgetTitle: _vm.gotConfig.header.name,
                                headerContrast: _vm.widgetheader.contrast,
                              },
                            })
                          : _vm._e(),
                        _c("v-row", [_c("v-col", [_c("v-divider")], 1)], 1),
                        _c("v-card-title", [
                          _vm._v(
                            _vm._s(_vm.$t("editor.widget.behavior.title"))
                          ),
                        ]),
                        _c("v-card-subtitle", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("editor.widget.behavior.description")
                              ) +
                              " "
                          ),
                        ]),
                        _c("WidgetRememberUser", {
                          attrs: { config: _vm.gotConfig.config },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _vm.isInitialized
        ? _c(
            "HubDialog",
            {
              attrs: {
                "primary-icon": "delete",
                "primary-label": _vm.$t("editor.common.discard"),
                modal: "",
                title: _vm.$t("editor.widget.unsaved.title"),
              },
              on: { onSecondary: _vm.lostCancel, onPrimary: _vm.lostOk },
              model: {
                value: _vm.lostDialog,
                callback: function ($$v) {
                  _vm.lostDialog = $$v
                },
                expression: "lostDialog",
              },
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("editor.widget.unsaved.description")) + " "
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }