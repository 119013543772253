<script>
export default {
  name: 'WidgetChatInput',
  props: {
    "qr": {
      type: Array,
      default: () => [],
    },
    "placeholder": {
      type: String,
      default: "Nachricht senden",
    }
  }
};
</script>

<template>
  <div class="reply-area">
    <!-- Input interactive area (quick-replies) -->
    <div v-if="qr.length" class="d-flex gap-1 px-2 pt-1 mb-1 justify-end">
      <span
        v-for="quickReply in qr"
        :key="quickReply"
        class="quick-reply"
      >
        {{ quickReply }}
      </span>
    </div>

    <!-- Input text area -->
    <div class="d-flex gap-1 pa-2">
      <span class="input-field">{{ placeholder }}</span>

      <svg
        width="38px"
        height="38px"
        viewBox="0 0 38 38"
        xmlns="http://www.w3.org/2000/svg"
      >
          <g id="button_send_new-copy"
             stroke="none"
             stroke-width="1"
             fill="none"
             fill-rule="evenodd"
          >
            <circle id="Sendbutton"
                    fill="#a9a9a9"
                    cx="19"
                    cy="19"
                    r="19"
            />
            <polygon id="Paperplane"
                     fill="#FFFFFF"
                     points="7.19273235 14.2191781
                            32.9594125 12 13.5085974 30 8.56247221 22.7710478 20.1374946
                            16.9315068 7.04058754 20.1077497"
            />
          </g>
      </svg>
    </div>
  </div>
</template>

<style scoped lang="scss">
.reply-area {
  background-color: #ececec;
}

.input-field {
  line-height: 1em;

  /* From the widget itself */
  border: 1px solid;
  border-color: var(--moin-textinput-textarea-borderColor, rgba(150, 150, 150, 0));
  background: #fff;
  resize: none;
  width: 100%;
  max-height: 85px;
  min-height: 0;
  font-family: "Rubik", Helvetica, Arial, sans-serif;
  font-size: 1.6em;
  color: #33333378;
  margin: 0 4px 0 0;
  padding: 10px 15px;
  border-radius: 20px;
  transition: border .15s, height .15s;
  outline: none !important;
}

.quick-reply {
  line-height: 2.5em;

  /* From the widget itself */
  background: #fff;
  border: 1px solid #E2E2E2;
  border-radius: 20px;
  height: auto;
  min-height: 35px;
  padding: 0 12px;
  margin-left: 3px;
  margin-bottom: 3px;
  font-family: "Rubik", Helvetica, Arial, sans-serif;
  font-size: 1.5em;
  background: var(--moin-quickreplybar-pill-background, #FFFFFF);
  color: var(--moin-quickreplybar-pill-textcolor, #333333);
  text-transform: none !important;
}
</style>