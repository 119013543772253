var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { staticStyle: { "max-height": "100vh", height: "100vh" } },
    [
      _c("HubMenu"),
      _c("Appbar"),
      _c("HubEditorFrame", {
        ref: "formEditorScrollContainer",
        attrs: {
          loading: _vm.isLoading,
          draft: _vm.contentChanged,
          back: "/forms",
          channels: _vm.channels,
          content: _vm.intentName,
          title: _vm.displayName,
          "sidebar-items": [
            {
              title: _vm.$t("forms.steps"),
              name: "steps",
              icon: "list_alt",
              padlessItem: true,
            },
            {
              title: _vm.$t("forms.analysis.title"),
              name: "insights",
              icon: "mdi-chart-timeline-variant-shimmer",
            },
          ],
          "sidebar-width": 4,
          disabled: !_vm.formValid,
        },
        on: {
          onChannelChanged: _vm.channelChanged,
          onReset: _vm.reset,
          onSave: _vm.save,
        },
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function ({ channelId, isLoading }) {
                return [
                  _c(
                    "div",
                    { key: _vm.intentName },
                    [
                      _c("BotFormEdit", {
                        key: _vm.version,
                        ref: "BotFomEdit",
                        attrs: {
                          loading: isLoading,
                          intent: _vm.intentName,
                          channel: channelId,
                          options: { template: "BotForm" },
                          draft: _vm.contentChanged,
                        },
                        on: {
                          setFormValid: function ($event) {
                            _vm.formValid = $event
                          },
                          onSave: _vm.save,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "back-button",
              fn: function () {
                return [
                  _c("span", [
                    _vm._v(" " + _vm._s(_vm.$t("forms.title")) + " "),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "toolbar",
              fn: function ({ disabled }) {
                return [
                  _c(
                    "v-tooltip",
                    {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({
                              on: onTooltip,
                              attrs: attrsTooltip,
                            }) {
                              return [
                                _c(
                                  "BtnSquare",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "ml-4",
                                        attrs: {
                                          disabled: disabled,
                                          icon: "view_sidebar",
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.sidebar = !_vm.sidebar
                                          },
                                        },
                                      },
                                      "BtnSquare",
                                      attrsTooltip,
                                      false
                                    ),
                                    onTooltip
                                  )
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.$t("forms.steps")))])]
                  ),
                ]
              },
            },
            {
              key: `sidebar.steps`,
              fn: function () {
                return [
                  _c(
                    "v-col",
                    { staticClass: "pa-0 ma-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "div",
                        { staticClass: "pa-6" },
                        [
                          _c(
                            "h2",
                            { staticClass: "headline primary--text mb-3" },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mr-2",
                                  attrs: {
                                    left: "",
                                    color: "primary lighten-3",
                                  },
                                },
                                [_vm._v(" list_alt ")]
                              ),
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("forms.steps"))),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "p",
                            { staticClass: "text-body-2 primary--text" },
                            [_vm._v(_vm._s(_vm.$t("forms.stepsInfo")))]
                          ),
                          _c(
                            "v-list",
                            {
                              staticClass: "mx-0 mt-0 pa-0",
                              attrs: { dense: "", "two-line": "" },
                            },
                            [
                              _vm._l(_vm.formSteps, function (step) {
                                return [
                                  _c(
                                    "div",
                                    { key: step.box },
                                    [
                                      _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.goto(step)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-list-item-icon",
                                            [
                                              step.template ===
                                              "BotFormFreeText"
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        color:
                                                          "primary lighten-3",
                                                      },
                                                    },
                                                    [_vm._v(" mdi-text ")]
                                                  )
                                                : _vm._e(),
                                              step.template === "BotFormYesNo"
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "rotate-180",
                                                      attrs: {
                                                        color:
                                                          "primary lighten-3",
                                                      },
                                                    },
                                                    [_vm._v(" mdi-call-split ")]
                                                  )
                                                : _vm._e(),
                                              step.template ===
                                                "BotFormMultipleChoice" ||
                                              step.template ===
                                                "BotFormConditionalSwitch"
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        color:
                                                          "primary lighten-3",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " mdi-format-list-bulleted-square "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              step.template ===
                                              "BotFormMultipleChoiceSlider"
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        color:
                                                          "primary lighten-3",
                                                      },
                                                    },
                                                    [_vm._v(" view_carousel ")]
                                                  )
                                                : _vm._e(),
                                              step.template === "BotFormEmail"
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        color:
                                                          "primary lighten-3",
                                                      },
                                                    },
                                                    [_vm._v(" mdi-email ")]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-content",
                                            { staticClass: "mt-0 pt-0" },
                                            [
                                              _c(
                                                "v-list-item-title",
                                                {
                                                  staticClass:
                                                    "pt-0 pb-1 font-weight-bold",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-truncate",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            step.displayName
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c("v-list-item-subtitle", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.stepNames(
                                                        step.template
                                                      ).name
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      step.template === "BotFormYesNo" ||
                                      step.template ===
                                        "BotFormMultipleChoice" ||
                                      step.template ===
                                        "BotFormMultipleChoiceSlider" ||
                                      step.template ===
                                        "BotFormConditionalSwitch"
                                        ? _c(
                                            "v-list-item",
                                            [
                                              _c(
                                                "v-list-item-content",
                                                { staticClass: "pt-0" },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "d-inline-block mx-0 mt-0 mb-2",
                                                    },
                                                    _vm._l(
                                                      _vm.answers(step),
                                                      function (answer, index) {
                                                        return _c(
                                                          "v-chip",
                                                          {
                                                            key: index,
                                                            staticClass:
                                                              "ml-2 mb-1",
                                                            attrs: {
                                                              outlined:
                                                                !_vm.active(
                                                                  step,
                                                                  answer
                                                                ),
                                                              color:
                                                                "primary lighten-2",
                                                              label: "",
                                                              small: "",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                                return _vm.changePath(
                                                                  step,
                                                                  answer
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.getLabel(
                                                                    answer.label,
                                                                    step
                                                                  ).data
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c("v-divider"),
                                    ],
                                    1
                                  ),
                                ]
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: `sidebar.insights`,
              fn: function () {
                return [
                  _c(
                    "div",
                    [
                      _c("BotFormsInsights", {
                        attrs: {
                          form: _vm.form,
                          channelId: _vm.getChannelIdByIndex(
                            _vm.selectedChannelIndex
                          ),
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
        model: {
          value: _vm.sidebar,
          callback: function ($$v) {
            _vm.sidebar = $$v
          },
          expression: "sidebar",
        },
      }),
      _c(
        "HubDialog",
        {
          attrs: {
            "primary-label": _vm.$t("common.ok"),
            "secondary-label": _vm.$t("common.cancel"),
            modal: "",
            title: _vm.$t("editor.dialogs.hint.title"),
          },
          on: { onSecondary: _vm.lostCancel, onPrimary: _vm.lostOk },
          model: {
            value: _vm.draftDialog,
            callback: function ($$v) {
              _vm.draftDialog = $$v
            },
            expression: "draftDialog",
          },
        },
        [
          _vm._v(" " + _vm._s(_vm.$t("editor.dialogs.hint.description")) + " "),
          _vm.channels.length > 1
            ? _c(
                "v-list",
                { staticClass: "mt-2", attrs: { dense: "" } },
                [
                  _c("v-subheader", [
                    _vm._v(_vm._s(_vm.$t("editor.dialogs.hint.channelchange"))),
                  ]),
                  _vm._l(_vm.channelContentChanged, function (channelChanged) {
                    return [
                      channelChanged.changed
                        ? _c(
                            "v-list-item",
                            { key: channelChanged.channelId },
                            [
                              _c(
                                "v-list-item-icon",
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        small: "",
                                        color: "secondary darken-2",
                                      },
                                    },
                                    [_vm._v("edit")]
                                  ),
                                ],
                                1
                              ),
                              _c("v-list-item-title", [
                                _vm._v(_vm._s(channelChanged.name)),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }