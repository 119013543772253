<script>
export default {
  name: 'WidgetRatingBar',
  props: {
    "rating": {
      type: Number,
      default: 4,
    },
    "rateLabel": {
      type: String,
      default: "Rate conversation",
    },
  },
};
</script>

<template>
  <transition name="slide-fade">
    <div class="knw_widget_ratingbar" >
      {{ rateLabel }}

      <div class="knw_widget_rating_stars">
        <span
          v-for="i in 5"
          :key="i"
          class="unstyled"
          :data-filled="rating >= i"
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z"/>
          </svg>
        </span>
      </div>
    </div>
  </transition>
</template>

<style scoped lang="scss">
.unstyled {
  background: none;
  border: none;
  padding: .5em .005em;
  margin: 0;
  display: flex;
}

.knw_widget_ratingbar {
  background: white;
  border-top: 1px solid #e0e0e0;
  display: flex;
  gap: 1em;
  flex-shrink: 1;
  min-height: 0;
  z-index: 1;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0;
  border-radius: 0 0 1em 1em;
  color: #222C50;
  font-size: 1.5em;
  box-shadow: 0 10px 20px -10px rgba(50, 50, 50, 0.1);
}

.knw_widget_rating_stars {
  display: flex;

  svg {
    fill: #A9A9A9; // Same as send button circle fill
  }
  span[data-filled="true"] svg {
    fill: #FF7555;
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}
.slide-fade-leave-active {
  transition: all 0.4s ease-in;
}

.slide-fade-leave-to,
.slide-fade-enter-from {
  opacity: 0;
  height: 0px;
}
.slide-fade-leave-from,
.slide-fade-enter-to {
  opacity: 1;
  height: 32px;
}

</style>