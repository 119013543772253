var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { staticStyle: { "max-height": "100vh", height: "100vh" } },
    [
      _c("HubMenu"),
      _c("Appbar"),
      _c("HubContent", {
        attrs: { isSidebarOpen: _vm.isSidebarOpen },
        scopedSlots: _vm._u([
          {
            key: "navbar",
            fn: function () {
              return [
                _c("HubContentNavbar", {
                  attrs: {
                    title: _vm.$t("settingsLivechat.livechatStats.title"),
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "toolbar",
                      fn: function () {
                        return [
                          _c("BtnSquare", {
                            attrs: { icon: "view_sidebar" },
                            on: {
                              click: () =>
                                (_vm.isSidebarOpen = !_vm.isSidebarOpen),
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "sidebar",
            fn: function () {
              return [
                _c(
                  "HubContentSidebarSimple",
                  {
                    attrs: { isOpen: _vm.isSidebarOpen },
                    on: { onClose: () => (_vm.isSidebarOpen = false) },
                  },
                  [_c("StatsOverviewSidebar")],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "v-container",
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "3" } },
                          [
                            _c("v-select", {
                              attrs: {
                                items: _vm.dropdownOptions,
                                "item-text": "label",
                                "item-value": "value",
                                label: _vm.$t(
                                  "settingsLivechat.livechatStats.timePeriod"
                                ),
                                outlined: "",
                              },
                              on: { change: _vm.onDateRangeChange },
                              model: {
                                value: _vm.selectedOption,
                                callback: function ($$v) {
                                  _vm.selectedOption = $$v
                                },
                                expression: "selectedOption",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("v-col", { attrs: { cols: "3" } }, [
                          _vm.showDates
                            ? _c(
                                "div",
                                { staticClass: "d-flex" },
                                [
                                  _c(
                                    "v-menu",
                                    {
                                      attrs: {
                                        "close-on-content-click": false,
                                        "nudge-right": 40,
                                        transition: "scale-transition",
                                        "offset-y": "",
                                        "min-width": "auto",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-text-field",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: {
                                                          label: _vm.$t(
                                                            "settingsLivechat.livechatStats.from"
                                                          ),
                                                          readonly: "",
                                                        },
                                                        model: {
                                                          value: _vm.fromDate,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.fromDate = $$v
                                                          },
                                                          expression:
                                                            "fromDate",
                                                        },
                                                      },
                                                      "v-text-field",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  )
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2334393504
                                      ),
                                      model: {
                                        value: _vm.datePickerA,
                                        callback: function ($$v) {
                                          _vm.datePickerA = $$v
                                        },
                                        expression: "datePickerA",
                                      },
                                    },
                                    [
                                      _c("v-date-picker", {
                                        attrs: {
                                          "allowed-dates": _vm.allowedDates,
                                        },
                                        on: {
                                          change: _vm.dateChanged,
                                          input: function ($event) {
                                            _vm.datePickerA = false
                                          },
                                        },
                                        model: {
                                          value: _vm.fromDate,
                                          callback: function ($$v) {
                                            _vm.fromDate = $$v
                                          },
                                          expression: "fromDate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-menu",
                                    {
                                      attrs: {
                                        "close-on-content-click": false,
                                        "nudge-right": 40,
                                        transition: "scale-transition",
                                        "offset-y": "",
                                        "min-width": "auto",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-text-field",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        staticClass: "ml-2",
                                                        attrs: {
                                                          label: _vm.$t(
                                                            "settingsLivechat.livechatStats.to"
                                                          ),
                                                          readonly: "",
                                                        },
                                                        model: {
                                                          value: _vm.toDate,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.toDate = $$v
                                                          },
                                                          expression: "toDate",
                                                        },
                                                      },
                                                      "v-text-field",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  )
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2103081982
                                      ),
                                      model: {
                                        value: _vm.datePickerB,
                                        callback: function ($$v) {
                                          _vm.datePickerB = $$v
                                        },
                                        expression: "datePickerB",
                                      },
                                    },
                                    [
                                      _c("v-date-picker", {
                                        attrs: {
                                          "allowed-dates": _vm.allowedDates,
                                        },
                                        on: {
                                          change: _vm.dateChanged,
                                          input: function ($event) {
                                            _vm.datePickerB = false
                                          },
                                        },
                                        model: {
                                          value: _vm.toDate,
                                          callback: function ($$v) {
                                            _vm.toDate = $$v
                                          },
                                          expression: "toDate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                        _c(
                          "v-col",
                          { attrs: { cols: "6" } },
                          [
                            _c("v-text-field", {
                              attrs: { label: _vm.$t("common.search") },
                              model: {
                                value: _vm.searchText,
                                callback: function ($$v) {
                                  _vm.searchText = $$v
                                },
                                expression: "searchText",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("v-data-table", {
                      attrs: {
                        headers: _vm.headers,
                        items: _vm.filteredChats,
                        "item-key": "chatId",
                        search: _vm.searchText,
                        loading: _vm.loading,
                        "show-group-by": "",
                        "hide-default-footer": true,
                        "items-per-page": -1,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "item.chatDuration",
                          fn: function ({ item }) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.formatDuration(item.chatDuration)
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                        {
                          key: "item.waitingTime",
                          fn: function ({ item }) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.formatDuration(item.waitingTime)) +
                                  " "
                              ),
                            ]
                          },
                        },
                        {
                          key: "item.agentName",
                          fn: function ({ item }) {
                            return [
                              _c("ProfileAvatar", {
                                attrs: {
                                  profilePicture: item.agentPicture,
                                  placeholderIcon: "mdi-face-agent",
                                  small: "",
                                },
                              }),
                              _c("span", { staticClass: "ml-1" }, [
                                _vm._v(_vm._s(item.agentName)),
                              ]),
                            ]
                          },
                        },
                        {
                          key: "group.header",
                          fn: function ({
                            items,
                            groupBy,
                            group,
                            isOpen,
                            toggle,
                            remove,
                          }) {
                            return [
                              _c(
                                "td",
                                { attrs: { colspan: _vm.headers.length - 1 } },
                                [
                                  _c("v-icon", { on: { click: toggle } }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          isOpen
                                            ? "mdi-menu-down"
                                            : "mdi-menu-right"
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  groupBy[0] === "agentName"
                                    ? _c("ProfileAvatar", {
                                        staticClass: "mr-1",
                                        attrs: {
                                          profilePicture: items[0].agentPicture,
                                          placeholderIcon: "mdi-face-agent",
                                          small: "",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(
                                    " " +
                                      _vm._s(group) +
                                      " (" +
                                      _vm._s(
                                        _vm.$t(
                                          "settingsLivechat.livechatStats.avgChatDuration"
                                        )
                                      ) +
                                      ": " +
                                      _vm._s(
                                        _vm.averageDurationForField(
                                          group,
                                          groupBy[0]
                                        )
                                      ) +
                                      ") "
                                  ),
                                  _c("v-icon", { on: { click: remove } }, [
                                    _vm._v("mdi-close"),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }