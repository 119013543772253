var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c(
        "HubBg",
        [
          _c(
            "HubPanelLogo",
            { attrs: { loading: _vm.authenticating } },
            [
              _c(
                "v-form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", [
                            _c("h1", { staticClass: "text-h5 mb-4" }, [
                              _vm._v(_vm._s(_vm.$t("login.title"))),
                            ]),
                            _c("p", { staticClass: "text-body-1" }, [
                              _vm._v(
                                " " + _vm._s(_vm.$t("login.subTitle")) + " "
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  outlined: "",
                                  label: _vm.$t("login.username"),
                                  name: "login",
                                  type: "text",
                                  "data-cy": "login-username",
                                  "hide-details": "",
                                },
                                on: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    $event.preventDefault()
                                    return _vm.handleSubmit()
                                  },
                                },
                                model: {
                                  value: _vm.username,
                                  callback: function ($$v) {
                                    _vm.username = $$v
                                  },
                                  expression: "username",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  outlined: "",
                                  id: "password",
                                  label: _vm.$t("login.password"),
                                  name: "password",
                                  type: "password",
                                  "data-cy": "login-password",
                                  "hide-details": "",
                                },
                                on: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    $event.preventDefault()
                                    return _vm.handleSubmit()
                                  },
                                },
                                model: {
                                  value: _vm.password,
                                  callback: function ($$v) {
                                    _vm.password = $$v
                                  },
                                  expression: "password",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-6",
                              attrs: { cols: "12", sm: "6" },
                            },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "grey--text",
                                  attrs: { to: "/password" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("login.lostPassword")) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "text-right pt-6",
                              attrs: { cols: "12", sm: "6" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    depressed: "",
                                    color: "primary",
                                    large: "",
                                    loading: _vm.authenticating,
                                    "data-cy": "login",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleSubmit()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("login.btnLabel")) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm.authenticationError
                            ? _c(
                                "v-col",
                                [
                                  _c(
                                    "v-alert",
                                    { attrs: { outlined: "", type: "error" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("login.errorLogin")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-container", [
                _c(
                  "div",
                  { staticClass: "d-flex gap-2 items-center py-4" },
                  [
                    _c("v-divider"),
                    _c("span", { staticClass: "text-body-1" }, [
                      _vm._v(_vm._s(_vm.$t("common.or"))),
                    ]),
                    _c("v-divider"),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "d-flex flex-column justify-center gap-3" },
                  [_c("SSOLoginButton", { attrs: { provider: "microsoft" } })],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }