var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fill-height" },
    [
      _c(
        "v-card",
        { staticClass: "fill-height pa-4", attrs: { outlined: "" } },
        [
          _c("h2", { staticClass: "text-h6" }, [
            _vm._v(
              _vm._s(_vm.trainingData.length) +
                " neue Varianten (letzten 30 Tagen)"
            ),
          ]),
          _c("v-divider", { staticClass: "my-2" }),
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.trainingData,
              "sort-by": "item.createdAt",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: `item.name`,
                  fn: function ({ item }) {
                    return [
                      _vm._v(
                        " " + _vm._s(_vm.displayName(item.name, "de")) + " "
                      ),
                    ]
                  },
                },
                {
                  key: `item.createdBy.user`,
                  fn: function ({ item }) {
                    return [
                      _vm._v(
                        " " + _vm._s(_vm.shortenUser(item.createdBy)) + " "
                      ),
                    ]
                  },
                },
                {
                  key: `item.createdAt`,
                  fn: function ({ item }) {
                    return [
                      _vm._v(
                        " " + _vm._s(_vm.getDateFormated(item.createdAt)) + " "
                      ),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }