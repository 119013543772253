var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c("HubMenu"),
      _c("Appbar"),
      this.code
        ? _c(
            "v-main",
            { staticClass: "grey lighten-2" },
            [
              _c("p", { staticClass: "text-h5 mt-4 ml-4" }, [_vm._v("OAuth")]),
              _c(
                "v-col",
                [
                  _c(
                    "v-card",
                    [
                      _c("v-card-title", [
                        _vm._v(
                          _vm._s(
                            this.provider
                              ? `${`${this.provider[0]}`.toUpperCase()}${this.provider.slice(
                                  1
                                )} `
                              : ""
                          ) + " authorized via OAuth"
                        ),
                      ]),
                      _c("v-col", [_vm._v("Code: " + _vm._s(this.code))]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "v-main",
            { staticClass: "grey lighten-2" },
            [
              _c("p", { staticClass: "text-h5 mt-4 ml-4" }, [_vm._v("Tools")]),
              this.provider === "zendesk"
                ? _c("v-col", [_c("ZendeskAuth")], 1)
                : _vm._e(),
              this.provider === "facebook"
                ? _c("v-col", [_c("FacebookAuth")], 1)
                : _vm._e(),
              this.provider === "hubspot"
                ? _c("v-col", [_c("HubspotAuth")], 1)
                : _vm._e(),
              this.provider === "livechat"
                ? _c(
                    "v-col",
                    [
                      _c("LiveChatAuth", {
                        attrs: { "client-id": _vm.clientId },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              this.provider === "shopware"
                ? _c("v-col", [_c("ShopwareAuth")], 1)
                : _vm._e(),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }