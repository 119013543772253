var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { staticStyle: { "max-height": "100vh", height: "100vh" } },
    [
      _c("HubMenu"),
      _c("Appbar"),
      _vm.channel
        ? _c(
            "HubEditorFrame",
            {
              ref: "hubeditor",
              attrs: {
                title: _vm.channel.displayName,
                loading: _vm.isLoading,
                draft: _vm.isDirty,
                saveIcon: "cloud_upload",
                back: "/settings/channels",
                "sidebar-items": [{ title: "", icon: "info", name: "info" }],
              },
              on: { onReset: _vm.resetChannel, onSave: _vm.saveChannel },
              model: {
                value: _vm.isSidebarOpen,
                callback: function ($$v) {
                  _vm.isSidebarOpen = $$v
                },
                expression: "isSidebarOpen",
              },
            },
            [
              _c(
                "v-container",
                { staticClass: "white pa-6 pb-12", attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            staticClass: "mt-8",
                            attrs: {
                              label: _vm.$t(
                                "channelManagement.createDialog.nameLabel"
                              ),
                              type: "text",
                            },
                            model: {
                              value: _vm.displayNameInput,
                              callback: function ($$v) {
                                _vm.displayNameInput = $$v
                              },
                              expression: "displayNameInput",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-select", {
                            attrs: {
                              outlined: "",
                              label: _vm.$t(
                                "channelManagement.createDialog.typeLabel"
                              ),
                              items: _vm.typeOptions,
                              "item-text": "text",
                              "item-value": "value",
                              dense: "",
                              "hide-details": "",
                            },
                            model: {
                              value: _vm.selectedType,
                              callback: function ($$v) {
                                _vm.selectedType = $$v
                              },
                              expression: "selectedType",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-select", {
                            attrs: {
                              outlined: "",
                              label: _vm.$t("common.language"),
                              items: _vm.langOptions,
                              "item-text": "text",
                              "item-value": "value",
                              dense: "",
                              "hide-details": "",
                            },
                            model: {
                              value: _vm.selectedLang,
                              callback: function ($$v) {
                                _vm.selectedLang = $$v
                              },
                              expression: "selectedLang",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }