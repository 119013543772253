<script>
import BtnSquare from '@/components/common/BtnSquare.vue';
import WelcomeInfoTeaser from '@/components/stats/components/WelcomeInfoTeaser.vue';
import StatsChangeIndicator from '@/components/welcome/StatsChangeIndicator.vue';
import IconNumberTeaser from '@/components/stats/components/IconNumberTeaser.vue';
import { mapGetters } from 'vuex';
import { stageTypes } from '@/store/modules/welcome';
import { formatNumberFixed } from '@/utils/formatNumber';

export default {
  name: 'WelcomeUsageStats',
  components: {
    IconNumberTeaser,
    StatsChangeIndicator,
    WelcomeInfoTeaser,
    BtnSquare,
  },
  data() {
    return {
      stageTypes,
      // Fake stats displayed for stageTypes other than ONBOARDING
      fake: {
        StatsChartFeedbackPos: {
          old: 223,
          new: 278,
          isLoading: false,
          isFailed: false,
        },
        StatsChartFeedbackNeg: {
          old: 184,
          new: 151,
          isLoading: false,
          isFailed: false,
        },
        StatsChartConversations: {
          old: 1385,
          new: 1453,
          isLoading: false,
          isFailed: false,
        },
        StatsChartAutomation: {
          old: 72,
          new: 76,
          isLoading: false,
          isFailed: false,
        },
      }
    };
  },
  computed: {
    ...mapGetters('bots', [
      'viewableBotId',
      'currentBotStage',
    ]),
    ...mapGetters('welcome', [
      'StatsChartFeedbackPos',
      'StatsChartFeedbackNeg',
      'StatsChartConversations',
      'StatsChartAutomation',
      'statsDatePeriod',
    ]),
    datePeriods() {
      return [
        { label: this.$t('welcome.datePeriods.months3'), value: 90 },
        { label: this.$t('welcome.datePeriods.weeks4'), value: 28 },
        { label: this.$t('welcome.datePeriods.week1'), value: 7 },
      ];
    },
    isFake() {
      return this.currentBotStage !== stageTypes.LIVE;
    },
    stats() {
      if (!this.isFake) {
        return {
          StatsChartFeedbackPos: this.StatsChartFeedbackPos,
          StatsChartFeedbackNeg: this.StatsChartFeedbackNeg,
          StatsChartConversations: this.StatsChartConversations,
          StatsChartAutomation: this.StatsChartAutomation,
        }
      }

      // for stageTypes.ONBOARDING and any other unexpected stages
      return this.fake;
    }
  },
  methods: {
    formatNumberFixed,
    onChangePeriod(periodDays) {
      this.$store.commit('welcome/setStatsLoading');
      this.$store.commit('welcome/setStatsDatePeriod', periodDays);
      this.fetchStats();
    },
    async fetchStats() {
      if (!this.isFake) {
        return await this.$store.dispatch('welcome/fetchStatsLive', {
          botId: this.viewableBotId,
        });
      }

      // No need to fetch stats for fake data
    },
  },
  async mounted() {
    this.$store.commit('welcome/setStatsLoading');
    await this.fetchStats();
  },
};
</script>

<template>
  <div>
    <!-- Title / Header -->
    <div
      class="mb-4 d-flex align-center primary--text text--lighten-2 justify-space-between"
    >
      <div class="d-flex">
        <v-icon color="primary lighten-2" left>speed</v-icon>
        <h2 class="text-h6">{{ $t('welcome.stats.title') }}</h2>
      </div>

      <div class="d-flex gap-2 items-center">
        <v-select
          class="mt-n4"
          :items="datePeriods"
          :value="statsDatePeriod"
          @change="onChangePeriod"
          item-value="value"
          item-text="label"
          hide-details
          :disabled="isFake"
        >
          <template v-slot:prepend-inner>
            <v-icon color="primary lighten-4">mdi-calendar</v-icon>
          </template>
        </v-select>

        <BtnSquare
          icon="show_chart"
          :to="{name: 'statistics'}"
          outlined
          :disabled="isFake"
        />
      </div>
    </div>

    <!-- Fake data warning -->
    <v-alert
      v-if="isFake"
      type="info"
      outlined
      class="mb-4"
    >
      {{ $t('welcome.stats.fakeDataWarning') }}
    </v-alert>

    <!-- Main content -->
    <div class="d-flex gap-4 mb-4" :class="{ 'fake-data-overlay': isFake }">
      <!-- Total Conversations -->
      <WelcomeInfoTeaser
        class="column"
        :title="$t('welcome.stats.conversations')"
        icon="forum"
        :value="`${formatNumberFixed(stats.StatsChartConversations.new)}`"
        :loading="!isFake && stats.StatsChartConversations.isLoading"
        :noData="!isFake && stats.StatsChartConversations.isFailed"
      >
        <StatsChangeIndicator
          v-if="isFake || !stats.StatsChartConversations.isFailed"
          :newValue="stats.StatsChartConversations.new"
          :oldValue="stats.StatsChartConversations.old"
          :loading="!isFake && stats.StatsChartConversations.isLoading"
        />
      </WelcomeInfoTeaser>

      <!-- Automation rate -->
      <WelcomeInfoTeaser
        class="column"
        :title="$t('welcome.stats.automations')"
        icon="model_training"
        :value="`${formatNumberFixed(stats.StatsChartAutomation.new)}%`"
        :noData="!isFake && stats.StatsChartAutomation.isFailed"
        :loading="!isFake && stats.StatsChartAutomation.isLoading"
      >
        <StatsChangeIndicator
          v-if="isFake || !stats.StatsChartAutomation.isFailed"
          :newValue="stats.StatsChartAutomation.new"
          :oldValue="stats.StatsChartAutomation.old"
          :loading="!isFake && stats.StatsChartConversations.isLoading"
          isPercent
        />
      </WelcomeInfoTeaser>

      <v-divider vertical />

      <!-- Positive feedback -->
      <WelcomeInfoTeaser
        class="column"
        :title="$t('welcome.stats.feedbackPositive')"
        icon="thumb_up"
        :value="stats.StatsChartFeedbackPos.new"
        :loading="!isFake && stats.StatsChartFeedbackPos.isLoading"
        :noData="!isFake && stats.StatsChartFeedbackPos.isFailed"
      >
        <StatsChangeIndicator
          v-if="isFake || !stats.StatsChartFeedbackPos.isFailed"
          :newValue="stats.StatsChartFeedbackPos.new"
          :oldValue="stats.StatsChartFeedbackPos.old"
          :loading="!isFake && stats.StatsChartFeedbackPos.isLoading"
        />
      </WelcomeInfoTeaser>

      <!-- Negative Feedback -->
      <WelcomeInfoTeaser
        class="column"
        :title="$t('welcome.stats.feedbackNegative')"
        icon="thumb_down"
        :value="stats.StatsChartFeedbackNeg.new"
        :loading="!isFake && stats.StatsChartFeedbackNeg.isLoading"
        :noData="!isFake && stats.StatsChartFeedbackNeg.isFailed"
      >
        <StatsChangeIndicator
          v-if="isFake || !stats.StatsChartFeedbackNeg.isFailed"
          :newValue="stats.StatsChartFeedbackNeg.new"
          :oldValue="stats.StatsChartFeedbackNeg.old"
          :loading="!isFake && stats.StatsChartFeedbackNeg.isLoading"
          less-is-better
        />
      </WelcomeInfoTeaser>
    </div>
  </div>
</template>

<style scoped lang="scss">
.column {
  flex-basis: 25%;
  text-align: center;
}

// Add a semi-transparent overlay to the stats when using fake data
.fake-data-overlay {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5);
    pointer-events: none;
  }
}
</style>