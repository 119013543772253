var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "DemoStep",
    { attrs: { slug: "stepIntents" } },
    [
      _c("div", { staticClass: "question-item d-flex flex-column" }, [
        _c(
          "div",
          { staticClass: "header-container d-flex align-items-center" },
          [
            _c("v-icon", { attrs: { color: "primary lighten-3" } }, [
              _vm._v("mdi-check"),
            ]),
            _c(
              "div",
              {
                staticClass: "font-weight-bold text-body-1 primary--text ml-2",
              },
              [_vm._v(" redaktionelle Antworten ")]
            ),
          ],
          1
        ),
        _c("div", { staticClass: "text-body-2 grey--text mt-2" }, [
          _vm._v(
            " Mit redaktionellen Antworten können Sie Ihren Nutzern in vordefinierten Dialogen antworten. Damit behalten Sie die volle Kontrolle. "
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " Empfohlene Themen für redaktionelle Antworten für Ihre Webseite: "
          ),
        ]),
        _c(
          "ul",
          { staticClass: "product-advisors-list mt-2" },
          _vm._l(_vm.features.editorialTopics, function (topic) {
            return _c("li", { key: topic }, [_vm._v(_vm._s(topic))])
          }),
          0
        ),
      ]),
      _c("div", { staticClass: "question-item d-flex flex-column mt-3" }, [
        _c(
          "div",
          { staticClass: "header-container d-flex align-items-center" },
          [
            _c("v-icon", { attrs: { color: "primary lighten-3" } }, [
              _vm._v("mdi-check"),
            ]),
            _c(
              "div",
              {
                staticClass: "font-weight-bold text-body-1 primary--text ml-2",
              },
              [_vm._v(" Knowledge Base ")]
            ),
          ],
          1
        ),
        _c("div", { staticClass: "text-body-2 grey--text mt-2" }, [
          _vm._v(
            " Mit der Knowledge Base und generativer KI kann der Chatbot auf Basis von Webseiten, Dokumenten und Wissensdatenbanken passend zur Anfrage des Nutzers die richtige Antwort geben. "
          ),
          _c("br"),
          _c("br"),
          _vm._v(" Eine generierte Antwort auf Basis Ihrer Webseite: "),
        ]),
        _c("div", { staticClass: "example-request mt-2" }, [
          _c("div", { staticClass: "request" }, [
            _c("strong", [_vm._v("Anfrage:")]),
            _vm._v(' "' + _vm._s(_vm.features.answer.query) + '" '),
          ]),
          _c("div", { staticClass: "response mt-1" }, [
            _c("strong", [_vm._v("Antwort:")]),
            _vm._v(' "' + _vm._s(_vm.features.answer.answer) + '" '),
          ]),
        ]),
      ]),
      _vm.features.product_advisor
        ? _c(
            "div",
            {
              staticClass:
                "question-item d-flex flex-column mt-3 highlight-item",
            },
            [
              _c(
                "div",
                { staticClass: "header-container d-flex align-items-center" },
                [
                  _c("v-icon", { attrs: { color: "#8046eb" } }, [
                    _vm._v("mdi-check"),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass:
                        "font-weight-bold text-body-1 primary--text ml-2",
                    },
                    [_vm._v(" moinAI Produktberater ")]
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "text-body-2 grey--text mt-2" }, [
                _vm._v(
                  " Mit dem moinAI Produktberater können Sie Ihren Nutzern eine interaktive Beratung anbieten, um das passende Produkt zu empfehlen. Fügen Sie für jede Ihrer Produktkategorien einen Berater hinzu. "
                ),
                _c("br"),
                _vm._v(" "),
                _c("br"),
                _vm._v(
                  "Importieren Sie einfach Ihre Produktkategorien und Produkte und lassen Sie moinAI den Rest erledigen. "
                ),
                _c("br"),
                _c("br"),
                _vm._v(" Empfohlene Produktberater für Ihre Webseite: "),
              ]),
              _c(
                "ul",
                { staticClass: "product-advisors-list mt-2" },
                _vm._l(_vm.features.product_advisors, function (advisors) {
                  return _c("li", { key: advisors }, [_vm._v(_vm._s(advisors))])
                }),
                0
              ),
            ]
          )
        : _vm._e(),
      _vm.features.livechat
        ? _c("div", { staticClass: "question-item d-flex flex-column mt-3" }, [
            _c(
              "div",
              { staticClass: "header-container d-flex align-items-center" },
              [
                _c("v-icon", { attrs: { color: "primary lighten-3" } }, [
                  _vm._v("mdi-check"),
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "font-weight-bold text-body-1 primary--text ml-2",
                  },
                  [_vm._v(" moinAI Livechat ")]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "text-body-2 grey--text mt-2" }, [
              _vm._v(
                " Mit dem moinAI Livechat können Sie Ihren Nutzern einen direkten Kontakt mit Ihren Mitarbeitern ermöglichen. Der Chatbot kann die Übergabe dann einleiten, wenn es Sinnvoll ist. So sparen Sie wertvolle Zeit ihrere Mitarbeiter. "
              ),
            ]),
          ])
        : _vm._e(),
      _c("DemoBtns", {
        on: {
          onBack: function ($event) {
            return _vm.back()
          },
          onNext: function ($event) {
            return _vm.next()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }