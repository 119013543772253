var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "HubBox",
    {
      attrs: {
        title: _vm.$t("intents.tags.title"),
        icon: "",
        boxId: "intents-tags",
        expandedInitial: "",
      },
      on: {
        onExpand: (isExpanded) => isExpanded && _vm.trackIt("hub-box-expanded"),
      },
    },
    [
      _c("p", { staticClass: "text-body-2" }, [
        _vm._v(" " + _vm._s(_vm.$t("intents.tags.description")) + " "),
      ]),
      _c("v-combobox", {
        attrs: {
          items: _vm.allExistingTags,
          chips: "",
          outlined: "",
          label: _vm.$t("intents.tags.tagSearchLabel"),
          multiple: "",
          disabled: _vm.maxTagCountReached,
          hint: _vm.$t("intents.tags.maxHint", { max: _vm.maxTagsPer }),
          "menu-props": { closeOnContentClick: true, closeOnClick: true },
          "persistent-hint": "",
          maxlength: "32",
        },
        on: { blur: _vm.forceSave },
        scopedSlots: _vm._u(
          [
            {
              key: "selection",
              fn: function ({ attrs, item, select, selected }) {
                return [
                  _c(
                    "v-chip",
                    _vm._b(
                      {
                        attrs: { "input-value": selected, close: "" },
                        on: {
                          click: select,
                          "click:close": function ($event) {
                            return _vm.remove(item)
                          },
                        },
                      },
                      "v-chip",
                      attrs,
                      false
                    ),
                    [_vm._v(" " + _vm._s(item) + " ")]
                  ),
                ]
              },
            },
            {
              key: `no-data`,
              fn: function () {
                return [
                  _c("p", { staticClass: "px-4" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("intents.tags.notFound")) +
                        " " +
                        _vm._s(_vm.$t("intents.tags.createHint.press")) +
                        " "
                    ),
                    _c("kbd", [
                      _vm._v(_vm._s(_vm.$t("intents.tags.createHint.enter"))),
                    ]),
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("intents.tags.createHint.toCreate")) +
                        " "
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
        model: {
          value: _vm.modifiedTags,
          callback: function ($$v) {
            _vm.modifiedTags = $$v
          },
          expression: "modifiedTags",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }