<template>
  <v-app style="max-height: 100vh; height: 100vh">
    <HubMenu />
    <Appbar />
    <HubEditorFrame
      ref="hubeditor"
      v-model="isSidebarOpen"
      :title="$t('botSettings.privacy.title')"
      :loading="isLoading"
      :draft="isChanged"
      saveIcon="cloud_upload"
      @onReset="reset"
      @onSave="save"
      back="/settings"
      :sidebar-items="[{ title: '', icon: 'info', name: 'info' }]"
    >
      <template v-slot="{ isLoading }">
        <v-container fluid class="white pa-6">
          <v-row>
            <v-col cols="12" lg="6" xl="4">
              <h2 class="text-h6">
                {{ $t('botSettings.keywordFeature.title') }}
              </h2>
              <div class="text-body-2">
                {{ $t('botSettings.keywordFeature.description') }}
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-switch
                v-model="isKeywordsActive"
                :label="$t('botSettings.keywordFeature.labelActive')"
                color="green"
                inset
                dense
                :disabled="isLoading"
              />
              <InfoBox
                class="my-4"
                :text="$t('botSettings.keywordFeature.infoBox')"
              />
            </v-col>
          </v-row>
        </v-container>
        <v-divider />
        <v-container fluid class="white pa-6">
          <v-row>
            <v-col cols="12" lg="6" xl="4">
              <h2 class="text-h6">
                {{ $t('botSettings.privacy.deleteConversations.title') }}
              </h2>
              <div class="text-body-2">
                {{ $t('botSettings.privacy.deleteConversations.description') }}
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-select
                v-model="selectedConversationsOption"
                :label="
                  $t('botSettings.privacy.deleteConversations.selectLabel')
                "
                :items="optionsConversations"
                item-text="title"
                item-value="value"
                prepend-inner-icon="mdi-delete-clock-outline"
                :disabled="isLoading"
                @change="changeConversationsSettings()"
              />
              <InfoBox
                v-if="conversationsOptionIsSmaller"
                iconColor="warning"
                class="mb-6"
                :text="
                  $t('botSettings.privacy.deleteConversations.warning', {
                    x: selectedConversationsOption,
                  })
                "
              />
            </v-col>
          </v-row>
        </v-container>
        <v-divider />
        <v-container fluid class="white pa-6" v-if="isRAG">
          <v-row>
            <v-col cols="12" lg="6" xl="4">
              <h2 class="text-h6">Knowledge Base</h2>
                <p v-if="fallbackFaqIntent === null" class="text-body-2">
                {{ $t('botSettings.knowledgeBase.notSetUp') }}
                </p>
                <p v-else class="text-body-2">
                {{ $t('botSettings.knowledgeBase.activate') }}
                </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <template v-if="fallbackFaqIntent === null">
                <BtnSquare
                  preset="default"
                  @click="setKnowledgeBase"
                  :disabled="isLoading"
                >
                  Setup
                </BtnSquare>
              </template>
                <template v-else>
                <v-switch
                  v-model="fallbackFaqIntentEdited"
                  :label="$t('botSettings.knowledgeBase.defaultActive')"
                  color="green"
                  inset
                  dense
                  :readonly="fallbackFaqIntentEdited && !isChanged"
                  :disabled="isLoading"
                />
                </template>
            </v-col>
          </v-row>
        </v-container>
        <template v-if="highPrivacy || isAdmin">
          <v-divider />
          <v-container fluid class="white pa-6">
            <v-row>
              <v-col cols="12" lg="6" xl="4">
                <h2 class="text-h6">
                  {{ $t('botSettings.highPrivacy.title') }}
                </h2>
                <div class="text-body-2">
                  {{ $t('botSettings.highPrivacy.description') }}
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-switch
                  :input-value="isHighPrivacyActiveLocal"
                  :label="$t('botSettings.highPrivacy.switchLabel')"
                  color="green"
                  inset
                  dense
                  @change="isHighPrivacyActiveLocal = $event"
                  :readonly="!isAdmin"
                  :disabled="isLoading"
                />
                <div v-if="isAdmin" class="text-body-2 admin--text">
                  (Änderung nur als Admin möglich)
                </div>
              </v-col>
            </v-row>
            <v-card v-if="isAdmin && highPrivacy" outlined class="mt-6 pa-4">
              <v-row>
                <v-col>
                  <div class="text-body-2">
                    <span class="admin--text">vocabId:</span>
                    {{ highPrivacy.vocabId }}
                  </div>
                  <div class="text-body-2">
                    <span class="admin--text">language:</span>
                    {{ highPrivacy.language }}
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-container>
        </template>
        <SnackbarStack :scopes="['SettingsPrivacy']" />
      </template>
      <template #[`sidebar.info`]>
        <div class="pa-4">
          <HubBoxSimple
            :title="$t('botSettings.privacy.deleteConversations.title')"
            icon="mdi-delete-clock-outline"
            class="mb-4"
          >
            <template v-slot:content>
              <p class="text-body-1">
                {{
                  $t('botSettings.privacy.deleteConversations.infoDescription')
                }}
              </p>
            </template>
          </HubBoxSimple>
        </div>
      </template>
      <template v-slot:toolbar>
        <v-tooltip bottom>
          <template v-slot:activator="{ on: onTooltip, attrs: attrsTooltip }">
            <span v-bind="attrsTooltip" v-on="onTooltip">
              <BtnSquare
                icon="info_outline"
                @click="isSidebarOpen = !isSidebarOpen"
              />
            </span>
          </template>
          <span>{{ $t('common.moreInfo') }}</span>
        </v-tooltip>
      </template>
      <template #[`saveDialog.content`]>
        <InfoBox
          v-if="conversationsOptionIsSmaller"
          iconColor="warning"
          class="mt-6 mb-6"
          :text="
            $t('botSettings.privacy.deleteConversations.warning', {
              x: selectedConversationsOption,
            })
          "
        />
      </template>
    </HubEditorFrame>
    <HubDialog
      v-model="isLostDialogOpen"
      primary-icon="delete"
      :primary-label="$t('editor.common.discard')"
      modal
      :title="$t('editor.widget.unsaved.title')"
      @onSecondary="lostCancel"
      @onPrimary="lostOk"
    >
      {{ $t('editor.widget.unsaved.description') }}
    </HubDialog>
  </v-app>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import HubMenu from '@/components/hub/HubMenu.vue';
import Appbar from '@/components/Appbar.vue';
import HubEditorFrame from '../components/hub/HubEditorFrame.vue';
import HubDialog from '@/components/hub/HubDialog.vue';
import HubBoxSimple from '@/components/hub/HubBoxSimple.vue';
import BtnSquare from '@/components/common/BtnSquare.vue';
import InfoBox from '@/components/common/InfoBox.vue';
import BotService from '@/services/bot';
import { TrackingService } from '@/services/tracking';
import RAGServiceV2 from '@/services/rag_v2';
import SnackbarStack from '@/components/tools/SnackbarStack.vue';

const conversationsOptionTypes = {
  DAYS30: 30,
  DAYS60: 60,
  DAYS90: 90,
  DAYS120: 120,
  DAYS180: 180,
  YEAR: 365,
  NEVER: 0,
};

export default {
  name: 'SettingsPrivacy',
  components: {
    SnackbarStack,
    HubMenu,
    Appbar,
    HubEditorFrame,
    HubBoxSimple,
    HubDialog,
    BtnSquare,
    InfoBox,
  },
  props: [],
  data() {
    return {
      isLoading: false,
      isSidebarOpen: false,
      isLostDialogOpen: false,
      selectedConversationsOptionInitial: conversationsOptionTypes.NEVER,
      selectedConversationsOption: conversationsOptionTypes.NEVER,
      conversationsSettings: {
        active: true,
        days: conversationsOptionTypes.NEVER,
      },
      nextUrl: null,
      isKeywordsActiveInitial: false,
      isKeywordsActive: false,
      highPrivacy: null,
      isHighPrivacyActiveLocal: false,
      fallbackFaqIntentEdited: null,
    };
  },

  computed: {
    ...mapGetters('bots', [
      'hasWidget',
      'currentBotId',
      'currentBot',
      'getBotSettings',
      'hasKeywordMatchFeature',
    ]),
    ...mapGetters('auth', ['isAdmin', 'isRAG']),
    ...mapGetters('intents', ['fallbackFaqIntent', 'fallbackFaqIntentEnabled']),
    conversationsOptionIsSmaller() {
      if (
        this.selectedConversationsOptionInitial >
          this.selectedConversationsOption &&
        this.selectedConversationsOption > 0
      ) {
        return true;
      }

      if (
        this.selectedConversationsOptionInitial === 0 &&
        this.selectedConversationsOption > 0
      ) {
        return true;
      }

      return false;
    },
    optionsConversations() {
      return [
        {
          title: this.$t(
            'botSettings.privacy.optionsConversations.afterXDays',
            { days: String(conversationsOptionTypes.DAYS30) }
          ),
          value: conversationsOptionTypes.DAYS30,
        },
        {
          title: this.$t(
            'botSettings.privacy.optionsConversations.afterXDays',
            { days: String(conversationsOptionTypes.DAYS60) }
          ),
          value: conversationsOptionTypes.DAYS60,
        },
        {
          title: this.$t(
            'botSettings.privacy.optionsConversations.afterXDays',
            { days: String(conversationsOptionTypes.DAYS90) }
          ),
          value: conversationsOptionTypes.DAYS90,
        },
        {
          title: this.$t(
            'botSettings.privacy.optionsConversations.afterXDays',
            { days: String(conversationsOptionTypes.DAYS120) }
          ),
          value: conversationsOptionTypes.DAYS120,
        },
        {
          title: this.$t(
            'botSettings.privacy.optionsConversations.afterXDays',
            { days: String(conversationsOptionTypes.DAYS180) }
          ),
          value: conversationsOptionTypes.DAYS180,
        },
        {
          title: this.$t(
            'botSettings.privacy.optionsConversations.after1Year',
            { days: String(conversationsOptionTypes.YEAR) }
          ),
          value: conversationsOptionTypes.YEAR,
        },
        {
          title: this.$t('botSettings.privacy.optionsConversations.never'),
          value: conversationsOptionTypes.NEVER,
        },
      ];
    },
    isChanged() {
      if (
        this.isAdmin &&
        this.isHighPrivacyActiveLocal &&
        !this.highPrivacy?.active
      ) {
        return true;
      }

      if (
        this.highPrivacy &&
        this.isHighPrivacyActiveLocal !== this.highPrivacy?.active
      ) {
        return true;
      }

      if (
        this.fallbackFaqIntent !== null &&
        this.fallbackFaqIntentEnabled !== this.fallbackFaqIntentEdited
      ) {
        return true;
      }

      return this.selectedConversationsOption !==
        this.selectedConversationsOptionInitial ||
        this.isKeywordsActiveInitial !== this.isKeywordsActive
        ? true
        : false;
    },
  },
  created() {
    this.fetchConfig();

    this.isKeywordsActive = this.hasKeywordMatchFeature;
    this.isKeywordsActiveInitial = this.hasKeywordMatchFeature;
    this.fallbackFaqIntentEdited = this.fallbackFaqIntentEnabled;
  },
  watch: {
    hasKeywordMatchFeature() {
      this.isKeywordsActive = this.hasKeywordMatchFeature;
      this.isKeywordsActiveInitial = this.hasKeywordMatchFeature;
    },
  },
  methods: {
    ...mapMutations('hubUi', ['addAlert']),
    ...mapMutations('bots', ['setFallbackFaqIntent']),
    ...mapActions('intents', ['get']),
    async save() {
      this.isLoading = true;
      // only if conversationOption changed
      if (
        this.selectedConversationsOption !==
        this.selectedConversationsOptionInitial
      ) {
        const cleanupRes = await this.$store.dispatch('bots/setCleanup', {
          botId: this.currentBotId,
          cleanup: this.conversationsSettings,
        });
        if (cleanupRes) {
          this.selectedConversationsOptionInitial = Number(
            this.selectedConversationsOption
          );
        }
      }

      // toggle keywords
      if (this.isKeywordsActiveInitial !== this.isKeywordsActive) {
        if (this.isKeywordsActive) {
          const res = await BotService.activateKeywords({
            botIdLive: this.currentBotId,
            botIdStaging: this.currentBot.stagingBot,
          });
          if (res) {
            this.isKeywordsActive = true;
            this.isKeywordsActiveInitial = true;
          }
          this.trackIt('keyword-feature', { active: true });
        } else {
          const res = await BotService.deactivateKeywords({
            botIdLive: this.currentBotId,
            botIdStaging: this.currentBot.stagingBot,
          });
          if (res) {
            this.isKeywordsActive = false;
            this.isKeywordsActiveInitial = false;
          }
          this.trackIt('keyword-feature', { active: false });
        }
      }

      // toggle high privacy
      if (
        this.isAdmin &&
        this.isHighPrivacyActiveLocal !== this.highPrivacy?.active
      ) {
        const data = await BotService.setHighPrivacyActivation({
          botId: this.currentBotId,
          activate: this.isHighPrivacyActiveLocal,
        });
        // Just to get the new vocabId out of the message
        const newVocabId = data?.message?.split('dictionary ')[1] || '';

        if (!this.highPrivacy) {
          this.highPrivacy = {};
        }

        if (newVocabId) {
          this.highPrivacy.vocabId = newVocabId;
        }

        this.highPrivacy.active = this.isHighPrivacyActiveLocal;
      }

      // Toggle fallback FAQ intent, if it is set up and changed
      if (
        this.fallbackFaqIntent !== null &&
        this.fallbackFaqIntent.active !== this.fallbackFaqIntentEnabled
      ) {
        await this.setKnowledgeBase();
      }

      this.isLoading = false;
      this.trackIt('save');
    },
    reset() {
      this.selectedConversationsOption =
        this.selectedConversationsOptionInitial;
      this.isKeywordsActive = this.isKeywordsActiveInitial;
    },
    async fetchConfig() {
      this.isLoading = true;
      const botSettings = await this.getBotSettings(this.currentBotId);
      if (botSettings) {
        this.setConversionsSettings(botSettings);
      } else {
        const botSettings = await this.getBotSettings(this.currentBotId);
        if (botSettings) {
          this.setConversionsSettings(botSettings);
        }
      }
      // timer for ui loading
      await new Promise((resolved) => setTimeout(resolved, 300));

      this.highPrivacy = botSettings.highPrivacy || null;

      this.isHighPrivacyActiveLocal = botSettings.highPrivacy?.active || false;
      this.isLoading = false;
    },
    setConversionsSettings(botSettings) {
      if (!botSettings?.cleanup) return;
      if (!botSettings.cleanup.active) {
        this.selectedConversationsOption = conversationsOptionTypes.NEVER;
        this.selectedConversationsOptionInitial =
          conversationsOptionTypes.NEVER;
      } else {
        this.selectedConversationsOption = Number(botSettings.cleanup.days);
        this.selectedConversationsOptionInitial = Number(
          botSettings.cleanup.days
        );
      }
    },
    changeConversationsSettings() {
      if (!this.selectedConversationsOption) {
        this.conversationsSettings = {
          active: false,
          days: 0,
        };
      } else {
        this.conversationsSettings = {
          active: true,
          days: this.selectedConversationsOption,
        };
      }
    },
    lostCancel() {
      this.isLostDialogOpen = false;
    },
    lostOk() {
      this.isLostDialogOpen = false;
      if (!this.nextUrl) {
        this.reset();
      } else {
        // url change
        this.$router.push(this.nextUrl);
        this.nextUrl = null;
      }
      this.trackIt('draft-cancel');
    },
    trackIt(type = 'view', payload) {
      const body = {
        botId: this.currentBotId,
        type: type,
        source: 'SettingsPrivacy.vue',
      };
      if (payload) {
        body.payload = payload;
      }
      TrackingService.trackSettingsPrivacy(body);
    },
    /**
     * Bot does not have any KB / Fallback intent set.
     * Set it up for this bot. Default state is disabled.
     */
    async setKnowledgeBase() {
      this.isLoading = true;
      const wasNeverSetUp = this.fallbackFaqIntent === null;

      let r;
      try {
        // If missing, set it up with false. Else do the opposite of the current state.
        const newState = this.fallbackFaqIntent === null
          ? false :
          !this.fallbackFaqIntentEnabled

        // Set up or toggle state
        r = await RAGServiceV2.setDefaultEnabled(this.currentBotId, newState);
      } catch (e) {
        console.error(e);
        this.addAlert({
          message: e.message,
          type: 'error',
          scope: 'SettingsPrivacy',
        });
      } finally {
        this.isLoading = false;
      }

      if (r.success) {
        // Populate bot setting
        this.setFallbackFaqIntent(r.fallbackFaqIntent);

        // If it was never set up, we need to now also re-fetch intent list, since the intent was just created
        if (wasNeverSetUp) {
          await this.get(this.currentBotId);
        }
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.isChanged && !this.nextUrl) {
      this.isLostDialogOpen = true;
      this.nextUrl = to;
      return next(false);
    }

    return next(true);
  },
};
</script>
