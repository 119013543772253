var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "reply-area" }, [
    _vm.qr.length
      ? _c(
          "div",
          { staticClass: "d-flex gap-1 px-2 pt-1 mb-1 justify-end" },
          _vm._l(_vm.qr, function (quickReply) {
            return _c("span", { key: quickReply, staticClass: "quick-reply" }, [
              _vm._v(" " + _vm._s(quickReply) + " "),
            ])
          }),
          0
        )
      : _vm._e(),
    _c("div", { staticClass: "d-flex gap-1 pa-2" }, [
      _c("span", { staticClass: "input-field" }, [
        _vm._v(_vm._s(_vm.placeholder)),
      ]),
      _c(
        "svg",
        {
          attrs: {
            width: "38px",
            height: "38px",
            viewBox: "0 0 38 38",
            xmlns: "http://www.w3.org/2000/svg",
          },
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "button_send_new-copy",
                stroke: "none",
                "stroke-width": "1",
                fill: "none",
                "fill-rule": "evenodd",
              },
            },
            [
              _c("circle", {
                attrs: {
                  id: "Sendbutton",
                  fill: "#a9a9a9",
                  cx: "19",
                  cy: "19",
                  r: "19",
                },
              }),
              _c("polygon", {
                attrs: {
                  id: "Paperplane",
                  fill: "#FFFFFF",
                  points:
                    "7.19273235 14.2191781\n                            32.9594125 12 13.5085974 30 8.56247221 22.7710478 20.1374946\n                            16.9315068 7.04058754 20.1077497",
                },
              }),
            ]
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }