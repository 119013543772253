<template>
  <div class="d-flex">
    <div class="d-flex items-center mr-3">
      <v-icon class="grey--text text--darken-1 mr-1 mt-n1">mdi-robot-happy-outline</v-icon>
      <div class="text-h6 grey--text text--darken-1">24</div>
    </div>
    <div class="d-flex items-center">
      <v-icon class="grey--text text--darken-1">mdi-flag-checkered</v-icon>
      <div class="text-h6 grey--text text--darken-1">3</div>
    </div>
    <v-divider vertical class="mx-4" />
    <div class="d-flex items-center mr-3">
      <v-icon class="primary--text text--lighten-4 mr-1 mt-n1">mdi-face-agent</v-icon>
      <div class="text-h6 primary--text text--lighten-4">3</div>
    </div>
    <div class="d-flex items-center">
      <v-icon class="primary--text text--lighten-4">mdi-flag-checkered</v-icon>
      <div class="text-h6 primary--text text--lighten-4">1</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LivechatAmountInfo',
  components: {},
};
</script>
