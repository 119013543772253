var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "311px",
        height: "85px",
        viewBox: "0 0 311 85",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c("title", [_vm._v("Group 4")]),
      _c("defs", [
        _c("polygon", {
          attrs: {
            id: "path-1-message",
            points: "-4.08 4.08 16.08 4.08 16.08 16.08 8.32615385 16.08",
          },
        }),
        _c("path", {
          attrs: {
            d: "M6,0 L294,0 C297.313708,-6.08718376e-16 300,2.6862915 300,6 L300,58 C300,61.3137085 297.313708,64 294,64 L6,64 C2.6862915,64 4.05812251e-16,61.3137085 0,58 L0,6 C-4.05812251e-16,2.6862915 2.6862915,6.08718376e-16 6,0 Z",
            id: "path-3",
          },
        }),
      ]),
      _c(
        "g",
        {
          attrs: {
            id: "X---Lab",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
          },
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "lab/theming-backend-professional",
                transform: "translate(-733.000000, -1584.000000)",
              },
            },
            [
              _c("rect", {
                attrs: {
                  id: "Rectangle",
                  stroke: "#979797",
                  fill: "none",
                  x: "664.5",
                  y: "1529",
                  width: "449",
                  height: "189",
                  rx: "8",
                },
              }),
              _c(
                "g",
                {
                  attrs: {
                    id: "Group-4-message",
                    transform: "translate(734.000000, 1585.000000)",
                  },
                },
                [
                  _c(
                    "g",
                    {
                      attrs: {
                        id: "2-molecule-message/chat/bubbles/user/bubble-multiline-withindicator",
                        transform: "translate(10.000000, 10.500000)",
                      },
                    },
                    [
                      _c(
                        "g",
                        {
                          attrs: {
                            id: "message/chat/bubble-user/bubble-multiline-indicator",
                          },
                        },
                        [
                          _c(
                            "g",
                            {
                              attrs: {
                                id: "message/chat/bubble-user/bubble-indicator",
                                transform: "translate(288.000000, 51.840000)",
                              },
                            },
                            [
                              _c(
                                "mask",
                                {
                                  attrs: {
                                    id: "mask-2-message",
                                    fill: "white",
                                  },
                                },
                                [
                                  _c("use", {
                                    attrs: { "xlink:href": "#path-1-message" },
                                  }),
                                ]
                              ),
                              _c("use", {
                                attrs: {
                                  id: "atom/chat/messages/bot/bubble-indicator",
                                  fill: "#FFFFFF",
                                  transform:
                                    "translate(6.000000, 10.080000) scale(-1, 1) rotate(-90.000000) translate(-6.000000, -10.080000) ",
                                  "xlink:href": "#path-1-message",
                                },
                              }),
                              _c(
                                "g",
                                {
                                  attrs: {
                                    id: "message/_theming/_globalcolor/primary-color",
                                    mask: "url(#mask-2-message)",
                                  },
                                },
                                [
                                  _c(
                                    "g",
                                    {
                                      attrs: {
                                        transform:
                                          "translate(-1.000000, 0.000000)",
                                        id: "message/color/branding/moinai-primary",
                                        fill: _vm.colors.color,
                                      },
                                    },
                                    [
                                      _c("rect", {
                                        attrs: {
                                          id: "Color",
                                          x: "0",
                                          y: "0",
                                          width: "13",
                                          height: "20.16",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c("g", { attrs: { id: "bubble" } }, [
                            _c(
                              "mask",
                              {
                                attrs: { id: "mask-4-message", fill: "white" },
                              },
                              [
                                _c("use", {
                                  attrs: { "xlink:href": "#path-3" },
                                }),
                              ]
                            ),
                            _c("use", {
                              attrs: {
                                id: "atom/chat/messages/bot/bubble",
                                fill: "#FFFFFF",
                                "xlink:href": "#path-3",
                              },
                            }),
                            _c(
                              "g",
                              {
                                attrs: {
                                  id: "message/_theming/_globalcolor/primary-color",
                                  mask: "url(#mask-4-message)",
                                  fill: _vm.colors.color,
                                },
                              },
                              [
                                _c(
                                  "g",
                                  {
                                    attrs: {
                                      id: "message/color/branding/moinai-primary",
                                    },
                                  },
                                  [
                                    _c("rect", {
                                      attrs: {
                                        id: "Color",
                                        x: "0",
                                        y: "0",
                                        width: "300",
                                        height: "72",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "g",
                        {
                          attrs: {
                            id: "message-box",
                            transform: "translate(12.000000, 10.000000)",
                            fill: _vm.colors.contrast,
                            "font-family": "Helvetica",
                            "font-size": "15",
                            "font-weight": "normal",
                            "line-spacing": "22",
                          },
                        },
                        [
                          _c(
                            "text",
                            { attrs: { id: "atom/chat/messages/bot/text" } },
                            [
                              _c("tspan", { attrs: { x: "0", y: "15" } }, [
                                _vm._v("Wo bekomme ich Informationen über "),
                              ]),
                              _c("tspan", { attrs: { x: "0", y: "37" } }, [
                                _vm._v("eure neuesten Produkte?"),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c("g", { attrs: { id: "Oval" } }, [
                    _c("circle", {
                      attrs: {
                        stroke: "#FF00B5",
                        "fill-opacity": "0",
                        fill: "#D8D8D8",
                        cx: "20",
                        cy: "20",
                        r: "20",
                      },
                    }),
                    _c(
                      "g",
                      {
                        attrs: {
                          transform: "translate(18.000000, 18.000000)",
                          fill: "#FF00B5",
                        },
                      },
                      [
                        _c("circle", {
                          attrs: { cx: "2", cy: "1.71875", r: "1.71875" },
                        }),
                      ]
                    ),
                  ]),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }