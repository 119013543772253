var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("HubBoxSimple", {
    attrs: { title: _vm.$t("stats.intents"), isLoading: _vm.isLoading },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c("v-data-table", {
              attrs: {
                items: _vm.items,
                headers: _vm.headers,
                "hide-default-footer": _vm.items && _vm.items.length <= 5,
                "footer-props": {
                  "items-per-page-options": [5, 20, 50, -1],
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: `item.displayName`,
                    fn: function ({ item }) {
                      return [
                        _c(
                          "div",
                          { staticClass: "py-2", attrs: { id: item.intent } },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "mr-2",
                                attrs: {
                                  size: "20",
                                  color: "primary lighten-3",
                                },
                              },
                              [_vm._v(" mdi-folder-text ")]
                            ),
                            _c("span", { staticClass: "text-body-1" }, [
                              _vm._v(
                                " " + _vm._s(_vm.displayName(item.topic)) + " "
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: `item.count`,
                    fn: function ({ item }) {
                      return [
                        _c("span", { staticClass: "text-left" }, [
                          _vm._v(" " + _vm._s(item.count) + " "),
                        ]),
                      ]
                    },
                  },
                  {
                    key: `item.isConversion`,
                    fn: function ({ item }) {
                      return [
                        _vm.$vuetify.breakpoint.smAndUp
                          ? [
                              item.isConversion
                                ? _c("StatsBarCountConversion", {
                                    attrs: {
                                      isLoading: _vm.isLoading,
                                      countAll: item.count,
                                      countGoals: item.goals,
                                      language: _vm.user.language,
                                    },
                                  })
                                : _c("StatsBarCountFeedback", {
                                    attrs: {
                                      isLoading: _vm.isLoading,
                                      countAll: item.count,
                                      countPos: item.pos,
                                      countNeg: item.neg,
                                      language: _vm.user.language,
                                    },
                                  }),
                            ]
                          : [
                              _c("span", { staticClass: "green--text" }, [
                                _vm._v(_vm._s(item.pos)),
                              ]),
                              _c("span", [_vm._v("/")]),
                              _c("span", { staticClass: "red--text" }, [
                                _vm._v(_vm._s(item.neg)),
                              ]),
                            ],
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "footer",
        fn: function () {
          return [
            _c(
              "v-btn",
              {
                staticClass: "mt-4",
                attrs: {
                  small: "",
                  outlined: "",
                  color: "primary",
                  to: `statistics/${_vm.statsTypes.intents}`,
                },
              },
              [
                _vm._v(" " + _vm._s(_vm.$t("stats.reports.title")) + " "),
                _c("v-icon", { attrs: { right: "" } }, [_vm._v("query_stats")]),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }