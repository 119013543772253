var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.quota && false
    ? _c(
        "div",
        [
          _c(
            "div",
            {
              staticClass: "moin-admin-box",
              staticStyle: { display: "inline-block" },
            },
            [
              _c(
                "v-btn",
                {
                  attrs: { depressed: "", color: "admin", disabled: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialogIsOpen = true
                    },
                  },
                },
                [
                  _vm._v(" " + _vm._s(_vm.$t("common.add")) + " "),
                  _c("v-icon", { attrs: { right: "" } }, [
                    _vm._v("mdi-playlist-plus"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "HubDialog",
            {
              attrs: {
                value: _vm.dialogIsOpen,
                title: _vm.$t("channelManagement.createDialog.title"),
                asyncDialog: "",
                primaryIcon: "save",
                primaryLabel: _vm.$t(
                  "channelManagement.createDialog.btnSubmit"
                ),
                secondaryLabel: _vm.$t("common.cancel"),
                isLoading: _vm.isLoading,
                disabled: _vm.isDisabled,
                modal: "",
              },
              on: {
                onSecondary: function ($event) {
                  _vm.dialogIsOpen = false
                },
                onPrimary: _vm.createNewTeaser,
              },
            },
            [
              _c("div", { staticClass: "text-body-1" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("channelManagement.createDialog.description")
                    ) +
                    " "
                ),
              ]),
              _c("v-text-field", {
                staticClass: "mt-8",
                attrs: {
                  label: _vm.$t("channelManagement.createDialog.nameLabel"),
                  type: "text",
                },
                model: {
                  value: _vm.displayNameInput,
                  callback: function ($$v) {
                    _vm.displayNameInput = $$v
                  },
                  expression: "displayNameInput",
                },
              }),
              _c(
                "div",
                { staticClass: "mb-6" },
                [
                  _c("v-select", {
                    attrs: {
                      outlined: "",
                      label: _vm.$t("channelManagement.createDialog.typeLabel"),
                      items: _vm.typeOptions,
                      "item-text": "text",
                      "item-value": "value",
                      dense: "",
                      "hide-details": "",
                    },
                    model: {
                      value: _vm.selectedType,
                      callback: function ($$v) {
                        _vm.selectedType = $$v
                      },
                      expression: "selectedType",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mb-6" },
                [
                  _c("v-select", {
                    attrs: {
                      outlined: "",
                      label: _vm.$t("common.language"),
                      items: _vm.langOptions,
                      "item-text": "text",
                      "item-value": "value",
                      dense: "",
                      "hide-details": "",
                    },
                    model: {
                      value: _vm.selectedLang,
                      callback: function ($$v) {
                        _vm.selectedLang = $$v
                      },
                      expression: "selectedLang",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }