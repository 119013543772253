var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { "padding-right": "1px" } },
    [
      _c("v-divider", { staticClass: "grey lighten-2" }),
      !_vm.user
        ? _c(
            "div",
            [
              _c(
                "v-list",
                { staticClass: "py-0" },
                [
                  _c(
                    "v-list-item",
                    {
                      attrs: {
                        to: !_vm.preview
                          ? `/login?redirect=${_vm.$route.fullPath}`
                          : null,
                      },
                      on: {
                        click: function ($event) {
                          _vm.preview
                            ? _vm.goToUrl(
                                `/login?redirect=${_vm.$route.fullPath}`
                              )
                            : null
                        },
                      },
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c("v-icon", { attrs: { color: "secondary" } }, [
                            _vm._v("exit_to_app"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              " " + _vm._s(_vm.$t("login.btnLabel")) + " "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm.myProfileHidden
        ? _c(
            "div",
            [
              _c(
                "v-list-item",
                {
                  attrs: { link: "", "data-cy": "logout" },
                  on: {
                    click: function ($event) {
                      return _vm.logout()
                    },
                  },
                },
                [
                  _c(
                    "v-list-item-icon",
                    [
                      _c("v-icon", { attrs: { color: "secondary" } }, [
                        _vm._v("exit_to_app"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", [
                        _vm._v(" " + _vm._s(_vm.$t("menu.logout")) + " "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "v-expansion-panels",
            { attrs: { tile: "", flat: "" } },
            [
              _c(
                "v-expansion-panel",
                { key: 0 },
                [
                  _c("v-expansion-panel-header", [
                    _c(
                      "div",
                      {
                        staticClass: "d-flex align-center",
                        attrs: { "data-cy": "profile-menu" },
                      },
                      [
                        _c("v-icon", { attrs: { color: "secondary" } }, [
                          _vm._v("mdi-account-circle"),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "text-h6 pt-1 ml-8 primary--text text--lighten-2",
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("menu.profile")) + " ")]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c(
                        "v-list",
                        { staticClass: "py-0" },
                        [
                          _c(
                            "v-list-item",
                            {
                              attrs: {
                                to: !_vm.isTester ? "/user-profile" : null,
                              },
                              on: {
                                click: () => {
                                  if (_vm.isTester)
                                    _vm.isProfileOpen = !_vm.isProfileOpen
                                },
                              },
                            },
                            [
                              _c(
                                "v-list-item-icon",
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "secondary" } },
                                    [_vm._v("mdi-account-cog")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("menu.myProfile")) +
                                        " "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              attrs: { link: "", "data-cy": "logout" },
                              on: {
                                click: function ($event) {
                                  return _vm.logout()
                                },
                              },
                            },
                            [
                              _c(
                                "v-list-item-icon",
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "secondary" } },
                                    [_vm._v("exit_to_app")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("menu.logout")) + " "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
      _c(
        "HubDialog",
        {
          staticStyle: { "z-index": "100010" },
          attrs: {
            title: _vm.$t("menu.myProfile"),
            "primary-label": _vm.$t("common.save"),
            "primary-icon": "save",
            "secondary-icon": "close",
            "secondary-label": _vm.$t("common.cancel"),
            "max-width": "1200px",
          },
          on: {
            onPrimary: function ($event) {
              return _vm.saveConfig()
            },
            onSecondary: function ($event) {
              _vm.resetInputs()
              _vm.isProfileOpen = false
            },
          },
          model: {
            value: _vm.isProfileOpen,
            callback: function ($$v) {
              _vm.isProfileOpen = $$v
            },
            expression: "isProfileOpen",
          },
        },
        [
          _c(
            "div",
            [
              _c("UserProfileComp", {
                ref: "userProfileComp",
                on: {
                  onChange: function ($event) {
                    _vm.isChanged = $event
                  },
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }