var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {
    staticClass: "gap-separator",
    class: { "double-height": _vm.doubleHeight },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }