var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-expansion-panels",
    { attrs: { tile: "", flat: "" } },
    [
      _c(
        "v-expansion-panel",
        { key: 0, staticStyle: { background: "#d1d4d9" } },
        [
          _c("v-expansion-panel-header", [
            _c(
              "div",
              { staticClass: "d-flex full-width items-center" },
              [
                _vm.$vuetify.breakpoint.smAndDown
                  ? _c("BtnSquare", {
                      staticClass: "mr-1",
                      attrs: { icon: "mdi-arrow-left" },
                      nativeOn: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.closeChat()
                        },
                      },
                    })
                  : _vm._e(),
                _vm.activeChat
                  ? [
                      _vm.activeChat.user && _vm.activeChat.user.avatar
                        ? _c("LivechatAvatar", {
                            attrs: { avatar: _vm.activeChat.user.avatar },
                          })
                        : _vm._e(),
                      _c("div", { staticClass: "ml-2" }, [
                        _c("h1", { staticClass: "text-body-1 primary--text" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                `${_vm.activeChat.user.avatar.color.name} ${_vm.activeChat.user.avatar.icon.name}`
                              ) +
                              " "
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "d-flex" },
                          _vm._l(_vm.infoTags, function (item, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "d-flex" },
                              [
                                _c("LivechatInfoTag", {
                                  attrs: { item: item },
                                }),
                                index < _vm.infoTags.length - 1
                                  ? _c("v-divider", {
                                      staticClass: "mx-2",
                                      attrs: { vertical: "" },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                      ]),
                    ]
                  : _vm._e(),
              ],
              2
            ),
            _c(
              "div",
              [
                _vm.activeChat?.notes
                  ? _c("v-icon", [
                      _vm._v(_vm._s(_vm.infoCardIcon(_vm.infoTagTypes.note))),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c("v-expansion-panel-content", [
            _vm.activeChat
              ? _c(
                  "div",
                  { staticClass: "d-flex mb-2" },
                  [
                    _c("LivechatInfoSummary", {
                      attrs: { "active-chat": _vm.activeChat },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }