var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: {
        "offset-y": "",
        "nudge-top": "-20px",
        left: "",
        bottom: "",
        absolute: "",
        "min-width": _vm.width,
        "max-width": _vm.width,
        value: _vm.isOpen,
        disabled: _vm.isDisabled,
      },
      on: {
        click: function ($event) {
          $event.stopPropagation()
        },
        input: _vm.handleOpen,
      },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function ({ on, attrs }) {
              return [
                _c(
                  "span",
                  _vm._g(
                    _vm._b(
                      { staticClass: "d-inline-block" },
                      "span",
                      attrs,
                      false
                    ),
                    on
                  ),
                  [_vm._t("default")],
                  2
                ),
              ]
            },
          },
        ],
        null,
        true
      ),
    },
    [
      _c(
        "v-list",
        { staticClass: "pa-0" },
        [
          _vm._l(_vm.items, function (item, index) {
            return [
              !item.subitems
                ? _c(
                    "v-list-item",
                    {
                      key: index,
                      attrs: {
                        link: "",
                        disabled: _vm.isDisabled || item.isDisabled,
                        to: item.to ? item.to : "",
                        target: item.target ? item.target : "",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.handleClick(item)
                        },
                      },
                    },
                    [
                      _c("v-list-item-content", [
                        _vm._v(" " + _vm._s(item.text) + " "),
                      ]),
                      item.icon
                        ? _c(
                            "v-list-item-action",
                            [
                              _c(
                                "v-icon",
                                { attrs: { right: "", color: item.iconColor } },
                                [_vm._v(_vm._s(item.icon))]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _c(
                    "v-menu",
                    {
                      key: index,
                      attrs: {
                        "offset-y": "",
                        "open-on-hover": "",
                        "nudge-right": "200",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "v-list-item",
                                  _vm._g(
                                    _vm._b({}, "v-list-item", attrs, false),
                                    on
                                  ),
                                  [
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-title", [
                                          _vm._v(_vm._s(item.text)),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-item-action",
                                      [
                                        _c("v-icon", [
                                          _vm._v("mdi-chevron-right"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _c(
                        "v-list",
                        { staticClass: "pa-0" },
                        [
                          _c(
                            "v-list-item-group",
                            [
                              _vm._l(
                                item.subitems,
                                function (subitem, subindex) {
                                  return [
                                    _c(
                                      "v-list-item",
                                      {
                                        key: subindex,
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.handleClick(subitem)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c("v-list-item-title", [
                                              _vm._v(_vm._s(subitem.text)),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list-item-action",
                                          [
                                            subitem.icon
                                              ? _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      right: "",
                                                      color: subitem.iconColor,
                                                    },
                                                  },
                                                  [_vm._v(_vm._s(subitem.icon))]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("v-divider", {
                                      key: `${subindex}-divider`,
                                    }),
                                  ]
                                }
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
              _c("v-divider", { key: `${index}-divider` }),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }