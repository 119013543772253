<template>
  <div class="white">
    <div class="d-flex justify-space-between px-3" style="height: 60px">
      <div class="d-flex items-center" style="margin-top: 10px; height: 40px;">
        <div class="">
          <v-img src="@/assets/moin-logo.svg" width="110" />
        </div>

        <v-divider
          v-if="$vuetify.breakpoint.mdAndUp"
          style="height: 40px"
          class="ml-4 mr-6"
          vertical
        />

        <h1 class="text-h6">{{ title }}</h1>
      </div>

      <!-- action icons toolbar -->
      <div class="d-flex align-center justify-end">
        <slot name="toolbar" :disabled="isLoading" />
      </div>
    </div>
    <v-divider />
  </div>
</template>
<script>
import BtnSquare from '@/components/common/BtnSquare.vue';

export default {
  name: 'HubContentNavbar',
  components: {
    BtnSquare,
  },
  props: {
    title: {
      type: String,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isAdminOnly() {
      return this.$route.meta?.admin || false;
    },
  },
};
</script>
