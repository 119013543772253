import ProductService from '../../services/product';
import Fuse from 'fuse.js';

const state = {
  botResults: [],
  intentResults: [],
  searchQueries: [],
  suggestionResults: [],
  //searching: false
  botIndex: null, // FuseJS search instance for bots
};

const getters = {
  botResults: (state) => {
    return state.botResults;
  },
  intentResults: (state) => {
    return state.intentResults;
  },
  queries: (state, getters, rootState, rootGetters) => {
    let currentBotId = rootState.bots.currentBot.uniqueBotId;

    return state.searchQueries
      .filter((query) => {
        if (query.type === 'bot' && !rootGetters['auth/isAdmin']) {
          return false;
        }

        return query.type !== 'intent' || query.value.botId === currentBotId;
      })
      .slice(0, 5);
  },
  botIndex: (state, getters, rootState) => {
    return new Fuse(rootState.bots.bots, {
      keys: [
        // If you use Bot ID for matching, you likely want that result higher
        {
          name: 'uniqueBotId',
          weight: 0.8,
        },
        {
          name: 'name',
          weight: 0.6,
        }
      ],
      threshold: 0.4,
      minMatchCharLength: 2,
      distance: 60,
    });
  },
  intentIndex: (state, getters, rootState, rootGetters) => {
    const language = rootGetters['auth/user'].language;
    const formattedIntents = rootGetters['intents/intents'].map(intent => ({
      displayName: rootGetters['intents/displayName'](intent.intent, language) || intent.displayName,
      type: 'intent',
      icon: intent.suggested ? 'auto_awesome' : 'mdi-folder-text',
      value: intent,
    }));
    rootGetters['intents/suggested'].forEach((s) => {
      formattedIntents.push({
        displayName: rootGetters['intents/displayName'](s.intent, language) || s.displayName,
        type: 'intent',
        icon: 'auto_awesome',
        value: s,
      })
    });

    const s = new Fuse(formattedIntents, {
      keys: [
        {
          name: 'displayName',
          weight: 0.8,
        },
        {
          name: 'intent',
          weight: 0.3,
        }
      ],
      threshold: 0.6,
      minMatchCharLength: 1,
    });

    window.fuse = s;
    return s;
  }
};

const actions = {
  async search({ rootState, commit, rootGetters, getters }, { text }) {
    if (!text || text.trim().length === 0 || text === '') {
      return commit('setResults', { bots: [], intents: [] });
    }
    //commit('searching', true);

    // search bots
    let bots = [];
    if (rootGetters['auth/isAdmin']) {
      bots = getters.botIndex
        .search(text)
        .map(({item}) => {
          return {
            displayName: item.name,
            type: 'bot',
            icon: 'smart_toy',
            value: item,
          };
        });
    }

    // search intent names
    const intents = getters.intentIndex.search(text).map(({ item }) => item);

    commit('setResults', { bots, intents });
  },
  async pick({ commit }, item) {
    commit('addQuery', item);
    commit('setResults', { bots: [], intents: [] });
  },
  async removeQuery({ commit }, item) {
    commit('removeQuery', item);
  },
};

const mutations = {
  setResults(state, { bots, intents }) {
    state.botResults = bots.slice(0, 5);
    
    state.intentResults = intents.slice(0, 5);
  },
  unshiftResult(state, { bot, intent }) {
    if (intent) {
      state.intentResults = state.intentResults.filter((q) => {
        return !(
          q.type === intent.type && q.displayName === intent.displayName
        );
      });

      state.intentResults.unshift(intent);
      if (state.intentResults.length > 5) {
        state.intentResults.length = 5;
      }
    }

    if (bot) {
      state.botResults = state.botResults.unshift(bot);
      if (state.botResults.length > 5) {
        state.botResults.length = 5;
      }
    }
  },
  addQuery(state, query) {
    state.searchQueries = state.searchQueries.filter((q) => {
      return !(q.type === query.type && q.displayName === query.displayName);
    });

    state.searchQueries.unshift(query);
    if (state.searchQueries.length > 100) {
      state.searchQueries.length = 100;
    }
  },
  removeQuery(state, query) {
    state.searchQueries = state.searchQueries.filter((q) => {
      return !(q.type === query.type && q.displayName === query.displayName);
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
