var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "relative" }, [
    _c(
      "div",
      {
        staticClass: "relative start-chart",
        class: (_vm.isLoading || _vm.isNoData) && "start-chart--hidden",
      },
      [
        _c("apexchart", {
          attrs: {
            width: "100%",
            height: _vm.height,
            type: _vm.type,
            options: _vm.chartOptions,
            series: _vm.series,
          },
        }),
        _vm.timeFormat
          ? _c(
              "div",
              {
                staticClass: "text-right text-body-2 grey--text text--darken-1",
                staticStyle: { position: "absolute", left: "15px", top: "0px" },
              },
              [
                _c(
                  "v-icon",
                  { attrs: { small: "", color: "primary lighten-4" } },
                  [_vm._v("mdi-calendar-range")]
                ),
                _vm._v(
                  " " +
                    _vm._s(_vm.$t(`stats.timeFormat.${_vm.timeFormat}`)) +
                    " "
                ),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
    _vm.isNoData
      ? _c(
          "div",
          {
            staticClass: "text-center text-body-2",
            staticStyle: {
              position: "absolute",
              top: "150px",
              left: "0",
              right: "0",
            },
          },
          [
            _vm.isDateRangeTooSmall
              ? _c(
                  "div",
                  { staticClass: "text-center mt-n4" },
                  [
                    _c(
                      "v-icon",
                      {
                        staticClass: "mb-2",
                        attrs: { color: "primary lighten-3" },
                      },
                      [_vm._v(" mdi-eye-off-outline ")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "text-center primary--text text--lighten-3",
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("stats.minTimeRange")) + " ")]
                    ),
                  ],
                  1
                )
              : _c("span", { staticClass: "grey--text" }, [
                  _vm._v(" " + _vm._s(_vm.$t("common.nodata")) + " "),
                ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }