var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass:
            "mb-4 d-flex align-center primary--text text--lighten-2 justify-space-between",
        },
        [
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c(
                "v-icon",
                { attrs: { color: "primary lighten-2", left: "" } },
                [_vm._v("speed")]
              ),
              _c("h2", { staticClass: "text-h6" }, [
                _vm._v(_vm._s(_vm.$t("welcome.stats.title"))),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "d-flex gap-2 items-center" },
            [
              _c("v-select", {
                staticClass: "mt-n4",
                attrs: {
                  items: _vm.datePeriods,
                  value: _vm.statsDatePeriod,
                  "item-value": "value",
                  "item-text": "label",
                  "hide-details": "",
                  disabled: _vm.isFake,
                },
                on: { change: _vm.onChangePeriod },
                scopedSlots: _vm._u([
                  {
                    key: "prepend-inner",
                    fn: function () {
                      return [
                        _c(
                          "v-icon",
                          { attrs: { color: "primary lighten-4" } },
                          [_vm._v("mdi-calendar")]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("BtnSquare", {
                attrs: {
                  icon: "show_chart",
                  to: { name: "statistics" },
                  outlined: "",
                  disabled: _vm.isFake,
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm.isFake
        ? _c(
            "v-alert",
            { staticClass: "mb-4", attrs: { type: "info", outlined: "" } },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("welcome.stats.fakeDataWarning")) + " "
              ),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "d-flex gap-4 mb-4",
          class: { "fake-data-overlay": _vm.isFake },
        },
        [
          _c(
            "WelcomeInfoTeaser",
            {
              staticClass: "column",
              attrs: {
                title: _vm.$t("welcome.stats.conversations"),
                icon: "forum",
                value: `${_vm.formatNumberFixed(
                  _vm.stats.StatsChartConversations.new
                )}`,
                loading:
                  !_vm.isFake && _vm.stats.StatsChartConversations.isLoading,
                noData:
                  !_vm.isFake && _vm.stats.StatsChartConversations.isFailed,
              },
            },
            [
              _vm.isFake || !_vm.stats.StatsChartConversations.isFailed
                ? _c("StatsChangeIndicator", {
                    attrs: {
                      newValue: _vm.stats.StatsChartConversations.new,
                      oldValue: _vm.stats.StatsChartConversations.old,
                      loading:
                        !_vm.isFake &&
                        _vm.stats.StatsChartConversations.isLoading,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "WelcomeInfoTeaser",
            {
              staticClass: "column",
              attrs: {
                title: _vm.$t("welcome.stats.automations"),
                icon: "model_training",
                value: `${_vm.formatNumberFixed(
                  _vm.stats.StatsChartAutomation.new
                )}%`,
                noData: !_vm.isFake && _vm.stats.StatsChartAutomation.isFailed,
                loading:
                  !_vm.isFake && _vm.stats.StatsChartAutomation.isLoading,
              },
            },
            [
              _vm.isFake || !_vm.stats.StatsChartAutomation.isFailed
                ? _c("StatsChangeIndicator", {
                    attrs: {
                      newValue: _vm.stats.StatsChartAutomation.new,
                      oldValue: _vm.stats.StatsChartAutomation.old,
                      loading:
                        !_vm.isFake &&
                        _vm.stats.StatsChartConversations.isLoading,
                      isPercent: "",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("v-divider", { attrs: { vertical: "" } }),
          _c(
            "WelcomeInfoTeaser",
            {
              staticClass: "column",
              attrs: {
                title: _vm.$t("welcome.stats.feedbackPositive"),
                icon: "thumb_up",
                value: _vm.stats.StatsChartFeedbackPos.new,
                loading:
                  !_vm.isFake && _vm.stats.StatsChartFeedbackPos.isLoading,
                noData: !_vm.isFake && _vm.stats.StatsChartFeedbackPos.isFailed,
              },
            },
            [
              _vm.isFake || !_vm.stats.StatsChartFeedbackPos.isFailed
                ? _c("StatsChangeIndicator", {
                    attrs: {
                      newValue: _vm.stats.StatsChartFeedbackPos.new,
                      oldValue: _vm.stats.StatsChartFeedbackPos.old,
                      loading:
                        !_vm.isFake &&
                        _vm.stats.StatsChartFeedbackPos.isLoading,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "WelcomeInfoTeaser",
            {
              staticClass: "column",
              attrs: {
                title: _vm.$t("welcome.stats.feedbackNegative"),
                icon: "thumb_down",
                value: _vm.stats.StatsChartFeedbackNeg.new,
                loading:
                  !_vm.isFake && _vm.stats.StatsChartFeedbackNeg.isLoading,
                noData: !_vm.isFake && _vm.stats.StatsChartFeedbackNeg.isFailed,
              },
            },
            [
              _vm.isFake || !_vm.stats.StatsChartFeedbackNeg.isFailed
                ? _c("StatsChangeIndicator", {
                    attrs: {
                      newValue: _vm.stats.StatsChartFeedbackNeg.new,
                      oldValue: _vm.stats.StatsChartFeedbackNeg.old,
                      loading:
                        !_vm.isFake &&
                        _vm.stats.StatsChartFeedbackNeg.isLoading,
                      "less-is-better": "",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }