var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-chip",
    { attrs: { label: "", small: "", color: _vm.color } },
    [
      _c("v-icon", { attrs: { left: "", color: "white", size: "16" } }, [
        _vm._v(_vm._s(_vm.icon)),
      ]),
      _c("span", { staticClass: "text-uppercase text-body-2 white--text" }, [
        _vm._v(_vm._s(_vm.text)),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }