var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { staticStyle: { "max-height": "100vh", height: "100vh" } },
    [
      _c("HubMenu"),
      _c("Appbar"),
      _c("HubContent", {
        attrs: { isSidebarOpen: _vm.isSidebarOpen, fixedHeight: "" },
        scopedSlots: _vm._u([
          {
            key: "navbar",
            fn: function () {
              return [
                _c("HubContentNavbar", {
                  attrs: {
                    title: _vm.$t("channelManagement.title"),
                    backTo: "/settings",
                    isLoading: _vm.isLoading,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "toolbar",
                      fn: function () {
                        return [
                          _vm.quota
                            ? _c("BtnSquare", {
                                attrs: { icon: "view_sidebar" },
                                on: {
                                  click: function ($event) {
                                    _vm.isSidebarOpen = !_vm.isSidebarOpen
                                  },
                                },
                              })
                            : _vm._e(),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "sidebar",
            fn: function () {
              return [
                _c(
                  "HubContentSidebarSimple",
                  {
                    attrs: { isOpen: _vm.isSidebarOpen, icon: "info" },
                    on: {
                      onClose: function ($event) {
                        _vm.isSidebarOpen = false
                      },
                    },
                  },
                  [_c("div", { staticClass: "pa-4" }, [_c("QuotaInfo")], 1)]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "content",
            fn: function () {
              return [
                _c("HubFrame", {
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function () {
                        return [
                          _c("h2", { staticClass: "text-h5" }, [
                            _vm._v(
                              _vm._s(_vm.$t("channelManagement.table.title"))
                            ),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "actions",
                      fn: function () {
                        return [
                          _vm.isAdmin ? _c("ChannelsNewChannel") : _vm._e(),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "content",
                      fn: function () {
                        return [
                          _c(
                            "v-container",
                            { staticClass: "pa-4", attrs: { fluid: "" } },
                            [
                              _c(
                                "v-row",
                                [_c("v-col", [_c("ChannelsList")], 1)],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }