import ApiService from './api';
import moment from 'moment';

const ProductService = {
  getIntents: async function(botId) {
    try {
      const response = await ApiService.get('/bot/faq/' + botId);
      return response.data;
    } catch (error) {
      return {};
    }
  },
  getIntentDetails: async function(botId, template, intent) {
    try {
      const response = await ApiService.get(
        `/schaltzentrale/intent/${template}/${intent}/${botId}`
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  getForms: async function(botId) {
    try {
      const response = await ApiService.get('/schaltzentrale/forms/' + botId);
      return response.data;
    } catch (error) {
      return {};
    }
  },
  getAllForms: async function(botId) {
    try {
      const response = await ApiService.get(
        '/schaltzentrale/forms/get/' + botId
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  deleteForm: async function(botId, intent) {
    try {
      const response = await ApiService.delete(
        '/schaltzentrale/forms/' + botId + '/' + intent
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  updateFormDisplayName: async function(botId, intent, displayName) {
    try {
      const response = await ApiService.put(
        '/schaltzentrale/forms/' + botId + '/' + intent,
        {
          displayName,
        }
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  getContent: async function(botId, template, intent, box, channel) {
    try {
      let url = `/schaltzentrale/intent/content/${botId}/${intent}/${template}`;
      if (box) {
        url += `/box/${box}`;
      } else {
        url += `/box`;
      }

      if (channel) {
        url += `/channel/${channel}`;
      } else {
        url += `/channel`;
      }

      const response = await ApiService.get(url);
      return response.data;
    } catch (error) {
      return {};
    }
  },
  saveContent: async function(botId, box, content) {
    /* console.log('SAVE', botId, box, content); */

    try {
      const response = await ApiService.post(
        '/schaltzentrale/intent/' + botId,
        content
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  changeMeasurement: async function(botId, intent, measurement) {
    const body = {
      intent,
      measurement,
    };

    try {
      const response = await ApiService.post(
        `/schaltzentrale/intent/measurement/` + botId,
        body
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  createChannelContent: async function(botId, template, intent, channel) {
    try {
      const response = await ApiService.post(
        '/schaltzentrale/intent/' + botId + '/create',
        {
          template,
          intent,
          channel,
        }
      );

      return response.data;
    } catch (error) {
      return {};
    }
  },
  updateBotContent: async function(botId, intent, type, actions) {
    try {
      const response = await ApiService.post(
        '/schaltzentrale/intent/' + intent + `/${type}/` + botId,
        actions
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  /**
   * @returns {Promise<{} | {status: string, intent?: Object, message?: string, tags?: string[]}>}
   */
  updateIntent: async function(intent) {
    try {
      const body = {
        uniqueBotId: intent.botId,
        intent: intent.intent,
        displayName: intent.displayName,
        description: intent.description,
        zeroshotLabel: intent.zeroshotLabel,
        standardIntent: intent.standardIntent,
        translation: intent.translation,
      };

      if (intent.confidenceLimit) {
        body.confidenceLimit = intent.confidenceLimit;
      }

      if (intent.tags) {
        body.tags = intent.tags;
      }

      const response = await ApiService.post('/schaltzentrale/intent', body);
      return response.data;
    } catch (error) {
      return {};
    }
  },
  updateSuggestion: async function(intent) {
    try {
      const body = {
        uniqueBotId: intent.botId,
        intent: intent.intent,
        forward: intent.forward,
      };

      const response = await ApiService.post(
        '/schaltzentrale/intent/suggestion',
        body
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  analyze: async function(botId, fromBox, toBoxes) {
    try {
      const body = {
        uniqueBotId: botId,
        fromBox,
        toBoxes,
      };

      const response = await ApiService.post(
        '/schaltzentrale/intent/analyze',
        body
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  addIntent: async function(
    uniqueBotId,
    samples,
    displayName,
    description,
    suggested,
    forward
  ) {
    try {
      const body = {
        uniqueBotId,
        samples,
        displayName,
        description,
        suggested,
        forward,
      };

      const response = await ApiService.post(
        '/schaltzentrale/intent/new',
        body
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  addForm: async function(uniqueBotId, displayName, template = 'BotForm') {
    try {
      const body = {
        uniqueBotId,
        displayName,
        template,
      };

      const response = await ApiService.post('/schaltzentrale/forms', body);
      return response.data;
    } catch (error) {
      return {};
    }
  },
  changeIntentSuggestion: async function(uniqueBotId, name) {
    try {
      const body = {
        uniqueBotId,
        name,
      };

      const response = await ApiService.post(
        '/schaltzentrale/intent/suggest',
        body
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  getTrainingdata: async function(botId, name) {
    try {
      const response = await ApiService.get(
        '/schaltzentrale/trainingdata/' + botId + '/' + name
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  getTrainingdataPeriod: async function(botId, dateRange) {
    try {
      const response = await ApiService.get(
        `/schaltzentrale/trainingdata/${botId}/${dateRange[0].format('YYYY-MM-DD')}/${dateRange[1].format('YYYY-MM-DD')}`
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  addTrainingdata: async function(botId, name, trainingdata) {
    try {
      const body = {
        uniqueBotId: botId,
        name,
        trainingdata,
      };
      const response = await ApiService.post(
        '/schaltzentrale/trainingdata',
        body
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  deleteTrainingdata: async function(botId, text) {
    try {
      const body = {
        uniqueBotId: botId,
        text,
      };
      const response = await ApiService.delete(
        '/schaltzentrale/trainingdata',
        body
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  updateTrainingdata: async function(variant) {
    try {
      const body = {
        uniqueBotId: variant.botId,
        id: variant._id,
        variant,
      };
      const response = await ApiService.post(
        '/schaltzentrale/trainingdata/update',
        body
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  updateQualityCheck: async function(variant) {
    try {
      const body = {
        uniqueBotId: variant.botId,
        id: variant._id,
      };
      const response = await ApiService.post(
        '/schaltzentrale/trainingdata/qualitycheck',
        body
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  nlpCheck: async function(botId, text, noReferenceMatch = false, noKeywordMatch = false, channelId = null) {
    try {
      const body = {
        uniqueBotId: botId,
        text,
        noReferenceMatch,
        noKeywordMatch,
        channelId
      };

      const response = await ApiService.post('/schaltzentrale/nlp/check', body);
      return response.data;
    } catch (error) {
      return {};
    }
  },
  nlpFeedback: async function(botId, text, feedback, intent) {
    try {
      const body = {
        uniqueBotId: botId,
        text,
        feedback,
        intent,
      };

      const response = await ApiService.post(
        '/schaltzentrale/nlp/feedback',
        body
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  /**
   * Delete/withdraw feedback by the feedback record ID
   * @param {string} botId
   * @param {string} id Object ID to the feedback document
   */
  deleteFeedback: async function (botId, id) {
    try {
      const response = await ApiService.delete(
        `/schaltzentrale/nlp/feedback/${botId}/${id}`,
      );
      const data = response.data;
      return { success: data.success ?? false, ...data };
    } catch (error) {
      return { success: false, error };
    }
  },
  getDeployment: async function(botId) {
    try {
      const response = await ApiService.get(
        '/schaltzentrale/deployment/' + botId
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  deploy: async function(botId) {
    try {
      const body = {
        uniqueBotId: botId,
      };
      const response = await ApiService.post(
        '/schaltzentrale/deployment',
        body
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  nlpCluster: async function(botId, texts, cluster) {
    try {
      const body = {
        uniqueBotId: botId,
        texts,
        cluster,
      };

      const response = await ApiService.post(
        '/schaltzentrale/nlp/cluster',
        body
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  fakeDeploy: async function(botId, lastChangeDate) {
    try {
      const body = {
        uniqueBotId: botId,
        lastChangeDate,
      };
      const response = await ApiService.post(
        '/schaltzentrale/fakeDeployment',
        body
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  getNLPModels: async function(botId) {
    try {
      const response = await ApiService.get(
        '/schaltzentrale/nlp/model/' + botId
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  deleteNLPModel: async function(botId, modelid) {
    try {
      const response = await ApiService.delete(
        '/schaltzentrale/nlp/model/' + botId + '/' + modelid
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  updateNLPModel: async function(
    botId,
    modelId,
    live,
    staging,
    modelType = 'k1'
  ) {
    try {
      const body = {
        modelId,
        updateStaging: staging,
        updateLive: live,
        modelType,
        stage: 'prod', // change to dev
      };
      const response = await ApiService.put(
        '/schaltzentrale/bot/' + botId + '/model',
        body
      );
      return response;
    } catch (error) {
      return {};
    }
  },
  uploadNLPModel: async function(botId, modelId) {
    try {
      await ApiService.post(
        `/schaltzentrale/nlp/model/download/${botId}/dev/${modelId}`
      );
      await ApiService.post(
        `/schaltzentrale/nlp/model/download/${botId}/cluster/${modelId}`
      );
      const response = await ApiService.post(
        `/schaltzentrale/nlp/model/download/${botId}/prod/${modelId}`
      );
      return response;
    } catch (error) {
      return {};
    }
  },
  addLicence: async function(botId, licence) {
    try {
      const body = {
        licence,
      };
      const response = await ApiService.put(
        '/schaltzentrale/bot/' + botId + '/licence',
        body
      );
      return response;
    } catch (error) {
      return {};
    }
  },
  deleteLicence: async function(botId, licenceStart) {
    try {
      const body = {
        start: licenceStart,
      };
      const response = await ApiService.delete(
        '/schaltzentrale/bot/' + botId + '/licence',
        body
      );
      return response;
    } catch (error) {
      return {};
    }
  },
  cancelLicence: async function(botId, licenceStart, cancel) {
    try {
      const body = {
        start: licenceStart,
        cancel,
      };
      const response = await ApiService.post(
        '/schaltzentrale/bot/' + botId + '/licence/cancel',
        body
      );
      return response;
    } catch (error) {
      return {};
    }
  },
  confirmLicence: async function(botId, licenceStart, confirm) {
    try {
      const body = {
        start: licenceStart,
        confirm,
      };
      const response = await ApiService.post(
        '/schaltzentrale/bot/' + botId + '/licence/confirm',
        body
      );
      return response;
    } catch (error) {
      return {};
    }
  },
  getLicences: async function() {
    try {
      const response = await ApiService.get('/schaltzentrale/licences');
      return response.data;
    } catch (error) {
      return {};
    }
  },
  validateDefaultRules: async function(botId) {
    try {
      const response = await ApiService.get(
        `/schaltzentrale/validator/default/${botId}`
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  fixValidateDefaultRules: async function(botId, version = '1.0.0') {
    try {
      const body = {
        version,
      };
      const response = await ApiService.post(
        `/schaltzentrale/validator/default/${botId}`,
        body
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  getWisdomStats: async function(
    from = moment().add(-30, 'days'),
    to = moment().startOf('days')
  ) {
    try {
      const response = await ApiService.get(
        `/schaltzentrale/stats/wisdom/all/${from.format(
          'YYYY-MM-DD'
        )}/${to.format('YYYY-MM-DD')}?sort=aifeedback`
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  getBotsAccuracy: async function(botIds) {
    try {
      const response = await ApiService.post(
        '/schaltzentrale/stats/accuracy/all',
        { bots: botIds }
      );
      return response.data;
    } catch (error) {
      return { result: [] };
    }
  },
  deleteIntent: async function(botId, intent) {
    try {
      const response = await ApiService.delete(
        `/schaltzentrale/intent/${botId}/${intent}`
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  getKnowledgeBaseItems: async function(locale = 'de', stage = 'demo') {
    try {
      const response = await ApiService.get(
        `/schaltzentrale/cms/knowledge/${locale}/${stage}`
      );
      return response.data.items;
    } catch (error) {
      return {};
    }
  },
  getExpertItems: async function(locale = 'de', stage = 'demo') {
    try {
      const response = await ApiService.get(
        `/schaltzentrale/cms/expert/${locale}/${stage}`
      );
      return response.data.items;
    } catch (error) {
      return {};
    }
  },
  getContacts: async function() {
    try {
      const response = await ApiService.get('/schaltzentrale/cms/contacts');
      return response.data.items;
    } catch (error) {
      return {};
    }
  },
  getContact: async function(id) {
    if (!id) return null;

    try {
      const response = await ApiService.get(
        `/schaltzentrale/cms/contact/${id}`
      );
      return response.data.item;
    } catch (error) {
      return null;
    }
  },
  // DEPRECATED use addIntentFromSource instead
  addSmalltalk: async function(botId, smalltalk) {
    if (!smalltalk) return null;

    try {
      const response = await ApiService.post(
        `/schaltzentrale/intent/add/smalltalk/${botId}`,
        { intentName: smalltalk }
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },
  addIntentFromSource: async function(botId, intent, sourceBot) {
    if (!intent || !sourceBot) return null;

    try {
      const response = await ApiService.post(
        `/schaltzentrale/intent/add/smalltalk/${botId}`,
        { intentName: intent, sourceBot },
        120000
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },
  addNewIntent: async function({
    botId,
    samples,
    displayName,
    scenario,
    description,
  }) {
    try {
      const body = {
        uniqueBotId: botId,
        samples,
        displayName,
        scenario,
        description,
      };

      const response = await ApiService.post(
        '/schaltzentrale/intent/new/review',
        body
      );
      return response.data;
    } catch (error) {
      return false;
    }
  },
  setNlpTemplate: async function(botId, nlpTemplate) {
    try {
      const body = {
        displayName: nlpTemplate.displayName,
        templateBotId: nlpTemplate.botId,
        uniqueBotId: botId,
      };

      const response = await ApiService.post(
        '/schaltzentrale/bot/nlpTemplate',
        body
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  generateTrainingdata: async function ({
    botId,
    intentName,
    iterations = 3,
    sampleSize = 10,
    alternateDescription,
    negativeExamples,
  }) {
    try {
      const body = {
        intent: intentName,
        config: {
          translate: false,
          translateBack: false,
          iterations,
          sampleSize,
          numExampleTopics: 1,
          alternateDescription: alternateDescription || null,
          negativeExamples: negativeExamples?.length ? negativeExamples : null,
        },
      };

      const response = await ApiService.post(
        `/schaltzentrale/vgeneration/${botId}`,
        body
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  copyIntent: async function(targetBotId, body) {
    try {
      const response = await ApiService.post(
        `/schaltzentrale/intent/copy/${targetBotId}`,
        body
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  copyChannel: async function(liveBotId, intentName, channelFrom, channelTo) {
    const body = {
      intentName,
      sourceBot: liveBotId,
      targetIntent: intentName,
      sourceChannelId: channelFrom,
      targetChannelId: channelTo,
  }
    try {
      const response = await ApiService.post(
        `/schaltzentrale/intent/copy/channel/${liveBotId}`,
        body
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  setInReview: async function(botId, intent, inReview = false) {
    try {
      const body = {
        name: intent,
        inReview,
        uniqueBotId: botId,
      };
      const response = await ApiService.post(
        `/schaltzentrale/intent/inreview`,
        body
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  training: async function(botId, type = 'reference') {
    try {
      if (!botId) return false;
      
      const response = await ApiService.post(
        `/schaltzentrale/nlp/model/${botId}/training/${type}`
      );
      return response.data;
    } catch (error) {
      return false;
    }
  },
  translate: async function(text, language, botId) {
    try {
      const response = await ApiService.post(`/schaltzentrale/translate/` + botId, {
        text,
        language,
      })
      return response.data.translation
    } catch(error) {
      // empty
    }
  },
  k2check: async function({botId, channelId, text}) {
    try {
      const response = await ApiService.post(`/schaltzentrale/k2/classify/`, {
        text,
        uniqueBotId: botId,
        channelId,
        staging: true
      });
      return response.data;
    } catch (error) {
      return {};
    }
  }
};

export default ProductService;

export { ProductService };
