var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "info-card pl-2 pr-1 py-2" }, [
    _c(
      "div",
      { staticClass: "d-flex items-center" },
      [
        _c("div", { staticClass: "flex-grow-1" }, [
          _c(
            "div",
            { staticClass: "info-card-title d-flex items-center" },
            [
              _vm.item.icon
                ? _c(
                    "v-icon",
                    {
                      staticClass: "mr-1",
                      attrs: { small: "", color: "primary lighten-3" },
                    },
                    [_vm._v(" " + _vm._s(_vm.item.icon) + " ")]
                  )
                : _vm._e(),
              _c(
                "span",
                { staticClass: "text-body-2 primary--text text--lighten-3" },
                [_vm._v(" " + _vm._s(_vm.item.title) + " ")]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "info-card-body pr-1" }, [
            _vm.item.type === _vm.infoCardTypes.image
              ? _c("div", [
                  _c("div", { staticClass: "info-card-img mr-1" }),
                  _c("div", { staticClass: "info-card-img mr-1" }),
                ])
              : _c("div", [_vm._v(" " + _vm._s(_vm.item.text) + " ")]),
          ]),
        ]),
        _vm.item.downloadable
          ? _c(
              "v-btn",
              {
                staticClass: "pa-0 ml-1",
                staticStyle: { "min-width": "26px" },
                attrs: { text: "", color: "primary lighten-3" },
                on: { click: _vm.handleDownload },
              },
              [
                _c("v-icon", { attrs: { small: "" } }, [
                  _vm._v("mdi-download"),
                ]),
              ],
              1
            )
          : _vm._e(),
        _vm.item.lookable
          ? _c(
              "v-btn",
              {
                staticClass: "pa-0 ml-1",
                staticStyle: { "min-width": "26px" },
                attrs: { text: "", color: "primary lighten-3" },
                on: { click: _vm.handleLook },
              },
              [_c("v-icon", { attrs: { small: "" } }, [_vm._v("mdi-eye")])],
              1
            )
          : _vm._e(),
        _vm.item.copyable
          ? _c(
              "v-btn",
              {
                staticClass: "pa-0 ml-1",
                staticStyle: { "min-width": "26px" },
                attrs: { text: "", color: "primary lighten-3" },
                on: { click: _vm.handleCopy },
              },
              [
                _c("v-icon", { attrs: { small: "" } }, [
                  _vm._v("mdi-content-copy"),
                ]),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }