var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-text-field", {
    staticClass: "ma-0 pa-0",
    attrs: {
      "v-mask": "!#XXXXXX",
      "persistent-hint": "",
      hint: "test",
      "hide-details": "",
      label: _vm.$t(
        `channelSettings.appearance.themecolors.${_vm.colorkey}.label`
      ),
      outlined: "",
    },
    scopedSlots: _vm._u([
      {
        key: "append",
        fn: function () {
          return [
            _c(
              "v-menu",
              {
                attrs: {
                  top: "",
                  "nudge-bottom": "105",
                  "nudge-left": "16",
                  "close-on-content-click": false,
                },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function ({ on }) {
                      return [
                        _c(
                          "div",
                          _vm._g(
                            { style: _vm.swatchStyle(_vm.value.color) },
                            on
                          )
                        ),
                      ]
                    },
                  },
                ]),
              },
              [
                _c(
                  "v-card",
                  [
                    _c(
                      "v-card-text",
                      { staticClass: "pa-0" },
                      [
                        _c("v-color-picker", {
                          attrs: { mode: "hexa", flat: "" },
                          on: { input: _vm.propagateColorChange },
                          model: {
                            value: _vm.value.color,
                            callback: function ($$v) {
                              _vm.$set(_vm.value, "color", $$v)
                            },
                            expression: "value.color",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.value.color,
      callback: function ($$v) {
        _vm.$set(_vm.value, "color", $$v)
      },
      expression: "value.color",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }