var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("Facebook authorisieren via OAuth")]),
          _c("v-card-subtitle", [
            _vm._v(
              "Facebook authorisieren damit es als Kanal benutzt werden kann"
            ),
          ]),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm.channels.length == 0
                ? _c(
                    "v-btn",
                    {
                      attrs: { color: "success", dark: "", small: "" },
                      on: {
                        click: function ($event) {
                          return _vm.logInWithFacebook()
                        },
                      },
                    },
                    [
                      _vm._v(" Auth "),
                      _c("v-icon", { attrs: { right: "", dark: "" } }, [
                        _vm._v("mdi-robot-love-outline"),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.channels.length > 0
                ? _c(
                    "v-btn",
                    {
                      attrs: { color: "success", dark: "", small: "" },
                      on: {
                        click: function ($event) {
                          return _vm.chooseChannel()
                        },
                      },
                    },
                    [
                      _vm._v(" Select this Channel "),
                      _c("v-icon", { attrs: { right: "", dark: "" } }, [
                        _vm._v("mdi-robot-love-outline"),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.channels
            ? _c(
                "div",
                [
                  _c(
                    "v-list",
                    [
                      _c(
                        "v-list-item-group",
                        {
                          attrs: { mandatory: true },
                          model: {
                            value: _vm.selectedItem,
                            callback: function ($$v) {
                              _vm.selectedItem = $$v
                            },
                            expression: "selectedItem",
                          },
                        },
                        _vm._l(_vm.channels, function (item) {
                          return _c(
                            "v-list-item",
                            {
                              key: item.id,
                              attrs: { value: item },
                              on: {
                                click: function ($event) {
                                  return _vm.itemType(item)
                                },
                              },
                            },
                            [
                              _c(
                                "v-list-item-avatar",
                                [
                                  _c("v-img", {
                                    attrs: {
                                      src: `https://graph.facebook.com/${item.id}/picture?app_id=${_vm.appId}`,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(item.name)),
                                  ]),
                                  _c("v-list-item-subtitle", [
                                    _vm._v(_vm._s(item.category)),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: 6000 },
          model: {
            value: _vm.savingToast,
            callback: function ($$v) {
              _vm.savingToast = $$v
            },
            expression: "savingToast",
          },
        },
        [
          _vm._v(" " + _vm._s(_vm.message)),
          _c("v-spacer"),
          _c("v-icon", { attrs: { type: "success" } }, [_vm._v("check")]),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: 6000 },
          model: {
            value: _vm.savingToastError,
            callback: function ($$v) {
              _vm.savingToastError = $$v
            },
            expression: "savingToastError",
          },
        },
        [
          _vm._v(" Fehler: " + _vm._s(_vm.message)),
          _c("v-spacer"),
          _c("v-icon", { attrs: { color: "red", type: "error" } }, [
            _vm._v("mdi-alert-circle"),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }