var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        {
          staticClass: "text-center align-self-center hidden-sm-and-down",
          attrs: { cols: "12", md: "6" },
        },
        [
          _c("WidgetPrivacyPreview", {
            attrs: {
              startScreen: _vm.startScreen,
              bgColor: _vm.bgColor,
              widgetTitle: _vm.widgetTitle,
              headerContrast: _vm.headerContrast,
              avatarUrl: _vm.avatar,
            },
          }),
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "12", md: "6" } },
        [
          _c("v-card-title", [
            _vm._v(
              " " + _vm._s(_vm.$t("editor.widget.startscreen.title")) + " "
            ),
          ]),
          _c(
            "v-row",
            [
              _c("v-col", [
                _c(
                  "div",
                  { staticClass: "px-4 mb-4" },
                  [
                    _c("v-switch", {
                      attrs: {
                        "input-value": _vm.startScreen,
                        color: _vm.startScreen ? "green" : "grey",
                        inset: "",
                        label: _vm.$t("editor.widget.startscreen.switchLabel"),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.setStartScreen($event)
                        },
                      },
                    }),
                  ],
                  1
                ),
                _vm.startScreen
                  ? _c(
                      "div",
                      { staticClass: "px-4" },
                      [
                        _c("v-text-field", {
                          attrs: {
                            outlined: "",
                            label: _vm.$t(
                              "editor.widget.startscreen.greetingLabel"
                            ),
                          },
                          on: {
                            input: function ($event) {
                              return _vm.update()
                            },
                          },
                          model: {
                            value: _vm.startScreen.greeting,
                            callback: function ($$v) {
                              _vm.$set(_vm.startScreen, "greeting", $$v)
                            },
                            expression: "startScreen.greeting",
                          },
                        }),
                        _c("v-textarea", {
                          attrs: {
                            outlined: "",
                            label: _vm.$t(
                              "editor.widget.startscreen.textLabel"
                            ),
                          },
                          on: {
                            input: function ($event) {
                              return _vm.update()
                            },
                          },
                          model: {
                            value: _vm.startScreen.text,
                            callback: function ($$v) {
                              _vm.$set(_vm.startScreen, "text", $$v)
                            },
                            expression: "startScreen.text",
                          },
                        }),
                        _c("v-card-text", { staticClass: "pl-0" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("editor.widget.startscreen.linksLabel")
                              ) +
                              " "
                          ),
                        ]),
                        _c("WidgetPrivacyLinks", {
                          staticClass: "mb-8",
                          attrs: { loading: _vm.isLoading },
                          on: {
                            input: function ($event) {
                              return _vm.update()
                            },
                          },
                          model: {
                            value: _vm.startScreen.links,
                            callback: function ($$v) {
                              _vm.$set(_vm.startScreen, "links", $$v)
                            },
                            expression: "startScreen.links",
                          },
                        }),
                        _c("v-text-field", {
                          attrs: { outlined: "", label: "Button Label" },
                          on: {
                            input: function ($event) {
                              return _vm.update()
                            },
                          },
                          model: {
                            value: _vm.startScreen.buttonLabel,
                            callback: function ($$v) {
                              _vm.$set(_vm.startScreen, "buttonLabel", $$v)
                            },
                            expression: "startScreen.buttonLabel",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }