import { render, staticRenderFns } from "./IntentsDialogSuggestion.vue?vue&type=template&id=03b14cab"
import script from "./IntentsDialogSuggestion.vue?vue&type=script&lang=js"
export * from "./IntentsDialogSuggestion.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/moin-hub/moin-hub/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('03b14cab')) {
      api.createRecord('03b14cab', component.options)
    } else {
      api.reload('03b14cab', component.options)
    }
    module.hot.accept("./IntentsDialogSuggestion.vue?vue&type=template&id=03b14cab", function () {
      api.rerender('03b14cab', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/intents/IntentsDialogSuggestion.vue"
export default component.exports