const state = {
  // {[botId]: channelId}
  lastChannelId: {}
}

const getters = {
  /**
   * Return the channel ID from the URL query string
   * @returns {string|null}
   */
  queryChannelId() {
    return new URLSearchParams(window.location.search)?.get("channel") || null;
  },
  /**
   * Resolves which channel you are going to want to be using, for the currently selected bot.
   * Prefers URL query's channel if set, otherwise it uses the last known selected channel, and if not, falls back to the first/default one.
   * Validates that the channel returned also exists in the bot.
   * @param {Object} state state
   * @param {Object} getters getters
   * @param {Object} _ rootState
   * @param {Object} rootGetters
   * @returns {string|null}
   */
  suggestedChannelId(state, getters, _, rootGetters) {
    const botId = rootGetters['bots/currentBotId'];
    if (botId === null) return null;

    const channelExists = (channelId) => {
      return rootGetters['bots/currentChannels']
        .some(ch => !ch.blocked && ch.channelId === channelId);
    };

    let channelId = getters.queryChannelId;
    if (channelId && channelExists(channelId)) return channelId;

    channelId = state.lastChannelId[botId];
    if (channelId && channelExists(channelId)) return channelId;

    // Fallback: First channel that is not blocked
    const bot = rootGetters['bots/getBot'](botId);
    const ch = bot?.channels?.find(ch => !ch.blocked);
    if (ch) return ch.channelId;

    // If all channels blocked, use the first channel regardless,
    // or `null` if no channels at all.
    return bot?.channels?.[0]?.channelId ?? null;
  },
  /**
   * Write just the query parameter, specifically
   * @returns {(channelId: string) => void}
   */
  setQueryChannelId() {
    return (channelId) => {
      // First, remove all params
      const url = new URL(window.location.href);
      while(url.searchParams.has("channel")) url.searchParams.delete("channel");
      // If channel ID is not null, add it
      if (channelId !== null) url.searchParams.append("channel", channelId);

      window.history.replaceState(window.history.state, "", url.toString());
    }
  }
}

const actions = {
  /**
   * Get the last selected channel for a bot by ID
   * @param {any} state
   * @param {string} botId
   * @returns {string|null}
   */
  lastSelectedChannelId({state}, botId) {
    return state.lastChannelId[botId] ?? null;
  },
  /**
   * Exist so you can call mutation from other namespaced modules to update this preference.
   * Ideally, you should set the channel ID in `bot/setCurrentChannelId` instead.
   * @private
   * @param commit
   * @param dispatch
   * @param {string} botId
   * @param {string} channelId
   */
  async setLastChannelId({commit, dispatch}, {botId, channelId}) {
    commit('setLastChannelId', {botId, channelId});
  },
  /**
   * Updates the web URL according to the input.
   * Removes redundant channel query, and adds if relevant
   * @param rootGetters
   * @param getters
   * @param {string} botId
   * @param {string} channelId
   */
  updateQueryParams({ rootGetters, getters }, {botId, channelId}) {
    return; // Not in use, but kept around for a bit longer just in case.

    const channels = rootGetters['bots/currentChannels']
    const url = new URL(window.location.href);
    const queryChId = url.searchParams.get("channel");

    // Usually we shouldn't need these checks if done right, but done just in case.
    // Careful handling: if channelId is null, replace with first channel
    if (channelId === null) channelId = channels?.[0]?.channelId;
    // Careful handling: if not explicitly defined, read from URL query
    if (!channelId) channelId = queryChId;
    // Careful handling: You can have multiple identical queries. Remove all but one.
    while(url.searchParams.has("channel")) url.searchParams.delete("channel");
    url.searchParams.append("channel", queryChId);


    // 0 or 1 channels || first channel selected: Remove redundant channel query parameter
    if (channels.length <= 1 || channels?.[0]?.channelId === channelId) {
      url.searchParams.delete("channel");

      // Stop processing
      return window.history.replaceState(window.history.state, "", url.toString());
    }

    // ... by now channelId cannot possibly be the default channel ID.

    // Channel provided, but it's not matching the URL query (or no query set)? Change it
    if (queryChId !== channelId) {
      url.searchParams.delete("channel");
      url.searchParams.append("channel", channelId);
    }
    // 2+ channels: Add current channel to query parameters, if it is not the default one
    else if (channels.length > 1) {
      const chId = getters['suggestedChannelId'];

      // Channel at index 0 is the default channel.
      if (chId !== channels[0]?.channelId) {
        url.searchParams.delete("channel");
        url.searchParams.append("channel", chId);
      }
    }

    // Update params with the final state
    window.history.replaceState(window.history.state, "", url.toString());
  }
}

const mutations = {
  /**
   * Sets which channel user last viewed.
   *
   * NB:
   * If you want to switch the channel of the current bot, use `bot/setCurrentChannelId` instead.
   * It will be propagated here automatically.
   * @private
   * @param state
   * @param {{botId: string, channelId: string|null}} payload
   */
  setLastChannelId(state, payload) {
    state.lastChannelId[payload.botId] = payload.channelId;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}