var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("DemoStep", [
    _c(
      "div",
      [
        _vm.hasError
          ? _c("div", [_vm._v("Ein Fehler ist aufgetreten.")])
          : [
              _vm._l(_vm.questions, function (item) {
                return _c("DemoQuestion", {
                  key: item.id,
                  staticClass: "mb-2",
                  attrs: { question: item },
                  on: {
                    onToggle: function ($event) {
                      return _vm.toggleCheck(item)
                    },
                    onAnswer: function ($event) {
                      return _vm.openAnswerDialog(item)
                    },
                  },
                })
              }),
              _c("DemoBtns", {
                on: {
                  onBack: function ($event) {
                    return _vm.back()
                  },
                  onNext: function ($event) {
                    return _vm.next()
                  },
                },
              }),
            ],
        _c("DemoAnswerDialog", {
          attrs: { open: _vm.isAnswerOpen, question: _vm.questionForDialog },
          on: {
            onClose: function ($event) {
              _vm.isAnswerOpen = false
            },
          },
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }