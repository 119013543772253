import { render, staticRenderFns } from "./WidgetPreview.vue?vue&type=template&id=9234b06e&scoped=true"
import script from "./WidgetPreview.vue?vue&type=script&lang=js"
export * from "./WidgetPreview.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9234b06e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/moin-hub/moin-hub/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9234b06e')) {
      api.createRecord('9234b06e', component.options)
    } else {
      api.reload('9234b06e', component.options)
    }
    module.hot.accept("./WidgetPreview.vue?vue&type=template&id=9234b06e&scoped=true", function () {
      api.rerender('9234b06e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/widget/WidgetPreview.vue"
export default component.exports