<template>
  <HubBox
    :title="$t('common.general')"
    boxId="intents-general"
  >
    <v-tabs v-model="languageIndex" centered grow class="mb-2">
      <v-tab
        v-for="language in languages"
        :key="language.key"
      >
        {{ language.label }}
      </v-tab>
    </v-tabs>

    <div class="text-body-2">
      {{$t('intent.general.description')}}
    </div>

    <div class="mt-4">
      <p class="primary--text text--lighten-3 mb-1 text-body-2">
        {{ $t('intent.name') }}
      </p>
      <p :class="{'font-italic grey--text': !displayNameOriginal}">
        {{ displayNameOriginal || $t('intentNew.editIntent.notTranslated') }}
      </p>
    </div>

    <div class="mt-4">
      <p class="primary--text text--lighten-3 mb-1 text-body-2">
        {{ $t('intent.description') }}
      </p>
      <p :class="{'font-italic grey--text': !descriptionOriginal}">
        {{ descriptionOriginal || $t('intentNew.editIntent.notTranslated') }}
      </p>
    </div>

    <div class="mt-6">
      <v-btn
        outlined
        color="primary lighten-3"
        @click="editDialogOpen = true"
      >
        {{ $t('common.edit') }}
        <v-icon right>edit</v-icon>
      </v-btn>
    </div>

    <HubDialog
      :value="editDialogOpen"
      :primary-label="$t('common.save')"
      primary-icon="save"
      :secondary-label="$t('common.cancel')"
      secondary-icon="close"
      :title="$t('intentNew.editIntent.title')"
      @onPrimary="saveEdit"
      @onSecondary="cancelEdit"
      :disabled="!isValid || !isRootLanguageValid"
      modal
    >
      <v-form v-model="isValid">
        <v-tabs v-model="languageIndex" centered grow>
          <v-tab
            v-for="language in languages"
            :key="language.key"
          >
            {{ language.label }}
          </v-tab>
        </v-tabs>

        <!-- Error warning that root cannot be empty -->
        <v-alert
          v-if="!isRootLanguageValid"
          class="mt-2"
          type="error"
          outlined
          dense
          border="left"
          color="error"
        >
          {{ $t('intentNew.editIntent.allFieldsMandatory', { language: $t(languageKeyOfRoot ? `common.languageNames.${languageKeyOfRoot}` : 'common.original') }) }}
        </v-alert>

        <v-row class="mt-4">
          <v-col cols="12">
            <div class="d-flex justify-space-between gap-2 items-baseline">
              <label for="displayName" class="subtitle-1">
                {{ displayNameLabel }}
              </label>

              <v-checkbox
                v-if="!currentIsRoot"
                :label="$t('intentNew.editIntent.autoTranslate')"
                v-model="translateCurrentName"
                hide-details
                class="pa-0 ma-0"
              />
            </div>
            <InfoBox
              v-if="!translateCurrentName && !currentIsRoot"
              :text="$t('intentNew.stepName.displayName.hint')"
              :icon="null"
            />
            <InfoBox
              v-else
              :text="$t('intentNew.editIntent.willAutoTranslateName')"
              :icon="null"
            />
            <v-text-field
              name="displayName"
              v-model="displayName"
              outlined
              color="secondary darken-2"
              autofocus
              :rules="translateCurrentName ? [] : rules.displayName"
              :disabled="translateCurrentName && !currentIsRoot"
            />
          </v-col>
        </v-row>
        <v-row class="mt-4">
          <v-col cols="12">
            <div class="d-flex justify-space-between gap-2 items-baseline">
              <label for="description" class="subtitle-1">
                {{ descriptionLabel }}
              </label>

              <v-checkbox
                v-if="!currentIsRoot"
                :label="$t('intentNew.editIntent.autoTranslate')"
                v-model="translateCurrentDescription"
                hide-details
                class="pa-0 ma-0"
              />
            </div>
            <InfoBox
              v-if="!translateCurrentDescription && !currentIsRoot"
              :text="$t('intentNew.stepName.description.hint')"
              :icon="null"
            />
            <InfoBox
              v-else
              :text="$t('intentNew.editIntent.willAutoTranslateDesc')"
              :icon="null"
            />
            <v-textarea
              name="description"
              v-model="description"
              outlined
              color="secondary darken-2"
              :readonly="!isAdmin"
              :disabled="!isAdmin || (translateCurrentDescription && !currentIsRoot)"
              :rules="translateCurrentDescription ? [] : rules.description"
            />
          </v-col>
        </v-row>
      </v-form>
    </HubDialog>
  </HubBox>
</template>

<script>
import HubBox from '@/components/hub/HubBox.vue';
import InfoBox from '@/components/common/InfoBox.vue';
import HubDialog from '../hub/HubDialog.vue';
import ProductService from '@/services/product';

import { mapGetters } from 'vuex';

export default {
  name: 'IntentGeneral',
  components: {
    HubBox,
    InfoBox,
    HubDialog,
  },
  props: {
    intent: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isValid: false,
      editDialogOpen: false,
      draftIntent: null,
      languageKeyOfRoot: null,
      languageIndex: 0,
      languages: [],
      rules: {
        displayName: [
          v => !!v || this.$t('intentNew.stepName.errorMessage'),
        ],
        description: [
          v => !!v || this.$t('intentNew.stepName.errorMessage'),
        ]
      },
      autoTranslate: {
        /**
         * Leave these off by default.
         * If enabled you might damage the manual corrections users have done, so it must be explicit.
         */
        de: {
          displayName: false,
          description: false,
        },
        en: {
          displayName: false,
          description: false,
        },
        // More might be generated...
      }
    };
  },
  computed:{
    ...mapGetters('auth', ['isAdmin']),
    /**
     * Get and Set whether you want DeepL to auto-translate the Display Name of the current language
     */
    translateCurrentName: {
      get() {
        if (this.currentIsRoot) return false;
        return this.autoTranslate[this.languageKey]?.displayName ?? '';
      },
      set(newValue) {
        if (this.currentIsRoot) return;
        return this.autoTranslate[this.languageKey].displayName = newValue;
      },
    },
    /**
     * Get and Set whether you want DeepL to auto-translate the Description of the current language
     */
    translateCurrentDescription: {
      get() {
        if (this.currentIsRoot) return false;
        return this.autoTranslate[this.languageKey].description;
      },
      set(newValue) {
        if (this.currentIsRoot) return;
        return this.autoTranslate[this.languageKey].description = newValue;
      },
    },
    /**
     * Checks whether the current language key is the root one
     * @returns {boolean}
     */
    currentIsRoot() {
      const key = this.languageKey === 'ORIGINAL' ? null : this.languageKey;
      return key === this.languageKeyOfRoot;
    },
    /**
     * Display label for the Display Name input field
     * @returns {string}
     */
    displayNameLabel() {
      if (this.languageKey === 'ORIGINAL') return 'Name in original language';
      return `${this.$t(`common.languageNames.${this.languageKey}`)} ${this.$t('common.title')}`;
    },
    /**
     * Display label for the Description input field
     * @returns {string}
     */
    descriptionLabel() {
      if (this.languageKey === 'ORIGINAL') return 'Description in original language';
      return `${this.$t(`common.languageNames.${this.languageKey}`)} ${this.$t('common.description')}`;
    },
    /**
     * Language key of the currently selected language
     * @returns {'ORIGINAL'|string}
     */
    languageKey() {
      return this.languages?.[this.languageIndex]?.key ?? 'ORIGINAL';
    },
    /**
     * Get and set the Display Name for the currently selected language
     */
    displayName: {
      get() {
        if (this.languageKey === 'ORIGINAL') return this.draftIntent.displayName;
        return this.draftIntent?.translation?.[this.languageKey]?.displayName || '';
      },
      set(newValue) {
        // No match, modify the root, so that translations can be based on it
        if (this.languageKey === 'ORIGINAL') return this.draftIntent.displayName = newValue;

        // If current language is same as root, also update the root
        if (this.languageKey === this.languageKeyOfRoot) this.draftIntent.displayName = newValue;

        // Update the translation
        return this.draftIntent.translation[this.languageKey].displayName = newValue;
      },
    },

    /**
     * Get and set the Description for the currently selected language
     */
    description: {
      get() {
        if (this.languageKey === 'ORIGINAL') return this.draftIntent.description;
        return this.draftIntent?.translation?.[this.languageKey]?.description || '';
      },
      set(newValue) {
        // No match, modify the root, so that translations can be based on it
        if (this.languageKey === 'ORIGINAL') return this.draftIntent.description = newValue;

        // If current language is same as root, also update the root
        if (this.languageKey === this.languageKeyOfRoot) this.draftIntent.description = newValue;

        // Update the translation
        return this.draftIntent.translation[this.languageKey].description = newValue;
      },
    },
    /**
     * Returns the unmodified Display Name of the currently selected language, or null if not translated
     * @returns {string | null}
     */
    displayNameOriginal() {
      if (this.languageKey === 'ORIGINAL') return this.intent.displayName;
      return this.intent?.translation?.[this.languageKey]?.displayName || null;
    },
    /**
     * Returns the unmodified Description of the currently selected language, or null if not translated
     * @returns {string | null}
     */
    descriptionOriginal() {
      if (this.languageKey === 'ORIGINAL') return this.intent.description;
      return this.intent?.translation?.[this.languageKey]?.description || null;
    },
    /**
     * Checks whether the root has a valid name and description.
     * The translations could be left empty to auto-translate, but the root cannot be left empty.
     */
    isRootLanguageValid() {
      return !!this.draftIntent.displayName && !!this.draftIntent.description;
    },
  },
  methods: {
    cancelEdit() {
      this.editDialogOpen = false;
    },
    async saveEdit() {
      /**
       * We want to perform additional hacks on the intent to produce auto-translation,
       * but the components do not like the bug this would produce, in addition to the
       * new incorrect state the draft would have.
       *
       * Instead, we modify a copy dedicated for the HTTP request.
       */
      const requestPayload = structuredClone(this.draftIntent);
      for (const langKey in this.autoTranslate) {
        if (!(langKey in requestPayload.translation) || langKey === this.languageKeyOfRoot) continue;

        // Empty fields are forced to be auto-translated
        if (this.autoTranslate[langKey].displayName) {
          requestPayload.translation[langKey].displayName = null;
        }
        if (this.autoTranslate[langKey].description) {
          requestPayload.translation[langKey].description = null;
        }
      }

      const result = await ProductService.updateIntent(requestPayload);
      if (result.status !== 'ok') {
        console.error(result?.message ?? 'An unknown error occurred when updating the intent');
        this.editDialogOpen = false;
        return;
      }

      // Current instance
      this.intent.translation = result.intent.translation;
      this.intent.description = result.intent.description;
      this.intent.displayName = result.intent.displayName;
      this.draftIntent = result.intent;
      // Persistence
      this.$store.commit('intents/setIntent', this.draftIntent);

      this.editDialogOpen = false;
      this.init();
    },
    init() {
      this.reset();

      // Ensure we have at least always German and English
      const langKeys = Object.keys(this.draftIntent.translation);
      if (!langKeys.includes('de')) langKeys.push('de');
      if (!langKeys.includes('en')) langKeys.push('en');

      for (const langKey of langKeys) {
        this.languages.push({
          label: this.$t(`common.languageNames.${langKey}`),
          key: langKey,
        });

        // Ensure properties exists
        if (!this.draftIntent.translation[langKey]) {
          this.draftIntent.translation[langKey] = {
            displayName: '',
            description: '',
            samples: [],
          };
        }
        if (!this.autoTranslate[langKey]) {
          this.autoTranslate[langKey] = {
            displayName: false,
            description: false,
          };
        }
      }

      /**
       * If your root language is e.g. French, then what do you display?
       * We don't *actually* know the source language, but we just know it's different from in the translations.
       *
       * The solution is that we add a third tab "Original" if the name & description
       * does not exactly match one of the other languages.
       *
       * If it is exactly the same, we do not need the "original" tab
       */
      this.languageKeyOfRoot = this.calculateLanguageKeyOfRoot();
      if (!this.languageKeyOfRoot) {
        this.languages.unshift({
          label: this.$t(`common.original`),
          key: 'ORIGINAL',
        });
      }
    },
    /**
     * If your root name & description matches exactly one of the translations (eng/ger)
     * Not a computed property to avoid sudden merging of tabs when Original and another language matches after edits.
     * @returns {null|string} Language key or null if no match
     */
    calculateLanguageKeyOfRoot() {
      const name = this.intent?.displayName, description = this.intent?.description;

      for (const langKey in this.intent.translation) {
        if (this.intent.translation[langKey].displayName !== name) continue;
        if (this.intent.translation[langKey].description !== description) continue;
        return langKey;
      }

      return null;
    },
    /**
     * Checks for non-root languages if they should be auto-translated because they are empty.
     * The backend will do this anyway if they're empty, so it's mostly for UI purposes.
     */
    calculateAutoTranslateEnabled() {
      for (const langKey in this.draftIntent.translation) {
        if (!this.draftIntent.translation[langKey]?.displayName) {
          this.autoTranslate[langKey].displayName = true;
        }
        if (!this.draftIntent.translation[langKey]?.description) {
          this.autoTranslate[langKey].description = true;
        }
      }
    },
    reset() {
      this.languageKeyOfRoot = null;
      this.languages = [];
      this.languageIndex = 0;
      this.draftIntent = structuredClone(this.intent);
      if (!this.draftIntent?.translation) this.draftIntent.translation = {};
      this.autoTranslate = {
        de: {
          displayName: false,
          description: false,
        },
        en: {
          displayName: false,
          description: false,
        },
      };
    }
  },
  created() {
    this.init();
    this.calculateAutoTranslateEnabled();
  },
  watch: {
    editDialogOpen: function() {
      this.init();
      this.calculateAutoTranslateEnabled();
    }
  },
};
</script>
