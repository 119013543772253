<script>
import { mapGetters, mapMutations } from 'vuex';
import Settings from '@/services/settings';
import BtnSquare from '@/components/common/BtnSquare.vue';
import SnackbarStack from '@/components/tools/SnackbarStack.vue';

export default {
  name: 'ShopwareAuth',
  components: { SnackbarStack, BtnSquare },
  props: {},
  data() {
    return {
      loading: false,
      authType: 'integration',
      testWasSuccessful: false,

      url: '',
      identifier: '',
      secret: '',

      urlRules: [
        (v) => {
          return (v||'').startsWith('https://') || this.$t('integrations.rules.url');
        },
      ],

      // Read-only Shopware settings, if already set
      currentSettings: null,
      hideSecret: true,

      // Populated on mounted
      botIds: {live: '', staging: ''},
    };
  },
  methods: {
    ...mapMutations('hubUi', ['addAlert']),
    async save(skipSuccessAlert = false) {
      this.loading = true;

      try {
        const saveResult = await Settings.saveOAuthToken(
          this.botIds.live,
          'shopware',
          undefined,
          undefined,
          {
            authType: this.authType,
            url: this.normalizedUrl,
            identifier: this.identifier,
            secret: this.secret,
          }
        );

        if (saveResult.error) throw new Error(saveResult.error);
        if (saveResult !== true) throw new Error(this.$t('integrations.shopware.alerts.failed'));
        if (!skipSuccessAlert) {
          this.addAlert({
            message: this.$t('integrations.shopware.alerts.success'),
            scope: 'shopware.setup',
            type: 'success',
          });
        }
      } catch (e) {
        this.addAlert({
          message: e.message || e.toString(),
          scope: 'shopware.setup',
          type: 'error',
        });
      } finally {
        this.loading = false;
      }
    },
    async update() {
      this.loading = true;

      // Update is a bit tricky.
      try {
        /**
         * If just the URL is changed, we want to just transform that.
         * Use-case is correcting the API endpoint in case of mistake or if it moved.
         * However, we cannot re-use OAuth endpoint because we don't necessarily have the
         * base auth details needed to recreate (overwrite) the settings. The user would
         * have to re-provide them, which they might not have.
         *
         * Therefore, use a different endpoint that just patches the URL.
         */
        if (this.onlyUrlChanged) {
          await this._updateJustUrl(this.url);
          this.addAlert({
            message: this.$t('integrations.shopware.alerts.endpointUpdated'),
            scope: 'shopware.setup',
            type: 'success',
          });
          return;
        }

        /**
         * For basic auth we don't have any original values we can pre-populate in the
         * UI fields, so they must provide everything so we can redo the auth.
         *
         * For integration auth we can easily re-use values and merge existing ones,
         * but we still need to redo the OAuth to get access token using the new details,
         * so we cannot use the endpoint that only updates properties.
         */
        await this.save(true);
        this.addAlert({
          message: this.$t('integrations.shopware.alerts.updated'),
          scope: 'shopware.setup',
          type: 'success',
        });
      } catch(e) {
        this.addAlert({
          message: e.message || e.toString(),
          scope: 'shopware.setup',
          type: 'error',
        });
      } finally {
        this.loading = false;
      }
    },
    _updateJustUrl(newUrl) {
      return Settings.patchSetting(
        this.botIds.live,
        'shopware',
        { url: newUrl }
      );
    },
    async test() {
      this.loading = true;

      try {
        const r = await Settings.testBotSetting(
          this.botIds.live,
          'shopware',
          {
            authType: this.authType,
            url: this.normalizedUrl,
            identifier: this.identifier,
            secret: this.secret,
          }
        );
        if (r.error) throw r.error;
        this.testWasSuccessful = !!r?.success;
        this.addAlert({
          message: this.$t('integrations.shopware.alerts.testSuccess'),
          scope: 'shopware.setup',
          type: 'success',
        });
      } catch(e) {
        this.testWasSuccessful = false;
        this.addAlert({
          message: e.message || e.toString(),
          scope: 'shopware.setup',
          type: 'error',
        });
      } finally {
        this.loading = false;
      }
    },

  },
  computed: {
    ...mapGetters('bots', ['currentBot', 'currentBotId', 'getBotSettings']),
    /**
     * The base URL without a trailing slash
     * @returns {string}
     */
    normalizedUrl() {
      if (!this.url) return 'https://missing.site.url';
      return this.url.endsWith('/') ? this.url.slice(0, -1) : this.url;
    },
    /**
     * Check if the URL is the only thing that has changed
     */
    onlyUrlChanged() {
      // Comparison is only used for Updates to settings
      if (!this.currentSettings) return false;

      if (this.url === this.currentSettings.url) return false;
      if (this.authType !== this.currentSettings.authType) return false;

      if (this.authType === 'user') {
        // Username and password isn't stored, so they are expected to be empty
        if (this.identifier) return false;
        if (this.secret) return false;

        return true;
      }

      if (this.identifier !== this.currentSettings.clientId) return false;
      if (this.secret !== this.currentSettings.secret) return false;

      return true;
    },
    canStartTest() {
      if (!this.url) return false;
      if (this.onlyUrlChanged) return true;

      const onlyOneSet = !!(!this.identifier && this.secret) || !!(this.identifier && !this.secret);
      const bothSet = !!(this.identifier && this.secret);

      if (this.authType === 'user') {
        // For user auth we do not require username/pw to test if it was previously configured...
        if (this.currentSettings?.authType === 'user') {
          // ... but if either is set, both must be set
          if (onlyOneSet) return false;

          return true;
        }

        // If not previously set up, both must be defined
        return bothSet;
      }

      return bothSet;
    },
  },
  watch: {
    authType(oldType, newType) {
      // Only reset if actual change + it was already initialized
      if (oldType === newType && !!oldType) return;

      this.identifier = '';
      this.secret = '';
    },
    onlyUrlChanged(newValue) {
      // Reset test result if they change URL
      if (!newValue) return;
      this.testWasSuccessful = false;
    },
  },
  mounted() {
    this.botIds = {
      staging: this.currentBot.stagingBot ? this.currentBot.stagingBot : this.currentBot.uniqueBotId,
      live: this.currentBot.stagingBot ? this.currentBot.uniqueBotId : this.currentBot.stagingBot,
    };

    // See if we have set up shopware already in the staging bot, fallback to prod bot.
    let settings = this.getBotSettings(this.botIds.live);
    if (!settings.shopware) settings = this.getBotSettings(this.botIds.staging);
    if (!settings.shopware) return; // Not set up

    this.currentSettings = Object.freeze(settings.shopware);
    this.url = this.currentSettings.url;
    this.authType = this.currentSettings.authType;
    const usesIntegration = this.currentSettings.authType === 'integration';
    if (usesIntegration && this.currentSettings.clientId) {
      this.identifier = this.currentSettings.clientId;
    }
    if (usesIntegration && this.currentSettings.secret) {
      this.secret = this.currentSettings.secret;
    }
  },
};
</script>

<template>
  <div>
    <v-card>
      <v-card-title>{{ $t('integrations.shopware.title') }}</v-card-title>
      <p class="px-4">
        {{ $t('integrations.shopware.description') }}
        <br />
        <a :href="'https://helpcenter.moin.ai/shopware'" target="_blank" rel="noopener noreferrer">
          {{ $t('integrations.shopware.helpLink') }}
        </a>
      </p>
      

      <div class="d-flex">
        <v-btn-toggle
          class="pb-4 mx-4"
          v-model="authType"
          mandatory
          borderless
          color="primary lighten-2"
        >
          <v-btn style="min-width: 120px" value="integration">
            <span>{{ $t('integrations.shopware.integration.label') }}</span>
          </v-btn>

          <v-btn style="min-width: 120px" value="user">
            <span>{{ $t('integrations.shopware.user.label') }}</span>
          </v-btn>
        </v-btn-toggle>

        <v-alert
          v-if="currentSettings"
          outlined
          color="primary lighten-3"
          class="info-box-outline primary--text text--lighten-3 py-2"
        >
          <v-icon color="primary lighten-3">mdi-lightbulb</v-icon>
          <span class="ml-2">
            {{
              $t('integrations.shopware.alreadyConfigured', { credentials: $t(`integrations.shopware.${currentSettings.authType}.label`) })
            }}
          </span>
        </v-alert>
      </div>

      <div class="mx-4 d-flex gap-4">
        <v-text-field
          v-model="identifier"
          :label="$t(`integrations.shopware.${authType}.identifier`)"
          :placeholder="$t(`integrations.shopware.${authType}.placeholder`)"
          outlined
        />
        <v-text-field
          v-model="secret"
          :label="$t(`integrations.shopware.${authType}.secret`)"
          class="no-inner-margin-top"
          :type="hideSecret ? 'password' : 'text'"
          outlined
        >
          <template #prepend-inner>
            <BtnSquare
              preset="basic"
              aria-label="Show or hide value"
              :icon="hideSecret ? 'mdi-eye' : 'mdi-eye-off'"
              @click="hideSecret = !hideSecret"
            />
          </template>
        </v-text-field>
      </div>
      <v-text-field
        class="px-4"
        :label="$t('integrations.shopware.url.label')"
        v-model="url"
        :rules="urlRules"
        :placeholder="$t('integrations.shopware.url.placeholder')"
        outlined
        type="url"
      />
      <div class="d-flex justify-end">
        <v-btn
          color="success"
          class="ma-4"
          :disabled="!canStartTest || loading"
          @click="test"
        >
          {{ $t('integrations.shopware.performTest') }}
          <v-icon right dark>mdi-test-tube</v-icon>
        </v-btn>

        <!-- Save new configuration -->
        <v-btn
          v-if="!currentSettings"
          color="success"
          class="ma-4"
          :disabled="!testWasSuccessful || loading"
          @click="save"
        >
          {{$t('common.connect')}}
          <v-icon right dark>mdi-link-variant</v-icon>
        </v-btn>
        <!-- Update existing configuration -->
        <v-btn
          v-else
          color="success"
          class="ma-4"
          :disabled="!testWasSuccessful || !canStartTest || loading"
          @click="update"
        >
          {{$t('common.save')}}
          <v-icon right dark>save</v-icon>
        </v-btn>
      </div>
    </v-card>

    <SnackbarStack :scope="['shopware.setup']" />
  </div>
</template>

<style scoped>
/* Adjust show/hide PW button to be visually balanced with text */
.no-inner-margin-top ::v-deep(.v-input__prepend-inner) {
  margin-top: 11px;
}
</style>