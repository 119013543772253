var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "moin-fresh" },
    [
      _c(
        "v-row",
        [
          _c("v-col", { staticClass: "d-flex" }, [
            _c(
              "div",
              {
                staticClass: "hidden-sm-and-down pl-1 mr-8",
                staticStyle: { width: "280px" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "avatar overflow-hidden",
                    staticStyle: {
                      "min-width": "100px",
                      "max-width": "140px",
                      width: "85%",
                      "border-radius": "50%",
                    },
                  },
                  [
                    _c("Avatar", {
                      attrs: {
                        "circle-border-size": "0",
                        arm: "#18293E",
                        shirt: "#A5E1DE",
                        "not-scaled": "",
                        "default-avatar": "",
                        background:
                          "radial-gradient(50% 50% at 50% 50%, rgba(0, 64, 105, 0.6) 0%, rgba(238, 238, 238, 6e-05) 100%);",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _c("div", { staticClass: "pt-8 pt-sm-0" }, [
              _c(
                "h2",
                {
                  staticClass: "text-h1 mb-3 primary--text text--lighten-2",
                  attrs: { "data-cy": "welcome-title" },
                },
                [_vm._v("Moin!")]
              ),
              _c("p", { staticClass: "mb-3 text-subtitle-1" }, [
                _c("span", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$t(`welcome.welcomeText.${_vm.currentBotStage}`)
                    ),
                  },
                }),
              ]),
              _c("p", { staticClass: "mb-0 text--lighten-2 text-subtitle-1" }, [
                _vm.currentBotStage !== _vm.stageTypes.LIVE
                  ? _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t("welcome.welcomeText.description")
                        ),
                      },
                    })
                  : _vm._e(),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }