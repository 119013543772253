import ApiService from './api';

const rag = (botId, path, v2 = false) => `/schaltzentrale/rag${v2 ? '/v2/' : '/'}bot/${botId}${path}`;

const RAGService = {
  /**
   * Creates a new resource
   * @template {RAGResourceDraftDocument | RagPDF | RagWEBPAGE | RagMARKDOWN | RagQNA} ResourcePayload
   * @param {string} botId
   * @param {ResourcePayload} resource
   * @throws {Error} If it fails to call the API
   * @returns {Promise<null | RemoteRAGResource<ResourcePayload>>} `null` if failed to read the properties
   */

  async addNewResource(botId, resource) {
    const r = await ApiService.post(rag(botId, `/resources`, true), resource);
    return r.data?.ressource ?? null;
  },
//   addPDFResource: (botId, RagPDF) => {
//     return createResource(botId, RagPDF);
//   },
//     /**
//    * @param {string} botId
//    * @param {RagTable} RagTable
//    */
//   addTableResource: (botId, RagTable) => {
//     return createResource(botId, RagTable);
//   },
//   /**
//    * @param {string} botId
//    * @param {RagWEBPAGE} RagWEBPAGE
//    */
//   addWebsiteResource: (botId, RagWEBPAGE) => {
//     return createResource(botId, RagWEBPAGE);
//   },
//   /**
//    * @param {string} botId
//    * @param {RagMARKDOWN} RagMARKDOWN
//    */
//   addMarkdownResource: (botId, RagMARKDOWN) => {
//     return createResource(botId, RagMARKDOWN);
//   },
//   /**
//    * @param {string} botId
//    * @param {RagQNA} RagQNA
//    */
//   addQNAResource: (botId, RagQNA) => {
//     return createResource(botId, RagQNA);
//   },
  /**
   * Updates a RAG Markdown resource with the new state.
   * Does a shallow merge of the new state onto the old.
   * @template {RAGResourceDraftDocument | RagPDF | RagWEBPAGE | RagMARKDOWN | RagQNA} ResourcePayload
   * @param {string} botId
   * @param {string} docId The resource Document ID (_id)
   * @param {Partial<ResourcePayload>} resource Whole document or parts to update
   * @throws {Error} If it fails to call the API
   * @returns {Promise<null | Partial<RemoteRAGResource<ResourcePayload>>}
   */
  async updateResource(botId, docId, resource) {
    const r = await ApiService.put(rag(botId, `/resources/update/${docId}`, true), resource);
    return r.data ?? null;
  },
  /**
   * @param {string} botId
   * @param {string} intent
   * @returns {Promise<RAGResourceOverview[]>}
   */
  getByIntent: async (botId, intent) => {
    try {
      const r = await ApiService.get(rag(botId, `/resources?intent=${intent}`, true));
      return r.data;
    } catch (e) {
      console.error(e);
      return null;
    }
  },
  /**
   * @param {string} botId
   * @param {string} resourceId Document Object ID
   * @returns {Promise<RAGResource | null>}
   */
  getByResourceID: async (botId, resourceId) => {
    try {
      const result = await ApiService.get(
        rag(botId, `/resources/${resourceId}`, true)
      );

      return result.data?.result ?? null
    } catch (e) {
      console.error(e);
      return null;
    }
  },
  /**
   * @param {string} botId
   * @param {string} intent
   * @returns {Promise<void>}
   */
  deleteByIntent: async (botId, intent) => {
    throw new Error('To be implemented'); // No idea what the response is like here
    try {
      return await ApiService.delete(rag(botId, `/intent/${intent}`));
    } catch (e) {
      console.error(e);
      return null;
    }
  },
  /**
   * @param {string} botId
   * @param {string} id MongoDB ID of the resource
   * @returns {Promise<RAGDeleteResponse>}
   * @throws {Error} Usually if it does not exist, or there are intents that still use this resource
   */
  deleteByID: async (botId, id) => {
    const r = await ApiService.delete(rag(botId, `/resources/resourceDocId/${id}`, true));
    return r.data;
  },
  /**
   * @param {string} botId
   * @param {?{intent?: string, channel?: string, status?: RAGStatus, environment?: "live"|"staging"}} [query={}]
   * @returns {Promise<RAGResourceOverview[]>}
   */
  getAllByBot: async (botId, query= {}) => {
    try {
      const qs = new URLSearchParams(query);
      const r = await ApiService.get(rag(botId, `/resources?${qs.toString()}`, true));

      // Not sure how the error would look like
      if (r.status !== 200) {
        throw new Error(
          r.data?.error ||
          r.data?.message ||
          r.data?.result ||
          'Unknown error fetching resources'
        );
      }

      return r.data;
    } catch (e) {
      console.error(e);
      return null;
    }
  },
  /**
   * @param {string} botId
   * @returns {Promise<void>}
   */
  deleteAllByBot: async (botId) => {
    throw new Error('To be implemented'); // No idea what the response is like here
    try {
      return await ApiService.delete(rag(botId, `/ressources`));
    } catch (e) {
      console.error(e);
      return null;
    }
  },
  /**
   * @param {string} botId
   * @param {string} intent
   * @param {string} query
   * @param {?RAGQueryOptions} [options={}]
   * @returns {Promise<void>}
   */
  getSimilarDocs: async (botId, intent, query, options = {}) => {
    throw new Error('To be implemented'); // No idea what the response is like here
    try {
      return await ApiService.post(rag(botId, `/simdocs`), {
        intent,
        query,
        ...options,
      });
    } catch (e) {
      console.error(e);
      return null;
    }
  },
  /**
   * Checks what the status of a document is.
   * Since the resource might not be ready, they will not have a resourceId, so we have to use URL
   * @param {string} botId
   * @param {string} intent
   * @param {string} resourceUrl
   * @returns {Promise<null | Omit<RAGStatus, 'draft'>>}
   */
  async checkResourceStatus(botId, intent, resourceUrl) {
    try {
      const r = await ApiService.get(
        rag(botId, `/intent/${intent}/status?url=${resourceUrl}`)
      );
      return r.data?.result ?? null;
    } catch (e) {
      console.error(e);
      return null;
    }
  },
  /**
   * @param {string} botId
   * @param {string} intent
   * @param {string} query
   * @param {?RAGQueryOptions} [options={}]
   * @returns {Promise<RAGQueryResponse & {result: RAGAnswer}>}
   */
  getAnswer: async (botId, intent, query, options = {}, channelId = null) => {
    try {
      let url = rag(botId, `/intent/${intent}/answer`);

      if (channelId) {
        url = rag(botId, `/intent/${intent}/channel/${channelId}/answer`);
      }

      const r = await ApiService.post(url, {
        query,
        ...options,
      });

      return r.data;
    } catch (e) {
      console.error(e);
      return null;
    }
  },
  /**
   * Retrieves questions that could not be answered by this RAG intent due to insufficient knowledge
   * @param {string} botId
   * @param {string} intent
   * @param {?string | null} [channelId = null] Channel to use
   * @returns {Promise<null | { question: string, created: string }[]>} `null` if an error occurred
   */
  async getMissingRAGKnowledge(botId, intent, channelId = null) {
    try {
      let url = rag(botId, `/intent/${intent}/missing-knowledge`);
      if (channelId) {
        url = rag(
          botId,
          `/intent/${intent}/missing-knowledge?channelId=${channelId}`
        );
      }

      const r = await ApiService.get(url);
      return r.data;
    } catch (e) {
      console.error(e);
      return null;
    }
  },
  /**
   * Overwrites which intents are assigned to this resource, and in which stage per-channel they are.
   * Any omitted channels/stages/intents will be "unassigned".
   * @param {string} botId
   * @param {RAGResourceOverview["resourceId"]} resourceId
   * @param {RagIntent[]} intentObjects
   * @returns {Promise<{updatedIntents: string[], resourceIntents: RagIntent[]}>}
   * @todo Return should be the new state, and we should set our current state to its response, to ensure it is 1:1 correct!
   */
  async setAssignedIntents(botId, resourceId, intentObjects) {
    // It requires "null" to be `null`
    const prepared = intentObjects.map((intent) => {
      if (intent?.stagingChannels?.length) intent.stagingChannels = intent.stagingChannels.map((c) => c === 'null' ? null : c);
      if (intent?.liveChannels?.length) intent.liveChannels = intent.liveChannels.map((c) => c === 'null' ? null : c);

      return intent;
    });

    try {
      const r = await ApiService.post(
        rag(botId, `/resources/${resourceId}/intents`, true),
        prepared
      );
      return r.data;
    } catch (e) {
      console.error(e);
      return null;
    }
  },
  /**
   * Sets whether the default intent is enabled or not
   * @param {string} botId
   * @param {boolean} newState
   * @returns {Promise<null | {success: boolean, fallbackFaqIntent: Object}>}
   */
  async setDefaultEnabled(botId, newState) {
    try {
      const r = await ApiService.post(
        rag(botId, `/intent/fallbackFaq`),
        {
          active: newState,
        }
      );
      return r.data;
    } catch (e) {
      console.error(e);
      return null;
    }
  },
};

export default RAGService;
export { RAGService };


/////////////////////////// UTILITY TYPES & ENUMS //////////////////////////////
/**
 * Types of statuses a resource can have.<br/>
 * <h3>NOTE:</h3> The "draft" status exist only in client-side,
 * it does not exist in the backend!
 * @typedef {'active'|'uploading'|'failed'|'deleted'|'draft'} RAGStatus
 */
/**
 * @typedef {'PDF'|'WEBPAGE'|'MARKDOWN'|'QNA'} RAGResourceType
 */
/**
 * Custom hashmap you can attach to a resource
 * @typedef {Record<string, string>} RAGArbitraryMetaData
 */
/**
 * @typedef {"public"|"private"} RAGPrivacyStatus
 */
/**
 * @typedef {RagPDF | RagWEBPAGE | RagMARKDOWN | RagQNA} RagANY
 */

////////////////////////////////// QUERYING ////////////////////////////////////
/**
 * @typedef {Object} RAGQueryOptions
 * @property {number} [topK] Limit results to the top matches
 * @property {Record<string, string>} [filter] Limit results to match records with given metadata
 */
/**
 * @typedef {Object & RAGArbitraryMetaData} RAGDocumentMetadata
 * @property {string} [page_label] The page this item is on, e.g. "1" or "2"
 * @property {string} intent The intent this document is linked to
 * @property {string} botId The bot this document is linked to
 * @property {string} url The URL to the resource (either website URL or URL to the PDF)
 * @property {RAGResourceType} type The type of resource, either "WEBSITE" or "PDF"
 * @property {?string} [sourceDocument] The name you provided if PDF, else the URL of the website
 * @property {string} createdAt The creation date as an ISO string
 * @property {string} ressourceId The resource ID this document belongs to
 * @property {string} _node_content The actual content of this document, e.g. some content from the PDF if a PDF Resource
 * @property {"TextNode"} _node_typ The type of node content. For now, only "TextNode", until more features are added
 * @property {string} document_id A unique ID of this document
 * @property {string} doc_id The ID of this document
 * @property {string} ref_doc_id Reference document ID
 */
/**
 * @typedef {Object} RAGDocument
 * @property {number} id The ID of this document
 * @property {string} text The content of this document, e.g. the content of the first page in a PDF
 * @property {RAGDocumentMetadata & RAGArbitraryMetaData} metadata Metadata about this document.
 * This object also merges in your {@link RAGArbitraryMetaData} object, so you can use that to store additional metadata, directly inside this object, alongside other data.
 */
/**
 * @typedef {Object} RagIntent
 * @property {string} intent The technical name of the intent
 * @property {string[]} stagingChannels The channels this intent is used in staging. May contain the string literal "null" to represent the default channel.
 * @property {string[]} liveChannels The channels this intent is used in production. May contain the string literal "null" to represent the default channel.
 */
/**
 * Object is unknown.
 * @typedef {Object} RAGResourceErrorMessage
 */
/**
 * @typedef {Object} RAGResourceUpdate
 * @property {RAGResourceUpdateError} error Contains details about the error.
 */
/**
 * @typedef {Object} RAGResourceUpdateError
 * @property {string} date The date of the error, ISO string.
 * @property {string} message A human-crafted descriptive error of what went wrong.
 * @property {RAGResourceUpdateErrorPayload} payload Contains additional details related to the error.
 */
/**
 * @typedef {Object} RAGResourceUpdateErrorPayload
 * @property {string} stack The error stacktrace
 * @property {string} message The error message itself
 */
/**
 * @typedef {Object} RAGUpdateData
 * @property {string|Date} [updatedAt] The date the resource was last re-fetched from the URL and updated
 * @property {boolean} [active] Whether the auto-update is active or not
 * @property {number} [period] The day interval to update the resource at
 */
/**
 * @typedef {Object} RAGResource
 * @property {string} _id Database object ID. Object ID.
 * @property {string} ressourceId The main ID of this resource, linking all docs together
 * @property {string[]} docIds List of document IDs. UUID.
 * @property {string} botId The bot this resource is linked to
 * @property {?string} [intent] DEPRECATED: The intent this resource is linked to. From before intents were re-usable in other intents.
 * @property {RAGResourceType} type The type of resource, either "WEBSITE" or "PDF"
 * @property {string} url The URL to the resource (either website URL or URL to the PDF)
 * @property {string|Record<string, string>} metaData A string or an object containing some arbitrary metadata, which can be used for categorizing and lookups
 * @property {number} numDocs The number of documents this resource contains. Might be higher than `docIds.length` by 1
 * @property {string|null} [comment] An optional comment that was attached
 * @property {string|null} [description] Describes this resource
 * @property {?RAGDocument[]} [docs] All the documents of this resource
 * @property {?string} [sourceDocument] The name of the source document if PDF, else the URL of the website
 * @property {RAGStatus} status The status of this resource, e.g. "active"
 * @property {RagIntent[]} [intents] The intents this resource is used in
 * @property {RAGResourceErrorMessage[]} [errorMessages] Any error messages that occurred during creation
 * @property {RAGResourceUpdate} [update] The result of updating the content, usually web-scraping. Might contain error.
 * @property {{markdown: string}} [content] The content of the resource, if it is a markdown article
 */
/**
 * @typedef {Object} RAGResourceOverview
 * @property {string} _id Database object ID. Object ID.
 * @property {string[]} docIds List of document IDs. UUID.
 * @property {string} botId The bot this resource is linked to
 * @property {?string} [intent] DEPRECATED: The intent this resource is linked to. From old when intents were not re-usable in other intents.
 * @property {null|string} channelId The ID of the channel the resource belongs. `null` signifies the default channel.
 * @property {RAGResourceType} type The type of resource, either "WEBSITE" or "PDF"
 * @property {string} url The URL to the resource (either website URL or URL to the PDF)
 * @property {string} date ISO Date string
 * @property {null|Record<string, string>} metaData Null, a string or an object containing some arbitrary metadata, which can be used for categorizing and lookups
 * @property {RAGStatus} status The status of this resource, e.g. "active"
 * @property {null|string} [comment] An optional comment that was attached
 * @property {null|string} [description] Describes this resource
 * @property {RAGPrivacyStatus} status If the resource is viewable by the public or not
 * @property {number} __v Mongoose versioning key
 * @property {number} numDocs How many documents (not to be confused with pages in the file) this resource contains
 * @property {string} ressourceId The main ID of this resource, linking all docs together
 * @property {RagIntent[]} [intents] The intents this resource is used in. May be unset if not used anywhere.
 * @property {RAGUpdateData} [update] A resource's auto-update configuration and when it was last fetched
 */
/**
 * @typedef {Object} RAGQueryResponse
 * @property {"ok"|"error"} status Should be "ok" if all went well
 */
/**
 * @typedef {Object & RAGQueryResponse} RAGDeleteResponse
 * @property {{status: string, num_docs_deleted: number}} result The result of the deletion
 */
/**
 * @typedef {Object} RAGSourceUsed
 * @property {string} source The name of the source used, e.g. the filename if you used PDF
 * @property {RAGResourceType} type The type of source used, e.g. "PDF" or "WEBSITE"
 * @property {?string} [page] The page the source was from if PDF
 */
/**
 * @typedef {Object} RAGAnswer
 * @property {string} answer The actual text answer given by the AI
 * @property {RAGSourceUsed[]} sources The sources the AI used to arrive at this result
 */


//////////////////////// RESOURCE CREATION & RESPONSE //////////////////////////
/**
 * Payload for creating a new resource from a PDF file that is hosted at a URL
 * @typedef {Object} RagPDF
 * @property {'PDF'} type Always "PDF" for PDF resources
 * @property {string} url A HTTP(s) URL to where the PDF file is hosted. Usually our media store.
 * @property {string} fileName The name of the file (used in UI), including .pdf extension
 * @property {?RAGArbitraryMetaData} [metaData] An object containing some arbitrary metadata, which can be used for categorizing and lookups
 * @property {?string} [description] A description of the PDF file, what it is for and such. Shown in the UI
 * @property {?string} [comment] A comment about the PDF file
 */
/**
 * Payload for creating a new resource from a PDF file that is hosted at a URL
 * @typedef {Object} RagTable
 * @property {'TABLE'} type Always "PDF" for PDF resources
 * @property {string} url A HTTP(s) URL to where the PDF file is hosted. Usually our media store.
 * @property {string} fileName The name of the file (used in UI), including .json and .csv extensions
 * @property {?RAGArbitraryMetaData} [metaData] An object containing some arbitrary metadata, which can be used for categorizing and lookups
 * @property {?string} [description] A description of the PDF file, what it is for and such. Shown in the UI
 * @property {?string} [comment] A comment about the PDF file
 */
/**
 * Payload for creating a new website resource
 * @typedef {Object} RagWEBPAGE
 * @property {'WEBPAGE'} type Always "WEBPAGE" for website resources
 * @property {string} url A HTTP(s) URL to where the website is hosted
 * @property {?RAGArbitraryMetaData} [metaData] An object containing some arbitrary metadata, which can be used for categorizing and lookups
 * @property {?string} [description] A description of the website, what it is for and such. Shown in the UI
 * @property {?string} [comment] A comment about the website
 */
/**
 * Payload for creating a new Markdown Article
 * @typedef {Object} RagMARKDOWN
 * @property {'MARKDOWN'} type Always "MARKDOWN" for markdown articles
 * @property {{markdown: string}} content An object containing a markdown property, which contains the actual markdown
 * @property {{title: string} & ?RAGArbitraryMetaData} metaData A `title` property holding the title, and any other arbitrary data you want
 * @property {?string} [description] A description of the website, what it is for and such. Shown in the UI
 * @property {?string} [comment] A comment about the website
 */
/**
 * Payload for creating a new Question & Answer resource
 * @typedef {Object} RagQNA
 * @property {'QNA'} type Always "QNA" for Question & Answer articles
 * @property {string} name An article name, purely for UI purposes
 * @property {{question: string, answer: string}} content Question and Answer pair. Question being plaintext, Answer being Markdown.
 * @property {?RAGArbitraryMetaData} [metaData] An object containing some arbitrary metadata, which can be used for categorizing and lookups
 * @property {?string} [description] A description of the website, what it is for and such. Shown in the UI
 * @property {?string} [comment] A comment about the website
 */

/**
 * Fields that are always returned when you create a resource of any type,
 * that is not already defined in the specific RagX typedefs.
 * @typedef {Object} __PostRAGResourceResponseExtraFields
 * @property {string} _id The MongoDB ID
 * @property {string[]} docIds A list of other document's IDs that will contain the actual content,
 * broken up into smaller parts. Gets populated as the resource is processed, so usually empty initially.
 * @property {string[]} errorMessages Any error messages that occurred during creation
 * @property {string} date The date of creation as an ISO string
 * @property {RAGStatus} status The status of the resource, e.g. "uploading"
 * @property {number} __v Mongoose versioning key
 * @property {?string} [ressourceId] The ID to the resource, linking all docs together
 */
/**
 * @template {RagPDF | RagWEBPAGE | RagMARKDOWN | RagQNA | RagTable} ResourcePayload
 * @typedef {ResourcePayload & __PostRAGResourceResponseExtraFields} RemoteRAGResource
 */


// ////////////////////////////////////////////////////////////////////////////////
// /**
//  * A non-exposed generic function for creating resources.
//  * Used {@link addPDFResource}, {@link addWebsiteResource},
//  * {@link addMarkdownResource}, or {@link addQNAResource} instead of this directly.
//  * @function
//  * @template {RagPDF | RagWEBPAGE | RagMARKDOWN | RagQNA | RagTable} ResourcePayload
//  * @param {string} botId
//  * @param {ResourcePayload} resource
//  * @throws {Error} If it fails to call the API
//  * @returns {Promise<null | RemoteRAGResource<ResourcePayload>>} `null` if failed to read the properties
//  */
// async function createResource(botId, resource) {
//   const r = await ApiService.post(rag(botId, `/resources`, true), resource);
//   return r.data?.ressource ?? null;
// }

// /**
//  * PUT Overwrite a {@link addPDFResource}, {@link addWebsiteResource},
//  * {@link addMarkdownResource}, or {@link addQNAResource} with the new content.
//  * @function
//  * @template {RagPDF | RagWEBPAGE | RagMARKDOWN | RagQNA | RagTable} ResourcePayload
//  * @param {string} botId
//  * @param {string} docId The resource Document ID (_id)
//  * @param {ResourcePayload} resource
//  * @throws {Error} If it fails to call the API
//  * @returns {Promise<null | RemoteRAGResource<ResourcePayload>>}
//  */
// async function updateResource(botId, docId, resource) {
//   const r = await ApiService.put(rag(botId, `/resources/update/${docId}`, true), resource);
//   return r.data?.ressource ?? null;
// }
