<template>
  <div class="moin-fresh" v-if="availableChannels && availableChannels.length > 1">
    <!-- Select Box -->
    <v-select
      outlined
      dense
      flat
      hide-details
      :value="selectedChannelId"
      @change="changeChannelId"
      :items="availableChannels"
      item-value="channelId"
      item-text="displayName"
      :disabled="disabled"
      item-disabled="blocked"
      style="min-width: 240px"
    >
      <template v-slot:selection="{ item }">
        <div class="d-flex justify-space-between full-width">
          <div
            :style="{
              maxWidth: $vuetify.breakpoint.smOnly ? '210px' : 'auto',
            }"
            class="text-truncate"
          >
            {{ item.displayName }}
            <v-tooltip
              bottom
              color="primary lighten-3"
              v-if="draftAvailable(item)"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-on="on"
                  v-bind="attrs"
                  class="secondary--text pl-2 mb-1"
                  small
                  left
                >
                  edit
                </v-icon>
              </template>
              <span>{{ $t('common.unsavedChanges') }}</span>
            </v-tooltip>
          </div>
          <div>
            <span
              class="ml-auto mr-2 text-caption align-self-center"
              v-text="languageCode(item)"
            />
            <span class="mr-1">
              <v-tooltip bottom color="primary lighten-3">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    color="primary lighten-2"
                  >
                    {{ $channelIcon(item) }}
                  </v-icon>
                </template>
                <span>{{ channelTypeDescription(item.channel) }}</span>
              </v-tooltip>
            </span>
          </div>
        </div>
      </template>
      <template v-slot:item="{ item }">
        <span class="ml-0">
          {{ item.displayName }}
          <span v-if="!item.displayName">Kein Name</span>
          <v-tooltip
            bottom
            color="primary lighten-3"
            v-if="draftAvailable(item)"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-on="on"
                v-bind="attrs"
                class="secondary--text text--darken-2 pl-2 mb-1"
                small
                left
              >
                edit
              </v-icon>
            </template>
            <span>{{ $t('common.unsavedChanges') }}</span>
          </v-tooltip>
        </span>
        <span
          class="ml-auto mr-2 text-caption align-self-center"
          v-text="languageCode(item)"
        ></span>
        <span class="mr-1">
          <v-tooltip bottom color="primary lighten-3">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mb-1"
                color="primary lighten-2"
                small
                v-bind="attrs"
                v-on="on"
              >
                {{ $channelIcon(item) }}
              </v-icon>
            </template>
            <span>{{ channelTypeDescription(item.channel) }}</span>
          </v-tooltip>
        </span>
      </template>
    </v-select>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'ChannelSelection',
  emits: ['input'],
  props: {
    disabled: Boolean,
    intent: String,
    // Optional: Provide your own channels override
    channels: Array,
    /**
     * Optional.
     * Define what channel user _should_ be switched to.
     * Can be used to change channel using an external method.
     */
    value: {
      type: String,
      // NOTE: Do not use `null`. In some cases `null` is a valid channel
      default: undefined,
    },
    /**
     * Enable if your component expect `null` as the first channel
     */
    firstIsNull: {
      type: Boolean,
      default: false,
    },
    /**
     * Enable it to omit blocked channels from appearing in the list
     */
    omitBlocked: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      selectedChannelId: undefined,
    }
  },
  computed: {
    ...mapGetters('bots', ['currentBotId', 'currentChannelId', 'currentChannels']),
    ...mapGetters('preferences', ['suggestedChannelId', 'setQueryChannelId']),
    /**
     * Coerce the property value to a proper ID if it is `null`
     * @returns {string|undefined}
     */
    resolvedValue() {
      if (this.value === null) return this.currentChannels[0].channelId;
      return this.value;
    },
    availableChannels() {
      const channels = this.channels ?? this.currentChannels;
      if (!this.omitBlocked) return channels;
      return channels.filter(ch => !ch.blocked);
    },
    currentChannelIsValid() {
      if (this.availableChannels.some(ch => ch.channelId === this.resolvedValue)) return true;

      // Fallback in order of importance
      let channelId = this.resolvedValue;
      if (channelId === undefined) channelId = this.selectedChannelId;
      if (channelId === undefined) channelId = this.suggestedChannelId;

      // If we can find it, all good
      return this.availableChannels.some(ch => ch.channelId === channelId);
    },
  },
  watch: {
    value: function(newChannelId) {
      this.changeChannelId(
        // Check against `undefined` explicitly, as `null` is a valid value.
        newChannelId !== undefined ? newChannelId : this.suggestedChannelId
      );
    },
    channels() {
      // We have to re-evaluate if the channel we are currently is still "allowed" ...
      if (this.currentChannelIsValid) return;

      // ... else we have to change to the first available channel.
      this.changeChannel(0);
    },
    '$route.query.channel'() {
      this.changeChannelId(
        // Will prioritize reading the new channel query (which was updated), and see if we can use it.
        this.suggestedChannelId
      );
    },
  },
  methods: {
    ...mapMutations('bots', ['setCurrentChannelId']),
    ...mapActions('preferences', ['setLastChannelId']),
    changeChannel(index) {
      return this.changeChannelId(this.availableChannels[index].channelId);
    },
    async changeChannelId(channelIdOrNull) {
      let actualChannelId = channelIdOrNull;

      // Special handling: Some places need to use `null` for first channel ID.
      if (actualChannelId === null) {
        actualChannelId = this.currentChannels[0].channelId;
      }
      // Special handling: If firstIsNull, we must _ensure_ that null is emitted if it is the first
      if (this.firstIsNull && channelIdOrNull !== null) {
        // Do not use `availableChannels`, we must include first channel, which might be blocked
        const channels = this.channels ?? this.currentChannels;
        if (channels[0].channelId === actualChannelId) {
          channelIdOrNull = null;
        }
      }

      // First set query params, since it might override any changes
      this.setQueryChannelId(channelIdOrNull);
      // ... then ...
      this.$emit('input', channelIdOrNull);   // Inform parent
      this.selectedChannelId = actualChannelId;     // Local state for UI
      this.setCurrentChannelId(actualChannelId);    // Global store
    },
    channelTypeDescription(type) {
      switch (type) {
        case 'widget':
          return 'Website';
        case 'facebook':
          return 'Facebook Messenger';
        case 'userlike':
          return 'Userlike';
        case 'whatsapp':
          return 'WhatsApp';
      }

      return '';
    },
    languageCode(c) {
      if (Array.isArray(c.languages) && c.languages.length > 0) {
        return c.languages[0].toUpperCase();
      }

      return '';
    },
    draftAvailable(c) {
      if (this.intent) {
        return this.$store.getters['content/isContentChanged'](
          this.currentBotId,
          this.intent,
          c.channelId
        );
      }

      return false;
    },
  },
  created() {
    return this.changeChannelId(
      this.resolvedValue !== undefined ? this.resolvedValue : this.suggestedChannelId
    );
  },
};
</script>
<style scoped scss>
:deep(.v-select__selections input) {
  display: none !important;
}
</style>
