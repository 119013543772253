var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("LiveChat authentication via OAuth")]),
          _c("v-card-subtitle", [
            _vm._v(
              " Authorize connection setup with your LiveChat, to allow visitors to be able to be able to be transferred to chatting with live agents. "
            ),
          ]),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "success", dark: "", small: "" },
                  on: {
                    click: function ($event) {
                      return _vm.oauth()
                    },
                  },
                },
                [
                  _vm._v(" Auth "),
                  _c("v-icon", { attrs: { right: "", dark: "" } }, [
                    _vm._v("mdi-robot-love-outline"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: 6000 },
          model: {
            value: _vm.savingToast,
            callback: function ($$v) {
              _vm.savingToast = $$v
            },
            expression: "savingToast",
          },
        },
        [
          _vm._v(" Oauth success "),
          _c("v-spacer"),
          _c("v-icon", { attrs: { type: "success" } }, [_vm._v("check")]),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: 6000 },
          model: {
            value: _vm.savingToastError,
            callback: function ($$v) {
              _vm.savingToastError = $$v
            },
            expression: "savingToastError",
          },
        },
        [
          _vm._v(" Fehler: " + _vm._s(_vm.message) + " "),
          _c("v-spacer"),
          _c("v-icon", { attrs: { color: "red", type: "error" } }, [
            _vm._v(" mdi-alert-circle "),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }