import axios from 'axios';
import ApiService from './api';
import { random } from 'lodash';

const StatsService = {
  getAnswers: async function (botId, dateRange, channel) {
    try {
      let url =
        '/schaltzentrale/stats/answers/' +
        botId +
        '/' +
        dateRange[0].format('YYYY-MM-DD') +
        '/' +
        dateRange[1].format('YYYY-MM-DD');
      if (channel) {
        url += `/${channel}`;
      }

      const response = await ApiService.get(url);
      if (response.data && response.data.result) {
        return response.data.result;
      }

      return {};
    } catch (error) {
      return {};
    }
  },
  getTakeover: async function (botId, dateRange, channel) {
    try {
      let url =
        '/schaltzentrale/stats/takeoverTypes/' +
        botId +
        '/' +
        dateRange[0].format('YYYY-MM-DD') +
        '/' +
        dateRange[1].format('YYYY-MM-DD');
      if (channel) {
        url += `/${channel}`;
      }
      const response = await ApiService.get(url);
      if (response.data && response.data.result) {
        return response.data.result;
      }

      return {};
    } catch (error) {
      return {};
    }
  },
  getFeedbacks: async function (botId, dateRange, channel) {
    try {
      let url =
        '/schaltzentrale/stats/feedbacks/' +
        botId +
        '/' +
        dateRange[0].format('YYYY-MM-DD') +
        '/' +
        dateRange[1].format('YYYY-MM-DD');
      if (channel) {
        url += `/${channel}`;
      }
      const response = await ApiService.get(url);
      if (response.data && response.data.result) {
        return response.data.result;
      }

      return {};
    } catch (error) {
      return {};
    }
  },
  getKpi: async function (botId, kpi, dateRange, channel) {
    try {
      let url = `/schaltzentrale/stats/${kpi}/${botId}/${dateRange[0].format(
        'YYYY-MM-DD'
      )}/${dateRange[1].format('YYYY-MM-DD')}`;

      if (channel) {
        url += `/${channel}`;
      }

      const response = await ApiService.get(url);
      if (response.data && response.data.result) {
        return response.data.result;
      }

      return {};
    } catch (error) {
      return {};
    }
  },
  getSuggestionFrequency: async function (botId, intent) {
    try {
      const url = '/schaltzentrale/stats/suggestions/' + botId + '/' + intent;

      const response = await ApiService.get(url);
      if (response.data && response.data.result) {
        return response.data.result;
      }

      return {};
    } catch (error) {
      return {};
    }
  },
  getIntentRelevance: async function (botId, intent, channel) {
    /**
     *
     * hier wird die API für die stats zu einem Intent eines Bots und einem Channel abgefragt
     *
     * - frequence 0 bis 10 von selten bis sehr häufig
     * - history Array der letzten 12 Wochen, frequence Wert pro Woche wie oben 0 selten und 10 Häufig
     *
     * Balken ist voll, wenn der Wert 10 ist, und leer wenn 0.
     *
     * Vorsicht: Skalierung ist genau umgekehrt, als wie sie bisher implementiert ist.
     *
     **/
    const url = '/schaltzentrale/stats/relevance/' + botId + '/' + intent;
    const response = await ApiService.get(url);
    if (!response.data || !response.data.result) {
      return {
        frequent: 0,
        history: [],
        data: 0,
        botId,
        intent,
        channel,
      };
    }

    const data = {
      frequent: response.data.result.frequent,
      history: response.data.result.history,
      data: response.data.result.data,
      botId,
      intent,
      channel,
    };
    // await new Promise(resolve => setTimeout(resolve, 4000)) // Dummy Loading Time
    return data;
  },
  getIntentFeedback: async function (botId, intent, channel) {
    /**
     *
     * hier wird die API für die stats zu einem Intent eines Bots und einem Channel abgefragt
     *
     * - pos: wie häufig wurde das Thema positiv Bewertet. Der Wert ist >= 0
     * - neg: wie häufig wurde das Thema negativ Bewertet. Der Wert ist >= 0
     * - history Array der letzten 12 Wochen, ein Objekt mit pos und neg Bewertungen
     *
     * Progress Balken: Prozentzahl wird ausgerechnet, pos / (pos + neg). 100% ist alles Grün
     *
     **/
    const url = '/schaltzentrale/stats/rating/' + botId + '/' + intent;
    const response = await ApiService.get(url);

    if (!response.data || !response.data.result) {
      return {
        pos: 0,
        history: [],
        neg: 0,
        botId,
        intent,
        channel,
      };
    }

    const dummy = {
      pos: response.data.result.pos,
      neg: response.data.result.neg,
      history: response.data.result.history,
      botId,
      intent,
      channel,
    };
    // await new Promise(resolve => setTimeout(resolve, 4000)) // Dummy Loading Time
    return dummy;
  },
  getIntentAccuracy: async function (botId, intent, channel) {
    const url = '/schaltzentrale/stats/accuracy/' + botId + '/' + intent;
    const response = await ApiService.get(url);

    if (!response.data || !response.data.result) {
      return {
        pos: 0,
        history: [],
        neg: 0,
        botId,
        intent,
        channel,
      };
    }

    const dummy = {
      pos: response.data.result.pos,
      neg: response.data.result.neg,
      history: response.data.result.history,
      botId,
      intent,
      channel,
    };
    return dummy;
  },
  getIntentConversion: async function (botId, intent, channel) {
    /**
     *
     * hier wird die API für die stats zu einem Intent eines Bots und einem Channel abgefragt
     *
     * - goals: wie häufig führte das Thema zur Conversion. Der Wert ist >= 0
     * - views: wie häufig wurde das Thema ausgespielt. Der Wert ist >= 0
     * - history Array der letzten 12 Wochen, ein Objekt mit goals und views
     *
     * Progress Balken: Prozentzahl wird ausgerechnet, goals / (goals + views). 100% ist alles Blau
     *
     **/
    const goals = random(0, 100);
    const dummy = {
      goals,
      views: goals + random(0, 20),
      history: Array.from({ length: 12 }, () => {
        const gls = random(0, 100);
        return { goals: gls, views: gls + random(0, 20) };
      }),
      botId,
      intent,
      channel,
    };
    await new Promise((resolve) => setTimeout(resolve, 2000)); // Dummy Loading Time
    return dummy;
  },
  getConversationSeries: async (botId, dateRange, channel) => {
    const startTime = dateRange[0].utcOffset(0, true).format();
    const endTime = dateRange[1].utcOffset(0, true).format();
    channel = channel ? channel : '';

    const url = `/schaltzentrale/stats/series/conversation/${botId}/${channel}?startTime=${startTime}&endTime=${endTime}`;
    const response = await ApiService.get(url);

    return response?.data;
  },
  getAutomationSeries: async (botId, dateRange, channel) => {
    const startTime = dateRange[0].utcOffset(0, true).format();
    const endTime = dateRange[1].utcOffset(0, true).format();
    channel = channel ? channel : '';

    const url = `/schaltzentrale/stats/series/automation/${botId}/${channel}?startTime=${startTime}&endTime=${endTime}`;
    const response = await ApiService.get(url);

    return response?.data;
  },
  getConversionsSeries: async (botId, dateRange, channel) => {
    const startTime = dateRange[0].utcOffset(0, true).format();
    const endTime = dateRange[1].utcOffset(0, true).format();
    channel = channel ? channel : '';

    const url = `/schaltzentrale/stats/series/conversationgoallead/${botId}/${channel}?startTime=${startTime}&endTime=${endTime}`;
    const response = await ApiService.get(url);

    return response?.data;
  },
  getFeedbackSeries: async (botId, dateRange, channel) => {
    const startTime = dateRange[0].utcOffset(0, true).format();
    const endTime = dateRange[1].utcOffset(0, true).format();

    channel = channel ? channel : '';

    const url = `/schaltzentrale/stats/series/feedback/${botId}/${channel}?startTime=${startTime}&endTime=${endTime}`;
    const response = await ApiService.get(url);

    return response?.data;
  },
  getTakeoversSeries: async (botId, dateRange, channel) => {
    const startTime = dateRange[0].utcOffset(0, true).format();
    const endTime = dateRange[1].utcOffset(0, true).format();
    channel = channel ? channel : '';

    const url = `/schaltzentrale/stats/series/takeovers/${botId}/${channel}?startTime=${startTime}&endTime=${endTime}`;
    const response = await ApiService.get(url);
    return response?.data;
  },
  getTopTopics: async (botId, dateRange, channel, k = 10) => {
    const startTime = dateRange[0].utcOffset(0, true).format();
    const endTime = dateRange[1].utcOffset(0, true).format();
    channel = channel ? channel : '';
    const url = `/schaltzentrale/stats/topktopics/${botId}/${channel}?startTime=${startTime}&endTime=${endTime}&k=${k}`;
    const response = await ApiService.get(url);

    return response?.data;
  },
  getConversationDistribution: async (botId, dateRange, channel, intent) => {
    const startTime = dateRange[0].utcOffset(0, true).format();
    const endTime = dateRange[1].utcOffset(0, true).format();
    channel = channel ? channel : '';
    intent = intent ? `&topic=${intent}` : '';

    const url = `/schaltzentrale/stats/conversation/${botId}/distribution?startTime=${startTime}&endTime=${endTime}${intent}&channel=${channel}`;
    const response = await ApiService.get(url);

    return response?.data;
  },
  getConversationDuration: async (botId, dateRange, channel) => {
    const startTime = dateRange[0].utcOffset(0, true).format();
    const endTime = dateRange[1].utcOffset(0, true).format();
    channel = channel ? channel : '';

    const url = `/schaltzentrale/stats/conversation/${botId}/duration?startTime=${startTime}&endTime=${endTime}&channel=${channel}`;

    const response = await ApiService.get(url);

    return response?.data;
  },
  getConversationTopicAmount: async (botId, dateRange, channel) => {
    const startTime = dateRange[0].utcOffset(0, true).format();
    const endTime = dateRange[1].utcOffset(0, true).format();
    channel = channel ? channel : '';

    const url = `/schaltzentrale/stats/conversation/${botId}/topics?startTime=${startTime}&endTime=${endTime}&channel=${channel}`;

    const response = await ApiService.get(url);

    return response?.data;
  },
  getAnswerSeries: async function (botId, dateRange, channel, intent) {
    const startTime = dateRange[0].utcOffset(0, true).format();
    const endTime = dateRange[1].utcOffset(0, true).format();

    channel = channel ? channel : '';

    const url = `/schaltzentrale/stats/series/intents/${botId}/${intent}/${channel}?startTime=${startTime}&endTime=${endTime}`;
    const response = await ApiService.get(url);
    return response?.data || [];
  },
  getAnalysisExport: async function (botId, dateRange) {
    const startTime = dateRange[0].format('YYYY-MM-DD');
    const endTime = dateRange[1].format('YYYY-MM-DD');

    const url = `/schaltzentrale/protocol/${botId}?startDate=${startTime}&endDate=${endTime}`;
    const response = await axios.get(url, { responseType: 'arraybuffer' })

    return response?.data || false;
  },
  getAIFeedbackExport: async function (botId, dateRange, positive) {
    const startTime = dateRange[0].format('YYYY-MM-DD');
    const endTime = dateRange[1].format('YYYY-MM-DD');

    const url = `/schaltzentrale/feedback/${botId}/${positive ? 'pos' : 'neg'}?startDate=${startTime}&endDate=${endTime}&format=csv`;
    const response = await ApiService.get(url);
    return response?.data || false;
  },

  getCSATReport: async function (botId, dateRange, channel = null, ) {
    const startTime = dateRange[0].format('YYYY-MM-DD');
    const endTime = dateRange[1].format('YYYY-MM-DD');

    let url = `/schaltzentrale/stats/csat/${botId}`;
    if (channel) {
      url += `/${channel}`;
    }

    url += `?startDate=${startTime}&endDate=${endTime}`;
    const response = await ApiService.get(url);
    return response?.data || false;
  },

  productAdvisor: {
    getAmountOfStartedConsultations: async ({ botId, intent, from, to }) => {
      const url = _generateQueryWithParams({
        baseUrl: `/schaltzentrale/stats/${botId}/product-advisor/started/count`,
        extraParams: { intent, from, to }
      });

      try {
        const response = await ApiService.get(url);
        return { success: true, data: response?.data?.data || response?.data || null };
      } catch (error) {
        return { success: false, error };
      }
    },
    getConversionRate: async ({ botId, intent, from, to }) => {
      const url = _generateQueryWithParams({
        baseUrl: `/schaltzentrale/stats/${botId}/product-advisor/conversion/rate`,
        extraParams: { intent, from, to }
      });

      try {
        const response = await ApiService.get(url);
        return { success: true, data: response?.data?.data || response?.data || null };
      } catch (error) {
        return { success: false, error };
      }
    },
    getPopularProducts: async ({ botId, intent, from, to, limit }) => {
      const url = _generateQueryWithParams({
        baseUrl: `/schaltzentrale/stats/${botId}/product-advisor/products/popular`,
        extraParams: { intent, from, to, limit }
      });

      try {
        const response = await ApiService.get(url);
        return { success: true, data: response?.data?.data || response?.data || null };
      } catch (error) {
        return { success: false, error };
      }
    },
    getRecommendedProducts: async ({ botId, intent, from, to, limit }) => {
      const url = _generateQueryWithParams({
        baseUrl: `/schaltzentrale/stats/${botId}/product-advisor/products/recommended`,
        extraParams: { intent, from, to, limit }
      });

      try {
        const response = await ApiService.get(url);
        return { success: true, data: response?.data?.data || response?.data || null };
      } catch (error) {
        return { success: false, error };
      }
    },
    getRecommendedProductsAvg: async ({ botId, intent, from, to }) => {
      const url = _generateQueryWithParams({
        baseUrl: `/schaltzentrale/stats/${botId}/product-advisor/products/recommended/avg`,
        extraParams: { intent, from, to }
      });

      try {
        const response = await ApiService.get(url);
        return { success: true, data: response?.data?.data || response?.data || null };
      } catch (error) {
        return { success: false, error };
      }
    },
    getMostRequestedQueryProperties: async ({ botId, intent, from, to }) => {
      const url = _generateQueryWithParams({
        baseUrl: `/schaltzentrale/stats/${botId}/product-advisor/products/recommended/query-properties`,
        extraParams: { intent, from, to }
      });

      try {
        const response = await ApiService.get(url);
        return { success: true, data: response?.data?.data || response?.data || null };
      } catch (error) {
        return { success: false, error };
      }
    },
  },
};

/**
 * Private method to generate extra Query params
 * @param {Object} param
 * @param {string} param.baseUrl
 * @param {{[key: string]: string}} param.extraParams - Object with key - value relation;
 */
const _generateQueryWithParams = ({ baseUrl, extraParams }) => {
  let url = baseUrl;

  const extraQuery = [];
  Object.entries(extraParams).forEach(([key, value]) => {
    if (!key || !value || typeof key !== 'string' && typeof value !== 'string') {
      // console.warn('[_generateQueryWithParams] Query parameter skipped, because key or value is empty. Parameters: ', { key, value });
      return; 
    }

    extraQuery.push(`${key}=${value}`);
  });

  if (extraQuery.length) url += '?' + extraQuery.join('&');

  return url;
};

export default StatsService;
export { StatsService };
