var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    [
      _c("v-col", { attrs: { cols: "12", md: "6" } }, [
        _c(
          "div",
          {
            staticClass: "pa-4",
            class: { "text-center": _vm.$vuetify.breakpoint.mdAndUp },
          },
          [
            _c(
              "div",
              { staticClass: "text-body-2" },
              [
                _vm.config.useLocalStorage
                  ? _c(
                      "v-icon",
                      {
                        staticClass: "green--text mr-1",
                        attrs: { size: "32" },
                      },
                      [_vm._v(" mdi-account-clock ")]
                    )
                  : _c(
                      "v-icon",
                      {
                        staticClass: "error--text mr-1",
                        attrs: { size: "32" },
                      },
                      [_vm._v(" mdi-account-off-outline ")]
                    ),
                _c(
                  "div",
                  {
                    staticClass:
                      "text-body-2 grey--text text--darken-2 font-weight-bold",
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.config.useLocalStorage
                            ? _vm.$t("editor.widget.behavior.userDataInfo")
                            : _vm.$t("editor.widget.behavior.noUserDataInfo")
                        ) +
                        " "
                    ),
                  ]
                ),
                _c(
                  "span",
                  { staticClass: "text-body-2 grey--text text--darken-2" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.config.useLocalStorage
                            ? _vm.$t("editor.widget.behavior.userDataInfoSub")
                            : _vm.$t("editor.widget.behavior.noUserDataInfoSub")
                        ) +
                        " "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]
        ),
      ]),
      _c(
        "v-col",
        {
          class: [_vm.$vuetify.breakpoint.smAndDown && "mt-n4 pt-0"],
          attrs: { cols: "12", md: "6" },
        },
        [
          _c("v-card-title", [
            _vm._v(
              " " + _vm._s(_vm.$t("editor.widget.behavior.titleRemember")) + " "
            ),
          ]),
          _c("div", { staticClass: "px-4" }, [
            _c(
              "div",
              [
                _c("v-switch", {
                  attrs: {
                    "input-value": _vm.config.useLocalStorage,
                    color: _vm.config.useLocalStorage ? "green" : "grey",
                    inset: "",
                    label: _vm.$t("editor.widget.behavior.switchRememberLabel"),
                  },
                  on: { change: _vm.toggleActive },
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _vm.isTokenCustom
                  ? _c("v-select", {
                      attrs: {
                        value: true,
                        label: `${_vm.$t(
                          "editor.widget.behavior.selectUserLabel"
                        )} (${_vm.$t("common.custom")})`,
                        items: _vm.optionsWidgetCustom,
                        "item-text": "title",
                        "item-value": "value",
                        "prepend-inner-icon": "mdi-account-clock-outline",
                        disabled: "",
                      },
                    })
                  : _c("v-select", {
                      attrs: {
                        label: _vm.$t("editor.widget.behavior.selectUserLabel"),
                        items: _vm.optionsWidget,
                        "item-text": "title",
                        "item-value": "value",
                        "prepend-inner-icon": "mdi-account-clock-outline",
                      },
                      model: {
                        value: _vm.config.tokenLifetime,
                        callback: function ($$v) {
                          _vm.$set(_vm.config, "tokenLifetime", $$v)
                        },
                        expression: "config.tokenLifetime",
                      },
                    }),
              ],
              1
            ),
            _c(
              "div",
              [
                _vm.isCloseCustom
                  ? _c("v-select", {
                      attrs: {
                        value: true,
                        label: `${_vm.$t(
                          "editor.widget.behavior.selectCloseLabel"
                        )} (${_vm.$t("common.custom")})`,
                        items: _vm.optionsCloseCustom,
                        "item-text": "title",
                        "item-value": "value",
                        "prepend-inner-icon": "mdi-close-box-multiple-outline",
                        disabled: "",
                      },
                    })
                  : _c("v-select", {
                      attrs: {
                        label: _vm.$t(
                          "editor.widget.behavior.selectCloseLabel"
                        ),
                        items: _vm.optionsClose,
                        "item-text": "title",
                        "item-value": "value",
                        "prepend-inner-icon": "mdi-close-box-multiple-outline",
                      },
                      model: {
                        value: _vm.config.dontShowClosedTeaserFor,
                        callback: function ($$v) {
                          _vm.$set(_vm.config, "dontShowClosedTeaserFor", $$v)
                        },
                        expression: "config.dontShowClosedTeaserFor",
                      },
                    }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }