var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "chat-history d-flex flex-column px-2",
      style: {
        height: _vm.height,
        "min-height": _vm.minHeight,
        "max-width": _vm.maxWidth,
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }