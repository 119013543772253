var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { staticStyle: { "max-height": "100vh", height: "100vh" } },
    [
      _c("HubMenu"),
      _c("Appbar"),
      _c("HubEditorFrame", {
        attrs: {
          title: _vm.$t("botSettings.regexp.title"),
          loading: _vm.isLoading,
          draft: _vm.isChanged,
          saveIcon: "cloud_upload",
          back: "/settings",
          "sidebar-items": [{ title: "", icon: "info", name: "info" }],
        },
        on: { onReset: _vm.reset, onSave: _vm.save },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ isLoading }) {
              return [
                _c(
                  "v-container",
                  { staticClass: "white pa-6", attrs: { fluid: "" } },
                  [
                    _c(
                      "v-row",
                      { staticClass: "mb-1" },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12", lg: "6", xl: "4" } },
                          [
                            _c("h2", { staticClass: "text-h6" }, [
                              _vm._v(
                                _vm._s(_vm.$t("botSettings.regexp.title"))
                              ),
                            ]),
                            _c("div", { staticClass: "text-body-2" }, [
                              _vm._v(
                                _vm._s(_vm.$t("botSettings.regexp.description"))
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-form",
                      { ref: "form", attrs: { "lazy-validation": "" } },
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              [
                                _c(
                                  "v-expansion-panels",
                                  {
                                    attrs: { multiple: "" },
                                    model: {
                                      value: _vm.panel,
                                      callback: function ($$v) {
                                        _vm.panel = $$v
                                      },
                                      expression: "panel",
                                    },
                                  },
                                  [
                                    _vm._l(
                                      _vm.regexpArray,
                                      function (item, index) {
                                        return [
                                          _c(
                                            "v-expansion-panel",
                                            [
                                              _c(
                                                "v-expansion-panel-header",
                                                { staticClass: "m-0 p-0" },
                                                [
                                                  _c(
                                                    "h3",
                                                    {
                                                      staticClass:
                                                        "text-h6 primary--text",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.displayName ||
                                                            "No name"
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-expansion-panel-content",
                                                [
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "my-0 py-0",
                                                        },
                                                        [
                                                          _c(
                                                            "v-card-title",
                                                            {
                                                              staticClass:
                                                                "pb-1 px-0",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "botSettings.regexp.displayName"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              label:
                                                                "Display name",
                                                              outlined: "",
                                                              rules:
                                                                _vm.rules.regexFieldRule(),
                                                            },
                                                            on: {
                                                              input:
                                                                _vm.handleChange,
                                                            },
                                                            model: {
                                                              value:
                                                                item.displayName,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "displayName",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.displayName",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "my-0 py-0",
                                                        },
                                                        [
                                                          _c(
                                                            "v-card-title",
                                                            {
                                                              staticClass:
                                                                "pb-1 px-0",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "botSettings.regexp.parameter"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              label:
                                                                "Parameter",
                                                              outlined: "",
                                                              rules:
                                                                _vm.rules.regexFieldRule(
                                                                  index,
                                                                  "parameter"
                                                                ),
                                                            },
                                                            on: {
                                                              input:
                                                                _vm.handleChange,
                                                            },
                                                            model: {
                                                              value:
                                                                item.parameter,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "parameter",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.parameter",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "my-0 pt-0",
                                                        },
                                                        [
                                                          _c(
                                                            "v-card-title",
                                                            {
                                                              staticClass:
                                                                "pb-1 px-0",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "botSettings.regexp.regexp"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              label:
                                                                "Expression",
                                                              outlined: "",
                                                              rules:
                                                                _vm.rules.regexFieldRule(
                                                                  index,
                                                                  "regexp"
                                                                ),
                                                            },
                                                            on: {
                                                              input:
                                                                _vm.handleChange,
                                                            },
                                                            model: {
                                                              value:
                                                                item.expression,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "expression",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.expression",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "d-flex justify-end",
                                                        },
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                depressed: "",
                                                                small: "",
                                                                color: "error",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.removeField(
                                                                      index
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "common.delete"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    right: "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "delete_outline"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                [
                  _c("v-card-text", { staticClass: "white flex-grow-1" }, [
                    !_vm.regexpArray.length
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "mb-5 text-body primary--text lighten-2",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("botSettings.regexp.notExisted")
                                ) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "text-right" },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              outlined: "",
                              color: "primary lighten-3",
                              loading: isLoading,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.addField()
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("botSettings.regexp.new")) +
                                " "
                            ),
                            _c("v-icon", { attrs: { right: "" } }, [
                              _vm._v(_vm._s(_vm.iconAdd)),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
              ]
            },
          },
        ]),
        model: {
          value: _vm.isSidebarOpen,
          callback: function ($$v) {
            _vm.isSidebarOpen = $$v
          },
          expression: "isSidebarOpen",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }