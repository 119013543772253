var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.noData && _vm.totalResponses > 0
    ? _c("HubBoxSimple", {
        attrs: { title: _vm.$t("stats.csat.title"), isLoading: _vm.isLoading },
        scopedSlots: _vm._u(
          [
            {
              key: "content",
              fn: function () {
                return [
                  _c("div", { staticClass: "text-center mb-4" }, [
                    _c(
                      "span",
                      { staticClass: "d-flex" },
                      [
                        _vm._l(5, function (star) {
                          return _c(
                            "v-icon",
                            {
                              key: star,
                              attrs: {
                                color:
                                  star <= Math.round(_vm.averageRating)
                                    ? "yellow darken-3"
                                    : "grey lighten-1",
                              },
                            },
                            [_vm._v(" mdi-star ")]
                          )
                        }),
                        _c("span", { staticClass: "ml-2 font-weight-bold" }, [
                          _vm._v(
                            _vm._s(_vm.$t("stats.csat.average")) +
                              ": " +
                              _vm._s(_vm.averageRating.toFixed(1)) +
                              " (" +
                              _vm._s(_vm.totalResponses) +
                              ")"
                          ),
                        ]),
                        _vm.dateRangeCompare
                          ? _c(
                              "span",
                              { staticClass: "ml-2" },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    attrs: {
                                      small: "",
                                      color:
                                        _vm.previousAvgRating <
                                        _vm.averageRating
                                          ? "green"
                                          : "red",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.previousAvgRating <
                                            _vm.averageRating
                                            ? "mdi-arrow-up"
                                            : "mdi-arrow-down"
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.previousAvgRating.toFixed(1)) +
                                    " (" +
                                    _vm._s(_vm.previousTotalResponses) +
                                    ") "
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]),
                  _c("v-divider"),
                  _c(
                    "v-row",
                    { staticClass: "mt-2" },
                    _vm._l(_vm.ratings, function (value, index) {
                      return _c(
                        "v-col",
                        {
                          key: index,
                          staticClass: "text-left d-flex align-center col-12",
                        },
                        [
                          _vm._l(5, function (star) {
                            return _c(
                              "v-icon",
                              {
                                key: star,
                                attrs: {
                                  color:
                                    star <= index + 1
                                      ? "yellow darken-3"
                                      : "grey lighten-1",
                                },
                              },
                              [_vm._v(" mdi-star ")]
                            )
                          }),
                          _c("span", { staticClass: "ml-2" }, [
                            _vm._v(
                              _vm._s(value.label) +
                                " (" +
                                _vm._s(value.count) +
                                ")"
                            ),
                          ]),
                          _vm.dateRangeCompare
                            ? _c(
                                "span",
                                { staticClass: "ml-2" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        small: "",
                                        color:
                                          _vm.previousRatings[index]?.count <
                                          value.count
                                            ? "green"
                                            : "red",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.previousRatings[index]?.count <
                                              value.count
                                              ? "mdi-arrow-up"
                                              : "mdi-arrow-down"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.previousRatings[index]?.count || 0
                                      ) +
                                      " "
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        2
                      )
                    }),
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          3018423893
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }