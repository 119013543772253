import content from '@/store/modules/content';
import ApiService from './api';

import { Tree } from '@/utils/tree';
import TaskScheduler from '@/utils/taskScheduler';

const ContentService = {
  /**
   * @param {string} botId
   * @param {string} template
   * @param {string} intent
   * @param {?string} [box]
   * @param {?string} [channel]
   * @param {?{useLive?: boolean}} [options={useLive: false}]
   * @returns {Promise<{status: string, result: any}>}
   */
  getContent: async function (botId, template, intent, box, channel, options = {}) {
    Object.assign({
      useLive: false,
    }, options);

    try {
      let url = `/schaltzentrale/intent/content/${botId}/${intent}/${template}`;
      if (box) {
        url += `/box/${box}`;
      } else {
        url += `/box`;
      }

      if (channel) {
        url += `/channel/${channel}`;
      } else {
        url += `/channel`;
      }

      if (options.useLive) {
        url += '?useLive=true';
      }

      const response = await ApiService.get(url);
      return response.data;
    } catch (error) {
      console.error(error);
      return {};
    }
  },
  /**
   * @param {string} botId
   * @param {string} template
   * @param {string} intent
   * @param {?{useLive?: boolean, channel?: boolean}} [options={useLive: false, channel: false}]
   * @returns {Promise<any[]>}
   */
  getContentChilds: async function (botId, template, intent, options= {}) {
    Object.assign({
      useLive: false,
      channel: null,
    }, options);

    const search = new URLSearchParams();
    if (options.useLive) {
      search.append('useLive', 'true');
    }
    if (options.channel) {
      search.append('channel', options.channel);
    }

    try {
      let url = `/schaltzentrale/intent/${template}/${intent}/${botId}/childboxes`;

      if (search.size) {
        url += `?${search.toString()}`;
      }

      const response = await ApiService.get(url);
      if (
        response.data &&
        response.data.status === 'ok' &&
        Array.isArray(response.data.childBoxes)
      ) {
        return response.data.childBoxes;
      }

      return [];
    } catch (error) {
      return [];
    }
  },
  /**
   * @param {string} botId
   * @param {?string} [box] Not used?
   * @param {Object} content
   * @param {?boolean} [useLive=false] Apply changes to live bot instead of staging
   * @returns {Promise<any>}
   */
  saveContent: async function (botId, box, content, useLive = false) {
    try {
      let url = '/schaltzentrale/intent/' + botId;

      if (useLive) {
        url += '?useLive=true';
      }

      const response = await ApiService.post(
        url,
        content
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  /**
   * @param {string} botId
   * @param {string} template
   * @param {string} intent
   * @param {string} channel
   * @param {?{useLive?: boolean}} [options={useLive: false}]
   * @returns {AsyncGenerator<{contents: {}, tree: Tree}|{contents: (*|{}), tree: Tree}, null, *>}
   */
  _getContents: async function*(botId, template, intent, channel, options = {}) {
    Object.assign({
      useLive: false,
    }, options);

    // get the root box for template, intent and channel
    const originContent = await ContentService.getContent(
      botId,
      template,
      intent,
      null,
      channel,
      options,
    );

    if (
      !originContent ||
      !originContent.result ||
      originContent.result.onlyDraft !== false ||
      originContent.result.createChannel !== false
    ) {
      // error, nor root box found
      return null;
    }
    let contents = {};
    contents[originContent.result.box] = originContent.result;

    let contentTree = new Tree();
    contentTree.setRoot(originContent.result);
    contentTree.buildTree(contents);

    yield { contents, tree: contentTree }; // return the root box first

    // now get all childs
    const children = await ContentService.getContentChilds(
      botId,
      template,
      intent,
      {
        ...options,
        channel: channel,
      }
    );

    contents = children.reduce((acc, child) => {
      child.loading = true;
      acc[child.box] = child;
      return acc;
    }, contents);

    // Perform some auto-repair in case of broken records.
    await this._fixPathBasedToPathBasedBoxes(contents, botId, intent, channel);

    contentTree = new Tree();
    contentTree.setRoot(originContent.result);
    contentTree.buildTree(contents);

    // return the root box with loading childs
    yield { contents, tree: contentTree };

    for (const child of children) {
      const content = await ContentService.getContent(
        botId,
        child.template,
        intent,
        child.box,
        channel,
        options,
      );

      if (!content.result || (content.result.channel && (channel !== content.result.channel))) {
        console.error('channel mismatch', content.channel, channel);
        continue;
      }

      contents[child.box] = content.result;

      // Perform some auto-repair in case of broken records.
      await this._fixPathBasedToPathBasedBoxes(contents, botId, intent, channel);

      contentTree = new Tree();
      contentTree.setRoot(originContent.result);
      contentTree.buildTree(contents);

      // return the next child
      yield { contents, tree: contentTree };
    }
  },
  /**
   * @param {string} botId
   * @param {string} template
   * @param {string} intent
   * @param {string} channel
   * @param {?{useLive?: boolean, concurrency?: number}} [options={useLive: false, concurrency: 0}]
   * @returns {Promise<{contents: Awaited<Object>, tree: Tree}|null>}
   */
  getContents: async function (botId, template, intent, channel, options = {}) {
    Object.assign({
      useLive: false,
      concurrency: 0,
    }, options);

    const originContent = await ContentService.getContent(
      botId,
      template,
      intent,
      null,
      channel,
      options,
    );

    if (!originContent || !originContent.result) {
      // error
      return null;
    }
    if (!options.useLive &&
      (originContent.result.onlyDraft !== false ||
      originContent.result.createChannel !== false)
    ) {
      return null;
    }

    const children = await ContentService.getContentChilds(
      botId,
      template,
      intent,
      {
        ...options,
        channel,
      }
    );

    // Sets up the function that will retrieve each record
    const promiseHandler = async (child) => {
      const content = await ContentService.getContent(
        botId,
        child.template,
        intent,
        child.box,
        channel,
        options,
      );
      if (content && content.status === 'ok' && content.result) {
        return content.result;
      } else {
        throw false;
      }
    }

    let childPromises;

    // If we want controlled concurrency, we use a task scheduler
    if (options.concurrency > 0) {
      const scheduler = new TaskScheduler(options.concurrency);
      childPromises = children.map(child => {
        return scheduler.addToQueue(() => promiseHandler(child));
      });
    }
    // ... else run them all at once
    else {
      childPromises = children.map(child => {
        return new Promise(async (resolve, reject) => {
          try {
            const r = await promiseHandler(child);
            return resolve(r);
          } catch(e) {
            return reject(e);
          }
        });
      });
    }

    // Set up the main content object that holds all boxes
    const childs = await Promise.all(childPromises);
    const contents = childs.reduce((acc, child) => {
      acc[child.box] = child;
      return acc;
    }, {});
    contents[originContent.result.box] = originContent.result;

    // Perform some auto-repair in case of broken records.
    await this._fixPathBasedToPathBasedBoxes(contents, botId, intent, channel);

    // Set up and build the tree
    const contentTree = new Tree();
    contentTree.setRoot(originContent.result);
    contentTree.buildTree((box) => {
      return contents[box];
    });
  
    return { contents: contents, tree: contentTree };
  },
  /**
   * For boxes that has paths.
   * Ensures that all paths point to a non-path box and not happy path.
   * If it does not, will inject AnswerInfo in between them.
   *
   * For example, if a YesNoQuestionInfo points directly to MultipleChoiceInfo,
   * then MultipleChoiceInfo will disappear when you save.
   *
   * Usually handled automatically when adding a new box, but some old intents might be in a broken state already.
   * Mutates the input boxObject if necessary.
   *
   * @see https://github.com/knowhereto/moin-hub/pull/1354
   * @private
   * @param {Record<string, object>} content A container with all the boxes
   * @param {string} botId
   * @param {string} intent
   * @param {string|null} [channelId]
   * @returns {Promise<void>} Mutates the content object directly and the objects within.
   */
  async _fixPathBasedToPathBasedBoxes(content, botId, intent, channelId) {
    let answerInfoBase = null; // Lazy fetch
    let n = 0;

    // Iterate over every box in content
    for (const boxObj of Object.values(content)) {
      // If box is loading, skip. We'll call auto-fix again later.
      if (boxObj.loading) continue;

      // Skip non-path based boxes
      if (!['YesNoQuestionInfo', 'MultipleChoiceInfo', 'MultipleChoiceSlider'].includes(boxObj.template)) continue;

      // Iterate over each path
      for (const answer of boxObj.answers) {
        // Skip if the answer points to a non-path box or not happy path
        if (!['YesNoQuestionInfo', 'MultipleChoiceInfo', 'MultipleChoiceSlider', 'none'].includes(answer.template)) continue;

        // Prepare the new AnswerInfo to be injected
        if (!answerInfoBase) {
          const { result } = await this.getContent(botId, 'AnswerInfo', intent, undefined, channelId);
          answerInfoBase = result;
        }
        const answerInfo = structuredClone(answerInfoBase);
        answerInfo.box = `${answerInfoBase.box}${n++}`;

        // Store the original path as followup for AnswerInfo
        answerInfo.followup = structuredClone(answer);

        // Mutate the path point to the new AnswerInfo instead
        answer.template = answerInfo.template;
        answer.box = answerInfo.box;

        // Inject the AnswerInfo box into the master content map
        content[answerInfo.box] = answerInfo;
      }
    }
  }
};


export default ContentService;

export { ContentService };