// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/fonts/JosefinSans-VariableFont_wght.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("@/assets/fonts/Lato-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("@/assets/fonts/silka-semibold-webfont.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: \"Josefin Sans\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n@font-face {\n  font-family: \"Lato\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n@font-face {\n  font-family: \"silkaHeading\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n.slide-width[data-v-24f787d5] {\n  transition: max-width 0.2s ease-in 0.05s;\n  -webkit-transition: max-width 0.2s ease-in 0.05s;\n  -o-transition: max-width 0.2s ease-in 0.05s;\n  -moz-transition: max-width 0.2s ease-in 0.05s;\n}\n\n/* slide in */\n.slide-left-enter-active[data-v-24f787d5] {\n  -webkit-transition-duration: 0.2s;\n  transition-duration: 0.2s;\n  -webkit-transition-delay: 0.05;\n  transition-delay: 0.05s;\n  -webkit-transition-timing-function: ease-in;\n  transition-timing-function: ease-in;\n}\n.slide-left-enter[data-v-24f787d5],\n.slide-left-leave-to[data-v-24f787d5] {\n  margin-right: -100%;\n}\n.hub-editor-frame-assistant[data-v-24f787d5] {\n  overflow: hidden;\n}\n.hub-editor-frame-assistant--col[data-v-24f787d5] {\n  max-height: 100%;\n  height: 100%;\n}\n.hub-editor-frame-assistant--fixed[data-v-24f787d5] {\n  display: block;\n  position: fixed;\n  z-index: 100;\n  top: 260px;\n  right: 20px;\n  bottom: 70px;\n  width: 300px;\n  max-width: 300px;\n  border-radius: 8px;\n  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);\n          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
