import ApiService from './api';

const BotService = {
  get: async function () {
    try {
      const response = await ApiService.get('/bot');
      return response.data;
    } catch (error) {
      return [];
    }
  },
  getBot: async function (id) {
    try {
      const response = await ApiService.get(`/schaltzentrale/bot/${id}`);
      return response.data;
    } catch (error) {
      return [];
    }
  },
  hasActiveChannel: function (bot, type) {
    if (type === 'widget') return true;

    if (!bot || !Array.isArray(bot.channels)) return false;
    if (bot.channels.some((c) => c.channel === type && c.active)) {
      return true;
    }

    return false;
  },
  linkToChannel: function (bot, type) {
    if (!bot || !Array.isArray(bot.channels)) return '';
    const channel = bot.channels.find((c) => c.channel === type);

    if (type === 'facebook') {
      return 'https://m.me/' + channel.channelId;
    }

    if (type === 'widget') {
      return (
        'https://preview.moin.ai/?bot=' + (bot.stagingBot || bot.uniqueBotId)
      );
    }

    return '';
  },
  async saveSettings(botid, settings) {
    try {
      const response = await ApiService.post(
        `/schaltzentrale/bot/settings/${botid}`,
        settings
      );
      return response?.data;
    } catch (error) {
      throw new Error(error);
    }
  },
  async newBot({ name, language, fallbackFaqIntent }) {
    try {
      const response = await ApiService.post(`/bot/`, { name, language, fallbackFaqIntent });
      return response;
    } catch (error) {
      return false;
    }
  },
  async getAccounts(botId) {
    try {
      const response = await ApiService.get(
        `/schaltzentrale/bot/accounts/${botId}/`
      );
      return response.data;
    } catch (error) {
      return false;
    }
  },
  async getBotInvites(botId) {
    try {
      const response = await ApiService.get(`/bot/botinvites/${botId}/`);
      return response.data;
    } catch (error) {
      return false;
    }
  },
  async addChannel(channel) {
    try {
      const response = await ApiService.post(`/bot/channel/`, channel);
      return response.data;
    } catch (error) {
      return false;
    }
  },
  // @data: {active: boolean, features: string[]}
  async updateUser(email, data) {
    try {
      const response = await ApiService.post(
        `/schaltzentrale/bot/accounts/${email}`,
        data
      );
      return response.data;
    } catch (error) {
      return false;
    }
  },

  async deleteUser(email, botId) {
    try {
      const response = await ApiService.delete(
        `/schaltzentrale/bot/accounts/${email}`,
        { uniqueBotId: botId }
      );
      return response.data;
    } catch (error) {
      return false;
    }
  },

  async getNlPFeatures(botId) {
    try {
      const response = await ApiService.get(
        `/schaltzentrale/nlp/${botId}/features`
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  async activateKeywords({ botIdLive, botIdStaging }) {
    try {
      const resLive = await ApiService.post(
        `/schaltzentrale/nlp/${botIdLive}/features/keywordmatch`
      );
      const resStaging = await ApiService.post(
        `/schaltzentrale/nlp/${botIdStaging}/features/keywordmatch`
      );

      if (resLive?.status !== 200 || resStaging?.status !== 200) return false;
      return true;
    } catch (error) {
      return {};
    }
  },
  async deactivateKeywords({ botIdLive, botIdStaging }) {
    try {
      const resLive = await ApiService.delete(
        `/schaltzentrale/nlp/${botIdLive}/features/keywordmatch`
      );
      const resStaging = await ApiService.delete(
        `/schaltzentrale/nlp/${botIdStaging}/features/keywordmatch`
      );
      if (resLive?.status !== 200 || resStaging?.status !== 200) return false;
      return true;
    } catch (error) {
      return false;
    }
  },
  async set2FAActivation({ botId, activate, forSSO }) {
    try {
      const response = await ApiService.post(`/bot/2FA/${botId}`, {
        activate,
        forSSO: !!forSSO,
      });

      return response?.data;
    } catch (error) {
      return false;
    }
  },
  async setSSOSettings({botId, provider, sso2FA}) {
    try {
      const response = await ApiService.post(`/schaltzentrale/bot/settings/${botId}/sso`, {
        provider,
        sso2FA,
      });

      return response?.data;
    } catch (error) {
      return false;
    }
  },
  // @data example {
  //     "demoStepId": "640f2763baff1cdfdbccd146",
  //     "createAnswer": false,
  //     "selectTopics": [9,10,11,12],
  //     "advancedSamples": true
  // }
  async updateDemoBotIntents(botId, data) {
    try {
      const response = await ApiService.post(
        `/schaltzentrale/intentbootstrap/fromdemostep/${botId}`,
        data
      );
      return response?.data;
    } catch (error) {
      return false;
    }
  },
  async setHighPrivacyActivation({ botId, activate }) {
    try {
      const response = await ApiService.post(
        `/schaltzentrale/nlp/anonymization/${botId}/${
          activate ? 'enable' : 'disable'
        }`
      );

      return response?.data;
    } catch (error) {
      return false;
    }
  },
  async searchBot(botId, query) {
    try {
      const response = await ApiService.get(
        `/schaltzentrale/bot/${botId}/search/${query}`
      );
      return response?.data;
    } catch (error) {
      return false;
    }
  },
  async getContentByAction(botId, actionName) {
    try {
      const response = await ApiService.get(
        `/schaltzentrale/bot/${botId}/actionname/${actionName}`
      );
      if (response?.data?.error) {
        console.error("Loading content error: ", response?.data?.error);
        return false;
      }
      return response?.data;
    } catch (error) {
      return false;
    }
  },
  async updateEditedAction(uniqueBotId, action) {
    try {
      const response = await ApiService.post(`/bot/actions/edit`, {action, uniqueBotId});
      return response?.status === 200;
    } catch (error) {
      return false;
    }
  },

  /** @typedef {{ type: 'regexp', displayName: string, expression: string, parameter: string }} RegexpEntity */
  /**
   * 
   * @param {string} botId 
   * @returns {RegexpEntity[]}
   */
  async getAllRegEpx(botId) {
    try {
      const response = await ApiService.get(`/schaltzentrale/regexp/${botId}/all/`);
      return response.data;
    } catch (error) {
      return false;
    }
  },
  /**
   * 
   * @param {string} botId 
   * @param {[any]} data 
   * @returns 
   */
  async setRegExp(botId, data) {
    try {
      const response = await ApiService.put(`/schaltzentrale/regexp/${botId}`, data);
      return response.data;
    } catch (error) {
      return false;
    }
  },
};

export default BotService;

export { BotService };
