var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.parents.length > 1
        ? _c("ActionRow", {
            scopedSlots: _vm._u(
              [
                {
                  key: "line",
                  fn: function () {
                    return [
                      _c("ActionLine", {
                        attrs: {
                          blockType: _vm.blockType,
                          hideLine: "",
                          options: _vm.options,
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c("span", { staticClass: "grey--text" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              `action.info.multipleParent${
                                _vm.parents.length - 1 === 1 ? "" : "s"
                              }`,
                              { count: _vm.parents.length - 1 }
                            )
                          )
                        ),
                      ]),
                      _c("BtnSquare", {
                        staticClass: "ml-4",
                        attrs: {
                          outlined: "",
                          small: "",
                          icon: "navigate_before",
                          colorIcon: "grey",
                        },
                        on: { click: _vm.prevParent },
                      }),
                      _c("BtnSquare", {
                        staticClass: "ml-2",
                        attrs: {
                          outlined: "",
                          small: "",
                          icon: "navigate_next",
                          colorIcon: "grey",
                        },
                        on: { click: _vm.nextParent },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1151192837
            ),
          })
        : _vm._e(),
      _vm.component && !_vm.contentLoading
        ? _c(_vm.component, {
            tag: "component",
            attrs: {
              content: _vm.content,
              box: _vm.box,
              options: _vm.options,
              contentTree: _vm.contentTree,
            },
          })
        : _vm._e(),
      _vm.contentLoading
        ? _c("ContentTemplateLoaderLoading", {
            attrs: { contentType: _vm.template, options: _vm.options },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }