var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex pt-1" },
    [
      _c(
        "v-tooltip",
        {
          attrs: { bottom: "", color: "primary lighten-3" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on: onGoals, attrs: attrsGoals }) {
                return [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex",
                      staticStyle: {
                        width: "130px",
                        position: "relative",
                        top: "-3px",
                      },
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-1", attrs: { size: "16" } },
                        [_vm._v("mdi-thumbs-up-down")]
                      ),
                      _c(
                        "div",
                        _vm._g(
                          _vm._b(
                            { staticClass: "primary--text" },
                            "div",
                            attrsGoals,
                            false
                          ),
                          onGoals
                        ),
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.calcPro(
                                  _vm.countPos + _vm.countNeg,
                                  _vm.countAll
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("span", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("stats.feedbackCount", {
                    answers: _vm.countPos + _vm.countNeg,
                    count: _vm.countAll,
                    answersPro: _vm.calcPro(
                      _vm.countPos + _vm.countNeg,
                      _vm.countAll
                    ),
                  })
                ) +
                " "
            ),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "bar relative d-flex" },
        [
          _vm.isLoading
            ? _c("v-skeleton-loader", {
                attrs: { type: "image", height: "16", width: "100%" },
              })
            : _vm._e(),
          !_vm.isLoading
            ? _c(
                "v-tooltip",
                {
                  attrs: { bottom: "", color: "primary lighten-3" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on: onAll, attrs: attrsAll }) {
                          return [
                            _c(
                              "div",
                              _vm._g(
                                _vm._b(
                                  { staticClass: "bar-all grey lighten-2" },
                                  "div",
                                  attrsAll,
                                  false
                                ),
                                onAll
                              )
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3617317198
                  ),
                },
                [
                  _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("stats.feedbackNotAnswered", {
                            count: _vm.countAll - (_vm.countPos + _vm.countNeg),
                          })
                        ) +
                        " "
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          !_vm.isLoading
            ? _c(
                "v-tooltip",
                {
                  attrs: { bottom: "", color: "primary lighten-3" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on: onPos, attrs: attrsPos }) {
                          return [
                            _c(
                              "div",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass: "bar-inline relative bar-pos",
                                    style: { width: _vm.posPro },
                                  },
                                  "div",
                                  attrsPos,
                                  false
                                ),
                                onPos
                              )
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3922396698
                  ),
                },
                [
                  _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("stats.feedbackCountPos", {
                            answers: _vm.countPos + _vm.countNeg,
                            countPos: _vm.countPos,
                            posPro: _vm.getCommaString(_vm.posProVotes),
                          })
                        ) +
                        " "
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          !_vm.isLoading
            ? _c(
                "v-tooltip",
                {
                  attrs: { bottom: "", color: "primary lighten-3" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on: onNeg, attrs: attrsNeg }) {
                          return [
                            _c(
                              "div",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass: "bar-inline relative bar-neg",
                                    style: { width: _vm.negPro },
                                  },
                                  "div",
                                  attrsNeg,
                                  false
                                ),
                                onNeg
                              )
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2322157210
                  ),
                },
                [
                  _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("stats.feedbackCountNeg", {
                            answers: _vm.countPos + _vm.countNeg,
                            countNeg: _vm.countNeg,
                            negPro: _vm.getCommaString(_vm.negProVotes),
                          })
                        ) +
                        " "
                    ),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }