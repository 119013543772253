var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "checker pa-1",
      on: {
        click: function ($event) {
          _vm.isDialogOpen = true
        },
      },
    },
    [
      _c("div", { staticClass: "d-flex" }, [
        _c(
          "div",
          { staticClass: "checker-icon mr-1", staticStyle: { padding: "2px" } },
          [
            _vm.status === _vm.statusTypes.loading
              ? _c(
                  "v-icon",
                  { key: "icon-a", staticClass: "mt-1", attrs: { size: "24" } },
                  [_vm._v(" mdi-eyedropper ")]
                )
              : _vm._e(),
            _vm.status === _vm.statusTypes.good
              ? _c(
                  "v-icon",
                  { key: "icon-b", attrs: { size: "28", color: "green" } },
                  [_vm._v(" mdi-check-circle ")]
                )
              : _vm._e(),
            _vm.status === _vm.statusTypes.bad
              ? _c(
                  "v-icon",
                  { key: "icon-c", attrs: { size: "28", color: "warning" } },
                  [_vm._v(" mdi-circle-half-full ")]
                )
              : _vm._e(),
          ],
          1
        ),
        _c("div", { staticClass: "checker-rows pr-2" }, [
          _c(
            "div",
            {
              staticClass: "checker-row-a text-base-2",
              staticStyle: { "font-size": "0.8em" },
            },
            [_vm._v(" " + _vm._s(_vm.$t("contrastChecker.title")) + " ")]
          ),
          _c("div", { staticClass: "checker-row-b" }, [
            _vm.status === _vm.statusTypes.loading
              ? _c(
                  "div",
                  { staticClass: "pt-1" },
                  [
                    _c("v-progress-linear", {
                      attrs: { indeterminate: "", color: "secondary darken-2" },
                    }),
                  ],
                  1
                )
              : _c(
                  "div",
                  {
                    staticClass: "text-base-2 mt-n1",
                    staticStyle: { "font-size": "0.7em" },
                  },
                  [
                    _vm.status === _vm.statusTypes.good
                      ? _c("span", [
                          _vm._v(
                            " " + _vm._s(_vm.$t("contrastChecker.good")) + " "
                          ),
                        ])
                      : _vm._e(),
                    _vm.status === _vm.statusTypes.bad
                      ? _c("span", [
                          _vm._v(
                            " " + _vm._s(_vm.$t("contrastChecker.bad")) + " "
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
          ]),
        ]),
      ]),
      _c(
        "HubDialog",
        {
          attrs: {
            title: _vm.$t("contrastChecker.dialog.title"),
            "primary-label": _vm.$t("common.ok"),
            "primary-icon": "close",
            hideSecondary: "",
          },
          on: {
            onPrimary: function ($event) {
              _vm.isDialogOpen = false
            },
          },
          model: {
            value: _vm.isDialogOpen,
            callback: function ($$v) {
              _vm.isDialogOpen = $$v
            },
            expression: "isDialogOpen",
          },
        },
        [
          _c("div", {
            domProps: {
              innerHTML: _vm._s(_vm.$t("contrastChecker.dialog.body")),
            },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }