var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isInitialized
        ? _c("apexchart", {
            attrs: {
              height: "300",
              width: "100%",
              type: "area",
              options: _vm.chartOptions,
              series: _vm.series,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }