var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-autocomplete", {
    attrs: {
      value: _vm.value,
      chips: "",
      multiple: "",
      items: _vm.items,
      "item-text": "name",
      "item-value": "code",
      label: _vm.$t("settingsLivechat.agentDetails.agentLanguages"),
      filter: _vm.filter,
    },
    on: {
      input: function ($event) {
        return _vm.$emit("input", $event)
      },
    },
    scopedSlots: _vm._u([
      {
        key: "item",
        fn: function (data) {
          return [
            _c("CountryFlag", {
              attrs: { country: data.item.country, size: "small" },
            }),
            _vm.useLanguages
              ? _c("span", { staticClass: "ml-1" }, [
                  _vm._v(
                    " " +
                      _vm._s(data.item.translatedName) +
                      " (" +
                      _vm._s(data.item.name) +
                      ") "
                  ),
                ])
              : _c("span", { staticClass: "ml-1" }, [
                  _vm._v(_vm._s(data.item.translatedName)),
                ]),
          ]
        },
      },
      {
        key: "selection",
        fn: function (data) {
          return [
            _c(
              "v-chip",
              _vm._b(
                {
                  staticClass: "mr-2",
                  attrs: { close: "" },
                  on: {
                    "click:close": function ($event) {
                      return _vm.removeSelected(data.item)
                    },
                  },
                },
                "v-chip",
                data.attrs,
                false
              ),
              [
                _c("CountryFlag", {
                  attrs: { country: data.item.country, size: "small" },
                }),
                _vm.useLanguages
                  ? _c("span", { staticClass: "ml-1" }, [
                      _vm._v(
                        " " +
                          _vm._s(data.item.translatedName) +
                          " (" +
                          _vm._s(data.item.name) +
                          ") "
                      ),
                    ])
                  : _c("span", { staticClass: "ml-1" }, [
                      _vm._v(_vm._s(data.item.translatedName)),
                    ]),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }