<script>
export default {
  name: 'WidgetTitle',
  props: {
    "headline": {
      type: String,
      default: "Headline",
    },
    "headerContrast": {
      type: String,
      default: "light",
      enum: ["light", "dark"],
    },
    "headerColor": {
      type: String,
      default: "#CCCCCC",
    },
  }
};
</script>

<template>
  <div class="d-flex justify-space-between" :style="{
      'background-color': headerColor,
      'color': headerContrast === 'light' ? '#FFFFFF' : '#484848'
    }">
    <h3 class="title">{{ headline }}</h3>
    <div class="title-close">
      <v-icon :color="headerContrast === 'light' ? '#FFFFFF' : '#484848'">close</v-icon>
    </div>
  </div>
</template>

<style scoped lang="scss">
.title {
  flex-grow: 1;

  /* From the widget itself */
  max-width: 350px;
  overflow: hidden;
  padding: 15px 15px 25px 15px;
  font-size: 2.2em;
  font-weight: 500;
  align-self: center;
  text-align: left;
}
.title-close {
  /* From the widget itself */
  display: flex;
  align-content: center;
  justify-content: center;
  height: 60px;
  width: 50px;
}
</style>