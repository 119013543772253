var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { attrs: { cols: "3" } },
        [
          _c(
            "v-card",
            {
              staticClass: "p-4 mb-8",
              attrs: { outlined: "", elevation: "2" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "d-flex justify-center align-center",
                  staticStyle: { "min-height": "150px" },
                },
                [
                  !_vm.demoelement
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            width: "60px",
                            height: "60px",
                            "border-radius": "50%",
                            display: "flex",
                            "justify-content": "center",
                            "align-items": "center",
                          },
                          style: {
                            background: _vm.getCurrentColor,
                            color: _vm.getContrast,
                          },
                        },
                        [
                          _c(
                            "svg",
                            {
                              attrs: {
                                "data-v-a4e29072": "",
                                width: "38px",
                                height: "32px",
                                fill: _vm.getContrast,
                                viewBox: "0 0 38 32",
                                version: "1.1",
                                xmlns: "http://www.w3.org/2000/svg",
                                "xmlns:xlink": "http://www.w3.org/1999/xlink",
                              },
                            },
                            [
                              _c(
                                "g",
                                {
                                  attrs: {
                                    id: "Symbols",
                                    stroke: "none",
                                    "stroke-width": "1",
                                    "fill-rule": "evenodd",
                                  },
                                },
                                [
                                  _c(
                                    "g",
                                    {
                                      attrs: {
                                        id: "10-Teaser-/-Icon-/-nobadge",
                                        transform:
                                          "translate(-10.000000, -18.000000)",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d: "M42.7870161,18 C45.2722974,18 47.2870161,20.0147186 47.2870161,22.5 L47.2870161,45.6110514 L47.3519671,49.5 L39.7354526,45.6917427 L14.9875798,45.6234373 C12.5071569,45.6165912 10.5,43.6038868 10.5,41.1234545 L10.5,22.5 C10.5,20.0147186 12.5147186,18 15,18 L42.7870161,18 Z M19.0396423,29.7817959 C17.1467242,29.7817959 15.6122108,31.3163093 15.6122108,33.2092275 C15.6122108,35.1021456 17.1467242,36.636659 19.0396423,36.636659 C20.9325605,36.636659 22.4670739,35.1021456 22.4670739,33.2092275 C22.4670739,31.3163093 20.9325605,29.7817959 19.0396423,29.7817959 Z M29.2552925,29.7817959 C27.3623743,29.7817959 25.8278609,31.3163093 25.8278609,33.2092275 C25.8278609,35.1021456 27.3623743,36.636659 29.2552925,36.636659 C31.1482106,36.636659 32.682724,35.1021456 32.682724,33.2092275 C32.682724,31.3163093 31.1482106,29.7817959 29.2552925,29.7817959 Z M39.4709426,29.7817959 C37.5780244,29.7817959 36.043511,31.3163093 36.043511,33.2092275 C36.043511,35.1021456 37.5780244,36.636659 39.4709426,36.636659 C41.3638608,36.636659 42.8983741,35.1021456 42.8983741,33.2092275 C42.8983741,31.3163093 41.3638608,29.7817959 39.4709426,29.7817959 Z",
                                          id: "Chatbubble",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c(_vm.demoelement, {
                    tag: "component",
                    attrs: {
                      colors: {
                        color: _vm.getCurrentColor,
                        contrast: _vm.getContrast,
                      },
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "7" } },
        [
          _c(
            "v-card",
            { attrs: { color: "transparent", flat: "" } },
            [
              _c("v-card-title", { staticClass: "pt-0" }, [
                _vm._v(_vm._s(this.title)),
              ]),
              _c("v-card-subtitle", [
                _vm._v(" " + _vm._s(this.subtitle) + " "),
              ]),
              _c("v-card-text", [
                _vm._v(" " + _vm._s(this.explaination) + " "),
              ]),
              _c("v-card-subtitle", { staticClass: "pb-0" }, [
                _c("strong", [_vm._v("Farbe")]),
              ]),
              _c("v-card-actions", { staticClass: "px-16" }, [
                _c(
                  "div",
                  { staticClass: "d-flex" },
                  _vm._l(this.colors, function (color, index) {
                    return _c(
                      "div",
                      { key: index },
                      [
                        _c(
                          "v-chip",
                          {
                            staticClass: "ma-1",
                            attrs: {
                              outlined: "",
                              color: "blue-grey lighten-3",
                              "text-color": "black",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.selectColor(color.name)
                              },
                            },
                          },
                          [
                            _c(
                              "v-avatar",
                              { attrs: { left: "" } },
                              [
                                _vm.value.color === color.name
                                  ? _c(
                                      "v-icon",
                                      { attrs: { color: color.color } },
                                      [_vm._v("mdi-checkbox-marked-circle")]
                                    )
                                  : _c(
                                      "v-icon",
                                      { attrs: { color: color.color } },
                                      [_vm._v("mdi-checkbox-blank-circle")]
                                    ),
                              ],
                              1
                            ),
                            _vm._v(" " + _vm._s(color.name) + " "),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]),
              _c("v-card-subtitle", { staticClass: "pb-0" }, [
                _c("strong", [_vm._v("Kontrast")]),
              ]),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-chip",
                    {
                      staticClass: "ma-2",
                      attrs: {
                        color: "blue-grey lighten-3",
                        outlined: "",
                        "text-color": "black",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.selectContrast("light")
                        },
                      },
                    },
                    [
                      _c(
                        "v-avatar",
                        { attrs: { left: "" } },
                        [
                          _vm.value.contrast === "light"
                            ? _c("v-icon", [
                                _vm._v("mdi-checkbox-marked-circle"),
                              ])
                            : _c("v-icon", [
                                _vm._v("mdi-checkbox-blank-circle"),
                              ]),
                        ],
                        1
                      ),
                      _vm._v(" Light mode "),
                    ],
                    1
                  ),
                  _c(
                    "v-chip",
                    {
                      staticClass: "ma-2",
                      attrs: {
                        outlined: "",
                        "text-color": "black",
                        color: "blue-grey lighten-3",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.selectContrast("dark")
                        },
                      },
                    },
                    [
                      _c(
                        "v-avatar",
                        { attrs: { left: "" } },
                        [
                          _vm.value.contrast === "dark"
                            ? _c("v-icon", [
                                _vm._v("mdi-checkbox-marked-circle"),
                              ])
                            : _c("v-icon", [
                                _vm._v("mdi-checkbox-blank-circle"),
                              ]),
                        ],
                        1
                      ),
                      _vm._v(" Dark mode "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }