<template>
  <div>
    <HubDialog
      :value="open"
      :title="'Livechat hier verwenden?'"
      :primaryLabel="'Fortfahren'"
      @onPrimary="handleTabChange()"
      primary-icon="mdi-account-convert"
      modal
      @onSecondary="handleCancel()"
    >
      <div>
        <p>
          Du hast den Livechat in einem anderen Browser Tab/Fenster bereits
          geöffnet. Möchtest du den Livechat hier fortfahren?
        </p>
      </div>
    </HubDialog>
  </div>
</template>
<script>
import HubDialog from '@/components/hub/HubDialog.vue';

export default {
  name: 'LivechatDialogChangeTab',
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    HubDialog,
  },
  methods: {
    handleTabChange() {
      this.$store.dispatch('livechat/changeActiveTab');
      this.$emit('onClose');
    },
    handleCancel() {
      this.$router.go(-1);
    },
  },
};
</script>
