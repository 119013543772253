var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { staticStyle: { "max-height": "100vh", height: "100vh" } },
    [
      _c("HubMenu"),
      _c("Appbar"),
      _c("HubContent", {
        attrs: { isSidebarOpen: _vm.isSidebarOpen, fixedHeight: "" },
        scopedSlots: _vm._u([
          {
            key: "navbar",
            fn: function () {
              return [
                _c("HubContentNavbar", {
                  attrs: {
                    title: _vm.$t("intents.hubListTitle"),
                    isLoading: _vm.isLoading,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "toolbar",
                      fn: function () {
                        return [
                          _vm.isNewIntentBtnShown
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "d-relative",
                                  attrs: {
                                    color: _vm.toolbarBtnInEdit
                                      ? "secondary darken-3"
                                      : "primary lighten-3",
                                    text: "",
                                  },
                                  on: { click: _vm.createIntent },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.$t("intents.hubListNewIntent"))
                                    ),
                                  ]),
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "ml-2",
                                      attrs: { right: "" },
                                    },
                                    [_vm._v("mdi-plus-circle")]
                                  ),
                                  _vm.toolbarBtnInEdit
                                    ? _c(
                                        "v-icon",
                                        {
                                          staticClass: "ml-2",
                                          staticStyle: {
                                            position: "absolute",
                                            top: "-6px",
                                            right: "-14px",
                                          },
                                          attrs: {
                                            size: "12",
                                            color: "secondary darken-3",
                                          },
                                        },
                                        [_vm._v(" edit ")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.isAdmin ? _c("CreateIntent") : _vm._e(),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "sidebar",
            fn: function () {
              return [
                _vm.isNewIntentOpen
                  ? _c(
                      "HubContentSidebarSimple",
                      {
                        attrs: {
                          isOpen: _vm.isSidebarOpen,
                          title: _vm.isNewIntentOpen
                            ? _vm.$t("intents.hubListNewIntent")
                            : "",
                          icon: _vm.isNewIntentOpen ? "note_add" : "info",
                          isProminent: "",
                        },
                        on: { onClose: _vm.closeNewIntent },
                      },
                      [
                        _vm.isNewIntentOpen
                          ? _c(
                              "div",
                              { staticClass: "pa-4" },
                              [_c("IntentNew")],
                              1
                            )
                          : _vm._e(),
                      ]
                    )
                  : _c(
                      "HubContentSidebarSimple",
                      {
                        attrs: {
                          isOpen: _vm.isSidebarOpen,
                          title: "",
                          icon: "info",
                          isProminent: _vm.isNewIntentOpen,
                        },
                        on: { onClose: _vm.closeSidebar },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "pa-4" },
                          [
                            _c("IntentItemInfo", {
                              key: _vm.selectedIntent.intent,
                              attrs: { intent: _vm.selectedIntent },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
              ]
            },
            proxy: true,
          },
          {
            key: "content",
            fn: function () {
              return [
                _c("HubFrame", {
                  scopedSlots: _vm._u([
                    {
                      key: "content",
                      fn: function () {
                        return [
                          _c("IntentsList", {
                            ref: "intentsList",
                            attrs: {
                              id: "scroll-container",
                              isSidebarOpen: _vm.isSidebarOpen,
                            },
                            on: { onSelected: _vm.changeSelectedIntent },
                          }),
                          _c("SnackbarStack", {
                            attrs: { scopes: ["intents"] },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }