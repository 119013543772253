<template>
  <div>
    <div class="relative mx-auto" style="width: 391px">
      <div
        v-if="!startScreen"
        class="
            mx-auto
            pt-10
            pa-4
            text-body-2
            grey--text
            text--darken-2 text-center
          "
        style="max-width: 300px"
      >
        {{ $t('editor.widget.startscreen.notActivated') }}
      </div>
      <div v-else>
        <svg
          style="border-radius: 12px"
          class="floating"
          width="391"
          height="732"
          viewBox="0 0 391 732"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            <rect x="3" y="3" width="385" height="726" :fill="bgColor" rx="8" ry="8" />
          </g>
          <text
            :fill="textColor"
            xml:space="preserve"
            style="white-space: pre"
            font-family="Helvetica"
            font-size="22"
            font-weight="500"
            letter-spacing="0em"
          >
            <tspan x="36" y="50">{{ widgetTitle }}</tspan>
          </text>
          <path
            d="M360.615 31.7278L358.941 30.0544L352.307 36.6885L345.673 30.0544L344 31.7278L350.634 38.3618L344 44.9959L345.673 46.6692L352.307 40.0352L358.941 46.6692L360.615 44.9959L353.981 38.3618L360.615 31.7278Z"
            :fill="textColor"
          />
          <path
            d="M254.537 144C254.537 141.791 256.328 140 258.537 140H294.168C296.377 140 298.168 141.791 298.168 144V169.365C298.168 171.574 296.377 173.365 294.168 173.365H246L254.537 164.178V144Z"
            fill="white"
          />
          <text
            fill="black"
            xml:space="preserve"
            style="white-space: pre"
            font-family="Helvetica"
            font-size="21"
            letter-spacing="0em"
          >
            <tspan x="265" y="166.03">&#x1f44b;</tspan>
          </text>
        </svg>

        <div
          class="wpp-btn"
          :style="{ background: btnBgColor, color: btnTextColor }"
        >
          {{ startScreen.buttonLabel }}
        </div>

        <div class="wpp-texts">
          <div class="wpp-greeting" :style="{ color: textColor }">
            {{ startScreen.greeting }}
          </div>
          <div class="wpp-text" :style="{ color: textColor }">
            {{ startScreen.text }}
          </div>
          <div
            class="wpp-text-link"
            :style="{ color: textColor }"
            v-for="link in startScreen.links"
            :key="link.id"
          >
            <a :href="parseUrl(link.href)" target="_blank">{{ link.title }}</a>
          </div>
        </div>
        <div class="wpp-avatar">
          <img class="wpp-avatar-img" :src="avatarUrl" />
          <div class="wpp-avatar-ring "></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const colorDark = '#484848';
const colorWhite = '#FFFFFF';

export default {
  name: 'WidgetPrivacyPreview',
  props: {
    startScreen: {
      type: Object,
    },
    bgColor: {
      type: String,
      default: '#E5E5E5',
    },
    widgetTitle: {
      type: String,
      default: '',
    },
    headerContrast: {
      type: String,
      default: 'light',
    },
    avatarUrl: {
      type: String,
      default: '',
    },
  },
  computed: {
    textColor() {
      return this.headerContrast === 'light' ? colorWhite : colorDark;
    },
    btnBgColor() {
      return this.headerContrast === 'light' ? colorWhite : colorDark;
    },
    btnTextColor() {
      return this.headerContrast === 'light' ? colorDark : colorWhite;
    },
  },
  methods: {
    parseUrl(url) {
      if (url.startsWith('http')) {
        return url;
      }
      return `http://${url}`;
    },
  },
};
</script>
<style scoped lang="scss">
.floating {
  // elevation-4, but customized to be softer
  box-shadow: 0 2px 4px -1px rgba(0,0,0,.1),
    0 4px 5px 0 rgba(0,0,0,.07),
    0 1px 10px 0 rgba(0,0,0,.06);
}

.wpp-btn {
  position: absolute;
  bottom: 44px;
  right: 36px;
  padding: 8px 20px;
  border-radius: 20px;
  font-weight: 600;
  cursor: pointer;
}

.wpp-texts {
  position: absolute;
  top: 304px;
  left: 36px;
  right: 36px;
  font-family: Helvetica;
}

.wpp-greeting {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 16px;
  text-align: center;
}

.wpp-text {
  font-size: 16px;
  text-align: left;
  margin-bottom: 16px;
}
.wpp-text-link {
  font-size: 16px;
  text-align: left;
  margin-bottom: 16px;

  a {
    color: inherit;
    text-decoration: underline;
  }
}

$wpp-img-size: 96px;

.wpp-avatar {
  position: absolute;
  top: 160px;
  left: 50%;
  margin-left: $wpp-img-size * -0.5;
  height: $wpp-img-size;
  width: $wpp-img-size;
  overflow: hidden;
  border-radius: 50%;
}

.wpp-avatar-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.wpp-avatar-ring {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 4px solid #ffffff;
  border-radius: 50%;
}
</style>
