var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "moin-fresh", class: { "action-row": !_vm.useSeparator } },
    [
      _c(
        "div",
        {
          class: {
            "action-row-content": _vm.$slots.line && !_vm.useSeparator,
            "py-2": _vm.verticalPadding,
          },
        },
        [_vm._t("content")],
        2
      ),
      _vm.$slots.line && !_vm.useSeparator
        ? _c("div", { staticClass: "action-row-line" }, [_vm._t("line")], 2)
        : _vm._e(),
      _vm.useSeparator ? _c("ActionGapSeparator") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }