var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-btn",
        {
          attrs: { depressed: "", color: "secondary darken-2" },
          on: {
            click: function ($event) {
              _vm.dialogIsOpen = true
            },
          },
        },
        [
          _vm._v(" " + _vm._s(_vm.$t("common.add")) + " "),
          _c("v-icon", { attrs: { right: "" } }, [_vm._v("mdi-account-plus")]),
        ],
        1
      ),
      _c(
        "HubDialog",
        {
          attrs: {
            value: _vm.dialogIsOpen,
            title: _vm.$t("botSettings.newUser.title"),
            asyncDialog: "",
            primaryIcon: "mdi-send",
            primaryLabel: _vm.$t("botSettings.newUser.btnSend"),
            secondaryLabel: _vm.$t("common.cancel"),
            isLoading: _vm.isLoading,
            disabled: !_vm.isValid,
            modal: "",
          },
          on: {
            onSecondary: function ($event) {
              _vm.dialogIsOpen = false
            },
            onPrimary: _vm.sendNewUser,
          },
        },
        [
          _c("div", { staticClass: "text-body-1" }, [
            _vm._v(" " + _vm._s(_vm.$t("botSettings.newUser.info")) + " "),
          ]),
          _c("v-text-field", {
            staticClass: "mt-8",
            attrs: {
              label: _vm.$t("common.eMailAddress"),
              type: "email",
              "prepend-inner-icon": "mdi-at",
              rules: _vm.inputRules,
              "validate-on-blur": "",
            },
            on: { "update:error": (v) => (_vm.isValid = !v) },
            model: {
              value: _vm.emailInput,
              callback: function ($$v) {
                _vm.emailInput = $$v
              },
              expression: "emailInput",
            },
          }),
          _c(
            "v-radio-group",
            {
              model: {
                value: _vm.selectedRole,
                callback: function ($$v) {
                  _vm.selectedRole = $$v
                },
                expression: "selectedRole",
              },
            },
            _vm._l(_vm.rolesOptions, function (option) {
              return _c("v-radio", {
                key: option.value,
                attrs: { value: option.value, label: option.label },
              })
            }),
            1
          ),
          _c("InfoBox", {
            attrs: { text: _vm.$t("botSettings.newUser.infoBox") },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }