var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.agent
    ? _c(
        "div",
        { staticClass: "lc-header-menu d-flex" },
        [
          _vm.$vuetify.breakpoint.mdAndUp
            ? _c("v-divider", {
                staticClass: "mx-4",
                staticStyle: { height: "40px" },
                attrs: { vertical: "" },
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "full-height d-flex align-center" },
            [
              _c("v-avatar", { attrs: { size: "32", color: "mr-4" } }, [
                _vm.agent.avatar
                  ? _c("img", { attrs: { src: _vm.agent.avatar } })
                  : _vm._e(),
              ]),
              _c("div", [_vm._v(" " + _vm._s(_vm.agent.name) + " ")]),
              _c(
                "DropMenu",
                { attrs: { items: _vm.menuItems } },
                [
                  _c("BtnSquare", {
                    staticClass: "ml-4",
                    attrs: { icon: "mdi-chevron-down" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }