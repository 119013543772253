var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.content
        ? _c("v-timeline-item", {
            attrs: { "hide-dot": "", color: "grey lighten-3", "fill-dot": "" },
          })
        : _vm._e(),
      _vm.content
        ? _c("ActionList", {
            attrs: {
              actions: _vm.content.actions.actions,
              channelType: _vm.channelType,
            },
            on: { "hook:mounted": _vm.updateName, onUpdate: _vm.update },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }