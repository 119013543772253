import ApiService from "./api";

/**
 * @typedef {Object} QuotaAdjustment
 * @property {'quotaAdjustment'} type The type of contact request
 * @property {string} uniqueBotId The botId of the bot
 * @property {string} comment Additional text provided by the user
 * @property {Record<string, boolean>} quotas The types of quotas they want to adjust
 */
/**
 * @typedef {Object} GeneralContact
 * @property {'general'} type The type of contact request
 * @property {string} uniqueBotId The botId of the bot
 * @property {string} comment Additional text provided by the user
 * @property {string} csmId The ID of the Customer Success Manager they are contacting
 */

const NotificationService = {
    get: async (botId) => {
        try {
            const response = await ApiService.get(`/notifications/${botId}/`);
            return response.data;
        } catch (error) {
            return false;
        }
    },
    resend: async (botId, notificationId, recipients) => {
        try {
            const response = await ApiService.post(`/notifications/resend/${botId}/${notificationId}/${JSON.stringify(recipients)}`,);
            return response.data;
        } catch (error) {
            return false;
        }
    },
    /**
     * Send a notification to CSM / HubTasks
     * @param {QuotaAdjustment | GeneralContact} payload
     * @returns {Promise<{success: boolean, message?: string}>} Success is true if successfully contacted, else false with a message
     */
    notifyCSM: async (payload) => {
        if (!payload.uniqueBotId) throw new Error(`Unique Bot ID property is missing.`);

        try {
            const response = await ApiService.post(`/schaltzentrale/contact/csm`, payload);
            return response.data;
        } catch (error) {
            console.error(error);
            return {
                success: false,
                message: 'Failed to send notification to CSM'
            };
        }
    }
};

export default NotificationService;