var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { stroke: _vm.color, "stroke-width": _vm.weight } }, [
        _c(
          "path",
          {
            attrs: {
              d: _vm.frozen ? _vm.paths[0][2] : "",
              "stroke-linejoin": "round",
              "stroke-linecap": "round",
              fill: _vm.starFill,
            },
          },
          [
            !_vm.frozen
              ? _c("animate", {
                  attrs: {
                    attributeName: "d",
                    values: `${_vm.paths[0][0]};${_vm.paths[0][1]};${_vm.paths[0][2]};${_vm.paths[0][0]};`,
                    dur: _vm.speed,
                    repeatCount: "indefinite",
                  },
                })
              : _vm._e(),
          ]
        ),
        _c(
          "path",
          {
            attrs: {
              d: _vm.frozen ? _vm.paths[1][2] : "",
              "stroke-linejoin": "round",
              "stroke-linecap": "round",
              fill: _vm.starFill,
            },
          },
          [
            !_vm.frozen
              ? _c("animate", {
                  attrs: {
                    attributeName: "d",
                    values: `${_vm.paths[1][0]};${_vm.paths[1][1]};${_vm.paths[1][2]};${_vm.paths[1][0]}`,
                    dur: _vm.speed,
                    repeatCount: "indefinite",
                  },
                })
              : _vm._e(),
          ]
        ),
        _c(
          "path",
          {
            attrs: {
              d: _vm.frozen ? _vm.paths[2][2] : "",
              "stroke-linejoin": "round",
              "stroke-linecap": "round",
              fill: _vm.starFill,
            },
          },
          [
            !_vm.frozen
              ? _c("animate", {
                  attrs: {
                    attributeName: "d",
                    values: `${_vm.paths[2][0]};${_vm.paths[2][1]};${_vm.paths[2][2]};${_vm.paths[2][0]};`,
                    dur: _vm.speed,
                    repeatCount: "indefinite",
                  },
                })
              : _vm._e(),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }