var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "text-caption" },
    [
      _c(
        "v-col",
        _vm._l(Object.keys(_vm.passwordRequirements), function (req) {
          return _c("v-row", { key: req }, [
            _c(
              "span",
              { class: { "text--disabled": _vm.passwordRequirements[req] } },
              [
                _c(
                  "v-icon",
                  {
                    attrs: {
                      color: _vm.passwordRequirements[req]
                        ? "success"
                        : "warning",
                      "x-small": "",
                      left: "",
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.passwordRequirements[req]
                          ? "mdi-check"
                          : "mdi-circle-outline"
                      )
                    ),
                  ]
                ),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "login.passwordReset.setPassword.complexity." + req
                      )
                    ) +
                    " "
                ),
              ],
              1
            ),
          ])
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }