var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.isCurrentTabActive && _vm.botHasLivechat
        ? _c(
            "v-icon",
            {
              staticStyle: {
                position: "absolute",
                top: "-8px",
                left: "-12px",
                opacity: "0.7",
              },
              attrs: { small: "", color: "primary lighten-4" },
            },
            [_vm._v(" mdi-bell-off ")]
          )
        : _vm._e(),
      _vm.isShown ? _c("PulseBoeble") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }