var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return false
    ? _c("HubBoxSimple", {
        attrs: {
          title: "Tipps / Hilfe",
          icon: "help_outline",
          titleHeightAuto: "",
        },
        scopedSlots: _vm._u(
          [
            {
              key: "content",
              fn: function () {
                return [
                  _c("p", { staticClass: "text-body-2" }, [
                    _vm._v(
                      " Dieser Bericht zielt darauf ab, die Themen zu finden, die deine Aufmerksamkeit erfodert. Nutze die Ansicht für die Themen mit dem größten Impact, um diese weiter zu optimieren. Die Ansicht “Themen mit dem größten Potential, zeigt dir, bei welchen Themen noch Verbesserungen vorgenommen werden können. "
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          3188123410
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }