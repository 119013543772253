/**
 * A getter function that returns the content of a node/box
 * @example
 * const getBoxContent = (box) => {
 *  if (!box) return null;
 *  return currentContent[box] ?? null;
 * }
 * @typedef {(box: string) => null | Object} BoxNodeGetter
 */

// a util class for tree data structure
// a tree is a data structure that consists of nodes in a parent / child relationship
// tree has a walk method that traverses the tree and executes a callback on each node

class TreeNode {
  constructor(content) {
    // content is String than it is a box, otherwise it is a content with property box
    this.box = typeof content === 'string' ? content : content?.box;
  
    this.children = [];
    this.parents = [];
  }

  addParent(parent, index) {
    // parent with this box name already exists, return
    if (this.parents.find((p) => p.box === parent.box)) {
      return;
    }
    
    this.parents.push({ box: parent.box, index });
  }

  /**
   * @param {TreeNode | Object} content
   * @returns {TreeNode}
   */
  appendChild(content) {
    // if content is instance of TreeNode, set it as child
    if (content instanceof TreeNode) {
      content.addParent(this, this.children.length);
      this.children.push(content);
      return content;
    }

    const newNode = new TreeNode(content);
    newNode.addParent(this, this.children.length);
    this.children.push(newNode);
    return newNode;
  }

  getChildren() {
    return this.children;
  }

  getBoxName() {
    return this.box;
  }

  /**
   * @param {TreeNode | string} node If string, it is treated as 'box', and `node` will be root
   * @param {string | null} [box]
   */
  findNode(node, box) {
    // node is string
    if (typeof node === 'string') {
      return this.findNode(this, node);
    }

    if (node.box === box) {
      return node;
    }

    for (let i = 0; i < node.children.length; i += 1) {
      const found = this.findNode(node.children[i], box);
      if (found) {
        return found;
      }
    }

    return null;
  }
}

class Tree {
  constructor() {
    this.root = null;
    this.nodes = [];
  }

  // set the root node of the tree
  setRoot(content) {
    // if content is from TreeNode, set it as root
    if (content instanceof TreeNode) {
      this.root = content;
      return this.root;
    }
  
    // if content is string or content object, create a new TreeNode
    this.root = new TreeNode(content);
    this.nodes.push(this.root);
    return this.root;
  }

  getRoot() {
    return this.root;
  }

  /**
   * build the tree from content store, getBoxContent is getter function, root has to be set
   * @param {Object | BoxNodeGetter} getBoxContent
   * @returns {void}
   */
  buildTree(getBoxContent) {
    // if getBoxContent is not a function, return
    if (typeof getBoxContent !== 'function' && typeof getBoxContent !== 'object') {
      return;
    } else if (typeof getBoxContent === 'object') {
      // generate standard getBoxContent
      const contents = _.cloneDeep(getBoxContent);

      getBoxContent = (box) => {
        if (!box || !contents[box]) return null;
        return contents[box];
      }
    }

    // if root is not set, return
    if (!this.root) {
      return;
    }

    this.nodes = [];

    // build tree
    this.buildNode(this.root, getBoxContent);
  }

  // this method is use for building tree from array of nodes, like in forms
  buildNodeFromArray(node, getBoxContent, childBoxes) {
    
    // no box property, end the recursion
    if (!node.box) {
      return;
    }

    const nodeContent = getBoxContent(node.box);
    // no node content, end the recursion
    if (!nodeContent) {
      return;
    }
   
    // get the child box from childBoxes array
    const childBox = childBoxes.find((box) => box.box === nodeContent.box);

    // child box has next property for one child or values property for multiple children
    if (childBox.next) {
      const nodeExists = this.nodes.find((n) => n.box === childBox.next);
      const newNode = node.appendChild(nodeExists || getBoxContent(childBox.next) || childBox.next);
      if (!nodeExists) {
        this.nodes.push(newNode);
      }
      this.buildNodeFromArray(newNode, getBoxContent, childBoxes);
    } else if (childBox.values && Array.isArray(childBox.values)) {
      for (const value of childBox.values) {
        if (!value.box) continue;
        
        const nodeExists = this.nodes.find((n) => n.box === value.box);
        const newNode = node.appendChild(nodeExists || getBoxContent(value.box) || value.box);
        if (!nodeExists) {
          this.nodes.push(newNode);
        }
        
        this.buildNodeFromArray(newNode, getBoxContent, childBoxes);
      }
    }

    // BotFormEmail && BotFormValidableInput: 
    if (nodeContent.template === 'BotFormEmail' || nodeContent.template === 'BotFormValidableInput') {
      // add as second child a end node.
      const endNode = node.appendChild('end');
      this.nodes.push(endNode);
      // add as third child a cancel node.
      const cancelNode = node.appendChild('cancel');
      this.nodes.push(cancelNode);
    }
  }

  // build node and its children
  buildNode(node, getBoxContent) {
    const nodeContent = getBoxContent(node.box);

    if (!nodeContent) {
      return;
    }

    // if node value has followup property, build its children
    if (nodeContent.followup && nodeContent.followup.box) {
      const nodeExists = this.nodes.find((n) => n.box === nodeContent.followup.box);
      const newNode = node.appendChild(nodeExists || getBoxContent(nodeContent.followup.box));

      if (!nodeExists) {
        this.nodes.push(newNode);
        this.buildNode(newNode, getBoxContent);
      }
    } else if (nodeContent.followup && !nodeContent.followup.box) {
      node.appendChild('completion');
    } else if (nodeContent.answers && Array.isArray(nodeContent.answers)) {
      let i = 0;
      for (const answer of nodeContent.answers) {
        // copy answer to get two different objects
        const cloneOfAnswer = _.cloneDeep(answer);
        if (cloneOfAnswer.template === 'copy') {
          const copyFrom = nodeContent.answers.find((a, n) => {
            if (a.box && n !== i) {
              return true;
            }

            return false;
          })

          if (copyFrom) {
            cloneOfAnswer.box = copyFrom.box;
          }
        }

        if (!cloneOfAnswer.box) {
          node.appendChild('completion');
          continue;
        }

        const nodeExists = this.nodes.find((n) => n.box === cloneOfAnswer.box);
        const newNode = node.appendChild(nodeExists || getBoxContent(cloneOfAnswer.box));

        if (!nodeExists) {
          this.nodes.push(newNode);
          this.buildNode(newNode, getBoxContent);
        }

        i++;
      }
    } else {

      // handling forms
      if (nodeContent.childBoxes && Array.isArray(nodeContent.childBoxes)) {
        if (nodeContent.childBoxes.length === 0 && nodeContent.completion) {
          const completionNode = node.appendChild('completion');
          this.nodes.push(completionNode);
        } else {
          // get start child box
          const startChildBox = nodeContent.childBoxes.find((box) => box.box === nodeContent.start.next);
          const startChildNodeContent = getBoxContent(startChildBox.box);
          
          // cause the botforms has the knowledge of all child nodes but not the content
          if (!startChildNodeContent) {
            return;
          }

          const nodeExists = this.nodes.find((n) => n.box === startChildNodeContent.box);
          const newNode = node.appendChild(nodeExists || getBoxContent(startChildNodeContent.box));
          if (!nodeExists) {
            this.nodes.push(newNode);
          }

          this.buildNodeFromArray(newNode, getBoxContent, nodeContent.childBoxes);
        }
      }
    }

    // SimpleInformation: no branch node
    if (nodeContent.template === 'SimpleInformation') {
      // just add as second child a completion node.
      const completionNode = node.appendChild('completion');
      this.nodes.push(completionNode);
    }
  }

  // insert a node into the tree
  insert(box, parent) {
    // if parent is instance of TreeNode set it as parent otherwise find it
    const parentNode = parent instanceof TreeNode ? parent : this.findNode(parent);

    if (parentNode) {
      // check if the box is present at path between parent and root
      const path = this.getPath(parentNode.box);
      if (path.includes(box)) {
        // attention circular, return null
        return null;
      }

      return parentNode.appendChild(box);
    }

    // if parent is not found, return null
    return null;
  }

  /**
   * @param {TreeNode | string} node If string, it is treated as 'box', and `node` will be root
   * @param {string | null} [box]
   */
  findNode(node, box) {
    // node is string
    if (typeof node === 'string') {
      return this.root.findNode(node);
    }

    if (!node) {
      return null;
    }

    node.findNode(node, box);
  }

  getCurrentPath(box, selectedNodes = {}) {
    // walk the tree and find with the box and walk along the selectedNodes { [box]: { [property]: index } } 
    const path = [];
    this.getCurrentPathNode(this.root, box, selectedNodes, path);
    return path.reverse();  
  }

  getCurrentPathNode(node, box, selectedNodes, path) {
    if (node?.box === box) {
      path.push(node.box);
      return true;
    }

    if (node.children.length === 0 && box) {
      return false;
    } else if (node.children.length === 0) {
      path.push(node.box);
      return true;
    }

    if (node.children.length === 1) {
      const found = this.getCurrentPathNode(node.children[0], box, selectedNodes, path);
      if (found) {
        path.push(node.box);
        return true;
      }
    }

    if (node.children.length > 1) {
      if (selectedNodes[node.box]) {
        let selectedOption = selectedNodes[node.box].question || 0;
        
        if (selectedOption >= node.children.length) {
          selectedOption = node.children.length - 1;
        }

        const found = this.getCurrentPathNode(node.children[selectedOption], box, selectedNodes, path);
        if (found) {
          path.push(node.box);
          return true;
        }
      } else {
        const found = this.getCurrentPathNode(node.children[0], box, selectedNodes, path);
        if (found) {
          path.push(node.box);
          return true;
        }
      }
    }

    return false;
  }

  getPath(box) {
    const path = [];
    this.getPathNode(this.root, box, path);
    return path;
  }

  getPathNode(node, box, path) {
    if (node.box === box) {
      path.push(node.box);
      return true;
    }

    for (let i = 0; i < node.children.length; i += 1) {
      const found = this.getPathNode(node.children[i], box, path);
      if (found) {
        path.push(node.box);
        return true;
      }
    }

    return false;
  }

  remove(box) {
    return this.removeNode(this.root, box);
  }

  removeNode(node, box) {
    if (node.children.length === 0) {
      return;
    }

    for (let i = 0; i < node.children.length; i += 1) {
      if (node.children[i].box === box) {
        node.children.splice(i, 1);
        return node;
      }
      return this.removeNode(node.children[i], box);
    }
  }

  getAllNodesWithDepthNode(node, result, depth) {
    result.push({ box: node.box, depth });
    for (let i = 0; i < node.children.length; i += 1) {
      this.getAllNodesWithDepthNode(node.children[i], result, depth + 1);
    }
  }

  getAllNodesWithDepth() {
    const result = [];
    this.getAllNodesWithDepthNode(this.root, result, 0);
    // sort results by depth from low to high
    result.sort((a, b) => a.depth - b.depth);
   
    return result;
  }

  print(printFunc = (parentNode, node) => `${node.box} ->`) {
    return this.printNode(null, this.root, printFunc);
  }

  printNode(parentNode, node, printFunc) {
    let nodes = [];
    if (!node) return nodes;
    
    nodes.push(printFunc(parentNode, node));

    for (let i = 0; i < node.children.length; i += 1) {
      nodes = nodes.concat(this.printNode(node, node.children[i], printFunc));
    }
    return nodes;
  }
}

// export Tree and TreeNode
export { Tree, TreeNode };