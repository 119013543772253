var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("HubBoxSimple", {
    attrs: {
      title: _vm.$t("stats.reports.titlePlural"),
      icon: "poll",
      titleHeight: "",
    },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "v-list",
              { staticClass: "mt-n8 mx-n4" },
              [
                _c("v-divider"),
                _c(
                  "v-list-item-group",
                  _vm._l(_vm.items, function (item, index) {
                    return _c(
                      "div",
                      { key: index },
                      [
                        _c(
                          "v-list-item",
                          {
                            staticClass: "px-2 py-0",
                            attrs: { to: item.to, "exact-path": item.excact },
                          },
                          [
                            _c(
                              "v-list-item-icon",
                              [
                                !item.isAnualReview
                                  ? _c(
                                      "v-icon",
                                      {
                                        attrs: {
                                          small: "",
                                          color: "primary lighten-3",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              item && item.icon
                                                ? item.icon
                                                : "query_stats"
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "div",
                                      { staticClass: "relative" },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              small: "",
                                              color: "accentE",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " mdi-calendar-blank-outline "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-icon",
                                          {
                                            staticStyle: {
                                              position: "absolute",
                                              top: "6px",
                                              left: "1px",
                                              shadow: "0 0 0 2px #fff",
                                            },
                                            attrs: {
                                              small: "",
                                              color: "accentE",
                                            },
                                          },
                                          [_vm._v(" show_chart ")]
                                        ),
                                      ],
                                      1
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "v-list-item-content",
                              { staticClass: "py-0" },
                              [
                                _c("v-list-item-title", [
                                  _vm._v(" " + _vm._s(item.title) + " "),
                                  item.isAnualReview
                                    ? _c(
                                        "span",
                                        { staticClass: "accentE--text" },
                                        [_vm._v(_vm._s(_vm.anualReviewYear))]
                                      )
                                    : _vm._e(),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "v-list-item-action",
                              [
                                _c(
                                  "v-icon",
                                  { attrs: { color: "primary lighten-3" } },
                                  [_vm._v("navigate_next")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("v-divider"),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }