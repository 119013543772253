import { render, staticRenderFns } from "./DemoStepIntents.vue?vue&type=template&id=b2b97668&scoped=true"
import script from "./DemoStepIntents.vue?vue&type=script&lang=js"
export * from "./DemoStepIntents.vue?vue&type=script&lang=js"
import style0 from "./DemoStepIntents.vue?vue&type=style&index=0&id=b2b97668&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b2b97668",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/moin-hub/moin-hub/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b2b97668')) {
      api.createRecord('b2b97668', component.options)
    } else {
      api.reload('b2b97668', component.options)
    }
    module.hot.accept("./DemoStepIntents.vue?vue&type=template&id=b2b97668&scoped=true", function () {
      api.rerender('b2b97668', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/demo/DemoStepIntents.vue"
export default component.exports