var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex" }, [
    _vm.icon
      ? _c(
          "div",
          [
            _c(
              "v-icon",
              {
                staticClass: "mt-n2 mr-3",
                attrs: { size: "20", color: _vm.iconColor },
              },
              [_vm._v(" " + _vm._s(_vm.icon) + " ")]
            ),
          ],
          1
        )
      : _vm._e(),
    _c("div", {
      staticClass: "text-body-2 primary--text text--lighten-4",
      domProps: { innerHTML: _vm._s(_vm.text) },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }