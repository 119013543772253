var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("HubBoxSimple", {
        attrs: {
          title: _vm.$t("stats.reports.export.title"),
          icon: "mdi-file-download-outline",
          isLoading: _vm.isLoading,
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("p", { staticClass: "body-text-2" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("stats.reports.export.info")) + " "
                  ),
                ]),
                _c(
                  "v-btn",
                  {
                    staticClass: "mt-4",
                    attrs: {
                      depressed: "",
                      color: "primary lighten-2",
                      loading: _vm.isLoading,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.createPDF()
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("stats.reports.export.btn")) + " "
                    ),
                    _c("v-icon", { attrs: { right: "" } }, [
                      _vm._v("mdi-file-download-outline"),
                    ]),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(_vm.componentMapping[_vm.$route.params.type], {
        ref: "pdfExport",
        tag: "component",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }