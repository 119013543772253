var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-fade-transition",
    [
      _c("v-app", [
        _c(
          "div",
          { staticClass: "app-bot-select moin-bg-grey-lightest" },
          [
            _c(
              "v-card",
              { staticClass: "pa-6 ma-4" },
              [
                _c("div", { staticClass: "d-flex justify-center" }, [
                  _c(
                    "div",
                    { staticClass: "mb-8", staticStyle: { height: "40px" } },
                    [
                      _c("v-img", {
                        attrs: { src: require("@/assets/moin-logo.svg") },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("v-select", {
                  staticStyle: { width: "300px" },
                  attrs: {
                    placeholder: _vm.$t("common.chooseABot"),
                    outlined: "",
                    items: _vm.bots,
                    value: _vm.currentBot,
                    "item-text": "name",
                    "item-value": "uniqueBotId",
                    "data-cy": "app-bot-select",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.changeBot($event)
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }