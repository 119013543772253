<script>
import BtnSquare from '@/components/common/BtnSquare.vue';
import HubBoxSimple from '@/components/hub/HubBoxSimple.vue';
import ProfileAvatar from '@/components/common/ProfileAvatar.vue';
import LivechatSpecialViewWrapper from '@/components/livechat/special-views/LivechatSpecialViewWrapper.vue';
import LivechatStateCallout from '@/components/livechat/special-views/LivechatStateCallout.vue';

export default {
  name: 'LivechatAgentOffline',
  events: ["goOnline"],
  components: {
    LivechatStateCallout,
    LivechatSpecialViewWrapper,
    ProfileAvatar,
    HubBoxSimple,
    BtnSquare,
  },
  props: {
    "agents": {
      type: Array,
      default: () => [],
    },
    "canManageAgents": {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    /**
     * A list of agents that are online
     * @returns {Object[]}
     */
    agentsOnline() {
      // If you get yourself in this list, likely because of another tab with a different environment
      return this.agents.filter((agent) => agent.status === 'online');
    },
  },
};
</script>

<template>
  <LivechatSpecialViewWrapper id="agent-offline">
    <!-- Header content: You are offline -->
    <template>
      <h2 class="text-h2 primary--text text--lighten-2">{{ $t('livechat.screens.agentOffline.title') }}</h2>
      <p class="mb-0 primary--text text--lighten-2">{{ $t('livechat.screens.agentOffline.description') }}</p>
      <v-btn
        color="secondary"
        class="mt-3"
        depressed
        @click="$emit('goOnline')"
      >
        {{ $t('livechat.screens.agentOffline.ctaLabel') }}
        <v-icon class="ml-2">mdi-face-agent</v-icon>
      </v-btn>
    </template>

    <!-- Secondary container: Other agents online -->
    <template #secondary>
      <div class="d-flex gap-4 justify-space-between">
        <h3 class="tet-h3 mb-2">{{ $t('livechat.screens.agentOffline.otherAgentsTitle') }}</h3>

        <v-btn
          v-if="canManageAgents"
          small
          outlined
          color="primary"
          :to="{ name: 'settings-livechat' }"
        >
          {{ $t('livechat.manageAgents') }}
          <v-icon right>settings</v-icon>
        </v-btn>
      </div>

      <p class="text-body-1 mb-8" v-if="agentsOnline.length">
        {{ $tc('livechat.screens.agentOffline.otherAgentsDescription', agentsOnline.length, { count: agentsOnline.length }) }}
      </p>

      <!-- Agents online list -->
      <div v-if="agentsOnline.length" id="agents-online">
        <!-- An agent -->
        <div
          v-for="agent in agentsOnline"
          class="d-flex gap-2 items-center mb-2"
        >
          <ProfileAvatar small :profilePicture="agent.profilePicture" />
          <span class="ml-2">{{ agent.name }}</span>
        </div>
      </div>

      <!-- No agents online -->
      <LivechatStateCallout
        v-else
        class="py-8"
        classes="grey--text text--lighten-1"
        :title="$t('livechat.screens.agentOffline.noAgentsOnline')"
        icon="mdi-face-agent"
        :description="$t('livechat.screens.agentOffline.noAgentsOnlineDescription')"
      />
    </template>
  </LivechatSpecialViewWrapper>
</template>
