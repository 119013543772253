var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex align-end" },
    [
      _c(
        "div",
        { staticClass: "align-self-center d-flex" },
        [
          _c("DropDown", {
            attrs: { items: _vm.chatDropdownItems, autoClose: true },
            scopedSlots: _vm._u([
              {
                key: "actuator",
                fn: function ({ toggle }) {
                  return [
                    _c(
                      "v-btn",
                      { attrs: { icon: "" }, on: { click: toggle } },
                      [_c("v-icon", [_vm._v("mdi-plus")])],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "message-field-wrapper" },
        [
          _c("LivechatShortcodesList", {
            ref: "shortcodesListRef",
            attrs: {
              filter: _vm.shortcodeFilter,
              show: _vm.showShortcodesPanel,
            },
            on: { select: _vm.onShortcodeSelected },
          }),
          _c("div", { staticClass: "chat-input-wrapper" }, [
            _vm.files.length > 0
              ? _c(
                  "div",
                  { staticClass: "input-top-info pa-1" },
                  _vm._l(_vm.files, function (file) {
                    return _c(
                      "v-chip",
                      {
                        key: file.url,
                        staticClass: "mr-1 grey lighten-3",
                        attrs: { close: "", small: "" },
                        on: {
                          "click:close": function ($event) {
                            return _vm.removeFile(file)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(file.name) + " ")]
                    )
                  }),
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "chat-input" },
              [
                _c("v-textarea", {
                  ref: "messageInputRef",
                  attrs: {
                    rows: "1",
                    solo: "",
                    flat: "",
                    "hide-details": "",
                    "auto-grow": "",
                    dense: "",
                    disabled: _vm.disabled,
                  },
                  on: {
                    input: _vm.onInput,
                    keydown: _vm.onKeyDown,
                    keypress: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      if (
                        $event.ctrlKey ||
                        $event.shiftKey ||
                        $event.altKey ||
                        $event.metaKey
                      )
                        return null
                      return _vm.sendMessage.apply(null, arguments)
                    },
                  },
                  model: {
                    value: _vm.message,
                    callback: function ($$v) {
                      _vm.message = $$v
                    },
                    expression: "message",
                  },
                }),
                _c(
                  "div",
                  { staticClass: "input-bottom-info" },
                  [
                    _vm._t("bottom-info", function () {
                      return [
                        _vm.useTranslation && !_vm.translationServiceError
                          ? _c(
                              "div",
                              [
                                _c(
                                  "v-icon",
                                  { staticClass: "pr-1", attrs: { small: "" } },
                                  [_vm._v("mdi-web")]
                                ),
                                !_vm.inputTranslation
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "livechat.chats.autoTranslationHint",
                                              {
                                                lang: _vm.$t(
                                                  `common.languageNames.${_vm.activeChatLanguage?.toLowerCase()}`
                                                ),
                                              }
                                            )
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _c("span", [
                                      _vm._v(_vm._s(_vm.inputTranslation)),
                                    ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.translationServiceError
                          ? _c(
                              "div",
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "pr-1",
                                    attrs: {
                                      small: "",
                                      color: "red lighten-1",
                                    },
                                  },
                                  [_vm._v("mdi-alert-circle")]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "red--text text--lighten-1" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "livechat.chats.autoTranslationErrorHint"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "v-btn",
        {
          staticClass: "primary lighten-2 ml-2",
          attrs: { icon: "", color: "white" },
          on: {
            click: function ($event) {
              return _vm.sendMessage()
            },
          },
        },
        [_c("v-icon", [_vm._v("mdi-send")])],
        1
      ),
      _c("LivechatFilesModal", {
        attrs: { open: _vm.showFilesModal },
        on: { cancel: _vm.onFilesModalCancel, add: _vm.onFilesModalAdd },
      }),
      _c("LivechatNotesModal", {
        attrs: { open: _vm.showNotesModal },
        on: { cancel: _vm.onNotesModalCancel, add: _vm.onNotesModalAdd },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }