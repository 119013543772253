<template>
  <div v-if="item">
    <v-list-item-title>{{ i18nDateFormat(item.created, $vuetify.lang.current, $t('common.timeFormatShort')) }}
      <span v-if="!selected" class="pl-2">
        <template v-for="t in item.all">
          <AnalysisItem :key="t._id" :item="t" type="label" />
        </template>
      </span>
    </v-list-item-title>
    <div v-if="item.star_rating" class="mt-1 rating-container">
        <button class="rating" aria-label="Show rate conversation buttons">
          {{ item.star_rating }}
          <svg width="12" height="12" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z" fill="#222C50"></path>
          </svg>
        </button>
    </div>
  </div>
</template>

<script>
import AnalysisItem from '@/components/conversation/AnalysisItem.vue'
import { i18nDateFormat } from '@/utils/formatDateTime'

export default {
  name: 'InsightsListItem',
  components: {
    AnalysisItem,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    selected: Boolean,
  },
  data() {
    return {
      i18nDateFormat,
    }
  },
}
</script>

<style scoped>
.rating-container {
  display: flex;
  align-items: center;
  padding-left: 0;
}

.rating {
  background: #f0f0f0;
  border: none;
  color: #222C50;
  display: flex;
  align-items: center;
  font-size: 1rem;
  cursor: pointer;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  margin-left: 0;
}

.rating svg {
  margin-left: 0.25rem;
}
</style>
