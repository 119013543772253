var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "HubBox",
        {
          attrs: {
            title: "Status",
            titleSub: _vm.statusTitle,
            icon: _vm.user.active ? "mdi-account-check" : "mdi-send-check",
            expandedInitial: "",
          },
        },
        [
          _c("v-divider", { staticClass: "mb-4" }),
          !_vm.user.active
            ? _c("div", { staticClass: "mb-4" }, [
                _c("div", { staticClass: "text-body-2" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("botSettings.userStatus.validUntil")) +
                      " "
                  ),
                ]),
                _c("div", { staticClass: "text-body-1" }, [
                  _vm._v(_vm._s(_vm.dateMaxValid)),
                ]),
              ])
            : _vm._e(),
          _vm.user.active
            ? _c("div", { staticClass: "mb-4" }, [
                _c("div", { staticClass: "text-body-2" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("botSettings.userStatus.userCreated")) +
                      " "
                  ),
                ]),
                _c("div", { staticClass: "text-body-1" }, [
                  _vm._v(_vm._s(_vm.dateCreated)),
                ]),
              ])
            : _vm._e(),
          _c("v-divider", { staticClass: "my-4" }),
          _c("div", { staticClass: "mb-4" }, [
            _c("div", { staticClass: "text-body-2" }, [
              _vm._v(_vm._s(_vm.$t("botSettings.userStatus.status"))),
            ]),
            _c(
              "div",
              {
                staticClass: "text-body-1",
                class: [
                  _vm.user.active
                    ? "green--text"
                    : "primary--text text--lighten-3",
                ],
              },
              [
                _c("strong", [_vm._v(_vm._s(_vm.statusTitle))]),
                !_vm.user.active
                  ? [_c("br"), _c("span", [_vm._v(_vm._s(_vm.statusTitleSub))])]
                  : _vm._e(),
              ],
              2
            ),
          ]),
          !_vm.user.active
            ? _c(
                "v-btn",
                {
                  staticClass: "mt-6",
                  attrs: { outlined: "", color: "primary lighten-2" },
                  on: {
                    click: function ($event) {
                      _vm.dialogIsOpen = true
                    },
                  },
                },
                [
                  _vm._v(" " + _vm._s("erneut einladen") + " "),
                  _c("v-icon", { attrs: { right: "" } }, [_vm._v("mdi-send")]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "HubDialog",
        {
          attrs: {
            value: _vm.dialogIsOpen,
            title: "Einladung-Status",
            modal: "",
            asyncDialog: "",
            primaryIcon: "mdi-send",
            primaryLabel: _vm.$t("botSettings.userStatus.inviteBtn"),
            isLoading: _vm.isLoading,
          },
          on: {
            onSecondary: function ($event) {
              _vm.dialogIsOpen = false
            },
            onPrimary: _vm.sendAgain,
          },
        },
        [
          _c("div", { staticClass: "py-4" }, [
            !_vm.user.active
              ? _c("div", { staticClass: "text-body-1" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("botSettings.userStatus.pendingSubLong", {
                          userMail: _vm.user.username,
                        })
                      ) +
                      " "
                  ),
                ])
              : _vm._e(),
          ]),
          _c("InfoBox", {
            attrs: { text: _vm.$t("botSettings.userStatus.inviteHint") },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }