var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { style: { minHeight: _vm.hideCompare ? "80px" : "110px" } },
    [
      _vm.title
        ? _c(
            "div",
            {
              staticClass: "primary--text pb-0 text-center",
              class: [_vm.titleHeading ? "text-h6" : "text-body-1"],
            },
            [_vm._v(" " + _vm._s(_vm.title) + " ")]
          )
        : _vm._e(),
      _c("div", { staticStyle: { height: "48px" } }, [
        _vm.loading
          ? _c(
              "div",
              { staticClass: "d-flex justify-center pt-3" },
              [
                _c("v-skeleton-loader", {
                  attrs: { type: "image", height: "25", width: "48" },
                }),
              ],
              1
            )
          : _c(
              "div",
              { staticClass: "d-flex justify-center pt-1" },
              [
                !_vm.noIcon
                  ? _c(
                      "v-icon",
                      {
                        attrs: {
                          color: "primary lighten-4",
                          size: "32",
                          left: "",
                        },
                      },
                      [_vm._v(_vm._s(_vm.icon))]
                    )
                  : _vm._e(),
                _vm.noData
                  ? _c(
                      "div",
                      {
                        class: `pt-1 grey--text text--darken-1 ${
                          _vm.noIcon ? "text-h1" : "text-h4"
                        }`,
                      },
                      [_vm._v("0")]
                    )
                  : _vm.valueString
                  ? [
                      _c(
                        "div",
                        {
                          class: `text-truncate pt-1 text-center ${
                            _vm.noIcon ? "text-h2" : "text-h4"
                          }`,
                        },
                        [_vm._v(_vm._s(_vm.valueString))]
                      ),
                    ]
                  : [
                      _vm.isPercent
                        ? _c(
                            "div",
                            {
                              class: `pt-1 ${
                                _vm.noIcon ? "text-h1" : "text-h4"
                              }`,
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.formatNumberFixed(_vm.newValue)) +
                                  " "
                              ),
                              _c("span", [_vm._v("%")]),
                            ]
                          )
                        : _c(
                            "div",
                            {
                              class: `pt-1 ${
                                _vm.noIcon ? "text-h1" : "text-h4"
                              }`,
                            },
                            [_vm._v(" " + _vm._s(_vm.newValue) + " ")]
                          ),
                    ],
              ],
              2
            ),
      ]),
      _vm.loading
        ? _c(
            "div",
            { staticClass: "d-flex justify-center pt-1" },
            [
              _c("v-skeleton-loader", {
                attrs: { type: "image", height: "15", width: "30" },
              }),
            ],
            1
          )
        : !_vm.hideCompare
        ? _c(
            "div",
            { staticClass: "relative", staticStyle: { height: "30px" } },
            [
              _vm.noData
                ? _c(
                    "div",
                    { staticClass: "text-body-2 grey--text text-center" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("stats.iconNumberTeaser.noData")) +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
              !_vm.noData && !_vm.compareDisabled
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({
                              on: onTooltip,
                              attrs: attrsTooltip,
                            }) {
                              return [
                                _c(
                                  "div",
                                  _vm._g(
                                    _vm._b(
                                      { staticClass: "d-flex justify-center" },
                                      "div",
                                      attrsTooltip,
                                      false
                                    ),
                                    onTooltip
                                  ),
                                  [
                                    _vm.newValue < _vm.oldValue
                                      ? _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              size: "20",
                                              color: _vm.colorUp,
                                            },
                                          },
                                          [_vm._v(" arrow_downward ")]
                                        )
                                      : _vm._e(),
                                    _vm.newValue === _vm.oldValue
                                      ? _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              size: "20",
                                              color: "primary lighten-2",
                                            },
                                          },
                                          [_vm._v(" arrow_forward ")]
                                        )
                                      : _vm._e(),
                                    _vm.newValue > _vm.oldValue
                                      ? _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              size: "20",
                                              color: _vm.colorDown,
                                            },
                                          },
                                          [_vm._v(" arrow_upward ")]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "span",
                                      { staticClass: "primary--text" },
                                      [
                                        _vm._v(
                                          " " + _vm._s(_vm.changedPercent) + " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3691715810
                      ),
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.changedToolTip))])]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }