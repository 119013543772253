var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex gap-2 justify-end" },
    [
      _vm.isArchiveQuotaEnabled && _vm.isArchiveActive
        ? _c(
            "v-btn",
            {
              attrs: {
                color: "primary",
                depressed: "",
                small: "",
                disabled: !_vm.isArchiveActive,
              },
              on: {
                click: function ($event) {
                  _vm.isArchiveDialogOpen = true
                },
              },
            },
            [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.$t("livechat.chats.closeAndArchiveChatButton"))
                ),
              ]),
            ]
          )
        : _vm._e(),
      _c(
        "v-btn",
        {
          attrs: { color: "primary", depressed: "", small: "" },
          on: {
            click: function ($event) {
              _vm.isDialogOpen = true
            },
          },
        },
        [_vm._v(" " + _vm._s(_vm.$t("livechat.chats.closeChatButton")) + " ")]
      ),
      _c(
        "HubDialog",
        {
          attrs: {
            title: "Diesen Chat wirklich schließen?",
            confirmText: "Ja, schließen",
          },
          on: {
            onPrimary: function ($event) {
              return _vm.closeChat()
            },
            onSecondary: function ($event) {
              _vm.isDialogOpen = false
            },
          },
          model: {
            value: _vm.isDialogOpen,
            callback: function ($$v) {
              _vm.isDialogOpen = $$v
            },
            expression: "isDialogOpen",
          },
        },
        [
          _c("p", [
            _vm._v(_vm._s(_vm.$t("livechat.chats.closeChatDialogBody"))),
          ]),
        ]
      ),
      _c(
        "HubDialog",
        {
          attrs: { title: "Diesen Chat schließen?" },
          on: {
            onPrimary: function ($event) {
              return _vm.closeChatAndArchive()
            },
            onSecondary: function ($event) {
              _vm.isArchiveDialogOpen = false
            },
          },
          model: {
            value: _vm.isArchiveDialogOpen,
            callback: function ($$v) {
              _vm.isArchiveDialogOpen = $$v
            },
            expression: "isArchiveDialogOpen",
          },
        },
        [
          _c("p", [
            _vm._v(_vm._s("Dieser Chat wird geschlossen und archiviert")),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }