var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-menu",
        {
          attrs: { "close-on-content-click": false },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on: onMenu, attrs: attrsMenu }) {
                return [
                  _c("v-hover", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ hover }) {
                            return [
                              _c(
                                "v-text-field",
                                _vm._g(
                                  _vm._b(
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !_vm.searchMenu,
                                          expression: "!searchMenu",
                                        },
                                      ],
                                      staticClass: "mt-6",
                                      attrs: {
                                        readonly: "",
                                        "single-line": "",
                                        label: _vm.$t("search.label"),
                                        outlined: "",
                                        dense: "",
                                        color: "white",
                                        filled: "",
                                        "prepend-inner-icon": "search",
                                      },
                                      on: {
                                        "click:prepend-inner": function (
                                          $event
                                        ) {
                                          _vm.searchMenu = true
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "append",
                                            fn: function () {
                                              return [
                                                hover && _vm.getOS === "apple"
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-caption pt-1 grey--text text--lighten-1 hidden-md-and-down",
                                                      },
                                                      [_vm._v(" command+p ")]
                                                    )
                                                  : _vm._e(),
                                                hover && _vm.getOS === "windows"
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-caption pt-1 grey--text text--lighten-1 hidden-md-and-down",
                                                      },
                                                      [_vm._v(" windows+p ")]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                      model: {
                                        value: _vm.searchText,
                                        callback: function ($$v) {
                                          _vm.searchText = $$v
                                        },
                                        expression: "searchText",
                                      },
                                    },
                                    "v-text-field",
                                    attrsMenu,
                                    false
                                  ),
                                  onMenu
                                )
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ]
              },
            },
          ]),
          model: {
            value: _vm.searchMenu,
            callback: function ($$v) {
              _vm.searchMenu = $$v
            },
            expression: "searchMenu",
          },
        },
        [
          _c(
            "v-row",
            { staticClass: "ma-0 pa-0" },
            [
              _c(
                "v-col",
                { staticClass: "ma-0 pa-0" },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "pt-0 mt-n5",
                      attrs: { "min-width": "250px", "max-width": "320px" },
                    },
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "mt-3 pt-1 pl-0" },
                        [
                          _c("v-text-field", {
                            attrs: {
                              height: "40px",
                              "hide-details": "",
                              solo: "",
                              flat: "",
                              "single-line": "",
                              color: "primary",
                              "prepend-inner-icon": "search",
                              autofocus: "",
                            },
                            on: { input: _vm.updateSearch },
                            model: {
                              value: _vm.searchText,
                              callback: function ($$v) {
                                _vm.searchText = $$v
                              },
                              expression: "searchText",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      !_vm.emptyQuery && _vm.noResults
                        ? _c("v-card-text", { staticClass: "text-center" }, [
                            _vm._v(" " + _vm._s(_vm.$t("search.empty")) + " "),
                          ])
                        : _vm._e(),
                      _vm.emptyQuery && _vm.lastQueries.length === 0
                        ? _c("v-card-text", { staticClass: "text-center" }, [
                            _vm._v(
                              " " + _vm._s(_vm.$t("search.description")) + " "
                            ),
                          ])
                        : _vm._e(),
                      _vm.lastQueries.length > 0 && _vm.emptyQuery
                        ? _c("v-card-subtitle", { staticClass: "pb-0 pt-1" }, [
                            _vm._v(
                              " " + _vm._s(_vm.$t("search.history")) + " "
                            ),
                          ])
                        : _vm._e(),
                      _vm.lastQueries.length > 0 && _vm.emptyQuery
                        ? _c(
                            "v-list",
                            { staticClass: "pt-0" },
                            [
                              _vm._l(_vm.lastQueries, function (item, index) {
                                return [
                                  _c("v-hover", {
                                    key: index,
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ hover }) {
                                            return [
                                              _c(
                                                "v-list-item",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.callItem(item)
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-list-item-icon",
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            small: "",
                                                            color:
                                                              "primary lighten-3",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.icon
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-list-item-title",
                                                    {
                                                      staticClass:
                                                        "text-truncate",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.displayName
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-list-item-action",
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value: hover,
                                                              expression:
                                                                "hover",
                                                            },
                                                          ],
                                                          attrs: {
                                                            icon: "",
                                                            tile: "",
                                                            "x-small": "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.removeItem(
                                                                item
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                "x-small": "",
                                                              },
                                                            },
                                                            [_vm._v("close")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ]
                              }),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm.intentResults.length > 0
                        ? _c("v-card-subtitle", { staticClass: "pb-0 pt-1" }, [
                            _vm._v(" " + _vm._s(_vm.$t("search.topics")) + " "),
                          ])
                        : _vm._e(),
                      _vm.intentResults.length > 0
                        ? _c(
                            "v-list",
                            _vm._l(_vm.intentResults, function (item, index) {
                              return _c(
                                "v-list-item",
                                {
                                  key: index,
                                  attrs: {
                                    disabled:
                                      !_vm.isAdmin && item.value.suggested,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.callItem(item)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            small: "",
                                            color: "primary lighten-3",
                                          },
                                        },
                                        [_vm._v(" " + _vm._s(item.icon) + " ")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.displayName(
                                            item.value.intent,
                                            _vm.$vuetify.lang.current
                                          ) || item.displayName
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ],
                                1
                              )
                            }),
                            1
                          )
                        : _vm._e(),
                      _vm.isAdmin && _vm.botResults.length > 0
                        ? _c("v-card-subtitle", { staticClass: "pb-0 pt-1" }, [
                            _vm._v(" Gefundene Bots "),
                          ])
                        : _vm._e(),
                      _vm.isAdmin && _vm.botResults.length > 0
                        ? _c(
                            "v-list",
                            _vm._l(_vm.botResults, function (item, index) {
                              return _c(
                                "v-list-item",
                                {
                                  key: index,
                                  on: {
                                    click: function ($event) {
                                      return _vm.callItem(item)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            small: "",
                                            color: "primary lighten-3",
                                          },
                                        },
                                        [_vm._v(" " + _vm._s(item.icon) + " ")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(item.displayName)),
                                  ]),
                                ],
                                1
                              )
                            }),
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }