var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.items, function (item, index) {
      return _c("LivechatListItem", {
        key: index,
        attrs: { item: item },
        on: {
          onSelect: function ($event) {
            return _vm.selectItem(item)
          },
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }