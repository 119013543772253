<template>
  <div>
    <v-container fluid class="pa-6">
      <v-row>
        <v-col class="d-flex items-center" style="min-height: 70vh">
          <LivechatMarketingScreen
            :isLoading="isLoading"
            :ctaDisabled="!isAdmin && !isOwner"
            :labels="ctaLabels"
            @cta="createLivechat"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import LivechatService from '@/services/livechat';
import LivechatMarketingScreen from '@/components/settingsLivechat/LivechatMarketingScreen.vue';

export default {
  name: 'SettingsLivechatInactive',
  computed: {
    ...mapGetters('auth', ['user', 'isAdmin', 'isOwner',]),
    ...mapGetters('bots', ['currentBot', 'getBotSettings']),
  },
  components: { LivechatMarketingScreen },
  data() {
    return {
      isDialogRequestOpen: false,
      isLoading: false,
      ctaLabels: {
        title: this.$t('settingsLivechat.featurePopup.notActive'),
        description: this.$t('settingsLivechat.featurePopup.introDescription'),
        cta: this.$t('settingsLivechat.featurePopup.ctaLabel'),
      },
    };
  },
  methods: {
    async createLivechat() {
      try {
        this.isLoading = true;
        const organization = await this.$store.dispatch('livechat/createLivechat', {
          uniqueBotId: this.currentBot?.uniqueBotId,
        });

        const currentSettings = this.getBotSettings(this.currentBot?.uniqueBotId);
        if (!currentSettings.moin_livechat) {
          await LivechatService.addBotSettings({botId: this.currentBot.uniqueBotId, defaultGroup: organization.groups[0]});
          await this.$store.dispatch('bots/getBotById', this.currentBot.uniqueBotId);
        }

        await this.$store.dispatch('livechat/init', {
          botId: this.currentBot.uniqueBotId,
          routePath: this.$route.path,
        });

        this.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
