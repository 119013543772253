var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.content
        ? _c(
            "v-timeline-item",
            {
              attrs: {
                "hide-dot": "",
                color: "grey lighten-3",
                "fill-dot": "",
              },
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        staticClass: "font-weight-medium ma-0",
                        attrs: {
                          outlined: "",
                          dense: "",
                          label: _vm.$t("botFormEdit.whatIsAsked"),
                          disabled: _vm.isLivechatRating,
                        },
                        on: { input: _vm.update },
                        model: {
                          value: _vm.content.displayName,
                          callback: function ($$v) {
                            _vm.$set(_vm.content, "displayName", $$v)
                          },
                          expression: "content.displayName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-col", [
                    _vm.isAdmin
                      ? _c(
                          "div",
                          { staticClass: "moin-admin-box pa-1" },
                          [
                            _c("v-text-field", {
                              attrs: {
                                outlined: "",
                                dense: "",
                                label: _vm.$t("botFormEdit.technicalName"),
                                disabled: _vm.isLivechatRating,
                                "hide-details": "",
                              },
                              on: { input: _vm.update },
                              model: {
                                value: _vm.content.context,
                                callback: function ($$v) {
                                  _vm.$set(_vm.content, "context", $$v)
                                },
                                expression: "content.context",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.content
        ? _c("ActionList", {
            attrs: {
              actions: _vm.content.question.actions,
              channelType: _vm.channelType,
            },
            on: { "hook:mounted": _vm.updateName, onUpdate: _vm.update },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }