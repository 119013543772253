import ApiService from './api';

const DemoService = {
  getLocalUserId() {
    return localStorage.getItem('moin-user-id');
  },
  setLocalUserId(userId) {
    localStorage.setItem('moin-user-id', userId);
  },
  getUserIdWithFingerprintId: async ({ fingerprintId }) => {
    try {
      const res = await ApiService.get(
        `/schaltzentrale/demo/init/${fingerprintId}`
      );
      return res?.data;
    } catch (error) {
      return { error };
    }
  },
  async getQuestionsWithCompany({ site, fingerprintId, language }) {
    let res = null;
    try {
      if (!site) return false;

      const body = {
        companyDescription: {
          site,
        },
        language,
        userId: this.getLocalUserId() || null,
      };

      if (process.env.NODE_ENV !== 'production') {
        body.noSlack = true;
      }

      res = await ApiService.post(
        `/schaltzentrale/demo/generateTopQueries/${fingerprintId}`,
        body
      );

      if (res.data.status === 'ERROR') {
        return { status: 'ERROR', message: res.data.message, queries: [] };
      }

      return res.data;
    } catch (error) {
      return { error };
    }
  },
  async getIntentsWithQuestions({ excludedQueries, fingerprintId }) {
    try {
      const body = {
        excludedQueries,
        userId: this.getLocalUserId() || null,
      };

      if (process.env.NODE_ENV !== 'production') {
        body.noSlack = true;
      }

      const res = await ApiService.post(
        `/schaltzentrale/demo/generateTopIntents/${fingerprintId}`,
        body
      );
      return res.data;
    } catch (error) {
      return { error };
    }
  },
  async getFeaturesWithQuestions({ excludedQueries, fingerprintId, language = 'de' }) {
    try {
      const body = {
        excludedQueries,
        userId: this.getLocalUserId() || null,
        language,
      };

      if (process.env.NODE_ENV !== 'production') {
        body.noSlack = true;
      }

      const res = await ApiService.post(
        `/schaltzentrale/demo/generateTopFeatures/${fingerprintId}`,
        body
      );
      return res.data;
    } catch (error) {
      return { error };
    }
  },
  async createDemoAccount({ contact, fingerprintId, language = 'de' }) {
    try {
      if (process.env.NODE_ENV !== 'production') {
        contact.sendToHubspot = false;
      }

      const body = {
        contact: {
          ...contact,
          language,
          marketing: contact.marketing ? 'Ja' : 'Nein',
        },
        userId: this.getLocalUserId() || null,
      };

      if (process.env.NODE_ENV !== 'production') {
        body.noSlack = true;
      }

      const res = await ApiService.post(
        `/schaltzentrale/demo/submit/${fingerprintId}`,
        body
      );

      return res.data;
    } catch (error) {
      return { error };
    }
  },
  async getAnswerWithQuestion({ question, site, fingerprintId }) {
    try {
      const body = {
        question,
        site,
      };

      const res = await ApiService.post(
        `/schaltzentrale/demo/answer/${fingerprintId}`,
        body
      );

      return res.data;
    } catch (error) {
      return { error };
    }
  },
  async getDemoRequests({ dateRange = null }) {
    let dates = '';

    if (dateRange) {
      dates = `?since=${dateRange[0].format(
        'YYYY-MM-DD'
      )}&to=${dateRange[1].format('YYYY-MM-DD')}`;
    }

    try {
      const res = await ApiService.get('/schaltzentrale/demo-hub' + dates);
      return res.data;
    } catch (error) {
      return { error };
    }
  },
  async addCommentToDemoRequest(botId, { comment }) {
    try {
      const response = await ApiService.post(
        `/schaltzentrale/demo-hub/comment/${botId}`,
        {
          comment,
        }
      );
      return response?.data;
    } catch (error) {
      return false;
    }
  },
  async deleteDemoRequest(demoId) {
    try {
      const response = await ApiService.delete(
        `/schaltzentrale/demo-hub/${demoId}`
      );
      return response?.data;
    } catch (error) {
      return false;
    }
  },
};

export default DemoService;
