var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("ActionRow", {
        staticClass: "action-row-end",
        scopedSlots: _vm._u([
          {
            key: "line",
            fn: function () {
              return [
                _c("ActionLine", {
                  attrs: { blockType: _vm.blockTypes.END_LOADING },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass: "relative",
                    staticStyle: { "max-width": "400px" },
                  },
                  [
                    _c("v-skeleton-loader", {
                      attrs: { height: 40, width: "100%", type: "image" },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }