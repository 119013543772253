var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.content
        ? _c(
            "v-timeline-item",
            {
              attrs: {
                "hide-dot": "",
                color: "grey lighten-3",
                "fill-dot": "",
              },
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        staticClass: "font-weight-medium ma-0",
                        attrs: {
                          outlined: "",
                          dense: "",
                          label: _vm.$t("botFormEdit.whatIsAsked"),
                        },
                        on: { input: _vm.update },
                        model: {
                          value: _vm.content.displayName,
                          callback: function ($$v) {
                            _vm.$set(_vm.content, "displayName", $$v)
                          },
                          expression: "content.displayName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-col", [
                    _vm.isAdmin
                      ? _c(
                          "div",
                          { staticClass: "moin-admin-box pa-1" },
                          [
                            _c("v-text-field", {
                              attrs: {
                                outlined: "",
                                dense: "",
                                label: _vm.$t("botFormEdit.technicalName"),
                                "hide-details": "",
                              },
                              on: { input: _vm.update },
                              model: {
                                value: _vm.content.context,
                                callback: function ($$v) {
                                  _vm.$set(_vm.content, "context", $$v)
                                },
                                expression: "content.context",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-select", {
                        attrs: {
                          outlined: "",
                          dense: "",
                          items: _vm.validableTypes,
                          "item-text": "label",
                          "item-value": "value",
                          disabled: _vm.validableType?.custom && !_vm.isRegex,
                          label: _vm.$t("botFormEdit.validable.entity"),
                        },
                        on: { input: _vm.handleEntityChange },
                        model: {
                          value: _vm.content.entity,
                          callback: function ($$v) {
                            _vm.$set(_vm.content, "entity", $$v)
                          },
                          expression: "content.entity",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.isRegex
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-select", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              items: _vm.getAllRegExp,
                              "item-text": "displayName",
                              "return-object": "",
                              disabled:
                                _vm.validableType?.custom && !_vm.isRegex,
                              label: _vm.$t("botFormEdit.validable.regexp"),
                            },
                            on: {
                              input: function ($event) {
                                return _vm.handleChange($event)
                              },
                            },
                            model: {
                              value: _vm.content.regexp,
                              callback: function ($$v) {
                                _vm.$set(_vm.content, "regexp", $$v)
                              },
                              expression: "content.regexp",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.content
        ? _c("ActionList", {
            attrs: {
              actions: _vm.content.question.actions,
              channelType: _vm.channelType,
            },
            on: { "hook:mounted": _vm.updateName, onUpdate: _vm.update },
          })
        : _vm._e(),
      _vm.content
        ? _c(
            "v-timeline-item",
            {
              attrs: { color: "white" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "icon",
                    fn: function () {
                      return [
                        _c("v-icon", { attrs: { color: "error" } }, [
                          _vm._v("cancel"),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                2229607582
              ),
            },
            [
              _c("span", { staticClass: "text-subtitle-1" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("botFormEdit.validable.validationErrorTitle")
                    ) +
                    " "
                ),
              ]),
              _c("p", { staticClass: "text-subtitle-2" }, [
                _vm._v(
                  _vm._s(_vm.$t("botFormEdit.validable.validationErrorText"))
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm.content
        ? _c("ActionList", {
            attrs: {
              actions: _vm.content.validation.actions,
              channelType: _vm.channelType,
            },
            on: { onUpdate: _vm.update },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }