var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "demo-container",
      class: [
        _vm.$vuetify.breakpoint.mdAndDown && "mx-auto",
        _vm.$vuetify.breakpoint.lgAndUp && "demo-container--lg",
      ],
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _vm.step === _vm.stepSlugs.stepCompanyLoading ||
              _vm.step === _vm.stepSlugs.stepQuestionsLoading
                ? _c("DemoStepLoad")
                : _vm._e(),
              _vm.step === _vm.stepSlugs.stepQuestions
                ? _c("DemoStepQuestions")
                : _vm._e(),
              _vm.step === _vm.stepSlugs.stepIntents
                ? _c("DemoStepIntents")
                : _vm._e(),
              _vm.step === _vm.stepSlugs.stepContact
                ? _c("DemoStepContact")
                : _vm._e(),
              _vm.step === 4 ? _c("DemoStepFinal") : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }