var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        { class: { "ma-3": !_vm.isInPopup }, attrs: { flat: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "white pb-0", attrs: { cols: "12", sm: "5" } },
                [
                  _c(
                    "v-row",
                    { attrs: { justify: "center" } },
                    [
                      _c(
                        "v-card",
                        { staticClass: "avatar-preview", attrs: { flat: "" } },
                        [
                          _c("v-card-title", [
                            _vm._v(_vm._s(_vm.$t("common.preview"))),
                          ]),
                          _c(
                            "v-card-text",
                            {
                              staticClass:
                                "text-center d-flex flex-column align-center",
                            },
                            [
                              _c("Avatar", {
                                ref: "avatar",
                                attrs: {
                                  image: _vm.image,
                                  "face-color": _vm.face.color,
                                  background: _vm.background.color,
                                  arm: _vm.arm.color,
                                  shirt: _vm.shirt.color,
                                  "logo-size": _vm.logoSize,
                                  "logo-posy": _vm.posy,
                                  "logo-posx": _vm.posx,
                                },
                                on: {
                                  onDragLogo: _vm.onDragLogo,
                                  onCreateAvatarBase64:
                                    _vm.onCreateAvatarBase64,
                                },
                              }),
                              _c("p", { staticClass: "mt-1" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "editor.widget.appearance.avatar.enlargedView"
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-card-text",
                            {
                              staticClass:
                                "text-center d-flex flex-column align-center",
                            },
                            [
                              _c("Avatar", {
                                staticClass: "original-size-avatar",
                                attrs: {
                                  image: _vm.image,
                                  "face-color": _vm.face.color,
                                  background: _vm.background.color,
                                  arm: _vm.arm.color,
                                  shirt: _vm.shirt.color,
                                  "logo-size": _vm.logoSize,
                                  "logo-posy": _vm.posy,
                                  "logo-posx": _vm.posx,
                                  "circle-border-size": "3px",
                                  "prevent-drag": "",
                                },
                              }),
                              _c("p", { staticClass: "mt-1 mb-0" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "editor.widget.appearance.avatar.originalView"
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "white", attrs: { cols: "12", sm: "7" } },
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [
                      _c("v-card-title", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("editor.widget.appearance.avatar.colors")
                            ) +
                            " "
                        ),
                      ]),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticClass: "pt-0", attrs: { cols: "12" } },
                                [
                                  _c("HubColorInput", {
                                    attrs: {
                                      label: _vm.$t(
                                        "editor.widget.appearance.avatar.backgroundColor"
                                      ),
                                    },
                                    model: {
                                      value: _vm.background,
                                      callback: function ($$v) {
                                        _vm.background = $$v
                                      },
                                      expression: "background",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("HubColorInput", {
                                    attrs: {
                                      label: _vm.$t(
                                        "editor.widget.appearance.avatar.shirt"
                                      ),
                                    },
                                    model: {
                                      value: _vm.shirt,
                                      callback: function ($$v) {
                                        _vm.shirt = $$v
                                      },
                                      expression: "shirt",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("HubColorInput", {
                                    attrs: {
                                      label: _vm.$t(
                                        "editor.widget.appearance.avatar.sleeves"
                                      ),
                                    },
                                    model: {
                                      value: _vm.arm,
                                      callback: function ($$v) {
                                        _vm.arm = $$v
                                      },
                                      expression: "arm",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-card-title", { staticClass: "pt-0 pb-0" }, [
                        _vm._v("Logo"),
                      ]),
                      _c("v-card-text", { staticClass: "pb-0" }, [
                        _c("p", { staticClass: "mb-0" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "editor.widget.appearance.avatar.infoLogo"
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c(
                        "v-card-text",
                        { staticClass: "pt-0 pb-0" },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-4 pb-0",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _c("v-file-input", {
                                    attrs: {
                                      accept:
                                        "image/png, image/jpeg, image/bmp, image/gif",
                                      "prepend-icon": "",
                                      "truncate-length": "15",
                                      outlined: "",
                                      label: _vm.$t(
                                        "editor.widget.appearance.avatar.uploadLogo"
                                      ),
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        _vm.image
                                          ? {
                                              key: "append-outer",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "avatar mt-n3",
                                                      staticStyle: {
                                                        "min-width": "48px",
                                                        width: "48px",
                                                        "min-height": "48px",
                                                        height: "48px",
                                                      },
                                                    },
                                                    [
                                                      _c("img", {
                                                        attrs: {
                                                          width: "48px",
                                                          src: _vm.image,
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            }
                                          : null,
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value: _vm.file,
                                      callback: function ($$v) {
                                        _vm.file = $$v
                                      },
                                      expression: "file",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-0 pb-0",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _c("v-slider", {
                                    attrs: {
                                      disabled: !_vm.file,
                                      label: _vm.$t(
                                        "editor.widget.appearance.avatar.size"
                                      ),
                                      max: "50",
                                      min: "5",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "thumb-label",
                                        fn: function (props) {
                                          return [
                                            _vm._v(
                                              " " + _vm._s(props.value) + "px "
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                    model: {
                                      value: _vm.logoSize,
                                      callback: function ($$v) {
                                        _vm.logoSize = $$v
                                      },
                                      expression: "logoSize",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      !_vm.isInPopup
                        ? _c(
                            "v-card-actions",
                            [
                              _c("v-btn", { on: { click: _vm.save } }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "editor.widget.appearance.avatar.download"
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }