var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "py-0" },
        [
          _c(
            "v-row",
            { attrs: { justify: "start" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _vm.possibleValues.length === 0
                    ? _c("v-text-field", {
                        ref: "valueInput",
                        attrs: {
                          "append-outer-icon": "mdi-plus-circle",
                          "full-width": "",
                          "hide-details": "",
                          label: _vm.placeholder,
                          "single-line": "",
                          rules: [_vm.validator],
                        },
                        on: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.addChip.apply(null, arguments)
                          },
                          "click:append-outer": _vm.addChip,
                        },
                        model: {
                          value: _vm.valueInput,
                          callback: function ($$v) {
                            _vm.valueInput = $$v
                          },
                          expression: "valueInput",
                        },
                      })
                    : _c("v-select", {
                        ref: "valueInput",
                        attrs: {
                          "append-outer-icon": "mdi-plus-circle",
                          "full-width": "",
                          "hide-details": "",
                          items: _vm.possibleValuesLeft,
                          label: _vm.placeholder,
                          "single-line": "",
                          rules: [_vm.validator],
                        },
                        on: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.addChip.apply(null, arguments)
                          },
                          "click:append-outer": _vm.addChip,
                        },
                        model: {
                          value: _vm.valueInput,
                          callback: function ($$v) {
                            _vm.valueInput = $$v
                          },
                          expression: "valueInput",
                        },
                      }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-container",
        { staticClass: "py-5" },
        [
          _c(
            "v-row",
            { attrs: { justify: "start" } },
            _vm._l(_vm.values, function (chip, i) {
              return _c(
                "v-col",
                { key: chip, staticClass: "shrink" },
                [
                  _c(
                    "v-chip",
                    {
                      attrs: { close: "" },
                      on: {
                        "click:close": function ($event) {
                          return _vm.removeChip(i)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(chip) + " ")]
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }