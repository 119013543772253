var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-tab-item",
    {
      staticStyle: { height: "100%" },
      attrs: {
        transition: "none",
        color: "black",
        "reverse-transition": "none",
        value: "infos",
      },
    },
    [
      _vm.getChannelID
        ? _c(
            "v-card",
            {
              staticClass: "px-8 pt-4 pb-4",
              attrs: { color: "#f0f0f0", elevation: "0", height: "100%" },
            },
            [
              _c("h2", [
                _vm._v(_vm._s(_vm.$t("channelSettings.config.infos.headline"))),
              ]),
              _c("p", { staticStyle: { "word-break": "break-all" } }, [
                _vm._v(" Channel: " + _vm._s(_vm.getChannelID) + " "),
              ]),
              _c("p", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("channelSettings.config.infos.description")) +
                    " "
                ),
              ]),
              _c(
                "v-card",
                [
                  _c(
                    "v-card-actions",
                    { staticClass: "d-flex" },
                    [
                      _vm._v(" Embedd Code: "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-2",
                          attrs: { outlined: "", small: "" },
                          on: { click: _vm.copyCodeToClipboard },
                        },
                        [
                          _vm._v(" Copy Code "),
                          _c("v-icon", [_vm._v("mdi-code-tags")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "code",
                    {
                      staticClass: "mt-2 pa-4 elevation-0",
                      staticStyle: { "word-break": "break-all" },
                    },
                    [_vm._v(_vm._s(_vm.code(_vm.getBotId, _vm.getChannelID)))]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }