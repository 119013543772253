import { render, staticRenderFns } from "./LivechatNotAnAgentView.vue?vue&type=template&id=4432d8a4"
import script from "./LivechatNotAnAgentView.vue?vue&type=script&lang=js"
export * from "./LivechatNotAnAgentView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/moin-hub/moin-hub/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4432d8a4')) {
      api.createRecord('4432d8a4', component.options)
    } else {
      api.reload('4432d8a4', component.options)
    }
    module.hot.accept("./LivechatNotAnAgentView.vue?vue&type=template&id=4432d8a4", function () {
      api.rerender('4432d8a4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/livechat/special-views/LivechatNotAnAgentView.vue"
export default component.exports