// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/fonts/JosefinSans-VariableFont_wght.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("@/assets/fonts/Lato-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("@/assets/fonts/silka-semibold-webfont.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: \"Josefin Sans\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n@font-face {\n  font-family: \"Lato\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n@font-face {\n  font-family: \"silkaHeading\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n.message-field-wrapper[data-v-0f96525a] {\n  position: relative;\n  margin-right: 0.5rem;\n  width: 100%;\n}\n.chat-input-wrapper[data-v-0f96525a] {\n  border-radius: 20px;\n  background: white;\n  outline: none;\n  border: 1px solid transparent;\n  width: 100%;\n  overflow: hidden;\n}\n.chat-input[data-v-0f96525a] {\n  position: relative;\n  width: 100%;\n  resize: none;\n  -webkit-transition: height 0.3s;\n  transition: height 0.3s;\n}\n.input-bottom-info[data-v-0f96525a] {\n  background-color: #f4f4f4;\n  padding: 0 0.5rem;\n  font-size: 0.75rem;\n}\n.invisible[data-v-0f96525a] {\n  display: none;\n}\n.chat-input[data-v-0f96525a]:focus {\n  border: 1px solid var(--v-primary-lighten3);\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
