var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pt-6 pb-2" }, [
    _c("div", { staticClass: "text-body-2 primary--text text--lighten-3" }, [
      _vm._v(" " + _vm._s(_vm.$t("demo.stepCompany.labelCompanyUrl")) + " "),
    ]),
    _c(
      "div",
      {
        staticClass: "text-body-1",
        staticStyle: { "word-break": "break-all" },
      },
      [_vm._v(" " + _vm._s(_vm.companyUrl) + " ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }