var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "VueDraggable",
        {
          attrs: { handle: ".drag-item" },
          on: { end: _vm.updateLinks },
          model: {
            value: _vm.links,
            callback: function ($$v) {
              _vm.links = $$v
            },
            expression: "links",
          },
        },
        _vm._l(_vm.links, function (link, index) {
          return _c(
            "v-card",
            { key: link.id, staticClass: "mb-2", attrs: { outlined: "" } },
            [
              _vm.loading
                ? _c("v-progress-linear", {
                    attrs: {
                      indeterminate: "",
                      color: "secondary darken-2",
                      absolute: "",
                    },
                  })
                : _vm._e(),
              _c(
                "div",
                { staticClass: "d-flex align-center px-2" },
                [
                  _c(
                    "div",
                    { staticClass: "pt-1 drag-item" },
                    [_c("v-icon", [_vm._v("mdi-drag-vertical")])],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex-grow-1 text-body-1 pt-1 grey--text text-darken-2 primary--text text--lighten-2",
                    },
                    [_vm._v(" " + _vm._s(`Link ${index + 1}:`) + " ")]
                  ),
                  _c("BtnSquare", {
                    staticClass: "mt-2",
                    attrs: { icon: "mdi-delete" },
                    on: {
                      click: function ($event) {
                        return _vm.deleteLink(index)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "py-2 px-4" },
                [
                  _c("v-text-field", {
                    staticClass: "mb-2",
                    attrs: {
                      outlined: "",
                      dense: "",
                      "hide-details": "",
                      type: "text",
                      label: `Text`,
                    },
                    on: { keyup: _vm.updateLinks },
                    model: {
                      value: link.title,
                      callback: function ($$v) {
                        _vm.$set(link, "title", $$v)
                      },
                      expression: "link.title",
                    },
                  }),
                  _c("v-text-field", {
                    staticClass: "mb-2",
                    attrs: {
                      outlined: "",
                      dense: "",
                      "hide-details": "",
                      type: "text",
                      label: `URL`,
                    },
                    on: { keyup: _vm.updateLinks },
                    model: {
                      value: link.href,
                      callback: function ($$v) {
                        _vm.$set(link, "href", $$v)
                      },
                      expression: "link.href",
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "d-flex" }),
            ],
            1
          )
        }),
        1
      ),
      _c(
        "v-btn",
        { attrs: { outlined: "" }, on: { click: _vm.addLink } },
        [
          _vm._v(
            " " + _vm._s(_vm.$t("editor.widget.startscreen.addLink")) + " "
          ),
          _c("v-icon", { attrs: { outlined: "", right: "" } }, [
            _vm._v("mdi-plus"),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }