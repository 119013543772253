var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("Zendesk authorisieren via OAuth")]),
          _c("v-card-subtitle", [
            _vm._v(
              " Zendesk authorisieren damit via Botformulare die Daten in ein Zendesk Ticket übertragen werden können "
            ),
          ]),
          _c(
            "v-form",
            { ref: "form" },
            [
              _c(
                "v-col",
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "OAuth-URL",
                      placeholder: "OAuth-URL",
                      rules: _vm.rules.oauthUrl,
                    },
                    model: {
                      value: _vm.oauthUrl,
                      callback: function ($$v) {
                        _vm.oauthUrl = $$v
                      },
                      expression: "oauthUrl",
                    },
                  }),
                  _c("p", { staticClass: "primary--text" }, [
                    _vm._v("Format: https://company.zendesk.com"),
                    _c("br"),
                  ]),
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c("v-text-field", {
                    attrs: { label: "Scopes", placeholder: "scopes" },
                    model: {
                      value: _vm.scopes,
                      callback: function ($$v) {
                        _vm.scopes = $$v
                      },
                      expression: "scopes",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm.isConfigured
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary lighten-2",
                        outlined: "",
                        small: "",
                        disabled: !_vm.isChanged,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.resetOriginalConfig()
                        },
                      },
                    },
                    [
                      _vm._v(" Reset "),
                      _c("v-icon", { attrs: { right: "", dark: "" } }, [
                        _vm._v("refresh"),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "success",
                    small: "",
                    disabled: !_vm.isValid,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.oauth()
                    },
                  },
                },
                [
                  _vm._v(" Auth "),
                  _c("v-icon", { attrs: { right: "", dark: "" } }, [
                    _vm._v("mdi-robot-love-outline"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: 6000 },
          model: {
            value: _vm.savingToast,
            callback: function ($$v) {
              _vm.savingToast = $$v
            },
            expression: "savingToast",
          },
        },
        [
          _c(
            "div",
            { staticClass: "d-flex align-center" },
            [
              _c(
                "v-icon",
                {
                  staticClass: "mr-2",
                  attrs: { color: "green", type: "success" },
                },
                [_vm._v("check")]
              ),
              _c("div", [_vm._v("Oauth success")]),
            ],
            1
          ),
        ]
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: 6000 },
          model: {
            value: _vm.savingToastError,
            callback: function ($$v) {
              _vm.savingToastError = $$v
            },
            expression: "savingToastError",
          },
        },
        [
          _c(
            "div",
            { staticClass: "d-flex align-center" },
            [
              _c("v-icon", { staticClass: "mr-2", attrs: { color: "red" } }, [
                _vm._v("mdi-alert-circle"),
              ]),
              _c("div", [
                _vm._v(_vm._s(_vm.message || _vm.$t("common.notSavedError"))),
              ]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }