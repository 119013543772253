var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return (_vm.activeChat && _vm.activeChat.status === "open") ||
    _vm.inSupervisorMode
    ? _c("div", { staticClass: "full-height d-flex flex-column flex-grow-1" }, [
        _c("div", { staticClass: "flex-grow-1 relative" }, [
          _c(
            "div",
            {
              staticStyle: {
                overflow: "hidden",
                "overflow-y": "auto",
                position: "absolute",
                top: "0",
                left: "0",
                right: "0",
                bottom: "0",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "mx-auto pa-4",
                  staticStyle: { "max-width": "800px" },
                },
                [
                  _c("LivechatHistory"),
                  _vm._l(_vm.messages, function (item) {
                    return _c("LivechatChatItem", {
                      key: item.id,
                      attrs: {
                        item: item,
                        isTranslated:
                          _vm.useTranslation &&
                          typeof item.translated !== "undefined",
                        chat: _vm.activeChat,
                      },
                    })
                  }),
                  _vm.isOffline
                    ? _c("div", { staticClass: "relative text-center" }, [
                        _c("div", {
                          staticClass: "error",
                          staticStyle: {
                            position: "absolute",
                            top: "14px",
                            left: "0",
                            right: "0",
                            height: "1px",
                          },
                        }),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-inline-block mx-auto relative white px-3 text-body-1 error--text",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("livechat.chats.connectionLostAt")
                                ) +
                                " " +
                                _vm._s(_vm.getTimeFormatted()) +
                                " h "
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _c("div", { attrs: { id: "end-of-chat" } }),
                ],
                2
              ),
            ]
          ),
        ]),
        _c("div", { staticStyle: { background: "#d1d4d9" } }, [
          _vm.isOffline
            ? _c(
                "div",
                { staticClass: "error white--text text-center py-1 px-2" },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("livechat.chats.userLostAt")) +
                      " " +
                      _vm._s(_vm.lastUpdated) +
                      " "
                  ),
                ]
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "pa-3 pl-0" },
            [
              _vm.agentStatus === _vm.statusTypes.online
                ? _c("LivechatChatInput", { staticClass: "flex-grow-1" })
                : _vm._e(),
              _c(
                "div",
                { staticClass: "d-flex justify-space-between pt-3" },
                [
                  _c("div"),
                  _vm.activeChat &&
                  ![_vm.chatStatus.closed, _vm.chatStatus.unassigned].includes(
                    _vm.activeChat.status
                  )
                    ? _c("LivechatCloseChat", {
                        attrs: { chat: _vm.activeChat },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }