<template>
  <div class="livechat-lists">
    <div class="livechat-lists-content">
      <div style="overflow: hidden; overflow-y: auto; max-width: 100%">
        <!-- Unassigned Chats -->
        <div>
          <h1 class="pt-5 pb-2 text-body-2 text-uppercase text-center secondary--text">
            {{ $t('livechat.chats.unassignedChats') }}
            <span style="opacity: 0.5">({{ chatsUnassigned.length }})</span>
          </h1>
          <v-divider />
          <LivechatList :items="chatsUnassigned" />
        </div>

        <!-- Agent Chats -->
        <div v-if="!inSupervisorMode">
          <h1 class="pt-5 pb-2 text-body-2 text-uppercase text-center primary--text text--lighten-2">
            {{ $t('livechat.chats.myChats') }}
            <span style="opacity: 0.5">({{ chatsOpen.length }})</span>
          </h1>
          <v-divider />
          <LivechatList :items="chatsOpen" />
        </div>
        
        <!-- Other Agents Chats -->
        <div v-if="!inSupervisorMode">
          <h1 class="pt-5 pb-2 text-body-2 text-uppercase text-center primary--text text--lighten-2">
            {{ $t('livechat.chats.assignedToOtherAgentsChats') }}
            <span style="opacity: 0.5">({{ chatsOtherAgents.length }})</span>
          </h1>
          <v-divider />
          <LivechatList :items="chatsOtherAgents" />
        </div>
        <!-- Show all open chats | For Supervisor -->
        <div v-else>
          <h1 class="pt-5 pb-2 text-body-2 text-uppercase text-center primary--text text--lighten-2">
            {{ $t('livechat.chats.openedChats') }}
            <span style="opacity: 0.5">({{ chatsOtherAgents.length }})</span>
          </h1>
          <v-divider />
          <LivechatList :items="chatsOtherAgents" />
        </div>

      </div>
    </div>

    <!-- Closed Chats -->
    <div class="livechat-lists-closed" :class="[isClosedOpen && 'livechat-lists-closed--open']">
      <v-divider v-if="!isClosedOpen" />
      <div class="livechat-lists-closed-header">
        <h1 class="text-body-2 flex-grow-1 text-uppercase text-center primary--text text--lighten-5">
          {{ inSupervisorMode ? $t('livechat.chats.closedChats') : $t('livechat.chats.closedChatsToday') }}
          <span style="opacity: 0.5">({{ chatsClosed.length }})</span>
        </h1>
        <BtnSquare
          :icon="isClosedOpen ? 'mdi-chevron-up' : 'mdi-chevron-down'"
          @click="isClosedOpen = !isClosedOpen"
        />
      </div>
      <v-divider />
      <div
        class="livechat-lists-closed-scroller moin-fade-in"
        v-if="isClosedOpen"
      >
        <LivechatList :items="chatsClosed" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import LivechatList from '@/components/livechat/LivechatList.vue';
import BtnSquare from '@/components/common/BtnSquare.vue';

export default {
  name: 'LivechatLists',
  components: {
    LivechatList,
    BtnSquare,
  },
  data() {
    return {
      isLoading: false,
      isClosedOpen: false,
    };
  },
  computed: {
    ...mapGetters('livechat', [
      'chatsUnassigned',
      'chatsOpen',
      'chatsClosed',
      'chatsOtherAgents',
      'inSupervisorMode',
    ]),
  },
};
</script>
<style lang="scss" scoped>
$nav-profile-drawer-height: 69px;

.livechat-lists {
  height: 100%;
  overflow: hidden;
  position: relative;
}

.livechat-lists-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: $nav-profile-drawer-height;
  overflow: hidden;
  overflow-y: auto;
}

.livechat-lists-closed {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 1px;
  max-height: 100%;
  height: $nav-profile-drawer-height;
  background: white;
}

.livechat-lists-closed-scroller {
  overflow: hidden;
  overflow-y: auto;
  height: calc(100% - $nav-profile-drawer-height);
}

.livechat-lists-closed--open {
  height: 100%;
  top: 0;
}

.livechat-lists-closed-header {
  height: $nav-profile-drawer-height;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 $spacer * 3;
}
</style>
