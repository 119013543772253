var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "HubBox",
        {
          attrs: {
            title: _vm.$t("botSettings.2factorAuth.title"),
            titleSub: _vm.activatedInitial
              ? _vm.$t("botSettings.2factorAuth.isActive")
              : _vm.$t("botSettings.2factorAuth.isInactive"),
            icon: "mdi-lock-check-outline",
            expandedInitial: "",
          },
        },
        [
          _c("div", { staticClass: "text-body-2" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("botSettings.2factorAuth.description")) + " "
            ),
          ]),
          _vm.activatedInitial
            ? _c(
                "div",
                { staticClass: "py-4 d-flex align-center" },
                [
                  _c(
                    "v-icon",
                    { staticClass: "mr-2", attrs: { color: "green" } },
                    [_vm._v("mdi-checkbox-marked-circle")]
                  ),
                  _c("div", { staticClass: "green--text" }, [
                    _vm._v(
                      _vm._s(_vm.$t("botSettings.2factorAuth.isActiveInfo"))
                    ),
                  ]),
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "py-4 d-flex align-center" },
                [
                  _c(
                    "v-icon",
                    { staticClass: "mr-2", attrs: { color: "grey" } },
                    [_vm._v("mdi-cancel")]
                  ),
                  _c("div", { staticClass: "grey--text" }, [
                    _vm._v(
                      _vm._s(_vm.$t("botSettings.2factorAuth.isInactiveInfo"))
                    ),
                  ]),
                ],
                1
              ),
          _c("InfoBox", {
            attrs: { text: _vm.$t("botSettings.2factorAuth.infoBox") },
          }),
          _c(
            "v-btn",
            {
              staticClass: "mt-6",
              attrs: { outlined: "", color: "primary lighten-2" },
              on: {
                click: function ($event) {
                  _vm.dialogIsOpen = true
                },
              },
            },
            [
              _vm._v(" " + _vm._s(_vm.$t("common.edit")) + " "),
              _c("v-icon", { attrs: { right: "" } }, [_vm._v("edit")]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "HubDialog",
        {
          attrs: {
            value: _vm.dialogIsOpen,
            title: _vm.$t("botSettings.2factorAuth.title"),
            modal: "",
            asyncDialog: "",
            primaryIcon: "cloud_upload",
            primaryLabel: _vm.$t("editor.common.deploy"),
            isLoading: _vm.isLoading,
            disabled: !_vm.isChanged,
          },
          on: {
            onSecondary: function ($event) {
              _vm.dialogIsOpen = false
            },
            onPrimary: _vm.save2Auth,
          },
        },
        [
          _c("div", { staticClass: "text-body-1" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("botSettings.2factorAuth.dialogDescription")) +
                " "
            ),
          ]),
          _c(
            "div",
            { staticClass: "my-4" },
            [
              _c("v-switch", {
                attrs: {
                  color: _vm.switchActivated ? "green" : "grey",
                  inset: "",
                  label: _vm.$t("botSettings.2factorAuth.title"),
                },
                model: {
                  value: _vm.switchActivated,
                  callback: function ($$v) {
                    _vm.switchActivated = $$v
                  },
                  expression: "switchActivated",
                },
              }),
            ],
            1
          ),
          _c("InfoBox", {
            attrs: { text: _vm.$t("botSettings.2factorAuth.changeHint") },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }