var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("HubBoxSimple", {
    attrs: { title: _vm.$t("quotaInfo.title") },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            !_vm.quota
              ? _c("div", [_vm._v("Noch keine Quota gesetzt")])
              : _vm._e(),
            _c("div", [
              _c("div", { staticClass: "d-flex justify-space-between" }, [
                _c(
                  "div",
                  { staticClass: "mb-2" },
                  [
                    _c(
                      "v-icon",
                      {
                        staticClass: "mr-1",
                        attrs: { small: "", color: "primary lighten-3" },
                      },
                      [_vm._v("mdi-folder-text")]
                    ),
                    _c(
                      "span",
                      { staticClass: "text-body-2 grey--text text--darken-2" },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("quotaInfo.intentsLabel")) + ": "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c("div", [
                  _vm._v(
                    " " + _vm._s(_vm.quota.intents - _vm.intentsUsed) + " "
                  ),
                  _c("span", { staticClass: "grey--text text--darken-2" }, [
                    _vm._v("/" + _vm._s(_vm.quota.intents)),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "d-flex justify-space-between" }, [
                _c(
                  "div",
                  { staticClass: "mb-2" },
                  [
                    _c(
                      "v-icon",
                      {
                        staticClass: "mr-1",
                        attrs: { small: "", color: "primary lighten-3" },
                      },
                      [_vm._v(" mdi-format-list-bulleted-type ")]
                    ),
                    _c(
                      "span",
                      { staticClass: "text-body-2 grey--text text--darken-2" },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("quotaInfo.channelsLabel")) + ": "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c("div", [
                  _vm._v(
                    " " + _vm._s(_vm.quota.channels - _vm.channelsUsed) + " "
                  ),
                  _c("span", { staticClass: "grey--text text--darken-2" }, [
                    _vm._v("/" + _vm._s(_vm.quota.channels)),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "d-flex justify-space-between" }, [
                _c(
                  "div",
                  { staticClass: "mb-2" },
                  [
                    _c(
                      "v-icon",
                      {
                        staticClass: "mr-1",
                        attrs: { small: "", color: "primary lighten-3" },
                      },
                      [_vm._v("list_alt")]
                    ),
                    _c(
                      "span",
                      { staticClass: "text-body-2 grey--text text--darken-2" },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("quotaInfo.formsLabel")) + ": "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c("div", [
                  _vm._v(" " + _vm._s(_vm.quota.forms - _vm.formsUsed) + " "),
                  _c("span", { staticClass: "grey--text text--darken-2" }, [
                    _vm._v("/" + _vm._s(_vm.quota.forms)),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "d-flex justify-space-between" }, [
                _c(
                  "div",
                  { staticClass: "mb-2" },
                  [
                    _c(
                      "v-icon",
                      {
                        staticClass: "mr-1",
                        attrs: { small: "", color: "primary lighten-3" },
                      },
                      [_vm._v("mdi-web")]
                    ),
                    _c(
                      "span",
                      { staticClass: "text-body-2 grey--text text--darken-2" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("quotaInfo.languagesLabel")) +
                            ": "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c("div", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.quota.languages.length - _vm.languagesUsed.length
                      ) +
                      " "
                  ),
                  _c("span", { staticClass: "grey--text text--darken-2" }, [
                    _vm._v(" /" + _vm._s(_vm.quota.languages.length) + " "),
                  ]),
                ]),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }