<template>
  <div>
    <LivechatListItem
      v-for="(item, index) in items"
      :key="index"
      :item="item"
      @onSelect="selectItem(item)"
    />
  </div>
</template>
<script>
import LivechatListItem from '@/components/livechat/LivechatListItem.vue';
export default {
  name: 'LivechatList',
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  components: {
    LivechatListItem,
  },
  data() {
    return {
      selectedItemId: null,
    };
  },
  methods: {
    selectItem(item) {
      this.$store.dispatch('livechat/changeToChat', {
        chatUuid: item.uuid,
        uniqueUserId: item.uniqueUserId,
      });
    },
  },
};
</script>
