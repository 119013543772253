var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "HubBox",
    {
      attrs: {
        icon: "list_alt",
        title: "K2 Namen",
        boxId: "'intentK2Names'",
        adminOnly: "",
      },
    },
    [
      _c(
        "div",
        { staticClass: "text-body-2 mb-6 primary--text text--lighten-2" },
        [_vm._v(" K2 Namen hinzufügen, bearbeiten und löschen. ")]
      ),
      _vm.k2Names.length
        ? _c(
            "div",
            { staticClass: "primary--text text--lighten-2" },
            _vm._l(_vm.k2Names, function (item, index) {
              return _c("div", { key: index }, [_vm._v("- " + _vm._s(item))])
            }),
            0
          )
        : _c(
            "div",
            { staticClass: "text-body-2 primary--text text--lighten-3" },
            [_vm._v(" Noch keine K2 Namen vorhanden. ")]
          ),
      _c(
        "div",
        { staticClass: "pt-5" },
        [
          _c(
            "v-btn",
            {
              attrs: { outlined: "", color: "primary lighten-3" },
              on: {
                click: function ($event) {
                  return _vm.startEditing()
                },
              },
            },
            [
              _vm._v(" " + _vm._s(_vm.$t("common.edit")) + " "),
              _c("v-icon", { attrs: { right: "" } }, [_vm._v("edit")]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "HubDialog",
        {
          attrs: {
            value: _vm.editDialogOpen,
            "primary-label": _vm.$t("common.save"),
            "primary-icon": "save",
            "secondary-label": _vm.$t("common.cancel"),
            "secondary-icon": "close",
            title: "K2 Namen bearbeiten",
            disabled: !_vm.isChanged || !_vm.isValid,
            modal: "",
            asyncDialog: "",
          },
          on: {
            onPrimary: function ($event) {
              return _vm.saveK2Names()
            },
            onSecondary: function ($event) {
              return _vm.cancelEdit()
            },
          },
        },
        [
          _c(
            "div",
            _vm._l(_vm.k2NamesDraft, function (item, index) {
              return _c("div", { key: `item-${index}` }, [
                _c(
                  "div",
                  { staticClass: "d-flex" },
                  [
                    _c("v-text-field", {
                      key: `item-${index}`,
                      staticClass: "full-width",
                      attrs: { outlined: "", dense: "" },
                      model: {
                        value: _vm.k2NamesDraft[index],
                        callback: function ($$v) {
                          _vm.$set(_vm.k2NamesDraft, index, $$v)
                        },
                        expression: "k2NamesDraft[index]",
                      },
                    }),
                    _c(
                      "div",
                      { staticClass: "ml-1" },
                      [
                        _c("BtnSquare", {
                          attrs: { icon: "mdi-trash-can" },
                          on: {
                            click: function ($event) {
                              return _vm.removeName(index)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            }),
            0
          ),
          _c(
            "div",
            [
              _c(
                "v-btn",
                {
                  attrs: { outlined: "", color: "primary lighten-3" },
                  on: {
                    click: function ($event) {
                      return _vm.addName()
                    },
                  },
                },
                [
                  _vm._v(" Neu "),
                  _c("v-icon", { attrs: { right: "" } }, [_vm._v("mdi-plus")]),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }