import moment from 'moment';

export const statusTypes = {
  online: 'online',
  offline: 'offline',
  otherTab: 'otherTab',
};

export const incomeTypes = {
  newChat: 'newChat',
  newMessage: 'newMessage',
  activeTab: 'activeTab',
  none: null,
};

export const agentForOrganizationStatusTypes = {
  isAgentForCurrentOrganization: 'isAgentForCurrentOrganization',
  isAgentForOtherOrganization: 'isAgentForOtherOrganization',
  isNotAgent: 'isNotAgent',
};

export const chatStatus = {
  unassigned: 'unassigned',
  open: 'open',
  closed: 'closed',
  offline: 'offline',
};

export const infoTagTypes = {
  dialogs: 'dialogs',
  website: 'website',
  channel: 'channel',
  language: 'language',
  link: 'link',
  attachment: 'attachment',
  note: 'note',
  form: 'form',
};

export const infoCardTypes = {
  text: 'text',
  email: 'email',
  attachment: 'attachment',
  image: 'image',
  phone: 'phone',
  link: 'link',
  number: 'number',
  date: 'date',
  licenseplate: 'licensePlate',
  iban: 'iban',
  regexp: 'regexp',
};

export const extractMsgText = (m) => {
  if (m.message?.message?.text) {
    return m.message.message.text;
  } else if (m.text) {
    return m.text;
  } else {
    return m.data;
  }
}

/**
 * 
 * @param {object} msg - a history message object
 * @param {object} activeChat 
 * @returns {object} - a message object 
 */
export const toProperMsgFormat = (msg, activeChat) => {
  let sender = {};

  if (msg.user) {
    sender = {
      ...activeChat.user,
      role: 'user',
    };
  } else if (msg.livechat) {
    sender = {
      role: 'agent',
    };
  } else {
    sender = {
      role: 'bot',
    };
  }

  const message = {
    data: '',
    sender,
    type: msg.type,
    created_at: msg.date  || msg.created_at,
    livechat: msg.livechat || false,
    uuid: msg.uuid,
  };

  if (msg.message?.message?.text) {
    message.data = msg.message?.message?.text;
    message.isHistory = true
  } else if (msg.text) {
    message.data = msg.text;
    message.isHistory = true
  } else {
    message.data = msg.data;
    message.isHistory = false;
  }

  return message;
}

export const infoCardIcon = type => {
  switch (type) {
    case infoCardTypes.text:
      return 'mdi-text';
    case infoCardTypes.email:
      return 'mdi-email';
    case infoCardTypes.image:
      return 'mdi-image';
    case infoTagTypes.attachment:
    case infoCardTypes.attachment:
      return 'mdi-paperclip';
    case infoTagTypes.website:
      return 'mdi-application';
    case infoTagTypes.channel:
      return 'mdi-format-list-bulleted-type';
    case infoTagTypes.language:
      return 'mdi-web';
    case infoTagTypes.note:
      return 'mdi-note-text-outline';
    case infoTagTypes.form:
      return 'mdi-message-flash';
    case infoTagTypes.dialogs:
      return 'message';
    case infoTagTypes.link:
      return 'mdi-link';
    case infoCardTypes.phone:
      return 'mdi-phone';
    case infoCardTypes.number:
      return 'mdi-numeric';
    case infoCardTypes.date:
      return 'mdi-calendar';
    case infoCardTypes.licenseplate:
      return 'mdi-car';
    case infoCardTypes.iban:
      return 'mdi-bank';
    case infoCardTypes.regexp:
      return 'mdi-regex';
    default:
      return 'mdi-help-circle-outline';
  }
}

export const sortUpdated = (a, b) => {
  const aDate = moment(a.updated_at);
  const bDate = moment(b.updated_at);
  return moment(aDate).diff(bDate);
};

export const sortOffline = (a, b) => {
  if (b.status === 'offline') {
    return -1;
  }
};

export function getTimeString(lastDate, now = new Date(), extended) {
  const diffMinutes = moment(now).diff(moment(lastDate), 'minutes');
  const currentYear = moment(now).year() === moment(lastDate).year()

  if (diffMinutes < 1) return 'gerade eben';
  if (diffMinutes >= 1 && diffMinutes <= 60) return `vor ${diffMinutes} Min.`;

  if (diffMinutes > 60) {
    const diffHours = moment(now).diff(moment(lastDate), 'hours');
    if (diffHours <= 24) return `vor mehr als ${diffHours}h`;

    if (diffHours > 24 && !extended) return `vor mehr als 24h`;

    if (currentYear) return moment(lastDate).format('YYYY-MM-DD HH:mm');
    return moment(lastDate).format('YYYY-MM-DD');
  }
}

export function checkTabIsOk({ tabsLocal, tabIdSession }) {
  const activeTab = tabsLocal?.find(tab => tab.isActive);
  //returns true if there is no active tab or the current tab is the active one
  return !activeTab || activeTab.id === tabIdSession;
}
