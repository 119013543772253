var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.item
    ? _c(
        "div",
        [
          _c("v-list-item-title", [
            _vm._v(
              _vm._s(
                _vm.i18nDateFormat(
                  _vm.item.created,
                  _vm.$vuetify.lang.current,
                  _vm.$t("common.timeFormatShort")
                )
              ) + " "
            ),
            !_vm.selected
              ? _c(
                  "span",
                  { staticClass: "pl-2" },
                  [
                    _vm._l(_vm.item.all, function (t) {
                      return [
                        _c("AnalysisItem", {
                          key: t._id,
                          attrs: { item: t, type: "label" },
                        }),
                      ]
                    }),
                  ],
                  2
                )
              : _vm._e(),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }