var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c("HubMenu"),
      _c("Appbar"),
      _c(
        "v-main",
        [
          _vm.isAdmin
            ? _c(
                "v-dialog",
                {
                  attrs: { "max-width": "800px" },
                  model: {
                    value: _vm.addVariantDialog,
                    callback: function ($$v) {
                      _vm.addVariantDialog = $$v
                    },
                    expression: "addVariantDialog",
                  },
                },
                [
                  _vm.addVariantDialog
                    ? _c("VariantDialog", {
                        attrs: { text: _vm.selectedTexts },
                        on: {
                          onClose: function ($event) {
                            _vm.addVariantDialog = false
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-dialog",
            {
              attrs: {
                fullscreen: _vm.$vuetify.breakpoint.mobile,
                "max-width": "500px",
                persistent: "",
              },
              model: {
                value: _vm.dreamingDialog,
                callback: function ($$v) {
                  _vm.dreamingDialog = $$v
                },
                expression: "dreamingDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _vm.dreaming
                    ? _c("v-progress-linear", {
                        attrs: {
                          indeterminate: "",
                          color: "secondary darken-2",
                        },
                      })
                    : _vm._e(),
                  _c(
                    "v-card-title",
                    {
                      staticClass: "text-h5 pt-6",
                      staticStyle: { "word-break": "break-word" },
                    },
                    [_vm._v(" Dreaming ")]
                  ),
                  _vm.result.length > 500
                    ? _c("v-card-text", [
                        _vm._v(
                          " Es können aus maximal 400 Anfragen Themen gebildet werden. "
                        ),
                      ])
                    : _vm._e(),
                  _vm.result.length <= 500
                    ? _c(
                        "v-card-text",
                        [
                          _c("v-slider", {
                            staticClass: "align-center",
                            attrs: {
                              disabled: _vm.dreaming,
                              max: Math.min(150, _vm.result.length - 1),
                              min: Math.min(10, _vm.result.length - 1),
                              label: "Anzahl der neuen Themen",
                              "hide-details": "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "append",
                                  fn: function () {
                                    return [
                                      _c("v-text-field", {
                                        staticClass: "mt-0 pt-0",
                                        staticStyle: { width: "60px" },
                                        attrs: {
                                          disabled: _vm.dreaming,
                                          "hide-details": "",
                                          "single-line": "",
                                          type: "number",
                                        },
                                        model: {
                                          value: _vm.dreamingCluster,
                                          callback: function ($$v) {
                                            _vm.dreamingCluster = $$v
                                          },
                                          expression: "dreamingCluster",
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              4119545502
                            ),
                            model: {
                              value: _vm.dreamingCluster,
                              callback: function ($$v) {
                                _vm.dreamingCluster = $$v
                              },
                              expression: "dreamingCluster",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("v-divider", { staticClass: "mt-2" }),
                  _c(
                    "v-card-actions",
                    {
                      staticClass:
                        "flex-column flex-sm-row justify-space-between align-stretch",
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass:
                            "px-4 grey--text d-block d-sm-inline-block my-4 my-sm-0",
                          attrs: { disabled: _vm.dreaming, text: "" },
                          on: {
                            click: function ($event) {
                              _vm.dreamingDialog = false
                            },
                          },
                        },
                        [
                          _vm._v(" " + _vm._s(_vm.$t("common.cancel")) + " "),
                          _c("v-icon", { staticClass: "ml-2 grey--text" }, [
                            _vm._v("cancel"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass:
                            "px-4 white--text ml-0 ml-sm-2 d-block d-sm-inline-block",
                          attrs: {
                            disabled: _vm.result.length > 500 || _vm.dreaming,
                            elevation: "0",
                            color: "primary",
                          },
                          on: { click: _vm.cluster },
                        },
                        [
                          _vm._v(" Start "),
                          _c("v-icon", { staticClass: "ml-2" }, [
                            _vm._v("scatter_plot"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: {
                fullscreen: _vm.$vuetify.breakpoint.mobile,
                "max-width": "480px",
                scrollable: "",
              },
              model: {
                value: _vm.aiFeedback,
                callback: function ($$v) {
                  _vm.aiFeedback = $$v
                },
                expression: "aiFeedback",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    [
                      _vm._v(" Feedback "),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function ($event) {
                              _vm.aiFeedback = false
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("close")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("AiFeedback", {
                    key: _vm.jumpto,
                    attrs: { embedded: "", text: _vm.currentText },
                    on: {
                      close: function ($event) {
                        _vm.aiFeedback = false
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: {
                fullscreen: _vm.$vuetify.breakpoint.mobile,
                "max-width": "480px",
                scrollable: "",
              },
              model: {
                value: _vm.dialog,
                callback: function ($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("conversation.singletitle")) + " "
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            icon: "",
                            link: "",
                            to: "/chats/messaging/" + _vm.uniqueUserId,
                          },
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("open_in_new"),
                          ]),
                        ],
                        1
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function ($event) {
                              _vm.dialog = false
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("close")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("ChatHistory", {
                    key: _vm.jumpto,
                    attrs: {
                      uniqueUserId: _vm.uniqueUserId,
                      jumpto: _vm.jumpto,
                      embedded: "",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card",
            { attrs: { flat: "" } },
            [
              _c("v-card-title", { staticClass: "text-h5" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("conversation.insgights.title")) + " "
                ),
              ]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "2" } },
                        [
                          _c(
                            "v-menu",
                            {
                              ref: "menu",
                              attrs: {
                                "close-on-content-click": false,
                                transition: "scale-transition",
                                "offset-y": "",
                                "max-width": "290px",
                                "min-width": "290px",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on }) {
                                    return [
                                      _c("v-chip", _vm._g({}, on), [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.formatDate(_vm.from)) +
                                            " - " +
                                            _vm._s(_vm.formatDate(_vm.to)) +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ]),
                              model: {
                                value: _vm.menu,
                                callback: function ($$v) {
                                  _vm.menu = $$v
                                },
                                expression: "menu",
                              },
                            },
                            [
                              _c(
                                "v-date-picker",
                                {
                                  attrs: {
                                    value: _vm.selectedDateRange,
                                    "no-title": "",
                                    range: "",
                                    color: "secondary darken-2",
                                  },
                                  on: { input: _vm.changeDates },
                                },
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "", color: "primary" },
                                      on: {
                                        click: function ($event) {
                                          _vm.menu = false
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("common.cancel")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "", color: "primary" },
                                      on: { click: _vm.newDateRange },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("common.ok")) + " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.isAdmin && _vm.selectedRows.length >= 1
                        ? _c(
                            "v-col",
                            { attrs: { "align-self": "center" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-2",
                                  attrs: {
                                    small: "",
                                    color: "admin",
                                    outlined: "",
                                  },
                                  on: { click: _vm.toggleMultipleTranslations },
                                },
                                [
                                  _c("v-icon", { attrs: { left: "" } }, [
                                    _vm._v("translate"),
                                  ]),
                                  _vm._v(" Auswahl übersetzen "),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "7" } },
                        [
                          _c(
                            "v-chip",
                            {
                              attrs: { disabled: _vm.unknowns },
                              on: {
                                click: function ($event) {
                                  return _vm.toggleFilter()
                                },
                              },
                            },
                            [
                              _c(
                                "v-avatar",
                                { attrs: { left: "" } },
                                [
                                  _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v(_vm._s(_vm.feedbackFilter)),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" Feedback "),
                            ],
                            1
                          ),
                          _vm.selectedIntent
                            ? _c(
                                "v-chip",
                                {
                                  staticClass: "ml-2",
                                  attrs: { disabled: _vm.unknowns, close: "" },
                                  on: {
                                    "click:close": function ($event) {
                                      return _vm.deleteIntent()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-avatar",
                                    { attrs: { left: "" } },
                                    [
                                      _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v("mdi-folder-text"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm._v(
                                    " " + _vm._s(_vm.selectedIntent) + " "
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.isAdmin
                            ? _c(
                                "v-chip",
                                {
                                  staticClass: "ml-2",
                                  attrs: { color: "admin lighten-1" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toggleUnknowns()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-avatar",
                                    { attrs: { left: "" } },
                                    [
                                      !_vm.unknowns
                                        ? _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [
                                              _vm._v(
                                                " check_box_outline_blank "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.unknowns
                                        ? _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [_vm._v("check_box")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _vm._v(" Nicht Verstandene Anfragen "),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm.currentChannels && _vm.currentChannels.length > 1
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12", md: "3" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  "hide-details": "",
                                  value: _vm.channel,
                                  "menu-props": "auto",
                                  label: _vm.$t("channels.filter"),
                                  "append-icon": "call_split",
                                  placeholder: _vm.$t("channels.select-all"),
                                  items: _vm.currentChannels,
                                  "item-text": "displayName",
                                  "item-value": "channelId",
                                  clearable: !_vm.areChannelsBlocked,
                                  outlined: "",
                                  "item-disabled": "blocked",
                                },
                                on: { change: _vm.changeChannel },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "item",
                                      fn: function ({ item }) {
                                        return [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mr-2 mt-1",
                                              attrs: {
                                                color: "primary lighten-2",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.channelIcon(item)
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _vm._v(
                                            " " + _vm._s(item.displayName) + " "
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  872835356
                                ),
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-col",
                        [
                          _vm.unknowns
                            ? _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "mx-2 px-2 white--text font-weight-bold",
                                  attrs: { color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      _vm.dreamingDialog = true
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "hidden-xs-only pl-2" },
                                    [_vm._v("Dreaming")]
                                  ),
                                  _c("v-icon", { staticClass: "pl-0 mx-2" }, [
                                    _vm._v("scatter_plot"),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-fab-transition",
                [
                  _vm.isAdmin && _vm.selectedTexts.length > 0
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary lighten-2",
                            dark: "",
                            bottom: "",
                            fixed: "",
                            right: "",
                            fab: "",
                          },
                          on: {
                            click: function ($event) {
                              _vm.addVariantDialog = true
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("add_comment")])],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("v-data-table", {
                attrs: {
                  headers: _vm.headers,
                  items: _vm.result,
                  "show-select": _vm.isAdmin,
                  "item-key": "uuid",
                  "items-per-page": _vm.initialItemsPerPage,
                  "footer-props": _vm.footerPropsItemsPerPage,
                  "group-by": _vm.groupBy,
                  "no-data-text": _vm.$t("conversation.filter.nodata"),
                  loading: _vm.dataLoading,
                  "loading-text": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: `item.actions`,
                      fn: function ({ item }) {
                        return [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { icon: "" },
                                  on: {
                                    click: function ($event) {
                                      _vm.uniqueUserId = item.uniqueUserId
                                      _vm.jumpto = item.uuid
                                      _vm.dialog = true
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "text-decoration-none",
                                      attrs: { small: "" },
                                    },
                                    [_vm._v("message")]
                                  ),
                                ],
                                1
                              ),
                              item.text || item.forward
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: { icon: "" },
                                      on: {
                                        click: function ($event) {
                                          _vm.jumpto = item.uuid
                                          _vm.currentText =
                                            item.text || item.messageText
                                          _vm.aiFeedback = true
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "text-decoration-none",
                                          attrs: { small: "" },
                                        },
                                        [_vm._v("school")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: `group.header`,
                      fn: function ({ group, toggle }) {
                        return [
                          _c(
                            "v-card",
                            { attrs: { flat: "" } },
                            [
                              _c(
                                "v-card-title",
                                [
                                  group == 1000
                                    ? _c("span", [_vm._v("Sonstige")])
                                    : _vm._e(),
                                  group !== 1000
                                    ? _c(
                                        "v-avatar",
                                        {
                                          staticClass: "pt-1",
                                          attrs: {
                                            color: "primary",
                                            size: "36",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "white--text text-h5",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(parseInt(group) + 1) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: `item.intent`,
                      fn: function ({ item }) {
                        return [
                          _c(
                            "v-chip",
                            {
                              attrs: { small: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.filterIntent(item.intent)
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.displayName(
                                      item.intent,
                                      _vm.$vuetify.lang.current
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: `item.date`,
                      fn: function ({ item }) {
                        return [
                          _c("span", { staticClass: "text-caption" }, [
                            _vm._v(_vm._s(_vm.formatDate(item.date))),
                          ]),
                        ]
                      },
                    },
                    {
                      key: `item.text`,
                      fn: function ({ item }) {
                        return [
                          _c(
                            "div",
                            { staticClass: "d-inline-flex align-center" },
                            [
                              _vm.isAdmin && (item.text || item.forward)
                                ? _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    class: {
                                                      "secondary--text text--darken-2":
                                                        _vm.translations[
                                                          item._id
                                                        ] &&
                                                        _vm.translations[
                                                          item._id
                                                        ].isOpen,
                                                    },
                                                    attrs: {
                                                      icon: "",
                                                      small: "",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.toggleTranslation(
                                                          item._id,
                                                          item.text ||
                                                            item.messageText
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            attrs: {
                                                              small: "",
                                                            },
                                                          },
                                                          "v-icon",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          " mdi-translate "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [_c("span", [_vm._v("Übersetzen")])]
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                [
                                  item.forward
                                    ? _c(
                                        "v-chip",
                                        {
                                          staticClass: "mr-2",
                                          attrs: {
                                            outlined: "",
                                            label: "",
                                            small: "",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("common.forwarded")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  !_vm.translations[item._id] ||
                                  (_vm.translations[item._id] &&
                                    !_vm.translations[item._id].isOpen)
                                    ? _c("span", [
                                        _vm._v(" " + _vm._s(item.text) + " "),
                                      ])
                                    : _vm._e(),
                                  _vm.translations[item._id] &&
                                  _vm.translations[item._id].isLoading
                                    ? _c("v-progress-circular", {
                                        staticClass: "mx-2",
                                        attrs: {
                                          indeterminate: "",
                                          size: "12",
                                          width: "2",
                                          color: "grey",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.translations[item._id] &&
                                  _vm.translations[item._id].isOpen
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "secondary--text text--darken-2",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.translations[item._id].text
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          item.forward &&
                          (!_vm.translations[item._id] ||
                            (_vm.translations[item._id] &&
                              !_vm.translations[item._id].isOpen))
                            ? _c("span", {
                                staticClass: "text-caption",
                                domProps: {
                                  textContent: _vm._s(item.messageText),
                                },
                              })
                            : _vm._e(),
                          !item.text && !item.forward
                            ? _c(
                                "v-chip",
                                {
                                  attrs: { outlined: "", label: "", small: "" },
                                },
                                [_vm._v(" Button ")]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
                model: {
                  value: _vm.selectedRows,
                  callback: function ($$v) {
                    _vm.selectedRows = $$v
                  },
                  expression: "selectedRows",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }