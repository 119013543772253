<template>
  <div v-if="agent">
    <div class="text-body-2 grey--text text--darken-2 mb-6">
      {{ $t('livechat.profile.description') }}
    </div>

    <!-- 8< Temporäre Info für CSM Admins -->
    <div v-if="false" class="admin--text text-copy-2 mb-4">
      Pro Hub User gibt es aktuell einen Agenten. Geplant ist aber, dass pro Bot
      der Agent ein eigenes Profil hat, um Bild, Name und Titel entsprechend
      anzupassen.
    </div>
    <!-- >8 -->
    
    <div class="d-sm-flex">
      <div style="width: 150px">
        <ImageUpload
          :width="150"
          circleStyle
          :imageSrc="profilePicture"
          @onUpload="uploadImage($event)"
          :disabled="loading"
        />
      </div>
      <div
        class="flex-grow-1 items-center pt-4"
        :class="[$vuetify.breakpoint.smAndUp ? 'ml-6' : '']"
      >
        <v-text-field
          outlined
          dense
          :label="$t('livechat.profile.nameLabel')"
          type="text"
          required
          v-model="userName"
          :disabled="loading"
        ></v-text-field>
        <CountryLanguageSelect useLanguages v-model="selectedLanguages" />
        <!-- <v-text-field
          outlined
          dense
          :label="$t('livechat.profile.titleLabel')"
          type="text"
          v-model="titleInput"
          :disabled="loading"
        ></v-text-field> -->
      </div>
    </div>
    <v-card flat class="mb-4" v-if="agent">
      <div class="pa-4 d-flex justify-space-between">
        <h2 class="text-h6">{{ $t('livechat.settings.title') }}</h2>
      </div>
  
      <v-divider />
      <v-container>
        <v-row>
          <v-col>
            <div class="mt-6 text-body-2">
              {{ $t('livechat.settings.description') }}
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <v-switch
              :input-value="hubSettingsLocal.notificationBrowser"
              :color="true ? 'green' : 'grey'"
              inset
              :label="$t('livechat.settings.switchBrowser')"
              @change="
                changeHubSettingsLocal({
                  key: 'notificationBrowser',
                  value: $event,
                })
              "
            />
            <v-switch
              :input-value="hubSettingsLocal.notificationAudio"
              :color="true ? 'green' : 'grey'"
              inset
              :label="$t('livechat.settings.switchAudio')"
              @change="
                changeHubSettingsLocal({
                  key: 'notificationAudio',
                  value: $event,
                })
              "
            />
          </v-col>
          <v-col cols="12" sm="6" class="pt-sm-8">
            <div class="d-flex items-center mb-4">
              <v-select
                outlined
                dense
                v-model="hubSettingsLocal.soundNewChat"
                :label="$t('settingsLivechat.notifications.soundNewChatLabel')"
                hide-details
                :items="soundItems"
                item-value="value"
                item-text="name"
                :disabled="!hubSettingsLocal.notificationAudio"
                @change="
                  changeHubSettingsLocal({
                    key: 'soundNewChat',
                    value: $event,
                  })
                "
              ></v-select>
              <BtnSquare
                icon="mdi-volume-high"
                class="ml-1"
                @click="playSound(hubSettingsLocal.soundNewChat)"
                :disabled="!hubSettingsLocal.notificationAudio"
              />
            </div>
            <div class="d-flex items-center">
              <v-select
                outlined
                dense
                v-model="hubSettingsLocal.soundNewMessage"
                :label="$t('settingsLivechat.notifications.soundNewMessageLabel')"
                hide-details
                :items="soundItems"
                item-value="value"
                item-text="name"
                :disabled="!hubSettingsLocal.notificationAudio"
                @change="
                  changeHubSettingsLocal({
                    key: 'soundNewMessage',
                    value: $event,
                  })
                "
              ></v-select>
              <BtnSquare
                icon="mdi-volume-high"
                class="ml-1"
                @click="playSound(hubSettingsLocal.soundNewMessage)"
                :disabled="!hubSettingsLocal.notificationAudio"
              />
            </div>
            <div class="pr-10 pt-4">
              <v-slider
                color="primary"
                v-model="volume"
                min="0"
                max="100"
                prepend-icon="mdi-volume-high"
                :disabled="!hubSettingsLocal.notificationAudio"
                @end="onVolumeChanged"
              ></v-slider>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import HubEditorFrame from '@/components/hub/HubEditorFrame.vue';
import ImageUpload from '@/components/common/ImageUpload.vue';
import UploadService from '@/services/upload';
import CountryLanguageSelect from '@/components/common/CountryLanguageSelect.vue';
import BtnSquare from '@/components/common/BtnSquare.vue';
import _ from 'lodash';

export default {
  name: 'LivechatProfile',
  components: {
    HubEditorFrame,
    ImageUpload,
    CountryLanguageSelect,
    BtnSquare,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userName: '',
      titleInput: '',
      profilePicture: '',
      selectedLanguages: [],
      volume: 50,
      hubSettingsLocal: {
        soundNewMessage: '',
        soundNewChat: '',
        notificationAudio: true,
        notificationBrowser: true,
        volume: 0.5,
      },
      soundItems: [
        {
          name: 'Audio 1',
          value: '/sounds/blooom.mp3',
        },
        {
          name: 'Audio 2',
          value: '/sounds/din-don.mp3',
        },
        {
          name: 'Audio 3',
          value: '/sounds/ding-dong.mp3',
        },
        {
          name: 'Audio 4',
          value: '/sounds/dril.mp3',
        },
        {
          name: 'Audio 5',
          value: '/sounds/glass.mp3',
        },
        {
          name: 'Audio 6',
          value: '/sounds/ping-pang-ping-pang.mp3',
        },
        {
          name: 'Audio 7',
          value: '/sounds/ping-pang.mp3',
        },
        {
          name: 'Audio 8',
          value: '/sounds/ping-ping.mp3',
        },
        {
          name: 'Audio 9',
          value: '/sounds/ping.mp3',
        },
      ],
    };
  },
  created() {
    this.init();
  },
  watch: {
    isDirty() {
      this.$emit('onDirty', this.isDirty);
    },
  },
  computed: {
    ...mapGetters('livechat', ['agent', , 'defaultHubSettings']),
    isDirty() {
      return this.isDirtyProfile || this.isSoundDirty;
    },
    isDirtyProfile() {
      if (
        (this.userName && this.userName !== this.agent?.name) ||
        (this.profilePicture && this.profilePicture !== this.agent?.profilePicture) ||
        (!_.isEqual(this.selectedLanguages, this.agent?.languages) && this.agent?.languages)
        //  || (this.titleInput !== this.agent?.title && this.agent?.title)
      ) {
        return true;
      }
      return false;
    },
    isSoundDirty() {
      if (!this.hubSettingsLocal.notificationAudio) {
        return (
          this.hubSettingsLocal.notificationAudio !==
          this.activeHubSettings.notificationAudio
        ) || this.hubSettingsLocal.notificationBrowser !== this.activeHubSettings.notificationBrowser;
      }

      return (
        JSON.stringify(this.hubSettingsLocal) !==
        JSON.stringify(this.activeHubSettings)
      );
    },
    activeHubSettings() {
      return this.agent?.hubSettings || this.defaultHubSettings;
    },
  },
  methods: {
    async init() {
      this.reset();
    },
    async uploadImage(imageFile) {
      if (!imageFile) return;
      let response = await UploadService.uploadFile(imageFile);
      if (response?.file?.location) {
        this.profilePicture = response.file.location;
      }
    },
    changeHubSettingsLocal({ key, value }) {
      this.hubSettingsLocal = {
        ...this.hubSettingsLocal,
        [key]: value,
      };
    },
    onVolumeChanged(value) {
      this.hubSettingsLocal.volume = value / 100;
    },
    playSound(url) {
      if (this.volume <= 0) {
        return;
      }
      
      this.$store.dispatch(
        'audio/playAudio',
        {
          url,
          volume: this.volume / 100,
        },
        { root: true }
      );
    },
    reset() {
      this.userName = this.agent?.name || '';
      this.profilePicture = this.agent?.profilePicture || '';
      this.titleInput = this.agent?.title || '';
      this.selectedLanguages = this.agent?.languages || [];

      this.hubSettingsLocal = { ...this.activeHubSettings };
      this.volume = this.activeHubSettings.volume * 100;
    },
    async save() {
      return await this.$store.dispatch('livechat/updateAgent', {
        name: this.userName,
        profilePicture: this.profilePicture,
        languages: this.selectedLanguages,
        // title: this.titleInput,
        hubSettings: this.hubSettingsLocal,
        isAgentSelf: true,
      });
    },
  },
};
</script>
