<script>
import HubBoxSimple from '@/components/hub/HubBoxSimple.vue';

export default {
  name: 'LivechatSpecialViewWrapper',
  components: { HubBoxSimple },
};
</script>

<template>
  <div class="full-width moin-bg-grey-lightest d-flex flex-col overflow-y-auto">
    <!-- The white header part -->
    <div class="d-flex justify-center items-center py-16 white">
      <div style="width: 100%; max-width: 700px;" class="px-4">
        <slot name="default" />
      </div>
    </div>

    <!-- Secondary container -->
    <HubBoxSimple
      v-if="$slots.secondary"
      style="width: 100%; max-width: 700px; margin-top: -2.5rem;"
      class="mx-auto mb-16"
      :is-loading="false"
    >
      <template #content>
        <slot name="secondary" />
      </template>
    </HubBoxSimple>
  </div>
</template>