var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "HubDialog",
        {
          attrs: {
            value: _vm.open,
            title: "Livechat hier verwenden?",
            primaryLabel: "Fortfahren",
            "primary-icon": "mdi-account-convert",
            modal: "",
          },
          on: {
            onPrimary: function ($event) {
              return _vm.handleTabChange()
            },
            onSecondary: function ($event) {
              return _vm.handleCancel()
            },
          },
        },
        [
          _c("div", [
            _c("p", [
              _vm._v(
                " Du hast den Livechat in einem anderen Browser Tab/Fenster bereits geöffnet. Möchtest du den Livechat hier fortfahren? "
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }