<template>
  <v-fade-transition>
    <v-list-item v-if="contact">
      <v-list-item-avatar :size="avatarSize" class="mr-4">
        <v-img :src="contact.profileImage" />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title
          v-if="title"
          class="primary--text text--lighten-2 font-weight-bold mb-2"
          v-html="title"
        ></v-list-item-title>
        <v-list-item-title class="primary--text">
          {{ contact.name }}
        </v-list-item-title>
        <v-list-item-title v-if="large" class="primary--text">
          <a :href="'mailto:' + contact.eMail">{{ contact.eMail }}</a>
        </v-list-item-title>
        <v-list-item-title>
          <BtnSquare
            @click="contactFormShown = true"
            class="grow-0 mt-2 px-2 font-weight-medium"
            color="gray"
            outlined
            with-slot
            small
          >
            {{ $t('welcome.supportContact.contact.contactBtn') }}
          </BtnSquare>

          <!-- Form submission status -->
          <p
            v-if="contactFormStatus.message"
            :class="contactFormStatus.success ? 'success--text' : 'error--text'"
          >
            <v-icon :color="contactFormStatus.success ? 'success' : 'error'">
              {{ contactFormStatus.success ? 'check' : 'warning' }}
            </v-icon>
            {{ contactFormStatus.message }}
          </p>
        </v-list-item-title>

        <HubDialog
          v-model="contactFormShown"
          :title="$t('welcome.supportContact.contact.title')"
          :primary-label="$t('common.send')"
          async-dialog
          @onSecondary="contactFormShown = false"
          @onPrimary="sendForm"
          :disabled="!contactFormContent.length"
        >
          <p>
            {{ $t('welcome.supportContact.contact.description') }}
          </p>
          <v-textarea
            v-model="contactFormContent"
            :placeholder="$t('welcome.supportContact.contact.placeholder')"
            filled
            outlined
            auto-grow
            autofocus
            :maxlength="4096"
          />
        </HubDialog>
      </v-list-item-content>
    </v-list-item>
  </v-fade-transition>
</template>

<script>
import ProductService from '@/services/product';
import BtnSquare from '@/components/common/BtnSquare.vue';
import HubDialog from '@/components/hub/HubDialog.vue';
import NotificationService from '@/services/notifications';
import { mapGetters } from 'vuex';

export default {
  name: 'ContactItem',
  components: { BtnSquare, HubDialog },
  props: {
    large: {
      type: Boolean,
      default: false,
    },
    contactId: {
      type: String,
      default: null,
    },
    contactObj: {
      type: Object,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      contact: null,
      contactFormShown: false,
      contactFormContent: '',
      contactFormStatus: {
        success: true,
        message: null,
      },
    };
  },
  computed: {
    ...mapGetters('bots', ['currentBotId']),
    avatarSize() {
      if (this.large) return 80;
      return 40;
    },
  },
  created() {
    if (this.contactObj) {
      this.contact = this.contactObj;
    } else {
      ProductService.getContact(this.contactId).then((contact) => {
        this.contact = contact;
      });
    }
  },
  methods: {
    /**
     * Submits a contact form request
     * @returns {Promise<void>}
     */
    async sendForm() {
      if (!this.contactFormContent) return;

      try {
        const result = await NotificationService.notifyCSM({
          uniqueBotId: this.currentBotId,
          type: 'general',
          comment: this.contactFormContent,
          csmId: this.contactId,
        });

        if (!result.success) throw new Error(result.message || this.$t('welcome.supportContact.contact.failure'));
        this.contactFormStatus.message = this.$t('welcome.supportContact.contact.success');
        this.contactFormStatus.success = true;

        // Reset form if success, otherwise keep the content
        this.contactFormContent = '';
      } catch (e) {
        console.error(e);
        this.contactFormStatus.success = false;
        this.contactFormStatus.message = this.$t('welcome.supportContact.contact.failure');
      } finally {
        this.contactFormShown = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
