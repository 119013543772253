var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.teaser && _vm.teaser.payload
    ? _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("h3", { staticClass: "text-h6" }, [
                _vm._v(_vm._s(_vm.$t("teasers.name.title"))),
              ]),
              _c("div", { staticClass: "text-body-1 mb-4" }, [
                _vm._v(_vm._s(_vm.$t("teasers.name.info"))),
              ]),
              _c("v-text-field", {
                staticClass: "mb-4",
                attrs: {
                  outlined: "",
                  dense: "",
                  "hide-details": "",
                  type: "text",
                  label: `${_vm.$t("teasers.name.inputLabel")}`,
                },
                on: { input: _vm.checkDirty },
                model: {
                  value: _vm.teaser.payload.displayName,
                  callback: function ($$v) {
                    _vm.$set(_vm.teaser.payload, "displayName", $$v)
                  },
                  expression: "teaser.payload.displayName",
                },
              }),
              _c("InfoBox", {
                attrs: { text: _vm.$t("teasers.name.infoBottom") },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }