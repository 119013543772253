var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "v-messages v-messages__message message-transition-enter-to error--text",
      },
      [_vm._v(_vm._s(_vm.text))]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }