var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        [
          _c("h2", { staticClass: "text-h6" }, [_vm._v("Passwort ändern")]),
          _c("p", { staticClass: "text-copy-2" }, [
            _vm._v(
              " Bitte gib dein aktuelles Passwort ein, um ein neues Passwort festzulegen. "
            ),
          ]),
          _c("v-text-field", {
            attrs: {
              outlined: "",
              label: "Aktuelles Passwort",
              type: "password",
            },
            model: {
              value: _vm.currentPassword,
              callback: function ($$v) {
                _vm.currentPassword = $$v
              },
              expression: "currentPassword",
            },
          }),
          _c("p", { staticClass: "text-copy-2" }, [
            _vm._v(
              " Bitte gib dein aktuelles Passwort ein, um ein neues Passwort festzulegen. "
            ),
          ]),
          _c("v-text-field", {
            attrs: {
              outlined: "",
              type: "password",
              label: "Neues Passwort",
              disabled: !_vm.currentPassword.length,
            },
            model: {
              value: _vm.newPassword,
              callback: function ($$v) {
                _vm.newPassword = $$v
              },
              expression: "newPassword",
            },
          }),
          _c("v-text-field", {
            attrs: {
              outlined: "",
              type: "password",
              label: "Neues Passwort bestätigt",
              disabled: !_vm.currentPassword.length,
            },
            model: {
              value: _vm.newPasswordConfirm,
              callback: function ($$v) {
                _vm.newPasswordConfirm = $$v
              },
              expression: "newPasswordConfirm",
            },
          }),
          _c("InfoBox", {
            attrs: {
              text: "Das Passwort muß mindestens aus 8 Zeichen bestehen ...",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }