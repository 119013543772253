var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "text-h6 text-center", class: _vm.classes },
      [
        _c("v-icon", { class: _vm.classes, attrs: { size: "64" } }, [
          _vm._v(" " + _vm._s(_vm.icon) + " "),
        ]),
        _vm._v(" " + _vm._s(_vm.title) + " "),
      ],
      1
    ),
    _vm.description
      ? _c(
          "div",
          { staticClass: "text-body-1 text-center", class: _vm.classes },
          [_vm._v(" " + _vm._s(_vm.description) + " ")]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }