var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "theme-header bg-secondary/10 p-4 rounded-lg mb-6" },
        [
          _c("h4", { staticClass: "text-secondary text-xl font-bold mb-3" }, [
            _vm._v(_vm._s(_vm.newIntent.displayName)),
          ]),
          _c("p", { staticClass: "text-gray-700" }, [
            _vm._v(_vm._s(_vm.newIntent.scenario)),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "mb-6" },
        [
          _c("InfoBox", {
            attrs: { text: _vm.$t("intentNew.stepSamples.infoSteps") },
          }),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-4" },
        _vm._l(_vm.samplesInput, function (_, index) {
          return _c(
            "v-col",
            { key: index, attrs: { cols: "12" } },
            [
              _c("v-text-field", {
                attrs: {
                  outlined: "",
                  "persistent-placeholder": "",
                  color: "secondary darken-2",
                  "hide-details": "",
                  autofocus: index === 0,
                  label: `${_vm.$t("intentNew.stepSamples.labelTypicSample")} ${
                    index + 1
                  }`,
                  clearable: "",
                  disabled: _vm.validStates[index]?.running,
                },
                on: {
                  input: function ($event) {
                    return _vm.updateSamplesDebounced(index)
                  },
                },
                model: {
                  value: _vm.samplesInput[index],
                  callback: function ($$v) {
                    _vm.$set(_vm.samplesInput, index, $$v)
                  },
                  expression: "samplesInput[index]",
                },
              }),
              !_vm.noK2
                ? _c(
                    "div",
                    { staticClass: "mt-2 text-caption d-flex align-center" },
                    [
                      _vm.validStates[index]?.running
                        ? _c("v-progress-circular", {
                            staticClass: "mr-2",
                            attrs: {
                              indeterminate: "",
                              color: "primary",
                              size: "16",
                              width: "2",
                            },
                          })
                        : _vm.validStates[index]?.valid
                        ? _c(
                            "v-icon",
                            {
                              staticClass: "mr-2",
                              attrs: { color: "green", small: "" },
                            },
                            [_vm._v(" mdi-check-circle ")]
                          )
                        : _vm.validStates[index]?.valid === false
                        ? _c(
                            "v-icon",
                            {
                              staticClass: "mr-2",
                              attrs: { color: "red", small: "" },
                            },
                            [_vm._v(" mdi-alert-circle ")]
                          )
                        : _vm._e(),
                      _vm.validStates[index]?.running
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "intentNew.stepSamples.validationRunning"
                                )
                              )
                            ),
                          ])
                        : _vm.validStates[index]?.valid
                        ? _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "intentNew.stepSamples.validationValid"
                                  )
                                ) +
                                " "
                            ),
                          ])
                        : _vm.validStates[index]?.valid === false
                        ? _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "intentNew.stepSamples.validationInvalid",
                                    {
                                      topic: _vm.displayName(
                                        _vm.validStates[index]?.label,
                                        _vm.$vuetify.lang.current
                                      ),
                                    }
                                  )
                                ) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }