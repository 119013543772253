var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c("v-col", [
                _c("h1", { staticClass: "text-h5 mb-4" }, [
                  _vm._v(
                    _vm._s(_vm.$t("login.passwordReset.requestToken.title"))
                  ),
                ]),
                _c("p", { staticClass: "text-body-1" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("login.passwordReset.requestToken.subTitle")
                      ) +
                      " "
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      label: _vm.$t("login.username"),
                      name: "login",
                      type: "text",
                      "hide-details": "",
                    },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.handleSubmit()
                      },
                    },
                    model: {
                      value: _vm.username,
                      callback: function ($$v) {
                        _vm.username = $$v
                      },
                      expression: "username",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "text-right pt-6", attrs: { cols: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        depressed: "",
                        color: "primary",
                        large: "",
                        "data-cy": "login",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleSubmit()
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("login.passwordReset.requestToken.button")
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm.messageError
                ? _c(
                    "v-col",
                    [
                      _c(
                        "v-alert",
                        { attrs: { outlined: "", type: "error" } },
                        [_vm._v(" " + _vm._s(_vm.messageError) + " ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.messageSuccess
                ? _c(
                    "v-col",
                    [
                      _c(
                        "v-alert",
                        { attrs: { outlined: "", type: "success" } },
                        [_vm._v(" " + _vm._s(_vm.messageSuccess) + " ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }