<template>
  <v-app style="max-height: 100vh; height: 100vh">
    <HubContent fixedHeight class="moin-fresh">
      <template v-slot:navbar>
        <HubNavbar :isLoading="loading" title="Livechat">
          <template v-slot:toolbar>
            <LivechatAmountInfo v-if="$vuetify.breakpoint.mdAndUp" />
            <LivechatHeaderMenu />
          </template>
        </HubNavbar>
      </template>
      <template v-slot:content>
        <div class="full-height" :class="$vuetify.breakpoint.smAndUp && 'pa-3'">
          <Livechat />
        </div>
      </template>
    </HubContent>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import HubContent from '@/components/hub/HubContent.vue';
import HubNavbar from '@/components/hub/HubNavbar.vue';
import Livechat from '@/components/livechat/Livechat.vue';
import LivechatAmountInfo from '@/components/livechat/LivechatAmountInfo.vue';
import LivechatHeaderMenu from '@/components/livechat/LivechatHeaderMenu.vue';

export default {
  name: 'LivechatAgent',
  components: {
    HubContent,
    HubNavbar,
    Livechat,
    LivechatAmountInfo,
    LivechatHeaderMenu,
  },
  computed: {
    ...mapGetters('livechat', ['loading']),
  },
};
</script>
