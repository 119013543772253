var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "avatar",
      class: [_vm.small && "avatar--small"],
      style: {
        borderColor: _vm.avatar.color.hex,
      },
    },
    [
      _c(
        "v-icon",
        { style: { color: _vm.avatar.color.hex }, attrs: { small: _vm.small } },
        [_vm._v(" " + _vm._s(_vm.avatar.icon.icon) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }