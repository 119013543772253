<script>
import BtnSquare from '@/components/common/BtnSquare.vue';

export default {
  name: 'WelcomeInfoTeaser',
  components: { BtnSquare },
  props: {
    "title": {
      type: String,
      required: true,
    },
    "icon": {
      type: String,
      required: true,
    },
    "titleHeading": {
      type: Boolean,
      default: false,
    },
    "loading": {
      type: Boolean,
      default: true,
    },
    "noData": {
      type: Boolean,
      default: false,
    },
    "value": {
      type: [String,Number],
      default: null,
    },
    "to": {
      type: [String, Object],
      default: null,
    },
    "linkLabel": {
      type: String,
      default: null,
    },
    "criticalValue": {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<template>
  <div :style="{ minHeight: '110px' }" class="text-center d-flex flex-col gap-1">
    <!-- Title / Header -->
    <div
      class="primary--text pb-0 text-no-wrap"
      :class="[titleHeading ? 'text-h6' : 'text-body-1']"
    >
      {{ title }}
    </div>

    <!-- Main information -->
    <div v-if="loading" style="height: 48px" class="d-flex justify-center">
      <v-skeleton-loader type="image" height="25" width="48" />
    </div>

    <template v-else>
      <!-- Row with key metric / data -->
      <div class="d-flex justify-center items-center pb-1">
        <v-icon
          :color="noData ? 'grey' : 'primary'"
          size="32"
          left
        >
          {{ icon }}
        </v-icon>

        <!-- No data -->
        <h4 v-if="noData" class="pt-1 text-h4 grey--text text--darken-1">
          0
        </h4>
        <!-- Has data -->
        <h4
          v-else-if="value !== null"
          class="pt-1 text-h4"
          :class="{
            'primary--text': !criticalValue,
            'critical-value': criticalValue
          }"
        >
          {{ value }}
        </h4>
      </div>

      <!-- Custom content -->
      <slot v-if="$slots.default" />
    </template>
  </div>
</template>

<style scoped>
.critical-value {
  color: var(--moin-ci-secondary);
}
</style>