var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "HubBox",
    {
      attrs: {
        title: "Conversions",
        titleSub: _vm.$t("common.allChannels"),
        icon: "mdi-flag-outline",
      },
    },
    [
      _c("IntentStatistic", {
        staticClass: "mb-4",
        attrs: {
          "progress-title": `Conversion-Rate <span class='primary--text text--lighten-3'>(seit der letzten Änderung)</span>`,
          "chart-title": "Zeitliche Entwicklung / Trend",
          "chart-subline": "Conversions in den letzten 12 Wochen",
          loading: _vm.loading,
        },
        scopedSlots: _vm._u([
          {
            key: "progressBar",
            fn: function () {
              return [
                _c("v-progress-linear", {
                  staticClass: "v-progress-linear--outer-value",
                  attrs: {
                    value: _vm.progressValue,
                    "buffer-value": "100",
                    "background-color": "#bdbdbd",
                    color: "var(--v-primary-lighten1)",
                    height: "10",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ value }) {
                        return [
                          _c(
                            "strong",
                            { style: _vm.getProgressValueStyle(value) },
                            [_vm._v(" " + _vm._s(Math.ceil(value)) + "% ")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "chart",
            fn: function () {
              return [
                _vm.chart.series.length >= 2
                  ? _c("apexchart", {
                      attrs: {
                        type: "bar",
                        options: _vm.chart.options,
                        series: _vm.chart.series,
                        height: "140px",
                      },
                    })
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }