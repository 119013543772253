// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/fonts/JosefinSans-VariableFont_wght.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("@/assets/fonts/Lato-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("@/assets/fonts/silka-semibold-webfont.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@charset \"UTF-8\";\n@font-face {\n  font-family: \"Josefin Sans\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n@font-face {\n  font-family: \"Lato\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n@font-face {\n  font-family: \"silkaHeading\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n.question-item[data-v-b2b97668] {\n  border-radius: 4px;\n  padding: 12px 16px;\n  border: 1px solid #E0E0E0;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  /* Ändert die Ausrichtung der Kinder auf vertikal */\n}\n.highlight-item[data-v-b2b97668] {\n  border: 1px solid #8046eb;\n}\n.product-advisors-list[data-v-b2b97668] {\n  list-style: none;\n  /* Entfernt die Aufzählungszeichen */\n  padding-left: 0;\n  /* Entfernt den linken Abstand */\n  margin-top: 0;\n  /* Optional: Entfernt den oberen Abstand, falls nötig */\n}\n.product-advisors-list li[data-v-b2b97668] {\n  padding: 4px 0;\n  /* Fügt vertikalen Abstand zwischen den Elementen hinzu */\n  border-bottom: 1px solid #e0e0e0;\n  /* Fügt eine Trennlinie zwischen den Elementen hinzu */\n}\n.product-advisors-list li[data-v-b2b97668]:last-child {\n  border-bottom: none;\n  /* Entfernt die untere Linie vom letzten Element */\n}\n.example-request[data-v-b2b97668] {\n  background-color: #f9f9f9;\n  /* Leichter Hintergrund für Kontrast */\n  border-left: 4px solid #fa7a59;\n  /* Blaue Linie links als Hervorhebung */\n  padding: 10px 15px;\n  /* Innenabstand */\n  border-radius: 4px;\n  /* Ecken abrunden */\n  margin-top: 10px;\n  /* Abstand nach oben */\n}\n.request[data-v-b2b97668], .response[data-v-b2b97668] {\n  padding: 5px 0;\n  /* Vertikaler Abstand */\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
