var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.teaser && _vm.teaser.payload
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("h3", { staticClass: "text-h6" }, [
                    _vm._v(_vm._s(_vm.$t("teasers.integrations.title"))),
                  ]),
                  _c("div", { staticClass: "text-body-1" }, [
                    _vm._v(_vm._s(_vm.$t("teasers.integrations.info"))),
                  ]),
                  _c("v-switch", {
                    staticClass: "mt-4",
                    attrs: {
                      color: _vm.isOptionsActivated ? "green" : "grey",
                      label: _vm.$t("teasers.integrations.switchLabel"),
                      inset: "",
                    },
                    on: { change: _vm.updateTeaserIntegrations },
                    model: {
                      value: _vm.isOptionsActivated,
                      callback: function ($$v) {
                        _vm.isOptionsActivated = $$v
                      },
                      expression: "isOptionsActivated",
                    },
                  }),
                  _c("InfoBox", {
                    attrs: { text: _vm.$t("teasers.integrations.switchHint") },
                  }),
                ],
                1
              ),
              _vm.isOptionsActivated
                ? _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-select", {
                        staticClass: "mb-4",
                        attrs: {
                          items: _vm.options,
                          label: _vm.$t(
                            "teasers.integrations.kindOfConstraint"
                          ),
                        },
                        on: { change: _vm.changeTeaserListOption },
                        model: {
                          value: _vm.selectedListOption,
                          callback: function ($$v) {
                            _vm.selectedListOption = $$v
                          },
                          expression: "selectedListOption",
                        },
                      }),
                      _vm.selectedListOption
                        ? _vm._l(_vm.urls, function (item, index) {
                            return _c(
                              "div",
                              { key: index },
                              [
                                _c("v-divider", { staticClass: "mt-2" }),
                                index > 0
                                  ? _c("div", { staticClass: "ti-or" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "grey--text text-body-2 ti-or-inner",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "teasers.integrations.or"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                                _c(
                                  "v-radio-group",
                                  {
                                    staticClass: "mb-2",
                                    attrs: {
                                      value: _vm.getUrlConstraintType(item),
                                      row: "",
                                      dense: "",
                                      "hide-details": "",
                                    },
                                    on: {
                                      change: (type) =>
                                        _vm.changeUrlConstraint(type, index),
                                    },
                                  },
                                  [
                                    _c("v-radio", {
                                      attrs: {
                                        label: _vm.$t(
                                          `teasers.integrations.urlConstraintName.${_vm.urlConstraintTypes.EXACT}`
                                        ),
                                        value: _vm.urlConstraintTypes.EXACT,
                                      },
                                    }),
                                    _c("v-radio", {
                                      attrs: {
                                        label: _vm.$t(
                                          `teasers.integrations.urlConstraintName.${_vm.urlConstraintTypes.SUBPAGES}`
                                        ),
                                        value: _vm.urlConstraintTypes.SUBPAGES,
                                      },
                                    }),
                                    _c("v-radio", {
                                      attrs: {
                                        label: _vm.$t(
                                          `teasers.integrations.urlConstraintName.${_vm.urlConstraintTypes.KEYWORD}`
                                        ),
                                        value: _vm.urlConstraintTypes.KEYWORD,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "d-flex" },
                                  [
                                    _c("v-text-field", {
                                      staticClass: "mb-4",
                                      attrs: {
                                        outlined: "",
                                        dense: "",
                                        "hide-details": "",
                                        type: "text",
                                        value: _vm.cleanRegexToUrl(item),
                                        label: _vm.$t(
                                          `teasers.integrations.urlConstraintLabel.${_vm.getUrlConstraintType(
                                            item
                                          )}`
                                        ),
                                      },
                                      on: {
                                        input: (url) =>
                                          _vm.updateUrl(url, index),
                                      },
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "pl-2" },
                                      [
                                        _c("BtnSquare", {
                                          attrs: {
                                            icon: "mdi-delete",
                                            disabled:
                                              index === 0 &&
                                              _vm.urls.length === 1,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteUrlFromList(
                                                index
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          })
                        : _vm._e(),
                      _c("v-divider"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mt-4",
                          attrs: {
                            outlined: "",
                            disabled: !_vm.selectedListOption,
                          },
                          on: { click: _vm.addUrl },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("teasers.integrations.addUrl")) +
                              " "
                          ),
                          _c("v-icon", { attrs: { outlined: "", right: "" } }, [
                            _vm._v("mdi-plus"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }