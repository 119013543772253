var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "white" },
    [
      _c(
        "div",
        {
          staticClass: "d-flex justify-space-between px-3",
          staticStyle: { height: "60px" },
        },
        [
          _c(
            "div",
            { staticClass: "d-flex align-center full-height" },
            [
              _vm.backTo
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "", color: "primary lighten-3" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "span",
                                  _vm._g(
                                    _vm._b(
                                      { staticClass: "pt-1" },
                                      "span",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c("BtnSquare", {
                                      attrs: {
                                        icon: "arrow_back",
                                        to: _vm.backTo,
                                        exact: true,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3141298758
                      ),
                    },
                    [
                      _vm._t("back-button", function () {
                        return [
                          _c("span", [_vm._v(_vm._s(_vm.$t("common.back")))]),
                        ]
                      }),
                    ],
                    2
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "pl-2 pt-1",
                  class: _vm.isAdminOnly && "admin--text",
                },
                [
                  _vm.title
                    ? _vm._t(
                        "title",
                        function () {
                          return [
                            _vm.title
                              ? _c(
                                  "span",
                                  {
                                    key: _vm.title,
                                    staticClass: "text-truncate text-h5",
                                  },
                                  [_vm._v(" " + _vm._s(_vm.title) + " ")]
                                )
                              : _c("v-skeleton-loader", {
                                  staticClass: "pt-2 grow",
                                  attrs: { boilerplate: "", type: "heading" },
                                }),
                          ]
                        },
                        { title: _vm.title }
                      )
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "d-flex align-center justify-end" },
            [_vm._t("toolbar", null, { disabled: _vm.isLoading })],
            2
          ),
        ]
      ),
      _c("v-divider"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }