var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.activeChat
    ? _c(
        "div",
        [
          _c(
            "div",
            { staticClass: "livechat-history" },
            _vm._l(_vm.messages, function (item, index) {
              return _c(
                "div",
                { key: index },
                [
                  !item.hidden
                    ? _c("LivechatChatItem", {
                        attrs: {
                          item: item,
                          isTranslated:
                            _vm.useTranslation &&
                            typeof item.translated !== "undefined",
                          chat: _vm.activeChat,
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            }),
            0
          ),
          _c("LivechatDivider"),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }