var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "livechat-lists" }, [
    _c("div", { staticClass: "livechat-lists-content" }, [
      _c(
        "div",
        {
          staticStyle: {
            overflow: "hidden",
            "overflow-y": "auto",
            "max-width": "100%",
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "h1",
                {
                  staticClass:
                    "pt-5 pb-2 text-body-2 text-uppercase text-center secondary--text",
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("livechat.chats.unassignedChats")) + " "
                  ),
                  _c("span", { staticStyle: { opacity: "0.5" } }, [
                    _vm._v("(" + _vm._s(_vm.chatsUnassigned.length) + ")"),
                  ]),
                ]
              ),
              _c("v-divider"),
              _c("LivechatList", { attrs: { items: _vm.chatsUnassigned } }),
            ],
            1
          ),
          !_vm.inSupervisorMode
            ? _c(
                "div",
                [
                  _c(
                    "h1",
                    {
                      staticClass:
                        "pt-5 pb-2 text-body-2 text-uppercase text-center primary--text text--lighten-2",
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("livechat.chats.myChats")) + " "
                      ),
                      _c("span", { staticStyle: { opacity: "0.5" } }, [
                        _vm._v("(" + _vm._s(_vm.chatsOpen.length) + ")"),
                      ]),
                    ]
                  ),
                  _c("v-divider"),
                  _c("LivechatList", { attrs: { items: _vm.chatsOpen } }),
                ],
                1
              )
            : _vm._e(),
          !_vm.inSupervisorMode
            ? _c(
                "div",
                [
                  _c(
                    "h1",
                    {
                      staticClass:
                        "pt-5 pb-2 text-body-2 text-uppercase text-center primary--text text--lighten-2",
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("livechat.chats.assignedToOtherAgentsChats")
                          ) +
                          " "
                      ),
                      _c("span", { staticStyle: { opacity: "0.5" } }, [
                        _vm._v("(" + _vm._s(_vm.chatsOtherAgents.length) + ")"),
                      ]),
                    ]
                  ),
                  _c("v-divider"),
                  _c("LivechatList", {
                    attrs: { items: _vm.chatsOtherAgents },
                  }),
                ],
                1
              )
            : _c(
                "div",
                [
                  _c(
                    "h1",
                    {
                      staticClass:
                        "pt-5 pb-2 text-body-2 text-uppercase text-center primary--text text--lighten-2",
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("livechat.chats.openedChats")) + " "
                      ),
                      _c("span", { staticStyle: { opacity: "0.5" } }, [
                        _vm._v("(" + _vm._s(_vm.chatsOtherAgents.length) + ")"),
                      ]),
                    ]
                  ),
                  _c("v-divider"),
                  _c("LivechatList", {
                    attrs: { items: _vm.chatsOtherAgents },
                  }),
                ],
                1
              ),
        ]
      ),
    ]),
    _c(
      "div",
      {
        staticClass: "livechat-lists-closed",
        class: [_vm.isClosedOpen && "livechat-lists-closed--open"],
      },
      [
        !_vm.isClosedOpen ? _c("v-divider") : _vm._e(),
        _c(
          "div",
          { staticClass: "livechat-lists-closed-header" },
          [
            _c(
              "h1",
              {
                staticClass:
                  "text-body-2 flex-grow-1 text-uppercase text-center primary--text text--lighten-5",
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.inSupervisorMode
                        ? _vm.$t("livechat.chats.closedChats")
                        : _vm.$t("livechat.chats.closedChatsToday")
                    ) +
                    " "
                ),
                _c("span", { staticStyle: { opacity: "0.5" } }, [
                  _vm._v("(" + _vm._s(_vm.chatsClosed.length) + ")"),
                ]),
              ]
            ),
            _c("BtnSquare", {
              attrs: {
                icon: _vm.isClosedOpen ? "mdi-chevron-up" : "mdi-chevron-down",
              },
              on: {
                click: function ($event) {
                  _vm.isClosedOpen = !_vm.isClosedOpen
                },
              },
            }),
          ],
          1
        ),
        _c("v-divider"),
        _vm.isClosedOpen
          ? _c(
              "div",
              { staticClass: "livechat-lists-closed-scroller moin-fade-in" },
              [_c("LivechatList", { attrs: { items: _vm.chatsClosed } })],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }