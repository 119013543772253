var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { staticStyle: { "max-height": "100vh", height: "100vh" } },
    [
      _c("HubMenu"),
      _c("Appbar"),
      _vm.teaser && _vm.teaser.payload
        ? _c(
            "HubEditorFrame",
            {
              ref: "hubeditor",
              attrs: {
                title: _vm.teaser.payload.displayName,
                loading: _vm.isLoading,
                draft: _vm.isDirty,
                saveIcon: "cloud_upload",
                back: "/wt/teaser",
                "sidebar-items": [{ title: "", icon: "info", name: "info" }],
                disabled: !_vm.isValid,
              },
              on: { onReset: _vm.resetTeaser, onSave: _vm.saveTeaser },
              model: {
                value: _vm.isSidebarOpen,
                callback: function ($$v) {
                  _vm.isSidebarOpen = $$v
                },
                expression: "isSidebarOpen",
              },
            },
            [
              [
                _c(
                  "v-container",
                  { staticClass: "white pa-6 pb-8", attrs: { fluid: "" } },
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12", md: "6" } },
                          [
                            _c("h3", { staticClass: "text-h6" }, [
                              _vm._v(
                                " " + _vm._s(_vm.$t("teasers.status")) + " "
                              ),
                            ]),
                            _c("v-switch", {
                              attrs: {
                                inset: "",
                                color: _vm.teaser.active ? "green" : "grey",
                                label: _vm.$t("teasers.statusSwitchLabel"),
                                disabled: !_vm.isValid,
                              },
                              on: { change: _vm.checkDirty },
                              model: {
                                value: _vm.teaser.active,
                                callback: function ($$v) {
                                  _vm.$set(_vm.teaser, "active", $$v)
                                },
                                expression: "teaser.active",
                              },
                            }),
                            _c(
                              "div",
                              { staticStyle: { height: "40px" } },
                              [
                                !_vm.isValid
                                  ? _c("InfoBox", {
                                      attrs: {
                                        icon: "mdi-publish-off",
                                        iconColor: "error",
                                        text: _vm.$t(
                                          "teasers.statusBlockedInfo"
                                        ),
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "12", md: "6" } },
                          [
                            _vm.channels.length > 1
                              ? [
                                  _c("h3", { staticClass: "text-h6" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("teasers.channel")) +
                                        " "
                                    ),
                                  ]),
                                  _c("v-select", {
                                    attrs: {
                                      label: _vm.$t("teasers.channelLabel"),
                                      items: _vm.channels,
                                      "item-text": "displayName",
                                      "item-value": "channelId",
                                    },
                                    on: { change: _vm.changeTeaserChannel },
                                    model: {
                                      value: _vm.selectedChannel,
                                      callback: function ($$v) {
                                        _vm.selectedChannel = $$v
                                      },
                                      expression: "selectedChannel",
                                    },
                                  }),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c("v-divider", { staticClass: "my-6" }),
                    _c("TeasersActions"),
                    _c("v-divider", { staticClass: "my-6" }),
                    _c("TeasersIntegrations", {
                      attrs: { teaser: _vm.teaser },
                    }),
                    _c("v-divider", { staticClass: "my-6" }),
                    _c("TeasersTriggers"),
                    _c("v-divider", { staticClass: "my-6" }),
                    _c("TeasersName"),
                  ],
                  1
                ),
              ],
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }