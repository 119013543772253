<script>
/**
 * The snackbar stack will display Snackbar alerts in the bottom right corner with position fixed.
 * Alerts can be pushed to and displayed anywhere using the `hubUi` Vuex store.
 *
 * The SnackbarStack and the alert objects can both use scopes, and the snackbar stack will show only
 * the intersecting scopes. Alternatively, either the stack or the alert can use the wildcard
 * scope to display all alerts/be displayed anywhere.
 *
 * Omission of scopes is equivalent to using the wildcard on both sides.
 * I.e., if alert has no scopes, it will be displayed anywhere.
 * If snackbar stack has no scopes, it will display all alerts.
 *
 * Scopes are an array of arbitrary strings of your choice.
 */
export default {
  name: 'SnackbarStack',
  props: {
    "scopes": {
      type: Array,
      default: () => ["*"],
      validator: (val) => {
        return val.every(s => typeof s === "string");
      }
    },
  },
  computed: {
    /**
     * Returns all alerts that should be displayed for the scope of this component
     */
    alerts() {
      return this.$store.getters['hubUi/alertsInScope'](this.scopes);
    },
  },
  methods: {
    /**
     * Provides a convenient way of pushing alerts to the Snackbar stacks.
     * @param {Object} alertObj
     * @example
     * <SnackbarStack ref="alertStack" :scopes="['test']">
     *
     * this.$refs.alertStack.pushAlert({message: "Hi", scopes: ['test', 'other_scope']});
     */
    pushAlert(alertObj) {
      // You can, of course, also just use this Vuex store mutation directly anywhere
      this.$store.commit('hubUi/addAlert', alertObj);
    },
    /**
     * Adds an alert that will be using the same scope as this component instance
     * @param {Object} alertObj
     * @example
     * <SnackbarStack ref="alertStack" :scopes="['test']">
     *
     * this.$refs.alertStack.pushAlertToSelf({message: "Hi"});
     */
    pushAlertToSelf(alertObj) {
      alertObj.scopes = this.scopes;
      this.pushAlert(alertObj);
    },
  }
};
</script>

<template>
  <div>
    <template v-for="(alert,i) in alerts">
      <v-snackbar
        v-if="!alert.to"
        :key="alert.id"
        :timeout="alert.duration"
        :style="{'margin-bottom': `${i * 70}px`}"
        bottom
        right
        vertical
        :color="alert.type"
        v-model="alert.visible"
      >
        {{ $t(alert.message) }}
      </v-snackbar>

      <RouterLink v-else :to="alert.to" :key="alert.id">
        <v-snackbar
          :timeout="alert.duration"
          :style="{'margin-bottom': `${i * 70}px`}"
          bottom
          right
          vertical
          :color="alert.type"
          v-model="alert.visible"
        >
          {{ $t(alert.message) }}
        </v-snackbar>
      </RouterLink>
    </template>
  </div>
</template>