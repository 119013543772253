<script>

export default {
  name: 'SparkleLoader',
  props: {
    // Width of the SVG
    "width": {
      type: [String, Number],
      default: 24,
    },
    // Height of the SVG
    "height": {
      type: [String, Number],
      default: 24,
    },
    // Stroke weight
    "weight": {
      type: [String, Number],
      default: 1.5,
    },
    // Color of stroke. Should inherit color by default, but you can override it
    "color": {
      type: String,
      default: "currentColor",
    },
    // How fast it sparkles
    "speed": {
      type: String,
      default: "1s",
    },
    // Make the stars solid colors
    "filled": {
      type: Boolean,
      default: false,
    },
    // Makes the sparkles frozen (no animation)
    "frozen": {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      // Animated paths
      paths: [
        // Middle big star
        [
          "M9.33333 10.6667C8 9.33333 8 8 8 8C8 8 8 9.33333 6.66667 10.6667C5.33333 12 4 12 4 12C4 12 5.33333 12 6.66667 13.3333C8 14.6667 8 16 8 16C8 16 8 14.6667 9.33333 13.3333C10.6667 12 12 12 12 12C12 12 10.6667 12 9.33333 10.6667Z",
          "M8.66667 11.3333C8 10.6667 8 10 8 10C8 10 8 10.6667 7.33333 11.3333C6.66667 12 6 12 6 12C6 12 6.66667 12 7.33333 12.6667C8 13.3333 8 14 8 14C8 14 8 13.3333 8.66667 12.6667C9.33333 12 10 12 10 12C10 12 9.33333 12 8.66667 11.3333Z",
          "M10.3333 9.66667C8 7.33333 8 5 8 5C8 5 8 7.33333 5.66667 9.66667C3.33333 12 1 12 1 12C1 12 3.33333 12 5.66667 14.3333C8 16.6667 8 19 8 19C8 19 8 16.6667 10.3333 14.3333C12.6667 12 15 12 15 12C15 12 12.6667 12 10.3333 9.66667Z",
        ],
        // Top right star
        [
          "M18.6667 4.33333C17 2.66667 17 1 17 1C17 1 17 2.66667 15.3333 4.33333C13.6667 6 12 6 12 6C12 6 13.6667 6 15.3333 7.66667C17 9.33333 17 11 17 11C17 11 17 9.33333 18.6667 7.66667C20.3333 6 22 6 22 6C22 6 20.3333 6 18.6667 4.33333Z",
          "M18 5C17 4 17 3 17 3C17 3 17 4 16 5C15 6 14 6 14 6C14 6 15 6 16 7C17 8 17 9 17 9C17 9 17 8 18 7C19 6 20 6 20 6C20 6 19 6 18 5Z",
          "M17.6667 5.33333C17 4.66667 17 4 17 4C17 4 17 4.66667 16.3333 5.33333C15.6667 6 15 6 15 6C15 6 15.6667 6 16.3333 6.66667C17 7.33333 17 8 17 8C17 8 17 7.33333 17.6667 6.66667C18.3333 6 19 6 19 6C19 6 18.3333 6 17.6667 5.33333Z",
        ],
        // Bottom left star
        [
          "M17.6667 16.3333C17 15.6667 17 15 17 15C17 15 17 15.6667 16.3333 16.3333C15.6667 17 15 17 15 17C15 17 15.6667 17 16.3333 17.6667C17 18.3333 17 19 17 19C17 19 17 18.3333 17.6667 17.6667C18.3333 17 19 17 19 17C19 17 18.3333 17 17.6667 16.3333Z",
          "M19 15C17 13 17 11 17 11C17 11 17 13 15 15C13 17 11 17 11 17C11 17 13 17 15 19C17 21 17 23 17 23C17 23 17 21 19 19C21 17 23 17 23 17C23 17 21 17 19 15Z",
          "M18 16C17 15 17 14 17 14C17 14 17 15 16 16C15 17 14 17 14 17C14 17 15 17 16 18C17 19 17 20 17 20C17 20 17 19 18 18C19 17 20 17 20 17C20 17 19 17 18 16Z",
        ]
      ],
    };
  },
  computed: {
    starFill() {
      if (this.filled && this.color) return this.color;
      if (this.filled) return "currentColor";
      return "none";
    }
  }
};
</script>

<template>
  <svg :width="width" :height="height" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g :stroke="color" :stroke-width="weight">
      <!-- Middle -->
      <path
        :d="frozen ? paths[0][2] : ''"
        stroke-linejoin="round"
        stroke-linecap="round"
        :fill="starFill"
      >

        <!--
        You can customize more animations using this approach.
        values="start;to;end"
        -->

        <animate
          v-if="!frozen"
          attributeName="d"
          :values="`${paths[0][0]};${paths[0][1]};${paths[0][2]};${paths[0][0]};`"
          :dur="speed"
          repeatCount="indefinite"
        />
      </path>
      <!-- Top right -->
      <path
        :d="frozen ? paths[1][2] : ''"
        stroke-linejoin="round"
        stroke-linecap="round"
        :fill="starFill"
      >
        <animate
          v-if="!frozen"
          attributeName="d"
          :values="`${paths[1][0]};${paths[1][1]};${paths[1][2]};${paths[1][0]}`"
          :dur="speed"
          repeatCount="indefinite"
        />
      </path>
      <!-- Bottom left -->
      <path
        :d="frozen ? paths[2][2] : ''"
        stroke-linejoin="round"
        stroke-linecap="round"
        :fill="starFill"
      >
        <animate
          v-if="!frozen"
          attributeName="d"
          :values="`${paths[2][0]};${paths[2][1]};${paths[2][2]};${paths[2][0]};`"
          :dur="speed"
          repeatCount="indefinite"
        />
      </path>
    </g>
  </svg>
</template>