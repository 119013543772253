var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex flex-col", staticStyle: { width: "fit-content" } },
    [
      _c(
        "svg",
        {
          attrs: {
            width: "376",
            height: "72",
            viewBox: "0 0 448 86",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg",
          },
        },
        [
          _c("rect", {
            attrs: { width: "448", height: "86", fill: "#ECECEC" },
          }),
          _c("path", {
            attrs: {
              d: "M108 27.72a6.72 6.72 0 0 1 6.72-6.72h320.564A6.716 6.716 0 0 1 442 27.716V65.94H114.72a6.72 6.72 0 0 1-6.72-6.72z",
              fill: _vm.message,
            },
          }),
          _c(
            "text",
            {
              attrs: {
                x: _vm.question.x,
                y: _vm.question.y,
                fill: _vm.messageContrast === "light" ? "#FFFFFF" : "#484848",
                "font-family": "Helvetica",
                "font-size": "17",
                "font-weight": "500",
              },
            },
            [_vm._v(" Gibt es eine Altersgrenze? ")]
          ),
          _c("path", {
            attrs: {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d: "M433.045 65.94H442v13.433z",
              fill: _vm.message,
            },
          }),
        ]
      ),
      _c("div", { staticClass: "rag-ai-container" }, [
        _c("div", { staticClass: "avatar-container" }, [
          _c("img", {
            attrs: {
              src: _vm.avatar,
              width: "44px",
              height: "44px",
              alt: "Avatar",
            },
          }),
        ]),
        _c("div", { staticClass: "chat-container" }, [
          _c(
            "svg",
            {
              staticStyle: { position: "relative", left: "1px" },
              attrs: {
                width: "8",
                height: "11",
                viewBox: "59.701 85.523 8.955 13.432",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  "fill-rule": "evenodd",
                  "clip-rule": "evenodd",
                  d: "M59.701 85.523h8.955v13.432z",
                  fill: "#fff",
                },
              }),
            ]
          ),
          _c("div", { staticClass: "chat-bubbles" }, [
            _c(
              "div",
              {
                staticClass: "chat-bubble",
                class: { "rounded-bottom": !_vm.enabled },
              },
              [
                _c("p", [
                  _vm._v(
                    " Es gibt keine Altersbeschränkung und es ist für alle Altersgruppen geeignet. "
                  ),
                ]),
              ]
            ),
            _vm.enabled
              ? _c(
                  "details",
                  {
                    ref: "details",
                    staticClass: "ai-indicator rounded-bottom",
                    on: { toggle: _vm.handleToggle },
                  },
                  [
                    _c("summary", { staticClass: "ai-generated" }, [
                      _c("span", { staticClass: "ai-badge-container" }, [
                        _c("span", { staticClass: "ai-badge" }, [_vm._v("AI")]),
                        _vm._v(" " + _vm._s(_vm.generatedText) + " "),
                      ]),
                      _c("div", { staticClass: "expand" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.resourcesUsed.length) +
                            " " +
                            _vm._s(_vm.sourcesText) +
                            " "
                        ),
                        _c(
                          "svg",
                          {
                            style: `transform: rotate(${
                              _vm.isOpen ? "0" : "-90"
                            }deg);flex-shrink: 0;`,
                            attrs: {
                              width: "24",
                              height: "24",
                              viewBox: "0 0 24 20",
                              xmlns: "http://www.w3.org/2000/svg",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M14.7846 7.57617L10.7862 11.5659L6.78784 7.57617L5.55957 8.80444L10.7862 14.0311L16.0129 8.80444L14.7846 7.57617Z",
                                fill: "black",
                              },
                            }),
                          ]
                        ),
                      ]),
                    ]),
                    _c(
                      "ol",
                      _vm._l(_vm.resourcesUsed, function (source) {
                        return _c("li", { key: source.source }, [
                          _c("a", { attrs: { rel: "noopener" } }, [
                            _vm._v(" " + _vm._s(_vm.sourceLabel(source)) + " "),
                          ]),
                        ])
                      }),
                      0
                    ),
                  ]
                )
              : _vm._e(),
            _vm.enabled
              ? _c("p", { staticClass: "ai-disclaimer" }, [
                  _vm._v(" " + _vm._s(_vm.disclaimerText) + " "),
                ])
              : _vm._e(),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }