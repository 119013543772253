var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-tab-item",
    {
      staticStyle: { height: "100%" },
      attrs: {
        transition: "none",
        color: "black",
        "reverse-transition": "none",
        value: "config",
      },
    },
    [
      _vm.getChannelID
        ? _c(
            "v-card",
            {
              staticClass: "px-8 pt-4",
              attrs: { color: "#f0f0f0", elevation: "0", height: "100%" },
            },
            [
              _c("h2", [
                _vm._v(_vm._s(_vm.$t("channelSettings.config.tabHeadline"))),
              ]),
              _vm.getCurrentChannelConfig
                ? _c("WidgetConfigFields", {
                    attrs: {
                      config: _vm.getCurrentChannelConfig(_vm.getChannelID),
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }