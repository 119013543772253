var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "VueHtml2pdf",
        {
          ref: "html2Pdf",
          attrs: {
            "show-layout": false,
            "float-layout": true,
            "enable-download": true,
            "preview-modal": false,
            filename: _vm.pdfName,
            "pdf-quality": 2,
            "manual-pagination": true,
            "pdf-format": "a4",
            "pdf-orientation": "landscape",
            "pdf-content-width": "1130px",
          },
          on: { hasPaginated: _vm.exportDone },
        },
        [
          _c(
            "section",
            { attrs: { slot: "pdf-content" }, slot: "pdf-content" },
            [
              _c(
                "v-app",
                { staticClass: "pa-0 ma-0" },
                [
                  _c("StatsPdfCover"),
                  _c("div", { staticClass: "html2pdf__page-break" }),
                  _c(
                    "div",
                    { staticClass: "page-wrap" },
                    [
                      _c(
                        "v-container",
                        { staticClass: "pt-8" },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("StatsChartConversation", {
                                    attrs: { height: _vm.chartHeight },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("StatsChartAutomation", {
                                    attrs: { height: _vm.chartHeight },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "html2pdf__page-break" }),
                  _c(
                    "div",
                    { staticClass: "page-wrap" },
                    [
                      _c(
                        "v-container",
                        { staticClass: "pt-8" },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("StatsChartFeedback", {
                                    attrs: {
                                      positive: "",
                                      height: _vm.chartHeight,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("StatsChartFeedback", {
                                    attrs: {
                                      negative: "",
                                      height: _vm.chartHeight,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "html2pdf__page-break" }),
                  _c(
                    "div",
                    { staticClass: "page-wrap" },
                    [
                      _c(
                        "v-container",
                        { staticClass: "pt-8" },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [_c("StatsChartConversions")],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [_c("StatsChartTakeovers")],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "html2pdf__page-break" }),
                  _c(
                    "div",
                    { staticClass: "page-wrap" },
                    [
                      _c(
                        "v-container",
                        { staticClass: "pt-8" },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [_c("StatsIntents")],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }