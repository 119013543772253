<script>
import HubBoxSimple from '@/components/hub/HubBoxSimple.vue';

export default {
  name: 'LivechatMarketingScreen',
  components: { HubBoxSimple },
  emits: ['cta'],
  props: {
    // Set the hub box state to loading
    "isLoading": {
      type: Boolean,
      default: false,
    },
    // Whether the CTA button is disabled
    "ctaDisabled": {
      type: Boolean,
      default: false,
    },
    // The icon to use for the CTA button
    "ctaIcon": {
      type: String,
      default: 'mdi-package-variant-closed-plus',
    },
    // Object of already translated labels
    "labels": {
      type: Object,
      default: () => ({
        title: "Title (labels.title)",
        description: "Description (labels.description)",
        cta: "CTA (labels.cta)",
      }),
    },
  },
};
</script>

<template>

  <HubBoxSimple
    class="mx-auto pa-6"
    style="width: 100%; max-width: 700px"
    :isLoading="isLoading"
  >
    <template v-slot:content>
      <div>
        <h2 class="text-h5 mb-2">{{ labels.title }}</h2>
        <p class="text-body-1 mb-8">
          {{ labels.description }}
        </p>
        <h3 class="text-h6 secondary--text mb-6">
          {{ $t('settingsLivechat.featurePopup.highlightTitle') }}
        </h3>
        <div class="mb-2 text-body-2">
          <v-icon color="secondary" class="mr-1">
            mdi-check-decagram
          </v-icon>
          {{ $t('settingsLivechat.featurePopup.highlightPoints.startChatFromWidget') }}
        </div>
        <div class="mb-2 text-body-2">
          <v-icon color="secondary" class="mr-1">
            mdi-check-decagram
          </v-icon>
          {{ $t('settingsLivechat.featurePopup.highlightPoints.answerFromHub') }}
        </div>
        <div class="mb-2 text-body-2">
          <v-icon color="secondary" class="mr-1">
            mdi-check-decagram
          </v-icon>
          {{ $t('settingsLivechat.featurePopup.highlightPoints.groupsAndAgents') }}
        </div>
        <div class="mb-2 text-body-2">
          <v-icon color="secondary" class="mr-1">
            mdi-check-decagram
          </v-icon>
          {{ $t('settingsLivechat.featurePopup.highlightPoints.noThirdParty') }}
        </div>

        <div class="d-flex justify-end">
          <v-btn
            class="mt-4"
            depressed
            color="secondary"
            @click="$emit('cta')"
            :disabled="ctaDisabled"
          >
            {{ labels.cta }}
            <v-icon right>{{ ctaIcon }}</v-icon>
          </v-btn>
        </div>
      </div>
    </template>
  </HubBoxSimple>
</template>
