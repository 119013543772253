<template>
  <div class="relative" ref="infoBlock">
    <div
      class="d-flex moin-cursor-pointer"
      @mouseenter="showHideWithDelay(true)"
      @mouseleave="showHideWithDelay(false, 150)"
    >
      <v-icon
        v-if="mainIconShow"
        class="box"
        :class=" bounceAnimation ? 'bounce' : ''"
        :color="mainIconColor"
      >{{mainIcon}}</v-icon>

      <!-- Slot for your needs. Use case:   -->
      <slot />
    </div>

    <div
      v-show="hovering"
      class="info-box"
      ref="infoBox"
      @mouseenter="showHideWithDelay(true)"
      :style="{
        width: `${width}px`,
        top: `${top}px`,
        left: `${left ?? -width/2}px`,
      }"
      @mouseleave="showHideWithDelay(false, 150)"
    >
      <InfoBoxOutline
        class="mb-0"
        block
        :title="title"
        :body="body"
        :color-classes="iconColor"
        :icon="icon"
      />
    </div>
  </div>

</template>

<script>
import InfoBoxOutline from '@/components/common/InfoBoxOutline.vue';

export default {
  name: 'InfoHoverBox',
  components: {
    InfoBoxOutline,
  },
  props: {
    // < - - Banner settings - - >
    top: {
      type: Number,
      default: -80,
    },
    left: {
      type: Number,
      default: 0,
    },

    translateUp: {
      type: Number,
      default: -10,
    },
    translateBottom: {
      type: Number,
      default: 40,
    },

    /** Width of the banner dialog */
    width: {
      type: Number,
      default: 400,
    },
    // < - - END of Banner settings - - >

    /** Amount of 'px' limit to TOP of window view. F.e. Limit is 200. To the top of page is 150px => then Banner will be displayed under ICON [Anchor] */
    maxHeightToTop: {
      type: Number,
      default: 150,
    },
    /** If available add extra space to the right */
    maxWidthToRight: {
      type: Number,
      default: 20,
    },

    // More icons here: https://pictogrammers.github.io/@mdi/font/2.0.46/
    mainIconShow: {
      type: Boolean,
      default: true,
    },
    mainIcon: {
      type: String,
      default: 'mdi-lightbulb',
    },
    mainIconColor: {
      type: String,
      default: 'primary--text',
    },
    bounceAnimation: {
      type: Boolean,
      default: false,
    },

    title: {
      type: String,
      default: '',
    },
    body: {
      type: String,
      default: '...',
    },
    icon: {
      type: String,
      default: 'mdi-lightbulb',
    },
    iconColor: {
      type: String,
      default: 'secondary',
    },
  },
  data() {
    return {
      hovering: false,
      lastX: null,
      windowWidth: window.innerWidth,

      hoverTimeout: null,
    };
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth
    },
    /**
     * This function allows to move mouse over opened banner and copy text if it is needed.
     * @param {boolean} show - Show or Hide dialog banner
     * @param {*} delay - delay before Show or Hide
     */
    showHideWithDelay(show = true, delay = 0) {
      if (show) clearTimeout(this.hoverTimeout);

      this.hoverTimeout = setTimeout(() => {
        this.hovering = show;
        if (show) this.showAtProperPosition();
      }, delay);
    },
    /**
     * Calculates current position to properly display opened [info] banner at the page.
     * Before getBoundingClientRect() item must be displayed as block. Because in hidden - all values will be equal to 0.
     */
    showAtProperPosition() {
      if (this.lastX === null) this.lastX = this.left;
      this.$refs.infoBox.style.visibility = 'hidden';
      this.$refs.infoBox.style.display = 'block';

      /**
       * Icon is Anchor. Based on it's Y position => Banner will be displayed Above / Under it.
       */
      const iconPosition = this.$refs.infoBlock?.getBoundingClientRect();
      const boxPosition = this.$refs.infoBox?.getBoundingClientRect();
      const boxHeight = boxPosition.height;
      const boxLeft = boxPosition.x;
      console.log(boxPosition);

      console.log(iconPosition.top);
      console.log(this.maxHeightToTop, boxHeight, '= ', this.maxHeightToTop + boxHeight);
      console.log(' ');

      // Positioning 1 way
      const infoTop = iconPosition.top < this.maxHeightToTop + boxHeight
        ? this.translateBottom
        : this.translateUp - boxHeight;

      this.$refs.infoBox.style.top = `${infoTop}px`;

      // Positioning 2 way
      // if (iconPosition.top < this.maxHeightToTop) {
      //   this.$refs.infoBox.style.remove("up");
      //   this.$refs.infoBox.style.bottom = `${this.translateUp}px`;
      // } else {
      //   this.$refs.infoBox.style.top = `${this.translateBottom}px`;
      //   this.$refs.infoBox.style.remove("bottom");
      // }

      // In case if window is too small => Display on center
      let x_transition = this.windowWidth + (boxLeft - this.windowWidth) >= 0 ? boxLeft - this.windowWidth : this.lastX

      // If window view is big enough => calculate position
      if (this.windowWidth - (this.width) >= 0) {
        const widthPlusPosition = Number(boxLeft + this.width + this.maxWidthToRight).toFixed(); // container_start_x + container_width + margin_right
        const fitsInWindow =  this.windowWidth > widthPlusPosition + this.left ||
          this.windowWidth >= this.width + this.maxWidthToRight + iconPosition.x + this.left;

        const traslate_x = fitsInWindow
          ? this.left
          : this.lastX + (this.windowWidth - (boxLeft + this.width + this.maxWidthToRight));

        x_transition = traslate_x;
      }

      this.lastX = x_transition;

      this.$refs.infoBox.style.left = `${x_transition}px`;
      this.$refs.infoBox.style.visibility = 'visible';
    },
    setHoverTimeout(state) {
      setTimeout(() => this.hovering = state, 1000);
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },
  beforeDestroy() { 
    window.removeEventListener('resize', this.onResize); 
  },
};
</script>
<style scoped>
.info-box {
  display: block;
  position: absolute;
  background-color: white;
  z-index: 1;
  /* z-index: 1000; */
}

.box {
  display: flex;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  /* margin: 0 auto 0 auto; */
}

.ml-2 {
  position: relative;
}

.bounce {
  animation-name: bounce;
  animation-timing-function: ease;
}

@keyframes bounce {
  0%   { transform: scale(1,1)    translateY(0); }
  10%  { transform: scale(1.1,0.9) translateY(0); }
  30%  { transform: scale(0.9,1.1) translateY(-10px); }
  50%  { transform: scale(1,1)    translateY(0); }
  100% { transform: scale(1,1)    translateY(0); }
}

</style>
