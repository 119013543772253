<script>
import NumberTeaserUtil from '@/utils/numberTeaser';

export default {
  name: 'StatsChangeIndicator',
  props: {
    "newValue": {
      type: Number,
      required: true,
      default: 0,
    },
    "oldValue": {
      type: Number,
      required: true,
      default: 0,
    },
    "isPercent": {
      type: Boolean,
      default: false,
    },
    "lessIsBetter": {
      type: Boolean,
      default: false,
    },
    "isNeutral": {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    changedPercent() {
      return NumberTeaserUtil.getChangedPercent({
        oldValue: this.oldValue,
        newValue: this.newValue,
        isPercent: this.isPercent,
      });
    },
    changedToolTip() {
      const {
        change,
        changeString,
        beforeString,
      } = NumberTeaserUtil.getChangedStrings({
        oldValue: this.oldValue,
        newValue: this.newValue,
        isPercent: this.isPercent,
      });

      if (change > 0) {
        return this.$t('stats.iconNumberTeaser.tooltipMore', {
          change: changeString,
          before: beforeString,
        });
      } else {
        return this.$t('stats.iconNumberTeaser.tooltipLess', {
          change: changeString,
          before: beforeString,
        });
      }
    },
    colorUp() {
      if (this.isNeutral) return 'grey lighten-1';
      return this.lessIsBetter ? 'green lighten-1' : 'red lighten-1';
    },
    colorDown() {
      if (this.isNeutral) return 'grey lighten-1';
      return this.lessIsBetter ? 'red lighten-1' : 'green lighten-1';
    },
  }
};
</script>

<template>
  <v-tooltip bottom>
    <template #activator="{ on: onTooltip, attrs: attrsTooltip }">
      <div
        class="d-flex justify-center"
        v-on="onTooltip"
        v-bind="attrsTooltip"
      >
        <v-icon
          v-if="newValue < oldValue"
          size="20"
          :color="colorUp"
        >
          arrow_downward
        </v-icon>
        <v-icon
          v-if="newValue === oldValue"
          size="20"
          color="primary lighten-2"
        >
          arrow_forward
        </v-icon>
        <v-icon
          v-if="newValue > oldValue"
          size="20"
          :color="colorDown"
        >
          arrow_upward
        </v-icon>
        <span class="primary--text">
          {{ changedPercent }}
        </span>
      </div>
    </template>
    <span>{{ changedToolTip }}</span>
  </v-tooltip>
</template>
