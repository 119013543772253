// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/fonts/JosefinSans-VariableFont_wght.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("@/assets/fonts/Lato-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("@/assets/fonts/silka-semibold-webfont.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: \"Josefin Sans\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n@font-face {\n  font-family: \"Lato\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n@font-face {\n  font-family: \"silkaHeading\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n.floating[data-v-70d4f7a2] {\n  -webkit-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.1), 0 4px 5px 0 rgba(0, 0, 0, 0.07), 0 1px 10px 0 rgba(0, 0, 0, 0.06);\n          box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.1), 0 4px 5px 0 rgba(0, 0, 0, 0.07), 0 1px 10px 0 rgba(0, 0, 0, 0.06);\n}\n.wpp-btn[data-v-70d4f7a2] {\n  position: absolute;\n  bottom: 44px;\n  right: 36px;\n  padding: 8px 20px;\n  border-radius: 20px;\n  font-weight: 600;\n  cursor: pointer;\n}\n.wpp-texts[data-v-70d4f7a2] {\n  position: absolute;\n  top: 304px;\n  left: 36px;\n  right: 36px;\n  font-family: Helvetica;\n}\n.wpp-greeting[data-v-70d4f7a2] {\n  font-size: 20px;\n  font-weight: 500;\n  margin-bottom: 16px;\n  text-align: center;\n}\n.wpp-text[data-v-70d4f7a2] {\n  font-size: 16px;\n  text-align: left;\n  margin-bottom: 16px;\n}\n.wpp-text-link[data-v-70d4f7a2] {\n  font-size: 16px;\n  text-align: left;\n  margin-bottom: 16px;\n}\n.wpp-text-link a[data-v-70d4f7a2] {\n  color: inherit;\n  text-decoration: underline;\n}\n.wpp-avatar[data-v-70d4f7a2] {\n  position: absolute;\n  top: 160px;\n  left: 50%;\n  margin-left: -48px;\n  height: 96px;\n  width: 96px;\n  overflow: hidden;\n  border-radius: 50%;\n}\n.wpp-avatar-img[data-v-70d4f7a2] {\n  height: 100%;\n  width: 100%;\n  -o-object-fit: cover;\n     object-fit: cover;\n}\n.wpp-avatar-ring[data-v-70d4f7a2] {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  border: 4px solid #ffffff;\n  border-radius: 50%;\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
