var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "HubBox",
    {
      attrs: {
        title: _vm.$t("forms.generalEdit.title"),
        boxId: "forms-general",
        isLoading: _vm.isLoading,
      },
    },
    [
      _c("div", { staticClass: "text-body-2" }, [
        _vm._v(_vm._s(_vm.$t("forms.generalEdit.description"))),
      ]),
      _c("div", { staticClass: "mt-4" }, [
        _c(
          "div",
          { staticClass: "primary--text text--lighten-3 mb-1 text-body-2" },
          [_vm._v(" " + _vm._s(_vm.$t("forms.generalEdit.displayName")) + " ")]
        ),
        _c("div", [_vm._v(_vm._s(_vm.form.displayName))]),
      ]),
      _c(
        "div",
        { staticClass: "mt-6" },
        [
          _c(
            "v-btn",
            {
              attrs: { outlined: "", color: "primary lighten-3" },
              on: {
                click: function ($event) {
                  _vm.editDialogOpen = true
                },
              },
            },
            [
              _vm._v(" " + _vm._s(_vm.$t("common.edit")) + " "),
              _c("v-icon", { attrs: { right: "" } }, [_vm._v("edit")]),
            ],
            1
          ),
          _vm.isAdmin
            ? _c("BtnSquare", {
                staticClass: "ml-2",
                attrs: {
                  colorBtn: "admin",
                  colorIcon: "admin",
                  icon: "mdi-trash-can",
                },
                on: {
                  click: function ($event) {
                    _vm.deleteDialogOpen = true
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "HubDialog",
        {
          attrs: {
            value: _vm.editDialogOpen,
            "primary-label": _vm.$t("common.save"),
            "primary-icon": "save",
            "secondary-label": _vm.$t("common.cancel"),
            "secondary-icon": "close",
            title: _vm.$t("common.edit"),
            modal: "",
          },
          on: {
            onPrimary: function ($event) {
              return _vm.updateForm()
            },
            onSecondary: function ($event) {
              return _vm.cancelEdit()
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "v-row",
                { staticClass: "mt-4 mb-2" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-text-field", {
                        staticClass: "mb-2",
                        attrs: {
                          outlined: "",
                          color: "secondary darken-2",
                          autofocus: "",
                          "hide-details": "",
                        },
                        model: {
                          value: _vm.displayName,
                          callback: function ($$v) {
                            _vm.displayName = $$v
                          },
                          expression: "displayName",
                        },
                      }),
                      _c("InfoBox", {
                        attrs: {
                          text: _vm.$t("forms.generalEdit.hint"),
                          icon: null,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm.isAdmin
        ? _c(
            "HubDialog",
            {
              attrs: {
                value: _vm.deleteDialogOpen,
                "primary-label": _vm.$t("common.delete"),
                "primary-icon": "save",
                "secondary-label": _vm.$t("common.cancel"),
                "secondary-icon": "close",
                title: "Formular löschen",
                modal: "",
              },
              on: {
                onPrimary: function ($event) {
                  return _vm.deleteForm()
                },
                onSecondary: function ($event) {
                  return _vm.cancelDelete()
                },
              },
            },
            [
              _c(
                "div",
                [
                  _c(
                    "v-row",
                    { staticClass: "mt-4 mb-4" },
                    [
                      _c("v-col", { attrs: { cols: "12" } }, [
                        _c("div", { staticClass: "text-copy-1 mb-2" }, [
                          _vm._v(
                            " Bist du sicher, dass du das Formular löschen möchtest? Das Formular wird unwiderruflich gelöscht. "
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }