var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "white" },
    [
      _c(
        "v-container",
        { staticClass: "pa-6", staticStyle: { "max-width": "1137px" } },
        [
          _vm.botHasLivechat && _vm.agent
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("HubBoxSimple", {
                        attrs: { title: "Livechat Profil" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "content",
                              fn: function () {
                                return [
                                  _c("LivechatProfile", {
                                    ref: "livechatProfileRef",
                                    attrs: { loading: _vm.isLoading },
                                    on: {
                                      onDirty: function ($event) {
                                        _vm.livechatDirty = $event
                                      },
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          1456152507
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: _vm.currentBotContactId ? 6 : 12 } },
                [
                  _c("HubBoxSimple", {
                    attrs: { title: _vm.$t("userRoles.title"), fillHeight: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "content",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              { staticClass: "pb-4" },
                              [
                                _c(
                                  "v-chip-group",
                                  { staticClass: "mb-2" },
                                  [
                                    _c(
                                      "v-chip",
                                      {
                                        staticStyle: { cursor: "default" },
                                        attrs: { ripple: false },
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              color: "primary lighten-2",
                                              size: "20",
                                              left: "",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.userRole.icon) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _vm._v(
                                          " " + _vm._s(_vm.userRole.title) + " "
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-body-2 grey--text text--darken-2",
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.userRole.info) + " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "div",
                                              _vm._g(
                                                _vm._b({}, "div", attrs, false),
                                                on
                                              ),
                                              [
                                                _c("v-switch", {
                                                  staticClass:
                                                    "moin-cursor-default",
                                                  attrs: {
                                                    inset: "",
                                                    color: "green",
                                                    dense: "",
                                                    label:
                                                      _vm.$t(
                                                        "2factorAuth.label"
                                                      ),
                                                    readonly: "",
                                                  },
                                                  model: {
                                                    value: _vm.is2FA,
                                                    callback: function ($$v) {
                                                      _vm.is2FA = $$v
                                                    },
                                                    expression: "is2FA",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("2factorAuth.tooltip")
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm.currentBotContactId
                ? _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("HubBoxSimple", {
                        attrs: {
                          title: _vm.$t("welcome.supportContact.live"),
                          fillHeight: "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "content",
                              fn: function () {
                                return [
                                  _c("ContactItem", {
                                    attrs: {
                                      "contact-id": _vm.currentBotContactId,
                                      large: "",
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          2725140398
                        ),
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("HubBoxSimple", {
                    attrs: { title: _vm.$t("common.hubLanguage") },
                    scopedSlots: _vm._u([
                      {
                        key: "content",
                        fn: function () {
                          return [
                            _c("v-select", {
                              attrs: {
                                items: _vm.languageOptions,
                                "item-text": "text",
                                "item-value": "value",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "item",
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-2",
                                          attrs: { small: "" },
                                        },
                                        [_vm._v("mdi-web")]
                                      ),
                                      _vm._v(" " + _vm._s(item.text) + " "),
                                    ]
                                  },
                                },
                                {
                                  key: "selection",
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mr-2",
                                              attrs: {
                                                small: "",
                                                color: item.state,
                                              },
                                            },
                                            [_vm._v(" mdi-web ")]
                                          ),
                                          _vm._v(" " + _vm._s(item.text) + " "),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                              model: {
                                value: _vm.selectedLanguage,
                                callback: function ($$v) {
                                  _vm.selectedLanguage = $$v
                                },
                                expression: "selectedLanguage",
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("HubBoxSimple", {
                    attrs: { title: _vm.$t("common.eMailAddress") },
                    scopedSlots: _vm._u([
                      {
                        key: "content",
                        fn: function () {
                          return [
                            _c("v-text-field", {
                              attrs: {
                                label: _vm.$t("common.eMailAddress"),
                                value: _vm.user.username,
                                type: "email",
                                "persistent-hint": "",
                                readonly: "",
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          false
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("HubBoxSimple", {
                        attrs: { title: "Kontakt Daten" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "content",
                              fn: function () {
                                return [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Vorname",
                                      "persistent-hint": "",
                                    },
                                  }),
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Nachname",
                                      "persistent-hint": "",
                                    },
                                  }),
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Telefon-Nr.",
                                      "persistent-hint": "",
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          4026187464
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          false
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("HubBoxSimple", {
                        attrs: { title: "E-Mail Benachrichtigungen" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "content",
                              fn: function () {
                                return [
                                  _c("v-switch", {
                                    attrs: {
                                      inset: "",
                                      color: "green",
                                      dense: "",
                                      label:
                                        "Benachrichtigungen per E-Mail erhalten.",
                                    },
                                  }),
                                  _c("v-switch", {
                                    attrs: {
                                      inset: "",
                                      color: "green",
                                      dense: "",
                                      label:
                                        "Themen-Vorschläge per E-Mail erhalten.",
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          4009337381
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }