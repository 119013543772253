<script>
import LivechatSpecialViewWrapper from '@/components/livechat/special-views/LivechatSpecialViewWrapper.vue';
import LivechatStateCallout from '@/components/livechat/special-views/LivechatStateCallout.vue';

export default {
  name: 'LivechatAgentWrongOrgView',
  components: { LivechatStateCallout, LivechatSpecialViewWrapper },
  props: {
    "canManageAgents": {
      type: Boolean,
      default: false,
    }
  },
};
</script>

<template>
  <LivechatSpecialViewWrapper id="agent-in-other-org">
    <!-- Header content: Decoration, "Not an agent here" -->
    <template>
      <LivechatStateCallout
        class="mb-4"
        classes="text--lighten-2 primary--text"
        :title="$t('livechat.screens.wrongOrganization.notAgentHere')"
        icon="mdi-face-agent"
        :description="$t('livechat.screens.wrongOrganization.notAgentHereDescription')"
      />
    </template>

    <!-- Secondary container: Information and CTA -->
    <template #secondary>
      <h3 class="tet-h3 mb-2">{{ $t('livechat.screens.wrongOrganization.title') }}</h3>
      <p class="text-body-1 mb-8">
        {{ $t('livechat.screens.wrongOrganization.description') }}
      </p>
      <div v-if="canManageAgents" class="d-flex mt-3 justify-end">
        <v-btn
          color="secondary"
          :to="{ name: 'settings-livechat' }"
          depressed
        >
          {{ $t('livechat.manageAgents') }}
          <v-icon class="ml-2">settings</v-icon>
        </v-btn>
      </div>
    </template>
  </LivechatSpecialViewWrapper>
</template>