var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { staticStyle: { "max-height": "100vh", height: "100vh" } },
    [
      _c("HubMenu"),
      _c("Appbar"),
      _c("HubContent", {
        attrs: { isSidebarOpen: _vm.isSidebarOpen },
        scopedSlots: _vm._u([
          {
            key: "navbar",
            fn: function () {
              return [
                _c("HubContentNavbar", {
                  attrs: { title: _vm.$t("botNotifications.title") },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "sidebar",
            fn: function () {
              return [
                _c(
                  "HubContentSidebarSimple",
                  {
                    attrs: {
                      isOpen: _vm.isSidebarOpen,
                      title: _vm.$t("botNotifications.resendTitle"),
                      icon: "info",
                    },
                    on: { onClose: _vm.closeSidebar },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "pa-4" },
                      [
                        _c("BotNotificationsResend", {
                          attrs: { notification: _vm.selectedNotification },
                          on: { onResend: _vm.resendNotification },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "content",
            fn: function () {
              return [
                _c("BotNotificationsTable", {
                  attrs: {
                    notifications: _vm.notifications,
                    isLoading: _vm.isLoading,
                  },
                  on: { onResendNotification: _vm.openResendNotification },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }