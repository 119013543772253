const countryLanguageMixin = {
    data() {
        return {
            countries: [
                { "name": "Afghanistan", "country": "AF", "language": "ps, fa" },
                { "name": "Albania", "country": "AL", "language": "sq" },
                { "name": "Algeria", "country": "DZ", "language": "ar" },
                { "name": "Andorra", "country": "AD", "language": "ca" },
                { "name": "Angola", "country": "AO", "language": "pt" },
                { "name": "Antigua and Barbuda", "country": "AG", "language": "en" },
                { "name": "Argentina", "country": "AR", "language": "es" },
                { "name": "Armenia", "country": "AM", "language": "hy" },
                { "name": "Australia", "country": "AU", "language": "en" },
                { "name": "Austria", "country": "AT", "language": "de" },
                { "name": "Azerbaijan", "country": "AZ", "language": "az" },
                { "name": "Bahamas", "country": "BS", "language": "en" },
                { "name": "Bahrain", "country": "BH", "language": "ar" },
                { "name": "Bangladesh", "country": "BD", "language": "bn" },
                { "name": "Barbados", "country": "BB", "language": "en" },
                { "name": "Belarus", "country": "BY", "language": "be, ru" },
                { "name": "Belgium", "country": "BE", "language": "nl, fr, de" },
                { "name": "Belize", "country": "BZ", "language": "en" },
                { "name": "Benin", "country": "BJ", "language": "fr" },
                { "name": "Bhutan", "country": "BT", "language": "dz" },
                { "name": "Bolivia", "country": "BO", "language": "es" },
                { "name": "Bosnia and Herzegovina", "country": "BA", "language": "bs, hr, sr" },
                { "name": "Botswana", "country": "BW", "language": "en" },
                { "name": "Brazil", "country": "BR", "language": "pt" },
                { "name": "Brunei", "country": "BN", "language": "ms" },
                { "name": "Bulgaria", "country": "BG", "language": "bg" },
                { "name": "Burkina Faso", "country": "BF", "language": "fr" },
                { "name": "Burundi", "country": "BI", "language": "rn, fr" },
                { "name": "Cabo Verde", "country": "CV", "language": "pt" },
                { "name": "Cambodia", "country": "KH", "language": "km" },
                { "name": "Cameroon", "country": "CM", "language": "fr, en" },
                { "name": "Canada", "country": "CA", "language": "en, fr" },
                { "name": "Catalonia", "country": "ES-CA", "language": "ca" },
                { "name": "Central African Republic", "country": "CF", "language": "fr, sg" },
                { "name": "Chad", "country": "TD", "language": "fr, ar" },
                { "name": "Chile", "country": "CL", "language": "es" },
                { "name": "China", "country": "CN", "language": "zh" },
                { "name": "Colombia", "country": "CO", "language": "es" },
                { "name": "Comoros", "country": "KM", "language": "ar, fr, km" },
                { "name": "Congo", "country": "CG", "language": "fr" },
                { "name": "Costa Rica", "country": "CR", "language": "es" },
                { "name": "Croatia", "country": "HR", "language": "hr" },
                { "name": "Cuba", "country": "CU", "language": "es" },
                { "name": "Cyprus", "country": "CY", "language": "el, tr" },
                { "name": "Czech Republic", "country": "CZ", "language": "cs" },
                { "name": "Denmark", "country": "DK", "language": "da" },
                { "name": "Djibouti", "country": "DJ", "language": "ar, fr" },
                { "name": "Dominica", "country": "DM", "language": "en" },
                { "name": "Dominican Republic", "country": "DO", "language": "es" },
                { "name": "Ecuador", "country": "EC", "language": "es" },
                { "name": "Egypt", "country": "EG", "language": "ar" },
                { "name": "El Salvador", "country": "SV", "language": "es" },
                { "name": "Equatorial Guinea", "country": "GQ", "language": "es, fr, pt" },
                { "name": "Eritrea", "country": "ER", "language": "ti, ar, en" },
                { "name": "Estonia", "country": "EE", "language": "et" },
                { "name": "Eswatini", "country": "SZ", "language": "en, ss" },
                { "name": "Ethiopia", "country": "ET", "language": "am" },
                { "name": "Fiji", "country": "FJ", "language": "en, fj, hi" },
                { "name": "Finland", "country": "FI", "language": "fi, sv" },
                { "name": "France", "country": "FR", "language": "fr" },
                { "name": "Gabon", "country": "GA", "language": "fr" },
                { "name": "Gambia", "country": "GM", "language": "en" },
                { "name": "Georgia", "country": "GE", "language": "ka" },
                { "name": "Germany", "country": "DE", "language": "de" },
                { "name": "Ghana", "country": "GH", "language": "en" },
                { "name": "Greece", "country": "GR", "language": "el" },
                { "name": "Grenada", "country": "GD", "language": "en" },
                { "name": "Guatemala", "country": "GT", "language": "es" },
                { "name": "Guinea", "country": "GN", "language": "fr" },
                { "name": "Guinea-Bissau", "country": "GW", "language": "pt" },
                { "name": "Guyana", "country": "GY", "language": "en" },
                { "name": "Haiti", "country": "HT", "language": "ht, fr" },
                { "name": "Honduras", "country": "HN", "language": "es" },
                { "name": "Hungary", "country": "HU", "language": "hu" },
                { "name": "Iceland", "country": "IS", "language": "is" },
                { "name": "India", "country": "IN", "language": "hi, en" },
                { "name": "Indonesia", "country": "ID", "language": "id" },
                { "name": "Iran", "country": "IR", "language": "fa" },
                { "name": "Iraq", "country": "IQ", "language": "ar, ku" },
                { "name": "Ireland", "country": "IE", "language": "ga, en" },
                { "name": "Israel", "country": "IL", "language": "he" },
                { "name": "Italy", "country": "IT", "language": "it" },
                { "name": "Jamaica", "country": "JM", "language": "en" },
                { "name": "Japan", "country": "JP", "language": "ja" },
                { "name": "Jordan", "country": "JO", "language": "ar" },
                { "name": "Kazakhstan", "country": "KZ", "language": "kk, ru" },
                { "name": "Kenya", "country": "KE", "language": "sw, en" },
                { "name": "Kiribati", "country": "KI", "language": "en" },
                { "name": "Kuwait", "country": "KW", "language": "ar" },
                { "name": "Kyrgyzstan", "country": "KG", "language": "ky, ru" },
                { "name": "Laos", "country": "LA", "language": "lo" },
                { "name": "Latvia", "country": "LV", "language": "lv" },
                { "name": "Lebanon", "country": "LB", "language": "ar, fr" },
                { "name": "Lesotho", "country": "LS", "language": "en, st" },
                { "name": "Liberia", "country": "LR", "language": "en" },
                { "name": "Libya", "country": "LY", "language": "ar" },
                { "name": "Liechtenstein", "country": "LI", "language": "de" },
                { "name": "Lithuania", "country": "LT", "language": "lt" },
                { "name": "Luxembourg", "country": "LU", "language": "lb, de, fr" },
                { "name": "Madagascar", "country": "MG", "language": "mg, fr" },
                { "name": "Malawi", "country": "MW", "language": "ny, en" },
                { "name": "Malaysia", "country": "MY", "language": "ms" },
                { "name": "Maldives", "country": "MV", "language": "dv" },
                { "name": "Mali", "country": "ML", "language": "fr" },
                { "name": "Malta", "country": "MT", "language": "mt, en" },
                { "name": "Marshall Islands", "country": "MH", "language": "en, mh" },
                { "name": "Mauritania", "country": "MR", "language": "ar, fr" },
                { "name": "Mauritius", "country": "MU", "language": "en, fr" },
                { "name": "Mexico", "country": "MX", "language": "es" },
                { "name": "Micronesia", "country": "FM", "language": "en" },
                { "name": "Moldova", "country": "MD", "language": "ro" },
                { "name": "Monaco", "country": "MC", "language": "fr" },
                { "name": "Mongolia", "country": "MN", "language": "mn" },
                { "name": "Montenegro", "country": "ME", "language": "sr, sq" },
                { "name": "Morocco", "country": "MA", "language": "ar, fr" },
                { "name": "Mozambique", "country": "MZ", "language": "pt" },
                { "name": "Myanmar", "country": "MM", "language": "my" },
                { "name": "Namibia", "country": "NA", "language": "en, af" },
                { "name": "Nauru", "country": "NR", "language": "en, na" },
                { "name": "Nepal", "country": "NP", "language": "ne" },
                { "name": "Netherlands", "country": "NL", "language": "nl" },
                { "name": "New Zealand", "country": "NZ", "language": "en, mi" },
                { "name": "Nicaragua", "country": "NI", "language": "es" },
                { "name": "Niger", "country": "NE", "language": "fr" },
                { "name": "Nigeria", "country": "NG", "language": "en" },
                { "name": "North Korea", "country": "KP", "language": "ko" },
                { "name": "North Macedonia", "country": "MK", "language": "mk" },
                { "name": "Norway", "country": "NO", "language": "no, nb, nn" },
                { "name": "Oman", "country": "OM", "language": "ar" },
                { "name": "Pakistan", "country": "PK", "language": "ur, en" },
                { "name": "Palau", "country": "PW", "language": "en" },
                { "name": "Palestine", "country": "PS", "language": "ar" },
                { "name": "Panama", "country": "PA", "language": "es" },
                { "name": "Papua New Guinea", "country": "PG", "language": "en, tp" },
                { "name": "Paraguay", "country": "PY", "language": "es, gn" },
                { "name": "Peru", "country": "PE", "language": "es" },
                { "name": "Philippines", "country": "PH", "language": "tl" },
                { "name": "Poland", "country": "PL", "language": "pl" },
                { "name": "Portugal", "country": "PT", "language": "pt" },
                { "name": "Qatar", "country": "QA", "language": "ar" },
                { "name": "Romania", "country": "RO", "language": "ro" },
                { "name": "Russia", "country": "RU", "language": "ru" },
                { "name": "Rwanda", "country": "RW", "language": "rw, en, fr" },
                { "name": "Saint Kitts and Nevis", "country": "KN", "language": "en" },
                { "name": "Saint Lucia", "country": "LC", "language": "en" },
                { "name": "Saint Vincent and the Grenadines", "country": "VC", "language": "en" },
                { "name": "Samoa", "country": "WS", "language": "sm, en" },
                { "name": "San Marino", "country": "SM", "language": "it" },
                { "name": "Sao Tome and Principe", "country": "ST", "language": "pt" },
                { "name": "Saudi Arabia", "country": "SA", "language": "ar" },
                { "name": "Senegal", "country": "SN", "language": "fr" },
                { "name": "Serbia", "country": "RS", "language": "sr" },
                { "name": "Seychelles", "country": "SC", "language": "en, fr" },
                { "name": "Sierra Leone", "country": "SL", "language": "en" },
                { "name": "Singapore", "country": "SG", "language": "en, ms, ta, zh" },
                { "name": "Slovakia", "country": "SK", "language": "sk" },
                { "name": "Slovenia", "country": "SI", "language": "sl" },
                { "name": "Solomon Islands", "country": "SB", "language": "en" },
                { "name": "Somalia", "country": "SO", "language": "so, ar" },
                { "name": "South Africa", "country": "ZA", "language": "zu, xh, af, en, nr, st, tn, ts, ve" },
                { "name": "South Korea", "country": "KR", "language": "ko" },
                { "name": "South Sudan", "country": "SS", "language": "ar, en, nus" },
                { "name": "Spain", "country": "ES", "language": "es" },
                { "name": "Sri Lanka", "country": "LK", "language": "si, ta" },
                { "name": "Sudan", "country": "SD", "language": "ar, en" },
                { "name": "Suriname", "country": "SR", "language": "nl" },
                { "name": "Sweden", "country": "SE", "language": "sv" },
                { "name": "Switzerland", "country": "CH", "language": "de, fr, it, rm" },
                { "name": "Syria", "country": "SY", "language": "ar" },
                { "name": "Tajikistan", "country": "TJ", "language": "tg, ru" },
                { "name": "Tanzania", "country": "TZ", "language": "sw, en" },
                { "name": "Thailand", "country": "TH", "language": "th" },
                { "name": "Timor-Leste", "country": "TL", "language": "pt, tet" },
                { "name": "Togo", "country": "TG", "language": "fr" },
                { "name": "Tonga", "country": "TO", "language": "en, to" },
                { "name": "Trinidad and Tobago", "country": "TT", "language": "en" },
                { "name": "Tunisia", "country": "TN", "language": "ar" },
                { "name": "Turkey", "country": "TR", "language": "tr" },
                { "name": "Turkmenistan", "country": "TM", "language": "tk, ru" },
                { "name": "Tuvalu", "country": "TV", "language": "en" },
                { "name": "Uganda", "country": "UG", "language": "en, sw" },
                { "name": "Ukraine", "country": "UA", "language": "uk" },
                { "name": "United Arab Emirates", "country": "AE", "language": "ar" },
                { "name": "United Kingdom", "country": "GB", "language": "en" },
                { "name": "United States", "country": "US", "language": "en" },
                { "name": "Uruguay", "country": "UY", "language": "es" },
                { "name": "Uzbekistan", "country": "UZ", "language": "uz, ru" },
                { "name": "Vanuatu", "country": "VU", "language": "bi, en, fr" },
                { "name": "Vatican City", "country": "VA", "language": "la, it" },
                { "name": "Venezuela", "country": "VE", "language": "es" },
                { "name": "Vietnam", "country": "VN", "language": "vi" },
                { "name": "Yemen", "country": "YE", "language": "ar" },
                { "name": "Zambia", "country": "ZM", "language": "en, bem" },
                { "name": "Zimbabwe", "country": "ZW", "language": "en, sn" }
            ],
            languages: [ // not supported languages are commented out
                { "name": "العربية", "language": "ar", "country": "SA" },
                { "name": "Български", "language": "bg", "country": "BG" },
                // { "name": "Català", "language": "ca", "country": "ES-CA" },
                // { "name": "Hrvatski", "language": "hr", "country": "HR" },
                { "name": "Čeština", "language": "cs", "country": "CZ" },
                { "name": "Dansk", "language": "da", "country": "DK" },
                { "name": "Nederlands", "language": "nl", "country": "NL" },
                { "name": "English", "language": "en", "country": "GB" },
                { "name": "Eesti", "language": "et", "country": "EE" },
                { "name": "Suomi", "language": "fi", "country": "FI" },
                { "name": "Français", "language": "fr", "country": "FR" },
                { "name": "Deutsch", "language": "de", "country": "DE" },
                { "name": "Ελληνικά", "language": "el", "country": "GR" },
                { "name": "Magyar", "language": "hu", "country": "HU" },
                // { "name": "Íslenska", "language": "is", "country": "IS" },
                // { "name": "Gaeilge", "language": "ga", "country": "IE" },
                { "name": "Italiano", "language": "it", "country": "IT" },
                { "name": "Latviešu", "language": "lv", "country": "LV" },
                { "name": "Lietuvių", "language": "lt", "country": "LT" },
                // { "name": "Malti", "language": "mt", "country": "MT" },
                { "name": "Norsk Bokmål", "language": "nb", "country": "NO" },
                { "name": "Polski", "language": "pl", "country": "PL" },
                { "name": "Português", "language": "pt", "country": "PT" },
                { "name": "Română", "language": "ro", "country": "RO" },
                { "name": "Русский", "language": "ru", "country": "RU" },
                { "name": "Slovenčina", "language": "sk", "country": "SK" },
                { "name": "Slovenščina", "language": "sl", "country": "SI" },
                { "name": "Español", "language": "es", "country": "ES" },
                { "name": "Svenska", "language": "sv", "country": "SE" },
                { "name": "Türkçe", "language": "tr", "country": "TR" },
                { "name": "Українська", "language": "uk", "country": "UA" },
                { "name": "中文", "language": "zh", "country": "CN" },
                // { "name": "हिन्दी", "language": "hi", "country": "IN" },
                // { "name": "Bengali", "language": "bn", "country": "BD" },
                { "name": "日本語", "language": "ja", "country": "JP" },
                // { "name": "فارسی", "language": "fa", "country": "IR" },
                { "name": "한국어", "language": "ko", "country": "KR" },
                // { "name": "اردو", "language": "ur", "country": "PK" },
                // { "name": "ไทย", "language": "th", "country": "TH" },
                // { "name": "മലയാളം", "language": "ml", "country": "IN" },
                // { "name": "ਨੇਪਾਲੀ", "language": "ne", "country": "NP" },
                // { "name": "Српски", "language": "sr", "country": "RS" },
                // { "name": "Босански", "language": "bs", "country": "BA" },
                // { "name": "Shqip", "language": "sq", "country": "AL" },
                // { "name": "Македонски", "language": "mk", "country": "MK" },
                { "name": "Bahasa Indonesia", "language": "id", "country": "ID" },
                // Commenting out all remaining languages as they're not in the specified list
                // { "name": "Беларуская мова", "language": "be", "country": "BY" },
                // { "name": "Dzongkha", "language": "dz", "country": "BT" },
                // { "name": "Bahasa Melayu", "language": "ms", "country": "BN" },
                // { "name": "Kirundi", "language": "rn", "country": "BI" },
                // { "name": "ភាសាខ្មែរ", "language": "km", "country": "KH" },
                // { "name": "Sängö", "language": "sg", "country": "CF" },
                // { "name": "Shikomori", "language": "km", "country": "KM" },
                // { "name": "ትግርኛ", "language": "ti", "country": "ER" },
                // { "name": "SiSwati", "language": "ss", "country": "SZ" },
                // { "name": "አማርኛ", "language": "am", "country": "ET" },
                // { "name": "Fijian", "language": "fj", "country": "FJ" },
                // { "name": "ქართული", "language": "ka", "country": "GE" },
                // { "name": "Kreyòl ayisyen", "language": "ht", "country": "HT" },
                // { "name": "کوردی", "language": "ku", "country": "IQ" },
                // { "name": "עברית", "language": "he", "country": "IL" },
                // { "name": "Қазақ тілі", "language": "kk", "country": "KZ" },
                // { "name": "Kiswahili", "language": "sw", "country": "KE" },
                // { "name": "Кыргыз тили", "language": "ky", "country": "KG" },
                // { "name": "ພາສາລາວ", "language": "lo", "country": "LA" },
                // { "name": "Sesotho", "language": "st", "country": "LS" },
                // { "name": "Lëtzebuergesch", "language": "lb", "country": "LU" },
                // { "name": "Malagasy", "language": "mg", "country": "MG" },
                // { "name": "ChiChewa", "language": "ny", "country": "MW" },
                // { "name": "Bahasa Melayu", "language": "ms", "country": "MY" },
                // { "name": "ދިވެހި", "language": "dv", "country": "MV" },
                // { "name": "Marshallese", "language": "mh", "country": "MH" },
                // { "name": "Монгол хэл", "language": "mn", "country": "MN" },
                // { "name": "မြန်မာဘာသာ", "language": "my", "country": "MM" },
                // { "name": "Afrikaans", "language": "af", "country": "NA" },
                // { "name": "Nauru", "language": "na", "country": "NR" },
                // { "name": "Māori", "language": "mi", "country": "NZ" },
                // { "name": "Norsk bokmål", "language": "no", "country": "NO" },
                // { "name": "Norsk nynorsk", "language": "nn", "country": "NO" },
                // { "name": "Tok Pisin", "language": "tp", "country": "PG" },
                // { "name": "Avañe'ẽ", "language": "gn", "country": "PY" },
                // { "name": "Filipino", "language": "tl", "country": "PH" },
                // { "name": "Kinyarwanda", "language": "rw", "country": "RW" },
                // { "name": "Gagana Samoa", "language": "sm", "country": "WS" },
                // { "name": "Bahasa Melayu", "language": "ms", "country": "SG" },
                // { "name": "தமிழ்", "language": "ta", "country": "SG" },
                // { "name": "Soomaali", "language": "so", "country": "SO" },
                // { "name": "IsiZulu", "language": "zu", "country": "ZA" },
                // { "name": "IsiXhosa", "language": "xh", "country": "ZA" },
                // { "name": "Afrikaans", "language": "af", "country": "ZA" },
                // { "name": "IsiNdebele", "language": "nr", "country": "ZA" },
                // { "name": "Sesotho sa Leboa", "language": "st", "country": "ZA" },
                // { "name": "Setswana", "language": "tn", "country": "ZA" },
                // { "name": "Xitsonga", "language": "ts", "country": "ZA" },
                // { "name": "Tshivenda", "language": "ve", "country": "ZA" },
                // { "name": "Nuer", "language": "nus", "country": "SS" },
                // { "name": "සිංහල", "language": "si", "country": "LK" },
                // { "name": "தமிழ்", "language": "ta", "country": "LK" },
                // { "name": "Rumantsch", "language": "rm", "country": "CH" },
                // { "name": "Тоҷикӣ", "language": "tg", "country": "TJ" },
                // { "name": "Kiswahili", "language": "sw", "country": "TZ" },
                // { "name": "Tetun", "language": "tet", "country": "TL" },
                // { "name": "Tonga", "language": "to", "country": "TO" },
                // { "name": "Türkmençe", "language": "tk", "country": "TM" },
                // { "name": "Kiswahili", "language": "sw", "country": "UG" },
                // { "name": "Oʻzbek tili", "language": "uz", "country": "UZ" },
                // { "name": "Bislama", "language": "bi", "country": "VU" },
                // { "name": "Latine", "language": "la", "country": "VA" },
                // { "name": "Tiếng Việt", "language": "vi", "country": "VN" },
                // { "name": "Bemba", "language": "bem", "country": "ZM" },
                // { "name": "ChiShona", "language": "sn", "country": "ZW" },
            ]
        };
            
    },
    methods: {
        getCountryByCode(code) {
            return this.countries.find(country => country.country === code);
        },
        getLanguageByCode(code) {
            return this.languages.find(language => language.language === code);
        },
        getCountryByLanguageCode(code) {
            return this.getCountryByCode(this.getLanguageByCode(code)?.country);
        },
    }
};

export default countryLanguageMixin;