var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-select", {
        attrs: {
          value: _vm.value,
          items: _vm.dateOptions,
          label: _vm.$t("dateRange.labelDateRange"),
          "prepend-inner-icon": "date_range",
          "item-text": "text",
          "item-value": "value",
          menuProps: { maxHeight: 460 },
          disabled: _vm.disabled,
        },
        on: { input: _vm.updateValue },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function ({ item, attrs, on }) {
              return [
                _c(
                  "v-list-item",
                  _vm._g(
                    _vm._b(
                      {
                        class:
                          item.value === _vm.openOwnPeriod &&
                          "dr-border-bottom",
                      },
                      "v-list-item",
                      attrs,
                      false
                    ),
                    on
                  ),
                  [
                    _c(
                      "v-list-item-content",
                      { class: item.isOwn && "primary--text text--lighten-3" },
                      [_vm._v(" " + _vm._s(item.text) + " ")]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "v-menu",
        {
          ref: "menu",
          attrs: {
            "close-on-content-click": false,
            transition: "scale-transition",
            "offset-y": "",
            "min-width": "auto",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({}) {
                return [_c("div")]
              },
            },
          ]),
          model: {
            value: _vm.isMenuOpen,
            callback: function ($$v) {
              _vm.isMenuOpen = $$v
            },
            expression: "isMenuOpen",
          },
        },
        [
          _c(
            "v-date-picker",
            {
              attrs: {
                range: "",
                "no-title": "",
                scrollable: "",
                color: "primary lighten-2",
                max: new Date().toISOString(),
              },
              on: {
                input: function ($event) {
                  return _vm.checkDates()
                },
              },
              model: {
                value: _vm.dates,
                callback: function ($$v) {
                  _vm.dates = $$v
                },
                expression: "dates",
              },
            },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { text: "", color: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.isMenuOpen = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { text: "", color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.changeOwnDateRange()
                    },
                  },
                },
                [_vm._v(" OK ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }