var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.bots.length > 1
    ? _c("v-select", {
        attrs: {
          items: _vm.bots,
          label: "Bots",
          "item-text": "name",
          "return-object": "",
          value: _vm.currentBot,
          outlined: "",
        },
        on: {
          change: function ($event) {
            return _vm.onChangeBot($event)
          },
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }