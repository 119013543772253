var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.teaser && _vm.teaser.payload && _vm.teaser.payload.quickActions
    ? _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "12" } }, [
            _c("h3", { staticClass: "text-h6" }, [
              _vm._v(_vm._s(_vm.$t("teasers.actions.title"))),
            ]),
            _c("div", { staticClass: "text-body-1" }, [
              _vm._v(_vm._s(_vm.$t("teasers.actions.info"))),
            ]),
          ]),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "fill-height d-flex align-center pa-4",
                  attrs: { outlined: "" },
                },
                [_c("TeasersTeaserPreview", { attrs: { teaser: _vm.teaser } })],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              _c(
                "div",
                [
                  _c("v-textarea", {
                    attrs: {
                      label: _vm.$t("teasers.actions.teaserText"),
                      outlined: "",
                      "row-height": "20",
                      "auto-grow": "",
                      rules: _vm.teaserTextRules,
                    },
                    on: { input: _vm.checkDirty },
                    model: {
                      value: _vm.teaser.payload.message,
                      callback: function ($$v) {
                        _vm.$set(_vm.teaser.payload, "message", $$v)
                      },
                      expression: "teaser.payload.message",
                    },
                  }),
                ],
                1
              ),
              _c(
                "VueDraggable",
                {
                  attrs: { handle: ".drag-item" },
                  on: { end: _vm.updateTeaserActions },
                  model: {
                    value: _vm.actions,
                    callback: function ($$v) {
                      _vm.actions = $$v
                    },
                    expression: "actions",
                  },
                },
                _vm._l(_vm.actions, function (action, index) {
                  return _c(
                    "v-card",
                    {
                      key: action.id,
                      staticClass: "mb-2",
                      attrs: { outlined: "" },
                    },
                    [
                      _vm.isLoading
                        ? _c("v-progress-linear", {
                            attrs: {
                              indeterminate: "",
                              color: "secondary darken-2",
                              absolute: "",
                            },
                          })
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "d-flex align-center px-2" },
                        [
                          _c(
                            "div",
                            { staticClass: "pt-1 drag-item" },
                            [_c("v-icon", [_vm._v("mdi-drag-vertical")])],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex-grow-1 text-body-1 pt-1 grey--text text-darken-2 primary--text text--lighten-2",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    `${_vm.$t("teasers.actions.action")} ${
                                      index + 1
                                    }:`
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c("BtnSquare", {
                            staticClass: "mt-2",
                            attrs: {
                              icon: "mdi-delete",
                              disabled:
                                index === 0 &&
                                _vm.teaser.payload.quickActions.length === 1,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.deleteAction(action.id)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "py-2 px-4" },
                        [
                          _c("v-text-field", {
                            staticClass: "mb-2",
                            attrs: {
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              type: "text",
                              label: `${_vm.$t("teasers.actions.text")}`,
                            },
                            on: { keyup: _vm.updateTeaserActions },
                            model: {
                              value: action.text,
                              callback: function ($$v) {
                                _vm.$set(action, "text", $$v)
                              },
                              expression: "action.text",
                            },
                          }),
                          _vm.intents
                            ? _c("v-select", {
                                attrs: {
                                  items: _vm.targetOptionsFiltered,
                                  label: `${_vm.$t("teasers.actions.target")}`,
                                  "item-text": "displayName",
                                  "item-value": "intent",
                                  disabled: _vm.isLoading,
                                },
                                on: { change: _vm.updateTeaserActions },
                                model: {
                                  value: action.targetIntent,
                                  callback: function ($$v) {
                                    _vm.$set(action, "targetIntent", $$v)
                                  },
                                  expression: "action.targetIntent",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "d-flex" }),
                    ],
                    1
                  )
                }),
                1
              ),
              _c(
                "v-btn",
                { attrs: { outlined: "" }, on: { click: _vm.addAction } },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("teasers.actions.addAction")) + " "
                  ),
                  _c("v-icon", { attrs: { outlined: "", right: "" } }, [
                    _vm._v("mdi-plus"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }