<template>
  <div class="chat-divider">
    <div class="d-flex flex-col items-center">
      <div class="chat-divider-text">{{ textA }}</div>
      <div class="chat-divider-icon">
        <v-icon color="primary lighten-5" size="20">mdi-sync</v-icon>
      </div>
      <div class="chat-divider-text">{{ textB }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LivechatDivider',
  components: {},
  props: {
    type: {
      type: String,
      default: 'livechat',
    },
  },
  computed: {
    textA() {
      if (this.type === 'livechat_ended') {
        return this.$t('livechat.title');
      }
      return this.$t('livechat.chatbot');
    },
    textB() {
      if (this.type === 'livechat_ended') {
        return this.$t('livechat.chatbot');
      }
      return this.$t('livechat.title');
    },
  },
};
</script>

<style lang="scss">
.chat-divider {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: $spacer * 6;
  margin-bottom: $spacer * 4;
}

.chat-divider:before {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  content: '';
  display: block;
  height: 1px;
  width: 100%;
  border-top: 1px dashed var(--v-primary-lighten5);
  opacity: 0.6;
}

.chat-divider-icon {
  width: 24px;
  height: 24px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.chat-divider-text {
  text-align: center;
  color: var(--v-primary-lighten5);
  font-size: 12px;
}
</style>
