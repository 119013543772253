var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "moin-fresh" },
    [
      _c(
        "v-row",
        [
          _vm.currentBotContactId
            ? [
                _c("v-col", { attrs: { cols: "12" } }, [
                  _c(
                    "div",
                    { staticClass: "d-flex primary--text text--lighten-2" },
                    [
                      _c(
                        "v-icon",
                        { attrs: { color: "primary lighten-2", left: "" } },
                        [_vm._v("mdi-help-circle-outline")]
                      ),
                      _c("h2", { staticClass: "text-h6" }, [
                        _vm._v(_vm._s(_vm.$t(`welcome.supportTitle`))),
                      ]),
                    ],
                    1
                  ),
                ]),
                _c(
                  "v-col",
                  [
                    _c(
                      "v-card",
                      { staticClass: "fill-height mx-n3", attrs: { flat: "" } },
                      [
                        _c("ContactItem", {
                          attrs: {
                            "contact-id": _vm.currentBotContactId,
                            title: _vm.$t(
                              `welcome.supportContact.${_vm.currentBotStage}`
                            ),
                            large: "",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("v-divider", {
                  staticClass: "d-none d-md-inline-block mx-4 my-4",
                  attrs: { vertical: "" },
                }),
              ]
            : [
                _c("v-col", { staticClass: "col-2 hidden-sm-and-down" }, [
                  _vm._v(" "),
                ]),
              ],
          _c(
            "v-col",
            {
              staticClass: "col-12 col-md-6",
              class: { "col-12 col-md-8": !_vm.currentBotContactId },
            },
            [
              _c(
                "div",
                { staticClass: "pt-3 px-0 mx-n4 mx-sm-0 px-sm-9" },
                [_c("ExpertTipSlider")],
                1
              ),
            ]
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }