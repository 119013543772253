<template>
  <v-navigation-drawer
    app
    :value="isMenuOpen"
    class="moin-fresh hub-menu"
    :permanent="$vuetify.breakpoint.lgAndUp"
    @input="handleClose($event)"
  >
    <div style="height: 64px; padding-top: 4px" class="text-center">
      <img src="@/assets/moin-logo.svg" height="50px" />
    </div>
    <div v-for="(section, index) in sections" :key="index">
      <template v-if="!section.hidden">
        <div
          class="pa-2 px-4 grey--text text--lighten-1"
          style="font-size: 13px; transition: padding-left 0.3s"
          v-if="section.title"
        >
          {{ section.title }}
        </div>
        <template v-for="item in section.items">
          <v-list-item
            v-if="!item.hidden"
            class="hub-menu-item"
            :class="{
                'hub-menu-item--active': $router.currentRoute.path.split('/')[1] === item.name.toLowerCase(),
                'hub-menu-item--alone': section.items.length === 1,
              }"
            :key="`btn-${item.name}`"
            :data-cy="`menu-${item.name}-btn`"
            color="primary"
            :disabled="item.disabled"
            @click="handleMenuClick(item)"
            :data-cy-name="item.name"
          >
            <v-icon color="secondary" class="secondary--text" left>
              {{ item.icon }}
            </v-icon>
            <span>
              {{ item.title }}
            </span>
            <div
              v-if="item.name === 'livechat'"
              style="position: absolute; right: 18px;"
            >
              <LivechatMenuBoeble />
            </div>
          </v-list-item>
        </template>
        <v-divider />
      </template>
    </div>

    <HubMenuAdmin v-if="isAdmin" />

    <template v-slot:append>
      <HubMenuProfile />
    </template>
  </v-navigation-drawer>
</template>
<script>
import { mapGetters } from 'vuex';
import BtnSquare from '@/components/common/BtnSquare.vue';
import HubMenuAdmin from '@/components/hub/HubMenuAdmin.vue';
import HubMenuProfile from '@/components/hub/HubMenuProfile';
import { TrackingService } from '@/services/tracking';
import LivechatMenuBoeble from '@/components/livechat/LivechatMenuBoeble.vue';

export default {
  name: 'HubMenu',
  components: { BtnSquare, HubMenuAdmin, HubMenuProfile, LivechatMenuBoeble },
  data() {
    return {};
  },
  created() {
    this.$store.dispatch('auth/getUser'); // get current user
    this.$store.dispatch('bots/get');
  },
  computed: {
    ...mapGetters('hubUi', ['isMenuOpen']),
    ...mapGetters('auth', [
      'isAdmin',
      'isOwner',
      'isRAG',
      'isEditor',
      'isViewer',
      'isFormView',
      'isSimpleEdit',
      'isLivechat',
      'isWidgetEditorView',
      'isConversationalView',
    ]),
    ...mapGetters('bots', ['currentBotId', 'hasWidget', 'getBotSettings']),
    isLivechatAvaiable() {
      return this.isLivechat && this.getBotSettings(this.currentBotId)?.moin_livechat;
    },
    sections() {
      return [
        {
          title: null,
          items: [
            {
              title: this.$t('menu.dashboard'),
              icon: 'mdi-view-dashboard',
              name: 'welcome',
            },
          ],
        },
        {
          title: this.$t('menu.areaLive'),
          items: [
            {
              title: this.$t('menu.conversations'),
              icon: 'message',
              name: 'chats',
            },
            {
              title: this.$t('livechat.title'),
              icon: 'mdi-face-agent',
              name: 'livechat',
            },
            {
              title: this.$t('stats.title'),
              icon: 'show_chart',
              name: 'statistics',
            },
            {
              title: this.$t('settingsLivechat.livechatStats.title'),
              icon: 'show_chart',
              name: 'livechat-statistics',
              hidden: !this.isLivechatAvaiable || !(this.isAdmin || this.isOwner),
            },
          ],
        },
        {
          title: this.$t('menu.areaEditor'),
          hidden: !this.isSimpleEdit,
          items: [
            {
              title: this.$t('menu.conversational'),
              icon: 'schema',
              name: 'conversational',
              disabled: !this.isConversationalView,
            },
            {
              title: this.$t('menu.answers'),
              icon: 'mdi-folder-text',
              name: 'intents',
            },
            // {
            //   title: this.$t('menu.search'),
            //   icon: 'mdi-magnify',
            //   name: 'search',
            // },
            {
              title: this.$t('menu.forms'),
              icon: 'list_alt',
              name: 'Forms', // !! Keep F uppercase
              disabled: !this.isFormView,
            },
            {
              title: 'Knowledge Base',
              icon: 'mdi-book-open-variant',
              name: 'knowledge-base',
              hidden:
                !this.isSimpleEdit ||
                !this.isRAG,
            },
            {
              title: this.$t('productAdvisor.name'),
              icon: 'mdi-thumb-up-outline',
              name: 'advisors',
              hidden:
                !this.isSimpleEdit ||
                !this.isRAG ||
                !(this.isAdmin || this.isOwner),
            },
            {
              title: this.$t('menu.widgetAndTeaser'),
              icon: 'mdi-cog-box',
              name: 'wt',
              hidden:
                !this.isWidgetEditorView ||
                !this.hasWidget ||
                !this.isSimpleEdit,
            },
          ],
        },
        {
          title: this.$t('menu.areaSetup'),
          items: [
            {
              title: this.$t('menu.settings'),
              icon: 'mdi-tune-vertical',
              name: 'settings',
              hidden: !this.isSimpleEdit || !(this.isAdmin || this.isOwner),
            },
            {
              title: this.$t('ai.title'),
              icon: 'school',
              name: 'ai',
            },
            {
              title: this.$t('menu.integrations'),
              icon: 'cable',
              name: 'integrations',
              hidden: !(this.isAdmin || this.isOwner),
            },
          ],
        },
      ];
    },
  },
  methods: {
    async handleMenuClick(item) {
      if (this.$router.currentRoute?.name === item.name) {
        return false;
      }

      this.handleClose(false);

      const delay = this.$vuetify.breakpoint.lgAndUp ? 0 : 300;

      await new Promise((resolve) => setTimeout(resolve, delay));

      TrackingService.trackMenu({
        botId: this.currentBotId,
        type: 'click',
        source: 'Menu',
        payload: {
          routerPath: item.to,
        },
      });

      this.$router.push({ name: item.name });
    },
    handleClose(openValue) {
      this.$store.commit('hubUi/setValue', {
        key: 'isMenuOpen',
        value: openValue,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.hub-menu {
  height: 100% !important;
  max-height: 100% !important;
}
.hub-menu-item--alone {
  height: 60px; // Nur solange bis hubContentNavbar schmaler wird!
}

.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: var(--v-primary-lighten1) !important;
}

.hub-menu-item--active {
  cursor: default;
  background-color: var(--v-primary-lighten3) !important;
  span,
  .v-icon,
  .v-icon::before,
  .v-icon::after {
    color: white !important;
  }
}
</style>
