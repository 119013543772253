var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-navigation-drawer",
    {
      staticClass: "moin-fresh hub-menu",
      attrs: {
        app: "",
        value: _vm.isMenuOpen,
        permanent: _vm.$vuetify.breakpoint.lgAndUp,
      },
      on: {
        input: function ($event) {
          return _vm.handleClose($event)
        },
      },
      scopedSlots: _vm._u([
        {
          key: "append",
          fn: function () {
            return [_c("HubMenuProfile")]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "div",
        {
          staticClass: "text-center",
          staticStyle: { height: "64px", "padding-top": "4px" },
        },
        [
          _c("img", {
            attrs: { src: require("@/assets/moin-logo.svg"), height: "50px" },
          }),
        ]
      ),
      _vm._l(_vm.sections, function (section, index) {
        return _c(
          "div",
          { key: index },
          [
            !section.hidden
              ? [
                  section.title
                    ? _c(
                        "div",
                        {
                          staticClass: "pa-2 px-4 grey--text text--lighten-1",
                          staticStyle: {
                            "font-size": "13px",
                            transition: "padding-left 0.3s",
                          },
                        },
                        [_vm._v(" " + _vm._s(section.title) + " ")]
                      )
                    : _vm._e(),
                  _vm._l(section.items, function (item) {
                    return [
                      !item.hidden
                        ? _c(
                            "v-list-item",
                            {
                              key: `btn-${item.name}`,
                              staticClass: "hub-menu-item",
                              class: {
                                "hub-menu-item--active":
                                  _vm.$router.currentRoute.path.split(
                                    "/"
                                  )[1] === item.name.toLowerCase(),
                                "hub-menu-item--alone":
                                  section.items.length === 1,
                              },
                              attrs: {
                                "data-cy": `menu-${item.name}-btn`,
                                color: "primary",
                                disabled: item.disabled,
                                "data-cy-name": item.name,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleMenuClick(item)
                                },
                              },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "secondary--text",
                                  attrs: { color: "secondary", left: "" },
                                },
                                [_vm._v(" " + _vm._s(item.icon) + " ")]
                              ),
                              _c("span", [
                                _vm._v(" " + _vm._s(item.title) + " "),
                              ]),
                              item.name === "livechat"
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        position: "absolute",
                                        right: "18px",
                                      },
                                    },
                                    [_c("LivechatMenuBoeble")],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  }),
                  _c("v-divider"),
                ]
              : _vm._e(),
          ],
          2
        )
      }),
      _vm.isAdmin ? _c("HubMenuAdmin") : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }