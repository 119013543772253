var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "bar-percentage",
      class: [
        _vm.small && "bar-percentage--small",
        _vm.large && "bar-percentage--large",
      ],
    },
    [
      _c(
        "v-tooltip",
        {
          attrs: { bottom: "", disabled: !_vm.tooltip },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on: onTooltip, attrs: attrsTooltip }) {
                return [
                  _c(
                    "div",
                    _vm._g(
                      _vm._b(
                        { class: !_vm.noBackground && "grey lighten-3" },
                        "div",
                        attrsTooltip,
                        false
                      ),
                      onTooltip
                    ),
                    [
                      _c("v-progress-linear", {
                        attrs: {
                          "background-color": _vm.backgroundColor,
                          "buffer-value": _vm.buffer,
                          color: "primary",
                          height: _vm.small ? 4 : _vm.large ? 20 : 10,
                          value: _vm.value,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ value }) {
                                return [
                                  !_vm.noData && !_vm.hideText
                                    ? _c(
                                        "strong",
                                        {
                                          style:
                                            _vm.getProgressValueStyle(value),
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(value.toFixed(1)) +
                                              "% "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          !_vm.noData
            ? _c("span", [_vm._v(_vm._s(_vm.tooltip))])
            : _c("span", [_vm._v(_vm._s(_vm.$t("common.nodata")))]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }