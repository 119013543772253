var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("BtnSquare", {
        attrs: { icon: "add", colorBtn: "admin", colorIcon: "admin" },
        on: {
          click: function ($event) {
            _vm.isDialogOpen = true
          },
        },
      }),
      _c(
        "HubDialog",
        {
          attrs: {
            title: "Neues Thema als Vorschlag",
            "primary-label": _vm.$t("common.save"),
            "primary-icon": "save",
            "secondary-label": _vm.$t("common.cancel"),
            "secondary-icon": "close",
            modal: "",
            loading: _vm.isLoading,
            disabled: _vm.isLoading,
            asyncDialog: "",
          },
          on: {
            onPrimary: _vm.createIntent,
            onSecondary: function ($event) {
              _vm.isDialogOpen = false
            },
          },
          model: {
            value: _vm.isDialogOpen,
            callback: function ($$v) {
              _vm.isDialogOpen = $$v
            },
            expression: "isDialogOpen",
          },
        },
        [
          _c(
            "div",
            { staticClass: "pt-10" },
            [
              _c(
                "v-form",
                { ref: "createForm" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Name",
                              outlined: "",
                              rules: _vm.displayNameRules,
                              counter: "",
                              hint: "z.B. Kosten",
                              "persistent-hint": "",
                            },
                            model: {
                              value: _vm.nameInput,
                              callback: function ($$v) {
                                _vm.nameInput = $$v
                              },
                              expression: "nameInput",
                            },
                          }),
                          _c("div", [
                            _vm._v(
                              " Es ist ein Fehler mit dem Namen aufgetreten. "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-textarea", {
                            attrs: {
                              label: "Beschreibung",
                              outlined: "",
                              rules: _vm.descriptionRules,
                              counter: "",
                            },
                            model: {
                              value: _vm.descriptionInput,
                              callback: function ($$v) {
                                _vm.descriptionInput = $$v
                              },
                              expression: "descriptionInput",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-textarea", {
                            attrs: {
                              label: "5 Varianten",
                              outlined: "",
                              rules: _vm.samplesRules,
                              rows: "10",
                            },
                            model: {
                              value: _vm.samplesInput,
                              callback: function ($$v) {
                                _vm.samplesInput = $$v
                              },
                              expression: "samplesInput",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "div",
                            { staticClass: "mb-4" },
                            [
                              _c("v-switch", {
                                attrs: {
                                  color: _vm.isForwardActive ? "green" : "grey",
                                  inset: "",
                                  label: "Mit Weiterleitung",
                                  "hide-details": "",
                                },
                                model: {
                                  value: _vm.isForwardActive,
                                  callback: function ($$v) {
                                    _vm.isForwardActive = $$v
                                  },
                                  expression: "isForwardActive",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.isForwardActive
                            ? _c("v-select", {
                                attrs: {
                                  label: "Weiterleiten zu",
                                  items: _vm.intents,
                                  "item-text": "displayName",
                                  "item-value": "intent",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.forwardIntent,
                                  callback: function ($$v) {
                                    _vm.forwardIntent = $$v
                                  },
                                  expression: "forwardIntent",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }