<template>
  <v-app style="max-height: 100vh; height: 100vh">
    <HubContent fixedHeight class="moin-fresh">
      <template v-slot:navbar>
        <HubNavbar :isLoading="loading" title="Livechat Profile">
          <template v-slot:toolbar>
            <LivechatHeaderMenu />
          </template>
        </HubNavbar>
      </template>
      <template v-slot:content>
        <div class="fill-height">
          <HubEditorFrame
            :title="'Profil bearbeiten'"
            @onSave="saveProfile"
            @onReset="resetProfile"
            hideHeader
          >
            <template v-slot="{}">
              <div class="white pa-6">
                <v-container>
                  <LivechatProfile />
                  <LivechatProfileAgent />
                </v-container>
              </div>
            </template>
          </HubEditorFrame>
        </div>
      </template>
    </HubContent>
  </v-app>
</template>

<script>
import HubEditorFrame from '@/components/hub/HubEditorFrame.vue';
import HubContent from '@/components/hub/HubContent.vue';
import HubNavbar from '@/components/hub/HubNavbar.vue';
import LivechatHeaderMenu from '@/components/livechat/LivechatHeaderMenu.vue';
import LivechatProfile from '@/components/livechat/LivechatProfile.vue';
import LivechatProfileAgent from '@/components/livechat/LivechatProfileAgent.vue';

export default {
  name: 'LivechatAgentProfile',
  components: {
    HubEditorFrame,
    HubContent,
    HubNavbar,
    LivechatHeaderMenu,
    LivechatProfile,
    LivechatProfileAgent,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  created() {},
  computed: {},
  methods: {
    saveProfile() {
      console.log('saveProfile');
    },
    resetProfile() {
      console.log('resetProfile');
    },
  },
};
</script>
