var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "file-card px-1",
      class: [_vm.border && "file-card--border"],
    },
    [
      _c(
        "div",
        { staticClass: "d-flex items-center" },
        [
          _c("div", { staticClass: "flex-grow-1" }, [
            _c(
              "div",
              { staticClass: "file-card-title d-flex" },
              [
                _c(
                  "v-icon",
                  {
                    staticClass: "mr-1",
                    attrs: { small: "", color: "primary lighten-4" },
                  },
                  [_vm._v(" " + _vm._s(_vm.icon) + " ")]
                ),
                _c(
                  "span",
                  { staticClass: "text-body-2 grey--text text--darken-2" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t(`common.fileCard.title.${this.type}`)) +
                        " "
                    ),
                  ]
                ),
                _c("span", { staticClass: "text-body-2 grey--text" }, [
                  _vm._v(" (" + _vm._s(_vm.fileSize) + " KB)"),
                ]),
              ],
              1
            ),
            _c("div", { staticClass: "file-card-body pr-1" }, [
              _vm._v(" " + _vm._s(_vm.text) + " "),
            ]),
          ]),
          _c(
            "v-btn",
            {
              staticClass: "pa-0 ml-1",
              staticStyle: { "min-width": "26px" },
              attrs: { text: "", color: "primary lighten-4" },
              on: { click: _vm.handleDownload },
            },
            [_c("v-icon", { attrs: { small: "" } }, [_vm._v("mdi-download")])],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }