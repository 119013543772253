var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "d-flex items-center justify-center gap-3 px-3 py-3 sso-btn",
      class: { redirecting: _vm.redirecting },
      attrs: { disabled: _vm.redirecting },
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.startSSO.apply(null, arguments)
        },
      },
    },
    [
      _c("img", {
        attrs: {
          src: `${_vm.logos[_vm.provider]}`,
          alt: `Logo of ${_vm.provider}`,
        },
      }),
      _vm._v(
        " " +
          _vm._s(
            _vm.$t("login.btnSSO", { name: _vm.capitalize(_vm.provider) })
          ) +
          " "
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }