var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex pt-1" },
    [
      _c(
        "v-tooltip",
        {
          attrs: { bottom: "", color: "primary lighten-3" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on: onGoals, attrs: attrsGoals }) {
                return [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex",
                      staticStyle: {
                        width: "130px",
                        position: "relative",
                        top: "-3px",
                      },
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-1", attrs: { size: "16" } },
                        [_vm._v("outlined_flag")]
                      ),
                      _c(
                        "div",
                        _vm._g(
                          _vm._b(
                            { staticClass: "primary--text" },
                            "div",
                            attrsGoals,
                            false
                          ),
                          onGoals
                        ),
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.calcPro(_vm.countGoals, _vm.countAll)
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("span", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("stats.conversionsCountGoals", {
                    goals: _vm.countGoals,
                    count: _vm.countAll,
                  })
                ) +
                " "
            ),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "bar relative" },
        [
          _vm.isLoading
            ? _c("v-skeleton-loader", {
                attrs: { type: "image", height: "20", width: "100%" },
              })
            : _vm._e(),
          !_vm.isLoading
            ? _c(
                "v-tooltip",
                {
                  attrs: { bottom: "", color: "primary lighten-3" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on: onAll, attrs: attrsAll }) {
                          return [
                            _c(
                              "div",
                              _vm._g(
                                _vm._b(
                                  { staticClass: "bar-all grey lighten-2" },
                                  "div",
                                  attrsAll,
                                  false
                                ),
                                onAll
                              )
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3617317198
                  ),
                },
                [
                  _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("stats.conversionsCountGoalsNot", {
                            goals: _vm.countAll - _vm.countGoals,
                          })
                        ) +
                        " "
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          !_vm.isLoading
            ? _c(
                "v-tooltip",
                {
                  attrs: { bottom: "", color: "primary lighten-3" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on: onPos, attrs: attrsPos }) {
                          return [
                            _c(
                              "div",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass:
                                      "bar-inline relative primary darken-1",
                                    style: { width: _vm.goalsPro },
                                  },
                                  "div",
                                  attrsPos,
                                  false
                                ),
                                onPos
                              )
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    970011159
                  ),
                },
                [
                  _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("stats.conversionsCountGoalsOf", {
                            goals: _vm.countGoals,
                            count: _vm.countAll,
                          })
                        ) +
                        " "
                    ),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }