var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", [
      _c("div", { staticClass: "loading" }, [
        _c(
          "div",
          {
            staticClass: "star star-1",
            staticStyle: { "animation-delay": "0s" },
          },
          [
            _c("DemoStar", {
              attrs: {
                fill: _vm.assistant ? "var(--moin-color-accent-1)" : null,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "star star-2",
            staticStyle: { "animation-delay": "1s" },
          },
          [
            _c("DemoStar", {
              attrs: {
                fill: _vm.assistant ? "var(--moin-color-accent-2)" : null,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "star star-3",
            staticStyle: { "animation-delay": "1.5s" },
          },
          [
            _c("DemoStar", {
              attrs: {
                fill: _vm.assistant ? "var(--moin-color-accent-5)" : null,
              },
            }),
          ],
          1
        ),
        _c("div", {
          staticClass: "spark spark-1",
          staticStyle: { "animation-delay": "0s" },
          style: {
            backgroundColor: _vm.assistant ? "var(--moin-color-accent-5)" : "",
          },
        }),
        _c("div", {
          staticClass: "spark spark-2",
          staticStyle: { "animation-delay": "1.6s" },
          style: {
            backgroundColor: _vm.assistant ? "var(--moin-color-accent-1)" : "",
          },
        }),
        _c("div", {
          staticClass: "spark spark-3",
          staticStyle: { "animation-delay": "0.8s" },
        }),
        _c("div", {
          staticClass: "spark spark-4",
          staticStyle: { "animation-delay": "1.2s" },
        }),
        _c("div", {
          staticClass: "spark spark-5",
          staticStyle: { "animation-delay": "0.4s" },
          style: {
            backgroundColor: _vm.assistant ? "var(--moin-color-accent-2)" : "",
          },
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }