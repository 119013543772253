<template>
  <div class="mb-4 chat-item moin-fade-in">
    <!-- System message -->
    <div
      v-if="item.sender?.role === 'system'"
      class="text-center grey--text text--lighten-1 chat-system-message-wrapper"
    >
      <span class="chat-system-message">
        <span class="chat-system-message-text">{{ $t(`livechat.chats.${item.type}`) }}</span>
      </span>
    </div>
    <!-- user -->
    <div v-else-if="item.sender?.role === 'user'" class="d-flex chat-item-customer items-end">
      <div class="chat-item-time pt-2 grey--text">
        {{ getTimeString(item.created_at) }}
      </div>
      <div class="px-2 pt-2">
        <LivechatAvatar :avatar="chat.user.avatar" small />
      </div>
      <div class="chat-item-text pa-2 relative">
        <div
          class="chat-item-text-bg"
          :style="{ background: chat.user?.avatar.color?.hex }"
        ></div>
        <div class="relative">
          <span v-if="showTranslating" class="translating"><v-icon class="blinking">mdi-creation</v-icon>{{ $t('livechat.chats.translating') }}</span>
          <span v-else>{{ messageString }}</span>
        </div>
      </div>
      <v-icon v-if="isTranslated" class="ml-2 align-self-center" small :title="item.data">mdi-web</v-icon>
    </div>
    <!-- Agent | Bot -->
    <div v-else>
      <div v-if="item.type === 'note'" class="d-flex justify-center">
        <div class="note-container pa-2 mb-2">
          <div class="note-header d-flex align-center mb-1">
            <v-icon small color="primary" class="mr-1">mdi-note-text</v-icon>
            <span class="font-weight-medium primary--text">{{ $t('livechat.chats.note') }}</span>
          </div>
          <span style="text-align: center;">
            {{ item.text }}
          </span>
          <span class="d-flex justify-end">
            <span class="align-self-center">
              {{ getTimeString(item.created_at) }}
            </span>
            <v-btn v-show="isAgentsChat" icon @click="deleteNote(item._id)">
              <v-icon small color="grey">mdi-trash-can-outline</v-icon>
            </v-btn>
          </span>
        </div>
      </div>
      <div v-else class="d-flex justify-end chat-item-self items-end">
        <v-icon v-if="isTranslated && !isAttachment" class="mr-2 align-self-center" small :title="item.data">mdi-web</v-icon>
        <div
          class="chat-item-text pa-2 relative primary--text text--lighten-2"
          :class="{'agent-chat-item-bg': item.sender?.role === 'agent'}"
          :style="{
            border:
              item.sender?.role === 'bot'
                ? '1px solid rgb(158, 167, 179)'
                : '',
            
          }"
        >
          <span
            :style="{
              color:
                item.sender?.role === 'bot' ? 'rgb(116, 123, 134)' : 'inherit',
            }"
          >
            <span v-if="isAttachment">
              <a v-for="attachment in item.attachments" :key="attachment.url" :href="attachment.url" target="_blank">
                <v-icon small>mdi-paperclip</v-icon>
                {{ attachment.name }}
              </a>
            </span>
            <span v-else>
              <span v-if="showTranslating" class="translating"><v-icon class="blinking">mdi-creation</v-icon> {{ $t('livechat.chats.translating') }}</span>
              <span v-else style="white-space: preserve-breaks;">{{ messageString }}</span>
            </span>
          </span>
        </div>
        <div class="px-2 pt-2">
          <v-icon
            v-if="item.sender?.role === 'bot'"
            :color="
              item.sender?.role === 'bot'
                ? 'rgb(140, 146, 158)'
                : 'primary lighten-3'
            "
          >
            mdi-robot
          </v-icon>
          <v-icon v-else color="primary lighten-3">mdi-face-agent</v-icon>
        </div>
        <div class="chat-item-time pt-2 grey--text">
          {{ getTimeString(item.created_at) }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import LivechatAvatar from '@/components/livechat/LivechatAvatar.vue';
export default {
  name: 'LivechatChatItem',
  components: {
    LivechatAvatar,
  },
  props: {
    chat: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    isTranslated: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions('livechat', ['removeNoteFromChat']),
    getTimeString(dateIso) {
      return moment(dateIso).format('HH:mm');
    },
    deleteNote(id) {
      this.removeNoteFromChat({ chatUuid: this.chat.uuid, noteId: id });
    },
  },
  computed: {
    ...mapGetters('livechat', ['agent', 'activeChat', 'agentStatus']),
    isAgentsChat() {
      return this.agentStatus == 'online' && this.agent && this.activeChat && this.agent._id === this.activeChat.agent;
    },
    messageString() {
      const text = this.isTranslated ? this.item.translated : this.item.data;
      return text;
    },
    showTranslating() {
      return this.isTranslated && !this.item.translated;
    },
    isAttachment() {
      return this.item.attachments?.length > 0;
    },
  },
};
</script>
<style scoped lang="scss">
$spacer-width: 100px;

.chat-item-self {
  padding-left: $spacer-width;
}

.chat-item-customer {
  padding-right: $spacer-width;
}

.chat-item-time {
  width: $spacer * 10;
}

.chat-item-text {
  border-radius: $spacer;
  overflow: hidden;
}

.chat-item-spacer {
  width: 110px;
}

.chat-item-text-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.1;
}

@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}
.translating {
  font-style: oblique;
  .blinking {
    animation: blink 1s infinite;
  }
}

.agent-chat-item-bg {
  background-color: rgba(230, 230, 230, 0.5);
}

.chat-system-message-wrapper {
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
}

.chat-system-message:before {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  content: '';
  display: block;
  height: 1px;
  width: 100%;
  border-top: 1px dashed var(--v-primary-lighten5);
  opacity: 0.6;
}

.chat-system-message {
  position: relative;
  display: inline-block;
  padding: 0 20px;
  width: 100%;
  z-index: 1;
}

.chat-system-message-text {
  background: white;
  position: relative;
  z-index: 1;
  padding: 0 10px;
}

.note-container {
  width: 80%;
  background-color: #fff9c4;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.note-header {
  color: var(--v-primary-base);
}
</style>
