<template>
  <div class="lc-header-menu d-flex" v-if="agent">
    <v-divider
      v-if="$vuetify.breakpoint.mdAndUp"
      style="height: 40px;"
      class="mx-4"
      vertical
    />
    <div class="full-height d-flex align-center">
      <v-avatar size="32" color="mr-4">
        <img v-if="agent.avatar" :src="agent.avatar" />
      </v-avatar>
      <div>
        {{ agent.name }}
      </div>
      <DropMenu :items="menuItems">
        <BtnSquare icon="mdi-chevron-down" class="ml-4" />
      </DropMenu>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import BtnSquare from '@/components/common/BtnSquare.vue';
import DropMenu from '@/components/common/DropMenu.vue';

export default {
  name: 'LivechatHeaderMenu',
  components: {
    BtnSquare,
    DropMenu,
  },
  computed: {
    ...mapGetters('livechat', ['agent']),
    menuItems() {
      const items = [
        {
          text: this.$t('menu.logout'),
          icon: 'mdi-logout',
          onClick: () => {
            this.$store.dispatch('livechat/logout');
          },
        },
      ];

      if (!this.$route.path.includes('profile')) {
        items.unshift({
          text: this.$t('menu.myProfile'),
          icon: 'mdi-account-circle',
          to: '/livechat-agent/profile',
        });
      } else {
        items.unshift({
          text: 'Zurück zu Chats',
          icon: 'mdi-account-circle',
          to: '/livechat-agent',
        });
      }
      return items;
    },
  },
};
</script>
<style scoped>
.lc-header-menu {
  height: 40px;
}
</style>
