var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "user-message-wrapper",
      class: { "with-arrow": _vm.arrow },
      style: { "--widget-message-bg-color": _vm.bgColor },
    },
    [
      _c(
        "span",
        { staticClass: "user-message", style: `color: ${_vm.txtColor}` },
        [_vm._v(" " + _vm._s(_vm.text) + " ")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }