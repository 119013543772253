var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _vm._v(_vm._s(_vm.$t("integrations.shopware.title"))),
          ]),
          _c("p", { staticClass: "px-4" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("integrations.shopware.description")) + " "
            ),
            _c("br"),
            _c(
              "a",
              {
                attrs: {
                  href: "https://helpcenter.moin.ai/shopware",
                  target: "_blank",
                  rel: "noopener noreferrer",
                },
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("integrations.shopware.helpLink")) + " "
                ),
              ]
            ),
          ]),
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c(
                "v-btn-toggle",
                {
                  staticClass: "pb-4 mx-4",
                  attrs: {
                    mandatory: "",
                    borderless: "",
                    color: "primary lighten-2",
                  },
                  model: {
                    value: _vm.authType,
                    callback: function ($$v) {
                      _vm.authType = $$v
                    },
                    expression: "authType",
                  },
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticStyle: { "min-width": "120px" },
                      attrs: { value: "integration" },
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("integrations.shopware.integration.label")
                          )
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticStyle: { "min-width": "120px" },
                      attrs: { value: "user" },
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("integrations.shopware.user.label"))
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _vm.currentSettings
                ? _c(
                    "v-alert",
                    {
                      staticClass:
                        "info-box-outline primary--text text--lighten-3 py-2",
                      attrs: { outlined: "", color: "primary lighten-3" },
                    },
                    [
                      _c("v-icon", { attrs: { color: "primary lighten-3" } }, [
                        _vm._v("mdi-lightbulb"),
                      ]),
                      _c("span", { staticClass: "ml-2" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "integrations.shopware.alreadyConfigured",
                                {
                                  credentials: _vm.$t(
                                    `integrations.shopware.${_vm.currentSettings.authType}.label`
                                  ),
                                }
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mx-4 d-flex gap-4" },
            [
              _c("v-text-field", {
                attrs: {
                  label: _vm.$t(
                    `integrations.shopware.${_vm.authType}.identifier`
                  ),
                  placeholder: _vm.$t(
                    `integrations.shopware.${_vm.authType}.placeholder`
                  ),
                  outlined: "",
                },
                model: {
                  value: _vm.identifier,
                  callback: function ($$v) {
                    _vm.identifier = $$v
                  },
                  expression: "identifier",
                },
              }),
              _c("v-text-field", {
                staticClass: "no-inner-margin-top",
                attrs: {
                  label: _vm.$t(`integrations.shopware.${_vm.authType}.secret`),
                  type: _vm.hideSecret ? "password" : "text",
                  outlined: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "prepend-inner",
                    fn: function () {
                      return [
                        _c("BtnSquare", {
                          attrs: {
                            preset: "basic",
                            "aria-label": "Show or hide value",
                            icon: _vm.hideSecret ? "mdi-eye" : "mdi-eye-off",
                          },
                          on: {
                            click: function ($event) {
                              _vm.hideSecret = !_vm.hideSecret
                            },
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.secret,
                  callback: function ($$v) {
                    _vm.secret = $$v
                  },
                  expression: "secret",
                },
              }),
            ],
            1
          ),
          _c("v-text-field", {
            staticClass: "px-4",
            attrs: {
              label: _vm.$t("integrations.shopware.url.label"),
              rules: _vm.urlRules,
              placeholder: _vm.$t("integrations.shopware.url.placeholder"),
              outlined: "",
              type: "url",
            },
            model: {
              value: _vm.url,
              callback: function ($$v) {
                _vm.url = $$v
              },
              expression: "url",
            },
          }),
          _c(
            "div",
            { staticClass: "d-flex justify-end" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "ma-4",
                  attrs: {
                    color: "success",
                    disabled: !_vm.canStartTest || _vm.loading,
                  },
                  on: { click: _vm.test },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("integrations.shopware.performTest")) +
                      " "
                  ),
                  _c("v-icon", { attrs: { right: "", dark: "" } }, [
                    _vm._v("mdi-test-tube"),
                  ]),
                ],
                1
              ),
              !_vm.currentSettings
                ? _c(
                    "v-btn",
                    {
                      staticClass: "ma-4",
                      attrs: {
                        color: "success",
                        disabled: !_vm.testWasSuccessful || _vm.loading,
                      },
                      on: { click: _vm.save },
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.$t("common.connect")) + " "),
                      _c("v-icon", { attrs: { right: "", dark: "" } }, [
                        _vm._v("mdi-link-variant"),
                      ]),
                    ],
                    1
                  )
                : _c(
                    "v-btn",
                    {
                      staticClass: "ma-4",
                      attrs: {
                        color: "success",
                        disabled:
                          !_vm.testWasSuccessful ||
                          !_vm.canStartTest ||
                          _vm.loading,
                      },
                      on: { click: _vm.update },
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.$t("common.save")) + " "),
                      _c("v-icon", { attrs: { right: "", dark: "" } }, [
                        _vm._v("save"),
                      ]),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
      _c("SnackbarStack", { attrs: { scope: ["shopware.setup"] } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }