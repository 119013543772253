var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    { staticClass: "grey lighten-2 fill-height" },
    [
      _c(
        "v-container",
        { staticClass: "fill-height white", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { staticClass: "fill-height white" },
            [
              _c(
                "v-col",
                {
                  staticClass: "fill-height flex-column d-flex py-0",
                  attrs: { cols: "12" },
                },
                [
                  _c(
                    "v-row",
                    { staticClass: "flex-grow-0" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pa-0", attrs: { cols: "12" } },
                        [
                          _c(
                            "v-card-title",
                            {
                              staticClass: "pa-0",
                              staticStyle: { height: "60px" },
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "px-3 flex-nowrap",
                                  attrs: { cols: "8" },
                                },
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "pa-0 ma-0 flex-nowrap" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          class:
                                            _vm.isAdminOnly && "admin--text",
                                        },
                                        [
                                          _vm._t(
                                            "title",
                                            function () {
                                              return [
                                                _vm.title
                                                  ? _c(
                                                      "span",
                                                      {
                                                        key: _vm.title,
                                                        staticClass:
                                                          "text-truncate text-h5 ml-1 mt-1 mb-auto",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(_vm.title)
                                                        ),
                                                      ]
                                                    )
                                                  : _c("v-skeleton-loader", {
                                                      staticClass: "pt-2 grow",
                                                      attrs: {
                                                        boilerplate: "",
                                                        type: "heading",
                                                      },
                                                    }),
                                              ]
                                            },
                                            { title: _vm.title }
                                          ),
                                        ],
                                        2
                                      ),
                                      _vm.filter.length > 0 &&
                                      !_vm.showFilterAboveListHeader
                                        ? _c(
                                            "v-chip-group",
                                            {
                                              staticClass: "ml-4",
                                              attrs: {
                                                value: _vm.selectedFilter,
                                                multiple: "",
                                                "show-arrows": "",
                                                "active-class":
                                                  "text--accent-4",
                                              },
                                              on: { change: _vm.changeFilter },
                                            },
                                            [
                                              _vm._l(
                                                _vm.filter,
                                                function (
                                                  currentFilter,
                                                  index
                                                ) {
                                                  return [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        key: index + "-filter",
                                                        attrs: { bottom: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function ({
                                                                on,
                                                                attrs,
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "v-chip",
                                                                    _vm._g(
                                                                      _vm._b(
                                                                        {
                                                                          staticClass:
                                                                            "primary lighten-4",
                                                                          attrs:
                                                                            {
                                                                              disabled:
                                                                                currentFilter.disabled,
                                                                              "text-color":
                                                                                "primary lighten-3",
                                                                              outlined:
                                                                                "",
                                                                            },
                                                                        },
                                                                        "v-chip",
                                                                        attrs,
                                                                        false
                                                                      ),
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              small:
                                                                                "",
                                                                              left: "",
                                                                              color:
                                                                                currentFilter.selected
                                                                                  ? "primary lighten-2"
                                                                                  : "grey lighten-2",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                currentFilter.icon
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          class:
                                                                            {
                                                                              "hidden-sm-and-down":
                                                                                currentFilter.icon,
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  currentFilter.name
                                                                                )
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      },
                                                      [
                                                        currentFilter.selected
                                                          ? _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "common.filter.off"
                                                                  )
                                                                )
                                                              ),
                                                            ])
                                                          : _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "common.filter.on"
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                      ]
                                                    ),
                                                  ]
                                                }
                                              ),
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "text-right pt-3 pb-3",
                                  attrs: { cols: "4" },
                                },
                                [
                                  _vm._t("toolbar", null, {
                                    disabled: _vm.isLoading,
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c("v-divider"),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      staticClass: "flex-grow-1 flex-nowrap",
                      staticStyle: { height: "0%" },
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pa-0 fill-height moin-bg-grey-lightest",
                          class: _vm.contentClass,
                          attrs: {
                            cols: _vm.isSelected ? 12 - _vm.sidebarWidth : "12",
                          },
                        },
                        [
                          _c("v-divider", { staticClass: "grey lighten-4" }),
                          _vm.isLoading
                            ? _c("v-progress-linear", {
                                attrs: {
                                  indeterminate: "",
                                  color: "secondary darken-2",
                                },
                              })
                            : _vm._e(),
                          _c(
                            "v-row",
                            {
                              ref: "contentContainer",
                              staticClass: "overflow-y-auto ma-0 pa-0",
                              staticStyle: { height: "0%" },
                            },
                            [
                              _c(
                                "v-col",
                                { staticClass: "pa-0", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticClass:
                                        "d-flex flex-column px-3 pt-2 pb-3 fill-height",
                                      attrs: { color: "transparent" },
                                    },
                                    [
                                      _vm.filter.length > 0 &&
                                      _vm.showFilterAboveListHeader
                                        ? _c(
                                            "v-card",
                                            { attrs: { flat: "", tile: "" } },
                                            [
                                              _c(
                                                "v-card-text",
                                                [
                                                  _c(
                                                    "v-chip-group",
                                                    {
                                                      attrs: {
                                                        value:
                                                          _vm.selectedFilter,
                                                        multiple: "",
                                                        column: "",
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.changeFilter,
                                                      },
                                                    },
                                                    [
                                                      _vm._l(
                                                        _vm.filter,
                                                        function (
                                                          currentFilter,
                                                          index
                                                        ) {
                                                          return [
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                key:
                                                                  index +
                                                                  "-filter",
                                                                attrs: {
                                                                  bottom: "",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "activator",
                                                                        fn: function ({
                                                                          on,
                                                                          attrs,
                                                                        }) {
                                                                          return [
                                                                            _c(
                                                                              "v-chip",
                                                                              _vm._g(
                                                                                _vm._b(
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        disabled:
                                                                                          currentFilter.disabled,
                                                                                        color:
                                                                                          currentFilter.selected
                                                                                            ? "primary lighten-3"
                                                                                            : "grey lighten-1",
                                                                                        "text-color":
                                                                                          currentFilter.selected
                                                                                            ? "white"
                                                                                            : "grey",
                                                                                        outlined:
                                                                                          !currentFilter.selected,
                                                                                      },
                                                                                  },
                                                                                  "v-chip",
                                                                                  attrs,
                                                                                  false
                                                                                ),
                                                                                on
                                                                              ),
                                                                              [
                                                                                _c(
                                                                                  "v-icon",
                                                                                  {
                                                                                    staticClass:
                                                                                      "mr-2",
                                                                                    attrs:
                                                                                      {
                                                                                        small:
                                                                                          "",
                                                                                        color:
                                                                                          currentFilter.selected
                                                                                            ? "white"
                                                                                            : currentFilter.iconColor ||
                                                                                              "primary lighten-3",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          currentFilter.icon
                                                                                        ) +
                                                                                        " "
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "span",
                                                                                  {
                                                                                    class:
                                                                                      {
                                                                                        "hidden-sm-and-down":
                                                                                          currentFilter.icon,
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          _vm.$t(
                                                                                            currentFilter.name
                                                                                          )
                                                                                        ) +
                                                                                        " "
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                currentFilter.countItems()
                                                                                  ? _c(
                                                                                      "span",
                                                                                      {
                                                                                        staticClass:
                                                                                          "ml-sm-2",
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              currentFilter.countItems()
                                                                                            ) +
                                                                                            " "
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e(),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              },
                                                              [
                                                                currentFilter.selected
                                                                  ? _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "common.filter.off"
                                                                          )
                                                                        )
                                                                      ),
                                                                    ])
                                                                  : _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "common.filter.on"
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]),
                                                              ]
                                                            ),
                                                          ]
                                                        }
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._t("list-header"),
                                      _c(
                                        "v-list",
                                        {
                                          staticClass: "pt-0 pb-0 pr-0 pl-0",
                                          attrs: { "two-line": "" },
                                        },
                                        [
                                          _c(
                                            "v-list-item-group",
                                            {
                                              attrs: {
                                                value: _vm.selectedKey,
                                                color: "primary",
                                                "active-class":
                                                  _vm.noActiveBackground
                                                    ? "bg-active"
                                                    : "",
                                              },
                                              on: { change: _vm.changeItem },
                                            },
                                            [
                                              _vm._l(
                                                _vm.items,
                                                function (item, index) {
                                                  return [
                                                    _c("v-hover", {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            _vm.filterItem(
                                                              item
                                                            ),
                                                          expression:
                                                            "filterItem(item)",
                                                        },
                                                      ],
                                                      key:
                                                        "item-" +
                                                        item[_vm.itemKey],
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function ({
                                                              hover,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "v-list-item",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "intersect",
                                                                          rawName:
                                                                            "v-intersect",
                                                                          value:
                                                                            (
                                                                              entries
                                                                            ) =>
                                                                              _vm.onIntersect(
                                                                                entries,
                                                                                item
                                                                              ),
                                                                          expression:
                                                                            "(entries) => onIntersect(entries, item)",
                                                                        },
                                                                      ],
                                                                    ref: item[
                                                                      _vm
                                                                        .itemKey
                                                                    ],
                                                                    refInFor: true,
                                                                    class: {
                                                                      selectborder:
                                                                        _vm.isSelecteItem(
                                                                          item
                                                                        ),
                                                                      "pl-5":
                                                                        !_vm.isSelecteItem(
                                                                          item
                                                                        ),
                                                                    },
                                                                    attrs: {
                                                                      value:
                                                                        item[
                                                                          _vm
                                                                            .itemKey
                                                                        ],
                                                                      to: !_vm.isSidebar
                                                                        ? {
                                                                            path: item[
                                                                              _vm
                                                                                .itemKey
                                                                            ],
                                                                          }
                                                                        : null,
                                                                      append:
                                                                        !_vm.isSidebar,
                                                                    },
                                                                  },
                                                                  [
                                                                    !_vm.hideIcon
                                                                      ? _c(
                                                                          "v-list-item-icon",
                                                                          {
                                                                            staticClass:
                                                                              "mt-auto mb-auto",
                                                                          },
                                                                          [
                                                                            _vm._t(
                                                                              "item-icon",
                                                                              null,
                                                                              {
                                                                                item: item,
                                                                                selected:
                                                                                  _vm.isSelecteItem(
                                                                                    item
                                                                                  ),
                                                                              }
                                                                            ),
                                                                          ],
                                                                          2
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm._t(
                                                                      "item-avatar",
                                                                      null,
                                                                      {
                                                                        item: item,
                                                                      }
                                                                    ),
                                                                    _c(
                                                                      "v-list-item-content",
                                                                      [
                                                                        _vm._t(
                                                                          "item",
                                                                          function () {
                                                                            return [
                                                                              _c(
                                                                                "v-list-item-title",
                                                                                {
                                                                                  domProps:
                                                                                    {
                                                                                      textContent:
                                                                                        _vm._s(
                                                                                          item.displayName
                                                                                        ),
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ]
                                                                          },
                                                                          {
                                                                            item: item,
                                                                            selected:
                                                                              _vm.isSelecteItem(
                                                                                item
                                                                              ),
                                                                          }
                                                                        ),
                                                                      ],
                                                                      2
                                                                    ),
                                                                    !_vm.hideActions
                                                                      ? _c(
                                                                          "v-list-item-action",
                                                                          {
                                                                            staticClass:
                                                                              "align-self-center",
                                                                          },
                                                                          [
                                                                            _vm._t(
                                                                              "item-action",
                                                                              function () {
                                                                                return [
                                                                                  !_vm.noEdit
                                                                                    ? _c(
                                                                                        "BtnSquare",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              to: {
                                                                                                path: item[
                                                                                                  _vm
                                                                                                    .itemKey
                                                                                                ],
                                                                                              },
                                                                                              icon: "edit",
                                                                                              append:
                                                                                                "",
                                                                                            },
                                                                                        }
                                                                                      )
                                                                                    : _vm._e(),
                                                                                ]
                                                                              },
                                                                              {
                                                                                hover:
                                                                                  hover,
                                                                                item: item,
                                                                                noEdit:
                                                                                  _vm.noEdit,
                                                                                itemKey:
                                                                                  _vm.itemKey,
                                                                                selected:
                                                                                  _vm.isSelecteItem(
                                                                                    item
                                                                                  ),
                                                                              }
                                                                            ),
                                                                          ],
                                                                          2
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  2
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                    }),
                                                    _vm._t(
                                                      "item-append",
                                                      null,
                                                      {
                                                        item: item,
                                                        selected:
                                                          _vm.isSelecteItem(
                                                            item
                                                          ),
                                                      }
                                                    ),
                                                    _vm.filterItem(item) &&
                                                    index < _vm.items.length - 1
                                                      ? _c("v-divider", {
                                                          key: "div-" + index,
                                                          attrs: {
                                                            inset: _vm.inset,
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ]
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._t("list-footer"),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("HubContentSidebar", {
                        attrs: {
                          items: _vm.sidebarItems,
                          width: _vm.sidebarWidth,
                          value: _vm.value,
                          "open-sidebar": _vm.openSidebar,
                        },
                        on: { input: _vm.closeSidebar },
                        scopedSlots: _vm._u(
                          [
                            _vm._l(_vm.sidebarItems, function (sidebarItem) {
                              return {
                                key: sidebarItem.name,
                                fn: function ({ item }) {
                                  return [
                                    sidebarItem.name
                                      ? _vm._t(
                                          `sidebar.${sidebarItem.name}`,
                                          null,
                                          { item: item }
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              }
                            }),
                            _vm._l(
                              _vm.sidebarItems,
                              function (sidebarItem, index) {
                                return {
                                  key: index,
                                  fn: function ({ item }) {
                                    return [
                                      !sidebarItem.name
                                        ? _vm._t(`sidebar.${index}`, null, {
                                            item: item,
                                          })
                                        : _vm._e(),
                                    ]
                                  },
                                }
                              }
                            ),
                          ],
                          null,
                          true
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }