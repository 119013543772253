import axios from 'axios';

// {
//   "uniqueBotId": string,
//   "intent": string,
//   "message": string,
//   "contents": {boxes}
// }

/**
 * @typedef {Object} ConceptTaskSummaryRessources
 * @property {string[]} search List of URLs used
 */
/**
 * @typedef {Object} ConceptTaskSummary
 * @property {ConceptTaskSummaryRessources} ressources The resources used
 * @property {string} article A summarized article.
 * @property {boolean} no_information Flag to indicate if there is no information.
 * @property {string[]} sources List of source URLs.
 */
/**
 * @typedef {Object} ConceptTask
 * @property {string} _id The MongoDB ID of this concept task
 * @property {string[]} websites List of websites.
 * @property {Array<ConceptData>} concepts List of concepts, or at least an empty array
 * @property {string} botId The ID of the bot
 * @property {string} description Description of the generation
 * @property {string} intent The intent it was created for
 * @property {string} information
 * @property {string} status Status of the task (not indicative of the concepts or conversations).
 * @property {string} createdAt The creation date/time.
 * @property {string} updatedAt The date/time of last update.
 * @property {number} __v Mongoose version number for the document
 * @property {ConceptTaskSummary} summary The summary object.
 * @property {Array} conversations List of conversations.
 */
/**
 * @typedef {Object} ConceptData
 * @property {string} _id The MongoDB ID of the concept
 * @property {string[]} bulletpoints
 * @property {string} botId The ID of the bot
 * @property {string} title Display title for this concept
 * @property {string} description
 * @property {string} intent The intent this was created for
 * @property {string} conceptTask The ID of the concept task
 * @property {string} createdAt
 * @property {string} updatedAt
 * @property {number} __v
 * @property {?string} [conversationId]
 */

/**
 * @typedef {Object} ConversationExtendedContext
 * @property {string[]} sources
 * @property {ConceptTaskSummaryRessources} ressources
 */

/**
 * @typedef {Object} ConversationDataUser
 * @property {string[]} features
 * @property {string[]} blockedChannels
 * @property {string} username
 * @property {string} language
 * @property {number} iat
 * @property {number} exp
 * @property {string} aud
 */
/**
 * @typedef {Object} ConversationData
 * @property {string} _id
 * @property {Array} history
 * @property {string} botId
 * @property {string} intent
 * @property {string} userPrompt
 * @property {string} systemPrompt
 * @property {string} state
 * @property {ConversationExtendedContext} extendedContext
 * @property {ConversationDataUser} user
 * @property {Array} contents
 * @property {?string} [assistant] Populated when conversation is completed
 * @property {?Object[]} [json] Populated when conversation is completed
 * @property {?string} [output] Populated when conversation is completed
 * @property {string} createdAt
 * @property {string} updatedAt
 * @property {number} __v
 */

// And since JSDoc @extends doesn't work properly, here are some again with
// almost identical properties, but conversation is nested inside concept
/**
 * @typedef {Object} NestedConceptData
 * @property {string} _id The MongoDB ID of the concept
 * @property {string[]} bulletpoints
 * @property {string} botId The ID of the bot
 * @property {string} title Display title for this concept
 * @property {string} description
 * @property {string} intent The intent this was created for
 * @property {string} conceptTask The ID of the concept task
 * @property {string} createdAt
 * @property {string} updatedAt
 * @property {number} __v
 * @property {?string} [conversationId]
 * @property {?ConversationData} [conversation] Undefined if conversation generation has not begun.
 * Conversation object if partially done (running) or completed.
 */
/**
 * @typedef {Object} NestedConceptTaskData
 * @property {string} _id The MongoDB ID of this concept task
 * @property {string[]} websites List of websites.
 * @property {NestedConceptData[]} concepts List of concepts, or at least an empty array
 * @property {string} botId The ID of the bot
 * @property {string} description Description of the generation
 * @property {string} intent The intent it was created for
 * @property {string} information
 * @property {string} status Status of the task (not indicative of the concepts or conversations).
 * @property {string} createdAt The creation date/time.
 * @property {string} updatedAt The date/time of last update.
 * @property {number} __v Mongoose version number for the document
 * @property {ConceptTaskSummary} summary The summary object.
 * @property {Array} conversations List of conversations.
 */

const timeoutAmount = 600000;

export const AssistantService = {
  async startConversation({ uniqueBotId, intent, message, contents, signal, channel }) {
    try {
      const data = {
        uniqueBotId,
        intent,
        message,
        contents,
        channel,
      };

      const res = await axios.post('/schaltzentrale/assistant/create', data, {
        timeout: timeoutAmount,
      });

      if (signal.aborted) {
        return false;
      }

      if (!res.data?.conversation) {
        return {
          error: 'No conversation in response?!',
        };
      }

      return res.data;
    } catch (error) {
      console.error('startConversation', error);
      return {
        error,
      };
    }
  },
  async continueConversation({ uniqueBotId, conversationId, message, signal }) {
    try {
      const data = {
        uniqueBotId,
        conversationId,
        message,
      };

      const res = await axios.post('/schaltzentrale/assistant/continue', data, {
        timeout: timeoutAmount,
      });

      if (signal.aborted) {
        return false;
      }


      return res.data;
    } catch (error) {
      console.error('continueConversation', error);
      return {
        error,
      };
    }
  },
  async getConversation({ uniqueBotId, conversationId }) {
    try {
      const res = await axios.get(`/schaltzentrale/assistant/get/${conversationId}/${uniqueBotId}`, {
        timeout: timeoutAmount,
      });
      
      return res.data;
    } catch (error) {
      console.error('getConversation', error);
      return {
        error,
      };
    }
  },
  async generateAnswers({ uniqueBotId, intent, channel, conceptIds }) {
    try {
      const data = {
        uniqueBotId,
        intent,
        channel,
        conceptIds,
      };

      const res = await axios.post('/schaltzentrale/assistant/generate/answers', data, {
        timeout: timeoutAmount,
      });

      return res.data;
    } catch (error) {
      return {
        error,
      };
    }
  },
  async generateConcepts({ uniqueBotId, intent, channel, websites, informations }) {
    try {
      const data = {
        uniqueBotId,
        intent,
        channel,
        websites,
        informations,
      };

      const res = await axios.post('/schaltzentrale/assistant/generate/concepts', data, {
        timeout: timeoutAmount,
      });

      return res.data;
    } catch (error) {
      console.error('generateConcepts', error);
      return {
        error,
      };
    }
  },
  async getConceptTask({ uniqueBotId, taskId }) {
    try {
      const res = await axios.get(`/schaltzentrale/assistant/concepts/getTask/${taskId}/${uniqueBotId}`, {
        timeout: timeoutAmount,
      });

      return res.data;
    } catch (error) {
      console.error('getConceptTask', error);
      return false;
    }
  },
  async getConcept({ uniqueBotId, conceptId }) {
    try {
      const res = await axios.get(`/schaltzentrale/assistant/concepts/get/${conceptId}/${uniqueBotId}`, {
        timeout: timeoutAmount,
      });

      return res.data;
    } catch (error) {
      console.error('getConcept', error);
      return false;
    }
  },
  /**
   * Retrieves all possible information, except the box contents, for a given concept task.
   * @param uniqueBotId
   * @param intent
   * @returns {Promise<null|NestedConceptTaskData>}
   */
  async getFullConceptTask({ uniqueBotId, intent, channelId }) {
    try {
      /**
       * @type {{data: {task: ConceptTask}}}
       */
      let url = `/schaltzentrale/assistant/concepts/getFull/${intent}/${uniqueBotId}`;
      if (channelId) url += `/${channelId}`;

      const res = await axios.get(url, {
        timeout: timeoutAmount,
      });
      if (!res.data?.task) return null;

      for (const concept of res.data.task?.concepts) {
        const found = res.data.task.conversations.find(c => c._id === concept.conversation);
        if (!found) continue;

        concept.conversation = found;
      }

      return res.data.task;
    } catch (error) {
      console.error('getFullConceptTask', error);
      return false;
    }
  }
};
