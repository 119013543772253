var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "DemoStep",
    { attrs: { slug: "stepContact" } },
    [
      _c(
        "v-form",
        {
          ref: "form",
          attrs: { "lazy-validation": "" },
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c(
            "div",
            [
              _c("v-text-field", {
                attrs: {
                  outlined: "",
                  label: _vm.$t("demo.stepContact.labelCompanyName"),
                  rules: _vm.contactCompanyRules,
                  required: "",
                  validateOnBlur: _vm.valid,
                },
                model: {
                  value: _vm.contact.company,
                  callback: function ($$v) {
                    _vm.$set(_vm.contact, "company", $$v)
                  },
                  expression: "contact.company",
                },
              }),
              _c("v-text-field", {
                attrs: {
                  outlined: "",
                  label: _vm.$t("demo.stepContact.labelName"),
                  required: "",
                  validateOnBlur: _vm.valid,
                },
                model: {
                  value: _vm.contact.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.contact, "name", $$v)
                  },
                  expression: "contact.name",
                },
              }),
              _c("v-text-field", {
                attrs: {
                  outlined: "",
                  label: _vm.$t("demo.stepContact.labelEmail"),
                  rules: _vm.contactEmailRules,
                  required: "",
                  validateOnBlur: _vm.valid,
                },
                model: {
                  value: _vm.contact.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.contact, "email", $$v)
                  },
                  expression: "contact.email",
                },
              }),
              _c("v-text-field", {
                attrs: {
                  outlined: "",
                  label: _vm.$t("demo.stepContact.labelPhone"),
                  validateOnBlur: _vm.valid,
                },
                model: {
                  value: _vm.contact.phone,
                  callback: function ($$v) {
                    _vm.$set(_vm.contact, "phone", $$v)
                  },
                  expression: "contact.phone",
                },
              }),
              _c("div", { staticClass: "d-flex mb-4" }, [
                _c(
                  "div",
                  { staticClass: "flex-shrink-0 mr-2" },
                  [
                    _c("Checkbox", {
                      attrs: { value: _vm.contact.marketing },
                      on: {
                        onClick: function ($event) {
                          _vm.contact.marketing = !_vm.contact.marketing
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c("div", {
                  staticClass: "text-body-2 primary--text text--lighten-2",
                  domProps: {
                    innerHTML: _vm._s(_vm.$t("demo.stepContact.infoConsent")),
                  },
                }),
              ]),
              _c("div", { staticClass: "d-flex" }, [
                _c(
                  "div",
                  { staticClass: "flex-shrink-0 mr-2" },
                  [
                    _c("Checkbox", {
                      attrs: {
                        value: _vm.contact.privacyAccepted,
                        error: _vm.showPrivacyError,
                      },
                      on: {
                        onClick: function ($event) {
                          return _vm.togglePrivacyAccepted()
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c("div", {
                  staticClass: "text-body-2 primary--text text--lighten-2",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$t("demo.stepContact.privacyConsent")
                    ),
                  },
                }),
              ]),
              _vm.showPrivacyError
                ? _c("ErrorMessage", {
                    staticClass: "ml-7 mt-2",
                    attrs: {
                      text: _vm.$t("demo.stepContact.rulePrivacyConsent"),
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _c(
        "DemoBtns",
        {
          attrs: { hideNextBtn: "" },
          on: {
            onBack: function ($event) {
              return _vm.back()
            },
          },
        },
        [
          _c(
            "v-btn",
            {
              attrs: { color: "secondary", depressed: "" },
              on: {
                click: function ($event) {
                  return _vm.next()
                },
              },
            },
            [
              _vm._v(" " + _vm._s(_vm.$t("demo.stepContact.btnNext")) + " "),
              _vm.$vuetify.breakpoint.smAndUp
                ? _c("v-icon", { attrs: { right: "" } }, [
                    _vm._v("mdi-check-bold"),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }