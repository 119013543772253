var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    {
      class: [!_vm.noBg && "moin-bg-grey-lightest"],
      staticStyle: { "max-height": "100%" },
    },
    [
      _c(
        "div",
        { staticClass: "fill-height flex-column d-flex" },
        [
          _c("div", { staticClass: "flex-grow-0" }, [_vm._t("navbar")], 2),
          _c(
            "v-row",
            {
              staticClass: "ma-0 flex-grow-1 flex-nowrap",
              staticStyle: { height: "0%", "max-height": "100%" },
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "fill-height pa-0",
                  class: [
                    _vm.isSidebarOpenFade
                      ? "flex-md-column d-md-flex flex-md-grow-1 hidden-sm-and-down"
                      : "flex-column d-flex fill-height flex-grow-1",
                    _vm.fixedHeight
                      ? "hub-content-fixed-height"
                      : "hub-content-scroll",
                  ],
                  attrs: {
                    cols: _vm.isSidebarOpenFade ? 12 - _vm.sidebarWidth : "12",
                  },
                },
                [_vm._t("content")],
                2
              ),
              _vm._t("sidebar"),
            ],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }