<template>
  <div v-if="activeChat">
    <div class="livechat-history">
      <div v-for="(item, index) in messages" :key="index">
        <LivechatChatItem v-if="!item.hidden" :item="item" :isTranslated="useTranslation && typeof item.translated !== 'undefined'" :chat="activeChat" />
      </div>
    </div>
    <LivechatDivider />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import LivechatChatItem from '@/components/livechat/LivechatChatItem.vue';
import LivechatDivider from './LivechatDivider.vue';
import moment from 'moment';

export default {
  name: '',
  components: {
    LivechatChatItem,
    LivechatDivider,
  },
  computed: {
    ...mapGetters('livechat', ['activeChat', 'chatHistoryById', 'chatTranslations', 'useTranslation']),
    messages() {
      const chatHistory = this.chatHistoryById(this.activeChat.uuid);
      if (!chatHistory) return [];
      const messages = [];
      const firstLivechatMessage = this.activeChat.messages[this.activeChat.messages.length - 1];
      chatHistory.filter(item => moment(item.date).isBefore(moment(firstLivechatMessage?.created_at || new Date()))).forEach((item) => {
        let sender = {};

        if (item.user) {
          sender = {
            ...this.activeChat.user,
            role: 'user',
          };
        } else if (item.livechat) {
          sender = {
            role: 'agent',
          };
        } else {
          sender = {
            role: 'bot',
          };
        }

        const message = {
          data: '',
          sender,
          type: item.type,
          created_at: item.date,
          livechat: item.livechat || false,
          uuid: item.uuid,
        };

        if (item.message?.message?.text) {
          message.data = item.message?.message?.text;
        } else if (item.text) {
          message.data = item.text;
        } else {
          message.hidden = true;
        }
        if (this.useTranslation) {
          message.translated = this.chatTranslations[message.data];
        }
        messages.push(message);
      });

      // Ignore last messages from user
      for (let i = messages.length - 1; i >= 0; i -= 1) {
        if (messages[i].sender.role === 'user') {
          messages[i].hidden = true;
        } else {
          break;
        }
      }

      return messages;
    },
  },
};
</script>
