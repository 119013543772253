var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { staticStyle: { "max-height": "100vh", height: "100vh" } },
    [
      _c("HubContent", {
        staticClass: "moin-fresh",
        attrs: { fixedHeight: "" },
        scopedSlots: _vm._u([
          {
            key: "navbar",
            fn: function () {
              return [
                _c("HubNavbar", {
                  attrs: { isLoading: _vm.loading, title: "Livechat" },
                  scopedSlots: _vm._u([
                    {
                      key: "toolbar",
                      fn: function () {
                        return [
                          _vm.$vuetify.breakpoint.mdAndUp
                            ? _c("LivechatAmountInfo")
                            : _vm._e(),
                          _c("LivechatHeaderMenu"),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass: "full-height",
                    class: _vm.$vuetify.breakpoint.smAndUp && "pa-3",
                  },
                  [_c("Livechat")],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }