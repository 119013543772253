var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "question-item" }, [
    _c(
      "div",
      {
        staticClass: "d-flex pa-4",
        style: { opacity: _vm.question.checked ? 1 : 0.6 },
      },
      [
        _vm.isAdmin
          ? _c(
              "div",
              {
                staticClass: "flex-shrink-0 moin-cursor-pointer",
                staticStyle: { width: "40px" },
                on: {
                  click: function ($event) {
                    return _vm.$emit("onAnswer")
                  },
                },
              },
              [
                _c("v-icon", { attrs: { color: "primary lighten-3" } }, [
                  _vm._v("mdi-message-text-outline"),
                ]),
              ],
              1
            )
          : _vm._e(),
        _c("div", { staticClass: "flex-grow-1" }, [
          _c(
            "span",
            {
              class: [!_vm.question.checked && "text-decoration-line-through"],
              staticStyle: { "overflow-wrap": "anywhere" },
            },
            [_vm._v(" " + _vm._s(_vm.question.text) + " ")]
          ),
          _c("div", { staticClass: "relative" }, [
            !_vm.question.checked
              ? _c(
                  "div",
                  {
                    staticClass: "grey--text",
                    staticStyle: {
                      position: "absolute",
                      top: "-2px",
                      left: "0",
                      "font-size": "0.7em",
                    },
                  },
                  [_vm._v(" Wird für nächsten Schritt ignoriert. ")]
                )
              : _vm._e(),
          ]),
        ]),
        _c(
          "div",
          {
            staticClass: "flex-shrink-0 d-flex items-center pl-4",
            on: {
              click: function ($event) {
                return _vm.onToggle()
              },
            },
          },
          [_c("Checkbox", { attrs: { value: _vm.question.checked } })],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }