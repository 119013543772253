var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "question-item d-flex" }, [
    _c(
      "div",
      { staticStyle: { width: "36px" } },
      [
        _c("v-icon", { attrs: { color: "primary lighten-3" } }, [
          _vm._v("mdi-folder-text"),
        ]),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "font-weight-bold text-body-1 flex-grow-1 primary--text",
        staticStyle: { "padding-top": "2px" },
      },
      [_vm._v(" " + _vm._s(_vm.intent.displayName) + " ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }