var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("HubBoxSimple", {
        attrs: {
          title: _vm.$t("stats.export.title"),
          icon: "mdi-file-download-outline",
          isLoading: _vm.isLoading,
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("p", { staticClass: "body-text-2" }, [
                  _vm._v(" " + _vm._s(_vm.$t("stats.export.info")) + " "),
                ]),
                _c(
                  "v-btn",
                  {
                    staticClass: "mt-4",
                    attrs: {
                      outlined: "",
                      color: "primary",
                      loading: _vm.isLoading,
                    },
                    on: {
                      click: function ($event) {
                        _vm.isDialogOpen = true
                        _vm.trackIt("stats-export-open")
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("stats.export.btnSelect")) + " "
                    ),
                    _c("v-icon", { attrs: { right: "" } }, [
                      _vm._v("mdi-file-download-outline"),
                    ]),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "HubDialog",
        {
          attrs: {
            value: _vm.isDialogOpen,
            title: _vm.$t("stats.export.title"),
            hideActions: "",
          },
          on: {
            input: function ($event) {
              _vm.isDialogOpen = false
            },
          },
        },
        [
          _c("div", { staticClass: "text-body-2 mb-4" }, [
            _c("div", [_vm._v('Bot: "' + _vm._s(_vm.currentBot.name) + '"')]),
            _vm.channel
              ? _c("div", [
                  _vm._v(
                    _vm._s(_vm.$t("common.channel")) +
                      ": " +
                      _vm._s(_vm.channelDisplayName)
                  ),
                ])
              : _vm._e(),
            _c("div", [
              _vm._v(
                _vm._s(_vm.$t("dateRange.labelDateRange")) +
                  ": " +
                  _vm._s(_vm.dateRangeFormatted)
              ),
            ]),
            _vm.dateRangeCompareFormatted
              ? _c("div", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("dateRange.labelDateRangeCompare")) +
                      ": " +
                      _vm._s(_vm.dateRangeCompareFormatted) +
                      " "
                  ),
                ])
              : _vm._e(),
          ]),
          _c("v-divider", { staticClass: "my-6" }),
          _c("div", { staticClass: "text-body-2 mb-4" }, [
            _vm._v(" " + _vm._s(_vm.$t("stats.export.selectOption")) + " "),
          ]),
          _c(
            "div",
            { staticClass: "text-body-2 mb-2" },
            [
              _c(
                "v-icon",
                {
                  staticClass: "mr-2",
                  attrs: { size: "20", color: "primary lighten-3" },
                },
                [_vm._v(" " + _vm._s(_vm.exportOptions[0].icon) + " ")]
              ),
              _c("strong", [
                _vm._v(_vm._s(_vm.$t("stats.export.optionPDF.title")) + ":"),
              ]),
              _vm._v(
                " " + _vm._s(_vm.$t("stats.export.optionPDF.description")) + " "
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "text-body-2 mb-2" },
            [
              _c(
                "v-icon",
                {
                  staticClass: "mr-2",
                  attrs: { size: "18", color: "primary lighten-3" },
                },
                [_vm._v(" " + _vm._s(_vm.exportOptions[1].icon) + " ")]
              ),
              _c("strong", [
                _vm._v(_vm._s(_vm.$t("stats.export.optionCSV.title")) + ":"),
              ]),
              _vm._v(
                " " + _vm._s(_vm.$t("stats.export.optionCSV.description")) + " "
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "text-body-2 mb-2" },
            [
              _c(
                "v-icon",
                {
                  staticClass: "mr-2",
                  attrs: { size: "18", color: "primary lighten-3" },
                },
                [_vm._v(" " + _vm._s(_vm.exportOptions[2].icon) + " ")]
              ),
              _c("strong", [
                _vm._v(_vm._s(_vm.$t("stats.export.optionCSV2.title")) + ":"),
              ]),
              _vm._v(
                " " +
                  _vm._s(_vm.$t("stats.export.optionCSV2.description")) +
                  " "
              ),
            ],
            1
          ),
          _c("v-select", {
            staticClass: "my-6",
            attrs: {
              label: _vm.$t("stats.export.exportKind"),
              items: _vm.exportOptions,
              "item-text": "label",
              "item-value": "value",
              disabled: _vm.isExporting,
            },
            model: {
              value: _vm.selectedOption,
              callback: function ($$v) {
                _vm.selectedOption = $$v
              },
              expression: "selectedOption",
            },
          }),
          _c(
            "v-card",
            {
              staticClass: "d-flex align-center pa-4",
              staticStyle: { "min-height": "80px" },
              attrs: { outlined: "" },
            },
            [
              _vm.isExporting
                ? _c("v-progress-linear", {
                    staticStyle: { top: "0", left: "0" },
                    attrs: {
                      absolute: "",
                      indeterminate: "",
                      color: "secondary darken-2",
                    },
                  })
                : _vm._e(),
              !_vm.selectedOption
                ? _c("div", { staticClass: "text-body-2" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("stats.export.noOptionSelected")) +
                        " "
                    ),
                  ])
                : _vm._e(),
              _vm.selectedOption
                ? _c(
                    "v-row",
                    [
                      _vm.selectedOptionObject.value ===
                      _vm.exportTypes.OVERVIEW_PDF
                        ? [
                            _c("v-col", { attrs: { cols: "12", sm: "7" } }, [
                              _c(
                                "div",
                                { staticClass: "d-flex align-center" },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            size: "32",
                                            color: "primary",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.selectedOptionObject.icon
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "pl-2" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-body font-weight-bold",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.selectedOptionObject.label
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "text-body-2 grey--text" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.selectedOptionObject.labelSub
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                            _c(
                              "v-col",
                              {
                                staticClass: "d-flex align-center justify-end",
                                attrs: { cols: "12", sm: "5" },
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      depressed: "",
                                      color: "primary lighten-2",
                                    },
                                    on: { click: _vm.downloadExport },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("common.download")) +
                                        " "
                                    ),
                                    _c("v-icon", { attrs: { right: "" } }, [
                                      _vm._v("mdi-download"),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        : [
                            _c("v-col", { attrs: { cols: "12", sm: "7" } }, [
                              !_vm.isExportDone
                                ? _c(
                                    "div",
                                    { staticClass: "d-flex align-center" },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                size: "32",
                                                color: "primary",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.selectedOptionObject
                                                      .icon
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("div", { staticClass: "pl-2" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-body font-weight-bold",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.selectedOptionObject.label
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-body-2 grey--text",
                                          },
                                          [
                                            !_vm.isExporting &&
                                            !_vm.isExportDone
                                              ? _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.selectedOptionObject
                                                          .labelSub
                                                      ) +
                                                      " "
                                                  ),
                                                ])
                                              : _vm._e(),
                                            _vm.isExporting
                                              ? _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.loadingInfo) +
                                                      " "
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.isExportDone
                                ? _c(
                                    "div",
                                    { staticClass: "d-flex align-center" },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                size: "32",
                                                color: "primary",
                                              },
                                            },
                                            [_vm._v("task")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("div", { staticClass: "pl-2" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-body font-weight-bold",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "stats.export.exportDone1"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-body-2 grey--text",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "stats.export.exportDone2"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                            _c(
                              "v-col",
                              {
                                staticClass: "d-flex align-center justify-end",
                                attrs: { cols: "12", sm: "5" },
                              },
                              [
                                !_vm.isExportDone
                                  ? _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          outlined: "",
                                          color: "primary lighten-2",
                                          disabled: _vm.isExporting,
                                        },
                                        on: { click: _vm.startExport },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "stats.export.btnCreateCSV"
                                              )
                                            ) +
                                            " "
                                        ),
                                        _c("v-icon", { attrs: { right: "" } }, [
                                          _vm._v("mdi-database-export-outline"),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.isExportDone
                                  ? _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          depressed: "",
                                          color: "primary lighten-2",
                                        },
                                        on: { click: _vm.downloadExport },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("common.download")) +
                                            " "
                                        ),
                                        _c("v-icon", { attrs: { right: "" } }, [
                                          _vm._v("mdi-download"),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("StatsOverviewExport", { ref: "exportOverview" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }