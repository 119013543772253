var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fill-height" },
    [
      _c(
        "v-card",
        { staticClass: "fill-height pa-4", attrs: { outlined: "" } },
        [
          _c("h2", { staticClass: "text-h6" }, [_vm._v("Models")]),
          _c("v-divider", { staticClass: "my-2" }),
          _vm.k1Model
            ? _c("div", { staticClass: "text-body-2 mb-2" }, [
                _c("div", { staticClass: "moin-label moin-col-label" }, [
                  _vm._v("K1:"),
                ]),
                _c("div", { staticClass: "moin-col-data" }, [
                  _vm._v(_vm._s(_vm.k1Model.model_id)),
                ]),
              ])
            : _vm._e(),
          _vm.wisdomModel
            ? _c("div", { staticClass: "text-body-2 mb-2" }, [
                _c("div", { staticClass: "moin-label moin-col-label" }, [
                  _vm._v("Wisdom:"),
                ]),
                _c("div", { staticClass: "moin-col-data" }, [
                  _vm._v(_vm._s(_vm.wisdomModel.model_id)),
                ]),
              ])
            : _vm._e(),
          _vm.nlpTemplate
            ? _c("div", { staticClass: "text-body-2 mb-2" }, [
                _c("div", { staticClass: "moin-label moin-col-label" }, [
                  _vm._v("Template:"),
                ]),
                _c("div", { staticClass: "moin-col-data" }, [
                  _vm._v(" " + _vm._s(_vm.nlpTemplate) + " "),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }