var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c(
        "HubBg",
        [
          _c(
            "HubPanelLogo",
            [
              _vm.requestReset ? _c("RequestResetForm") : _vm._e(),
              _vm.setNewPassword ? _c("SetNewPasswordForm") : _vm._e(),
              _vm.completeSSOBotInvite
                ? _c("CompleteSSOBotInvite")
                : _vm.completeBotInvite
                ? _c("CompleteBotInviteForm")
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }