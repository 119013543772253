<template>
  <v-row>
    <v-col>
      <h2 class="text-h6">Passwort ändern</h2>
      <p class="text-copy-2">
        Bitte gib dein aktuelles Passwort ein, um ein neues Passwort
        festzulegen.
      </p>
      <v-text-field
        outlined
        label="Aktuelles Passwort"
        type="password"
        v-model="currentPassword"
      ></v-text-field>

      <p class="text-copy-2">
        Bitte gib dein aktuelles Passwort ein, um ein neues Passwort
        festzulegen.
      </p>
      <v-text-field
        outlined
        type="password"
        label="Neues Passwort"
        v-model="newPassword"
        :disabled="!currentPassword.length"
      ></v-text-field>
      <v-text-field
        outlined
        type="password"
        label="Neues Passwort bestätigt"
        v-model="newPasswordConfirm"
        :disabled="!currentPassword.length"
      ></v-text-field>

      <InfoBox
        :text="'Das Passwort muß mindestens aus 8 Zeichen bestehen ...'"
      />
    </v-col>
  </v-row>
</template>
<script>
import InfoBox from '@/components/common/InfoBox.vue';
export default {
  name: 'LivechatProfileAgent',
  components: { InfoBox },
  data() {
    return {
      currentPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
    };
  },
};
</script>
