var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "LivechatSpecialViewWrapper",
    {
      attrs: { id: "agent-in-other-org" },
      scopedSlots: _vm._u([
        {
          key: "secondary",
          fn: function () {
            return [
              _c("h3", { staticClass: "tet-h3 mb-2" }, [
                _vm._v(
                  _vm._s(_vm.$t("livechat.screens.noAgentsAssigned.title"))
                ),
              ]),
              _c("p", { staticClass: "text-body-1 mb-8" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("livechat.screens.noAgentsAssigned.description")
                    ) +
                    " "
                ),
              ]),
              _vm.canManageAgents
                ? _c(
                    "div",
                    { staticClass: "d-flex mt-3 justify-end" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "secondary",
                            to: { name: "settings-livechat" },
                            depressed: "",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "livechat.screens.noAgentsAssigned.ctaLabel"
                                )
                              ) +
                              " "
                          ),
                          _c("v-icon", { staticClass: "ml-2" }, [
                            _vm._v("settings"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      [
        _c("LivechatStateCallout", {
          staticClass: "mb-4",
          attrs: {
            classes: "text--lighten-2 primary--text",
            title: _vm.$t("livechat.screens.noAgentsAssigned.noAgents"),
            icon: "mdi-face-agent",
            description: _vm.$t(
              "livechat.screens.noAgentsAssigned.noAgentsDescription"
            ),
          },
        }),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }