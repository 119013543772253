var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c("v-card-title", [
        _c("h3", { staticClass: "text-h4 mb-0" }, [
          _vm._v(
            _vm._s(`${_vm.notification.type} || ${_vm.notification.updatedAt}`)
          ),
        ]),
      ]),
      _c("ChipsArrayInput", {
        attrs: {
          values: _vm.recipients,
          placeholder: _vm.$t("botNotifications.recipientsOverridePlaceholder"),
          validator: _vm.emailValidator,
        },
        on: {
          onRemoveValue: _vm.removeRecipient,
          onAddValue: _vm.addRecipient,
        },
      }),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            { attrs: { color: "primary" }, on: { click: _vm.resend } },
            [_vm._v(" " + _vm._s(_vm.$t("botNotifications.resendBtn")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }