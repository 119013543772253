var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "h2",
        { staticClass: "headline primary--text mb-3" },
        [
          _c(
            "v-icon",
            {
              staticClass: "mr-2",
              attrs: { left: "", color: "primary lighten-3" },
            },
            [_vm._v(" mdi-chart-timeline-variant-shimmer ")]
          ),
          _c("span", [_vm._v(_vm._s(_vm.$t("forms.analysis.title")))]),
        ],
        1
      ),
      _c("p", { staticClass: "primary--text" }, [
        _vm._v(" " + _vm._s(_vm.$t("forms.analysis.subTitle")) + " "),
        _vm.noDataAndTracking
          ? _c("span", [
              _vm._v(
                " " + _vm._s(_vm.$t("forms.analysis.nodataandtracking")) + " "
              ),
            ])
          : _vm._e(),
      ]),
      _c("div", [
        _c(
          "div",
          { staticClass: "d-flex" },
          [
            _c(
              "div",
              [
                _c(
                  "v-icon",
                  {
                    staticClass: "mr-2 mt-n1",
                    attrs: {
                      color: !_vm.noDataAndTracking
                        ? "secondary darken-2"
                        : "grey lighten-2",
                    },
                  },
                  [_vm._v(" date_range ")]
                ),
                _c("span", { staticClass: "text-body-1" }, [
                  _vm._v(" " + _vm._s(_vm.dateRangeFormat) + " "),
                ]),
              ],
              1
            ),
            _c(
              "v-menu",
              {
                ref: "menu",
                attrs: {
                  "close-on-content-click": false,
                  transition: "scale-transition",
                  "offset-y": "",
                  "max-width": "290px",
                  "min-width": "290px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function ({ on }) {
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            {
                              staticClass: "ml-2 text-body-1",
                              attrs: {
                                small: "",
                                text: "",
                                color: "secondary darken-3",
                                disabled: _vm.noDataAndTracking,
                              },
                            },
                            on
                          ),
                          [_vm._v(" " + _vm._s(_vm.$t("common.change")) + " ")]
                        ),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.isDateMenuOpen,
                  callback: function ($$v) {
                    _vm.isDateMenuOpen = $$v
                  },
                  expression: "isDateMenuOpen",
                },
              },
              [
                _c(
                  "v-date-picker",
                  {
                    attrs: { "no-title": "", range: "", color: "secondary" },
                    model: {
                      value: _vm.dateRange,
                      callback: function ($$v) {
                        _vm.dateRange = $$v
                      },
                      expression: "dateRange",
                    },
                  },
                  [
                    _c("v-spacer"),
                    _c(
                      "v-btn",
                      {
                        attrs: { text: "", color: "primary" },
                        on: {
                          click: function ($event) {
                            _vm.isDateMenuOpen = false
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("common.cancel")) + " ")]
                    ),
                    _c(
                      "v-btn",
                      {
                        attrs: { text: "", color: "primary" },
                        on: { click: _vm.changeDateRange },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("common.ok")) + " ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "mt-8 mb-5 pb-10" },
        [
          _c("h2", { staticClass: "text-h6 primary--text mb-0" }, [
            _vm._v(" " + _vm._s(_vm.$t("forms.analysis.resultTitle")) + " "),
          ]),
          !_vm.noData
            ? _c(
                "div",
                {
                  staticClass:
                    "text-body-2 primary--text text--lighten-3 mt-n2",
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("forms.analysis.result", {
                          finished: String(_vm.resultFinished),
                        })
                      ) +
                      " "
                  ),
                ]
              )
            : _vm._e(),
          _c("BarPercentage", {
            staticClass: "mt-4",
            attrs: {
              large: "",
              buffer: _vm.resultPercentage,
              value: _vm.resultPercentage,
              "no-data": _vm.noData,
              tooltip: _vm.$t("forms.analysis.tooltipResult", {
                started: String(_vm.resultStarted),
                finished: String(_vm.resultFinished),
              }),
            },
          }),
          _vm.isAdmin && _vm.resultFinished > 0
            ? _c(
                "div",
                { staticClass: "mt-6" },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "", color: "primary lighten-3" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({
                              on: onTooltip,
                              attrs: attrsTooltip,
                            }) {
                              return [
                                _vm.resultFinished > 0
                                  ? _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            attrs: {
                                              outlined: "",
                                              "x-small": "",
                                              color: "admin",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.openLeads(
                                                  _vm.finishedUsers
                                                )
                                              },
                                            },
                                          },
                                          "v-btn",
                                          attrsTooltip,
                                          false
                                        ),
                                        onTooltip
                                      ),
                                      [
                                        _vm._v(" Leads "),
                                        _c("v-icon", { attrs: { right: "" } }, [
                                          _vm._v("mdi-list-box-outline"),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1602067073
                      ),
                    },
                    [_c("span", [_vm._v(_vm._s("Resultate anzeigen"))])]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("h2", { staticClass: "text-h6 primary--text mt-0 mb-0" }, [
        _vm._v(" " + _vm._s(_vm.$t("forms.analysis.resultStepTitle")) + " "),
      ]),
      _vm._l(_vm.insightSteps, function (formStep, index) {
        return _c("BotFormsInsightsItem", {
          key: index,
          attrs: {
            formStep: formStep,
            intent: _vm.form.intent,
            channelId: _vm.channelId,
            "no-data": _vm.noData,
          },
          on: { openLeads: _vm.openLeads },
        })
      }),
      _c(
        "HubDialog",
        {
          attrs: { maxWidth: "100%", btnsRight: "" },
          model: {
            value: _vm.leadExportDialog,
            callback: function ($$v) {
              _vm.leadExportDialog = $$v
            },
            expression: "leadExportDialog",
          },
        },
        [
          _c("h1", { staticClass: "text-h5 mb-4" }, [
            _vm._v("Leads " + _vm._s(_vm.dateRangeFormat)),
          ]),
          _c("v-data-table", {
            attrs: { headers: _vm.leadHeaders, items: _vm.leads, dense: "" },
            scopedSlots: _vm._u(
              [
                {
                  key: `item.createdAt`,
                  fn: function ({ item }) {
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.formatCreatedAt(
                              item.createdAt,
                              _vm.$t("common.dateTimeFormat")
                            )
                          )
                        ),
                      ]),
                    ]
                  },
                },
                {
                  key: `item.actions`,
                  fn: function ({ item }) {
                    return [
                      _c("BtnSquare", {
                        attrs: {
                          icon: "open_in_new",
                          to: `/chats/messaging/${item.uniqueUserId}?jumpto='${item.uuid}'`,
                          target: "_blank",
                        },
                      }),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          }),
          _c(
            "div",
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    outlined: "",
                    color: "primary lighten-3",
                    disabled: !_vm.leads.length,
                  },
                  on: { click: _vm.downloadLeadsAsCsv },
                },
                [
                  _vm._v(" CSV Download "),
                  _c("v-icon", { attrs: { right: "" } }, [
                    _vm._v("mdi-file-delimited"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }