var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.content
    ? _c(
        "div",
        [
          _vm.content
            ? _c(
                "v-timeline-item",
                {
                  attrs: {
                    "hide-dot": "",
                    color: "grey lighten-3",
                    "fill-dot": "",
                  },
                },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            staticClass: "font-weight-medium ma-0",
                            attrs: {
                              outlined: "",
                              dense: "",
                              label: _vm.$t("botFormEdit.whatIsAsked"),
                            },
                            on: { input: _vm.update },
                            model: {
                              value: _vm.content.displayName,
                              callback: function ($$v) {
                                _vm.$set(_vm.content, "displayName", $$v)
                              },
                              expression: "content.displayName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("v-col", [
                        _vm.isAdmin
                          ? _c(
                              "div",
                              { staticClass: "moin-admin-box pa-1" },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    outlined: "",
                                    dense: "",
                                    label: _vm.$t("botFormEdit.technicalName"),
                                    "hide-details": "",
                                  },
                                  on: { input: _vm.update },
                                  model: {
                                    value: _vm.content.context,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.content, "context", $$v)
                                    },
                                    expression: "content.context",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("ActionList", {
            attrs: {
              actions: this.content.question.actions,
              withoutCards: "",
              schema: "*t",
              channelType: _vm.channelType,
            },
            on: { onUpdate: _vm.update, "hook:mounted": _vm.updateName },
          }),
          _c(
            "v-timeline-item",
            {
              attrs: { color: "white" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "icon",
                    fn: function () {
                      return [
                        _c("v-icon", { attrs: { small: "" } }, [
                          _vm._v("account_tree"),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                764985146
              ),
            },
            [
              _c(
                "v-tabs",
                {
                  attrs: { "show-arrows": "", value: _vm.selectedTab },
                  on: { change: _vm.selectTab },
                },
                [
                  _vm._l(_vm.cards, function (card, index) {
                    return _c("v-tab", { key: index }, [
                      _c(
                        "span",
                        {
                          staticClass: "text-truncate d-inline-block text-left",
                          staticStyle: { "max-width": "180px" },
                        },
                        [_vm._v(_vm._s(card.title.data))]
                      ),
                    ])
                  }),
                  _c(
                    "v-btn",
                    {
                      staticClass: "align-self-center mr-4",
                      attrs: { disabled: _vm.cards.length >= 10, icon: "" },
                      on: { click: _vm.addCard },
                    },
                    [_c("v-icon", { attrs: { small: "" } }, [_vm._v("add")])],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "align-self-center mr-4",
                      attrs: { disabled: _vm.cards.length < 2, icon: "" },
                      on: { click: _vm.removeCard },
                    },
                    [
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v("remove"),
                      ]),
                    ],
                    1
                  ),
                  _vm._l(_vm.cards, function (card, index) {
                    return _c(
                      "v-tab-item",
                      { key: index, staticClass: "pt-4" },
                      [
                        _c(
                          "v-card",
                          { attrs: { flat: "" } },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "6", md: "3" } },
                                  [
                                    _c("ImageUpload", {
                                      attrs: { mediaSrc: card.image.data },
                                      on: {
                                        onUpload: (imageFile) =>
                                          _vm.uploadImage(card, imageFile),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "6" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        label: _vm.$t("botFormEdit.titleLabel"),
                                        placeholder: _vm.$t(
                                          "botFormEdit.titlePlaceholder"
                                        ),
                                        filled: "",
                                      },
                                      on: { input: _vm.update },
                                      model: {
                                        value: card.title.data,
                                        callback: function ($$v) {
                                          _vm.$set(card.title, "data", $$v)
                                        },
                                        expression: "card.title.data",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "6" } },
                                  [
                                    _c("v-textarea", {
                                      attrs: {
                                        label: _vm.$t(
                                          "botFormEdit.descriptionLabel"
                                        ),
                                        placeholder: _vm.$t(
                                          "botFormEdit.descriptionLabel"
                                        ),
                                        "row-height": "1",
                                        "auto-grow": "",
                                        filled: "",
                                      },
                                      on: { input: _vm.update },
                                      model: {
                                        value: card.subtitle.data,
                                        callback: function ($$v) {
                                          _vm.$set(card.subtitle, "data", $$v)
                                        },
                                        expression: "card.subtitle.data",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }