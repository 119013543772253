<template>
    <div class="shortcodes-list-wrapper" :class="{'invisible': !panelIsVisible}">
        <div v-for="shortcode, i in filteredShortcodes" :key="shortcode.code + shortcode.group">
            <div 
                class="shortcode"
                :class="{'shortcode-selected': i === selected}"
                @click="onSelect(i)"
            >
                <span class="shortcode-code">
                    {{ `/${shortcode.code}` }} 
                    <v-chip
                        x-small
                        class="ml-1"
                        color="secondary"
                        >
                        {{ shortcode.group }}
                    </v-chip>
                </span>
                <span class="shortcode-description">{{ shortcode.text }}</span>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Handlebars from 'handlebars';
export default {
    name: 'LivechatShortcodesList',
    props: {
        filter: {
            type: String,
            default: null
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            selected: 0
        }
    },
    computed: {
        ...mapGetters('livechat', ['agent', 'activeChatGroups', 'organization', 'shortcodeContext', 'activeChatShortcodes']),
        ...mapGetters('bots', ['currentBotId']),
        filteredShortcodes() {
            if (this.filter) {
                this.selected = 0;
                return this.activeChatShortcodes?.filter(shortcode => shortcode.code.includes(this.filter));
            }
            return this.activeChatShortcodes;
        },
        panelIsVisible() {
            return this.show && this.filteredShortcodes.length > 0;
        }
    },
    methods: {
        onSelectMove(direction) {
            if (direction === 'up') {
                if (this.selected > 0) {
                    this.selected--;
                }
            } else {
                if (this.selected < this.filteredShortcodes.length - 1) {
                    this.selected++;
                }
            }
        },
        onSelect(clickedIndex) {
            const index = typeof clickedIndex === 'undefined' ? this.selected : clickedIndex;
            // use handlebars to fill in the shortcodeContext before emitting
            const template = Handlebars.compile(this.filteredShortcodes[index].text);
            const filled = template(this.shortcodeContext);
            this.$emit('select', filled);
        }
    },
}
</script>
<style scoped>
    .shortcodes-list-wrapper {
        position: absolute;
        bottom: 50px;
        width: 80%;
        max-height: 200px;
        background: white;
        border: 1px solid #e0e0e0;
        padding-left: 10px;
        padding-top: 10px;
        border-radius: 10px;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); 
        z-index: 100;
        overflow-y: scroll;
    }

    .invisible {
        display: none;
        padding: 0;
        box-shadow: 0;
    }
    .shortcode {
        margin-bottom: 10px;
    }
    .shortcode-code {
        font-weight: bold;
        display: block;
        margin-right: 10px;
    }
    .shortcode-selected {
        background-color: #f4f4f4;
    }
</style>