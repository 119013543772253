<template>
  <div class="chat-info-tag d-flex items-center" :class="[item.href && 'chat-info-tag--link']" @click.stop="() => null">
    <v-icon small color="primary lighten-2">
      {{ icon }}
    </v-icon>
    <a
      v-if="item.href"
      :href="item.href"
      target="_blank"
      rel="noopener"
      class="text-decoration-none text-body-2 primary--text text--lighten-2 ml-1"
    >
      {{ item.text }}
    </a>
    <div v-else class="text-body-2 primary--text text--lighten-2 ml-1">
      {{ item.text }}
    </div>
  </div>
</template>
<script>
import { infoCardIcon } from '@/utils/livechat';
export default {
  name: 'LivechatInfoTag',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    icon() {
      return infoCardIcon(this.item.type);
    },
  },
};
</script>
<style scoped>
.chat-info-tag {
  display: inline-block;
}

.chat-info-tag--link:hover {
  opacity: 0.7;
}
</style>
