var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "moin-avatar",
      on: {
        click: function ($event) {
          return _vm.outsch()
        },
      },
    },
    [
      _c("img", {
        attrs: { src: require("@/assets/avatar-moini/moin-avatar.svg") },
      }),
      _vm.stateSmiling
        ? _c("img", {
            attrs: {
              src: require("@/assets/avatar-moini/moin-avatar-mouth-smiling.svg"),
            },
          })
        : _vm._e(),
      _vm.stateThinking
        ? _c("img", {
            attrs: {
              src: require("@/assets/avatar-moini/moin-avatar-mouth-thinking.svg"),
            },
          })
        : _vm._e(),
      _vm.stateOutsch
        ? _c("img", {
            attrs: {
              src: require("@/assets/avatar-moini/moin-avatar-mouth-outsch.svg"),
            },
          })
        : _vm._e(),
      _vm.stateBlinking
        ? _c("img", {
            attrs: {
              src: require("@/assets/avatar-moini/moin-avatar-eyes-closed.svg"),
            },
          })
        : _vm._e(),
      !_vm.stateBlinking && !_vm.stateThinking
        ? _c("img", {
            attrs: {
              src: require("@/assets/avatar-moini/moin-avatar-eyes-open.svg"),
            },
          })
        : _vm._e(),
      _vm.stateThinking
        ? _c("img", {
            staticClass: "moin-avatar-eyes-thinking",
            attrs: {
              src: require("@/assets/avatar-moini/moin-avatar-eyes-thinking.svg"),
            },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }