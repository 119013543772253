var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "HubBox",
    {
      attrs: {
        icon: "list_alt",
        title: _vm.$t("intents.fallbackKeywords.title"),
        boxId: _vm.boxId,
      },
      on: {
        onExpand: (isExpanded) => isExpanded && _vm.trackIt("hub-box-expanded"),
      },
    },
    [
      !_vm.keywords.length
        ? _c(
            "div",
            {
              staticClass:
                "text-body-2 mb-8 pl-2 primary--text text--lighten-2",
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("intents.fallbackKeywords.noKeywords")) +
                  " "
              ),
            ]
          )
        : _vm._l(_vm.keywords, function (keyword, index) {
            return _c(
              "v-card",
              {
                key: index,
                staticClass: "rounded-xl elevation-0 ma-1",
                attrs: { color: "d-inline-block grey lighten-3" },
              },
              [
                _c("v-card-text", { staticClass: "pa-1 px-3 primary--text" }, [
                  _vm._v(" " + _vm._s(keyword) + " "),
                ]),
              ],
              1
            )
          }),
      _c("InfoBox", {
        staticClass: "my-4",
        attrs: { text: _vm.$t("intents.fallbackKeywords.infoBox") },
      }),
      _c(
        "div",
        { staticClass: "pt-5" },
        [
          _c(
            "v-btn",
            {
              attrs: { outlined: "", color: "primary lighten-3" },
              on: {
                click: () => {
                  _vm.editDialogOpen = true
                  _vm.trackIt("open-edit-dialog")
                },
              },
            },
            [
              _vm._v(" " + _vm._s(_vm.$t("common.edit")) + " "),
              _c("v-icon", { attrs: { right: "" } }, [_vm._v("edit")]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "HubDialog",
        {
          attrs: {
            value: _vm.editDialogOpen,
            "primary-label": _vm.$t("common.save"),
            "primary-icon": "save",
            "secondary-label": _vm.$t("common.cancel"),
            "secondary-icon": "close",
            title: _vm.$t("intents.fallbackKeywords.editTitle"),
            disabled: !_vm.isChanged,
            modal: "",
            asyncDialog: "",
          },
          on: {
            onPrimary: function ($event) {
              return _vm.saveKeywords()
            },
            onSecondary: function ($event) {
              return _vm.cancelEdit()
            },
          },
        },
        [
          _c("div", { staticClass: "mb-8" }, [
            _c("p", { staticClass: "text-body-1" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("intents.fallbackKeywords.desciptionOverlay")) +
                  " "
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c("v-text-field", {
                staticClass: "mr-2",
                attrs: {
                  dense: "",
                  outlined: "",
                  label: _vm.$t("intents.fallbackKeywords.newInputLabel"),
                  rules: _vm.validateRules ? _vm.validateRules : _vm.inputRules,
                },
                on: {
                  "update:error": (v) => (_vm.isValid = !v),
                  change: function ($event) {
                    _vm.validateRules = null
                  },
                },
                model: {
                  value: _vm.newKeyword,
                  callback: function ($$v) {
                    _vm.newKeyword = $$v
                  },
                  expression: "newKeyword",
                },
              }),
              _c(
                "v-btn",
                {
                  attrs: {
                    height: "40",
                    color: "primary lighten-2",
                    depressed: "",
                    disabled: !_vm.isValid,
                  },
                  on: { click: _vm.addKeyword },
                },
                [_c("v-icon", [_vm._v("add")])],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mb-4" },
            [
              _c(
                "v-chip-group",
                { attrs: { column: "" } },
                _vm._l(_vm.keywordsDraft, function (keywordEdit, indexEdit) {
                  return _c(
                    "v-chip",
                    {
                      key: indexEdit,
                      staticClass: "moin-cursor-default",
                      attrs: {
                        close: "",
                        "close-icon": "clear",
                        ripple: false,
                      },
                      on: {
                        "click:close": function ($event) {
                          return _vm.deleteKeyword(keywordEdit)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(keywordEdit) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c("InfoBox", {
            attrs: { text: _vm.$t("intents.fallbackKeywords.infoBoxOverlay") },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }