import { render, staticRenderFns } from "./LivechatUsingOtherTab.vue?vue&type=template&id=3c17ab56"
import script from "./LivechatUsingOtherTab.vue?vue&type=script&lang=js"
export * from "./LivechatUsingOtherTab.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/moin-hub/moin-hub/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3c17ab56')) {
      api.createRecord('3c17ab56', component.options)
    } else {
      api.reload('3c17ab56', component.options)
    }
    module.hot.accept("./LivechatUsingOtherTab.vue?vue&type=template&id=3c17ab56", function () {
      api.rerender('3c17ab56', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/livechat/special-views/LivechatUsingOtherTab.vue"
export default component.exports