var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.content
    ? _c(
        "div",
        [
          _vm.content
            ? _c(
                "v-timeline-item",
                {
                  attrs: {
                    "hide-dot": "",
                    color: "grey lighten-3",
                    "fill-dot": "",
                  },
                },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            staticClass: "font-weight-medium ma-0",
                            attrs: {
                              outlined: "",
                              dense: "",
                              label: _vm.$t("botFormEdit.whatIsAsked"),
                              disabled: _vm.isLivechatRating,
                            },
                            on: { input: _vm.update },
                            model: {
                              value: _vm.content.displayName,
                              callback: function ($$v) {
                                _vm.$set(_vm.content, "displayName", $$v)
                              },
                              expression: "content.displayName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("v-col", [
                        _vm.isAdmin
                          ? _c(
                              "div",
                              { staticClass: "moin-admin-box pa-1" },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    outlined: "",
                                    dense: "",
                                    label: _vm.$t("botFormEdit.technicalName"),
                                    disabled: _vm.isLivechatRating,
                                    "hide-details": "",
                                  },
                                  on: { input: _vm.update },
                                  model: {
                                    value: _vm.content.context,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.content, "context", $$v)
                                    },
                                    expression: "content.context",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.content
            ? _c("ActionList", {
                attrs: {
                  actions: _vm.content.question.actions,
                  channelType: _vm.channelType,
                },
                on: { onUpdate: _vm.update, "hook:mounted": _vm.updateName },
              })
            : _vm._e(),
          _c(
            "v-timeline-item",
            {
              attrs: { color: "white" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "icon",
                    fn: function () {
                      return [
                        _c("v-icon", { attrs: { small: "" } }, [
                          _vm._v("account_tree"),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                764985146
              ),
            },
            [
              _c(
                "v-chip-group",
                [
                  _vm._l(_vm.answers, function (answer, index) {
                    return _c(
                      "v-chip",
                      {
                        key: index,
                        attrs: {
                          "close-icon": "mdi-delete",
                          close:
                            _vm.answers.length > 1 && !_vm.isLivechatRating,
                          color: _vm.active(answer) ? "primary" : "",
                        },
                        on: {
                          "click:close": function ($event) {
                            return _vm.removeAnswer(answer)
                          },
                          click: function ($event) {
                            return _vm.select(answer)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.getLabel(answer.label).data))]
                    )
                  }),
                  !_vm.isLivechatRating && _vm.isAdmin
                    ? _c(
                        "v-chip",
                        {
                          attrs: { outlined: "" },
                          on: { click: _vm.addAnswer },
                        },
                        [
                          _c(
                            "v-avatar",
                            { attrs: { left: "" } },
                            [_c("v-icon", [_vm._v("add")])],
                            1
                          ),
                          _vm._v(_vm._s(_vm.$t("botFormEdit.newAnswer")) + " "),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              ),
              _c("v-text-field", {
                attrs: { label: "Label", disabled: _vm.isLivechatRating },
                on: { input: _vm.update },
                model: {
                  value: _vm.getLabel(_vm.selectedAnswer.label).data,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.getLabel(_vm.selectedAnswer.label),
                      "data",
                      $$v
                    )
                  },
                  expression: "getLabel(selectedAnswer.label).data",
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }