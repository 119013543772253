<template>
  <div @click="resetInactivity" @keydown="resetInactivity">
    <AppBotLoader v-if="!isMaintenance && user" @onBotLoaded="handleBotLoaded()" />
    <router-view v-if="isBotLoaded || isPublic" />
    <HubFaviconIncome v-if="!isMaintenance && currentBot" />

    <v-snackbar
      v-if="globalSnackbar"
      v-model="globalSnackbar.show"
      :timeout="3000"
    >
      {{ globalSnackbar.message }}
      <v-spacer></v-spacer>
      <v-icon v-if="globalSnackbar.icon" :color="globalSnackbar.color">
        {{ globalSnackbar.icon }}
      </v-icon>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import '@/sass/custom.scss';
import '@/sass/moin-fresh.scss';
import '@/sass/material-icons.scss';
import { messageTypes, styleTypes } from '@/utils/notifications';
import AppBotLoader from '@/components/app/AppBotLoader.vue';
import HubFaviconIncome from '@/components/hub/HubFaviconIncome.vue';

export default {
  components: {
    AppBotLoader,
    HubFaviconIncome,
  },
  data() {
    return {
      isBotLoaded: false,
      checkInterval: null,
    };
  },
  computed: {
    ...mapGetters(['globalSnackbar']),
    ...mapGetters('auth', ['isOnlyStaging', 'user', 'isAdmin', 'isTester']),
    ...mapGetters('bots', [
      'currentLicence',
      'bots',
      'currentBotId',
      'currentBot',
    ]),
    isMaintenance() {
      return (process.env.VUE_APP_MAINTENANCE === 'true');
    },
    ...mapGetters('content', ['isDeployable', 'lastChangeDate']),
    isDemoLicence() {
      return this.currentLicence?.package === 'demo' || false;
    },
    isPublic() {
      return !!this.$route.meta?.public;
    },
  },
  created() {
    this.init();
  },
  beforeDestroy() {
    clearInterval(this.checkInterval);
  },
  watch: {
    user() {
      if (!this.user) return;

      // set language initially
      if (!this.user?.language) {
        this.$store.dispatch('auth/saveLanguage', this.getNavigatorLang());
        return;
      } else {
        this.$i18n.locale = this.user.language;
        this.$vuetify.lang.current = this.user.language;
      }

      if (this.isTester) {
        this.goToPreview();
        return;
      }
    },
    isDemoLicence() {
      this.setDemoNotification();
    },
    $route(to) {
      this.setBotIdQuery();
      this.$store.commit('notifications/clearRouteNotifications', {
        routeName: to.name,
        fullPath: to.fullPath,
      });
    },
  },
  methods: {
    async init() {
      
      if (process.env.VUE_APP_MAINTENANCE === 'true') return;

      await this.$store.dispatch('auth/getUser', {isPublic: this.$route.meta?.public});
      // Reset notifications from local storage
      this.$store.commit('notifications/clearNotifications');

      // Reset Favicon to default
      const favicon = document.querySelector('link[rel*="icon"]');
      favicon.href = '/favicon.png';

      if (!this.user) {
        let lang = this.getNavigatorLang();
        this.$i18n.locale = lang;
        this.$vuetify.lang.current = lang;
      }

      if (this.isTester) {
        this.goToPreview();
        return;
      }

      this.checkInterval = setInterval(() => {
        this.versionCheck();
      }, 1000 * 60 * 10);
      this.versionCheck();
    },
    // Look at AppBotloader.vue
    async handleBotLoaded() {
      console.log('Bot loaded');
      this.setDemoNotification();
      this.setBotIdQuery();
      this.checkForDeployable();
      this.handleLivechatInit();

      this.isBotLoaded = true;
    },
    getNavigatorLang() {
      const lang = navigator.language || navigator.userLanguage;
      if (lang.includes('de')) {
        return 'de';
      } else {
        return 'en';
      }
    },
    versionCheck() {
      this.$store.dispatch('checkVersion');
    },
    async setBotIdQuery() {
      if (!this.currentBotId || !this.isAdmin || this.$route.query.bid) {
        return;
      }

      let query = Object.assign({}, this.$route.query);
      query.bid = this.currentBotId;
      this.$router.replace({ query }).catch(() => true);
    },
    setDemoNotification() {
      const name = 'permanent-demo';
      if (this.isDemoLicence) {
        this.$store.dispatch('notifications/addNotification', {
          name,
          styleType: styleTypes.INFO,
          messageType: messageTypes.DEMO_ACCOUNT,
          permanent: true,
        });
      } else {
        this.$store.commit('notifications/removeNotification', name);
      }
    },
    checkForDeployable() {
      if (!this.currentBotId) return;
      if (!this.isDeployable(this.currentBotId)) return;

      // Only if it's older than 3 days
      const lastChange = this.lastChangeDate(this.currentBotId);
      if (!moment().add(-3, 'days').isAfter(lastChange)) {
        return;
      }

      const name = `deployable-${this.currentBotId}-${lastChange}`;

      this.$store.dispatch('notifications/addNotification', {
        name,
        styleType: styleTypes.INFO,
        messageType: messageTypes.DEPLOYMENT_READY,
        stayDismissed: true,
        action: () => true,
      });
    },
    goToPreview() {
      if (
        this.bots[0].stagingBot &&
        (this.$route.fullPath.includes('/preview/') ||
          this.$route.fullPath.includes('%2Fpreview%2F'))
      ) {
        return;
      }
      this.$router.push(`/preview/${this.bots[0].stagingBot}`);
    },
    handleLivechatInit() {
      // only initialize the livechat if the user has the feature
      if (this.user?.features?.includes('livechat')) {
        this.$store.dispatch('livechat/init', {
          botId: this.currentBotId,
          routePath: this.$route.path,
        });

        window.addEventListener('beforeunload', () => {
          this.$store.dispatch('livechat/registerTabClosed');
        });

        document.addEventListener('visibilitychange', () => {
          this.$store.dispatch('livechat/handleVisibilityChange');
        });
      } else {
        this.$store.commit('livechat/setAgent', null)
      }
    },
    resetInactivity() {
      this.$store.dispatch('inactivityTracker/restartTimeoutTimer');
    },
  },
};
</script>
