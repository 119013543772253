var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { xs: "12", sm: "12", md: "3" } },
            [
              _c(
                "v-card",
                {
                  staticStyle: { padding: "20px" },
                  attrs: { color: "#fafafa" },
                },
                [_vm._v(" TODO "), _c("br"), _vm._v(" Chat Bot Preview ")]
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { xs: "12", sm: "12", md: "7" } },
            [
              _c(
                "v-card",
                { attrs: { color: "transparent", flat: "" } },
                [
                  _c("v-card-title", { staticClass: "pt-0" }, [
                    _vm._v(_vm._s(_vm.$t(`channelSettings.config.headline`))),
                  ]),
                  _c("v-card-subtitle", [
                    _vm._v(_vm._s(_vm.$t(`channelSettings.config.subtitle`))),
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      _vm._s(_vm.$t(`channelSettings.config.explanantion`))
                    ),
                  ]),
                  _c(
                    "v-card-actions",
                    {
                      staticClass:
                        "px-16 d-flex flex-column justify-start align-start",
                    },
                    [
                      _c(
                        "v-container",
                        { attrs: { fluid: "" } },
                        [
                          _c("v-text-field", {
                            attrs: { label: "Widget Title" },
                            model: {
                              value: _vm.widget_title,
                              callback: function ($$v) {
                                _vm.widget_title = $$v
                              },
                              expression: "widget_title",
                            },
                          }),
                          _c("v-text-field", {
                            attrs: { label: "Chatbot Name" },
                            model: {
                              value: _vm.chatbot_name,
                              callback: function ($$v) {
                                _vm.chatbot_name = $$v
                              },
                              expression: "chatbot_name",
                            },
                          }),
                          _c("v-text-field", {
                            attrs: { label: "Chatbot Title" },
                            model: {
                              value: _vm.chatbot_title,
                              callback: function ($$v) {
                                _vm.chatbot_title = $$v
                              },
                              expression: "chatbot_title",
                            },
                          }),
                          _c("v-text-field", {
                            attrs: { label: "Avatar URL" },
                            scopedSlots: _vm._u([
                              {
                                key: "append-outer",
                                fn: function () {
                                  return [
                                    _c("v-avatar", { attrs: { size: "32" } }, [
                                      _c("img", {
                                        attrs: {
                                          src: _vm.avatar_url,
                                          alt: "Avatar Preview",
                                        },
                                      }),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.avatar_url,
                              callback: function ($$v) {
                                _vm.avatar_url = $$v
                              },
                              expression: "avatar_url",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }