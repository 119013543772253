var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { staticStyle: { "max-height": "100vh", height: "100vh" } },
    [
      _c("HubMenu"),
      _c("Appbar"),
      _c(
        "v-main",
        { staticClass: "fill-height" },
        [
          _c(
            "v-row",
            { staticClass: "fill-height", attrs: { dense: "" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "fill-height flex-column",
                  class: {
                    "d-none": _vm.chatVisible,
                    "d-md-flex": _vm.chatVisible,
                  },
                  attrs: { lg: "3", md: "4", cols: "12" },
                },
                [
                  _c(
                    "v-list",
                    {
                      staticClass: "overflow-y-auto",
                      attrs: { "two-line": "" },
                    },
                    [
                      _c("v-subheader", { staticClass: "text-h6" }, [
                        _vm._v(_vm._s(_vm.$t("conversation.title"))),
                      ]),
                      [
                        _c("v-divider"),
                        _vm._l(_vm.filteredDialogs, function (item) {
                          return _c(
                            "v-list-item",
                            {
                              key: item.uniqueUserId,
                              attrs: {
                                "active-class": "accent--text",
                                to: _vm.chatRoute(item.uniqueUserId),
                              },
                            },
                            [
                              _c(
                                "v-list-item-avatar",
                                { attrs: { color: "primary" } },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "white--text text-h5" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.firstLetter(item.uniqueUserId)
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _c("i", [
                                      _vm._v(_vm._s(_vm.computeTitle(item))),
                                    ]),
                                  ]),
                                  _c("v-list-item-subtitle", [
                                    _vm._v(_vm._s(_vm.lastMessage(item))),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-action",
                                [
                                  _c("v-list-item-action-text", [
                                    _vm._v(
                                      _vm._s(_vm.formatDate(item.userLastInput))
                                    ),
                                  ]),
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        color: _vm.online(item),
                                        "x-small": "",
                                      },
                                    },
                                    [_vm._v("fiber_manual_record")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                      ],
                    ],
                    2
                  ),
                ],
                1
              ),
              _c("router-view"),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }