var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-avatar",
    {
      staticClass: "align-center",
      style: _vm.style,
      attrs: { size: _vm.size },
    },
    [
      _c("v-img", {
        attrs: { src: _vm.profilePicture },
        scopedSlots: _vm._u([
          {
            key: "placeholder",
            fn: function () {
              return [
                _c(
                  "v-icon",
                  {
                    attrs: {
                      large: !_vm.small && !_vm.large,
                      "x-large": _vm.large,
                    },
                  },
                  [_vm._v(_vm._s(_vm.placeholderIcon))]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }