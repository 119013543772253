var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    [
      _c(
        "v-container",
        [
          _vm.isDone
            ? [
                _c(
                  "v-row",
                  [
                    _c("v-col", [
                      _c("h1", { staticClass: "text-h5 mb-4" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("login.completeInviteFinal.title")) +
                            " "
                        ),
                      ]),
                      _c("p", { staticClass: "text-body-1" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("login.completeInviteFinal.subTitle")
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      [
                        _c(
                          "v-alert",
                          { attrs: { outlined: "", type: "success" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("login.completeInviteFinal.success")
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { staticClass: "text-right", attrs: { cols: "12" } },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              depressed: "",
                              color: "primary",
                              large: "",
                              to: "/login",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "login.completeInviteFinal.buttonToLogin"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : [
                _c(
                  "v-row",
                  [
                    _c("v-col", [
                      _c("h1", { staticClass: "text-h4 mb-4" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("login.completeInvite.title")) +
                            " "
                        ),
                      ]),
                      _c("p", { staticClass: "text-h6" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("login.completeInvite.subTitle")) +
                            " "
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            outlined: "",
                            "hide-details": "",
                            label: _vm.$t("login.completeInvite.input"),
                            name: "login",
                            type: "password",
                          },
                          model: {
                            value: _vm.password,
                            callback: function ($$v) {
                              _vm.password = $$v
                            },
                            expression: "password",
                          },
                        }),
                        _c("PasswordValidationFeedback", {
                          attrs: { password: _vm.password },
                          on: {
                            "update:isValidPassword": _vm.handleIsValidPassword,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            outlined: "",
                            "hide-details": "",
                            label: _vm.$t("login.completeInvite.inputConfirm"),
                            name: "login",
                            "append-icon": _vm.passwordMatchesIcon,
                            type: "password",
                          },
                          on: {
                            input: function ($event) {
                              _vm.validation = false
                            },
                          },
                          model: {
                            value: _vm.confirmedPw,
                            callback: function ($$v) {
                              _vm.confirmedPw = $$v
                            },
                            expression: "confirmedPw",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { staticClass: "text-right pt-6", attrs: { cols: "12" } },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              depressed: "",
                              color: "primary",
                              large: "",
                              loading: _vm.isLoading,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleSubmit()
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("login.completeInvite.button")) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm.validation
                      ? _c(
                          "v-col",
                          [
                            _c(
                              "v-alert",
                              { attrs: { outlined: "", type: "error" } },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.validationMessage) + " "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.errorMessage
                      ? _c(
                          "v-col",
                          [
                            _c(
                              "v-alert",
                              { attrs: { outlined: "", type: "error" } },
                              [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }