var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { staticStyle: { "max-height": "100vh", height: "100vh" } },
    [
      _c("HubMenu"),
      _c("Appbar"),
      _c("HubContent", {
        attrs: { isSidebarOpen: _vm.isSidebarOpen },
        scopedSlots: _vm._u([
          {
            key: "navbar",
            fn: function () {
              return [
                _c("HubContentNavbar", {
                  attrs: { title: _vm.$t("botSettings.title") },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "sidebar",
            fn: function () {
              return [
                _c(
                  "HubContentSidebarSimple",
                  {
                    attrs: { isOpen: _vm.isSidebarOpen, icon: "info" },
                    on: {
                      onClose: function ($event) {
                        return _vm.closeSidebar()
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "pa-4" },
                      [
                        _vm.selectedSubpage
                          ? _c("HubBoxSimple", {
                              attrs: {
                                title: _vm.selectedSubpage.title,
                                icon: _vm.selectedSubpage.icon,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "content",
                                    fn: function () {
                                      return [
                                        _c(
                                          "p",
                                          { staticClass: "text-body-2" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.selectedSubpage
                                                    .descriptionLong
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "mt-2",
                                            attrs: {
                                              outlined: "",
                                              color: "primary lighten-2",
                                              to: `settings/${_vm.selectedSubpage.slug}`,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("common.edit")) +
                                                " "
                                            ),
                                            _c(
                                              "v-icon",
                                              { attrs: { right: "" } },
                                              [_vm._v("edit")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                2194662493
                              ),
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "v-container",
                  { attrs: { fluid: "" } },
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          [
                            _c(
                              "v-list-item-group",
                              {
                                staticClass: "white",
                                attrs: {
                                  value: _vm.selectedSubpage,
                                  color: "primary",
                                  rounded: "",
                                },
                                on: { change: _vm.selectSubpage },
                              },
                              [
                                _vm._l(_vm.subPages, function (subPage, index) {
                                  return [
                                    _c(
                                      "v-list-item",
                                      {
                                        key: subPage.slug,
                                        attrs: {
                                          value: subPage,
                                          to: `/settings/${subPage.slug}`,
                                        },
                                      },
                                      [
                                        _c("v-list-item-content", [
                                          _c(
                                            "div",
                                            { staticClass: "d-sm-flex" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "flex-grow-auto d-flex align-center",
                                                },
                                                [
                                                  _c(
                                                    "v-card",
                                                    {
                                                      staticClass:
                                                        "flex-shrink-0 d-flex a-lign-center justify-center",
                                                      staticStyle: {
                                                        height: "70px",
                                                        width: "116px",
                                                      },
                                                      attrs: { outlined: "" },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color:
                                                              "primary lighten-3",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                subPage.icon
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "flex-grow-auto d-flex align-center pa-4",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "pt-2" },
                                                    [
                                                      _c(
                                                        "h3",
                                                        {
                                                          staticClass:
                                                            "text-h5 text-left",
                                                          class: [
                                                            subPage.adminOnly &&
                                                              "admin--text",
                                                          ],
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                subPage.title
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "primary--text text--lighten-3 text-body-2 text-left",
                                                        },
                                                        [
                                                          _c("span", {
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                subPage.description
                                                              ),
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    index < _vm.subPages.length - 1
                                      ? _c("v-divider", { key: index })
                                      : _vm._e(),
                                  ]
                                }),
                              ],
                              2
                            ),
                            _vm.isAdmin && !_vm.quota
                              ? _c(
                                  "v-card",
                                  {
                                    staticClass:
                                      "mt-4 pa-10 text-body-1 grey--text text--darken-2 text-center",
                                    attrs: { outlined: "" },
                                  },
                                  [
                                    _vm._v(" Für diesen Bot sind unter "),
                                    _c("a", { attrs: { href: "/tools" } }, [
                                      _vm._v("tools"),
                                    ]),
                                    _vm._v(" noch keine Quota gesetzt. "),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }