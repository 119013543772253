var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "white" },
    [
      _c(
        "div",
        {
          staticClass: "d-flex justify-space-between px-3",
          staticStyle: { height: "60px" },
        },
        [
          _c(
            "div",
            {
              staticClass: "d-flex items-center",
              staticStyle: { "margin-top": "10px", height: "40px" },
            },
            [
              _c(
                "div",
                {},
                [
                  _c("v-img", {
                    attrs: {
                      src: require("@/assets/moin-logo.svg"),
                      width: "110",
                    },
                  }),
                ],
                1
              ),
              _vm.$vuetify.breakpoint.mdAndUp
                ? _c("v-divider", {
                    staticClass: "ml-4 mr-6",
                    staticStyle: { height: "40px" },
                    attrs: { vertical: "" },
                  })
                : _vm._e(),
              _c("h1", { staticClass: "text-h6" }, [_vm._v(_vm._s(_vm.title))]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "d-flex align-center justify-end" },
            [_vm._t("toolbar", null, { disabled: _vm.isLoading })],
            2
          ),
        ]
      ),
      _c("v-divider"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }