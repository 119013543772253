var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "slide-fade" } }, [
    _c("div", { staticClass: "knw_widget_ratingbar" }, [
      _vm._v(" " + _vm._s(_vm.rateLabel) + " "),
      _c(
        "div",
        { staticClass: "knw_widget_rating_stars" },
        _vm._l(5, function (i) {
          return _c(
            "span",
            {
              key: i,
              staticClass: "unstyled",
              attrs: { "data-filled": _vm.rating >= i },
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    width: "16",
                    height: "16",
                    viewBox: "0 0 16 16",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z",
                    },
                  }),
                ]
              ),
            ]
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }