var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "bot-message-wrapper",
      class: { "with-avatar": !!_vm.avatar },
    },
    [
      _vm.avatar
        ? _c("div", { staticClass: "avatar-container" }, [
            _c("img", {
              staticClass: "bot-avatar",
              attrs: { src: _vm.avatar, alt: "Avatar" },
            }),
          ])
        : _vm._e(),
      _vm.arrow || !!_vm.avatar
        ? _c(
            "svg",
            {
              attrs: {
                width: "10",
                height: "10",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("polygon", {
                attrs: { points: "0,0 100,100 100,0", fill: "white" },
              }),
            ]
          )
        : _vm._e(),
      _c("div", { staticClass: "d-flex flex-column" }, [
        _c(
          "div",
          {
            staticClass: "bot-message",
            class: { "with-arrow": _vm.arrow || !!_vm.avatar },
            style: _vm.rounding,
          },
          [_vm._v(" " + _vm._s(_vm.text) + " ")]
        ),
        _vm.enabled
          ? _c(
              "details",
              {
                ref: "details",
                staticClass: "ai-indicator rounded-bottom",
                on: { toggle: _vm.handleToggle },
              },
              [
                _c("summary", { staticClass: "ai-generated" }, [
                  _c("span", { staticClass: "ai-badge-container" }, [
                    _c("span", { staticClass: "ai-badge" }, [_vm._v("AI")]),
                    _vm._v(" " + _vm._s(_vm.generated) + " "),
                  ]),
                  _c("div", { staticClass: "expand" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.resourcesUsed.length) +
                        " " +
                        _vm._s(_vm.sources) +
                        " "
                    ),
                    _c(
                      "svg",
                      {
                        style: `transform: rotate(${
                          _vm.isOpen ? "0" : "-90"
                        }deg);flex-shrink: 0;`,
                        attrs: {
                          width: "24",
                          height: "24",
                          viewBox: "0 0 24 20",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M14.7846 7.57617L10.7862 11.5659L6.78784 7.57617L5.55957 8.80444L10.7862 14.0311L16.0129 8.80444L14.7846 7.57617Z",
                            fill: "black",
                          },
                        }),
                      ]
                    ),
                  ]),
                ]),
                _c(
                  "ol",
                  _vm._l(_vm.resourcesUsed, function (source) {
                    return _c("li", { key: source.source }, [
                      _c("a", { attrs: { rel: "noopener" } }, [
                        _vm._v(" " + _vm._s(_vm.sourceLabel(source)) + " "),
                      ]),
                    ])
                  }),
                  0
                ),
              ]
            )
          : _vm._e(),
        _vm.enabled
          ? _c("p", { staticClass: "ai-disclaimer" }, [
              _vm._v(" " + _vm._s(_vm.disclaimer) + " "),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }