var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "3" } },
            [
              _c(
                "v-card",
                { attrs: { color: "#fafafa" } },
                _vm._l(_vm.value, function (color, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "d-flex justify-start align-center px-4",
                      staticStyle: { "min-height": "80px", width: "100%" },
                      style: { background: _vm.getColorFromKey(color) },
                    },
                    [
                      _c(
                        "span",
                        {
                          style: {
                            color: _vm.getContrastTextColor(
                              _vm.getColorFromKey(color)
                            ),
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  `channelSettings.appearance.themecolors.${color}.label`
                                )
                              ) +
                              " "
                          ),
                          _c("br"),
                          _vm._v(" " + _vm._s(color.color) + " "),
                        ]
                      ),
                    ]
                  )
                }),
                0
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "7" } },
            [
              _c(
                "v-card",
                { attrs: { color: "transparent", flat: "" } },
                [
                  _c("v-card-title", { staticClass: "pt-0" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(`channelSettings.appearance.themecolors.title`)
                      )
                    ),
                  ]),
                  _c("v-card-subtitle", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          `channelSettings.appearance.themecolors.subtitle`
                        )
                      )
                    ),
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            `channelSettings.appearance.themecolors.explanantion`
                          )
                        ) +
                        " "
                    ),
                  ]),
                  _c(
                    "v-card-actions",
                    {
                      staticClass:
                        "px-16 d-flex flex-column justify-start align-start",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "mb-8" },
                        [
                          _c("ColorInput", {
                            attrs: { colorkey: _vm.primary.name },
                            model: {
                              value: _vm.primary,
                              callback: function ($$v) {
                                _vm.primary = $$v
                              },
                              expression: "primary",
                            },
                          }),
                          _c("small", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  `channelSettings.appearance.themecolors.${_vm.primary.name}.description`
                                )
                              )
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "mb-8" },
                        [
                          _c("ColorInput", {
                            attrs: { colorkey: _vm.secondary.name },
                            model: {
                              value: _vm.secondary,
                              callback: function ($$v) {
                                _vm.secondary = $$v
                              },
                              expression: "secondary",
                            },
                          }),
                          _c("small", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  `channelSettings.appearance.themecolors.${_vm.secondary.name}.description`
                                )
                              )
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }