var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c("v-col", [
                _c("h1", { staticClass: "text-h4 mb-4" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("login.completeInvite.title")) + " "
                  ),
                ]),
                _c("p", { staticClass: "text-h6" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("login.completeInvite.signInWith")) +
                      " "
                  ),
                  _c("strong", [_vm._v(_vm._s(_vm.$route.params.username))]),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("SSOLoginButton", {
                    staticClass: "full-width",
                    attrs: {
                      provider: _vm.$route.params.botinvite,
                      invite: _vm.$route.params.token,
                      "email-hint": _vm.$route.params.username,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }