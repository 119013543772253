var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "full-width moin-bg-grey-lightest d-flex flex-col overflow-y-auto",
    },
    [
      _c(
        "div",
        { staticClass: "d-flex justify-center items-center py-16 white" },
        [
          _c(
            "div",
            {
              staticClass: "px-4",
              staticStyle: { width: "100%", "max-width": "700px" },
            },
            [_vm._t("default")],
            2
          ),
        ]
      ),
      _vm.$slots.secondary
        ? _c("HubBoxSimple", {
            staticClass: "mx-auto mb-16",
            staticStyle: {
              width: "100%",
              "max-width": "700px",
              "margin-top": "-2.5rem",
            },
            attrs: { "is-loading": false },
            scopedSlots: _vm._u(
              [
                {
                  key: "content",
                  fn: function () {
                    return [_vm._t("secondary")]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }