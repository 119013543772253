var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h4", [_vm._v("Webhook")]),
      _c("v-select", {
        attrs: {
          label: _vm.$t("botFormEdit.chooseWebhook"),
          items: _vm.webhookConfigsList,
          "item-text": "displayName",
          "item-value": "key",
        },
        on: { change: _vm.update },
        model: {
          value: _vm.action.refAction.parameters.type,
          callback: function ($$v) {
            _vm.$set(_vm.action.refAction.parameters, "type", $$v)
          },
          expression: "action.refAction.parameters.type",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }