var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "widget d-flex flex-column overflow-hidden",
      staticStyle: { width: "461px" },
    },
    [
      _vm.showTitle
        ? _vm._t("title", function () {
            return [
              _c("WidgetTitle", {
                attrs: {
                  headline: _vm.headline,
                  headerContrast: _vm.headerContrast,
                  headerColor: _vm.header,
                },
              }),
            ]
          })
        : _vm._e(),
      _vm.showOperator
        ? _vm._t("operator", function () {
            return [
              _c("WidgetOperator", {
                attrs: {
                  avatar: _vm.avatar,
                  name: _vm.name,
                  title: _vm.title,
                  shadow: false,
                },
              }),
            ]
          })
        : _vm._e(),
      _vm.showRatingBar
        ? _vm._t("rating-bar", function () {
            return [
              _c("WidgetRatingBar", { attrs: { rateLabel: _vm.rateLabel } }),
            ]
          })
        : _vm._e(),
      _vm.showHistory
        ? _vm._t("history", function () {
            return [
              _c(
                "WidgetChatHistory",
                { staticStyle: { "font-size": "100%" } },
                [
                  _vm._t("messages", function () {
                    return [
                      _c("WidgetBotMessage", {
                        attrs: {
                          arrow: "",
                          text: "Hallo, ich bin hier um zu helfen. Was kann ich für dich tun?",
                        },
                      }),
                      _c("WidgetUserMessage", {
                        attrs: {
                          text: "Wo bekomme ich Informationen über eure neuesten Produkte?",
                          bgColor: _vm.message,
                          txtColor: _vm.userTxtColor,
                        },
                      }),
                      _c("WidgetBotMessage", {
                        attrs: {
                          avatar: _vm.avatar,
                          arrow: "",
                          text: "Schau am besten mal hier in unseren Produktfinder:",
                        },
                      }),
                      _c("WidgetButtonMessage", {
                        attrs: { label: "Produktfinder" },
                      }),
                    ]
                  }),
                ],
                2
              ),
            ]
          })
        : _vm._e(),
      _vm.showInput
        ? _vm._t("input", function () {
            return [
              _c("WidgetChatInput", {
                attrs: {
                  qr: ["abbrechen", "Hilfe"],
                  placeholder: _vm.inputPlaceholder,
                },
              }),
            ]
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }