var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "flex-grow-1" }, [
    _vm.teaser && _vm.teaser.payload
      ? _c(
          "div",
          { staticClass: "teaser pa-4" },
          [
            _c(
              "div",
              {
                staticClass: "teaser-close d-flex justify-center align-center",
              },
              [_c("v-icon", [_vm._v("close")])],
              1
            ),
            _vm.isNoData
              ? _c(
                  "div",
                  {
                    staticClass:
                      "pa-4 text-center text-body-1 grey--text text--darken-1",
                  },
                  [
                    _c(
                      "v-icon",
                      {
                        staticStyle: { "margin-top": "-4px" },
                        attrs: { color: "grey darken-1" },
                      },
                      [_vm._v(" mdi-alert ")]
                    ),
                    _vm._v(" " + _vm._s(_vm.$t("teasers.previewNoData")) + " "),
                  ],
                  1
                )
              : _c("div", { staticClass: "teaser-pos-wrapper" }, [
                  _c("div", { staticClass: "d-flex flex-col align-end" }, [
                    _c(
                      "div",
                      { staticClass: "teaser-message pa-2 text-left" },
                      [_vm._v(" " + _vm._s(_vm.teaser.payload.message) + " ")]
                    ),
                    _c(
                      "div",
                      { staticClass: "text-right" },
                      [
                        _vm._l(
                          _vm.teaser.payload.quickActions,
                          function (action, index) {
                            return [
                              action.text.length
                                ? _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass:
                                        "teaser-message teaser-action py-2 px-4 mt-4 ml-4",
                                    },
                                    [_vm._v(" " + _vm._s(action.text) + " ")]
                                  )
                                : _vm._e(),
                            ]
                          }
                        ),
                      ],
                      2
                    ),
                  ]),
                ]),
            _c(
              "v-avatar",
              { staticClass: "teaser-avatar", attrs: { size: "64" } },
              [
                _c("img", {
                  attrs: {
                    src: "https://media.moin.ai/1595514610395_chatbot.png",
                    alt: "Moini",
                  },
                }),
              ]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }