var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.agent
    ? _c(
        "div",
        [
          _c(
            "div",
            { staticClass: "text-body-2 grey--text text--darken-2 mb-6" },
            [_vm._v(" " + _vm._s(_vm.$t("livechat.profile.description")) + " ")]
          ),
          false
            ? _c("div", { staticClass: "admin--text text-copy-2 mb-4" }, [
                _vm._v(
                  " Pro Hub User gibt es aktuell einen Agenten. Geplant ist aber, dass pro Bot der Agent ein eigenes Profil hat, um Bild, Name und Titel entsprechend anzupassen. "
                ),
              ])
            : _vm._e(),
          _c("div", { staticClass: "d-sm-flex" }, [
            _c(
              "div",
              { staticStyle: { width: "150px" } },
              [
                _c("ImageUpload", {
                  attrs: {
                    width: 150,
                    circleStyle: "",
                    imageSrc: _vm.profilePicture,
                    disabled: _vm.loading,
                  },
                  on: {
                    onUpload: function ($event) {
                      return _vm.uploadImage($event)
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "flex-grow-1 items-center pt-4",
                class: [_vm.$vuetify.breakpoint.smAndUp ? "ml-6" : ""],
              },
              [
                _c("v-text-field", {
                  attrs: {
                    outlined: "",
                    dense: "",
                    label: _vm.$t("livechat.profile.nameLabel"),
                    type: "text",
                    required: "",
                    disabled: _vm.loading,
                  },
                  model: {
                    value: _vm.userName,
                    callback: function ($$v) {
                      _vm.userName = $$v
                    },
                    expression: "userName",
                  },
                }),
                _c("CountryLanguageSelect", {
                  attrs: { useLanguages: "" },
                  model: {
                    value: _vm.selectedLanguages,
                    callback: function ($$v) {
                      _vm.selectedLanguages = $$v
                    },
                    expression: "selectedLanguages",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm.agent
            ? _c(
                "v-card",
                { staticClass: "mb-4", attrs: { flat: "" } },
                [
                  _c(
                    "div",
                    { staticClass: "pa-4 d-flex justify-space-between" },
                    [
                      _c("h2", { staticClass: "text-h6" }, [
                        _vm._v(_vm._s(_vm.$t("livechat.settings.title"))),
                      ]),
                    ]
                  ),
                  _c("v-divider"),
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", [
                            _c("div", { staticClass: "mt-6 text-body-2" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("livechat.settings.description")
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "6" } },
                            [
                              _c("v-switch", {
                                attrs: {
                                  "input-value":
                                    _vm.hubSettingsLocal.notificationBrowser,
                                  color: true ? "green" : "grey",
                                  inset: "",
                                  label: _vm.$t(
                                    "livechat.settings.switchBrowser"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.changeHubSettingsLocal({
                                      key: "notificationBrowser",
                                      value: $event,
                                    })
                                  },
                                },
                              }),
                              _c("v-switch", {
                                attrs: {
                                  "input-value":
                                    _vm.hubSettingsLocal.notificationAudio,
                                  color: true ? "green" : "grey",
                                  inset: "",
                                  label: _vm.$t(
                                    "livechat.settings.switchAudio"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.changeHubSettingsLocal({
                                      key: "notificationAudio",
                                      value: $event,
                                    })
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-sm-8",
                              attrs: { cols: "12", sm: "6" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex items-center mb-4" },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      dense: "",
                                      label: _vm.$t(
                                        "settingsLivechat.notifications.soundNewChatLabel"
                                      ),
                                      "hide-details": "",
                                      items: _vm.soundItems,
                                      "item-value": "value",
                                      "item-text": "name",
                                      disabled:
                                        !_vm.hubSettingsLocal.notificationAudio,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changeHubSettingsLocal({
                                          key: "soundNewChat",
                                          value: $event,
                                        })
                                      },
                                    },
                                    model: {
                                      value: _vm.hubSettingsLocal.soundNewChat,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.hubSettingsLocal,
                                          "soundNewChat",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "hubSettingsLocal.soundNewChat",
                                    },
                                  }),
                                  _c("BtnSquare", {
                                    staticClass: "ml-1",
                                    attrs: {
                                      icon: "mdi-volume-high",
                                      disabled:
                                        !_vm.hubSettingsLocal.notificationAudio,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.playSound(
                                          _vm.hubSettingsLocal.soundNewChat
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "d-flex items-center" },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      dense: "",
                                      label: _vm.$t(
                                        "settingsLivechat.notifications.soundNewMessageLabel"
                                      ),
                                      "hide-details": "",
                                      items: _vm.soundItems,
                                      "item-value": "value",
                                      "item-text": "name",
                                      disabled:
                                        !_vm.hubSettingsLocal.notificationAudio,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changeHubSettingsLocal({
                                          key: "soundNewMessage",
                                          value: $event,
                                        })
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.hubSettingsLocal.soundNewMessage,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.hubSettingsLocal,
                                          "soundNewMessage",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "hubSettingsLocal.soundNewMessage",
                                    },
                                  }),
                                  _c("BtnSquare", {
                                    staticClass: "ml-1",
                                    attrs: {
                                      icon: "mdi-volume-high",
                                      disabled:
                                        !_vm.hubSettingsLocal.notificationAudio,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.playSound(
                                          _vm.hubSettingsLocal.soundNewMessage
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "pr-10 pt-4" },
                                [
                                  _c("v-slider", {
                                    attrs: {
                                      color: "primary",
                                      min: "0",
                                      max: "100",
                                      "prepend-icon": "mdi-volume-high",
                                      disabled:
                                        !_vm.hubSettingsLocal.notificationAudio,
                                    },
                                    on: { end: _vm.onVolumeChanged },
                                    model: {
                                      value: _vm.volume,
                                      callback: function ($$v) {
                                        _vm.volume = $$v
                                      },
                                      expression: "volume",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }