var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    [
      _c("v-col", { staticClass: "pb-0", attrs: { cols: "12" } }, [
        _c("h3", { staticClass: "text-h6" }, [
          _vm._v(_vm._s(_vm.$t("teasers.triggers.title"))),
        ]),
      ]),
      _c(
        "v-col",
        { staticClass: "mb-4", attrs: { cols: "12", md: "6" } },
        [
          _c("div", { staticClass: "text-body-1 mb-4" }, [
            _vm._v(_vm._s(_vm.$t("teasers.triggers.info"))),
          ]),
          _c("v-text-field", {
            staticStyle: { "max-width": "200px" },
            attrs: {
              outlined: "",
              dense: "",
              "hide-details": "",
              type: "number",
              suffix:
                _vm.timeoutInSeconds === 1
                  ? _vm.$t("teasers.triggers.second")
                  : _vm.$t("teasers.triggers.seconds"),
              label: _vm.$t(
                "teasers.triggers.triggerOptions.afterTime.inputLabel"
              ),
              value: _vm.timeoutInSeconds,
            },
            on: { input: _vm.changeTimeout },
          }),
        ],
        1
      ),
      _c(
        "v-col",
        [
          _c("div", { staticClass: "text-body-1 mb-4" }, [
            _vm._v(" " + _vm._s(_vm.$t("teasers.triggers.infoDevices")) + " "),
          ]),
          _c(
            "v-radio-group",
            {
              staticClass: "my-2",
              attrs: {
                value: _vm.teaser.device || "both",
                row: "",
                dense: "",
                "hide-details": "",
              },
              on: { change: (value) => _vm.changeDevice(value) },
            },
            [
              _c("v-radio", {
                attrs: {
                  label: _vm.$t(`teasers.triggers.devices.both`),
                  value: "both",
                },
              }),
              _c("v-radio", {
                attrs: {
                  label: _vm.$t(`teasers.triggers.devices.mobile`),
                  value: "mobile",
                },
              }),
              _c("v-radio", {
                attrs: {
                  label: _vm.$t(`teasers.triggers.devices.desktop`),
                  value: "desktop",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }