var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { outlined: "" } },
    [
      _c(
        "v-expansion-panels",
        {
          attrs: { flat: "", multiple: "", disabled: _vm.notExpandable },
          model: {
            value: _vm.expanded,
            callback: function ($$v) {
              _vm.expanded = $$v
            },
            expression: "expanded",
          },
        },
        [
          _c(
            "v-expansion-panel",
            { key: 0 },
            [
              _c(
                "v-expansion-panel-header",
                [
                  _vm.isLoading
                    ? _c("v-progress-linear", {
                        staticClass: "loading-bar",
                        attrs: {
                          absolute: "",
                          indeterminate: "",
                          color: "secondary darken-2",
                        },
                      })
                    : _vm._e(),
                  _vm._t("title", function () {
                    return [
                      _c("div", { staticClass: "d-flex pt-1" }, [
                        _vm.icon
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-start flex-shrink-0 flex-grow-0",
                                staticStyle: { width: "28px" },
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticStyle: { "margin-top": "-2px" },
                                    attrs: {
                                      color: _vm.adminOnly
                                        ? "admin"
                                        : "primary lighten-3",
                                      size: "20",
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(_vm.icon) + " ")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("div", [
                          _c(
                            "h2",
                            {
                              staticClass: "moin-line-height-1 text-h6 mr-2",
                              class: [
                                _vm.adminOnly ? "admin--text" : "primary--text",
                              ],
                            },
                            [_vm._v(" " + _vm._s(_vm.title) + " ")]
                          ),
                          _vm.titleSub
                            ? _c(
                                "h3",
                                {
                                  staticClass:
                                    "mt-2 moin-line-height-1 text-body-2 primary--text text--lighten-2",
                                },
                                [_vm._v(" " + _vm._s(_vm.titleSub) + " ")]
                              )
                            : _vm._e(),
                        ]),
                      ]),
                    ]
                  }),
                ],
                2
              ),
              _c("v-expansion-panel-content", [_vm._t("default")], 2),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }