// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/fonts/JosefinSans-VariableFont_wght.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("@/assets/fonts/Lato-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("@/assets/fonts/silka-semibold-webfont.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: \"Josefin Sans\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n@font-face {\n  font-family: \"Lato\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n@font-face {\n  font-family: \"silkaHeading\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n.user-message-wrapper[data-v-452180e4] {\n  /* From the widget itself */\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  width: 100%;\n  padding-left: 85px;\n  position: relative;\n  -webkit-box-pack: end;\n      -ms-flex-pack: end;\n          justify-content: end;\n  margin-top: 14px;\n  margin-bottom: calc(12px + 12px);\n}\n.with-arrow[data-v-452180e4]:after {\n  /* From the widget itself */\n  position: absolute;\n  content: \"\";\n  display: block;\n  bottom: -10px;\n  right: 0;\n  width: 10px;\n  height: 10px;\n  background-color: var(--widget-message-bg-color);\n  clip-path: polygon(100% 0, 0 0, 100% 80%);\n}\n.user-message[data-v-452180e4] {\n  line-height: 1.25em;\n  /* From the widget itself */\n  margin-left: 15px;\n  display: -webkit-inline-box;\n  display: -ms-inline-flexbox;\n  display: inline-flex;\n  border-radius: 6px;\n  color: #fff;\n  background-color: var(--widget-message-bg-color);\n  padding: 10px 18px 10px 12px;\n  min-width: 30px;\n  font-size: 1.5em;\n  font-weight: 400;\n}\n.with-arrow .user-message[data-v-452180e4] {\n  border-radius: 6px 6px 0 6px;\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
