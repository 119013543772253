var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "DropMenu",
        { attrs: { items: _vm.dropMenuItems } },
        [
          _vm.botHasLivechat && _vm.agent
            ? _c(
                "v-tooltip",
                {
                  attrs: { bottom: "", color: "primary lighten-3" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on, attrs }) {
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass: "mx-2 livechat-header-btn",
                                    class: [
                                      _vm.agentStatus ===
                                      _vm.statusTypes.otherTab
                                        ? "livechat-header-btn--other-tab"
                                        : "",
                                    ],
                                    attrs: { color: "white" },
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _vm.agentStatus === _vm.statusTypes.otherTab
                                  ? _c(
                                      "v-icon",
                                      {
                                        attrs: {
                                          small: "",
                                          color: "primary lighten-4",
                                        },
                                      },
                                      [_vm._v(" mdi-bell-off ")]
                                    )
                                  : _vm._e(),
                                _vm.agentStatus === _vm.statusTypes.online
                                  ? _c(
                                      "v-icon",
                                      { attrs: { color: "green" } },
                                      [_vm._v(" mdi-face-agent ")]
                                    )
                                  : _vm._e(),
                                _vm.agentStatus === _vm.statusTypes.offline
                                  ? _c(
                                      "v-icon",
                                      { attrs: { color: "primary lighten-4" } },
                                      [_vm._v(" mdi-headphones-off ")]
                                    )
                                  : _vm._e(),
                                _vm.hasNotifications
                                  ? _c("PulseBoeble")
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2932180710
                  ),
                },
                [
                  _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(`livechat.headerBtn.${_vm.agentStatus}`)
                        ) +
                        " "
                    ),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("EditModal", {
        attrs: {
          open: _vm.isAgentInactive,
          saveButtonLabel: _vm.$t("livechat.agentInactiveModal.btnStay"),
          title: _vm.$t("livechat.agentInactiveModal.title"),
          subtitle: _vm.$t("livechat.agentInactiveModal.body"),
        },
        on: { save: _vm.onAgentIsActive, cancel: _vm.onAgentIsInactive },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("v-card-text", { staticClass: "text-center" }, [
                  _c("h2", { staticClass: "mt-4 headline font-weight-bold" }, [
                    _vm._v(_vm._s(_vm.inactiveTimeoutLeft)),
                  ]),
                  _c("p", { staticClass: "mt-2" }, [
                    _vm._v(
                      _vm._s(_vm.$t("livechat.agentInactiveModal.countdown"))
                    ),
                  ]),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }