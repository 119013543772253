var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isDemoLicence
        ? _c("InfoBoxOutline", {
            staticClass: "mb-8",
            attrs: {
              block: "",
              title: _vm.$t("intents.inReview.hintNoLiveStateDemo.title"),
              body: _vm.$t("intents.inReview.hintNoLiveStateDemo.body"),
              icon: "hourglass_top",
            },
          })
        : _vm._e(),
      _c(
        "h2",
        { staticClass: "text-h6 primary--text mb-3" },
        [
          _c(
            "v-icon",
            {
              staticClass: "mr-2 pb-2",
              attrs: { left: "", color: "primary lighten-3" },
            },
            [_vm._v(" mdi-file-document-edit-outline ")]
          ),
          _c("span", [
            _vm._v(
              _vm._s(_vm.$t("intentNew.newWithoutTemplate.titleNewIntent"))
            ),
          ]),
        ],
        1
      ),
      _c(
        "v-slide-x-transition",
        { attrs: { group: "", "hide-on-leave": "" } },
        [
          _vm.activeStep === 1
            ? _c("IntentNewStepName", {
                key: "step-1",
                on: { onDisableChange: _vm.setDisabled },
              })
            : _vm.activeStep === 2
            ? _c("IntentNewStepSamples", {
                key: "step-2",
                on: { onDisableChange: _vm.setDisabled },
              })
            : _vm.activeStep === 3
            ? _c("IntentNewStepSummary", { key: "step-3" })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-row",
        {
          staticClass: "mt-4",
          attrs: { align: "center", justify: "space-between" },
        },
        [
          _c(
            "v-col",
            [
              _vm.activeStep === 1
                ? _c(
                    "v-btn",
                    {
                      attrs: { text: "", color: "primary lighten-2" },
                      on: { click: _vm.openCancelDialog },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("editor.common.discard")) + " "
                      ),
                      _c("v-icon", { attrs: { right: "" } }, [
                        _vm._v("delete_outline"),
                      ]),
                    ],
                    1
                  )
                : _c(
                    "v-btn",
                    {
                      attrs: { color: "primary lighten-2", text: "" },
                      on: { click: _vm.stepBack },
                    },
                    [
                      _c("v-icon", { attrs: { left: "", size: "24" } }, [
                        _vm._v("mdi-arrow-left"),
                      ]),
                      _vm._v(" " + _vm._s(_vm.$t("common.back")) + " "),
                    ],
                    1
                  ),
            ],
            1
          ),
          _vm.activeStep < _vm.stepsCount
            ? _c(
                "v-col",
                { staticClass: "text-right" },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { left: "", disabled: !_vm.disabledMessage },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "span",
                                  _vm._g(_vm._b({}, "span", attrs, false), on),
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          color: "primary lighten-3",
                                          text: "",
                                          disabled: _vm.isDisabled,
                                        },
                                        on: { click: _vm.stepNext },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("common.next")) +
                                            " "
                                        ),
                                        _c(
                                          "v-icon",
                                          { attrs: { right: "", size: "24" } },
                                          [_vm._v("mdi-arrow-right")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3890323377
                      ),
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.disabledMessage))])]
                  ),
                ],
                1
              )
            : _c(
                "v-col",
                { staticClass: "text-right" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "secondary darken-2",
                        depressed: "",
                        disabled: _vm.isLoading,
                      },
                      on: { click: _vm.openCreateDialog },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("intentNew.newWithoutTemplate.addIntent")
                          ) +
                          " "
                      ),
                      _c("v-icon", { attrs: { right: "" } }, [
                        _vm._v("mdi-plus-circle"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
          _vm.showError
            ? _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-alert",
                    { attrs: { outlined: "", color: "red darken-2" } },
                    [
                      _c("div", { staticClass: "text-body-2 error--text" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("intentNew.stepSummary.errorMessageName")
                            ) +
                            " "
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "HubDialog",
        {
          attrs: {
            "primary-label": _vm.$t("common.save"),
            "primary-icon": "save",
            "secondary-label": _vm.$t("common.cancel"),
            "secondary-icon": "close",
            title: _vm.$t("intentNew.dialogNewWithoutTemplate.title", {
              displayName: _vm.newIntent.displayName,
            }),
            modal: "",
            asyncDialog: "",
            disabled: _vm.isLoading,
          },
          on: {
            onPrimary: _vm.createIntent,
            onSecondary: _vm.closeCreateDialog,
          },
          model: {
            value: _vm.createDialogIsOpen,
            callback: function ($$v) {
              _vm.createDialogIsOpen = $$v
            },
            expression: "createDialogIsOpen",
          },
        },
        [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("intentNew.dialogNewWithoutTemplate.body", {
                  displayName: _vm.newIntent.displayName,
                })
              ) +
              " "
          ),
        ]
      ),
      _c(
        "HubDialog",
        {
          attrs: {
            "primary-label": _vm.$t(
              "intentNew.dialogIntentCancel.btnPrimaryLabel"
            ),
            "primary-icon": "delete_outline",
            title: _vm.$t("intentNew.dialogIntentCancel.title"),
            modal: "",
          },
          on: { onPrimary: _vm.cancelCreation },
          model: {
            value: _vm.cancelDialogIsOpen,
            callback: function ($$v) {
              _vm.cancelDialogIsOpen = $$v
            },
            expression: "cancelDialogIsOpen",
          },
        },
        [
          _vm._v(
            " " + _vm._s(_vm.$t("intentNew.dialogIntentCancel.body")) + " "
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }