<template>
  <HubBox
    :title="'KI Vorschlag'"
    icon="auto_awesome"
    boxId="'intents-status-suggested'"
    @onExpand="(isExpanded) => isExpanded && trackIt('hub-box-expanded')"
  >
    <div class="mb-4 text-body-2">
      {{ $t('editor.suggested.description') }}
    </div>

    <div class="text-body-1 mb-1" v-if="isAdmin">
      {{ $t('intents.answers.status.forwardLabel') }}
    </div>

    <template
      v-if="isCreateBtnShown"
    >
      <div class="text-body-1 font-weight-bold" v-if="isAdmin">
        <v-icon small left>directions</v-icon>
        <span v-if="status.length > 0 && status[0].forward">
          {{ getIntentDisplayName(status[0].forward, intents) }}
        </span>
        <span v-if="status.length > 0 && !status[0].forward">
          {{ $t('editor.conversational.unknown.title') }}
        </span>
      </div>

      <div class="mb-4">
        <v-btn
          outlined
          color="primary lighten-3"
          class="mt-4"
          @click="isSuggestionDialogOpen = true"
        >
          <span>{{ $t('common.accept') }}</span>
          <v-icon right color="primary lighten-3">add_box</v-icon>
        </v-btn>
      </div>
    </template>
    <IntentsDialogSuggestion
      :isOpen="isSuggestionDialogOpen"
      @onClose="isSuggestionDialogOpen = false"
      :intent="intent"
    />
  </HubBox>
</template>
<script>
import { mapGetters } from 'vuex';
import HubBox from '@/components/hub/HubBox.vue';
import { statusIcons } from '@/utils/intent';
import { TrackingService } from '@/services/tracking';
import { getIntentDisplayName } from '@/utils/intent';
import IntentsDialogSuggestion from '@/components/intents/IntentsDialogSuggestion.vue';

export default {
  name: 'IntentStatusSuggested',
  components: {
    HubBox,
    IntentsDialogSuggestion,
  },
  props: {
    intent: {
      type: Object,
      required: true,
    },
    status: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      statusIcons,
      statusList: [],
      isSuggestionDialogOpen: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['isAdmin', 'isOwner', 'isSimpleEdit']),
    ...mapGetters('bots', [
      'currentBotId',
      'currentChannels',
      'getBotSettings',
    ]),
    ...mapGetters('intents', ['intents']),
    quota() {
      return this.getBotSettings(this.currentBotId)?.quota || null;
    },
    isCreateBtnShown() {
      return this.isAdmin || ((this.isOwner || this.isSimpleEdit) && this.quota && this.quota.intents);
    },
  },
  methods: {
    getIntentDisplayName,
    goToEditSelected() {
      if (this.intent) {
        this.$router.push({
          name: 'intentsedit',
          params: { name: this.intent.intent },
        });
      }
    },
    trackIt(type) {
      TrackingService.trackIntentsView({
        botId: this.currentBotId,
        type,
        source: 'IntentStatusSuggested',
      });
    },
  },
};
</script>
