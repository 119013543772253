var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { staticStyle: { "max-height": "100vh", height: "100vh" } },
    [
      _c("HubMenu"),
      _c("Appbar"),
      _c("HubContent", {
        attrs: { isSidebarOpen: _vm.isSidebarOpen },
        scopedSlots: _vm._u([
          {
            key: "navbar",
            fn: function () {
              return [
                _c("HubContentNavbar", {
                  attrs: {
                    title: _vm.reportTitle,
                    backTo: { path: "/statistics" },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "toolbar",
                      fn: function () {
                        return [
                          _c("BtnSquare", {
                            attrs: { icon: "view_sidebar" },
                            on: {
                              click: () =>
                                (_vm.isSidebarOpen = !_vm.isSidebarOpen),
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "sidebar",
            fn: function () {
              return [
                _c(
                  "HubContentSidebarSimple",
                  {
                    attrs: { isOpen: _vm.isSidebarOpen },
                    on: { onClose: () => (_vm.isSidebarOpen = false) },
                  },
                  [
                    _c("StatsReportSidebar", {
                      attrs: {
                        statsType: _vm.statsType,
                        title: _vm.reportTitle,
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "pa-3" },
                  [
                    _c("StatsHeader", {
                      attrs: { compareDisabled: _vm.isCompareDisabled },
                    }),
                    _c(_vm.currentReport, { tag: "component" }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }