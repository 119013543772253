var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "avatar",
      class: { "not-scaled": _vm.notScaled },
      style: `border-width: ${_vm.circleBorderSize};`,
    },
    [
      _c(
        "svg",
        {
          ref: "box",
          attrs: {
            viewBox: "0 0 64 64",
            version: "1.1",
            xmlns: "http://www.w3.org/2000/svg",
            "xmlns:xlink": "http://www.w3.org/1999/xlink",
          },
        },
        [
          _c("title", [
            _vm._v("2-molecule/avatar/branded/export-assets/moinai-var1"),
          ]),
          _c(
            "defs",
            [
              _c("polygon", {
                attrs: {
                  id: "avatar-path-1",
                  points:
                    "64 64 64 -8.78186412e-14 0 -5.68434189e-14 -4.4408921e-16 64",
                },
              }),
              _c(
                "radialGradient",
                {
                  attrs: {
                    id: "avatar-radialGradient-3",
                    cx: "50%",
                    cy: "50%",
                    fx: "50%",
                    fy: "50%",
                    r: "50%",
                  },
                },
                [
                  _c("stop", {
                    attrs: { "stop-color": "#004069", offset: "0%" },
                  }),
                  _c("stop", {
                    attrs: {
                      "stop-color": "#EEEEEE",
                      "stop-opacity": "0",
                      offset: "100%",
                    },
                  }),
                ],
                1
              ),
              _c("rect", {
                attrs: {
                  id: "avatar-path-4",
                  x: "0",
                  y: "3.49805655e-14",
                  width: "7",
                  height: "4.92307692",
                },
              }),
              _c("path", {
                attrs: {
                  id: "avatar-path-6",
                  d: "M4.890402,0 L6.556,0 C7.35349918,3.39778733e-16 8,0.646500821 8,1.444 L8,22.1867692 C8,22.9842684 7.35349918,23.6307692 6.556,23.6307692 L2.75508397,23.6307692 C2.14899951,23.6307469 1.6074161,23.252259 1.39908844,22.6831035 C0.466362815,20.1348777 0,16.9301273 0,13.0688524 C0,9.76128163 0.342200592,6.45371087 1.02660178,3.14614012 C1.40572183,1.31399426 3.01944223,3.43689734e-16 4.890402,0 Z",
                },
              }),
              _c("path", {
                attrs: {
                  id: "avatar-path-8",
                  d: "M4.890402,0 L6.556,0 C7.35349918,3.39778733e-16 8,0.646500821 8,1.444 L8,22.1867692 C8,22.9842684 7.35349918,23.6307692 6.556,23.6307692 L2.75508397,23.6307692 C2.14899951,23.6307469 1.6074161,23.252259 1.39908844,22.6831035 C0.466362815,20.1348777 0,16.9301273 0,13.0688524 C0,9.76128163 0.342200592,6.45371087 1.02660178,3.14614012 C1.40572183,1.31399426 3.01944223,3.43689734e-16 4.890402,0 Z",
                },
              }),
              _c("path", {
                attrs: {
                  id: "avatar-path-10",
                  d: "M2.51448232,1.28748669 C6.88632005,0.429162229 11.5238587,0 16.4270984,0 C21.426291,0 26.4277716,0.446123383 31.4315403,1.33837015 C32.8915321,1.59872301 33.9170331,2.92257596 33.8041748,4.40129929 L32.3147538,23.9163927 C32.1998617,25.4217637 30.9448776,26.5846154 29.4351285,26.5846154 L4.56487148,26.5846154 C3.0551224,26.5846154 1.80013832,25.4217637 1.68524617,23.9163927 L0.191234377,4.34114753 C0.0798536903,2.88157571 1.0780885,1.5694944 2.51448232,1.28748669 Z",
                },
              }),
              _c("rect", {
                attrs: {
                  id: "avatar-path-12",
                  x: "4.26325641e-14",
                  y: "0",
                  width: "43",
                  height: "21.6615385",
                  rx: "10.8307692",
                },
              }),
              _c("rect", {
                attrs: {
                  id: "avatar-path-14",
                  x: "4.26325641e-14",
                  y: "-5.32907052e-15",
                  width: "45",
                  height: "23.6307692",
                  rx: "11.8153846",
                },
              }),
            ],
            1
          ),
          _c(
            "g",
            {
              attrs: {
                id: "avatar-2---Molecules",
                stroke: "none",
                "stroke-width": "1",
                fill: "none",
                "fill-rule": "evenodd",
              },
            },
            [
              _c(
                "g",
                {
                  attrs: {
                    id: "avatar-molecules/avatar",
                    transform: "translate(-490.000000, -4631.000000)",
                  },
                },
                [
                  _c("rect", {
                    attrs: {
                      fill: "#E9E9E9",
                      x: "0",
                      y: "0",
                      width: "940",
                      height: "13293",
                    },
                  }),
                  _c(
                    "g",
                    {
                      attrs: {
                        id: "avatar-Group",
                        transform: "translate(490.000000, 4631.000000)",
                      },
                    },
                    [
                      _c(
                        "mask",
                        { attrs: { id: "avatar-mask-2", fill: "white" } },
                        [
                          _c("use", {
                            attrs: { "xlink:href": "#avatar-path-1" },
                          }),
                        ]
                      ),
                      _c("use", {
                        attrs: {
                          id: "avatar-avatarmask-round",
                          fill: "#999999",
                          "xlink:href": "#avatar-path-1",
                        },
                      }),
                      _c(
                        "g",
                        {
                          attrs: {
                            id: "avatar-bot",
                            mask: "url(#avatar-mask-2)",
                          },
                        },
                        [
                          _c(
                            "g",
                            {
                              attrs: {
                                id: "avatar-Group",
                                transform: "translate(0.000000, -0.500000)",
                              },
                            },
                            [
                              _c(
                                "g",
                                {
                                  attrs: {
                                    id: "avatar-1-atom/color/avatar/kwbot/background-grey",
                                    transform: "translate(0.000000, 0.500000)",
                                    fill: _vm.background,
                                  },
                                },
                                [
                                  _c("rect", {
                                    attrs: {
                                      id: "avatar-Color",
                                      x: "0",
                                      y: "0",
                                      width: "64",
                                      height: "64",
                                    },
                                  }),
                                ]
                              ),
                              _c("g", { attrs: { "fill-opacity": "0.6" } }, [
                                _c(
                                  "g",
                                  {
                                    attrs: {
                                      id: "avatar-shadow-copy",
                                      fill: "url(#avatar-radialGradient-3)",
                                    },
                                  },
                                  [
                                    _c("circle", {
                                      attrs: { cx: "32", cy: "32", r: "32" },
                                    }),
                                  ]
                                ),
                              ]),
                              _c(
                                "g",
                                {
                                  attrs: {
                                    id: "avatar-1-atom/avatar/kwbot/neck",
                                    transform:
                                      "translate(28.585449, 37.425481)",
                                  },
                                },
                                [
                                  _c(
                                    "mask",
                                    {
                                      attrs: {
                                        id: "avatar-mask-5",
                                        fill: "white",
                                      },
                                    },
                                    [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href": "#avatar-path-4",
                                        },
                                      }),
                                    ]
                                  ),
                                  _c("use", {
                                    attrs: {
                                      id: "avatar-neck",
                                      fill: "#00619E",
                                      "xlink:href": "#avatar-path-4",
                                    },
                                  }),
                                  _c(
                                    "g",
                                    {
                                      attrs: {
                                        id: "avatar-Group",
                                        mask: "url(#avatar-mask-5)",
                                        fill: _vm.faceColor,
                                      },
                                    },
                                    [
                                      _c(
                                        "g",
                                        {
                                          attrs: {
                                            id: "avatar-1-atom/color/avatar/kwbot/arm-neck-color",
                                          },
                                        },
                                        [
                                          _c("rect", {
                                            attrs: {
                                              id: "avatar-Color",
                                              x: "0",
                                              y: "0",
                                              width: "7",
                                              height: "4.92307692",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "g",
                                {
                                  attrs: {
                                    id: "avatar-1-atom/avatar/kwbot/arm",
                                    transform:
                                      "translate(10.000000, 42.840865)",
                                  },
                                },
                                [
                                  _c(
                                    "mask",
                                    {
                                      attrs: {
                                        id: "avatar-mask-7",
                                        fill: "white",
                                      },
                                    },
                                    [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href": "#avatar-path-6",
                                        },
                                      }),
                                    ]
                                  ),
                                  _c("use", {
                                    attrs: {
                                      id: "avatar-right-arm",
                                      fill: "#00619E",
                                      "xlink:href": "#avatar-path-6",
                                    },
                                  }),
                                  _c(
                                    "g",
                                    {
                                      attrs: {
                                        id: "avatar-Group",
                                        mask: "url(#avatar-mask-7)",
                                        fill: _vm.arm,
                                      },
                                    },
                                    [
                                      _c(
                                        "g",
                                        {
                                          attrs: {
                                            id: "avatar-1-atom/color/avatar/kwbot/arm-neck-color",
                                          },
                                        },
                                        [
                                          _c("rect", {
                                            attrs: {
                                              id: "avatar-Color",
                                              x: "0",
                                              y: "0",
                                              width: "8",
                                              height: "23.6307692",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "g",
                                {
                                  attrs: {
                                    transform:
                                      "translate(45.937500, 42.840865)",
                                  },
                                },
                                [
                                  _c(
                                    "g",
                                    {
                                      attrs: {
                                        id: "avatar-1-atom/avatar/kwbot/arm",
                                        transform:
                                          "translate(4.000000, 11.815385) scale(-1, 1) translate(-4.000000, -11.815385) ",
                                      },
                                    },
                                    [
                                      _c(
                                        "mask",
                                        {
                                          attrs: {
                                            id: "avatar-mask-9",
                                            fill: "white",
                                          },
                                        },
                                        [
                                          _c("use", {
                                            attrs: {
                                              "xlink:href": "#avatar-path-8",
                                            },
                                          }),
                                        ]
                                      ),
                                      _c("use", {
                                        attrs: {
                                          id: "avatar-right-arm",
                                          fill: "#00619E",
                                          "xlink:href": "#avatar-path-8",
                                        },
                                      }),
                                      _c(
                                        "g",
                                        {
                                          attrs: {
                                            id: "avatar-Group",
                                            mask: "url(#avatar-mask-9)",
                                            fill: _vm.arm,
                                          },
                                        },
                                        [
                                          _c(
                                            "g",
                                            {
                                              attrs: {
                                                id: "avatar-1-atom/color/avatar/kwbot/arm-neck-color",
                                              },
                                            },
                                            [
                                              _c("rect", {
                                                attrs: {
                                                  id: "avatar-Color",
                                                  x: "0",
                                                  y: "0",
                                                  width: "8",
                                                  height: "23.6307692",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "g",
                                {
                                  attrs: {
                                    id: "avatar-1-atom/avatar/kwbot/torso",
                                    transform:
                                      "translate(15.085449, 39.887019)",
                                  },
                                },
                                [
                                  _c(
                                    "mask",
                                    {
                                      attrs: {
                                        id: "avatar-mask-11",
                                        fill: "white",
                                      },
                                    },
                                    [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href": "#avatar-path-10",
                                        },
                                      }),
                                    ]
                                  ),
                                  _c("use", {
                                    attrs: {
                                      id: "avatar-torso",
                                      fill: "#0075BF",
                                      "xlink:href": "#avatar-path-10",
                                    },
                                  }),
                                  _c(
                                    "g",
                                    {
                                      attrs: {
                                        id: "avatar-Group",
                                        mask: "url(#avatar-mask-11)",
                                        fill: _vm.shirt,
                                      },
                                    },
                                    [
                                      _c(
                                        "g",
                                        {
                                          attrs: {
                                            id: "avatar-1-atom/color/avatar/kwbot/torso-color",
                                          },
                                        },
                                        [
                                          _c("rect", {
                                            attrs: {
                                              id: "avatar-Color",
                                              x: "0",
                                              y: "-1.88895053e-14",
                                              width: "34",
                                              height: "26.5846154",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "g",
                                {
                                  attrs: {
                                    id: "avatar-1-atom/avatar/kwbot/headbuttons",
                                    transform:
                                      "translate(20.284180, 11.388230)",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      id: "avatar-yellow",
                                      d: "M10.5027522,0.404701015 C11.4695039,0.404701015 12.2532109,1.18840807 12.2532109,2.15515972 L12.2532109,3.42071633 L12.2532109,3.42071633 L8.75229351,3.42071633 L8.75229351,2.15515972 C8.75229351,1.18840807 9.53600056,0.404701015 10.5027522,0.404701015 Z",
                                      fill: "#F89D1C",
                                    },
                                  }),
                                  _c("path", {
                                    attrs: {
                                      id: "avatar-blue",
                                      d: "M6.12660546,-2.79844524e-14 C7.0933571,-2.81620418e-14 7.87706416,0.783707056 7.87706416,1.7504587 L7.87706416,3.42071633 L7.87706416,3.42071633 L4.37614675,3.42071633 L4.37614675,1.7504587 C4.37614675,0.783707056 5.15985381,-2.7806863e-14 6.12660546,-2.79844524e-14 Z",
                                      fill: "#4380C1",
                                    },
                                  }),
                                  _c("path", {
                                    attrs: {
                                      id: "avatar-red",
                                      d: "M1.7504587,0.404701015 C2.71721035,0.404701015 3.5009174,1.18840807 3.5009174,2.15515972 L3.5009174,3.42071633 L3.5009174,3.42071633 L1.92956762e-13,3.42071633 L1.92956762e-13,2.15515972 C1.92838369e-13,1.18840807 0.783707056,0.404701015 1.7504587,0.404701015 Z",
                                      fill: "#A11D47",
                                    },
                                  }),
                                ]
                              ),
                              _c(
                                "g",
                                {
                                  attrs: {
                                    id: "avatar-1-atom/avatar/kwbot/head",
                                    transform: "translate(9.500000, 14.526692)",
                                  },
                                },
                                [
                                  _c(
                                    "g",
                                    {
                                      attrs: {
                                        id: "avatar-head",
                                        transform:
                                          "translate(1.000000, 0.984615)",
                                      },
                                    },
                                    [
                                      _c(
                                        "mask",
                                        {
                                          attrs: {
                                            id: "avatar-mask-13",
                                            fill: "white",
                                          },
                                        },
                                        [
                                          _c("use", {
                                            attrs: {
                                              "xlink:href": "#avatar-path-12",
                                            },
                                          }),
                                        ]
                                      ),
                                      _c("use", {
                                        attrs: {
                                          fill: "#2B4754",
                                          "xlink:href": "#avatar-path-12",
                                        },
                                      }),
                                      _c(
                                        "g",
                                        {
                                          attrs: {
                                            id: "avatar-Group",
                                            mask: "url(#avatar-mask-13)",
                                          },
                                        },
                                        [
                                          _c(
                                            "g",
                                            {
                                              attrs: {
                                                id: "avatar-1-atom/color/avatar/kwbot/head-color",
                                                transform:
                                                  "translate(-1.000000, -0.984615)",
                                              },
                                            },
                                            [
                                              _c("rect", {
                                                attrs: {
                                                  id: "avatar-Color",
                                                  fill: _vm.faceColor,
                                                  x: "0",
                                                  y: "0",
                                                  width: "45",
                                                  height: "23.6307692",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "mask",
                                    {
                                      attrs: {
                                        id: "avatar-mask-15",
                                        fill: "white",
                                      },
                                    },
                                    [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href": "#avatar-path-14",
                                        },
                                      }),
                                    ]
                                  ),
                                  _c("rect", {
                                    attrs: {
                                      stroke: "#FFFFFF",
                                      "stroke-width": "1.425",
                                      x: "0.7125",
                                      y: "0.7125",
                                      width: "43.575",
                                      height: "22.2057692",
                                      rx: "11.1028846",
                                    },
                                  }),
                                ]
                              ),
                              _c(
                                "g",
                                {
                                  attrs: {
                                    transform:
                                      "translate(29.500000, 31.757461)",
                                    "stroke-linecap": "round",
                                  },
                                },
                                [
                                  _c(
                                    "g",
                                    {
                                      attrs: {
                                        id: "avatar-1-atom/avatar/kwbot/face/mouth",
                                        stroke: "#FFFFFF",
                                        "stroke-width": "1.444",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          id: "avatar-mouth",
                                          d: "M2.57571742e-13,0.460704043 C0.929077396,0.738904081 1.62588544,0.878004099 2.09042414,0.878004099 C2.55496284,0.878004099 3.25177089,0.738904081 4.18084828,0.460704043",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "g",
                                {
                                  attrs: {
                                    id: "avatar-1-atom/avatar/kwbot/face/eye",
                                    transform:
                                      "translate(13.914551, 19.942076)",
                                  },
                                },
                                [
                                  _c("ellipse", {
                                    attrs: {
                                      id: "avatar-right-eye",
                                      stroke: "#FFFFFF",
                                      "stroke-width": "1.444",
                                      cx: "6.56678499",
                                      cy: "6.17663829",
                                      rx: "5.84478499",
                                      ry: "5.45463829",
                                    },
                                  }),
                                  _vm.blink
                                    ? _c("ellipse", {
                                        attrs: {
                                          id: "avatar-right-pupil",
                                          fill: "#FFFFFF",
                                          cx: "6.56678499",
                                          cy: "6.17663829",
                                          rx: "1.74202012",
                                          ry: "1.67338518",
                                        },
                                      })
                                    : _vm._e(),
                                  !_vm.blink
                                    ? _c("path", {
                                        attrs: {
                                          id: "avatar-Combined-Shape",
                                          d: "M12.9632571,0.983789645 C12.9875354,1.14569724 13,1.31023523 13,1.47692308 C13,3.92396935 10.3137085,5.90769231 7,5.90769231 C3.6862915,5.90769231 1,3.92396935 1,1.47692308 C1,1.31023523 1.01246455,1.14569724 1.0367429,0.983789645 Z",
                                          fill: "#FFFFFF",
                                          transform:
                                            "translate(7.000000, 3.445964) scale(1, -1) translate(-7.000000, -3.445964) ",
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              ),
                              _c(
                                "g",
                                {
                                  attrs: {
                                    id: "avatar-1-atom/avatar/kwbot/face/eye",
                                    transform:
                                      "translate(37.005371, 19.942076)",
                                  },
                                },
                                [
                                  _c("ellipse", {
                                    attrs: {
                                      id: "avatar-right-eye",
                                      stroke: "#FFFFFF",
                                      "stroke-width": "1.444",
                                      cx: "6.56678499",
                                      cy: "6.17663829",
                                      rx: "5.84478499",
                                      ry: "5.45463829",
                                    },
                                  }),
                                  _vm.blink
                                    ? _c("ellipse", {
                                        attrs: {
                                          id: "avatar-right-pupil",
                                          fill: "#FFFFFF",
                                          cx: "6.56678499",
                                          cy: "6.17663829",
                                          rx: "1.74202012",
                                          ry: "1.67338518",
                                        },
                                      })
                                    : _vm._e(),
                                  !_vm.blink
                                    ? _c("path", {
                                        attrs: {
                                          id: "avatar-Combined-Shape",
                                          d: "M12.9632571,0.983789645 C12.9875354,1.14569724 13,1.31023523 13,1.47692308 C13,3.92396935 10.3137085,5.90769231 7,5.90769231 C3.6862915,5.90769231 1,3.92396935 1,1.47692308 C1,1.31023523 1.01246455,1.14569724 1.0367429,0.983789645 Z",
                                          fill: "#FFFFFF",
                                          transform:
                                            "translate(7.000000, 3.445964) scale(1, -1) translate(-7.000000, -3.445964) ",
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              ),
                              _c(
                                "g",
                                {
                                  ref: "avatarMoinAI",
                                  attrs: {
                                    id: "avatar-moinai",
                                    transform: "translate(20.000000, 40)",
                                    fill: "#18293E",
                                    "fill-rule": "nonzero",
                                  },
                                },
                                [
                                  _vm.image
                                    ? _c("image", {
                                        ref: "image",
                                        style: _vm.cursor,
                                        attrs: {
                                          x: _vm.posx,
                                          y: _vm.posy,
                                          width: _vm.logoSize,
                                          height: _vm.logoSize,
                                          "xlink:href": _vm.image,
                                        },
                                        on: {
                                          mousedown: _vm.drag,
                                          mouseup: _vm.drop,
                                          mouseleave: _vm.onMouseOut,
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.defaultAvatar && !_vm.image
                                    ? [
                                        _c(
                                          "g",
                                          {
                                            staticStyle: {
                                              transform:
                                                "translate(3.5px, 6px) scale(0.6)",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                d: "M21.694 15.2901V8.57818C21.6646 7.79112 21.3919 7.14276 20.8756 6.63244C20.359 6.12242 19.6569 5.86719 18.7696 5.86719C18.0857 5.86719 17.4347 6.05305 16.8165 6.42472C16.2508 6.76462 15.7983 7.23016 15.458 7.8199C15.3788 7.43217 15.2213 7.09782 14.9834 6.81825C14.7287 6.51969 14.4051 6.28637 14.0123 6.11864C13.6195 5.95126 13.1755 5.86719 12.6811 5.86719C11.8661 5.86719 11.1497 6.06774 10.5315 6.46844C9.96087 6.83839 9.51161 7.32932 9.18275 7.94077L9.03659 6.23886H7.18164V15.2901H9.233V11.0268C9.233 10.4294 9.34927 9.87561 9.58217 9.36529C9.81471 8.85528 10.1349 8.44362 10.5424 8.13002C10.9496 7.81677 11.4153 7.65994 11.9391 7.65994C12.4628 7.67463 12.8372 7.83863 13.063 8.15188C13.2883 8.46548 13.4083 8.85528 13.423 9.32157V15.2901H15.4744V11.005C15.4887 10.4075 15.6087 9.85718 15.8345 9.35434C16.0599 8.85149 16.38 8.44362 16.7947 8.13002C17.2093 7.81677 17.6785 7.65994 18.2022 7.65994C18.726 7.67463 19.0933 7.83863 19.3043 8.15188C19.515 8.46548 19.6279 8.85528 19.6426 9.32157V15.2901H21.694Z",
                                                fill: "#18293E",
                                              },
                                            }),
                                            _c("path", {
                                              attrs: {
                                                d: "M27.6921 9.19199C27.439 8.91648 27.2545 8.5751 27.1386 8.16848C27.0229 7.7621 26.9649 7.34837 26.9649 6.92709V3.31201C26.9649 2.61505 26.8818 2.08896 26.7152 1.73297C26.5491 1.37739 26.3354 1.12697 26.0749 0.981701C25.8144 0.836687 25.5142 0.749618 25.1739 0.720343C24.8341 0.69137 24.4831 0.676758 24.1211 0.676758H21.6973V2.4844H23.7087C23.9983 2.4844 24.2263 2.54249 24.3924 2.65854C24.559 2.77498 24.6746 2.99618 24.7397 3.32289C24.8049 3.64945 24.8375 4.12521 24.8375 4.74925V7.3191C24.8375 8.0018 24.9171 8.55701 25.0762 8.98515C25.2357 9.4136 25.4236 9.74736 25.6407 9.987C25.8577 10.2265 26.0677 10.4116 26.2702 10.5422C26.0823 10.673 25.8794 10.858 25.6624 11.0976C25.4452 11.3373 25.2537 11.653 25.0871 12.0449C24.921 12.4371 24.8375 12.9308 24.8375 13.526V16.3789C24.8375 17.0033 24.8049 17.4787 24.7397 17.8053C24.6746 18.132 24.559 18.3532 24.3924 18.4695C24.2263 18.5856 23.9983 18.6438 23.7087 18.6438H21.6973V20.4296H24.1211C24.4831 20.4296 24.8341 20.4188 25.1739 20.3969C25.5142 20.3752 25.8181 20.2881 26.0858 20.1356C26.3537 19.9831 26.567 19.7035 26.7261 19.2971C26.8855 18.8905 26.9649 18.3025 26.9649 17.5331V14.0921C26.9649 13.6859 27.0229 13.2754 27.1386 12.8616C27.2545 12.4479 27.439 12.1032 27.6921 11.8272C27.9454 11.5515 28.282 11.4134 28.7015 11.4134V9.62759C28.282 9.61338 27.9454 9.46806 27.6921 9.19199Z",
                                                fill: "#18293E",
                                              },
                                            }),
                                            _c("path", {
                                              attrs: {
                                                d: "M9.24969 21.8965V18.6622H5.18525C4.89503 18.6622 4.66651 18.604 4.50001 18.4878C4.3331 18.3714 4.21718 18.15 4.15195 17.823C4.08662 17.496 4.05395 17.0201 4.05395 16.3952V13.5395C4.05395 12.9437 3.97035 12.4495 3.80379 12.0569C3.63689 11.6646 3.44492 11.3486 3.22727 11.1087C3.00978 10.8689 2.80649 10.6837 2.61824 10.5528C2.82108 10.422 3.03153 10.2368 3.24907 9.99702C3.46666 9.75715 3.65496 9.42306 3.81472 8.9942C3.97417 8.56565 4.05395 8.00989 4.05395 7.32653V4.75419C4.05395 4.12953 4.08662 3.65332 4.15195 3.32644C4.21718 2.99941 4.3331 2.778 4.50001 2.66144C4.66651 2.54528 4.89503 2.48713 5.18525 2.48713H7.20101V0.677734H4.77196C4.40921 0.677734 4.05743 0.692361 3.71687 0.721362C3.37586 0.750665 3.07497 0.837819 2.81394 0.982974C2.55291 1.12838 2.33869 1.37905 2.17218 1.73498C2.00528 2.09131 1.92202 2.61791 1.92202 3.31555V6.93414C1.92202 7.35583 1.86384 7.76997 1.74792 8.17673C1.63175 8.58375 1.44677 8.92546 1.19319 9.20124C0.939312 9.47758 0.602027 9.62304 0.181641 9.63726V11.4248C0.602027 11.4248 0.939312 11.5631 1.19319 11.839C1.44677 12.1152 1.63175 12.4603 1.74792 12.8744C1.86384 13.2886 1.92202 13.6995 1.92202 14.1061V17.5504C1.92202 18.3206 2.00155 18.9092 2.16131 19.3162C2.32077 19.723 2.53453 20.0029 2.80301 20.1555C3.07124 20.3081 3.37586 20.3953 3.71687 20.4171C4.05743 20.4389 4.40921 20.4498 4.77196 20.4498H7.20101V24.3107H9.24969L13.4342 21.4762V20.4498H11.3856L9.24969 21.8965Z",
                                                fill: "#18293E",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }