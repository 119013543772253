var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { staticStyle: { "max-height": "100vh", height: "100vh" } },
    [
      _c("MenuPreview", {
        attrs: {
          botAvailable: !!_vm.botAvailable,
          loggedIn: _vm.isLoggedIn,
          channelId: _vm.theChannelId,
          botId: _vm.botId,
        },
      }),
      _c("AppbarPreview", {
        attrs: {
          loggedIn: _vm.isLoggedIn,
          botId: _vm.botId,
          channelId: _vm.theChannelId,
        },
      }),
      _c(
        "v-main",
        [
          _c(
            "v-container",
            { staticClass: "fill-height", attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                {
                  staticClass: "fill-height white",
                  attrs: { "no-gutters": "" },
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "fill-height flex-column d-flex",
                      attrs: { cols: "12" },
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "flex-grow-1" },
                        [
                          _vm.isLoggedIn && _vm.botAvailable
                            ? _c(
                                "v-col",
                                {
                                  staticClass:
                                    "fill-height d-none d-sm-flex flex-sm-column d-sm-flex",
                                  staticStyle: {
                                    "background-color": "#f5f5f5",
                                  },
                                  attrs: { cols: "4", sm: 6, md: 4 },
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "overflow-y-auto",
                                      staticStyle: { height: "0%" },
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "v-card",
                                            {
                                              staticClass:
                                                "pl-3 pr-3 pt-2 pb-3",
                                              attrs: {
                                                flat: "",
                                                color: "#f5f5f5",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-list",
                                                {
                                                  staticClass:
                                                    "pt-0 pb-0 pr-0 pl-0",
                                                  attrs: { "two-line": "" },
                                                },
                                                [
                                                  _vm._l(
                                                    _vm.intents,
                                                    function (item, index) {
                                                      return [
                                                        _c(
                                                          "v-list-item",
                                                          {
                                                            key: index,
                                                            class: {
                                                              selectborder:
                                                                _vm.isSelecteItem(
                                                                  item
                                                                ),
                                                              "pl-5":
                                                                !_vm.isSelecteItem(
                                                                  item
                                                                ),
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-list-item-content",
                                                              [
                                                                _c(
                                                                  "v-list-item-title",
                                                                  {
                                                                    staticClass:
                                                                      "py-2",
                                                                    class: {
                                                                      "primary--text text--lighten-3":
                                                                        item.payload &&
                                                                        item
                                                                          .payload
                                                                          .inReview,
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm.displayName(
                                                                            item.intent,
                                                                            _vm
                                                                              .$vuetify
                                                                              .lang
                                                                              .current
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                    item.payload &&
                                                                    item.payload
                                                                      .inReview
                                                                      ? [
                                                                          _c(
                                                                            "v-tooltip",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  bottom:
                                                                                    "",
                                                                                },
                                                                              scopedSlots:
                                                                                _vm._u(
                                                                                  [
                                                                                    {
                                                                                      key: "activator",
                                                                                      fn: function ({
                                                                                        on: onReview,
                                                                                      }) {
                                                                                        return [
                                                                                          _c(
                                                                                            "v-icon",
                                                                                            _vm._g(
                                                                                              {
                                                                                                staticStyle:
                                                                                                  {
                                                                                                    position:
                                                                                                      "relative",
                                                                                                    top: "-8px",
                                                                                                    right:
                                                                                                      "-4px",
                                                                                                  },
                                                                                                attrs:
                                                                                                  {
                                                                                                    color:
                                                                                                      "primary lighten-3",
                                                                                                    size: "16",
                                                                                                  },
                                                                                              },
                                                                                              onReview
                                                                                            ),
                                                                                            [
                                                                                              _vm._v(
                                                                                                " hourglass_top "
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                                  null,
                                                                                  true
                                                                                ),
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        _vm.isDemoLicence
                                                                                          ? _vm.$t(
                                                                                              "intents.inReview.tooltipInReviewDemo"
                                                                                            )
                                                                                          : _vm.$t(
                                                                                              "intents.inReview.tooltipInReview"
                                                                                            )
                                                                                      ) +
                                                                                      " "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      : _vm._e(),
                                                                  ],
                                                                  2
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  bottom: "",
                                                                  color:
                                                                    "primary lighten-2",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "activator",
                                                                        fn: function ({
                                                                          on,
                                                                          attrs,
                                                                        }) {
                                                                          return [
                                                                            _c(
                                                                              "BtnSquare",
                                                                              _vm._g(
                                                                                _vm._b(
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        disabled:
                                                                                          _vm
                                                                                            .teaserButtons
                                                                                            .length >
                                                                                            2 ||
                                                                                          (item.payload &&
                                                                                            item
                                                                                              .payload
                                                                                              .inReview),
                                                                                        colorIcon:
                                                                                          "grey darken-1",
                                                                                        icon: "control_point",
                                                                                      },
                                                                                    on: {
                                                                                      click:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          _vm.addTeaserButton(
                                                                                            {
                                                                                              intent:
                                                                                                item.intent,
                                                                                              text: _vm.displayName(
                                                                                                item.intent,
                                                                                                _vm
                                                                                                  .$vuetify
                                                                                                  .lang
                                                                                                  .current
                                                                                              ),
                                                                                            }
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  },
                                                                                  "BtnSquare",
                                                                                  attrs,
                                                                                  false
                                                                                ),
                                                                                on
                                                                              )
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "preview.teaser.addbutton"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  bottom: "",
                                                                  color:
                                                                    "primary lighten-2",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "activator",
                                                                        fn: function ({
                                                                          on,
                                                                          attrs,
                                                                        }) {
                                                                          return [
                                                                            _c(
                                                                              "BtnSquare",
                                                                              _vm._g(
                                                                                _vm._b(
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        icon: "play_circle",
                                                                                        colorIcon:
                                                                                          "primary lighten-2",
                                                                                      },
                                                                                    on: {
                                                                                      click:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.sendIntent(
                                                                                            item
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  },
                                                                                  "BtnSquare",
                                                                                  attrs,
                                                                                  false
                                                                                ),
                                                                                on
                                                                              )
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "preview.intent"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        index <
                                                        _vm.intents.length - 1
                                                          ? _c("v-divider", {
                                                              key:
                                                                "div-" + index,
                                                              attrs: {
                                                                inset: "",
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ]
                                                    }
                                                  ),
                                                ],
                                                2
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "transition",
                            { attrs: { name: "slide-left" } },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "flex-column d-flex fill-height white",
                                  attrs: {
                                    cols: "12",
                                    sm:
                                      _vm.isLoggedIn && _vm.botAvailable
                                        ? 6
                                        : 12,
                                    md:
                                      _vm.isLoggedIn && _vm.botAvailable
                                        ? 8
                                        : 12,
                                  },
                                },
                                [
                                  _c(
                                    "v-container",
                                    {
                                      staticClass: "fill-height py-0",
                                      attrs: { fluid: "" },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        { staticClass: "fill-height" },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "d-none d-md-block",
                                              attrs: { cols: "12" },
                                            },
                                            [
                                              _c(
                                                "v-card",
                                                {
                                                  attrs: {
                                                    color:
                                                      "fill-height flex-column d-flex",
                                                    flat: "",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-row",
                                                    {
                                                      staticClass:
                                                        "flex-grow-0 grey lighten-2",
                                                    },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "2",
                                                            md: "1",
                                                            offset: "1",
                                                          },
                                                        },
                                                        [
                                                          _c("v-sheet", {
                                                            staticClass:
                                                              "d-flex",
                                                            attrs: {
                                                              color:
                                                                "grey lighten-4",
                                                              height: "32",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "4",
                                                            md: "7",
                                                          },
                                                        },
                                                        [
                                                          _c("v-sheet", {
                                                            staticClass:
                                                              "d-flex",
                                                            attrs: {
                                                              color:
                                                                "grey lighten-4",
                                                              height: "32",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "2",
                                                            md: "1",
                                                          },
                                                        },
                                                        [
                                                          _c("v-sheet", {
                                                            staticClass:
                                                              "d-flex",
                                                            attrs: {
                                                              color:
                                                                "grey lighten-4",
                                                              height: "32",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "2",
                                                            md: "1",
                                                          },
                                                        },
                                                        [
                                                          _c("v-sheet", {
                                                            staticClass:
                                                              "d-flex",
                                                            attrs: {
                                                              color:
                                                                "grey lighten-4",
                                                              height: "32",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-row",
                                                    {
                                                      staticClass:
                                                        "flex-grow-1 grey lighten-2",
                                                    },
                                                    [
                                                      _c("v-col", {
                                                        staticClass:
                                                          "grey lighten-3",
                                                        attrs: { cols: "12" },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }