var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isSupervisor &&
    (_vm.isAllClear ||
      !_vm.activeChat ||
      _vm.isChatAcceptable ||
      _vm.isChatOtherAgent)
    ? _c("div", { staticClass: "full-height moin-fade-in" }, [
        !_vm.activeChat
          ? _c(
              "div",
              { staticClass: "full-height d-flex items-center justify-center" },
              [
                !_vm.isAllClear && !_vm.activeChat
                  ? _c("div", [
                      _c(
                        "div",
                        {
                          staticClass:
                            "text-h6 grey--text text--lighten-1 text-center",
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "grey--text text--lighten-1",
                              attrs: { size: "28" },
                            },
                            [_vm._v(" mdi-message-bulleted-off ")]
                          ),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("livechat.chats.noSelectedChatTitle")
                              ) +
                              " "
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "text-body-1 grey--text text--lighten-1 text-center",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("livechat.chats.noSelectedChatBody")
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm.isAllClear
                  ? _c("div", [
                      _c(
                        "div",
                        {
                          staticClass:
                            "text-h6 grey--text text--lighten-1 text-center",
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "grey--text text--lighten-1",
                              attrs: { size: "32" },
                            },
                            [_vm._v(" mdi-party-popper ")]
                          ),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("livechat.chats.allChatsClearTitle")
                              ) +
                              " "
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "text-body-1 grey--text text--lighten-1 text-center",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("livechat.chats.allChatsClearBody")
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]
            )
          : _vm._e(),
        _vm.isChatAcceptable && _vm.agentStatus === _vm.statusTypes.online
          ? _c(
              "div",
              { staticClass: "full-height d-flex items-center justify-center" },
              [
                _c(
                  "div",
                  { staticClass: "text-center" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "secondary mb-2",
                        attrs: { depressed: "" },
                        on: {
                          click: function ($event) {
                            return _vm.startChat()
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("livechat.chats.chatNotStartedTitle")
                            ) +
                            " "
                        ),
                        _c("v-icon", { attrs: { right: "" } }, [
                          _vm._v("mdi-forum"),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "text-body-2 primary--text text--lighten-3 text-center pa-4",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("livechat.chats.chatNotStartedBody")
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
        _vm.isChatOtherAgent
          ? _c(
              "div",
              { staticClass: "full-height d-flex items-center justify-center" },
              [
                _c(
                  "v-icon",
                  {
                    staticClass: "grey--text text--lighten-1",
                    attrs: { size: "32" },
                  },
                  [_vm._v(" mdi-eye-lock-open-outline ")]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "text-body-2 primary--text text--lighten-3 text-center",
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("livechat.chats.chatOpenedByAnotherAgentHint")
                        ) +
                        " "
                    ),
                  ]
                ),
              ],
              1
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }