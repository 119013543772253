<template>
  <div
    class="full-height d-flex flex-column flex-grow-1"
    v-if="activeChat && activeChat.status === 'open' || inSupervisorMode"
  >
    <div class="flex-grow-1 relative">
      <div
        style="
          overflow: hidden;
          overflow-y: auto;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        "
      >
        <div class="mx-auto pa-4" style="max-width: 800px">
          <LivechatHistory />
          
          <LivechatChatItem
            v-for="item in messages"
            :key="item.id"
            :item="item"
            :isTranslated="(useTranslation && typeof item.translated !== 'undefined')"
            :chat="activeChat"
          />
          <div v-if="isOffline" class="relative text-center">
            <div
              class="error"
              style="
                position: absolute;
                top: 14px;
                left: 0;
                right: 0;
                height: 1px;
              "
            ></div>
            <div
              class="d-inline-block mx-auto relative white px-3 text-body-1 error--text"
            >
              {{ $t('livechat.chats.connectionLostAt') }} {{ getTimeFormatted() }} h
            </div>
          </div>
          <div id="end-of-chat"></div>
        </div>
      </div>
    </div>

    <div style="background: #d1d4d9">
      <div v-if="isOffline" class="error white--text text-center py-1 px-2">
        {{ $t('livechat.chats.userLostAt') }} {{ lastUpdated }}
      </div>
      <div class="pa-3 pl-0">
        <LivechatChatInput v-if="agentStatus === statusTypes.online" class="flex-grow-1" />
        <div class="d-flex justify-space-between pt-3">
          <div></div>
          <LivechatCloseChat v-if="activeChat && ![chatStatus.closed, chatStatus.unassigned].includes(activeChat.status)" :chat="activeChat" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import LivechatChatItem from '@/components/livechat/LivechatChatItem.vue';
import LivechatChatInput from '@/components/livechat/LivechatChatInput.vue';
import LivechatCloseChat from '@/components/livechat/LivechatCloseChat.vue';
import LivechatHistory from '@/components/livechat/LivechatHistory.vue';
import { getTimeString } from '@/utils/livechat';
import { chatStatus, statusTypes } from '@/utils/livechat';

export default {
  data() {
    return {
      chatStatus,
      statusTypes,
    }
  },
  name: 'LivechatChat',
  components: {
    LivechatHistory,
    LivechatChatItem,
    LivechatChatInput,
    LivechatCloseChat,
  },
  computed: {
    ...mapGetters('livechat', ['agentStatus', 'activeChat', 'isChatOpen', 'chatTranslations', 'inSupervisorMode', 'useTranslation']),
    lastUpdated() {
      if (!this.activeChat) return null;
      return getTimeString(this.activeChat?.updated_at);
    },
    messages() {
      const messages = [
        ...(this.activeChat?.messages?.filter(m => m.type === 'message').map(m => ({...m, translated: this.chatTranslations[m.data]})) || []),
        ...(this.activeChat?.agentNotes?.map(n => ({...n, type: 'note'})) || []),
      ].sort((a, b) => moment(a.created_at).diff(moment(b.created_at)));
      return messages;
    },
    isOffline() {
      return !!(this.activeChat && this.activeChat.status === 'offline');
    },
  },
  watch: {
    'activeChat.messages'() {
      this.scrollDown();
    },
  },
  methods: {
    getTimeFormatted() {
      return moment(this.activeChat.updated_at).format('HH:mm');
    },
    async scrollDown() {
      if (!this.isChatOpen) return;
      await new Promise((resolve) => setTimeout(resolve, 300));
      let el = document.querySelector('#end-of-chat');
      el?.scrollIntoView({ behavior: 'smooth' });
    },
  },
};
</script>
<style scoped lang="scss">
.chat-input {
  padding: $spacer $spacer * 2;
  border-radius: 20px;
  background: white;
  outline: none;
  border: 1px solid transparent;
  width: 100%;
}

.chat-input:focus {
  border: 1px solid var(--v-primary-lighten3);
}
</style>
