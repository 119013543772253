var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        persistent: "",
        value: _vm.isOpen,
        "max-width": "500px",
        "hide-overlay": "",
      },
    },
    [
      _c(
        "div",
        { staticClass: "hub-panel" },
        [
          _vm.loading
            ? _c("v-progress-linear", {
                attrs: {
                  absolute: "",
                  indeterminate: "",
                  color: "secondary darken-2",
                },
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "hub-panel-logo py-8 px-2" },
            [
              _c(
                "div",
                { staticClass: "mb-8" },
                [
                  _c("v-img", {
                    staticClass: "mx-auto",
                    attrs: {
                      src: require("@/assets/moin-logo.svg"),
                      width: "160",
                    },
                  }),
                ],
                1
              ),
              _vm._t("default"),
            ],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }