<template>
  <div
    v-if="!isSupervisor && (isAllClear || !activeChat || isChatAcceptable || isChatOtherAgent)"
    class="full-height moin-fade-in"
  >
    <div
      v-if="!activeChat"
      class="full-height d-flex items-center justify-center"
    >
      <!-- Not selected -->
      <div v-if="!isAllClear && !activeChat">
        <div class="text-h6 grey--text text--lighten-1 text-center">
          <v-icon class="grey--text text--lighten-1" size="28">
            mdi-message-bulleted-off
          </v-icon>
          {{ $t('livechat.chats.noSelectedChatTitle') }}
        </div>
        <div class="text-body-1 grey--text text--lighten-1 text-center">
          {{ $t('livechat.chats.noSelectedChatBody') }}
        </div>
      </div>

      <!-- All clear -->
      <div v-if="isAllClear">
        <div class="text-h6 grey--text text--lighten-1 text-center">
          <v-icon class="grey--text text--lighten-1" size="32">
            mdi-party-popper
          </v-icon>
          {{ $t('livechat.chats.allChatsClearTitle') }}
        </div>
        <div class="text-body-1 grey--text text--lighten-1 text-center">
          {{ $t('livechat.chats.allChatsClearBody') }}
        </div>
      </div>
    </div>
    <!-- Chat not started -->
    <div
      v-if="isChatAcceptable && agentStatus === statusTypes.online"
      class="full-height d-flex items-center justify-center"
    >
      <div class="text-center">
        <v-btn class="secondary mb-2" depressed @click="startChat()">
          {{ $t('livechat.chats.chatNotStartedTitle') }}
          <v-icon right>mdi-forum</v-icon>
        </v-btn>
        <div class="text-body-2 primary--text text--lighten-3 text-center pa-4">
          {{ $t('livechat.chats.chatNotStartedBody') }}
        </div>
      </div>
    </div>
    <!-- Not your chat! -->
    <div
      class="full-height d-flex items-center justify-center"
      v-if="isChatOtherAgent"
    >
      <v-icon class="grey--text text--lighten-1" size="32">
        mdi-eye-lock-open-outline
      </v-icon>
      <div class="text-body-2 primary--text text--lighten-3 text-center">
        {{ $t('livechat.chats.chatOpenedByAnotherAgentHint') }}
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { chatStatus, statusTypes } from '@/utils/livechat';
export default {
  name: 'LivechatEmpty',
  data() {
    return {
      statusTypes,
    };
  },
  components: {},
  computed: {
    ...mapGetters('auth', ['isSupervisor']),
    ...mapGetters('livechat', [
      'agent',
      'agentStatus',
      'chatsUnassigned',
      'chatsOpen',
      'activeChat',
    ]),
    isAllClear() {
      if (this.chatsUnassigned?.length || this.chatsOpen?.length) {
        return false;
      }
      return true;
    },
    isChatAcceptable() {
      return (
        this.activeChat && this.activeChat.status === chatStatus.unassigned
      );
    },
    isChatOtherAgent() {
      return this.activeChat?.agent && this.activeChat.agent !== this.agent._id;
    },
  },
  methods: {
    startChat() {
      this.$store.dispatch('livechat/startChat', this.activeChat);
    },
  },
};
</script>
