<template>
    <EditModal
        :open="open"
        @save="onSave"
        @cancel="onCancel"
        :save-button-label="$t('common.add')"
        maxWidth="800px"
    >
        <template #title>
            Notes
        </template>
        <template #content>
            <v-textarea
                v-model="text"
                label="Note"
                outlined
                filled
                hide-details
            ></v-textarea>
        </template>
    </EditModal>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import EditModal from '@/components/common/EditModal.vue';

export default {
    name: 'LivechatFilesModal',
    components: {
        EditModal
    },
    props: {
        open: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            text: ''
        }
    },
    computed: {
        ...mapGetters('livechat', ['activeChat', 'activeChatFiles', 'activeChatGroups', 'organization']),
    },
    methods: {
        ...mapActions('livechat', ['addNoteToChat']),
        onSave() {
            this.$emit('add', this.text);
            this.addNoteToChat({
                chatUuid: this.activeChat.uuid,
                note: this.text
            });
            this.reset();
        },
        onCancel() {
            this.$emit('cancel');
            this.reset();
        },
        reset() {
            this.text = '';
        }
    }
}
</script>