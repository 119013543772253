var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "BtnSquare",
    {
      attrs: { "with-slot": true, disabled: _vm.disabled },
      on: {
        click: function ($event) {
          return _vm.$emit("click", $event)
        },
      },
    },
    [_c("v-icon", [_vm._v("mdi-dots-vertical")])],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }