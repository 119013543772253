<template>
  <v-main class="grey lighten-2 fill-height moin-fresh">
    <v-container fluid class="fill-height white">
      <!-- frame -->
      <v-row class="fill-height white">
        <!-- content -->
        <v-col cols="12" class="fill-height flex-column d-flex py-0">
          <!-- fix top bar -->
          <v-row v-if="!hideHeader" class="flex-grow-0">
            <v-col cols="12" class="pa-0">
              <!-- title -->
              <v-card-title class="pa-0" style="height: 60px">
                <v-col cols="4" class="flex-nowrap">
                  <v-row class="pa-0 ma-0 flex-nowrap">
                    <!-- icon btn back -->
                    <v-tooltip
                      v-if="back !== ''"
                      bottom
                      color="primary lighten-3"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          <BtnSquare icon="arrow_back" :to="back" exact />
                        </span>
                      </template>
                      <slot name="back-button">
                        <span>{{ $t('common.back') }}</span>
                      </slot>
                    </v-tooltip>

                    <!-- Title -->
                    <div
                      class="pl-2 pt-1"
                      :class="isAdminOnly && 'admin--text'"
                    >
                      <slot name="title" :title="title">
                        <span
                          v-if="title"
                          :key="title"
                          class="text-truncate text-h5"
                        >
                          {{ title }}
                        </span>
                        <v-skeleton-loader
                          v-else
                          boilerplate
                          class="pt-2 grow"
                          type="heading"
                        />
                      </slot>
                    </div>
                  </v-row>
                </v-col>

                <!-- action icons toolbar -->
                <v-col cols="8" class="text-right">
                  <slot name="toolbar" :disabled="isLoading" />
                </v-col>
              </v-card-title>
              <v-divider />
            </v-col>
          </v-row>

          <!-- content container (scrollable) -->
          <v-row class="flex-grow-1 flex-nowrap" style="height: 0%">
            <!-- scroll container hack -->
            <v-col
              class="relative pa-0 fill-height"
              :class="[
                contentClass,
                assistantOpen ? 'moin-bg-skyblue' : 'moin-bg-grey-lightest',
              ]"
              :cols="
                isSidebarOpen || ($vuetify.breakpoint.mdAndUp && assistantOpen)
                  ? 12 - sidebarWidth
                  : '12'
              "
            >
              <v-divider class="grey lighten-4" />
              <v-progress-linear
                v-if="isLoading"
                indeterminate
                absolute
                color="secondary"
              />

              <v-row
                ref="contentContainer"
                class="overflow-y-auto ma-0 pa-0"
                style="height: 0%"
                data-cy="hub-editor-frame"
              >
                <v-col class="pa-0" cols="12">
                  <v-card
                    class="px-3 pb-0 pt-1 fill-height"
                    flat
                    tile
                    color="transparent"
                  >
                    <v-card
                      v-if="!hideEditorHeader" 
                      flat
                      class="pa-0 mx-0 mt-2 mb-0 rounded-0 rounded-t"
                    >
                      <div
                        class="pa-4"
                        :class="[
                          $vuetify.breakpoint.smAndUp &&
                            'd-flex justify-space-between',
                        ]"
                        v-intersect="onSaveButtonIntersect"
                      >
                        <template v-if="assistantOpen && assistantDirty">
                          <AssistantHubBtns />
                        </template>
                        <template v-else>
                          <!-- channel details if more than one channel -->
                          <div>
                            <ChannelSelection
                              v-if="!noChannelHandling && channels?.length"
                              :intent="content"
                              :disabled="isLoading"
                              :class="[$vuetify.breakpoint.xs && 'mb-2']"
                              @input="selectedChannel = $event"
                              firstIsNull
                              omitBlocked
                            />
                          </div>

                          <!-- reset and save button -->
                          <div class="text-right">
                            <v-btn
                              :disabled="!draft || isLoading"
                              text
                              color="primary lighten-2"
                              @click="reset"
                            >
                              <slot name="discardbutton">
                                {{ $t('editor.common.discard') }}
                                <v-icon right>delete_outline</v-icon>
                              </slot>
                            </v-btn>
                            <v-btn
                              :disabled="!draft || isLoading || disabled"
                              depressed
                              class="ml-2"
                              color="secondary"
                              @click="save"
                              data-cy="hub-editor-frame-save-btn"
                            >
                              <slot name="savebutton">
                                {{ $t(saveLabel) }}
                                <v-icon right>{{ saveIcon }}</v-icon>
                              </slot>
                            </v-btn>

														<slot name="belt-content-after" />
                          </div>
                        </template>
                      </div>
                    </v-card>
                    <v-divider class="grey lighten-3" />
                    <slot :channelId="selectedChannel" :isLoading="isLoading">
                      <!-- editor content -->
                    </slot>
                  </v-card>
                </v-col>
              </v-row>

              <transition name="slide">
                <!-- fixed bottom save bar "slided in" -->
                <v-row
                  v-show="bottomBarShown"
                  class="ma-0 pa-0 shrink rounded-0"
                  style="overflow-y: scroll"
                  color="#f5f5f5"
                >
                  <v-col class="pa-0 rounded-0" cols="12">
                    <v-card flat class="pa-0 mx-3 mt-0 mb-0 rounded-0">
                      <v-divider class="grey lighten-3" />
                      <v-row class="ma-0 px-0 pt-1 pb-2">
                        <v-col class="pb-3 grow text-right">
                          <v-btn
                            :disabled="!draft || isLoading"
                            class="mr-4"
                            text
                            color="primary lighten-2"
                            @click="reset"
                          >
                            <slot name="discardbutton">
                              {{ $t('editor.common.discard') }}
                              <v-icon right>delete_outline</v-icon>
                            </slot>
                          </v-btn>
                          <v-btn
                            depressed
                            :disabled="!draft || isLoading || disabled"
                            color="secondary"
                            @click="save"
                            data-cy="hub-editor-frame-save-btn"
                          >
                            <slot name="savebutton">
                              {{ $t(saveLabel) }}
                              <v-icon right>{{ saveIcon }}</v-icon>
                            </slot>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </transition>
            </v-col>

            <v-col
              v-if="assistantOpen"
              :cols="4"
              class="pa-0 hub-editor-frame-assistant"
              :class="
                $vuetify.breakpoint.smAndDown
                  ? 'hub-editor-frame-assistant--fixed'
                  : 'hub-editor-frame-assistant--col'
              "
            >
              <slot name="assistant"></slot>
            </v-col>

            <!-- Sidebar md and above -->
            <HubContentSidebar
              :items="sidebarItems"
              :width="sidebarWidth"
              :value="value"
              @input="closeSidebar"
            >
              <template
                v-for="sidebarItem in sidebarItems"
                v-slot:[sidebarItem.name]="{ item }"
              >
                <slot
                  v-if="sidebarItem.name"
                  :name="`sidebar.${sidebarItem.name}`"
                  :item="item"
                />
              </template>

              <template
                v-for="(sidebarItem, index) in sidebarItems"
                v-slot:[index]="{ item }"
              >
                <slot
                  v-if="!sidebarItem.name"
                  :name="`sidebar.${index}`"
                  :item="item"
                />
              </template>
            </HubContentSidebar>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <!-- Dialogs -->
    <HubDialog
      v-model="saveDialog"
      :title="$t(saveConfirmationDialogTitle)"
      modal
      :primary-icon="saveIcon"
      :primary-label="$t(saveLabel)"
      @onPrimary="saveCommit"
      :disabled="disabled"
    >
      <span v-html="$t(saveConfirmationDialogDescription)" />
      <slot name="saveDialog.content" :disabled="disabled" />
    </HubDialog>

    <HubDialog
      v-model="resetDialog"
      :title="$t('editor.dialogs.discard.title')"
      modal
      primary-icon="delete"
      :primary-label="$t('editor.common.discard')"
      @onPrimary="resetCommit"
    >
      {{ $t('editor.dialogs.discard.description') }}
    </HubDialog>
  </v-main>
</template>

<script>
import ChannelSelection from '@/components/editor/ChannelSelection.vue';
import HubDialog from './HubDialog.vue';
import HubContentSidebar from '@/components/hub/HubContentSidebar';
import BtnSquare from '@/components/common/BtnSquare.vue';
import AssistantHubBtns from '@/components/assistant/AssistantHubBtns.vue';

export default {
  name: 'HubEditorFrame',
  components: {
    ChannelSelection,
    HubDialog,
    HubContentSidebar,
    BtnSquare,
    AssistantHubBtns,
  },
  props: {
    value: {
      type: [Boolean, Object],
    },
    title: {
      type: String,
      default: '',
    },
    back: {
      type: [String, Object],
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    draft: {
      type: Boolean,
      default: false,
    },
    channels: {
      type: Array,
      default: function () {
        return [];
      },
    },
    content: {
      type: String,
      default: null,
    },
    sidebarItems: {
      type: Array,
      default: () => {
        return [{ title: 'Info', icon: 'info', name: 'info' }];
      },
    },
    saveIcon: {
      type: String,
      default: 'save',
    },
    saveLabel: {
      type: String,
      default: 'editor.common.save',
    },
    saveConfirmationDialogTitle: {
      type: String,
      default: 'editor.dialogs.save.title',
    },
    saveConfirmationDialogDescription: {
      type: String,
      default: 'editor.dialogs.save.description',
    },
    sidebarWidth: {
      type: Number,
      default: 4,
    },
    disabled: {
      type: Boolean,
    },
    assistantOpen: {
      type: Boolean,
      default: false,
    },
    assistantDirty: {
      type: Boolean,
      default: false,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    hideEditorHeader: {
      type: Boolean,
      default: false,
    },
    noChannelHandling: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      bottomBarShown: false,
      selectedChannel: null,
      saveDialog: false,
      resetDialog: false,
      sidebarContent: null,
    };
  },
  computed: {
    isSidebarOpen() {
      return !!this.value;
    },
    showChannelSelection() {
      return this.channels.length > 1;
    },
    contentClass() {
      if (this.isLoading && !this.isSidebarOpen) {
        return 'flex-column d-flex fill-height flex-grow-1';
      } else if (this.isSidebarOpen && this.isLoading) {
        return 'flex-md-column d-md-flex flex-md-grow-1 hidden-sm-and-down slide-width'; // sm and xs hide content
      } else if (this.isSidebarOpen) {
        return 'flex-md-column d-md-flex flex-md-grow-1 hidden-sm-and-down slide-width'; // sm and xs hide content
      } else {
        return 'flex-column d-flex fill-height flex-grow-1';
      }
    },
    isLoading() {
      return this.loading === true;
    },
    isBottomSaveBar() {
      return this.bottomSaveBar && (this.draft || this.assistantDirty);
    },
    channelName() {
      const channel = this.channels.find((c) => {
        return c.channelId === this.selectedChannel;
      });
      return channel ? channel.displayName : '';
    },
    channelLanguage() {
      const channel = this.channels.find((c) => {
        return c.channelId === this.selectedChannel;
      });

      if (
        channel &&
        Array.isArray(channel.languages) &&
        channel.languages.length > 0
      ) {
        switch (channel.languages[0]) {
          case 'de':
            return 'Deutsch';
          case 'en':
            return 'Englisch';
        }
      }

      return 'n.a.';
    },
    channelIcon() {
      const channel = this.channels.find((c) => {
        return c.channelId === this.selectedChannel;
      });

      if (!channel) {
        return '';
      }

      return this.$channelIcon(channel);
    },
    channelTypeDescription() {
      const channel = this.channels.find((c) => {
        return c.channelId === this.selectedChannel;
      });

      if (!channel) {
        return '';
      }

      switch (channel.channel) {
        case 'widget':
          return 'Website';
        case 'facebook':
          return 'Facebook Messenger';
      }

      return '';
    },
    isAdminOnly() {
      return this.$route.meta?.admin || false;
    },
  },
  watch: {
    selectedChannel(channelId) {
      this.$emit('onChannelChanged', channelId);
    },
  },
  methods: {
    scrollTo(position) {
      this.$refs.contentContainer.scrollTo(0, position);
    },
    closeSidebar() {
      this.$emit('input', false);
    },
    onSaveButtonIntersect(entries) {
      if (Array.isArray(entries) && entries.length > 0) {
        this.bottomBarShown = !entries[0].isIntersecting;
      }
    },
    save() {
      this.saveDialog = true;
    },
    saveCommit() {
      this.$emit('onSave');
    },
    reset() {
      this.resetDialog = true;
    },
    resetCommit() {
      this.$emit('onReset');
    },
  },
  mounted() {
    this.channelId = this.suggestedChannelId;
  }
};
</script>

<style scoped lang="scss">
.slide-width {
  transition: max-width 0.2s ease-in 0.05s;
  -webkit-transition: max-width 0.2s ease-in 0.05s;
  -o-transition: max-width 0.2s ease-in 0.05s;
  -moz-transition: max-width 0.2s ease-in 0.05s;
}

/* slide in */
.slide-left-enter-active {
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -moz-transition-delay: 0.05s;
  -webkit-transition-delay: 0.05;
  -o-transition-delay: 0.05s;
  transition-delay: 0.05s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-left-enter,
.slide-left-leave-to {
  margin-right: -100%;
}

.hub-editor-frame-assistant {
  overflow: hidden;
}

.hub-editor-frame-assistant--col {
  max-height: 100%;
  height: 100%;
}

.hub-editor-frame-assistant--fixed {
  display: block;
  position: fixed;
  z-index: 100;
  top: 260px;
  right: $spacer * 5;
  bottom: 70px;
  width: 300px;
  max-width: 300px;
  border-radius: $spacer * 2;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}
</style>
