var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { on: { click: _vm.resetInactivity, keydown: _vm.resetInactivity } },
    [
      !_vm.isMaintenance && _vm.user
        ? _c("AppBotLoader", {
            on: {
              onBotLoaded: function ($event) {
                return _vm.handleBotLoaded()
              },
            },
          })
        : _vm._e(),
      _vm.isBotLoaded || _vm.isPublic ? _c("router-view") : _vm._e(),
      !_vm.isMaintenance && _vm.currentBot ? _c("HubFaviconIncome") : _vm._e(),
      _vm.globalSnackbar
        ? _c(
            "v-snackbar",
            {
              attrs: { timeout: 3000 },
              model: {
                value: _vm.globalSnackbar.show,
                callback: function ($$v) {
                  _vm.$set(_vm.globalSnackbar, "show", $$v)
                },
                expression: "globalSnackbar.show",
              },
            },
            [
              _vm._v(" " + _vm._s(_vm.globalSnackbar.message) + " "),
              _c("v-spacer"),
              _vm.globalSnackbar.icon
                ? _c("v-icon", { attrs: { color: _vm.globalSnackbar.color } }, [
                    _vm._v(" " + _vm._s(_vm.globalSnackbar.icon) + " "),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }