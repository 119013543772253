import { AssistantService } from '@/services/assistant';
import { set } from 'vue';

/**
 * The AI topic fill is made to somewhat support an unknown number of concepts.
 * The backend decides how many to create, but we need some sort of idea in frontend
 * too, since they are done sequentially.
 *
 * This is how many concepts we expect to make.
 */
const assumedConceptCount = 3;
export const letters = ['A', 'B', 'C'];
export const steps = {
  // Figuring out which step we're on
  INIT: 1,
  // Let user configure the source for generating
  SETUP: 2,
  // Waiting for generating to finish at least 1 concept and load its content
  GENERATING: 3,
  // We have at least 1 concept. Content and other concepts may still be loading
  RESULTS: 4,
  // Taking a concept/content and applying it
  TAKING: 5,
  // Any error in any step that made all columns fail
  ERROR: 6,
};

const persistentState = {
  /**
   * @typedef {Object} PersistentTopicAssistantState
   * @property {string[]} subscribed List of intents that we're expecting to finish processing
   */
  /**
   * @type {PersistentTopicAssistantState}
   */
  data: JSON.parse(localStorage.getItem("topicAssistant")) || { subscribed: [] },
  /**
   * Tracks count of alerts sent for which intent. Ued for automatic unsubscribing upon expected alert count
   * @type {Record<string, number>} Key is intent, count is number of alerts sent
   */
  alertsSent: {},
  /**
   * Subscribes to an intent:
   * - receive alerts when processing is done/partially done
   * - resume processing if the page is reloaded
   * @param {string} intent
   */
  subscribe(intent) {
    console.debug("[persistentState.subscribe] Subscribing to %s", intent);
    if (this.data.subscribed.includes(intent)) return;
    this.data.subscribed.push(intent);
    this.save();
  },
  /**
   * Unsubscribes from intents:
   * - no more resuming this processing
   * - no alerts sent on updates of processing
   * @param {string} intent
   */
  unsubscribe(intent) {
    console.debug("[persistentState.unsubscribe] Unsubscribing from %s", intent);
    this.data.subscribed = this.data.subscribed.filter((i) => i !== intent);
    this.save();
  },
  /**
   * Marks that an alert was sent for this intent
   * @param {string} intent
   */
  markAlerted(intent) {
    if (!this.alertsSent[intent]) this.alertsSent[intent] = 1;
    else this.alertsSent[intent]++;

    console.debug("[persistentState.markAlerted] Marking alert sent for %s. New count: %s", intent, this.alertsSent[intent]);
    if (this.alertsSent[intent] === assumedConceptCount) {
      console.debug("[persistentState.markAlerted] Max alert threshold met, unsubscribing.");
      this.unsubscribe(intent);
    }
  },
  /**
   * Checks whether we should show a UI alert for this intent
   * @param {string} intent
   * @returns {boolean}
   */
  shouldAlert(intent) {
    if (!this.data.subscribed.includes(intent)) return false;
    return this.alertsSent[intent] !== assumedConceptCount;
  },
  /**
   * Saves the persistent state data to localStorage
   */
  save() {
    localStorage.setItem("topicAssistant", JSON.stringify(this.data));
  },
  /**
   * Checks to see if there are intents that should be processed
   * @returns {boolean}
   */
  get hasIntentsToProcess() {
    return !!this.data.subscribed.length;
  },
};
// TODO Remove when done debugging
window.ps = persistentState;

/**
 * @typedef {Object} TopicAssistantError
 * @property {?string} [title] The title of the error
 * @property {string} message The error message
 */
/**
 * @typedef {Object} TopicAssistantData
 * @property {boolean} isTakingConcept Whether the user is currently taking a concept
 * @property {?Object} [conceptTask] The current task for generating concepts
 * @property {Array<Object>} concepts A list of concept records
 * @property {Array<Object>} contents A list of content (boxes/nodes) for each concept
 * @property {?string} [url] The URL used in setup (cached on submit)
 * @property {?string} [content] Optional content used in setup (cached on submit)
 * @property {number} step The current step the topic generation is at
 * @property {null|TopicAssistantError} [taskError] Error that will take up the screen because there's nothing to see
 * @property {Array<null|TopicAssistantError>} columnErrors Errors that happened per column (concept or content). Index is column number.
 */
/**
 * @returns {TopicAssistantData}
 */
const makeState = () => ({
  isTakingConcept: false,
  conceptTask: null,
  concepts: [],
  contents: [],
  step: steps.INIT,
  url: '',
  content: '',
  columnErrors: Array(assumedConceptCount).fill(null),
  taskError: null,
});

/**
 * @callback ConceptReadyCallback
 * @param {string} intent The intent that has concepts ready
 * @param {'completed'|'error'} status The status of the concept generation
 * @returns {any|Promise<any>}
 */
/**
 * @typedef {Object} TopicAssistantState
 * @property {string} currentIntent The technical name of the current intent you're visiting
 * @property {Record<string, TopicAssistantData>} perIntent Key here is the intent technical name, e.g. 'faq_portfolio'
 * @property {boolean} _processingResumed If we reloaded the page, this will be false.
 * This means we need to check the persistent state for intents that need to have their processing/checkers resumed.
 */
const state = {
  /**
   * @type {TopicAssistantState["currentIntent"]}
   */
  currentIntent: '',
  currentChannelId: null,
  /**
   * @type {TopicAssistantState["perIntent"]}
   */
  perIntent: {},
  /**
   * @type {TopicAssistantState["_processingResumed"]}
   */
  _processingResumed: false,
  // Uncomment for easier debugging
  // persistentState,
};

const getters = {
  /**
   * Check if we're currently loading anything in the current intent
   * @param {TopicAssistantState} state
   */
  isLoading: (state) => {
    if (state.perIntent[state.currentIntent].step === steps.INIT) return true;
    if (state.perIntent[state.currentIntent].step === steps.GENERATING) return true;
    if (state.perIntent[state.currentIntent].step === steps.SETUP) return false;
    if (state.perIntent[state.currentIntent].step === steps.ERROR) return false;
    // Usually if this is true, we're on ERROR step, but check just in case
    if (state.perIntent[state.currentIntent].taskError) return false;

    // For RESULTS, it depends.
    // We have to check whether a column had an error, has concept & content, or if we're still waiting for it
    let columnsAccountedFor = 0;
    for (let i=0; i<assumedConceptCount; i++) {
      const intent = state.perIntent[state.currentIntent];

      if (intent.columnErrors[i]) {
        columnsAccountedFor++;
        continue;
      }

      if (intent.concepts[i] && intent.contents[i]) {
        columnsAccountedFor++;
      }
    }

    // If not all columns are accounted for, we're still loading
    return columnsAccountedFor !== assumedConceptCount;
  },
  /**
   * Returns the task error object if there is any error
   * @param {TopicAssistantState} state
   * @returns {null|TopicAssistantError}
   */
  taskError: (state) => state.perIntent[state.currentIntent]?.taskError,
  /**
   * Returns the column errors if there are any errors per column
   * @param {TopicAssistantState} state
   * @returns {Array<TopicAssistantError | null>}
   */
  columnErrors: (state) => state.perIntent[state.currentIntent]?.columnErrors,
  /**
   * Returns the step of the current intent you're in
   * @param {TopicAssistantState} state
   * @returns {TopicAssistantData["step"]}
   */
  currentStep: (state) => state.perIntent[state.currentIntent].step,
  /**
   * Returns the content of the current intent you're in.
   * Array may be empty if it is not yet ready or nto yet loaded.
   * @param {TopicAssistantState} state
   * @returns {TopicAssistantData["contents"]}
   */
  contents: (state) => state.perIntent[state.currentIntent].contents,
  /**
   * Returns the concepts of the current intent you're in.
   * The array may not be entirely filled yet.
   * @param state
   * @returns {TopicAssistantData["concepts"]}
   */
  concepts: (state) => state.perIntent[state.currentIntent].concepts,
  /**
   * Returns the concept task of the current intent you're in.
   * @param {TopicAssistantState} state
   * @returns {TopicAssistantData["conceptTask"]}
   */
  conceptTask: (state) => state.perIntent[state.currentIntent].conceptTask,
  /**
   * Returns any cached setup properties that were previously set in this session
   * @param {TopicAssistantState} state
   * @returns {{url: string, content: string}} Strings will be empty or filled
   */
  cachedSetupProperties: (state) => ({
    url: state.perIntent[state.currentIntent].url,
    content: state.perIntent[state.currentIntent].content,
  }),
  /**
   * Lets you know if at least one concept is ready.
   * Content for said concept may not be loaded yet.
   * @param {TopicAssistantState} state
   * @returns {boolean}
   */
  aConceptIsReady: (state) => {
    for (let i=0; i<assumedConceptCount; i++) {
      if (state.perIntent[state.currentIntent].concepts[i]?.conversationId?.state === 'completed') return true;
    }

    return false;
  },
  /**
   * Returns a function to check whether an intent is already being processed
   * @param {TopicAssistantState} state
   * @returns {(function(intent: string): boolean)}
   * @private
   */
  _isIntentProcessing: (state) => {
    return (intent) => !!state.perIntent[intent];
  },
};

const actions = {
  /**
   * Gets the display name of an intent
   * @param rootGetters
   * @param {string} intent
   * @returns {string}
   */
  getIntentName({ rootGetters }, intent) {
    const found = rootGetters['intents/intent'](intent);
    return found.displayName || intent;
  },

  /**
   * Checks if 1 or more concepts are ready for a given intent.
   * Identical to `aConceptIsReady`, but you can specify the intent, which may be necessary for background loading.
   * @param {TopicAssistantState} state
   * @param {string} intent
   * @returns {boolean}
   */
  hasConceptsReady({ state }, { intent }) {
    for (const concept of state.perIntent[intent].concepts) {
      if (concept.conversationId?.state === 'completed') {
        return true;
      }
    }

    return false;
  },

  /**
   * Starts the generation processing.
   * Will start a process in the background to check for updates every 5 seconds.
   * @param {TopicAssistantState} state
   * @param commit
   * @param dispatch
   * @param {string} intent The intent to start generating
   * @param {string} botId The bot to use for generating
   * @param {string} url The URL to process
   * @param {?string} [content] Any optional extra information relevant for the generation.
   * @returns {Promise<void>}
   */
  async generateNewConcepts(
    { state, commit, dispatch },
    { botId, intent, url, content, channelId }
  ) {
    commit('setStep', { step: steps.GENERATING, intent });
    // Clear any errors
    commit('clearErrors', { intent });

    // Cache in memory for this session
    state.perIntent[intent].url = url;
    state.perIntent[intent].content = content;
    persistentState.subscribe(intent);

    const result = await AssistantService.generateConcepts({
      uniqueBotId: botId,
      intent: intent,
      informations: content,
      websites: [url],
      channel: channelId,
    });

    if (!result.concept) {
      dispatch('uiAlert', { intent, message: 'answerAssistant.error.generateConceptError', type: 'error' });
      commit('setStep', { step: steps.ERROR, intent });
      return commit('setError', {
        intent,
        taskError: true,
        title: 'answerAssistant.error.generateConceptError',
        message: 'answerAssistant.error.generateConceptErrorDesc',
      });
    }

    // Wipe local cache if we managed to start concept generation
    commit('setConcepts', { concepts: [], intent });
    commit('setConceptTask', { task: null, intent });
    for (let i=0;i<state.perIntent[intent].contents.length;i++) {
      commit('setContent', { index: i, content: null, intent });
    }

    commit('setConceptTask', { task: result.concept, intent });
    // Start a background "cron job" that calls the status of the concept task every 5 seconds
    dispatch('checkConceptTask', {
      botId,
      taskId: result.concept._id,
      intent,
    });
  },

  /**
   * In case you reloaded the page, you may want to resume processing of some intents,
   * or at least know how they ended up if you comer back at a later point.
   *
   * This method can be called, and it will check if there's anything to process,
   * and if so, will do that.
   *
   * If called in the same scope as init():
   * - ensureExit should be called first, this ensures the record exists in memory
   * - init should be the one to start the processing
   * - resumeProcessing called last, and see that it already exists in memory, so it skips this intent
   * @param commit
   * @param dispatch
   * @param getters
   * @param {string} botId The ID of the bot to use
   */
  resumeProcessing({ commit, dispatch, getters }, { botId }) {
    console.debug("[resumeProcessing] Resuming processing of intents...");
    if (!persistentState.hasIntentsToProcess || state._processingResumed) {
      console.debug("[resumeProcessing] Nothing to resume.");
      return;
    }

    for (const intentId of persistentState.data.subscribed) {
      if (getters['_isIntentProcessing'](intentId)) {
        console.debug(`[resumeProcessing] Skipping ${intentId} as it is already being processed.`);
        continue;
      }
      commit('ensureExists', { intent: intentId });
      console.debug(`[resumeProcessing] Resuming processing of ${intentId}...`);
      dispatch('_startCheckingIntent', { intent: intentId, botId });
    }

    console.debug("[resumeProcessing] Done resuming processing. Marking flag as true");
    commit('_setProcessingResumed', { isResumed: true });
  },

  /**
   * Initializes the page by checking if there are any concepts already generated.
   * This is triggered any time you un-minimize it as well.
   * @param {TopicAssistantState} state
   * @param commit
   * @param dispatch
   * @param {string} intent The technical name of the current intent, e.g. 'faq_portfolio'
   * @param {string} botId The ID of the bot to use
   * @param {string} [channelId] The ID of the channel to use
   */
  async init({ state, commit, dispatch }, { intent, botId, channelId }) {
    if (channelId !== undefined) commit('setCurrentAssistantChannelId', channelId);
    console.debug("[init] Initializing %s in ch %s...", intent, channelId);
    // If you minimize, then go open it again...
    //... check for error
    if (state.perIntent[intent].error?.message) {
      return commit('setStep', { step: steps.ERROR, intent});
    }
    // ... check for concepts ready
    if (await dispatch('hasConceptsReady', { intent })) {
      return commit('setStep', { step: steps.RESULTS, intent});
    }
    // ... we already started processing, we do not need to run init
    if (state.perIntent[intent].step !== steps.INIT) {
      return;
    }

    commit('setConcepts', { concepts: [], intent });

    return dispatch('_startCheckingIntent', { intent, botId });
  },

  /**
   * Internal: A part of the init() method, but split up so that the
   * `resumeProcessing` method can also re-use the parts that matter for it
   * @param {TopicAssistantState} state
   * @param getters
   * @param commit
   * @param dispatch
   * @param {string} intent The technical name of the current intent, e.g. 'faq_portfolio'
   * @param {string} botId The ID of the bot to use
   * @private
   */
  async _startCheckingIntent({ commit, dispatch, getters, state }, { intent, botId }) {
    console.debug("[_startCheckingIntent] Starting processing of %s", intent);

    const result = await AssistantService.getFullConceptTask({
      intent,
      uniqueBotId: botId,
      channelId: state.currentChannelId,
    });

    // Nothing generated for this intent before
    if (result === null || result.channelId !== state.currentChannelId) {
      return commit('setStep', { step: steps.SETUP, intent });
    }

    // No concepts property (should've been at least an empty array)
    if (!result?.concepts) {
      dispatch('uiAlert', { intent, message: 'answerAssistant.error.loadConceptsError', type: 'error' });
      commit('setStep', { step: steps.ERROR, intent });
      return commit('setError', {
        intent,
        taskError: true,
        title: 'answerAssistant.error.loadConceptsError',
        message: 'answerAssistant.error.loadConceptsErrorDesc',
      });
    }

    // If no concepts, but not open/running, skip to error
    if (!result?.concepts.length && !["open","running"].includes(result.status)) {
      dispatch('uiAlert', { intent, message: 'answerAssistant.error.generateConceptError', type: 'error' });
      commit('setStep', { step: steps.ERROR, intent });
      return commit('setError', {
        intent,
        taskError: true,
        title: 'answerAssistant.error.generateConceptError',
        message: 'answerAssistant.error.generateConceptErrorDesc',
      });
    }

    // If we've come this far, something is generating
    commit('setStep', { step: steps.GENERATING, intent });

    // Store whatever state the concepts are in now
    commit('setConcepts', { concepts: result.concepts, intent });

    // Start loading the content for each concept, however far they've come
    for (let i = 0; i < state.perIntent[intent].concepts.length; i++) {
      // If was generated before we might be able to skip directly to loading the actual generated content
      const contentReady = state.perIntent[intent]?.concepts?.[i]?.conversationId?.state === 'completed';
      if (contentReady) {
        await dispatch('fetchConceptContent', {
          botId,
          intent,
          index: i,
          conversationId: state.perIntent[intent].concepts[i].conversationId._id,
        });

        continue;
      }

      // Start polling, will self-retry every 5 seconds
      await dispatch('startConceptSummaryLoader', {
        botId,
        intent,
        index: i,
        conceptId: state.perIntent[intent].concepts[i]._id,
      });
    }
  },

  /**
   * Loads in the actual boxes/nodes for a given concept by index.
   * Self-triggers a retry every 5 seconds until the content is ready.
   * @param {TopicAssistantState} state
   * @param dispatch
   * @param commit
   * @param getters
   * @param {string} conversationId The ID of the conversation which will contain our nodes/boxes
   * @param {number} index Concept index
   * @param {string} botId The ID of the bot to use
   * @param {string} intent The intent to load the concept for
   */
  async fetchConceptContent(
    { dispatch, commit, state, getters },
    { index, botId, conversationId, intent }
  ) {
    console.debug("[fetchConceptContent] Starting processing of %s", intent);
    const content = await AssistantService.getConversation({
      uniqueBotId: botId,
      conversationId: conversationId,
    });

    if (!content.conversation?.content) {
      dispatch('uiAlert', { intent, message: `answerAssistant.error.loadContentError`, type: 'error' });

      console.debug("[fetchConceptContent] No content found, going to error step.");
      return commit('setError', {
        intent,
        columnIndex: index,
        title: 'answerAssistant.error.loadContentError',
        message: content.error?.message
          ? content.error.message
          : 'answerAssistant.error.loadContentErrorDesc',
      });
    }

    console.debug("[fetchConceptContent] Setting content:", content.conversation.content);
    // Set the content for a given index that is now ready and loaded
    commit('setContent', {
      index,
      content: content.conversation.content,
      intent,
    });

    if (state.perIntent[intent].step !== steps.ERROR) {
      console.debug("[fetchConceptContent] Changing step to RESULTS");
      commit('setStep', { step: steps.RESULTS, intent });
    }

    /**
     * Because i18n cannot be imported and used independently of a Vue component,
     * and passing a bound function through all the necessary methods is a lot of work,
     * we just hard-code the messages for both A, B, and C.
     */
    const successVariant = (index) => `answerAssistant.success.conceptReady${letters[index]}`;
    dispatch('uiAlert', {
      intent,
      message: successVariant(index),
      type: 'success',
    });
  },

  /**
   * Load a concept summary. Will self-try again if no box data is ready yet.
   * @param {TopicAssistantState} state
   * @param dispatch
   * @param commit
   * @param {string} botId The ID of the bot to use
   * @param {string} intent The intent to load the concept for
   * @param {string} conceptId The MongoDB ID of the concept
   * @param {number} index The index at which this concept is stored in the `concepts` array
   * @alias getConcept
   */
  async startConceptSummaryLoader(
    { state, dispatch, commit },
    { botId, intent, conceptId, index }
  ) {
    console.debug("[startConceptSummaryLoader] Starting processing of %s", intent);
    const result = await AssistantService.getConcept({
      conceptId,
      uniqueBotId: botId,
    });

    // No record found
    if (!result?.concept) {
      dispatch('uiAlert', { intent, message: 'answerAssistant.error.noConceptFound', type: 'error' });
      return commit('setError', {
        intent,
        columnIndex: index,
        title: 'answerAssistant.error.noConceptFound',
        message: 'answerAssistant.error.noConceptFoundDesc',
      });
    }

    // Record should exist by now
    const itsBeenTooLong = await dispatch('checkConceptCreationError', { intent, index });
    if (itsBeenTooLong) {
      dispatch('uiAlert', { intent, message: 'answerAssistant.error.generateConceptError', type: 'error' });

      return commit('setError', {
        intent,
        columnIndex: index,
        title: 'answerAssistant.error.generateConceptError',
        message: 'answerAssistant.error.generateConceptTooLongError',
      });
    }

    // Cache whatever state it is in...
    commit('setConcept', { index, intent, concept: result.concept});

    // ... and if it does not have content ready yet, check again in 5 seconds
    if (
      !result.concept?.conversationId
      || result.concept.conversationId?.state !== 'completed'
    ) {
      console.debug("[startConceptSummaryLoader] Retrying again later...");
      return setTimeout(() => {
        console.debug("[startConceptSummaryLoader] Retrying.");
        return dispatch('startConceptSummaryLoader', {
          conceptId,
          index,
          intent,
          botId,
        });
      }, 5000);
    }

    // ... else at least 1 concept is ready, so we can move on
    commit('setStep', { step: steps.RESULTS, intent });

    console.debug("[startConceptSummaryLoader] Passing on to fetchConceptContent");
    dispatch('fetchConceptContent', {
      index,
      botId,
      conversationId: result.concept.conversationId._id,
      intent,
    });
  },

  /**
   * Checks to see if our request for concepts have yielded any results yet
   * @alias checkConceptTask
   * @param {TopicAssistantState} state
   * @param commit
   * @param dispatch
   * @param {string} intent the specific intent to target if not current
   * @param {string} botId The bot to use for generating
   * @param {string} taskId The ID of the task to check
   */
  async checkConceptTask(
    { getters, dispatch, commit },
    { taskId, botId, intent }
  ) {
    const result = await AssistantService.getConceptTask({
      taskId: taskId,
      uniqueBotId: botId,
    });
    if (result?.task?.status === 'completed') {
      // Get all concepts from AssistantService startConceptSummaryLoader and push them to the concept array
      for (let i = 0; i < result.task.concepts.length; i++) {
        await dispatch('startConceptSummaryLoader', {
          conceptId: result.task.concepts[i],
          index: i,
          intent,
          botId,
        });
      }

      return;
    }

    if (result?.task?.status === 'error') {
      commit('setConcepts', { concepts: [], intent });
      commit('setConceptTask', { task: null, intent });
      commit('setStep', { step: steps.ERROR, intent });

      dispatch('uiAlert', { intent, message: 'answerAssistant.error.generateConceptError', type: 'error' });

      return commit('setError', {
        intent,
        taskError: true,
        title: 'answerAssistant.error.generateConceptError',
        message: 'answerAssistant.error.generateConceptErrorDesc',
      });
    }

    // Overwrite state tracking if it has progressed
    if (
      state.perIntent[intent]?.conceptTask?.status === 'open' &&
      result?.task?.status === 'running'
    ) {
      commit('setConceptTask', { task: result.task, intent });
    }

    // Check again in 5 seconds
    setTimeout(() => {
      return dispatch('checkConceptTask', { botId, taskId, intent });
    }, 5000);
  },

  /**
   * Wipes the current records and bring you back to the setup stage,
   * so you can fill inn details again and try to generate anew.
   * @param {TopicAssistantState} state
   * @param commit
   * @param {?string} [intent] Optional: specific intent to target if not current
   * @returns {Promise<number>}
   */
  async restartFromSetup({ commit, state }, { intent }) {
    // Go back to the setup step
    commit('setStep', { step: steps.SETUP, intent });
  },

  /**
   * Check the concept creation error for a given index on demand
   * @param {TopicAssistantState} state
   * @param {string} intent
   * @param {number} index
   * @returns {boolean}
   */
  checkConceptCreationError({ state }, { intent, index }) {
    if (!state.perIntent[intent].concepts || state.perIntent[intent].concepts.length < index) return false;

    // if the createdAt of a concept is more than 3 minutes ago, it is an error
    if (
      state.perIntent[intent]?.concepts?.[index]?.conversationId
      && state.perIntent[intent].concepts[index].conversationId.state !== 'completed'
      && new Date(state.perIntent[intent].concepts[index].conversationId.createdAt).getTime() < Date.now() - 3 * 60 * 1000
    ) {
      return true;
    }

    // If we got a concept record without a conversation/conversationId property after 3 minutes, it is also an error
    if (
      state.perIntent[intent].concepts[index]?.createdAt
      && !state.perIntent[intent]?.concepts?.[index]?.conversationId
      && new Date(state.perIntent[intent].concepts[index].createdAt).getTime() < Date.now() - 3 * 60 * 1000
    ) {
      return true;
    }

    return false;
  },

  /**
   * Handles adding alerts to the UI for when you are not in the /intent/{intent}/edit view
   * @param {TopicAssistantState} state
   * @param commit
   * @param dispatch
   * @param {string} message The message to display in the alert
   * @param {string} type The type of alert, e.g., 'error', 'warning', 'info'
   * @param {?string} [intent] Optional: specific intent to target if not current
   */
  async uiAlert({ commit, state, dispatch }, { intent, message, type }) {
    if (!intent) intent = state.currentIntent;

    if (!persistentState.shouldAlert(intent)) {
      return persistentState.unsubscribe(intent);
    }

    if (type==="error") persistentState.unsubscribe(intent);
    else persistentState.markAlerted(intent);

    commit('hubUi/addAlert',
      {
        message: `${message}`,
        type: type,
        to: {
          name: 'intent-view',
          params: {
            view: 'assistant',
            name: intent || state.currentIntent,
          },
        },
        // Arbitrary names you can find in <SnackbarStack> of Intents.vue and ContentEditorContainer.vue
        scopes: ["intents", "intent"],
      },
      // Else it will think hubUi is part of topicAssistant:
      { root: true }
    );
  }
};

const mutations = {
  /**
   * Indicates which intent we're currently using.
   * SHOULD BE USED ANY TIME YOU OPEN AN INTENT TO EDIT IT.
   * @param {TopicAssistantState} state
   * @param {string} intent
   */
  setCurrentAssistantIntent(state, intent) {
    state.currentIntent = intent;
  },
  setCurrentAssistantChannelId(state, channelId) {
    if (!channelId) {
      state.currentChannelId = null;
    }

    // Check if concepts are available and if the channel ID has changed
    if (state.perIntent[state.currentIntent] && 
        state.perIntent[state.currentIntent].concepts && 
        state.perIntent[state.currentIntent].concepts.length > 0) {
        const currentConceptTask = state.perIntent[state.currentIntent].concepts[0];
        
        if ( currentConceptTask.channelId !== channelId) {
            // Channel ID has changed, remove the concepts and contents and set step to INIT
            state.perIntent[state.currentIntent].concepts = [];
            state.perIntent[state.currentIntent].contents = [];
            state.perIntent[state.currentIntent].step= steps.INIT;
        }
    } else if (state.perIntent[state.currentIntent].step === steps.SETUP) {
      state.perIntent[state.currentIntent].step = steps.INIT;
    }
        
    state.currentChannelId = channelId;
  },
  /**
   * Ensures that a container for the given intent exists
   * @param state
   * @param commit
   * @param {string} intent The intent ot ensure exists
   * @returns {void}
   */
  ensureExists(state, { intent }) {
    if (state.perIntent[intent]) return;
    set(state.perIntent, intent, makeState());
  },
  /**
   * Overwrites the concepts
   * @param {TopicAssistantState} state
   * @param {Array<Object>} concepts
   * @param {?string} [intent] Optional: specific intent to target if not current
   */
  setConcepts(state, { concepts, intent }) {
    set(state.perIntent[intent || state.currentIntent], 'concepts', concepts);
  },
  /**
   * Sets a concept at a given indes
   * @param {TopicAssistantState} state
   * @param {Object} concept
   * @param {number} index
   * @param {?string} [intent] Optional: specific intent to target if not current
   */
  setConcept(state, { concept, index, intent }) {
    set(state.perIntent[intent || state.currentIntent].concepts, index, concept);
  },
  /**
   * Sets the content for a given item index
   * @param {TopicAssistantState} state
   * @param {number} index
   * @param {Object} content
   * @param {?string} [intent] Optional: specific intent to target if not current
   */
  setContent(state, { index, content, intent }) {
    set(state.perIntent[intent || state.currentIntent].contents, index, content);
  },
  /**
   * Sets which step you are at
   * @param {TopicAssistantState} state
   * @param {number} step
   * @param {?string} [intent] Optional: specific intent to target if not current
   */
  setStep(state, { step, intent }) {
    set(state.perIntent[intent || state.currentIntent], 'step', step);
  },
  /**
   * Sets the task that is created for generating content
   * @param {TopicAssistantState} state
   * @param {Object} task The task object
   * @param {?string} [intent] Optional: specific intent to target if not current
   */
  setConceptTask(state, { task, intent }) {
    set(state.perIntent[intent || state.currentIntent], 'conceptTask', task);
  },
  /**
   * Sets the active error
   * @param {TopicAssistantState} state
   * @param {?string} [title] Display title for the error. Default is up to the component.
   * @param {string} message
   * @param {?boolean} [taskError] If it was a task error, resulting in all content failing. Must be set if not columnIndex.
   * @param {?number} [columnIndex] The index of the column this error occurred on. Must be set if not taskError.
   * @param {?string} [intent] Optional: specific intent to target if not current
   */
  setError(state, { title, message, taskError, columnIndex, intent }) {
    if (!taskError && columnIndex === undefined) {
      throw new Error("You must provide either a columnIndex or set taskError to true.");
    }

    if (taskError) {
      return set(state.perIntent[intent || state.currentIntent], 'taskError', { title, message });
    }

    set(state.perIntent[intent || state.currentIntent].columnErrors, columnIndex, { title, message });
  },
  /**
   * Clears all errors: taskErrors and every column error
   * @param {TopicAssistantState} state
   * @param {?string} [intent] Optional: specific intent to target if not current
   */
  clearErrors(state, { intent }) {
    set(state.perIntent[intent || state.currentIntent], 'taskError', null);
    set(state.perIntent[intent || state.currentIntent], 'columnErrors', Array(assumedConceptCount).fill(null));
  },
  /**
   * Sets the flag indicating if processing of intents has resumed
   * @param {TopicAssistantState} state
   * @param {boolean} isResumed
   * @private
   */
  _setProcessingResumed(state, { isResumed }) {
    state._processingResumed = !!isResumed;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
