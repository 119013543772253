var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "pa-4",
      class: [_vm.fillHeight && "fill-height"],
      attrs: { outlined: !_vm.noBorder, flat: _vm.noBorder },
    },
    [
      _vm.isLoading
        ? _c("v-progress-linear", {
            staticClass: "hub-box-simple-loading-bar",
            attrs: {
              absolute: "",
              indeterminate: "",
              color: "secondary darken-2",
            },
          })
        : _vm._e(),
      _c(
        "div",
        { class: [_vm.fillHeight && "d-flex flex-column fill-height"] },
        [
          _vm._t("title", function () {
            return [
              _vm.icon || _vm.title || _vm.titleSub
                ? _c(
                    "div",
                    {
                      staticClass: "d-flex shrink pt-1 pb-4",
                      class: [
                        _vm.titleHeight && "hub-box-simple--title-height",
                      ],
                    },
                    [
                      _vm.icon
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-start flex-shrink-0 flex-grow-0",
                              staticStyle: { width: "28px" },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticStyle: { "margin-top": "-2px" },
                                  attrs: {
                                    color: "primary lighten-3",
                                    size: "20",
                                  },
                                },
                                [_vm._v(" " + _vm._s(_vm.icon) + " ")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("div", [
                        _c(
                          "h2",
                          {
                            staticClass:
                              "moin-line-height-1 text-h6 primary--text mr-2",
                          },
                          [_vm._v(" " + _vm._s(_vm.title) + " ")]
                        ),
                        _vm.titleSub
                          ? _c(
                              "h3",
                              {
                                staticClass:
                                  "mt-2 moin-line-height-1 text-body-2 primary--text text--lighten-2",
                              },
                              [_vm._v(" " + _vm._s(_vm.titleSub) + " ")]
                            )
                          : _vm._e(),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]
          }),
          _c(
            "div",
            { class: [_vm.fillHeight && "grow"] },
            [_vm._t("content")],
            2
          ),
          _c("div", [_vm._t("footer")], 2),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }