<template>
  <div
    class="avatar"
    :class="[small && 'avatar--small']"
    :style="{
      borderColor: avatar.color.hex,
    }"
  >
    <v-icon :style="{ color: avatar.color.hex }" :small="small">
      {{ avatar.icon.icon }}
    </v-icon>
  </div>
</template>
<script>
export default {
  name: 'LivechatAvatar',
  props: {
    avatar: {
      type: Object,
      default: () => ({
        color: { hex: '#002B98', name: 'Dark Blue' },
        icon: { name: 'Bone', icon: 'mdi-bone' },
      }),
    },
    small: {
      type: Boolean,
    },
  },
};
</script>
<style scoped lang="scss">
$size: 36px;
$sizeSmall: 24px;

.avatar {
  width: $size;
  height: $size;
  border-radius: 50%;
  border: 1px solid;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar--small {
  width: $sizeSmall;
  height: $sizeSmall;
}
</style>
