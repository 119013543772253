var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.status === _vm.statusTypes.initial
        ? _c("IntentNewInitial")
        : _vm._e(),
      _vm.status === _vm.statusTypes.creatingWithTemplate
        ? _c("IntentNewWithTemplate")
        : _vm._e(),
      _vm.status === _vm.statusTypes.creatingWithoutTemplate
        ? _c("IntentNewWithoutTemplate")
        : _vm._e(),
      _vm.status === _vm.statusTypes.created
        ? _c("IntentNewCreated")
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }