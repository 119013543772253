<template>
  <div
    class="list-item moin-fade-in"
    :class="{'list-item--selected': selected, 'inactive-chat': isChatInactive}"
  >
    <!-- Top line -->
    <div
      class="d-flex px-3 pa-2 moin-cursor-pointer full-width"
      @click="$emit('onSelect', item.uuid)"
    >
      <div class="pr-2 pt-1 moin-chat-avatar">
        <LivechatAvatar :avatar="item.user.avatar" :incoming="item.incoming" />
      </div>
      <div class="flex-grow-1 d-flex flex-col gap-2">
        <div class="full-width">
          <div
            class="d-flex justify-space-between full-width"
            :style="{ opacity: isOffline ? 0.5 : 1 }"
          >
            <div v-if="item.incoming" class="grey--text">{{ $t('livechat.chats.incomingChat') }}</div>
            <div
              v-else
              class="primary--text text--lighten-2"
              :class="[item.isMessageNew && 'font-weight-bold']"
            >
              <span v-if="hasAvatar">
                {{
                  `${item.user.avatar.color.name} ${item.user.avatar.icon.name}`
                }}
                {{ isChatInactive ? $t('livechat.chats.inactive') : '' }}
              </span>
              <span v-else>...</span>
            </div>
            <div class="d-flex justify-end">
              <!-- Other -->
              <div
                v-if="isOther && !isClosed && !isOffline"
                class="primary--text text--lighten-4 text-body-2 text-right"
              >
                {{ $t('livechat.chats.inChatWith') }} {{ item.agent && item.agent.name }}
              </div>
              <!-- Closed -->
              <template v-if="isClosed">
                <div class="d-flex justify-end items-center grey--text">
                  <v-icon class="mr-1" size="16" color="grey">
                    mdi-check-circle-outline
                  </v-icon>
                  <div class="text-body-2 text-right">
                    {{ lastUpdated }}
                  </div>
                </div>
              </template>
              <!-- Own -->
              <template v-else-if="isOwn || isUnassigned">
                <div
                  class="text-body-2 text-right"
                  :class="[
                    item.isMessageNew &&
                      'font-weight-bold primary--text text--lighten-2',
                  ]"
                >
                  {{ lastUpdated }}
                </div>
                <v-icon v-if="isOffline" small color="black" class="ml-1">
                  mdi-broadcast-off
                </v-icon>
                <PulseBoeble
                  v-if="!isUnassigned && item.isMessageNew"
                  class="ml-2 mt-1"
                />
              </template>
            </div>
          </div>
          <div class="d-flex items-center base gap-2 primary--text text--lighten-2">
            <span class="text-body-2 align-center d-flex gap-1">
              <v-icon x-small color="primary lighten-2">{{ infoCardIcon(infoTagTypes.channel) }}</v-icon>
              {{ item.lastForm?.bot?.channelDisplay }}
            </span>
            <span class="text-body-2 align-center d-flex gap-1" v-if="channelLanguage">
              <v-icon x-small color="primary lighten-2">{{ infoCardIcon(infoTagTypes.language) }}</v-icon>
              <span>{{ channelLanguage }}</span>
            </span>
            <v-chip
              v-if="item.isStaging"
              color="secondary"
              outlined
              x-small
              class="moin-cursor-pointer"
            >
              STAGING
            </v-chip>

            <template v-if="item.notes || !'Condition for other indicators here'">
              <v-divider vertical />
              <v-icon v-if="item.notes" small color="primary lighten-2">
                {{ infoCardIcon(infoTagTypes.note) }}
              </v-icon>
            </template>
          </div>
        </div>

        <!-- Additional form info -->
        <div v-if="item.lastForm" class="flex primary--text text--lighten-2">
          <!-- TODO Replace with dynamic-form icon -->
          <v-icon small color="primary lighten-2">
            {{ infoCardIcon(infoTagTypes.form) }}
          </v-icon>
          {{ item.lastForm?.displayName }}
        </div>
      </div>
    </div>
    <!-- Buttons -->
    <div v-if="!item.agent || isOwn" class="text-right px-3">
      <v-btn
        v-if="agentStatus === statusTypes.online && item.status === chatStatus.unassigned"
        color="secondary"
        depressed
        class="ml-2 mb-3"
        small
        @click="startChat()"
      >
        {{ $t('livechat.chats.startChatButton') }}
        <v-icon right>mdi-forum</v-icon>
      </v-btn>
      <LivechatCloseChat
        v-if="isChatInactive"
        :chat="item"
        class="ml-2 mb-3"
      />
    </div>
    <v-divider />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import LivechatAvatar from '@/components/livechat/LivechatAvatar.vue';
import LivechatInfoTag from '@/components/livechat/LivechatInfoTag.vue';
import LivechatInfoFormTag from '@/components/livechat/LivechatInfoFormTag.vue';
import LivechatCloseChat from '@/components/livechat/LivechatCloseChat.vue';
import { getTimeString, chatStatus, infoTagTypes, infoCardIcon, infoCardTypes } from '@/utils/livechat';
import PulseBoeble from '../common/PulseBoeble.vue';

import { statusTypes } from '@/utils/livechat';

const CHAT_INACTIVE_TIME = 1000 * 60 * 60 * 2; // 2 hours

export default {
  name: 'LivechatListItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      chatStatus,
      currentTime: new Date(),
      timer: null,
      statusTypes
    };
  },
  components: {
    LivechatAvatar,
    LivechatInfoTag,
    LivechatInfoFormTag,
    PulseBoeble,
    LivechatCloseChat,
  },
  mounted() {
    this.timer = setInterval(this.updateCurrentTime, 10_000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  computed: {
    infoTagTypes() {
      return infoTagTypes
    },
    infoCardTypes() {
      return infoCardTypes
    },
    ...mapGetters('auth', ['user']),
    ...mapGetters('livechat', ['agent', 'activeChat', 'agentStatus']),
    ...mapGetters('bots', ['getBot', 'currentChannels']),
    selected() {
      return this.activeChat && this.activeChat.uuid === this.item.uuid;
    },
    hasAvatar() {
      return (
        !!this.item?.user?.avatar?.color?.name &&
        !!this.item?.user?.avatar?.icon?.name
      );
    },
    lastUpdated() {
      const date = this.item.updated_at || this.item.created_at;
      const extendedDate = this.item.status === chatStatus.closed ? true : false;
      return getTimeString(date, this.currentTime, extendedDate);
    },
    isUnassigned() {
      return this.item.status === chatStatus.unassigned;
    },
    isOffline() {
      return this.item.status === chatStatus.offline;
    },
    isClosed() {
      return this.item.status === chatStatus.closed;
    },
    isOwn() {
      return this.item.agent && this.item.agent === this.agent._id;
    },
    isOther() {
      if (!this.item?.agent) return false;
      return this.item.agent !== this.agent?._id;
    },
    isChatInactive() {
      const lastUpdated = new Date(this.item.updated_at);
      const currentTime = new Date();

      const isUserDisconnected = this.item.messages[0]?.type === 'user_disconnected';
      const noCommunication = currentTime.getTime() - lastUpdated.getTime() > CHAT_INACTIVE_TIME;

      return (noCommunication || isUserDisconnected) && !this.isClosed;
    },
    channelLanguage() {
      const chatChannelId = this.item.lastForm?.bot?.channelId;
      // const botDetails = this.getBot(this.item.uniqueBotId);
      // FIX: use currentChannels instead of getBot, because it causes a infinite loop. For preview channels the language is not available. 
      const channel = this.currentChannels?.find((c) => c.channelId === chatChannelId);
      return channel?.languages[0]?.toUpperCase();
    },
  },
  methods: {
    infoCardIcon,
    startChat() {
      this.$store.dispatch('livechat/startChat', this.item);
    },
    updateCurrentTime() {
      this.currentTime = new Date();
    },
  },
};
</script>
<style scoped lang="scss">
$color-selected-bg: #e7e9ed;
$color-indicator-default: #e0e0e0;
$color-indicator-default-selected: #5f6e87;

.list-item {
  border-left: 3px solid $color-indicator-default;
}

.list-item--selected {
  background: $color-selected-bg;
  border-color: $color-indicator-default-selected;
}

.list-item--live {
  border-color: $moin-color-indicator-new;
}

.inactive-chat {
  background-color: #dedede;
}

.inactive-chat .moin-chat-avatar {
    opacity: 0.3;
  }
</style>
