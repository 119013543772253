var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { staticStyle: { "max-height": "100vh", height: "100vh" } },
    [
      _c("HubMenu"),
      _c("Appbar"),
      _c("HubContent", {
        attrs: { isSidebarOpen: _vm.isSidebarOpen },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("HubFrame", {
                  scopedSlots: _vm._u([
                    {
                      key: "content",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex" },
                                [
                                  _c("v-text-field", {
                                    staticClass: "mr-2",
                                    attrs: {
                                      label: "Search",
                                      outlined: "",
                                      dense: "",
                                    },
                                    on: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.search.apply(null, arguments)
                                      },
                                    },
                                    model: {
                                      value: _vm.searchQuery,
                                      callback: function ($$v) {
                                        _vm.searchQuery = $$v
                                      },
                                      expression: "searchQuery",
                                    },
                                  }),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        depressed: "",
                                        color: "success",
                                        disabled: _vm.isLoading,
                                      },
                                      on: { click: _vm.search },
                                    },
                                    [
                                      _c("v-icon", { attrs: { left: "" } }, [
                                        _vm._v(" mdi-magnify "),
                                      ]),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "searchAndReplace.searchButtonLabel"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "mx-n3" },
                                [
                                  _c("v-divider", { staticClass: "my-1" }),
                                  _c(
                                    "v-expansion-panels",
                                    { attrs: { accordion: "", flat: "" } },
                                    [
                                      _c(
                                        "v-expansion-panel",
                                        [
                                          _c("v-expansion-panel-header", [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "text-body-2 primary--text text--lighten-2",
                                                attrs: { color: "primary" },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "searchAndReplace.replacePanelTitle"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _c(
                                            "v-expansion-panel-content",
                                            [
                                              _c("v-text-field", {
                                                staticClass: "mr-2",
                                                attrs: {
                                                  label: _vm.$t(
                                                    "searchAndReplace.replaceTextPlaceholder"
                                                  ),
                                                  outlined: "",
                                                  dense: "",
                                                },
                                                model: {
                                                  value: _vm.replaceText,
                                                  callback: function ($$v) {
                                                    _vm.replaceText = $$v
                                                  },
                                                  expression: "replaceText",
                                                },
                                              }),
                                              _c(
                                                "v-alert",
                                                {
                                                  staticClass: "text-body-2",
                                                  attrs: {
                                                    "close-text": "Close Alert",
                                                    dismissible: "",
                                                    dense: "",
                                                    "close-icon": "mdi-close",
                                                    icon: "mdi-lightbulb",
                                                    color: "primary lighten-3",
                                                    text: "",
                                                  },
                                                  model: {
                                                    value: _vm.replaceAlert,
                                                    callback: function ($$v) {
                                                      _vm.replaceAlert = $$v
                                                    },
                                                    expression: "replaceAlert",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "searchAndReplace.replaceAlertText"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("v-divider", { staticClass: "mt-1 mb-5" }),
                                ],
                                1
                              ),
                              _c("div", [
                                _c("div", { staticClass: "my-2" }, [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex" },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            color: "primary lighten-3",
                                            size: "16",
                                          },
                                        },
                                        [_vm._v("mdi-filter")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-body-2 primary--text text--lighten-2 pl-1",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "searchAndReplace.filterActionTypeLabel"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticStyle: { height: "48px" } },
                                    [
                                      !_vm.isLoading
                                        ? _c("ChipFilter", {
                                            attrs: {
                                              options:
                                                _vm.filterActionTypeItems,
                                            },
                                            on: {
                                              onChange:
                                                _vm.changeFilterActionType,
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                              _vm.isLoading
                                ? _c("v-progress-linear", {
                                    attrs: {
                                      indeterminate: "",
                                      absolute: "",
                                      color: "secondary",
                                    },
                                  })
                                : _vm._e(),
                              _vm.searchResultFor
                                ? _c(
                                    "div",
                                    {
                                      class: {
                                        loadingTransparent: _vm.isLoading,
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _c("v-treeview", {
                                            ref: "searchResultsTree",
                                            attrs: {
                                              items: _vm.treeData,
                                              "open-all": "",
                                              "open-on-click": "",
                                              activatable: "",
                                              active: _vm.activeSearchResults,
                                            },
                                            on: {
                                              "update:active": function (
                                                $event
                                              ) {
                                                _vm.activeSearchResults = $event
                                              },
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "prepend",
                                                  fn: function ({ item }) {
                                                    return [
                                                      item.children
                                                        ? _c("v-icon", [
                                                            _vm._v(
                                                              " mdi-folder-text "
                                                            ),
                                                          ])
                                                        : _c("v-icon", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.icon
                                                                ) +
                                                                " "
                                                            ),
                                                          ]),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              2791173832
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.searchResultFor && _vm.treeData.length === 0
                                ? _c("div", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-h5 primary--text text--lighten-2 py-6",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "searchAndReplace.noSearchResults"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "sidebar",
            fn: function () {
              return [
                _c(
                  "HubContentSidebarSimple",
                  {
                    attrs: { isOpen: _vm.isSidebarOpen, icon: "mdi-pencil" },
                    on: { onClose: _vm.onQuickEditClose },
                  },
                  [
                    _c("h2", { staticClass: "ma-4" }, [
                      _vm._v(_vm._s(_vm.selectedIntentName)),
                    ]),
                    _c(
                      "v-card",
                      { staticClass: "ma-4", attrs: { outlined: "" } },
                      [
                        _c(
                          "v-card-title",
                          [
                            _c(
                              "v-icon",
                              { attrs: { color: "primary lighten-3" } },
                              [_vm._v("mdi-pencil")]
                            ),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "searchAndReplace.searchResultQuickEdit"
                                  )
                                )
                              ),
                            ]),
                          ],
                          1
                        ),
                        !_vm.reload
                          ? _c(
                              "v-card-text",
                              [
                                _c("ContentTemplateLoader", {
                                  attrs: {
                                    content: _vm.selectedContent,
                                    box: _vm.selectedBoxName,
                                    template:
                                      _vm.selectedContent[_vm.selectedBoxName]
                                        ?.template,
                                    options: _vm.actionEditorOptions,
                                    contentTree: _vm.selectedTree,
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "v-card-actions",
                          [
                            _c("v-spacer"),
                            !_vm.hasReplaceText || _vm.actionEditorUpdated
                              ? _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      depressed: "",
                                      color: "success",
                                      disabled: !_vm.actionEditorUpdated,
                                    },
                                    on: { click: _vm.onQuickEditSave },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "searchAndReplace.saveButtonLabel"
                                          )
                                        ) +
                                        " "
                                    ),
                                    _c("v-icon", { attrs: { right: "" } }, [
                                      _vm._v("mdi-content-save"),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.hasReplaceText && !_vm.actionEditorUpdated
                              ? _c(
                                  "v-btn",
                                  {
                                    attrs: { depressed: "", color: "success" },
                                    on: { click: _vm.onQuickEditReplace },
                                  },
                                  [
                                    _c("v-icon", { attrs: { right: "" } }, [
                                      _vm._v("mdi-check"),
                                    ]),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "searchAndReplace.replaceButtonLabel"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "ma-4",
                        attrs: { outlined: "" },
                        on: {
                          click: function ($event) {
                            return _vm.navigateToIntent(
                              _vm.selectedSearchResult.intent
                            )
                          },
                        },
                      },
                      [
                        _c("v-icon", { attrs: { left: "" } }, [
                          _vm._v("mdi-pencil"),
                        ]),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("searchAndReplace.editIntentButtonLabel")
                            ) +
                            " "
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }