import ApiService from './api';

const SettingsService = {
  getChannelConfig: async function(botId, channelId) {
    try {
      const response = await ApiService.get(`/schaltzentrale/widget/editor/${botId}/${channelId}`);
      return response.data;
    } catch (error) {
      return {error};
    }
  },
  saveChannelConfig: async function(botId, channelId, config) {
    try {
      const response = await ApiService.post(`/schaltzentrale/widget/editor/${botId}/${channelId}`, config);
      return response.data;
    } catch (error) {
      return {error};
    }
  },
  saveChannelState: async function(botId, channelId, state) {
    try {
      const response = await ApiService.post(`/schaltzentrale/widget/editor/active/${botId}/${channelId}`, { active: state });
      return response.data;
    } catch (error) {
      return {error};
    }
  },
  saveOAuthToken: async function(botId, provider, token, url, tokenObj) {
    const urlParam = url ? `/${encodeURIComponent(url)}` : '';
    try {
      const response = await ApiService.post(`/bot/oauth/${botId}/${provider}/${token?token:''}${urlParam}`, tokenObj);
      return response.data;
    } catch (error) {
      return {error};
    }
  },
  getSettings: async  function(botId, settingType) {
    try {
      const response = await ApiService.get(`/schaltzentrale/bot/settings/${botId}/${settingType}`);
      return response.data;
    } catch (error) {
      return {error};
    }
  }
  ,
  setSettings: async  function(botId, setting, settingType) {
    try {
      const response = await ApiService.post(`/schaltzentrale/bot/settings/${botId}/${settingType}`, setting);
      return response.data;
    } catch (error) {
      return {error};
    }
  },
  getFBChannels: async  function(botId) {
    try {
      const response = await ApiService.get(`/bot/oauth/${botId}/facebook/getChannels`);
      return response.data;
    } catch (error) {
      return {error};
    }
  },
  // Get general information for provider and their version. Responce example: { livechat: ['default', 'version2'] }
  getSettingsOverview: async function(botId) {
    try {
      const response = await ApiService.get(`/schaltzentrale/bot/${botId}/settings`);
      return response.data;
    } catch(error) {
      return {error};
    }
  },
  /**
   * Retrieves the Tone of Voice settings from all channels of the provided bot
   * @param {string} botId The current bot ID
   * @returns {Promise<null|{channelId: string, enabled: boolean, examples: {scenario: string, answer: string}[]}[]>} Null if error, else the settings
   */
  getToneOfVoiceSettings: async (botId) => {
    try {
      const response = await ApiService.get(`/schaltzentrale/bot/${botId}/channels/tone-of-voice`);
      return response?.data ?? null;
    } catch(error) {
      console.error('[getToneOfVoiceSettings] Error in request. Error: ', error);
      return null;
    }
  },
  /**
   * Stores the Tone of Voice settings for a given channel in the backend
   * @param {string} botId The ID of the bot
   * @param {string | null} [channelId=null] The ID of the channel, or `null` for the default/first channel
   * @param {boolean} enabled Whether the tone of voice feature is enabled or not
   * @param {string[]} answers The answers for each scenario, ordered by index
   * @returns {Promise<Object>} The new state of the entire channel
   */
  setToneOfVoiceSettings: async (botId, channelId, enabled, rules) => {
    try {
      const response = await ApiService.put(`/schaltzentrale/bot/${botId}/channels/${channelId}/tone-of-voice`, {
        enabled,
        rules,
      });
      return response?.data ?? null;
    } catch(error) {
      console.error('[setToneOfVoiceSettings] Error in request. Error: ', error);
      return null;
    }
  },
  /**
   * Retrieves all the settings for a given live chat provider
   * @param {string} botId The current bot ID
   * @param {string} provider The live chat provider
   * @returns {Promise<{error}|any|null>} Will return null if bot does not have this provider
   */
  getProviderSettings: async function({ botId, provider }) {
    try {
      const response = await ApiService.get(`/schaltzentrale/bot/${botId}/settings/${provider}`);
      return response?.data ?? null;
    } catch(error) {
      // { message: 'Bot doesn't have 'salesforce' provider', code: 10002, status: 400 }
      console.error('[getProviderSettings] Error in request. Error: ', error);
      return null;
    }
  },
  /**
   * Overwrites all the settings for a given live chat provider
   * @param {string} botId The current bot ID
   * @param {string} provider The live chat provider
   * @param {object} config The new settings
   * @returns {Promise<{error}>} Will throw error if it fails to post
   */
  setProviderSettings: async function({ botId, provider, config }) {
    try {
      const response = await ApiService.post(`/schaltzentrale/bot/${botId}/settings/${provider}`, config);
      return { success: true, data: response.data };
    } catch(error) {
      const errorData = error?.response?.data?.error  || error?.response?.data || error;
      return { success: false, error: errorData };
    }
  },
  /**
   * Get provider settings
   * @param {string} botId
   * @param {string} provider
   * @param {string} key
   * @returns {Promise<{error}|any|null>} Will return null if bot does not have this provider or version
   */
  getProviderVersionSettings: async function({ botId, provider, key }) {
    try {
      const response = await ApiService.get(`/schaltzentrale/bot/${botId}/settings/${provider}/${key}`);
      return response?.data ?? null;
    } catch(error) {
      return {error};
    }
  },
  addProviderVersionSettings: async function({ botId, provider, key, config }) {
    try {
      const response = await ApiService.post(`schaltzentrale/bot/${botId}/settings/${provider}/${key}`, config);
      return response.data;
    } catch(error) {
      return {error};
    }
  },
  updateProviderVersionSettings: async function({ botId, provider, key, config }) {
    try {
      const response = await ApiService.put(`schaltzentrale/bot/${botId}/settings/${provider}/${key}`, config);
      return { success: true, data: response.data };
    } catch(error) {
      console.log(error)
      const errorData = error?.response?.data?.error  || error?.response?.data || error;
      return { success: false, error: errorData };
    }
  },
  deleteProviderVersionSettings: async function({ botId, provider, key }) {
    try {
      const response = await ApiService.delete(`schaltzentrale/bot/${botId}/settings/${provider}/${key}`);
      return response.data;
    } catch(error) {
      return {error};
    }
  },
  /**
   * Perform a test with given settings
   * @param {string} botId
   * @param {string} settingToTest The identifier for what should be tested
   * @param {any} values The values that to test with
   * @returns {Promise<{error: Error|string} | {success: true}>}
   */
  async testBotSetting(botId, settingToTest, values) {
    try {
      const response = await ApiService.post(`schaltzentrale/bot/${botId}/settings/${settingToTest}/test`, values);
      return response.data;
    } catch(error) {
      return {error};
    }
  },
  /**
   * Patches in the fields you defined into a setting in the bot
   * @param {string} botId
   * @param {string} setting The setting key name (`bot.settings[setting]`)
   * @param {Object|Array} partialNewSettings Partial new value to merge into the existing settings
   * @returns {Promise<{error: Error|string} | any>} If not error, returns the full new settings object
   */
  async patchSetting(botId, setting, partialNewSettings) {
    try {
      const response = await ApiService.patch(`schaltzentrale/bot/${botId}/settings/${setting}`, partialNewSettings);
      return response.data;
    } catch(error) {
      return {error};
    }
  }
};

export default SettingsService;

export { SettingsService };