var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-card", { staticClass: "pa-4", attrs: { outlined: "" } }, [
        _c(
          "div",
          { staticStyle: { "font-size": "40px", "font-weight": "bold" } },
          [_vm._v(" " + _vm._s(_vm.value) + " ")]
        ),
        _c("div", { staticClass: "text-body-2 moin-label" }, [
          _vm._v(" " + _vm._s(_vm.text) + " "),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }