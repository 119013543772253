var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "px-4 pt-8 pb-4 mx-auto moin-fade-in d-flex items-center justify-center",
      staticStyle: {
        height: "100%",
        "max-width": "400px",
        "max-height": "1000px",
      },
    },
    [
      _c(
        "div",
        [
          !_vm.bubbleAni
            ? _c("DemoMoinAvatarBubble", {
                staticClass: "mx-auto mb-4 moin-fade-in",
                attrs: { text: _vm.knowledge[_vm.itemIndex] },
              })
            : _vm._e(),
          _c("DemoMoinAvatar", { staticClass: "mb-8 mx-auto" }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }