var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "HubBox",
    { attrs: { title: _vm.$t("common.general"), boxId: "intents-general" } },
    [
      _c(
        "v-tabs",
        {
          staticClass: "mb-2",
          attrs: { centered: "", grow: "" },
          model: {
            value: _vm.languageIndex,
            callback: function ($$v) {
              _vm.languageIndex = $$v
            },
            expression: "languageIndex",
          },
        },
        _vm._l(_vm.languages, function (language) {
          return _c("v-tab", { key: language.key }, [
            _vm._v(" " + _vm._s(language.label) + " "),
          ])
        }),
        1
      ),
      _c("div", { staticClass: "text-body-2" }, [
        _vm._v(" " + _vm._s(_vm.$t("intent.general.description")) + " "),
      ]),
      _c("div", { staticClass: "mt-4" }, [
        _c(
          "p",
          { staticClass: "primary--text text--lighten-3 mb-1 text-body-2" },
          [_vm._v(" " + _vm._s(_vm.$t("intent.name")) + " ")]
        ),
        _c(
          "p",
          { class: { "font-italic grey--text": !_vm.displayNameOriginal } },
          [
            _vm._v(
              " " +
                _vm._s(
                  _vm.displayNameOriginal ||
                    _vm.$t("intentNew.editIntent.notTranslated")
                ) +
                " "
            ),
          ]
        ),
      ]),
      _c("div", { staticClass: "mt-4" }, [
        _c(
          "p",
          { staticClass: "primary--text text--lighten-3 mb-1 text-body-2" },
          [_vm._v(" " + _vm._s(_vm.$t("intent.description")) + " ")]
        ),
        _c(
          "p",
          { class: { "font-italic grey--text": !_vm.descriptionOriginal } },
          [
            _vm._v(
              " " +
                _vm._s(
                  _vm.descriptionOriginal ||
                    _vm.$t("intentNew.editIntent.notTranslated")
                ) +
                " "
            ),
          ]
        ),
      ]),
      _c(
        "div",
        { staticClass: "mt-6" },
        [
          _c(
            "v-btn",
            {
              attrs: { outlined: "", color: "primary lighten-3" },
              on: {
                click: function ($event) {
                  _vm.editDialogOpen = true
                },
              },
            },
            [
              _vm._v(" " + _vm._s(_vm.$t("common.edit")) + " "),
              _c("v-icon", { attrs: { right: "" } }, [_vm._v("edit")]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "HubDialog",
        {
          attrs: {
            value: _vm.editDialogOpen,
            "primary-label": _vm.$t("common.save"),
            "primary-icon": "save",
            "secondary-label": _vm.$t("common.cancel"),
            "secondary-icon": "close",
            title: _vm.$t("intentNew.editIntent.title"),
            disabled: !_vm.isValid || !_vm.isRootLanguageValid,
            modal: "",
          },
          on: { onPrimary: _vm.saveEdit, onSecondary: _vm.cancelEdit },
        },
        [
          _c(
            "v-form",
            {
              model: {
                value: _vm.isValid,
                callback: function ($$v) {
                  _vm.isValid = $$v
                },
                expression: "isValid",
              },
            },
            [
              _c(
                "v-tabs",
                {
                  attrs: { centered: "", grow: "" },
                  model: {
                    value: _vm.languageIndex,
                    callback: function ($$v) {
                      _vm.languageIndex = $$v
                    },
                    expression: "languageIndex",
                  },
                },
                _vm._l(_vm.languages, function (language) {
                  return _c("v-tab", { key: language.key }, [
                    _vm._v(" " + _vm._s(language.label) + " "),
                  ])
                }),
                1
              ),
              !_vm.isRootLanguageValid
                ? _c(
                    "v-alert",
                    {
                      staticClass: "mt-2",
                      attrs: {
                        type: "error",
                        outlined: "",
                        dense: "",
                        border: "left",
                        color: "error",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("intentNew.editIntent.allFieldsMandatory", {
                              language: _vm.$t(
                                _vm.languageKeyOfRoot
                                  ? `common.languageNames.${_vm.languageKeyOfRoot}`
                                  : "common.original"
                              ),
                            })
                          ) +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "v-row",
                { staticClass: "mt-4" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-space-between gap-2 items-baseline",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "subtitle-1",
                              attrs: { for: "displayName" },
                            },
                            [_vm._v(" " + _vm._s(_vm.displayNameLabel) + " ")]
                          ),
                          !_vm.currentIsRoot
                            ? _c("v-checkbox", {
                                staticClass: "pa-0 ma-0",
                                attrs: {
                                  label: _vm.$t(
                                    "intentNew.editIntent.autoTranslate"
                                  ),
                                  "hide-details": "",
                                },
                                model: {
                                  value: _vm.translateCurrentName,
                                  callback: function ($$v) {
                                    _vm.translateCurrentName = $$v
                                  },
                                  expression: "translateCurrentName",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      !_vm.translateCurrentName && !_vm.currentIsRoot
                        ? _c("InfoBox", {
                            attrs: {
                              text: _vm.$t(
                                "intentNew.stepName.displayName.hint"
                              ),
                              icon: null,
                            },
                          })
                        : _c("InfoBox", {
                            attrs: {
                              text: _vm.$t(
                                "intentNew.editIntent.willAutoTranslateName"
                              ),
                              icon: null,
                            },
                          }),
                      _c("v-text-field", {
                        attrs: {
                          name: "displayName",
                          outlined: "",
                          color: "secondary darken-2",
                          autofocus: "",
                          rules: _vm.translateCurrentName
                            ? []
                            : _vm.rules.displayName,
                          disabled:
                            _vm.translateCurrentName && !_vm.currentIsRoot,
                        },
                        model: {
                          value: _vm.displayName,
                          callback: function ($$v) {
                            _vm.displayName = $$v
                          },
                          expression: "displayName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mt-4" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-space-between gap-2 items-baseline",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "subtitle-1",
                              attrs: { for: "description" },
                            },
                            [_vm._v(" " + _vm._s(_vm.descriptionLabel) + " ")]
                          ),
                          !_vm.currentIsRoot
                            ? _c("v-checkbox", {
                                staticClass: "pa-0 ma-0",
                                attrs: {
                                  label: _vm.$t(
                                    "intentNew.editIntent.autoTranslate"
                                  ),
                                  "hide-details": "",
                                },
                                model: {
                                  value: _vm.translateCurrentDescription,
                                  callback: function ($$v) {
                                    _vm.translateCurrentDescription = $$v
                                  },
                                  expression: "translateCurrentDescription",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      !_vm.translateCurrentDescription && !_vm.currentIsRoot
                        ? _c("InfoBox", {
                            attrs: {
                              text: _vm.$t(
                                "intentNew.stepName.description.hint"
                              ),
                              icon: null,
                            },
                          })
                        : _c("InfoBox", {
                            attrs: {
                              text: _vm.$t(
                                "intentNew.editIntent.willAutoTranslateDesc"
                              ),
                              icon: null,
                            },
                          }),
                      _c("v-textarea", {
                        attrs: {
                          name: "description",
                          outlined: "",
                          color: "secondary darken-2",
                          readonly: !_vm.isAdmin,
                          disabled:
                            !_vm.isAdmin ||
                            (_vm.translateCurrentDescription &&
                              !_vm.currentIsRoot),
                          rules: _vm.translateCurrentDescription
                            ? []
                            : _vm.rules.description,
                        },
                        model: {
                          value: _vm.description,
                          callback: function ($$v) {
                            _vm.description = $$v
                          },
                          expression: "description",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }