const state = {
    timeoutTimer: null,
    timestamp: null,
    timeoutDuration: 0, // in milliseconds
}

const getters = {
    timeoutTimer: state => state.timeoutTimer,
}

const actions = {
    restartTimeoutTimer({ state, dispatch }) {
        if (state.timeoutTimer) {
          clearTimeout(state.timeoutTimer);
        }

        if (state.timeoutDuration > 0) {
            // the timestamp is the current date and time plus the timeout duration
            state.timestamp = new Date().getTime() + state.timeoutDuration;
            state.timeoutTimer = setInterval(() => {
              // if the timestamp is in the past, the user has been inactive for too long
              if (state.timestamp < new Date().getTime()) {
                state.timestamp = null;
                clearTimeout(state.timeoutTimer);
                dispatch('livechat/setAgentInactive', null, { root: true });
              }
              
            }, 1000);
        }
      },
    
      removeTimeoutTimer({ state }) {
        if (state.timeoutTimer) {
          clearTimeout(state.timeoutTimer);
          state.timestamp = null;
        }
      },
}

const mutations = {
    setTimeoutTimer(state, timer) {
        state.timeoutTimer = timer;
    },
    setTimeoutDuration(state, { timeoutDuration }) {
        state.timeoutDuration = timeoutDuration;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}