var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-col",
    { staticClass: "text-right", attrs: { cols: "12" } },
    [
      _c(
        "v-btn",
        {
          staticClass: "mr-4",
          attrs: {
            disabled: !_vm.isAssistantDirty,
            text: "",
            color: "primary lighten-2",
          },
          on: {
            click: function ($event) {
              return _vm.resetAssistant()
            },
          },
        },
        [
          _vm._t("discardbutton", function () {
            return [
              _vm._v(" " + _vm._s(_vm.$t("editor.common.discard")) + " "),
              _c("v-icon", { attrs: { right: "" } }, [
                _vm._v("delete_outline"),
              ]),
            ]
          }),
        ],
        2
      ),
      _c(
        "v-btn",
        {
          class: _vm.isAssistantDirty ? "white--text" : "",
          attrs: {
            disabled: !_vm.isAssistantDirty || _vm.loading,
            depressed: "",
            color: "assistant",
          },
          on: {
            click: function ($event) {
              return _vm.acceptAssistant()
            },
          },
        },
        [
          _vm._t("savebutton", function () {
            return [
              _vm._v(" " + _vm._s(_vm.$t("assistant.btnAccept")) + " "),
              _c("v-icon", { attrs: { right: "" } }, [_vm._v("mdi-creation")]),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }