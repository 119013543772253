var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ma-2 moin-admin-box" }, [
    _c(
      "div",
      [
        _c(
          "div",
          {
            staticClass: "pa-2 grey--text text--lighten-1 px-4",
            staticStyle: {
              "font-size": "13px",
              transition: "padding-left 0.3s",
            },
          },
          [_vm._v(" Admin ")]
        ),
        _vm._l(_vm.items, function (item) {
          return _c(
            "v-list-item",
            {
              key: `btn-${item.value}`,
              attrs: {
                "data-cy": `menu-${item.value}-btn`,
                to: item.to,
                color: "admin",
                "active-class": "hub-menu-item--active",
              },
            },
            [
              _c("v-icon", { attrs: { left: "", color: "admin" } }, [
                _vm._v(_vm._s(item.icon)),
              ]),
              _c("span", [_vm._v(" " + _vm._s(item.title) + " ")]),
            ],
            1
          )
        }),
        _c("div", { staticClass: "pa-4" }, [
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c("v-switch", {
                staticClass: "ma-0",
                attrs: {
                  dense: "",
                  inset: "",
                  label: "Staging",
                  "input-value": _vm.viewStaging,
                  "hide-details": "",
                },
                on: { change: _vm.changeViewableBot },
              }),
            ],
            1
          ),
        ]),
        _c(
          "v-list-item",
          { staticClass: "d-none d-sm-flex mt-6" },
          [_c("BotPicker")],
          1
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }