var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "HubBox",
        {
          attrs: {
            title: _vm.$t("botSettings.deleteUser.title"),
            icon: "mdi-trash-can",
          },
        },
        [
          _c("div", { staticClass: "mb-4" }, [
            _c("div", { staticClass: "text-body-2" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("botSettings.deleteUser.description")) + " "
              ),
            ]),
          ]),
          _c(
            "v-btn",
            {
              staticClass: "mt-6",
              attrs: {
                disabled: _vm.isDisabled,
                outlined: "",
                color: "primary lighten-2",
              },
              on: {
                click: function ($event) {
                  _vm.dialogIsOpen = true
                },
              },
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("botSettings.deleteUser.title")) + " "
              ),
              _c("v-icon", { attrs: { right: "" } }, [_vm._v("mdi-trash-can")]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "HubDialog",
        {
          attrs: {
            value: _vm.dialogIsOpen,
            title: _vm.$t("botSettings.deleteUser.title"),
            asyncDialog: "",
            primaryIcon: "mdi-trash-can",
            primaryLabel: _vm.$t("botSettings.deleteUser.deleteBtn"),
            isLoading: _vm.isLoading,
            disabled: !_vm.isValid,
            modal: "",
          },
          on: {
            onSecondary: function ($event) {
              _vm.dialogIsOpen = false
            },
            onPrimary: _vm.deleteUser,
          },
        },
        [
          _c("div", { staticClass: "text-body-1 mb-4" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("botSettings.deleteUser.infoText")) + " "
            ),
          ]),
          _c("div", { staticClass: "text-body-2 mt-8" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("botSettings.deleteUser.emailCheck")) + " "
            ),
            _c("strong", { staticClass: "error--text" }, [
              _vm._v(_vm._s(_vm.user.username)),
            ]),
          ]),
          _c(
            "div",
            [
              _c("v-text-field", {
                attrs: {
                  label: _vm.$t("common.eMailAddress"),
                  type: "email",
                  "prepend-inner-icon": "mdi-at",
                },
                model: {
                  value: _vm.mailInput,
                  callback: function ($$v) {
                    _vm.mailInput = $$v
                  },
                  expression: "mailInput",
                },
              }),
            ],
            1
          ),
          _c("InfoBox", {
            attrs: {
              icon: "mdi-alert",
              text: _vm.$t("botSettings.deleteUser.infoBox"),
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }