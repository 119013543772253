<template>
  <div>
    <HubBox
      class="mb-4"
      notExpandable
      :isLoading="isLoading"
      icon="manage_search"
      :title="$t('intentNew.intentInitial.titleFindIntent')"
    >
      <InfoBox :text="$t('intentNew.intentInitial.infoIntentExample')" />

      <div class="my-7">
        <v-text-field
          :value="searchRequest"
          outlined
          persistent-placeholder
          color="secondary darken-2"
          hide-details
          autofocus
          clearable
          :readonly="isLoading"
          :label="$t('intentNew.intentInitial.inputIntentExample.label')"
          :placeholder="
            $t('intentNew.intentInitial.inputIntentExample.placeholder')
          "
          @input="updateSearchInput"
          @click:clear="clearSearchResults"
          prepend-inner-icon="search"
        />
      </div>

      <template v-if="searchResults">
        <div v-if="searchResults.own && searchResults.own.length" class="mt-8">
          <h3 class="text-h6 secondary--text text--darken-3" v-if="!isLoading">
            {{ $t('intentNew.intentInitial.titleFoundInIntents') }}
          </h3>

          <v-skeleton-loader
            v-if="isLoading"
            class="mb-8"
            type="list-item-avatar-two-line, list-item-avatar-two-line"
          />

          <v-list v-else>
            <v-list-item-group>
              <div v-for="item in searchResults.own" :key="`own_${item.label}`">
                <v-list-item class="px-2 pt-2 pb-1" inactive :ripple="false">
                  <v-list-item-icon>
                    <v-icon small color="primary lighten-3">
                      {{ intentIcon(getIntentFromLibraryByName(item.label)) }}
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="py-0">
                    <v-list-item-title>
                      {{ displayName(item.label, $vuetify.lang.current) }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                      v-if="getIntentFromLibraryByName(item.label).description"
                      class="pb-2 mt-1"
                    >
                      {{ description(item.label, $vuetify.lang.current) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider />
              </div>
            </v-list-item-group>
          </v-list>
        </div>

        <div
          v-if="searchResults.all && searchResults.all.length > 0 && !isLoading"
          class="mt-8"
        >
          <h3 class="text-h6 secondary--text text--darken-3">
            {{ $t('intentNew.intentInitial.titleFoundInTemplates') }}
          </h3>

          <v-list>
            <v-list-item-group>
              <div
                v-for="item in searchResults.all"
                :key="`standard_${item.label}`"
              >
                <v-list-item
                  class="px-2 pt-2 pb-1"
                  @click="
                    newIntentWithTemplate(
                      getIntentFromLibraryByName(item.label),
                      true
                    )
                  "
                >
                  <v-list-item-icon>
                    <v-icon small color="primary lighten-3">
                      {{ intentIcon(getIntentFromLibraryByName(item.label)) }}
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="py-0">
                    <v-list-item-title>
                      {{ getIntentFromLibraryByName(item.label).suggested }}
                      {{ displayName(item.label, $vuetify.lang.current) }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                      v-if="getIntentFromLibraryByName(item.label).description"
                      class="pb-2 mt-1"
                    >
                      {{ description(item.label, $vuetify.lang.current) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-icon color="primary lighten-3">navigate_next</v-icon>
                  </v-list-item-action>
                </v-list-item>
                <v-divider />
              </div>
            </v-list-item-group>
          </v-list>
        </div>

        <div class="mt-8" v-if="!isLoading">
          <h3 class="text-h6 secondary--text text--darken-3 mb-4">
            {{ $t('intentNew.intentInitial.titleNewWithoutTemplate') }}
          </h3>

          <InfoBox
            :text="$t('intentNew.intentInitial.infoNewWithoutTemplate')"
          />

          <v-list class="mt-4 pt-0">
            <v-list-item-group>
              <v-list-item class="px-2 py-1" @click="newIntentWithoutTemplate">
                <v-list-item-icon>
                  <v-icon small color="primary lighten-3">
                    create_new_folder
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content class="py-0">
                  <v-list-item-title class="primary--text text--lighten-2" v-if="false">
                    {{ $t('intentNew.intentInitial.itemNewWithoutTemplate') }}
                  </v-list-item-title>
                  <v-list-item-title class="primary--text text--lighten-2">
                    {{ searchResults?.newTopic?.title ||  $t('intentNew.intentInitial.titleNewWithoutTemplate')}}
                  </v-list-item-title>
                  <v-list-item-subtitle
                      v-if="searchResults?.newTopic?.description"
                      class="pb-2 mt-1"
                    >
                      {{ searchResults.newTopic.description }}
                    </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-icon color="primary lighten-3">navigate_next</v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
      </template>
    </HubBox>

    <HubBox
      class="mb-4"
      :title="$t('intentNew.intentInitial.titleAllTemplates')"
      :titleSub="`${existingIntentAmount}${' '}${$t(
        'intentNew.intentInitial.titleExistingTemplates'
      )}`"
      expandedInitial
    >
      <v-chip-group v-model="activeIntentFilter" column>
        <v-chip
          v-for="(item, index) in intentFilterChips"
          :disabled="!item.amount"
          :key="index"
          :value="index"
          :color="item.active ? 'primary lighten-3' : 'grey lighten-1'"
          :text-color="item.active ? 'white' : 'grey'"
          :outlined="!item.active"
          :class="{ 'white--text primary': item.active }"
        >
          <v-icon
            small
            :color="
              item.active ? 'white' : item.iconColor || 'primary lighten-3'
            "
            class="mr-2"
          >
            {{ item.icon }}
          </v-icon>
          {{ $t(item.label) }} {{ item.amount }}
        </v-chip>
      </v-chip-group>

      <v-list>
        <v-list-item-group>
          <div v-for="intent in getLibraryIntents" :key="intent._id">
            <v-list-item @click="newIntentWithTemplate(intent)" class="px-2">
              <v-list-item-icon>
                <v-icon small color="primary lighten-3">
                  {{ intentIcon(intent) }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content class="py-0">
                <v-list-item-title>
                  {{ displayName(intent.intent, $vuetify.lang.current) }}
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-icon color="primary lighten-3">navigate_next</v-icon>
              </v-list-item-action>
            </v-list-item>
            <v-divider />
          </div>
        </v-list-item-group>
      </v-list>
    </HubBox>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import InfoBox from '@/components/common/InfoBox.vue';
import HubBox from '@/components/hub/HubBox.vue';
import { TrackingService } from '@/services/tracking';

export default {
  name: 'IntentNewInitial',
  components: {
    InfoBox,
    HubBox,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isLoading: false,
      activeIntentFilter: null,
    };
  },
  computed: {
    ...mapGetters('bots', ['currentBotId', 'getNLPTemplate']),
    ...mapGetters('intentNew', [
      'searchRequest',
      'searchResults',
      'templatesExpansion',
    ]),
    ...mapGetters('intents', [
      'getIntentsFromLibraryByCategory',
      'getIntentsFromLibraryAndExclude',
      'getIntentFromLibraryByName',
      'displayName',
      'description',
    ]),
    getLibraryIntents() {
      if (this.activeIntentFilter === 'common') {
        return this.getIntentsFromLibraryByCategory('common');
      } else if (this.activeIntentFilter === 'smalltalks') {
        return this.getIntentsFromLibraryByCategory('smalltalks');
      }
      if (this.getNLPTemplate) {
        let categoryId = this.getNLPTemplate.botId;
        if (categoryId && this.activeIntentFilter === categoryId) {
          return this.getIntentsFromLibraryByCategory(categoryId);
        }
      }
      return this.getIntentsFromLibraryAndExclude(['own', 'suggestion']);
    },
    intentFilterChips() {
      const amountCommon =
        this.getIntentsFromLibraryByCategory('common').length;
      const amountSmalltalks =
        this.getIntentsFromLibraryByCategory('smalltalks').length;

      let filters = {
        common: {
          label: 'intents.categories.common', // i18n key
          icon: 'topic',
          iconColor: 'primary lighten-3',
          amount: amountCommon,
          active: this.activeIntentFilter === 'common' ? true : false,
        },
        smalltalks: {
          label: 'intents.categories.smalltalks', // i18n key
          icon: 'coffee',
          iconColor: 'primary lighten-3',
          amount: amountSmalltalks,
          active: this.activeIntentFilter === 'smalltalks' ? true : false,
        },
      };

      if (this.getNLPTemplate) {
        let categoryId = this.getNLPTemplate.botId;
        filters = {
          [categoryId]: {
            label: this.getNLPTemplate.displayName,
            icon: 'inventory_2',
            iconColor: 'primary lighten-3',
            amount: this.getIntentsFromLibraryByCategory(categoryId).length,
            active: this.activeIntentFilter === categoryId ? true : false,
          },
          ...filters,
        };
      }

      return filters;
    },
    existingIntentAmount() {
      let amount = 0;
      Object.keys(this.intentFilterChips).forEach((key) => {
        amount += this.intentFilterChips[key].amount;
      });

      return amount;
    },
  },
  methods: {
    async fetchAI(text) {
      this.isLoading = true;
      await this.$store.dispatch('intentNew/searchStandard', {
        botId: this.currentBotId,
        text,
        language: this.$vuetify.lang.current,
      });
      this.isLoading = false;
    },
    updateSearchInput: _.debounce(async function (inputValue) {
      if (!inputValue?.length) {
        this.clearSearchResults();
        return;
      }
      if (!this.isLoading && inputValue) {
        await this.fetchAI(inputValue);
      }
    }, 2000),
    clearSearchResults() {
      this.trackIt('clear-search');
      this.$store.commit('intentNew/clearSearch');
    },
    newIntentWithTemplate(intent, fromSearchResults) {
      this.$store.commit('intentNew/setIntentWithTemplate', intent);

      const type = fromSearchResults
        ? 'with-template-from-result'
        : 'with-template-from-standard';

      this.trackIt(type, intent.intent);
    },
    newIntentWithoutTemplate() {
      this.$store.commit('intentNew/setIntentWithoutTemplate');

      this.trackIt('without-template');
    },
    intentIcon(intent) {
      if (intent.suggested) return 'auto_awesome';
      if (intent?.intent?.startsWith('smalltalk_')) return 'coffee';

      return 'mdi-folder-text';
    },
    toggleTemplateExpantion() {
      if (this.templatesExpansion.length) {
        this.$store.commit('intentNew/setTemplatesExpansion', []);
      } else {
        this.$store.commit('intentNew/setTemplatesExpansion', [0]);
      }
    },
    trackIt(type = 'initial--undefined', chosenIntent = '?') {
      TrackingService.trackNewIntent({
        botId: this.currentBotId,
        type,
        source: 'IntentNewInitial',
        payload: {
          chosenIntent,
          searchRequest: this.searchRequest,
          searchResults: this.searchResults,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
:deep(.v-expansion-panel-header),
:deep(.v-expansion-panel-content__wrap) {
  padding: $spacer * 4 !important;
}
</style>
