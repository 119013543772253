var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-select", {
    staticClass: "rounded-tl",
    attrs: {
      flat: "",
      "hide-details": "",
      label: _vm.label,
      value: _vm.value,
      items: _vm.all,
      "item-value": "intent",
      "item-text": "displayName",
      disabled: _vm.isDisabled,
    },
    on: { change: _vm.changeValue },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }