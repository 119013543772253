var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.hasError
        ? _c(
            "div",
            { staticClass: "text-center pa-10" },
            [
              _c("v-icon", { staticClass: "pr-2", attrs: { color: "error" } }, [
                _vm._v("mdi-alert"),
              ]),
              _vm._v("Sorry, ein Fehler ist aufgetreten. "),
              _c("br"),
              _c("br"),
              _c("div", { staticClass: "text-body-2" }, [
                _vm._v(
                  " Vermutlich haben Sie bereits zu viele Anfragen gestellt. "
                ),
              ]),
            ],
            1
          )
        : [
            _c("DemoStars"),
            _c("div", [
              _c("h1", {
                staticClass: "text-h5 moinblue--text mb-4",
                domProps: {
                  innerHTML: _vm._s(_vm.$t(`demo.${_vm.step}.loading.title`)),
                },
              }),
              _c("div", {
                domProps: {
                  innerHTML: _vm._s(_vm.$t(`demo.${_vm.step}.loading.body`)),
                },
              }),
            ]),
            _c("DemoCompanyUrl"),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }