var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-col",
    { attrs: { cols: "12" } },
    [
      _vm.hasVariation
        ? _c(
            "v-row",
            { staticClass: "mb-4 pr-4" },
            [
              _c("v-spacer"),
              _c(
                "v-menu",
                {
                  attrs: { "close-on-content-click": false, "offset-y": "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on: onMenu, attrs: attrsMenu }) {
                          return [
                            _c(
                              "v-tooltip",
                              {
                                attrs: {
                                  bottom: "",
                                  color: "primary lighten-3",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({
                                        on: onTooltip,
                                        attrs: attrsTooltip,
                                      }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass:
                                                    "ml-2 mr-2 rounded",
                                                  attrs: {
                                                    color: "primary lighten-3",
                                                    icon: "",
                                                    tile: "",
                                                  },
                                                },
                                                "v-btn",
                                                {
                                                  ...attrsMenu,
                                                  ...attrsTooltip,
                                                },
                                                false
                                              ),
                                              { ...onMenu, ...onTooltip }
                                            ),
                                            [
                                              _c("v-icon", [
                                                _vm._v("library_books"),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t("botFormEdit.variations"))
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1455805407
                  ),
                  model: {
                    value: _vm.variationMenu,
                    callback: function ($$v) {
                      _vm.variationMenu = $$v
                    },
                    expression: "variationMenu",
                  },
                },
                [
                  _c(
                    "v-list",
                    [
                      _c(
                        "v-list-item-group",
                        {
                          attrs: { mandatory: "" },
                          model: {
                            value: _vm.variation,
                            callback: function ($$v) {
                              _vm.variation = $$v
                            },
                            expression: "variation",
                          },
                        },
                        _vm._l(_vm.variationCount, function (index) {
                          return _c(
                            "v-list-item",
                            { key: index },
                            [
                              _c("v-list-item-avatar", [
                                _vm._v(_vm._s(index) + "."),
                              ]),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("botFormEdit.variation")
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _vm.variationCount > 1
                                ? _c(
                                    "v-list-item-action",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { tile: "", icon: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeVariant(index)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [_vm._v(_vm._s(_vm.iconDelete))]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-list-item",
                        { on: { click: _vm.addVariant } },
                        [
                          _c(
                            "v-list-item-avatar",
                            [
                              _c("v-icon", { attrs: { left: "", small: "" } }, [
                                _vm._v(_vm._s(_vm.iconAdd)),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("botFormEdit.newVariation")) +
                                    " "
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "HubDialog",
        {
          attrs: {
            title: _vm.$t("botFormEdit.removeTitle"),
            "primary-label": _vm.$t("common.remove"),
            "primary-icon": "save",
            "secondary-label": _vm.$t("common.cancel"),
            "secondary-icon": "close",
          },
          on: {
            onPrimary: _vm._deleteConfirm,
            onSecondary: function ($event) {
              _vm.deleteDialog = false
            },
          },
          model: {
            value: _vm.deleteDialog,
            callback: function ($$v) {
              _vm.deleteDialog = $$v
            },
            expression: "deleteDialog",
          },
        },
        [
          _c("div", { staticClass: "text-body-1 py-4" }, [
            _vm._v(" " + _vm._s(_vm.$t("botFormEdit.removeDescription")) + " "),
          ]),
        ]
      ),
      _c(
        "div",
        { attrs: { "data-cy": "text-input-parent" } },
        [
          _vm.isText
            ? _c("v-textarea", {
                attrs: {
                  label: _vm.labelString,
                  filled: "",
                  rows: _vm.rows,
                  "append-icon": _vm.isDeletable,
                  "auto-grow": "",
                  "data-cy": "text-input",
                },
                on: { input: _vm.update, "click:append": _vm._delete },
                model: {
                  value: _vm.textModel.data,
                  callback: function ($$v) {
                    _vm.$set(_vm.textModel, "data", $$v)
                  },
                  expression: "textModel.data",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.isWebhook
        ? _c(
            "v-row",
            { staticClass: "moin-admin-box" },
            [
              _vm.isDeletable
                ? _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            icon: "",
                            tile: "",
                            "data-cy": "delete-image-button",
                          },
                          on: { click: _vm._delete },
                        },
                        [_c("v-icon", [_vm._v("delete")])],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("WebhookEdit", {
                    attrs: { action: _vm.action },
                    on: { onUpdated: _vm.update },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.isUserlikeCheck
        ? _c(
            "v-row",
            { staticClass: "moin-admin-box" },
            [
              _vm.isDeletable
                ? _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            icon: "",
                            tile: "",
                            "data-cy": "delete-image-button",
                          },
                          on: { click: _vm._delete },
                        },
                        [_c("v-icon", [_vm._v("delete")])],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                _vm._v(
                  " Userlike Check ob Agenten verfügbar sind: agent_available, agent_not_available "
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.isImage
        ? _c(
            "v-row",
            [
              _vm.isDeletable
                ? _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            icon: "",
                            tile: "",
                            "data-cy": "delete-image-button",
                          },
                          on: { click: _vm._delete },
                        },
                        [_c("v-icon", [_vm._v("delete")])],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c(
                    "v-img",
                    {
                      attrs: {
                        src: _vm.textModel.data,
                        "max-height": "200",
                        width: "358",
                        "data-cy": "image",
                      },
                      on: {
                        dragover: function ($event) {
                          $event.preventDefault()
                        },
                        dragleave: _vm.dragleave,
                        dragenter: _vm.dragenter,
                        drop: _vm.drop,
                      },
                    },
                    [
                      _c(
                        "v-row",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.hoveringContent,
                              expression: "hoveringContent",
                            },
                          ],
                          staticClass: "fill-height ma-0 success",
                          attrs: { align: "center", justify: "center" },
                        },
                        [
                          _c(
                            "v-icon",
                            { attrs: { large: "", color: "white" } },
                            [_vm._v("insert_photo")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.hoverCounter > 0 && !_vm.hoveringContent,
                              expression:
                                "hoverCounter > 0 && !hoveringContent",
                            },
                          ],
                          staticClass: "fill-height ma-0 error",
                          attrs: { align: "center", justify: "center" },
                        },
                        [
                          _c(
                            "v-icon",
                            { attrs: { large: "", color: "white" } },
                            [_vm._v("close")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "pa-2" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-file-input", {
                            attrs: {
                              accept: _vm.acceptedImageTypes,
                              "append-outer-icon": "mdi-cloud-upload",
                              "show-size": "",
                              label: _vm.$t("botFormEdit.replaceImage"),
                              "data-cy": "image-file-input",
                              loading: _vm.isUploading,
                              disabled: _vm.isUploading,
                              rules: _vm.rules,
                            },
                            on: {
                              "click:append-outer": function ($event) {
                                return _vm.upload()
                              },
                            },
                            model: {
                              value: _vm.files,
                              callback: function ($$v) {
                                _vm.files = $$v
                              },
                              expression: "files",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.isCards
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-tabs",
                    {
                      attrs: { "show-arrows": "", "data-cy": "cards" },
                      model: {
                        value: _vm.cardTab,
                        callback: function ($$v) {
                          _vm.cardTab = $$v
                        },
                        expression: "cardTab",
                      },
                    },
                    [
                      _vm._l(_vm.cards, function (card, index) {
                        return _c(
                          "v-tab",
                          { key: index, attrs: { "data-cy": "card-tab" } },
                          [_vm._v(" Slide " + _vm._s(index + 1) + " ")]
                        )
                      }),
                      _c(
                        "v-btn",
                        {
                          staticClass: "align-self-center mr-4",
                          attrs: {
                            disabled: _vm.cards.length >= 10,
                            icon: "",
                            "data-cy": "add-new-card-button",
                          },
                          on: { click: _vm.addCard },
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("add"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "align-self-center mr-4",
                          attrs: { disabled: _vm.cards.length < 2, icon: "" },
                          on: { click: _vm.removeCard },
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v(_vm._s(_vm.iconRemove)),
                          ]),
                        ],
                        1
                      ),
                      _vm.isDeletable
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "align-self-center mr-4",
                              attrs: {
                                icon: "",
                                "data-cy": "delete-card-button",
                              },
                              on: { click: _vm._delete },
                            },
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v(_vm._s(_vm.iconDelete)),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._l(_vm.cards, function (card, index) {
                        return _c(
                          "v-tab-item",
                          { key: index, staticClass: "pt-2" },
                          [
                            _vm.editable
                              ? _c(
                                  "v-card",
                                  {
                                    attrs: { flat: "" },
                                    on: {
                                      dragover: function ($event) {
                                        $event.preventDefault()
                                      },
                                      dragleave: _vm.dragleave,
                                      dragenter: (e) => _vm.dragenter(e, true),
                                      drop: _vm.drop,
                                    },
                                  },
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", md: "6" } },
                                          [
                                            _vm.getIsImage(card.image.data)
                                              ? _c(
                                                  "v-img",
                                                  {
                                                    attrs: {
                                                      src: card.image.data,
                                                      "aspect-ratio": 4 / 3,
                                                      "data-cy": "card-image",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-row",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              _vm.hoveringContent,
                                                            expression:
                                                              "hoveringContent",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "fill-height ma-0 success",
                                                        attrs: {
                                                          align: "center",
                                                          justify: "center",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              large: "",
                                                              color: "white",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "insert_photo"
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-row",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              _vm.hoverCounter >
                                                                0 &&
                                                              !_vm.hoveringContent,
                                                            expression:
                                                              "hoverCounter > 0 && !hoveringContent",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "fill-height ma-0 error",
                                                        attrs: {
                                                          align: "center",
                                                          justify: "center",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              large: "",
                                                              color: "white",
                                                            },
                                                          },
                                                          [_vm._v("close")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _c(
                                                  "video",
                                                  {
                                                    staticClass: "video-player",
                                                    attrs: {
                                                      controls: "",
                                                      preload: "auto",
                                                    },
                                                  },
                                                  [
                                                    _c("source", {
                                                      attrs: {
                                                        src: card.image.data,
                                                        type: "video/mp4",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                            _c("v-file-input", {
                                              attrs: {
                                                accept: _vm.acceptedMediaTypes,
                                                "append-outer-icon":
                                                  "mdi-cloud-upload",
                                                "show-size": "",
                                                label: _vm.$t(
                                                  "botFormEdit.replaceImageOrVideo"
                                                ),
                                                "data-cy":
                                                  "card-image-file-input",
                                                loading: _vm.isUploading,
                                                disabled: _vm.isUploading,
                                                rules: _vm.rules,
                                              },
                                              on: {
                                                "click:append-outer": function (
                                                  $event
                                                ) {
                                                  return _vm.upload(card)
                                                },
                                              },
                                              model: {
                                                value: _vm.files,
                                                callback: function ($$v) {
                                                  _vm.files = $$v
                                                },
                                                expression: "files",
                                              },
                                            }),
                                            _c("v-text-field", {
                                              attrs: {
                                                label: _vm.$t(
                                                  "botFormEdit.titleLabel"
                                                ),
                                                placeholder: _vm.$t(
                                                  "botFormEdit.titlePlaceholder"
                                                ),
                                                filled: "",
                                                "data-cy": "card-title",
                                              },
                                              on: { input: _vm.update },
                                              model: {
                                                value: card.title.data,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    card.title,
                                                    "data",
                                                    $$v
                                                  )
                                                },
                                                expression: "card.title.data",
                                              },
                                            }),
                                            _c("v-textarea", {
                                              attrs: {
                                                label: _vm.$t(
                                                  "botFormEdit.descriptionLabel"
                                                ),
                                                placeholder: _vm.$t(
                                                  "botFormEdit.descriptionLabel"
                                                ),
                                                filled: "",
                                                "data-cy": "card-description",
                                              },
                                              on: { input: _vm.update },
                                              model: {
                                                value: card.subtitle.data,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    card.subtitle,
                                                    "data",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "card.subtitle.data",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("ActionButtonList", {
                                      attrs: {
                                        channelType: _vm.channelType,
                                        buttons: card.buttons,
                                      },
                                      on: {
                                        onUpdated: _vm.update,
                                        addButton: _vm.addCardButton,
                                        removeButton: _vm.removeCardButton,
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.hasButtons
        ? _c("ActionButtonList", {
            attrs: { buttons: _vm.buttons, channelType: _vm.channelType },
            on: {
              onUpdated: _vm.update,
              addButton: _vm.addButton,
              removeButton: _vm.removeButton,
            },
          })
        : _vm._e(),
      _vm.quickreplies
        ? _c(
            "v-row",
            [
              _c("v-col", { staticClass: "pt-0 pb-0", attrs: { cols: "12" } }, [
                _vm._v(" " + _vm._s(_vm.$t("botFormEdit.quickReplies")) + " "),
              ]),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-chip-group",
                    {
                      attrs: { "show-arrows": "", column: _vm.dragging },
                      model: {
                        value: _vm.selectedQuickReply,
                        callback: function ($$v) {
                          _vm.selectedQuickReply = $$v
                        },
                        expression: "selectedQuickReply",
                      },
                    },
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-chip",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            attrs: {
                                              label: "",
                                              disabled:
                                                _vm.allIntents.length === 0,
                                            },
                                            on: { click: _vm.addQuick },
                                          },
                                          "v-chip",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("v-icon", [
                                          _vm._v(_vm._s(_vm.iconAdd)),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            555097476
                          ),
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("botFormEdit.addQuickReplies"))
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "VueDraggable",
                        {
                          on: {
                            start: _vm.dragStart,
                            end: _vm.dragEnd,
                            change: _vm.update,
                          },
                          model: {
                            value: _vm.action.content.quick,
                            callback: function ($$v) {
                              _vm.$set(_vm.action.content, "quick", $$v)
                            },
                            expression: "action.content.quick",
                          },
                        },
                        _vm._l(
                          _vm.action.content.quick,
                          function (chip, index) {
                            return _c(
                              "v-chip",
                              {
                                key: index,
                                attrs: {
                                  color: "primary lighten-3",
                                  close: "",
                                  "close-icon": _vm.iconDelete,
                                },
                                on: {
                                  "click:close": function ($event) {
                                    return _vm.deleteQuick(chip)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.quickLabel(chip.label)) + " "
                                ),
                              ]
                            )
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.action.content.quick.length > 0 &&
              _vm.selectedQuickReply !== undefined &&
              _vm.selectedQuickReply !== null &&
              _vm.selectedQuickReply !== 0
                ? _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: { label: "Label" },
                        on: { input: _vm.update },
                        model: {
                          value: _vm.quickData(
                            _vm.action.content.quick[_vm.selectedQuickReply - 1]
                              .label
                          ).data,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.quickData(
                                _vm.action.content.quick[
                                  _vm.selectedQuickReply - 1
                                ].label
                              ),
                              "data",
                              $$v
                            )
                          },
                          expression:
                            "\n            quickData(action.content.quick[selectedQuickReply - 1].label).data\n          ",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.action.content.quick.length > 0 &&
              _vm.selectedQuickReply !== undefined &&
              _vm.selectedQuickReply !== null &&
              _vm.selectedQuickReply !== 0
                ? _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("intent-selection", {
                        attrs: { exclude: _vm.quickIntents },
                        on: { change: _vm.update },
                        model: {
                          value:
                            _vm.action.content.quick[_vm.selectedQuickReply - 1]
                              .intent,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.action.content.quick[
                                _vm.selectedQuickReply - 1
                              ],
                              "intent",
                              $$v
                            )
                          },
                          expression:
                            "action.content.quick[selectedQuickReply - 1].intent",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }