var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.botHasLivechat
    ? _c(
        "div",
        { staticClass: "livechat d-flex" },
        [
          _vm.agentForOrganizationStatus ===
          _vm.agentForOrganizationStatusTypes.isAgentForCurrentOrganization
            ? _c(
                "div",
                { staticClass: "livechat d-flex" },
                [
                  _vm.agentStatus === _vm.statusTypes.online
                    ? _c(
                        "div",
                        {
                          staticClass: "white livechat d-flex",
                          class: {
                            "livechat--md": _vm.$vuetify.breakpoint.smAndUp,
                          },
                        },
                        [
                          [
                            _c("LivechatLists", {
                              staticClass:
                                "livechat-lists full-height moin-fade-in",
                              class: [
                                _vm.$vuetify.breakpoint.smAndDown
                                  ? "livechat-lists--sm"
                                  : "livechat-lists--md",
                                _vm.$vuetify.breakpoint.smAndDown &&
                                _vm.isChatOpen
                                  ? "livechat-lists--sm-chat-open"
                                  : "",
                              ],
                            }),
                            _c(
                              "div",
                              { staticClass: "full-height" },
                              [_c("v-divider", { attrs: { vertical: "" } })],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "livechat-main full-height",
                                class: [
                                  _vm.$vuetify.breakpoint.smAndDown
                                    ? "livechat-main--sm"
                                    : "livechat-main--md",
                                  _vm.$vuetify.breakpoint.smAndDown &&
                                  _vm.isChatOpen
                                    ? "livechat-main--sm-chat-open"
                                    : "",
                                ],
                              },
                              [_c("LivechatMain")],
                              1
                            ),
                          ],
                          _c("LivechatDialogChangeTab", {
                            attrs: { open: _vm.isDialogChangeLoginOpen },
                            on: {
                              onClose: function ($event) {
                                _vm.isDialogChangeLoginOpen = false
                              },
                            },
                          }),
                        ],
                        2
                      )
                    : _vm.agentStatus === _vm.statusTypes.otherTab
                    ? _c("LivechatUsingOtherTab", {
                        on: { useThisTab: _vm.goOnline },
                      })
                    : _c("LivechatAgentOfflineView", {
                        attrs: {
                          agents: _vm.organization?.agents,
                          "can-manage-agents": _vm.isOwner || _vm.isAdmin,
                        },
                        on: { goOnline: _vm.goOnline },
                      }),
                ],
                1
              )
            : _vm.agentForOrganizationStatus ===
              _vm.agentForOrganizationStatusTypes.isAgentForOtherOrganization
            ? _c("LivechatAgentWrongOrgView", {
                attrs: { "can-manage-agents": _vm.isOwner || _vm.isAdmin },
              })
            : !_vm.organization?.agents?.length
            ? _c("LivechatNoAgentsSetUpView", {
                attrs: { "can-manage-agents": _vm.isOwner || _vm.isAdmin },
              })
            : _c("LivechatNotAnAgent", {
                attrs: { "can-manage-agents": _vm.isOwner || _vm.isAdmin },
              }),
        ],
        1
      )
    : _c("SettingsLivechatInactive")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }