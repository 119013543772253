<template>
  <div>
    <v-timeline-item hide-dot color="grey lighten-3" fill-dot v-if="content">
      <v-row>
        <v-col>
          <v-text-field
            outlined
            class="font-weight-medium ma-0"
            @input="update"
            dense
            v-model="content.displayName"
            :label="$t('botFormEdit.whatIsAsked')"
          ></v-text-field>
        </v-col>
        <v-col>
          <div v-if="isAdmin" class="moin-admin-box pa-1">
            <v-text-field
              outlined
              v-model="content.context"
              @input="update"
              dense
              :label="$t('botFormEdit.technicalName')"
              hide-details
            />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select
            outlined
            dense
            :items="validableTypes"
            item-text="label"
            item-value="value"
            v-model="content.entity"
            :disabled="validableType?.custom && !isRegex"
            :label="$t('botFormEdit.validable.entity')"
            @input="handleEntityChange"
          ></v-select>
        </v-col>
      </v-row>
      <v-row v-if="isRegex">
        <v-col>
          <v-select
            outlined
            dense
            :items="getAllRegExp"
            item-text="displayName"
            return-object
            v-model="content.regexp"
            :disabled="validableType?.custom && !isRegex"
            :label="$t('botFormEdit.validable.regexp')"
            @input="handleChange($event)"
          ></v-select>
        </v-col>
      </v-row>
    </v-timeline-item>

    <ActionList
      v-if="content"
      :actions="content.question.actions"
      @hook:mounted="updateName"
      @onUpdate="update"
      :channelType="channelType"
    ></ActionList>

    <v-timeline-item color="white" v-if="content">
      <template v-slot:icon>
        <v-icon color="error">cancel</v-icon>
      </template>

      <span class="text-subtitle-1">
        {{ $t('botFormEdit.validable.validationErrorTitle') }}
      </span>
      <p class="text-subtitle-2">{{ $t('botFormEdit.validable.validationErrorText') }}</p>
    </v-timeline-item>

    <ActionList
      v-if="content"
      :actions="content.validation.actions"
      @onUpdate="update"
      :channelType="channelType"
    ></ActionList>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ActionList from '@/components/ActionList.vue';
import ChannelsUtil from '@/utils/channels';

const typeSet = new Set(['email', 'url', 'zipcode', 'number', 'date', 'phone', 'licenceplate', 'iban']);

export default {
  name: 'BotFormValidableInputEdit',
  components: {
    ActionList,
  },
  props: ['box', 'intent', 'channel', 'name'],
  data: () => ({
    tab: null,
    contextBeforeChange: '',
  }),
  computed: {
    ...mapGetters('bots', ['currentBotId', 'currentChannels', 'getAllRegExp']),
    ...mapGetters('intents', ['intents']),
    ...mapGetters('auth', ['isAdmin']),
    content() {
      return this.$store.getters['content/getContent'](
        this.currentBotId,
        this.intent,
        this.box,
        true,
        this.channel
      );
    },
    validableTypes() {
      const entity = this.content.entity;

      const types = [
        { value: 'email', label: this.$t('common.eMail') },
        { value: 'url', label: this.$t('common.url') },
        { value: 'zipcode', label: this.$t('common.zipCode') },
        { value: 'number', label: this.$t('common.number') },
        { value: 'date', label: this.$t('common.dateName') },
        { value: 'phone', label: this.$t('common.phoneNr') },
        { value: 'licenceplate', label: this.$t('common.licensePlate') },
        { value: 'iban', label: this.$t('common.iban') },
      ];

      // content.entity is not in types
      if (this.isRegex || this.getAllRegExp?.length) {
        const value = this.isRegex ? entity : 'regexp';
        types.push({ value, label: this.$t('common.regexp'), regexp: true })// Add RegExp as option in select
      } else if (types.findIndex(type => type.value === entity) === -1) {
        types.push({ value: entity, label: this.$t('botFormEdit.validable.custom'), custom: true }) // add custom type to list
      }

      return types;
    },
    validableType() {
      const x = this.validableTypes.find((type) => type.value === this.content.entity);
      return x
    },
    channelType() {
      return ChannelsUtil.getChannelType(
        this.content?.channel,
        this.currentChannels
      );
    },
    isRegex() {
      // To be RegExp element should not be in TYPE_SET and Context (entity) should not be defined RegExp parameter
      const res = !typeSet.has(this.content?.entity) &&
      (this.getAllRegExp?.findIndex(e => e.parameter === this.content.entity) !== -1 || this.content.entity === 'regexp')
      // isCustom when there is no nlp.regex with such context
      return res;
    }
  },
  methods: {
    ...mapActions('bots', ['fetchRegExp']),
    updateName() {
      if (this.content && !this.content.displayName) {
        this.content.displayName = this.name;
      }
    },
    handleChange(regexp) {
      this.content.entity = regexp.parameter;
      this.update();
    },
    handleEntityChange() {
      // If we swap to RegExp => save last context and use default values
      if (this.isRegex) {
        // Replace with default
        if (this.getAllRegExp && this.getAllRegExp[0]) {
          this.content.regexp = this.getAllRegExp[0];
        }
        return this.update();
      }

      this.content.regexp = {}; // Reset selected regexp
      this.update();
    },
    update() {
      this.$emit('onUpdated');
    },
  },
  watch: {
    name() {
      this.content.displayName = this.name;
    },
  },
  async beforeMount() {
    this.$store.dispatch('bots/fetchRegExp', { botId: this.currentBotId });
    const entity = this.content.entity;

    if (!this.getAllRegExp) {
      await this.fetchRegExp({ botId: this.currentBotId });
      // Context value should be equal to make UI work properly
    }

    this.content.regexp = this.getAllRegExp?.find((el) => entity === el.parameter) || {};
    this.content.entity = this.content.regexp?.parameter || this.content.entity;
  },
};
</script>
