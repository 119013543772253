// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/fonts/JosefinSans-VariableFont_wght.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("@/assets/fonts/Lato-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("@/assets/fonts/silka-semibold-webfont.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: \"Josefin Sans\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n@font-face {\n  font-family: \"Lato\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n@font-face {\n  font-family: \"silkaHeading\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n.livechat[data-v-fc725b22] {\n  position: relative;\n  height: 100%;\n  width: 100%;\n  overflow: hidden;\n  overflow: hidden;\n}\n.livechat--md[data-v-fc725b22] {\n  border-radius: 4px;\n}\n.livechat-main[data-v-fc725b22],\n.livechat-lists[data-v-fc725b22] {\n  left: 0;\n  max-width: 100%;\n}\n.livechat-lists--sm[data-v-fc725b22] {\n  width: 100%;\n  position: absolute;\n  top: 0;\n  left: 0;\n  -webkit-transition: left 0.3s;\n  transition: left 0.3s;\n}\n.livechat-main--sm[data-v-fc725b22] {\n  width: 100%;\n  position: absolute;\n  top: 0;\n  left: 100%;\n}\n.livechat-lists--sm-chat-open[data-v-fc725b22] {\n  left: -100%;\n}\n.livechat-main--sm-chat-open[data-v-fc725b22] {\n  left: 0;\n  -webkit-transition: left 0.3s;\n  transition: left 0.3s;\n}\n.livechat-lists--md[data-v-fc725b22] {\n  width: 380px;\n  position: relative;\n  -ms-flex-negative: 0;\n      flex-shrink: 0;\n}\n.livechat-main--md[data-v-fc725b22] {\n  -webkit-box-flex: 1;\n      -ms-flex-positive: 1;\n          flex-grow: 1;\n  width: auto;\n  position: relative;\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
