var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      staticClass: "white px-4",
      staticStyle: { "border-radius": "4px 4px 0 0" },
      attrs: { fluid: "" },
    },
    [
      _c(
        "v-row",
        [
          _vm.hasChannels
            ? _c(
                "v-col",
                { attrs: { cols: "12", lg: _vm.compareDisabled ? 6 : 4 } },
                [
                  _c("v-select", {
                    attrs: {
                      value: _vm.channel,
                      items: _vm.channels,
                      label: _vm.$t("stats.channelsFilter"),
                      placeholder: _vm.$t("channels.select-all"),
                      "prepend-inner-icon": "call_split",
                      "item-text": "displayName",
                      "item-value": "channelId",
                    },
                    on: { change: _vm.changeChannel },
                  }),
                ],
                1
              )
            : _vm._e(),
          !_vm.isAnualReview
            ? _c(
                "v-col",
                {
                  attrs: {
                    cols: "12",
                    sm: "6",
                    lg: _vm.compareDisabled ? 6 : _vm.hasChannels ? 4 : 6,
                  },
                },
                [
                  _c("DateRange", {
                    attrs: { disabled: _vm.isAnualReview },
                    on: { change: _vm.changeDateRange },
                    model: {
                      value: _vm.rangeValue,
                      callback: function ($$v) {
                        _vm.rangeValue = $$v
                      },
                      expression: "rangeValue",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          !_vm.compareDisabled && !_vm.isAnualReview
            ? _c(
                "v-col",
                { attrs: { cols: "12", sm: "6", lg: _vm.hasChannels ? 4 : 6 } },
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.dateOptionsCompare,
                      label: _vm.$t("dateRange.labelDateRangeCompare"),
                      "prepend-inner-icon": "date_range",
                      "item-text": "text",
                      "item-value": "value",
                    },
                    on: { change: _vm.changeDateRangeCompare },
                    model: {
                      value: _vm.rangeCompareValue,
                      callback: function ($$v) {
                        _vm.rangeCompareValue = $$v
                      },
                      expression: "rangeCompareValue",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }