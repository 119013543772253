var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "mb-10" }, [_c("DemoStepper")], 1),
    _c("div", { staticClass: "moin-fade-in" }, [
      _vm.celebrate
        ? _c("div", { staticStyle: { "font-size": "40px" } }, [_vm._v("🎉")])
        : _vm._e(),
      _c("h1", {
        staticClass: "text-h5 moinblue--text mb-2",
        domProps: { innerHTML: _vm._s(_vm.$t(`demo.${_vm.step}.title`)) },
      }),
      _c("div", {
        staticClass: "text-body-1",
        domProps: { innerHTML: _vm._s(_vm.$t(`demo.${_vm.step}.body`)) },
      }),
      _c("div", { staticClass: "pt-6" }, [_vm._t("default")], 2),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }