var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fill-height" },
    [
      _c(
        "v-card",
        { staticClass: "fill-height pa-4", attrs: { outlined: "" } },
        [
          _c("h2", { staticClass: "text-h6" }, [_vm._v("Features")]),
          _c("v-divider", { staticClass: "my-2" }),
          _vm.nlpFeatures
            ? _c(
                "div",
                { staticClass: "text-body-2" },
                _vm._l(_vm.nlpFeatures, function (feature, index) {
                  return _c("div", { key: index }, [
                    _vm._v(" " + _vm._s(feature) + ", "),
                  ])
                }),
                0
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }