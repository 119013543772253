<template>
  <div>
    <v-app-bar
      :extended="$vuetify.breakpoint.smAndDown"
      app
      class="white"
      elevation="0"
      :extension-height="$vuetify.breakpoint.smAndDown ? 50 : 64"
    >
      <template v-if="$vuetify.breakpoint.mdAndDown">
        <BtnSquare class="mt-2 mr-2" @click="changeDrawer()" icon="menu" />
        <div class="mt-4 mr-4">
          <img src="@/assets/moin-logo.svg" height="38px" />
        </div>
      </template>

      <v-toolbar-title class="hidden-sm-and-down text-h5 mr-2 mt-2 pl-0">
        <v-btn
          v-if="loggedIn && false"
          to="/"
          class="mr-1 mb-1 hidden-sm-and-down"
          small
          tile
          icon
        >
          <span class="pl-2 hidden-xs-only">Teaser</span>
          <v-icon right class="pl-0 mx-2">play_circle</v-icon>
        </v-btn>
        Preview
      </v-toolbar-title>

      <v-spacer />

      <v-btn
        v-if="false"
        class="mx-2 px-2 primary--text font-weight-bold"
        color="primary"
        outlined
      >
        <span class="hidden-sm-and-down pl-2">Teilen</span>
        <v-icon class="pl-0 mx-2">mdi-share</v-icon>
      </v-btn>
      <v-btn
        class="mx-2 px-2 white--text font-weight-bold hidden-sm-and-down"
        color="secondary darken-2"
        @click="showTeaser"
        depressed
      >
        <span class="pl-2">Teaser</span>
        <v-icon right class="pl-0 mx-2">play_circle</v-icon>
      </v-btn>
      <v-btn
        class="mx-2 px-2 white--text font-weight-bold hidden-sm-and-down"
        color="primary"
        @click="restart"
        depressed
      >
        <span class="pl-2">{{ $t('preview.restart') }}</span>
        <v-icon class="pl-0 mx-2">mdi-replay</v-icon>
      </v-btn>

      <template
        v-slot:extension
        v-if="
          $vuetify.breakpoint.smAndDown ||
          ($vuetify.breakpoint.mdAndUp && notification)
        "
      >
        <template v-if="$vuetify.breakpoint.smAndDown">
          <v-toolbar-title class="hidden-md-and-up subline mr-2 mt-0 pl-0">
            <v-btn
              v-if="loggedIn && false"
              to="/"
              class="mr-1 mb-1 hidden-sm-and-down"
              small
              fab
              rounded-4
              icon
            >
              <v-icon color="primary lighten-2">arrow_back</v-icon>
            </v-btn>
            Preview
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            class="mx-2 px-2 mb-2 white--text font-weight-bold hidden-md-and-up"
            color="secondary darken-2"
            @click="showTeaser"
          >
            <span class="pl-2 hidden-xs-only">Teaser</span>
            <v-icon right class="pl-0 mx-2">play_circle</v-icon>
          </v-btn>
          <v-btn
            class="mr-0 ml-2 mx-2 px-2 mb-2 white--text font-weight-bold"
            depressed
            color="primary"
            @click="restart"
          >
            <span class="pl-2 hidden-xs-only">{{ $t('preview.restart') }}</span>
            <v-icon class="pl-0 mx-2">mdi-replay</v-icon>
          </v-btn>
        </template>
        <div v-else class="white flex-grow-1 mt-n4 mx-n2 pt-2 px-2">
          <HubNotification />
        </div>
      </template>
    </v-app-bar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import BotService from '../services/bot';
import OsUtil from '@/utils/os';
import HubNotification from '@/components/hub/HubNotification.vue';
import BtnSquare from '@/components/common/BtnSquare.vue';

export default {
  name: 'AppbarPreview',
  components: { BtnSquare, HubNotification },
  props: {
    loggedIn: {
      type: Boolean,
      default: false,
    },
    botId: {
      type: String,
      default: '',
    },
    channelId: String,
  },
  methods: {
    restart() {
      this.resetWidget({ botId: this.botId, channelId: this.channelId });
      // reload page
      this.$router.go();
    },
    changeDrawer() {
      // change navigation drawer state
      this.$store.commit('hubUi/setValue', { key: 'isMenuOpen', value: true });
    },
    ...mapActions('preview', ['resetWidget', 'showTeaser']),
  },
  data: () => ({
  }),
  mounted() {
  },
  created() {
    if (this.isOnlyStaging) {
      this.$store.dispatch('bots/setViewStaging', true);
    } else {
      this.$store.dispatch('bots/setViewStaging', false);
    }
  },
  computed: {
    ...mapGetters('auth', ['isAdmin', 'isOnlyStaging', 'isNoBeamer', 'user']),
    ...mapGetters('notifications', ['notification']),
    userLang() {
      return this.$vuetify.lang.current;
    },
    getOS() {
      return OsUtil.getOs();
    },
    ...mapGetters('bots', [
      // create properties from auth store
      'currentBot',
      'viewStaging',
      'bots',
    ]),
    facebookChannel() {
      if (!this.currentBot) {
        return false;
      }

      return BotService.linkToChannel(this.currentBot, 'facebook');
    },
    webchatChannel() {
      if (!this.currentBot) {
        return false;
      }

      return BotService.linkToChannel(this.currentBot, 'widget');
    },
    hasFacebookChannel() {
      if (!this.currentBot) {
        return false;
      }

      return BotService.hasActiveChannel(this.currentBot, 'facebook');
    },
    hasWebchatChannel() {
      if (!this.currentBot) {
        return false;
      }

      return BotService.hasActiveChannel(this.currentBot, 'widget');
    },
  },
};
</script>
<style scoped>
.v-app-bar::after {
  content: ' ';
  display: block;
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  height: 1px;
  background: rgba(0, 0, 0, 0.12);
}
</style>
