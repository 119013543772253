var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "DemoStep",
    { attrs: { slug: "stepIntents" } },
    [
      _c("div", { staticClass: "question-item d-flex flex-column" }, [
        _c(
          "div",
          { staticClass: "header-container d-flex align-items-center" },
          [
            _c("v-icon", { attrs: { color: "primary lighten-3" } }, [
              _vm._v("mdi-check"),
            ]),
            _c(
              "div",
              {
                staticClass: "font-weight-bold text-body-1 primary--text ml-2",
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("demoStepIntents.editorialAnswers")) + " "
                ),
              ]
            ),
          ],
          1
        ),
        _c("div", { staticClass: "text-body-2 grey--text mt-2" }, [
          _vm._v(
            " " +
              _vm._s(_vm.$t("demoStepIntents.editorialAnswersDescription")) +
              " "
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " " + _vm._s(_vm.$t("demoStepIntents.editorialAnswersTopics")) + " "
          ),
        ]),
        _c(
          "ul",
          { staticClass: "product-advisors-list mt-2" },
          _vm._l(_vm.features.editorialTopics, function (topic) {
            return _c("li", { key: topic }, [_vm._v(_vm._s(topic))])
          }),
          0
        ),
      ]),
      _c("div", { staticClass: "question-item d-flex flex-column mt-3" }, [
        _c(
          "div",
          { staticClass: "header-container d-flex align-items-center" },
          [
            _c("v-icon", { attrs: { color: "primary lighten-3" } }, [
              _vm._v("mdi-check"),
            ]),
            _c(
              "div",
              {
                staticClass: "font-weight-bold text-body-1 primary--text ml-2",
              },
              [_vm._v(" Knowledge Base ")]
            ),
          ],
          1
        ),
        _c("div", { staticClass: "text-body-2 grey--text mt-2" }, [
          _vm._v(
            " " +
              _vm._s(_vm.$t("demoStepIntents.knowledgeBaseDescription")) +
              " "
          ),
          _c("br"),
          _c("br"),
          _vm._v(" " + _vm._s(_vm.$t("demoStepIntents.generatedAnswer")) + " "),
        ]),
        _c("div", { staticClass: "example-request mt-2" }, [
          _c("div", { staticClass: "request" }, [
            _c("strong", [
              _vm._v(_vm._s(_vm.$t("demoStepIntents.query")) + ":"),
            ]),
            _vm._v(' "' + _vm._s(_vm.features.answer.query) + '" '),
          ]),
          _c("div", { staticClass: "response mt-1" }, [
            _c("strong", [
              _vm._v(_vm._s(_vm.$t("demoStepIntents.answer")) + ":"),
            ]),
            _vm._v(' "' + _vm._s(_vm.features.answer.answer) + '" '),
          ]),
        ]),
      ]),
      _vm.features.product_advisor
        ? _c(
            "div",
            {
              staticClass:
                "question-item d-flex flex-column mt-3 highlight-item",
            },
            [
              _c(
                "div",
                { staticClass: "header-container d-flex align-items-center" },
                [
                  _c("v-icon", { attrs: { color: "#8046eb" } }, [
                    _vm._v("mdi-check"),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass:
                        "font-weight-bold text-body-1 primary--text ml-2",
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("demoStepIntents.productAdvisor")) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "text-body-2 grey--text mt-2" }, [
                _c("span", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$t("demoStepIntents.productAdvisorDescription")
                    ),
                  },
                }),
                _c("br"),
                _c("br"),
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("demoStepIntents.productAdvisorTopics")) +
                    " "
                ),
              ]),
              _c(
                "ul",
                { staticClass: "product-advisors-list mt-2" },
                _vm._l(_vm.features.product_advisors, function (advisors) {
                  return _c("li", { key: advisors }, [_vm._v(_vm._s(advisors))])
                }),
                0
              ),
            ]
          )
        : _vm._e(),
      _vm.features.livechat
        ? _c("div", { staticClass: "question-item d-flex flex-column mt-3" }, [
            _c(
              "div",
              { staticClass: "header-container d-flex align-items-center" },
              [
                _c("v-icon", { attrs: { color: "primary lighten-3" } }, [
                  _vm._v("mdi-check"),
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "font-weight-bold text-body-1 primary--text ml-2",
                  },
                  [_vm._v(" moinAI Livechat ")]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "text-body-2 grey--text mt-2" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("demoStepIntents.livechatDescription")) +
                  " "
              ),
            ]),
          ])
        : _vm._e(),
      _c("DemoBtns", {
        on: {
          onBack: function ($event) {
            return _vm.back()
          },
          onNext: function ($event) {
            return _vm.next()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }