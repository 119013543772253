var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("ActionRow", {
        class: { "ml-6": _vm.indent },
        style: _vm.fixedHeight ? "height: 60px" : "min-height: 60px",
        attrs: { "vertical-padding": !_vm.options?.readMode },
        scopedSlots: _vm._u(
          [
            {
              key: "line",
              fn: function () {
                return [
                  _c("ActionLine", {
                    attrs: {
                      blockType: _vm.blockType,
                      options: _vm.currenOptions,
                      items: _vm.items,
                      clickable: _vm.isClickable,
                    },
                    on: { onEdit: _vm.onEdit },
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "content",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "pr-4" },
                    [
                      !_vm.loading
                        ? [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-content justify-space-between",
                              },
                              [
                                _c(
                                  "h1",
                                  {
                                    staticClass:
                                      "text-body-1 font-weight-bold secondary--text mt-1",
                                  },
                                  [_vm._v(" " + _vm._s(_vm.displayName) + " ")]
                                ),
                                _c("div", [_vm._t("meta")], 2),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "text-body-2 secondary--text" },
                              [_vm._t("description")],
                              2
                            ),
                          ]
                        : _c("v-skeleton-loader", {
                            staticClass: "mt-1",
                            attrs: { height: 20, width: 176, type: "image" },
                          }),
                    ],
                    2
                  ),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
      _vm.showDialog
        ? _c(
            "HubDialog",
            {
              attrs: {
                modal: "",
                value: _vm.showDialog,
                title: "Namen ändern",
              },
              on: {
                input: (value) => (_vm.showDialog = value),
                onPrimary: _vm.updateName,
              },
            },
            [
              _c("v-text-field", {
                attrs: { outlined: "", label: "Name" },
                model: {
                  value: _vm.displayNameString,
                  callback: function ($$v) {
                    _vm.displayNameString = $$v
                  },
                  expression: "displayNameString",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }