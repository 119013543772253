var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _vm.showError
        ? _c("v-alert", { attrs: { type: "error" } }, [
            _vm._v("Varianten ist bereits vorhanden."),
          ])
        : _vm._e(),
      _c("v-card-title", [_vm._v("Varianten hinzufügen")]),
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-textarea", {
                    attrs: {
                      label: "Varianten",
                      "row-height": "20",
                      rows: "10",
                    },
                    model: {
                      value: _vm.newText,
                      callback: function ($$v) {
                        _vm.newText = $$v
                      },
                      expression: "newText",
                    },
                  }),
                  _c("span", [
                    _vm._v("Varianten: " + _vm._s(_vm.variantCount)),
                  ]),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      items: _vm.allIntents,
                      "item-text": "displayName",
                      "item-value": "intent",
                      "cache-items": "",
                      flat: "",
                      "hide-no-data": "",
                      label: "Thema auswählen",
                    },
                    on: { change: _vm.changeIntent },
                    model: {
                      value: _vm.newIntent,
                      callback: function ($$v) {
                        _vm.newIntent = $$v
                      },
                      expression: "newIntent",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.trainingdata.length > 0
            ? _c(
                "v-row",
                { staticClass: "mt-0" },
                [
                  _c(
                    "v-list",
                    { staticClass: "mt-0", attrs: { dense: "" } },
                    _vm._l(_vm.trainingdata, function (data, index) {
                      return _c("v-list-item", { key: index }, [
                        _vm._v(_vm._s(data.text)),
                      ])
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("v-divider", { staticClass: "mt-2" }),
      _c(
        "v-card-actions",
        {
          staticClass:
            "flex-column flex-sm-row justify-space-between align-stretch",
        },
        [
          _c(
            "v-btn",
            {
              staticClass:
                "px-4 primary--text d-block d-sm-inline-block my-4 my-sm-0",
              attrs: { outlined: "", color: "primary lighten-2" },
              on: {
                click: function ($event) {
                  return _vm.$emit("onClose")
                },
              },
            },
            [
              _vm._v("Abbrechen "),
              _c("v-icon", { attrs: { right: "" } }, [_vm._v("close")]),
            ],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass:
                "px-4 white--text ml-0 ml-sm-2 d-block d-sm-inline-block",
              attrs: {
                disabled: _vm.variantCount === 0 || !_vm.newIntent,
                depressed: "",
                color: "primary lighten-2",
              },
              on: { click: _vm.addVariant },
            },
            [
              _vm._v("Hinzufügen"),
              _c("v-icon", { attrs: { right: "" } }, [_vm._v("add")]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }