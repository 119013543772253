import store from "@/store/index.js";
import RAGServiceV2 from '@/services/rag_v2';
import { get, set } from 'lodash';

/**
 * These enums let you know mostly the "state" of the document relative to its status in the database.
 * @enum {string}
 */
export const ResourceDraftType = {
  /**
   * This indicates a new document that has never been published.
   * This happens when you create a new document and are taken to the editor.
   * This document is populated in the Vuex store when you create a new document.
   */
  NEW_DRAFT: 'newDraft',
  /**
   * The document is published and is an unmodified version you can compare edits against.
   * It is the document that is loaded and populated into the Content Vuex store.
   * Do not make changes to this document, use the DRAFT copy instead.
   */
  PUBLISHED: 'published',
  /**
   * This indicates a published document that has been edited.
   * It will exist alongside the PUBLISHED one.
   * This copy is automatically populated in the Vuex store when you start editing a document.
   */
  DRAFT: 'draftOfPublished',
}

export const LegacyMarkdownDocumentType = {
  /**
   * This indicates a new document that has never been published.
   * This happens when you create a new document and are taken to the editor.
   * This document is populated in the Vuex store when you create a new document.
   */
  NEW_DRAFT: 'KnowledgeBaseDocumentNewDraft',
  /**
   * The document is published and is an unmodified version you can compare edits against.
   * It is the document that is loaded and populated into the Content Vuex store.
   * Do not make changes to this document, use the DRAFT copy instead.
   */
  PUBLISHED: 'KnowledgeBaseDocument',
  /**
   * This indicates a published document that has been edited.
   * It will exist alongside the PUBLISHED one.
   * This copy is automatically populated in the Vuex store when you start editing a document.
   */
  DRAFT: 'KnowledgeBaseDocumentDraft',
}

/**
 * Where we can find the Markdown content for a given type of RAG resource
 */
export const MarkdownPath = {
  MARKDOWN: 'content.markdown',
  QNA: 'content.answer',
  WEBPAGE: 'markdown',
  PDF: 'markdown',
  TABLE: 'markdown',
  undefined: 'markdown',
};
/**
 * Where we can find the display title stored for a given type of RAG resource
 */
export const TitlePath = {
  MARKDOWN: 'metaData.title',
  QNA: 'content.question',
  QNAFallback: 'metaData.originalTitle',
  PDF: 'url',
  WEBPAGE: 'url',
  TABLE: 'url',
};
/**
 * Types of RAG resources that we can use the Markdown editor on.
 * Specifically, ones that has to be written in Markdown before they can get created.
 * @type {string[]}
 */
export const EditableTypes = [
  'QNA',
  'MARKDOWN',
];


/**
 * Small utility to make it easy to manage resource connections to intents, across channels.
 * Returns a new array of connections you replace the old one with.
 * @example
 * async function onDisconnectFromFallback(resourceId) {
 *   const resource = this.resources.find(r => r._id === resourceId);
 *   const fallbackIntentId = this.fallbackFaqIntent.intent;
 *
 *   const connections = ResourceConnectionUtil.disconnectFromStaging(
 *    resource.intents,
 *    fallbackIntentId,
 *    { channelId: this.currentChannelId },
 *   );
 *   resource.intents = connections;
 *
 *   await RAGService.updateResource(resource);
 * }
 */
export const ResourceConnectionUtil = {
  /**
   * What channel logic to use. Pick only one.
   * @typedef {Object} AssignmentChannelOptions
   * @property {?boolean} [all] Target all channels.
   * @property {?string|'null'|null} [channelId] Target a specific channel.
   * It can also be `null`, "null", or the ID of the first channel.
   * @property {?boolean} [current = true] Target the current channel you are in (bots/currentChannelId).
   */
  /**
   * Resolve channel IDs to work with
   * @param {AssignmentChannelOptions} channelOpts
   * @returns {string[]} One or more channel IDs to target
   * @private
   */
  _channelId(channelOpts = {}) {
    /**
     * First channelId or `null` always get changed to the string "null".
     */

    // Apply to all channels
    if (channelOpts.all) {
      const channelIds = store.getters['bots/currentChannels'].map(ch => ch.channelId);
      channelIds[0] = "null";
      return channelIds;
    }

    // Apply to current channel
    if (channelOpts.channelId === undefined) {
      const chId = store.getters['bots/currentChannelId'];
      if (store.getters['bots/currentChannels'][0].channelId === chId) return ["null"];
      return [chId];
    }

    // Apply to specified channel
    if ([null, "null", store.getters['bots/currentChannels']?.[0]?.channelId].includes(channelOpts.channelId)) return ["null"];
    return [channelOpts.channelId];
  },

  /**
   Whether an intent is active in a given channel's live environment
   * @param {RagIntent[]} intents A list of intents this resource is connected to (`resource.intents`)
   * @param {string} intent The intent you want connected
   * @param {AssignmentChannelOptions} [channelOpts={current: true}] Specify the channel(s) to connect to
   * @returns {boolean}
   */
  isInLive(intents, intent, channelOpts = {}) {
    for (const intentObj of intents) {
      if (intentObj.intent !== intent) continue;
      const channelIds = ResourceConnectionUtil._channelId(channelOpts);
      return intentObj?.liveChannels?.some(chId => channelIds.includes(chId)) ?? false;
    }

    return false;
  },

  /**
   * Whether an intent is active in a given channel's staging environment
   * @param {RagIntent[]} intents A list of intents this resource is connected to (`resource.intents`)
   * @param {string} intent The intent you want connected
   * @param {AssignmentChannelOptions} [channelOpts={current: true}] Specify the channel(s) to connect to
   * @returns {boolean}
   */
  isInStaging(intents, intent, channelOpts = {}) {
    for (const intentObj of intents) {
      if (intentObj.intent !== intent) continue;
      const channelIds = ResourceConnectionUtil._channelId(channelOpts);
      return intentObj?.stagingChannels?.some(chId => channelIds.includes(chId)) ?? false;
    }

    return false;
  },

  /**
   * Whether an intent is active in either live or staging environment of the given channel
   * @param {RagIntent[]} intents A list of intents this resource is connected to (`resource.intents`)
   * @param {string} intent The intent you want connected
   * @param {AssignmentChannelOptions} [channelOpts={current: true}] Specify the channel(s) to connect to
   * @returns {boolean}
   */
  isInLiveOrStaging(intents, intent, channelOpts={}) {
    return ResourceConnectionUtil.isInStaging(intents, intent, channelOpts)
      || ResourceConnectionUtil.isInLive(intents, intent, channelOpts);
  },

  /**
   * Connects a given intent to Live and Staging.
   * Gives you a new copy of the intent array.
   * @param {RagIntent[]} intents A list of intents this resource is connected to (`resource.intents`)
   * @param {string} intent The intent you want connected
   * @param {AssignmentChannelOptions} [channelOpts={current: true}] Specify the channel(s) to connect to
   * @returns {RagIntent[]}
   */
  connectToLiveAndStaging(intents, intent, channelOpts = {}) {
    return ResourceConnectionUtil.connectToLive(
      ResourceConnectionUtil.connectToStaging(intents, intent, channelOpts),
      intent,
      channelOpts,
    );
  },

  /**
   * Connects a given intent to Staging.
   * Gives you a new copy of the intent array.
   * @param {RagIntent[]} intents A list of intents this resource is connected to (`resource.intents`)
   * @param {string} intent The intent you want connected
   * @param {AssignmentChannelOptions} [channelOpts={current: true}] Specify the channel(s) to connect to
   * @returns {RagIntent[]}
   */
  connectToStaging(intents, intent, channelOpts = {}) {
    /**
     * @type {RagIntent[]}
     */
    const ragIntents = structuredClone(intents);
    const channelIds = ResourceConnectionUtil._channelId(channelOpts);
    let wasFound = false;

    for (const ragIntent of ragIntents) {
      if (ragIntent.intent !== intent) continue;
      wasFound = true;

      ragIntent.stagingChannels = Array.from(new Set(channelIds.concat(ragIntent.stagingChannels ?? [])));
      break;
    }

    // Create the intent in the array if necessary
    if (!wasFound) {
      ragIntents.push({
        intent,
        liveChannels: [],
        stagingChannels: channelIds,
      });
    }

    return ragIntents;
  },

  /**
   * Connects a given intent to Live.
   * Gives you a new copy of the intent array.
   * @param {RagIntent[]} intents A list of intents this resource is connected to (`resource.intents`)
   * @param {string} intent The intent you want connected
   * @param {AssignmentChannelOptions} [channelOpts={current: true}] Specify the channel(s) to connect to
   * @returns {RagIntent[]}
   */
  connectToLive(intents, intent, channelOpts = {}) {
    /**
     * @type {RagIntent[]}
     */
    const ragIntents = structuredClone(intents);
    const channelIds = ResourceConnectionUtil._channelId(channelOpts);
    let wasFound = false;

    for (const ragIntent of ragIntents) {
      if (ragIntent.intent !== intent) continue;
      wasFound = true;

      ragIntent.liveChannels = Array.from(new Set(channelIds.concat(ragIntent.liveChannels ?? [])));
      break;
    }

    // Create the intent in the array if necessary
    if (!wasFound) {
      ragIntents.push({
        intent,
        liveChannels: channelIds,
        stagingChannels: [],
      });
    }

    return ragIntents;
  },

  /**
   * Removes a given intent from Live and Staging.
   * Gives you a new copy of the intent array.
   * @param {RagIntent[]} intents A list of intents this resource is connected to (`resource.intents`)
   * @param {string} intent The intent you want to disconnect
   * @param {AssignmentChannelOptions} [channelOpts={current: true}] Specify the channel(s) to disconnect from
   * @returns {RagIntent[]}
   */
  disconnectFromLiveAndStaging(intents, intent, channelOpts = {}) {
    return ResourceConnectionUtil.disconnectLive(
      ResourceConnectionUtil.disconnectStaging(intents, intent, channelOpts),
      intent,
      channelOpts,
    );
  },

  /**
   * Removes a given intent from Staging.
   * Gives you a new copy of the intent array.
   * @param {RagIntent[]} intents A list of intents this resource is connected to (`resource.intents`)
   * @param {string} intent The intent you want disconnect
   * @param {AssignmentChannelOptions} [channelOpts={current: true}] Specify the channel(s) to disconnect from
   * @returns {RagIntent[]}
   */
  disconnectStaging(intents, intent, channelOpts = {}) {
    /**
     * @type {RagIntent[]}
     */
    const ragIntents = structuredClone(intents);

    for (const ragIntent of ragIntents) {
      if (ragIntent.intent !== intent) continue;

      const channelIds = ResourceConnectionUtil._channelId(channelOpts);
      ragIntent.stagingChannels = ragIntent.stagingChannels?.filter(chId => !channelIds.includes(chId)) ?? [];

      /*
      // Remove the entire intent if it has nothing left in both live and staging
      if (ragIntent.liveChannels.length === 0 && ragIntent.stagingChannels.length === 0) {
        ragIntents.splice(ragIntents.indexOf(ragIntent), 1);
      }
       */

      break;
    }

    return ragIntents;
  },

  /**
   * Removes a given intent from Live.
   * Gives you a new copy of the intent array.
   * @param {RagIntent[]} intents A list of intents this resource is connected to (`resource.intents`)
   * @param {string} intent The intent you want disconnect
   * @param {AssignmentChannelOptions} [channelOpts={current: true}] Specify the channel(s) to disconnect from
   * @returns {RagIntent[]}
   */
  disconnectLive(intents, intent, channelOpts = {}) {
    /**
     * @type {RagIntent[]}
     */
    const ragIntents = structuredClone(intents);

    for (const ragIntent of ragIntents) {
      if (ragIntent.intent !== intent) continue;

      const channelIds = ResourceConnectionUtil._channelId(channelOpts);
      ragIntent.liveChannels = ragIntent.liveChannels?.filter(chId => !channelIds.includes(chId)) ?? [];

      /*
      // Remove the entire intent if it has nothing left in both live and staging
      if (ragIntent.liveChannels.length === 0 && ragIntent.stagingChannels.length === 0) {
        ragIntents.splice(ragIntents.indexOf(ragIntent), 1);
      }
       */

      break;
    }

    return ragIntents;
  },
  /**
   * Disconnects every channel in every environment for all intents
   * @param {RagIntent[]} intents
   * @returns {RagIntent[]}
   */
  disconnectEverything(intents) {
    return intents.map(i => ({intent: i.intent, stagingChannels: [], liveChannels: []}))
  }
}

export default {
  methods: {
    /**
     * Connect resources to intents, for given channels
     * @param {string[]} resourceIds
     * @param {string[]} intentNames
     * @param {AssignmentChannelOptions} [channelOpts={current: true}]
     * @param {{staging?: boolean, live?: boolean}} [environments={staging: true}]
     * @returns {Promise<void>}
     */
    async connectToIntent(resourceIds, intentNames, channelOpts = {}, environments = {staging: true}) {
      // Skip if nothing changed
      if (!environments.staging && !environments.live) return;

      // Get a copy of the resources, making it easy to roll-back mutations of connected intents if it fails
      const botId = store.getters['bots/currentBotId'];
      const resources = store.getters['knowledgeBase/resources'](botId, resourceIds);

      const results = [];
      for (const resource of resources) {

        // Base the new list of intents on the existing one
        let newIntentList = resource.intents;

        // Build the new intent list iteratively
        for (const intentName of intentNames) {
          if (environments.live && environments.staging) newIntentList = ResourceConnectionUtil.connectToLiveAndStaging(resource.intents, intentName, channelOpts);
          else if (environments.staging) newIntentList = ResourceConnectionUtil.connectToStaging(resource.intents, intentName, channelOpts);
          else newIntentList = ResourceConnectionUtil.connectToLive(resource.intents, intentName, channelOpts);
        }

        // Update backend
        results.push(
          RAGServiceV2.setAssignedIntents(
            botId,
            resource._id,
            newIntentList
          ).then(result => {
            if (result === null) {
              console.error("Failed to update intent list for resource", resource._id);
              return null;
            }

            return newIntentList;
          })
        );
      }

      // Overwrite instance for successful ones ...
      let i=-1;
      for await (const result of results) {
        i++;
        if (result === null) continue;
        resources[i].intents = result;
      }

      // ... and commit all to storage/make it reactive other places
      store.commit('knowledgeBase/setResources', { botId, resources });
    },
    /**
     * Disconnect resources from intents, in given channels
     * @param {string[]} resourceIds
     * @param {string[]} intentNames
     * @param {AssignmentChannelOptions} [channelOpts={current: true}]
     * @param {{staging?: boolean, live?: boolean}} [environments={staging: true}]
     * @returns {Promise<void>}
     */
    async disconnectFromIntent(resourceIds, intentNames, channelOpts = {}, environments = {staging: true}) {
      // Skip if nothing changed
      if (!environments.staging && !environments.live) return;

      // Get a copy of the resources, making it easy to roll-back mutations of connected intents if it fails
      const botId = store.getters['bots/currentBotId'];
      const resources = store.getters['knowledgeBase/resources'](botId, resourceIds);

      const results = [];
      for (const resource of resources) {
        // Base the new list of intents on the existing one
        let newIntentList = resource.intents;

        // Build the new intent list iteratively
        for (const intentName of intentNames) {
          if (environments.live && environments.staging) newIntentList = ResourceConnectionUtil.disconnectFromLiveAndStaging(resource.intents, intentName, channelOpts);
          else if (environments.staging) newIntentList = ResourceConnectionUtil.disconnectStaging(resource.intents, intentName, channelOpts);
          else newIntentList = ResourceConnectionUtil.disconnectLive(resource.intents, intentName, channelOpts);
        }

        // Update backend
        results.push(
          RAGServiceV2.setAssignedIntents(
            botId,
            resource._id,
            newIntentList
          ).then(result => {
            if (result === null) {
              console.error("Failed to update intent list for resource", resource._id);
              return null;
            }

            return newIntentList;
          })
        );
      }

      // Overwrite instance for successful ones ...
      let i=-1;
      for await (const result of results) {
        i++;
        if (result === null) continue;
        resources[i].intents = result;
      }

      // ... and commit all to storage/make it reactive other places
      store.commit('knowledgeBase/setResources', { botId, resources });
    },
    /**
     * Disconnects all intents in every channel, for every environment.
     * A process that must be done before deleting a resource.
     * @param {string[]} resourceIds
     * @returns {Promise<void>}
     */
    async disconnectAllIntents(resourceIds) {
      const botId = store.getters['bots/currentBotId'];
      const results = [];

      // Disconnect in backend
      for (const resourceId of resourceIds) {
        results.push(
          RAGServiceV2.setAssignedIntents(
            botId,
            resourceId,
            ResourceConnectionUtil.disconnectEverything(
              store.getters['knowledgeBase/getResource'](botId, resourceId).intents
            )
          ).then(result => {
            if (result === null) {
              console.error("Failed to update intent list for resource", resourceId);
              return null;
            }
            return resourceId;
          })
        );
      }

      // Update the resource and storage
      for await (const idOrNull of results) {
        if (idOrNull === null) continue;
        const resource = store.getters['knowledgeBase/getResource'](botId, idOrNull);
        if (!resource) continue;
        resource.intents = [];
        store.commit('knowledgeBase/setResources', {botId, resources: [resource]});
      }
    }
  }
}
