var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.content
        ? _c(
            "v-timeline-item",
            {
              attrs: {
                "hide-dot": "",
                color: "grey lighten-3",
                "fill-dot": "",
              },
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        staticClass: "font-weight-medium ma-0",
                        attrs: {
                          outlined: "",
                          dense: "",
                          label: _vm.$t("botFormEdit.whatIsAsked"),
                        },
                        on: { input: _vm.update },
                        model: {
                          value: _vm.content.displayName,
                          callback: function ($$v) {
                            _vm.$set(_vm.content, "displayName", $$v)
                          },
                          expression: "content.displayName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.content
        ? _c("ActionList", {
            attrs: {
              actions: _vm.content.question.actions,
              channelType: _vm.channelType,
            },
            on: { "hook:mounted": _vm.updateName, onUpdate: _vm.update },
          })
        : _vm._e(),
      _vm.content
        ? _c(
            "v-timeline-item",
            {
              attrs: { color: "white" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "icon",
                    fn: function () {
                      return [
                        _c("v-icon", { attrs: { color: "success" } }, [
                          _vm._v("insert_drive_file"),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                922128254
              ),
            },
            [
              _c("span", { staticClass: "text-subtitle-1" }, [
                _vm._v(" Erlaubte Dateitypen "),
              ]),
              _c(
                "p",
                { staticClass: "text-subtitle-2" },
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.mimeTypes,
                      "item-text": "title",
                      "item-value": "mime",
                      multiple: "",
                      chips: "",
                      label: "Erlaubte Dateitypen",
                      hint: "Wählen Sie die Dateitypen aus, die der Benutzer hochladen darf.",
                      "persistent-hint": "",
                      outlined: "",
                    },
                    on: {
                      change: _vm.allowedTypesChanged,
                      "hook:mounted": _vm.initMimeTypes,
                    },
                    model: {
                      value: _vm.allowedTypes,
                      callback: function ($$v) {
                        _vm.allowedTypes = $$v
                      },
                      expression: "allowedTypes",
                    },
                  }),
                ],
                1
              ),
              _vm.isAdmin
                ? _c(
                    "v-row",
                    {
                      staticClass:
                        "ma-0 d-flex justify-start align-center moin-admin-box px-2",
                    },
                    [
                      _c(
                        "v-col",
                        [
                          _c("label", { staticClass: "mr-3" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "botFormEdit.fileUpload.maxFilesTemplate"
                                )
                              )
                            ),
                          ]),
                          _c("v-text-field", {
                            attrs: {
                              value: _vm.maxFileCount,
                              label: _vm.$t(
                                "botFormEdit.fileUpload.maxFilesLabel"
                              ),
                              type: "number",
                              step: "1",
                              min: _vm.fileCountLimits.min,
                              max: _vm.fileCountLimits.max,
                            },
                            on: { input: _vm.setMaxFileCount },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isAdmin && _vm.fileUploadConfigured
                ? _c(
                    "v-row",
                    {
                      staticClass:
                        "ma-0 mt-4 d-flex justify-start align-center moin-admin-box px-2",
                    },
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-switch", {
                            attrs: {
                              label: _vm.$t("botFormEdit.fileUpload.saveUrls"),
                              color: "green",
                              inset: "",
                              dense: "",
                              disabled: false,
                            },
                            on: { change: _vm.setSaveFileUrl },
                            model: {
                              value: _vm.saveFileUrl,
                              callback: function ($$v) {
                                _vm.saveFileUrl = $$v
                              },
                              expression: "saveFileUrl",
                            },
                          }),
                          _c("InfoBox", {
                            staticClass: "my-4",
                            attrs: {
                              text: _vm.$t(
                                "botFormEdit.fileUpload.saveUrlsInfo"
                              ),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isSkipAllowed || _vm.skipFileUploadOption
                ? _c(
                    "v-row",
                    {
                      staticClass:
                        "ma-0 mt-4 d-flex justify-start align-center moin-admin-box px-2",
                    },
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex align-center" },
                            [
                              _c("v-switch", {
                                attrs: {
                                  label: _vm.$t(
                                    "botFormEdit.fileUpload.skipOption"
                                  ),
                                  color: "green",
                                  inset: "",
                                  dense: "",
                                  disabled: !_vm.findLastTextStep().action,
                                },
                                on: { change: _vm.setSkipFileUploadOption },
                                model: {
                                  value: _vm.skipFileUploadOption,
                                  callback: function ($$v) {
                                    _vm.skipFileUploadOption = $$v
                                  },
                                  expression: "skipFileUploadOption",
                                },
                              }),
                              !_vm.findLastTextStep().action
                                ? _c("InfoHoverBox", {
                                    staticClass: "ml-3",
                                    attrs: {
                                      top: -80,
                                      left: -110,
                                      title: _vm.$t("common.warning"),
                                      body: _vm.$t(
                                        "botFormEdit.fileUpload.skipOptionInfoHintNoText"
                                      ),
                                      "main-icon": "mdi-information-outline",
                                      "bounce-animation": true,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm.skipFileUploadOption
                            ? _c("InfoBox", {
                                staticClass: "my-4",
                                attrs: {
                                  text: _vm.$t(
                                    "botFormEdit.fileUpload.skipOptionInfo"
                                  ),
                                },
                              })
                            : _vm._e(),
                          _vm.skipFileUploadOption
                            ? _c(
                                "div",
                                [
                                  _c("ActionTextInput", {
                                    staticClass: "flex-grow-1 mb-4",
                                    attrs: {
                                      label: "Button label",
                                      value: _vm.quickButtonSkipString,
                                      "read-only":
                                        !_vm.findLastTextStep().action,
                                    },
                                    on: {
                                      input: (v) =>
                                        _vm.updateRelatedSkipButton(v),
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.content
        ? _c(
            "v-timeline-item",
            {
              attrs: { color: "white" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "icon",
                    fn: function () {
                      return [
                        _c("v-icon", { attrs: { color: "error" } }, [
                          _vm._v("cancel"),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                2229607582
              ),
            },
            [
              _c("span", { staticClass: "text-subtitle-1" }, [
                _vm._v(" Datei Upload abgebrochen "),
              ]),
              _c("p", { staticClass: "text-subtitle-2" }, [
                _vm._v("Formular wird abgebrochen."),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }