var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "HubBox",
    {
      attrs: {
        title: _vm.$t("intents.details.relevance.title"),
        icon: "mdi-chart-timeline-variant-shimmer",
        boxId: _vm.boxId,
      },
      on: {
        onExpand: (isExpanded) => isExpanded && _vm.trackIt("hub-box-expanded"),
      },
    },
    [
      _c("IntentStatistic", {
        staticClass: "mb-4",
        attrs: {
          "progress-title": _vm.$t("intents.details.often.description"),
          "chart-title": _vm.$t("intents.details.often.title"),
          "chart-subline": _vm.$t("intents.details.often.subline"),
          loading: _vm.loading,
          channel: _vm.channel,
        },
        scopedSlots: _vm._u([
          {
            key: "progressBar",
            fn: function () {
              return [
                _c("v-progress-linear", {
                  attrs: {
                    value: _vm.progressValue,
                    "buffer-value": "100",
                    color:
                      _vm.loading || _vm.progressValue === 0
                        ? "grey lighten-2"
                        : "secondary darken-2",
                    height: "10",
                  },
                }),
                _c("small", { staticClass: "primary--text" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("intents.details.often.rare")) + " "
                  ),
                ]),
                _c("div", { staticClass: "float-right mr-1" }, [
                  _c("small", [
                    _vm._v(_vm._s(_vm.$t("intents.details.often.frequent"))),
                  ]),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "chart",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "d-flex justify-center" },
                  [
                    _vm.chart.series[0].data.length
                      ? _c("apexchart", {
                          staticClass: "flex-grow-1",
                          attrs: {
                            type: "bar",
                            options: _vm.chart.options,
                            series: _vm.chart.series,
                            height: "120px",
                          },
                        })
                      : !_vm.loading && _vm.progressValue === 0
                      ? _c(
                          "v-card-subtitle",
                          [
                            _c("v-icon", { attrs: { small: "", left: "" } }, [
                              _vm._v("warning"),
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("intents.details.often.nodata")) +
                                " "
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }