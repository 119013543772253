var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("HubBoxSimple", {
    attrs: { icon: "", title: "", fillHeight: "" },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "div",
              [
                _c("IconNumberTeaser", {
                  attrs: {
                    title: _vm.$t("stats.conversions"),
                    icon: "outlined_flag",
                    oldValue: _vm.sumOld,
                    newValue: _vm.sumNew,
                    loading: false,
                    noData: false,
                    compareDisabled: !_vm.dateRangeCompare
                      ? _vm.$t("dateRange.selectComparePeriod")
                      : "",
                    titleHeading: "",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "relative my-4 mx-n4" },
              [
                _c("v-divider"),
                _vm.isLoading
                  ? _c("v-progress-linear", {
                      staticStyle: {
                        position: "absolute",
                        top: "0",
                        left: "0",
                        width: "100%",
                      },
                      attrs: { indeterminate: "", color: "secondary darken-2" },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c("StatsChart", {
              attrs: {
                isLoading: _vm.isLoading,
                seriesData: _vm.seriesData,
                seriesDataCompare: _vm.seriesDataCompare,
                dateRange: _vm.dateRange,
                dateRangeCompare: _vm.dateRangeCompare,
                height: _vm.height,
                timeFormat: _vm.timeFormat,
                type: "line",
                opposite: "",
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }