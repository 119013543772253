<template>
  <DemoStep>
    <div>
      <div v-if="hasError">Ein Fehler ist aufgetreten.</div>
      <template v-else>
        <DemoQuestion
          v-for="item in questions"
          :key="item.id"
          :question="item"
          class="mb-2"
          @onToggle="toggleCheck(item)"
          @onAnswer="openAnswerDialog(item)"
        />
        <DemoBtns @onBack="back()" @onNext="next()" />
      </template>
      <DemoAnswerDialog :open="isAnswerOpen" @onClose="isAnswerOpen = false" :question="questionForDialog" />
    </div>
  </DemoStep>
</template>
<script>
import { mapGetters } from 'vuex';
import DemoStep from '@/components/demo/DemoStep.vue';
import DemoQuestion from '@/components/demo/components/DemoQuestion.vue';
import DemoBtns from '@/components/demo/components/DemoBtns.vue';
import { stepSlugs } from '@/components/demo/stepSlugs';
import DemoAnswerDialog from '@/components/demo/components/DemoAnswerDialog.vue';
import _ from 'lodash';

export default {
  name: 'DemoStepQuestions',
  components: { DemoStep, DemoQuestion, DemoBtns, DemoAnswerDialog },
  data() {
    return {
      questionsInitial: [],
      isAnswerOpen: false,
      questionForDialog: '',
    };
  },
  created() {
    this.questionsInitial = _.cloneDeep(this.questions);
  },
  computed: {
    ...mapGetters('demo', ['questions', 'isQuestionsDirty', 'hasError']),
  },
  methods: {
    toggleCheck(question) {
      const questions = [...this.questions];
      const index = questions.findIndex((item) => item.id === question.id);
      // Keep last checked
      const checkedItems = questions.filter((item) => item.checked);
      if (checkedItems.length === 1 && questions[index].checked) {
        return;
      }
      questions[index].checked = !questions[index].checked;

      this.$store.commit('demo/setValue', {
        key: 'questions',
        value: questions,
      });
    },
    back() {
      this.$store.commit('demo/setValue', {
        key: 'step',
        value: stepSlugs.stepCompany,
      });
    },
    next() {
      let dirty = this.isQuestionsDirty;
      if (
        !this.questionsInitial.length ||
        !_.isEqual(this.questions, this.questionsInitial)
      ) {
        dirty = true;
      }
      
      const language = this.$vuetify.lang.current;
      this.$store.dispatch('demo/handleStepQuestions', { dirty , language });
    },
    openAnswerDialog(item) {
      this.questionForDialog = item.text;
      this.isAnswerOpen = true;
    },
  },
};
</script>
