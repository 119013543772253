<template>
  <div>
    <v-menu v-model="searchMenu" :close-on-content-click="false">
      <template v-slot:activator="{ on: onMenu, attrs: attrsMenu }">
        <v-hover v-slot="{ hover }">
          <v-text-field
            v-show="!searchMenu"
            @click:prepend-inner="searchMenu = true"
            v-model="searchText"
            v-on="onMenu"
            readonly
            class="mt-6"
            single-line
            :label="$t('search.label')"
            outlined
            dense
            v-bind="attrsMenu"
            color="white"
            filled
            prepend-inner-icon="search"
          >
            <template v-slot:append>
              <span
                class="text-caption pt-1 grey--text text--lighten-1 hidden-md-and-down"
                v-if="hover && getOS === 'apple'"
              >
                command+p
              </span>
              <span
                class="text-caption pt-1 grey--text text--lighten-1 hidden-md-and-down"
                v-if="hover && getOS === 'windows'"
              >
                windows+p
              </span>
            </template>
          </v-text-field>
        </v-hover>
      </template>
      <v-row class="ma-0 pa-0">
        <v-col class="ma-0 pa-0">
          <v-card min-width="250px" max-width="320px" class="pt-0 mt-n5">
            <v-card-text class="mt-3 pt-1 pl-0">
              <v-text-field
                height="40px"
                hide-details
                solo
                flat
                single-line
                color="primary"
                prepend-inner-icon="search"
                v-model="searchText"
                autofocus
                @input="updateSearch"
              ></v-text-field>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-text class="text-center" v-if="!emptyQuery && noResults">
              {{ $t('search.empty') }}
            </v-card-text>
            <v-card-text
              class="text-center"
              v-if="emptyQuery && lastQueries.length === 0"
            >
              {{ $t('search.description') }}
            </v-card-text>
            <v-card-subtitle
              v-if="lastQueries.length > 0 && emptyQuery"
              class="pb-0 pt-1"
            >
              {{ $t('search.history') }}
            </v-card-subtitle>
            <v-list class="pt-0" v-if="lastQueries.length > 0 && emptyQuery">
              <template v-for="(item, index) in lastQueries">
                <v-hover v-slot:default="{ hover }" :key="index">
                  <v-list-item @click="callItem(item)">
                    <v-list-item-icon>
                      <v-icon small color="primary lighten-3">
                        {{ item.icon }}
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="text-truncate">
                      {{ item.displayName }}
                    </v-list-item-title>
                    <v-list-item-action>
                      <v-btn
                        @click.stop="removeItem(item)"
                        v-show="hover"
                        icon
                        tile
                        x-small
                      >
                        <v-icon x-small>close</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-hover>
              </template>
            </v-list>
            <v-card-subtitle v-if="intentResults.length > 0" class="pb-0 pt-1">
              {{ $t('search.topics') }}
            </v-card-subtitle>
            <v-list v-if="intentResults.length > 0">
              <v-list-item
                v-for="(item, index) in intentResults"
                :key="index"
                :disabled="!isAdmin && item.value.suggested"
                @click="callItem(item)"
              >
                <v-list-item-icon>
                  <v-icon small color="primary lighten-3">
                    {{ item.icon }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  {{
                    displayName(item.value.intent, $vuetify.lang.current) ||
                    item.displayName
                  }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
            <v-card-subtitle
              v-if="isAdmin && botResults.length > 0"
              class="pb-0 pt-1"
            >
              Gefundene Bots
            </v-card-subtitle>
            <v-list v-if="isAdmin && botResults.length > 0">
              <v-list-item
                v-for="(item, index) in botResults"
                :key="index"
                @click="callItem(item)"
              >
                <v-list-item-icon>
                  <v-icon small color="primary lighten-3">
                    {{ item.icon }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ item.displayName }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-menu>
  </div>
</template>
<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import OsUtil from '@/utils/os';

export default {
  name: 'SearchComponent',
  data() {
    return {
      searchMenu: false,
      searchResult: [],
      searchText: '',
      lastQueries: [],
      searching: false,
    };
  },
  created() {
    document.addEventListener('keydown', this.onKeyEvent);

    // Start indexing bots and intents in the background by just calling the getters
    setImmediate(() => {
      this.$store.getters['search/botIndex'];
      this.$store.getters['search/intentIndex'];
    });
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.onKeyEvent);
  },
  computed: {
    ...mapGetters('search', ['botResults', 'queries', 'intentResults']),
    ...mapGetters('bots', ['currentBotId']),
    ...mapGetters('auth', ['isAdmin']),
    ...mapGetters('intents', ['displayName']),
    noResults() {
      return (
        this.searchText &&
        this.botResults.length === 0 &&
        this.intentResults.length === 0 &&
        !this.searching
      );
    },
    emptyQuery() {
      return !this.searchText || this.searchText.trim().length === 0;
    },
    getOS() {
      return OsUtil.getOs();
    },
  },
  watch: {
    searchMenu(opened) {
      if (opened) {
        this.lastQueries = _.cloneDeep(this.queries);
      }
    },
  },
  methods: {
    onKeyEvent(event) {
      if (event.key === 'p' && event.metaKey) {
        event.preventDefault();
        this.openSearch();
      }
    },
    openSearch() {
      this.searchMenu = true;
    },
    async updateSearch(e) {
      this.searching = true;
      await this.$store.dispatch('search/search', { text: e?.trim() });
      this.searching = false;
    },
    removeItem(item) {
      this.$store.dispatch('search/removeQuery', item).then(() => {
        this.lastQueries = _.cloneDeep(this.queries);
      });
    },
    callItem(item) {
      if (this.isAdmin && item.value?.suggested) {
        this.$router.push({
          path: `/intent/${item.value.intent}/training`,
        });
        return;
      }

      if (item.type === 'bot') {
        this.$store.dispatch('bots/changeBot', {
          uniqueBotId: item.value.uniqueBotId,
          router: this.$router,
        });
      }

      if (item.type === 'intent') {
        if (
          this.$route.name !== 'intentsedit' ||
          this.$route.params.name !== item.value.intent
        ) {
          this.$router.push({
            name: 'intentsedit',
            params: { name: item.value.intent },
          });
        }
      }

      this.searchMenu = false;
      this.searchText = null;
      this.$store.dispatch('search/pick', item);
    },
  },
};
</script>
