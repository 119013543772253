var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-fade-transition",
    [
      _c(
        "v-card",
        { attrs: { outlined: "" } },
        [
          _c(
            "v-row",
            { staticClass: "pa-4" },
            [
              _c("v-col", [
                _c(
                  "div",
                  { staticClass: "pt-2 text-center" },
                  [
                    _c(
                      "v-avatar",
                      { attrs: { color: "primary lighten-1" } },
                      [
                        _c("v-icon", { attrs: { color: "white" } }, [
                          _vm._v("check"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "text-h6 text-center mt-4" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("intentNew.intentCreated.title")) + " "
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "text-body-2 primary--text text--lighten-4 text-center mt-4",
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("intentNew.intentCreated.body")) + " "
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "pa-4" },
            [
              _c(
                "v-col",
                { staticClass: "text-right" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "primary--text",
                      attrs: {
                        outlined: "",
                        color: "primary lighten-2",
                        block: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.closeSidebar()
                        },
                      },
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.$t("common.close")) + " "),
                      _c("v-icon", { attrs: { right: "" } }, [_vm._v("close")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "white--text",
                      attrs: {
                        block: "",
                        depressed: "",
                        color: "secondary darken-2",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.goToIntent()
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("intentNew.intentCreated.btnPrimaryLabel")
                          ) +
                          " "
                      ),
                      _c("v-icon", { attrs: { right: "" } }, [
                        _vm._v("mdi-folder-text"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }