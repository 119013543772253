<script>
import WidgetBotMessage from '@/components/widget/parts/WidgetBotMessage.vue';
import WidgetUserMessage from '@/components/widget/parts/WidgetUserMessage.vue';
import WidgetChatInput from '@/components/widget/parts/WidgetChatInput.vue';
import WidgetTitle from '@/components/widget/parts/WidgetTitle.vue';
import WidgetOperator from '@/components/widget/parts/WidgetOperator.vue';
import WidgetChatHistory from '@/components/widget/parts/WidgetChatHistory.vue';
import WidgetButtonMessage from '@/components/widget/parts/WidgetButtonMessage.vue';
import WidgetRatingBar from '@/components/widget/parts/WidgetRatingBar.vue';

export default {
  name: 'WidgetPreviewNew',
  components: { WidgetRatingBar, WidgetButtonMessage, WidgetChatHistory, WidgetOperator, WidgetTitle, WidgetChatInput, WidgetUserMessage, WidgetBotMessage },
  props: {
    'header': {
      type: String,
      default: '#CCCCCC'
    },
    'headerContrast': {
      type: String,
      default: 'light'
    },
    'message': {
      type: String,
      default: '#CCCCCC'
    },
    'userTxtColor': {
      type: String,
      default: '#ffffff'
    },
    'avatar': {
      type: String,
      default: 'https://media.botcast.ai/1595514610395_chatbot.png'
    },
    'headline': {
      type: String,
      default: 'Widget Title'
    },
    'name': {
      type: String,
      default: 'Max Mustermann'
    },
    'title': {
      type: String,
      default: 'Chatbot'
    },
    'inputPlaceholder': {
      type: String,
    },
    'rateLabel': {
      type: String,
    },

    // Hide parts of the widget
    'showTitle': {
      type: Boolean,
      default: true,
    },
    'showOperator': {
      type: Boolean,
      default: true,
    },
    'showRatingBar': {
      type: Boolean,
      default: true,
    },
    'showHistory': {
      type: Boolean,
      default: true,
    },
    'showInput': {
      type: Boolean,
      default: true,
    },
  }
};
</script>

<template>
  <div class="widget d-flex flex-column overflow-hidden" style="width: 461px">
    <!-- Top bar -->
    <slot v-if="showTitle" name="title">
      <WidgetTitle
        :headline="headline"
        :headerContrast="headerContrast"
        :headerColor="header"
      />
    </slot>

    <!-- Operator header -->
    <slot v-if="showOperator" name="operator">
      <WidgetOperator
        :avatar="avatar"
        :name="name"
        :title="title"
        :shadow="false"
      />
    </slot>

    <!-- Rating bar -->
    <slot v-if="showRatingBar" name="rating-bar">
      <WidgetRatingBar :rateLabel="rateLabel" />
    </slot>

    <!-- Chat history -->
    <slot v-if="showHistory" name="history">
      <WidgetChatHistory style="font-size: 100%;">
        <slot name="messages">
          <WidgetBotMessage arrow text="Hallo, ich bin hier um zu helfen. Was kann ich für dich tun?" />
          <WidgetUserMessage
            text="Wo bekomme ich Informationen über eure neuesten Produkte?"
            :bgColor="message"
            :txtColor="userTxtColor"
          />
          <WidgetBotMessage :avatar="avatar" arrow text="Schau am besten mal hier in unseren Produktfinder:" />
          <WidgetButtonMessage label="Produktfinder" />
        </slot>
      </WidgetChatHistory>
    </slot>

    <!-- Input area -->
    <slot v-if="showInput" name="input">
      <WidgetChatInput
        :qr="['abbrechen', 'Hilfe']"
        :placeholder="inputPlaceholder"
      />
    </slot>
  </div>
</template>

<style scoped lang="scss">
.widget {
  background-color: #e2e2e2;

  /* From the widget itself */
  font-size: 62.5%;
  border-radius: 12px;
}

</style>