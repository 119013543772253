var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "moin-checkbox d-flex items-center justify-center",
      class: [
        _vm.value && "moin-checkbox--active",
        _vm.error && "moin-checkbox--error",
      ],
      on: {
        click: function ($event) {
          return _vm.$emit("onClick")
        },
      },
    },
    [
      _vm.value
        ? _c("v-icon", { attrs: { color: "secondary" } }, [
            _vm._v("mdi-check-bold"),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }