<template>
  <HubBox
    v-if="!intent.suggested"
    :title="'Status'"
    :icon="
      intent.status === statusTypes.IN_REVIEW
        ? statusIcons.inReview.icon
        : 'mdi-play-circle'
    "
    boxId="'intents-status'"
    @onExpand="(isExpanded) => isExpanded && trackIt('hub-box-expanded')"
  >
    <div class="text-body-2">
      <span v-if="currentChannels.length > 1">
        {{ $t('intentStatus.infoTextChannels') }}
      </span>
      <span v-else>
        {{ $t('intentStatus.infoTextChannel') }}
      </span>
    </div>

    <div v-if="intent.status === statusTypes.IN_REVIEW" class="my-8">
      <span>{{ $t(`intentStatus.inReview.label2`) }}</span>
    </div>

    <div v-else class="my-8">
      <div class="text-body-1 mb-2" v-if="statusList.length > 1">
        {{ $t('common.channels') }}
      </div>
      <div class="text-body-1 mb-2" v-else>Status</div>
      <!-- 1 channel -->
      <div v-if="statusList.length === 1" class="d-flex mb-4">
        <v-icon
          class="mr-2"
          :color="statusIcons[statusList[0].status].iconColor"
        >
          {{ statusIcons[statusList[0].status].icon }}
        </v-icon>
        <span>{{ $t(`intentStatus.${statusList[0].status}.label2`) }}</span>
      </div>
      <!-- more channels -->
      <template v-else>
        <div v-for="(item, index) in statusList" :key="index">
          <div class="d-flex mb-4">
            <v-tooltip bottom color="primary lighten-3">
              <template
                v-slot:activator="{ on: onTooltip, attrs: attrsTooltip }"
              >
                <v-icon
                  class="mr-2"
                  v-on="onTooltip"
                  v-bind="attrsTooltip"
                  :color="statusIcons[item.status].iconColor"
                >
                  {{ statusIcons[item.status].icon }}
                </v-icon>
              </template>
              <span>{{ $t(`intentStatus.${item.status}.label2`) }}</span>
            </v-tooltip>
            <div class="text-body-2 mb-1">{{ item.displayName }}</div>
          </div>
        </div>
      </template>
    </div>

    <div v-if="isEditBtnShown" class="mt-6">
      <v-btn outlined color="primary lighten-3" @click="goToEditSelected" data-cy="intent-status-edit-btn">
        {{ $t('common.edit') }}
        <v-icon right>edit</v-icon>
      </v-btn>
    </div>
  </HubBox>
</template>
<script>
import { mapGetters } from 'vuex';
import HubBox from '@/components/hub/HubBox.vue';
import { ProductService } from '@/services/product';
import { statusIcons } from '@/utils/intent';
import IntentUtil from '@/utils/intent';
import { TrackingService } from '@/services/tracking';

export default {
  name: 'IntentStatus',
  components: {
    HubBox,
  },
  props: {
    intent: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      statusIcons,
      statusList: [],
      statusTypes: IntentUtil.statusTypes,
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapGetters('bots', ['currentBotId', 'currentChannels']),
    isEditBtnShown() {
      return this.$route.name !== 'intent-view';
    },
  },
  methods: {
    async init() {
      const result = await ProductService.getIntentDetails(
        this.currentBotId,
        this.intent.template,
        this.intent.intent
      );

      if (!result.state?.channels) return;

      const channelIdList = Object.keys(result.state?.channels);

      channelIdList.forEach((channelId) => {
        const channel = this.currentChannels.find(
          (c) => c.channelId === channelId
        );

        if (channel.blocked) return;
        const { status, forward } = IntentUtil.getStatus(
          result.state?.channels,
          channelId
        );

        const item = {
          displayName: channel.displayName,
          channelId: channel.channelId,
          status,
        };

        if (forward) {
          item.forward = forward;
        }

        this.statusList.push(item);
      });
    },
    goToEditSelected() {
      if (this.intent) {
        this.$router.push({
          name: 'intentsedit',
          params: { name: this.intent.intent },
        });
      }
    },
    trackIt(type) {
      TrackingService.trackIntentsView({
        botId: this.currentBotId,
        type,
        source: 'IntentStatus',
      });
    },
  },
};
</script>
