var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fill-height" },
    [
      _c(
        "v-card",
        { staticClass: "fill-height pa-4", attrs: { outlined: "" } },
        [
          _c("h2", { staticClass: "text-h6" }, [_vm._v("Sprachen")]),
          _c("v-divider", { staticClass: "my-2" }),
          _vm._l(_vm.languages, function (lang) {
            return _c(
              "div",
              { key: lang, staticClass: "text-body-2 mb-2" },
              [
                _c(
                  "v-icon",
                  { attrs: { small: "", color: "primary lighten-4 mr-2" } },
                  [_vm._v("mdi-web")]
                ),
                _vm._v(" " + _vm._s(_vm.$t(`languages.${lang}`)) + " "),
              ],
              1
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }