var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card-text",
    { staticClass: "pa-0" },
    [
      _vm.detectedIntent && _vm.isSame && !_vm.isSmalltalk
        ? _c(
            "v-row",
            [
              _vm.detectedIntent
                ? _c(
                    "v-col",
                    { staticClass: "text-subtitle-2 mb-2" },
                    [
                      _c("v-icon", { attrs: { small: "", left: "" } }, [
                        _vm._v("school"),
                      ]),
                      _vm._v(" Erkanntes Thema: "),
                      _c("strong", [_vm._v(_vm._s(_vm.detectedIntent))]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      !_vm.isSame && !_vm.isSmalltalk && _vm.detectedIntent
        ? _c(
            "v-row",
            [
              _vm.detectedIntent
                ? _c(
                    "v-col",
                    {
                      staticClass:
                        "pt-0 pb-0 text-subtitle-2 mb-2 secondary--text text--darken-2",
                      attrs: { cols: "12" },
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: {
                            color: "secondary darken-2",
                            small: "",
                            left: "",
                          },
                        },
                        [_vm._v("school")]
                      ),
                      _vm._v(" Veränderte Zusweisung: "),
                      _c("strong", [_vm._v(_vm._s(_vm.detectedIntent))]),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-col",
                {
                  staticClass:
                    "pt-0 pb-0 text-subtitle-2 mb-2 secondary--text text--darken-2",
                },
                [
                  _c(
                    "strong",
                    [
                      _vm._v("Richtig? "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-2",
                          attrs: {
                            color: "secondary darken-2",
                            icon: "",
                            tile: "",
                          },
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("check"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "secondary darken-2",
                            icon: "",
                            tile: "",
                          },
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("highlight_off"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }