var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "mb-2" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t("demo.stepsFrom", {
                current: _vm.currentStep + 1,
                total: _vm.stepsAmount,
              })
            ) +
            " "
        ),
      ]),
      _c(
        "div",
        { staticClass: "d-flex" },
        _vm._l(_vm.steps, function (item) {
          return _c("div", {
            key: item.index,
            staticClass: "stepper-item mr-2",
            class: [
              item.active && "stepper-item--active",
              item.done && "stepper-item--done",
            ],
          })
        }),
        0
      ),
      _c("DemoCompanyUrl"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }