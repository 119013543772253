var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.getSamples && _vm.getSamples.length > 0
    ? _c(
        "HubBox",
        {
          attrs: {
            icon: "chat_bubble_outline",
            title: _vm.$t("intents.samples"),
            boxId: _vm.boxId,
            expandedInitial: "",
          },
          on: {
            onExpand: (isExpanded) =>
              isExpanded && _vm.trackIt("open-intent-samples"),
          },
        },
        [
          _c(
            "div",
            { staticClass: "pb-4" },
            _vm._l(_vm.getSamples, function (sample, index) {
              return _c(
                "v-list-item",
                {
                  key: index,
                  staticClass: "ref-item",
                  attrs: { selectable: "" },
                },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "rounded-lg elevation-0 ma-1",
                      attrs: { color: "grey lighten-3" },
                    },
                    [
                      _c("v-card-text", { staticClass: "pa-2 primary--text" }, [
                        _vm._v(" " + _vm._s(sample) + " "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
          _vm.isAutoTranslated(_vm.intent.intent, _vm.user.language)
            ? _c("div", { staticClass: "text-body-2 grey--text pl-6" }, [
                _vm._v(" " + _vm._s(_vm.$t("common.autoTranslated")) + " "),
              ])
            : _vm._e(),
          _vm.showInsightsButton
            ? _c(
                "v-card",
                { staticClass: "pa-4", attrs: { flat: "" } },
                [
                  _c("v-divider", { staticClass: "mb-2" }),
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-body-2 primary--text text--lighten-2 mb-3",
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("intents.insightsDescription")) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        outlined: "",
                        color: "primary lighten-3",
                        to: `/conversations?intent=${_vm.intent.intent}`,
                      },
                    },
                    [
                      _vm._v("Insights "),
                      _c("v-icon", { attrs: { right: "" } }, [
                        _vm._v("mdi-forum-outline"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }