var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("ActionRowStart", {
        attrs: {
          blockType: _vm.blockTypes.START_LOADING,
          options: _vm.options,
        },
      }),
      _vm.showTextRow
        ? _c("ActionRow", {
            scopedSlots: _vm._u(
              [
                {
                  key: "line",
                  fn: function () {
                    return [
                      _c("ActionLine", {
                        attrs: {
                          blockType: _vm.blockTypes.content_loading,
                          options: _vm.options,
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "pb-4 pr-2" },
                        [
                          _c("v-skeleton-loader", {
                            staticClass: "relative",
                            attrs: {
                              type: "image",
                              width: "100%",
                              height: 112,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1589670806
            ),
          })
        : _vm._e(),
      _vm.showUserInput
        ? _c("ActionUserRow", {
            attrs: { loading: true, noLine: !_vm.showRowEndLoading },
          })
        : _vm._e(),
      _vm.showRowEndLoading ? _c("ActionRowEndLoading") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }