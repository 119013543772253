<script>
export default {
  name: "WidgetUserMessage",
  props: {
    "text": {
      type: String,
      required: true
    },
    "arrow": {
      type: Boolean,
      default: true,
    },
    "bgColor": {
      type: String,
      default: "#103461",
    },
    "txtColor": {
      type: String,
      default: "#FFFFFF",
    }
  }
}
</script>

<template>
  <div
    class="user-message-wrapper"
    :class="{'with-arrow': arrow}"
    :style="{'--widget-message-bg-color': bgColor}"
  >
    <span class="user-message" :style="`color: ${txtColor}`">
      {{ text }}
    </span>
  </div>
</template>

<style scoped lang="scss">
.user-message-wrapper {
  /* From the widget itself */
  display: flex;
  width: 100%;
  padding-left: 85px;
  position: relative;
  justify-content: end;

  margin-top: 14px;
  margin-bottom: calc(12px + 12px);
}
.with-arrow:after {
  /* From the widget itself */
  position: absolute;
  content: "";
  display: block;
  bottom: -10px;
  right: 0;
  width: 10px;
  height: 10px;
  background-color: var(--widget-message-bg-color);
  clip-path: polygon(100% 0, 0 0, 100% 80%);
}
.user-message {
  line-height: 1.25em;

  /* From the widget itself */
  margin-left: 15px;
  display: inline-flex;
  border-radius: 6px;
  color: #fff;
  background-color: var(--widget-message-bg-color);
  padding: 10px 18px 10px 12px;
  min-width: 30px;
  font-size: 1.5em;
  font-weight: 400;
}
.with-arrow .user-message {
  border-radius: 6px 6px 0 6px;
}
</style>