var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pa-6" },
    [
      _c("h2", { staticClass: "text-h6 primary--text mt-2 mb-4" }, [
        _vm._v(_vm._s(_vm.$t("stats.title"))),
      ]),
      _c("StatsSidebarActiveFilter", { staticClass: "mb-4" }),
      _c("StatsSidebarReportsList", { staticClass: "mb-4" }),
      _c("StatsSidebarExport", { staticClass: "mb-4" }),
      _c("StatsSidebarInfoText", { staticClass: "mb-4" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }