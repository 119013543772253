<template>
  <div v-if="botHasLivechat" class="livechat d-flex">
    <div
      v-if="agentForOrganizationStatus === agentForOrganizationStatusTypes.isAgentForCurrentOrganization"
      class="livechat d-flex"
    >
      <div
        v-if="agentStatus === statusTypes.online"
        class="white livechat d-flex"
        :class="{ 'livechat--md': $vuetify.breakpoint.smAndUp }"
      >
        <template>
          <!-- Livechat Agent view -->
          <LivechatLists
            class="livechat-lists full-height moin-fade-in"
            :class="[
              $vuetify.breakpoint.smAndDown
                ? 'livechat-lists--sm'
                : 'livechat-lists--md',
              $vuetify.breakpoint.smAndDown && isChatOpen
                ? 'livechat-lists--sm-chat-open'
                : '',
            ]"
          />
          <div class="full-height">
            <v-divider vertical />
          </div>
          <div
            class="livechat-main full-height"
            :class="[
              $vuetify.breakpoint.smAndDown
                ? 'livechat-main--sm'
                : 'livechat-main--md',
              $vuetify.breakpoint.smAndDown && isChatOpen
                ? 'livechat-main--sm-chat-open'
                : '',
            ]"
          >
            <LivechatMain />
          </div>
        </template>
        <LivechatDialogChangeTab
          :open="isDialogChangeLoginOpen"
          @onClose="isDialogChangeLoginOpen = false"
        />
      </div>
      <!-- Another tab is used for the chat -->
      <LivechatUsingOtherTab
        v-else-if="agentStatus === statusTypes.otherTab"
        @useThisTab="goOnline"
      />
      <!-- You are an agent, but you're offline -->
      <LivechatAgentOfflineView
        v-else
        :agents="organization?.agents"
        :can-manage-agents="isOwner || isAdmin"
        @goOnline="goOnline"
      />
    </div>
    <!-- You are agent for another org. -->
    <LivechatAgentWrongOrgView
      v-else-if="agentForOrganizationStatus === agentForOrganizationStatusTypes.isAgentForOtherOrganization"
      :can-manage-agents="isOwner || isAdmin"
    />
    <!-- Livechat enabled, but there are no agents assigned in this org. -->
    <LivechatNoAgentsSetUpView
      v-else-if="!organization?.agents?.length"
      :can-manage-agents="isOwner || isAdmin"
    />
    <!-- You are not an agent -->
    <LivechatNotAnAgent
      v-else
      :can-manage-agents="isOwner || isAdmin"
    />
  </div>
  <SettingsLivechatInactive v-else />
</template>
<script>
import { mapGetters } from 'vuex';
import LivechatLists from '@/components/livechat/LivechatLists.vue';
import LivechatMain from '@/components/livechat/LivechatMain.vue';
import LivechatDialogChangeTab from '@/components/livechat/LivechatDialogChangeTab.vue';
import { statusTypes, agentForOrganizationStatusTypes } from '@/utils/livechat';
import LivechatAgentOfflineView from '@/components/livechat/special-views/LivechatAgentOfflineView.vue';
import LivechatAgentWrongOrgView from '@/components/livechat/special-views/LivechatAgentWrongOrgView.vue';
import LivechatNoAgentsSetUpView from '@/components/livechat/special-views/LivechatNoAgentsSetUpView.vue';
import SettingsLivechatInactive from '@/components/settingsLivechat/SettingsLivechatInactive.vue';
import LivechatNotAnAgent from '@/components/livechat/special-views/LivechatNotAnAgentView.vue';
import LivechatUsingOtherTab from '@/components/livechat/special-views/LivechatUsingOtherTab.vue';

export default {
  name: 'Livechat',
  components: {
    LivechatUsingOtherTab,
    LivechatNotAnAgent,
    SettingsLivechatInactive,
    LivechatNoAgentsSetUpView,
    LivechatAgentWrongOrgView,
    LivechatAgentOfflineView,
    LivechatLists,
    LivechatMain,
    LivechatDialogChangeTab,
  },
  data() {
    return {
      isInitialized: false,
      selectedItemId: null,
      updateTicker: null,
      isDialogChangeLoginOpen: false,
      statusTypes,
    };
  },
  computed: {
    ...mapGetters('bots', ['currentBotId']),
    ...mapGetters('livechat', [
      'botHasLivechat',
      'isChatOpen',
      'isCurrentTabActive',
      'activeChat',
      'agentStatus',
      'agentForOrganizationStatus',
      'loadChats',
    ]),
    ...mapGetters('livechatManagement', ['organization']),
    ...mapGetters('auth', ['isAdmin', 'isOwner']),
    agentForOrganizationStatusTypes() {
      return agentForOrganizationStatusTypes;
    },
  },
  async created() {
    // TODO
    await this.$store.dispatch('livechat/setIsLivechatVisible', true);
    await this.init();
  },
  beforeDestroy() {
    this.clearLivechat();
  },
  methods: {
    async init() {
      // let whileCount = 50;
      // while (!this.initialized && whileCount > 0) {
      //   await new Promise((resolve) => setTimeout(resolve, 200));
      //   whileCount -= 1;
      // }

      // if ((!this.botHasLivechat || !this.agent) && (this.isAdmin || this.isOwner)) {
      //   this.$router.push({ path: '/settings/livechat' });
      // }

      // this.updateTicker = setInterval(() => {
      //   this.$store.commit('livechat/setValue', {
      //     key: 'timeNow',
      //     value: new Date(),
      //   });
      // }, 60000);

      // document.addEventListener('visibilitychange', this.checkTabId);

      // this.checkTabId();
      // await new Promise((resolve) => setTimeout(resolve, 200));
      // this.isInitialized = true;

      // Keep up to date every time you land on this page
      await this.$store.dispatch(
        'livechatManagement/init',
        this.currentBotId
      );
    },
    async checkTabId() {
      // const goodTab = await this.$store.dispatch('livechat/checkIsCorrectTab');
      if (!this.isCurrentTabActive) {
        this.isDialogChangeLoginOpen = true;
      }
    },
    clearLivechat() {
      clearInterval(this.updateTicker);
      this.$store.dispatch('livechat/setIsLivechatVisible', false);
    },
    goOnline() {
      return this.$store.dispatch('livechat/goOnline');
    },
  },
};
</script>
<style scoped lang="scss">
.livechat {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  overflow: hidden;
}

.livechat--md {
  border-radius: $spacer;
}
.livechat-main,
.livechat-lists {
  left: 0;
  max-width: 100%;
}

// mobile
.livechat-lists--sm {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: left 0.3s;
}

// mobile
.livechat-main--sm {
  width: 100%;
  position: absolute;
  top: 0;
  left: 100%;
}

.livechat-lists--sm-chat-open {
  left: -100%;
}

// mobile
.livechat-main--sm-chat-open {
  left: 0;
  transition: left 0.3s;
}

.livechat-lists--md {
  width: 380px;
  position: relative;
  flex-shrink: 0;
}

.livechat-main--md {
  flex-grow: 1;
  width: auto;
  position: relative;
}
</style>
