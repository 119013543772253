<template>
  <div
    v-if="shouldBeVisible"
    class="moin-fresh mb-6 text-center pa-2 text-subtitle-1"
    :class="stageBarClasses"
  >
    {{ $t(`welcome.topbar.${currentBotStage}.title`) }}
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { stageTypes } from '@/store/modules/welcome';

export default {
  name: 'WelcomeHeaderBar',
  data() {
    return {
      contact: null,
    };
  },
  computed: {
    ...mapGetters('bots', [
      'currentBotContactId',
      'currentLicence',
      'currentBotStage',
    ]),
    shouldBeVisible() {
      return [stageTypes.DEMO, stageTypes.ONBOARDING].includes(this.currentBotStage);
    },
    stageBarClasses() {
      if (!this.currentBotStage) return 'grey lighten-2 primary--text';

      switch (this.currentBotStage) {
        case stageTypes.DEMO:
          return 'grey lighten-2 primary--text';
        case stageTypes.ONBOARDING:
          return 'primary lighten-3 white--text';
        case stageTypes.LIVE:
          return 'secondary lighten-1 white--text';
        case stageTypes.UNDEFINED:
          return 'grey lighten-2 primary--text';
        default:
          return 'grey lighten-2 primary--text';
      }
    },
  },
};
</script>
