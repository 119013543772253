// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/fonts/JosefinSans-VariableFont_wght.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("@/assets/fonts/Lato-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("@/assets/fonts/silka-semibold-webfont.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: \"Josefin Sans\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n@font-face {\n  font-family: \"Lato\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n@font-face {\n  font-family: \"silkaHeading\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n.unstyled[data-v-04502888] {\n  background: none;\n  border: none;\n  padding: 0.5em 0.005em;\n  margin: 0;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n}\n.knw_widget_ratingbar[data-v-04502888] {\n  background: white;\n  border-top: 1px solid #e0e0e0;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  gap: 1em;\n  -ms-flex-negative: 1;\n      flex-shrink: 1;\n  min-height: 0;\n  z-index: 1;\n  width: 100%;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  padding: 0;\n  border-radius: 0 0 1em 1em;\n  color: #222C50;\n  font-size: 1.5em;\n  -webkit-box-shadow: 0 10px 20px -10px rgba(50, 50, 50, 0.1);\n          box-shadow: 0 10px 20px -10px rgba(50, 50, 50, 0.1);\n}\n.knw_widget_rating_stars[data-v-04502888] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n}\n.knw_widget_rating_stars svg[data-v-04502888] {\n  fill: #A9A9A9;\n}\n.knw_widget_rating_stars span[data-filled=true] svg[data-v-04502888] {\n  fill: #FF7555;\n}\n.slide-fade-enter-active[data-v-04502888] {\n  -webkit-transition: all 0.3s ease-out;\n  transition: all 0.3s ease-out;\n}\n.slide-fade-leave-active[data-v-04502888] {\n  -webkit-transition: all 0.4s ease-in;\n  transition: all 0.4s ease-in;\n}\n.slide-fade-leave-to[data-v-04502888],\n.slide-fade-enter-from[data-v-04502888] {\n  opacity: 0;\n  height: 0px;\n}\n.slide-fade-leave-from[data-v-04502888],\n.slide-fade-enter-to[data-v-04502888] {\n  opacity: 1;\n  height: 32px;\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
