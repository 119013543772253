var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.localIntent
    ? _c(
        "div",
        [
          _c(
            "HubBox",
            {
              attrs: {
                title: "Relations",
                boxId: "intents-relations",
                isLoading: _vm.isLoading,
                adminOnly: "",
              },
            },
            [
              _c("div", { staticClass: "text-body-2 mb-4" }, [
                _vm._v(" Hier kannst du verwandte Themen einstellen. "),
              ]),
              _c("div", [
                _c(
                  "div",
                  { staticClass: "mb-4" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "primary--text text--lighten-3 mb-1 text-body-2",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.labels[_vm.relationTypes.SIMILAR]) +
                            " "
                        ),
                      ]
                    ),
                    _vm.selectedSimilarsInitial.length
                      ? _vm._l(
                          _vm.selectedSimilarsInitial,
                          function (relation) {
                            return _c(
                              "span",
                              { key: `similar-${relation.intent}` },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: `/intent/${relation.intent}/training`,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(relation.displayName) + " "
                                    ),
                                  ]
                                ),
                                _vm._v(" ,   "),
                              ]
                            )
                          }
                        )
                      : _c("span", [_vm._v("–")]),
                  ],
                  2
                ),
                _c(
                  "div",
                  { staticClass: "mb-4" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "primary--text text--lighten-3 mb-1 text-body-2",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.labels[_vm.relationTypes.GENERALIZATION]
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _vm.selectedGeneralizationsInitial.length
                      ? _vm._l(
                          _vm.selectedGeneralizationsInitial,
                          function (relation) {
                            return _c(
                              "span",
                              { key: `generalization-${relation.intent}` },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: `/intent/${relation.intent}/training`,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(relation.displayName) + " "
                                    ),
                                  ]
                                ),
                                _vm._v(" ,   "),
                              ]
                            )
                          }
                        )
                      : _c("span", [_vm._v("–")]),
                  ],
                  2
                ),
                _c("div", { staticClass: "mb-4" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "primary--text text--lighten-3 mb-1 text-body-2",
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.labels[_vm.relationTypes.SPECIAL_CASE]) +
                          " "
                      ),
                    ]
                  ),
                  _vm.selectedSpecialCaseInitial
                    ? _c(
                        "a",
                        {
                          attrs: {
                            href: `/intent/${
                              _vm.getIntentByIntent(
                                _vm.selectedSpecialCaseInitial
                              ).intent
                            }`,
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.getIntentByIntent(
                                  _vm.selectedSpecialCaseInitial
                                ).displayName
                              ) +
                              " "
                          ),
                        ]
                      )
                    : _c("span", [_vm._v("–")]),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "mt-6" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { outlined: "", color: "primary lighten-3" },
                      on: {
                        click: function ($event) {
                          _vm.isDialogOpen = true
                        },
                      },
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.$t("common.edit")) + " "),
                      _c("v-icon", { attrs: { right: "" } }, [_vm._v("edit")]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "HubDialog",
            {
              attrs: {
                title: `Relations für '${_vm.localIntent.displayName}'`,
                "primary-label": _vm.$t("common.save"),
                "primary-icon": "save",
                "secondary-label": _vm.$t("common.cancel"),
                "secondary-icon": "close",
                modal: "",
                disabled: _vm.isLoading,
              },
              on: { onPrimary: _vm.saveEdit, onSecondary: _vm.cancelEdit },
              model: {
                value: _vm.isDialogOpen,
                callback: function ($$v) {
                  _vm.isDialogOpen = $$v
                },
                expression: "isDialogOpen",
              },
            },
            [
              _c("div", { staticClass: "mb-4 text-body-2" }, [
                _vm._v(" Auswahl muss gespeichert werden. "),
              ]),
              _c(
                "div",
                { staticClass: "mb-4" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "primary--text text--lighten-3 mb-1 text-body-2",
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.labels[_vm.relationTypes.SIMILAR]) +
                          " "
                      ),
                    ]
                  ),
                  _c("v-combobox", {
                    attrs: {
                      outlined: "",
                      "hide-details": "",
                      dense: "",
                      items: _vm.intentsFilteredSimilar,
                      "item-text": "displayName",
                      multiple: "",
                    },
                    model: {
                      value: _vm.selectedSimilars,
                      callback: function ($$v) {
                        _vm.selectedSimilars = $$v
                      },
                      expression: "selectedSimilars",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mb-4" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "primary--text text--lighten-3 mb-1 text-body-2",
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.labels[_vm.relationTypes.GENERALIZATION]) +
                          " "
                      ),
                    ]
                  ),
                  _c("v-combobox", {
                    attrs: {
                      outlined: "",
                      "hide-details": "",
                      dense: "",
                      items: _vm.intentsFilteredGeneralization,
                      "item-text": "displayName",
                      multiple: "",
                    },
                    model: {
                      value: _vm.selectedGeneralizations,
                      callback: function ($$v) {
                        _vm.selectedGeneralizations = $$v
                      },
                      expression: "selectedGeneralizations",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mb-4" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "primary--text text--lighten-3 mb-1 text-body-2",
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.labels[_vm.relationTypes.SPECIAL_CASE]) +
                          " "
                      ),
                    ]
                  ),
                  _c("v-select", {
                    attrs: {
                      items: _vm.intentsFilteredSpecialCase,
                      value: _vm.selectedSpecialCase,
                      "item-text": "displayName",
                      "item-value": "intent",
                      outlined: "",
                      dense: "",
                      "hide-details": "",
                    },
                    on: {
                      change: (intent) => (_vm.selectedSpecialCase = intent),
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }