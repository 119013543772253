<template>
  <div class="d-flex gap-2 justify-end">
    <v-btn v-if="isArchiveQuotaEnabled && isArchiveActive"
      color="primary"
      depressed
      small
      @click="isArchiveDialogOpen = true"
      :disabled="!isArchiveActive"
    >
      <span>{{ $t('livechat.chats.closeAndArchiveChatButton') }}</span>
      <!-- <v-icon>mdi-archive-outline</v-icon> -->
    </v-btn>

    <v-btn color="primary" depressed small @click="isDialogOpen = true">
      {{ $t('livechat.chats.closeChatButton') }}
    </v-btn>

    <HubDialog
      v-model="isDialogOpen"
      :title="'Diesen Chat wirklich schließen?'"
      :confirmText="'Ja, schließen'"
      @onPrimary="closeChat()"
      @onSecondary="isDialogOpen = false"
    >
      <p>{{ $t('livechat.chats.closeChatDialogBody') }}</p>
    </HubDialog>

    <HubDialog
      v-model="isArchiveDialogOpen"
      :title="'Diesen Chat schließen?'"
      @onPrimary="closeChatAndArchive()"
      @onSecondary="isArchiveDialogOpen = false"
    >
      <p>{{ 'Dieser Chat wird geschlossen und archiviert' }}</p>
    </HubDialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import HubDialog from '@/components/hub/HubDialog.vue';

export default {
  name: 'LivechatCloseChat',
  components: {
    HubDialog,
  },
  props: {
    chat: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isArchiveQuotaEnabled: false,
      isArchiveActive: true,
      isArchiveDialogOpen: false,
      isDialogOpen: false,
    };
  },
  computed: {
    ...mapGetters('bots', ['currentBotId', 'getBotSettings']),
  },
  methods: {
    async closeChatAndArchive() {
      if (!this.isArchiveQuotaEnabled || !this.isArchiveActive) {
        console.error('Archive quota is not enabled or archive logic is not active');
        return;
      }

      const result = await this.$store.dispatch('livechat/sendEmailArchive', {
        uniqueBotId: this.chat.uniqueBotId,
        uniqueUserId: this.chat.uniqueUserId,
      });

      const { success, error } = result || {};
      if (error) {
        console.error('Error while sending email archive. Error:', error);
        return;
      }

      if (success) await this.$store.dispatch('livechat/closeChat', this.chat);
    },
    closeChat() {
      this.$store.dispatch('livechat/closeChat', this.chat);
    },
    async init() {
      const { archive, quota } = await this.getBotSettings(this.currentBotId) || {};
      this.isArchiveQuotaEnabled = quota?.archive ?? false;
      this.isArchiveActive = archive?.active ?? false;
    },
  },
  created() {
    this.init();
  },
};
</script>
