var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "hub-frame",
      class: [_vm.isFooterActive && "hub-frame--show-footer"],
    },
    [
      _c("div", { staticClass: "hub-frame-wrapper" }, [
        _c(
          "div",
          { staticClass: "hub-frame-content white relative" },
          [
            _vm.isLoading
              ? _c("v-progress-linear", {
                  staticClass: "hub-frame-progress",
                  attrs: {
                    indeterminate: "",
                    absolute: "",
                    color: "secondary darken-2",
                  },
                })
              : _vm._e(),
            _vm.$slots.title || _vm.$slots.actions
              ? [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "intersect",
                          rawName: "v-intersect",
                          value: _vm.onHeaderIntersect,
                          expression: "onHeaderIntersect",
                        },
                      ],
                      staticClass: "pa-4 align-center d-flex",
                      class: [
                        _vm.$vuetify.breakpoint.xs &&
                          "flex-column flex-column-reverse",
                        _vm.$vuetify.breakpoint.smAndUp &&
                          "justify-space-between",
                      ],
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flex-grow-1 hub-frame-full-width" },
                        [
                          _c(
                            "span",
                            { class: _vm.isAdminOnly && "admin--text" },
                            [_vm._t("title")],
                            2
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "text-right flex-grow-1 hub-frame-full-width",
                        },
                        [_vm._t("actions")],
                        2
                      ),
                    ]
                  ),
                  _c("v-divider", { staticClass: "grey lighten-4" }),
                ]
              : _vm._e(),
            _c("div", { staticClass: "pa-4" }, [_vm._t("content")], 2),
          ],
          2
        ),
      ]),
      _c("v-fade-transition", [
        _vm.isFooterActive
          ? _c("div", { staticClass: "hub-frame-footer" }, [
              _c(
                "div",
                { staticClass: "white" },
                [
                  _c("v-divider", { staticClass: "grey lighten-4" }),
                  _c(
                    "div",
                    { staticClass: "pa-4 d-flex justify-end" },
                    [_vm._t("actions")],
                    2
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }