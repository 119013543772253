var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fill-height" },
    [
      _c(
        "v-card",
        { staticClass: "fill-height pa-4", attrs: { outlined: "" } },
        [
          _c("h2", { staticClass: "text-h6" }, [_vm._v("Info")]),
          _c("v-divider", { staticClass: "my-2" }),
          _c("div", { staticClass: "d-flex text-body-2 mb-2" }, [
            _c("div", { staticClass: "moin-label mr-2 moin-col-label" }, [
              _vm._v("Themen:"),
            ]),
            _c("div", { staticStyle: { width: "100px" } }, [
              _c("div", [_vm._v(_vm._s(_vm.intents.length))]),
            ]),
          ]),
          _c("div", { staticClass: "d-flex text-body-2 mb-2" }, [
            _c("div", { staticClass: "moin-label mr-2 moin-col-label" }, [
              _vm._v("In Review:"),
            ]),
            _c("div", { staticStyle: { width: "100px" } }, [
              _c("div", [_vm._v(_vm._s(_vm.intentsInReview.length))]),
            ]),
          ]),
          _c("div", { staticClass: "d-flex text-body-2 mb-2" }, [
            _c("div", { staticClass: "moin-label mr-2 moin-col-label" }, [
              _vm._v("Vorschläge:"),
            ]),
            _c("div", { staticStyle: { width: "100px" } }, [
              _c("div", [_vm._v(_vm._s(_vm.suggested.length))]),
            ]),
          ]),
          _c("div", { staticClass: "d-flex text-body-2 mb-2" }, [
            _c("div", { staticClass: "moin-label mr-2 moin-col-label" }, [
              _vm._v("Smalltalk:"),
            ]),
            _c("div", { staticStyle: { width: "100px" } }, [
              _c("div", [_vm._v(_vm._s(_vm.smalltalks.length))]),
            ]),
          ]),
          _c("div", { staticClass: "d-flex text-body-2 mb-2" }, [
            _c("div", { staticClass: "moin-label mr-2 moin-col-label" }, [
              _vm._v("Unterhaltungen:"),
            ]),
            _c("div", { staticStyle: { width: "100px" } }, [
              _c("div", [_vm._v(_vm._s(_vm.conversations))]),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }