var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "HubDialog",
    {
      attrs: {
        value: _vm.isOpen,
        "primary-label": _vm.$t("common.accept"),
        "primary-icon": "mdi-plus",
        title: _vm.$t("intentsSuggestion.title"),
        modal: "",
        disabled: !_vm.isQuotaOk,
      },
      on: {
        onSecondary: function ($event) {
          return _vm.$emit("onClose")
        },
        onPrimary: _vm.createIntentFromSuggestion,
      },
    },
    [
      _c("div", {
        staticClass: "text-body-1",
        domProps: {
          innerHTML: _vm._s(
            _vm.$t("intentsSuggestion.text", {
              displayName: _vm.intent
                ? _vm.displayName(_vm.intent.intent, _vm.$vuetify.lang.current)
                : "",
            })
          ),
        },
      }),
      _vm.isAdmin
        ? _c(
            "div",
            { staticClass: "pt-6" },
            [
              _vm.quota
                ? _c("InfoBox", {
                    attrs: {
                      text: _vm.isQuotaOk
                        ? _vm.$t("intentsSuggestion.quotaInfoOk", {
                            available:
                              _vm.quota.intents - _vm.intents.length - 1,
                            all: _vm.quota.intents,
                          })
                        : _vm.$t("intentsSuggestion.quotaInfoNotOk", {
                            all: _vm.quota.intents,
                          }),
                      icon: _vm.isQuotaOk
                        ? "mdi-comment-check"
                        : "mdi-comment-alert-outline",
                    },
                  })
                : _c("InfoBox", {
                    attrs: {
                      text: "Es sind noch keine Quota eingestellt.",
                      icon: "mdi-comment-alert-outline",
                    },
                  }),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "pt-6" },
            [
              _vm.quota
                ? _c("InfoBox", {
                    attrs: {
                      text: _vm.isQuotaOk
                        ? _vm.$t("intentsSuggestion.quotaInfoOk", {
                            available:
                              _vm.quota.intents - _vm.intents.length - 1,
                            all: _vm.quota.intents,
                          })
                        : _vm.$t("intentsSuggestion.quotaInfoNotOk", {
                            all: _vm.quota.intents,
                          }),
                      icon: _vm.isQuotaOk
                        ? "mdi-comment-check"
                        : "mdi-comment-alert-outline",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }