var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-data-table", {
        attrs: {
          value: [],
          "item-key": "id",
          headers: _vm.headers,
          items: _vm.channels,
          "single-select": "",
          "hide-default-footer": "",
        },
        on: { "click:row": (item) => _vm.$emit("onSelect", item) },
        scopedSlots: _vm._u(
          [
            {
              key: `item.channel`,
              fn: function ({ item }) {
                return [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v(_vm._s(_vm.typeIcons[item.channel])),
                  ]),
                  _vm._v(
                    " " + _vm._s(_vm.$t(`channels.${item.channel}`)) + " "
                  ),
                ]
              },
            },
            {
              key: `item.languages`,
              fn: function ({ item }) {
                return [
                  _c(
                    "v-chip",
                    {
                      attrs: {
                        color: "primary lighten-3",
                        "text-color": "white",
                        small: "",
                      },
                    },
                    [
                      _c("v-icon", { attrs: { left: "", small: "" } }, [
                        _vm._v("mdi-web"),
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t(`languages.${_vm.getLanguage(item)}`)) +
                          " "
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
            _vm.isAdmin && false
              ? {
                  key: `item.action`,
                  fn: function ({ item }) {
                    return [
                      _vm.isAdmin
                        ? _c("BtnSquare", {
                            staticClass: "mr-1",
                            attrs: {
                              to: `/settings/channels/${item.channelId}`,
                              icon: "edit",
                              colorBtn: "admin",
                              colorIcon: "admin",
                            },
                          })
                        : _vm._e(),
                      _c(
                        "DropMenu",
                        { attrs: { items: _vm.dropMenuItems, isDisabled: "" } },
                        [
                          _vm.isAdmin
                            ? _c("BtnSquare", {
                                attrs: {
                                  icon: "mdi-dots-horizontal",
                                  colorBtn: "admin",
                                  colorIcon: "admin",
                                  disabled: "",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  },
                }
              : null,
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }