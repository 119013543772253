var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "fill-height px-0", attrs: { fluid: "" } },
    [
      _c(
        "v-dialog",
        {
          staticStyle: { position: "relative" },
          attrs: {
            persistent: "",
            "max-width": "70vw",
            fullscreen: _vm.$vuetify.breakpoint.mdAndDown,
          },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "d-flex flex-column", attrs: { height: "90vh" } },
            [
              _c(
                "v-card-title",
                { staticClass: "pa-0" },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { flat: "" } },
                    [
                      _c("v-toolbar-title", [
                        _vm._v(_vm._s(_vm.$route.params.channelid)),
                      ]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function ($event) {
                              return _vm.closeDialog()
                            },
                          },
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "heading grey--text text--darken-4",
                            },
                            [_vm._v("close")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              !_vm.isLoading
                ? _c(
                    "v-tabs",
                    {
                      attrs: {
                        "active-class": "secondary--text text--darken-2",
                        vertical: _vm.$vuetify.breakpoint.mdAndUp,
                      },
                      model: {
                        value: _vm.currentSettingView,
                        callback: function ($$v) {
                          _vm.currentSettingView = $$v
                        },
                        expression: "currentSettingView",
                      },
                    },
                    [
                      _c("v-tab", { attrs: { href: "#infos" } }, [
                        _vm._v("Infos "),
                      ]),
                      _vm.isConfigV3
                        ? _c("v-tab", { attrs: { href: "#editor" } }, [
                            _vm._v("Darstellung "),
                          ])
                        : _vm._e(),
                      _vm.isConfigV3
                        ? _c("v-tab", { attrs: { href: "#config" } }, [
                            _vm._v("Configuration "),
                          ])
                        : _vm._e(),
                      _c("v-tabs-slider", {
                        attrs: { color: "secondary darken-2" },
                      }),
                      _c(
                        "v-tabs-items",
                        {
                          staticStyle: { display: "grid" },
                          model: {
                            value: _vm.currentSettingView,
                            callback: function ($$v) {
                              _vm.currentSettingView = $$v
                            },
                            expression: "currentSettingView",
                          },
                        },
                        [
                          _c("TabChannelInfos"),
                          _vm.isConfigV3 ? _c("TabAppearance") : _vm._e(),
                          _vm.isConfigV3 ? _c("TabConfiguration") : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "div",
                    {
                      staticStyle: {
                        display: "grid",
                        height: "100%",
                        "align-items": "center",
                        "justify-items": "center",
                      },
                    },
                    [
                      _c("v-progress-circular", {
                        attrs: { indeterminate: "", color: "primary" },
                      }),
                    ],
                    1
                  ),
              _c(
                "v-banner",
                {
                  staticClass: "pa-0 d-flex justify-end",
                  staticStyle: { bottom: "0" },
                  attrs: {
                    elevation: "3",
                    sticky: "",
                    "single-line": "",
                    bottom: "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "actions",
                      fn: function () {
                        return [
                          _c(
                            "v-btn",
                            {
                              attrs: { outlined: "", color: "primary" },
                              on: { click: _vm.saveChanges },
                            },
                            [_vm._v(_vm._s(_vm.$t("common.save")))]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _vm.getDirty
                    ? _c(
                        "div",
                        { staticClass: "d-flex align-center" },
                        [
                          _c("v-icon", { staticClass: "mr-2" }, [
                            _vm._v("mdi-alert-circle-outline"),
                          ]),
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t(`common.unsavedChanges`))),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
          _c(
            "v-snackbar",
            {
              attrs: { color: _vm.snackbarType },
              scopedSlots: _vm._u([
                {
                  key: "action",
                  fn: function ({ attrs }) {
                    return [
                      _c(
                        "v-btn",
                        _vm._b(
                          {
                            attrs: { color: "red", text: "" },
                            on: {
                              click: function ($event) {
                                _vm.snackbar = false
                              },
                            },
                          },
                          "v-btn",
                          attrs,
                          false
                        ),
                        [_vm._v(" Close ")]
                      ),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.snackbar,
                callback: function ($$v) {
                  _vm.snackbar = $$v
                },
                expression: "snackbar",
              },
            },
            [_vm._v(" " + _vm._s(_vm.snackbarText) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }