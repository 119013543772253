var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex" },
    [
      _c(
        "div",
        { staticClass: "d-flex items-center mr-3" },
        [
          _c(
            "v-icon",
            { staticClass: "grey--text text--darken-1 mr-1 mt-n1" },
            [_vm._v("mdi-robot-happy-outline")]
          ),
          _c("div", { staticClass: "text-h6 grey--text text--darken-1" }, [
            _vm._v("24"),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex items-center" },
        [
          _c("v-icon", { staticClass: "grey--text text--darken-1" }, [
            _vm._v("mdi-flag-checkered"),
          ]),
          _c("div", { staticClass: "text-h6 grey--text text--darken-1" }, [
            _vm._v("3"),
          ]),
        ],
        1
      ),
      _c("v-divider", { staticClass: "mx-4", attrs: { vertical: "" } }),
      _c(
        "div",
        { staticClass: "d-flex items-center mr-3" },
        [
          _c(
            "v-icon",
            { staticClass: "primary--text text--lighten-4 mr-1 mt-n1" },
            [_vm._v("mdi-face-agent")]
          ),
          _c("div", { staticClass: "text-h6 primary--text text--lighten-4" }, [
            _vm._v("3"),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex items-center" },
        [
          _c("v-icon", { staticClass: "primary--text text--lighten-4" }, [
            _vm._v("mdi-flag-checkered"),
          ]),
          _c("div", { staticClass: "text-h6 primary--text text--lighten-4" }, [
            _vm._v("1"),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }