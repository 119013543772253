var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "HubBox",
        {
          attrs: {
            title: _vm.$t("botSettings.userRole.title"),
            icon: "mdi-badge-account-horizontal-outline",
            titleSub: _vm.userRole,
          },
        },
        [
          _c("div", { staticClass: "mb-4" }, [
            _c("div", { staticClass: "text-body-2" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("botSettings.userRole.description")) + " "
              ),
            ]),
          ]),
          _c(
            "v-btn",
            {
              staticClass: "mt-6",
              attrs: { outlined: "", color: "primary lighten-2" },
              on: {
                click: function ($event) {
                  _vm.dialogIsOpen = true
                },
              },
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("botSettings.userRole.widgetBtn")) + " "
              ),
              _c("v-icon", { attrs: { right: "" } }, [
                _vm._v("mdi-badge-account-horizontal-outline"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "HubDialog",
        {
          attrs: {
            value: _vm.dialogIsOpen,
            title: _vm.$t("botSettings.userRole.changeRole"),
            asyncDialog: "",
            primaryIcon: "mdi-badge-account-horizontal-outline",
            primaryLabel: _vm.$t("editor.common.deploy"),
            isLoading: _vm.isLoading,
            disabled: _vm.selectedRoleInitial === _vm.selectedRole,
            modal: "",
          },
          on: {
            onSecondary: function ($event) {
              _vm.dialogIsOpen = false
            },
            onPrimary: _vm.changeRole,
          },
        },
        [
          _c("div", { staticClass: "text-body-1 mb-4" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("botSettings.userRole.setRoleAndRights")) +
                " "
            ),
          ]),
          _c("div", { staticClass: "text-body-2" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("botSettings.roles.userSettings.userTitle")) +
                " "
            ),
          ]),
          _c("div", { staticClass: "text-body-1" }, [
            _c("strong", [_vm._v(_vm._s(_vm.user.username))]),
          ]),
          _c("div", { staticClass: "mt-4 text-body-2 mb-n3" }, [
            _vm._v(" " + _vm._s(_vm.$t("botSettings.userRole.role")) + " "),
          ]),
          _c(
            "v-radio-group",
            {
              model: {
                value: _vm.selectedRole,
                callback: function ($$v) {
                  _vm.selectedRole = $$v
                },
                expression: "selectedRole",
              },
            },
            _vm._l(_vm.rolesOptions, function (option) {
              return _c("v-radio", {
                key: option.value,
                attrs: { value: option.value, label: option.label },
              })
            }),
            1
          ),
          _c("InfoBox", {
            attrs: {
              icon: "mdi-alert",
              text: _vm.$t("botSettings.userRole.changeHint"),
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }