<template>
  <v-app style="max-height: 100vh; height: 100vh">
    <HubMenu />
    <Appbar />
    <HubEditorFrame
      v-model="isSidebarOpen"
      :title="$t('botSettings.archive.title')"
      :loading="isLoading"
      :draft="isChanged || hasUndefinedSetting"
      saveIcon="cloud_upload"
      @onReset="reset"
      @onSave="save"
      back="/settings"
      :sidebar-items="[{ title: '', icon: 'info', name: 'info' }]"
    >
      <template v-slot="{ isLoading }">
        <v-container fluid class="white pa-6">
          <v-row>
            <v-col cols="12" lg="6" xl="4">
              <h2 class="text-h6">{{ $t('botSettings.archive.active.title') }}</h2>
              <div class="text-body-2">{{ $t('botSettings.archive.active.description') }}</div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-switch
                v-model="active"
                :label="$t('botSettings.archive.active.label')"
                color="green"
                inset
                dense
                :disabled="isLoading"
                @change="(v) => {
                  handleChange(v, active);
                  return active = v;
                }"
              />
              <InfoBox class="my-4" :text="$t('botSettings.archive.active.info')"/>
            </v-col>
          </v-row>
        </v-container>
        <v-divider/>
      </template>
    </HubEditorFrame>
  </v-app>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import Appbar from '@/components/Appbar.vue';
import BtnSquare from '@/components/common/BtnSquare.vue';
import HubBoxSimple from '@/components/hub/HubBoxSimple.vue';
import HubEditorFrame from '../components/hub/HubEditorFrame.vue';
import HubMenu from '@/components/hub/HubMenu.vue';
import InfoBox from '@/components/common/InfoBox.vue';
import { BotSettingKeyEnum } from '@/constants';
import { TrackingService } from '@/services/tracking';

/**
 * @typedef {Object} TSettingsArchive
 * @property {boolean} active - Indicates if the settings are active.
 * @property {number} limit - The limit value for the settings.
 * @property {string} quotaPeriod - The quota period for the settings.
 */

export default {
  name: 'SettingsArchive',
  components: {
    HubMenu,
    Appbar,
    HubEditorFrame,
    HubBoxSimple,
    BtnSquare,
    InfoBox,
  },
  props: [],
  data() {
    return {
      key: BotSettingKeyEnum.archive,

      isChanged: false,
      hasUndefinedSetting: false,
      isInitialized: false,
      isLoading: false,
      isSidebarOpen: false,
      nextUrl: null,

      initialConfig: {},
      active: false,
    };
  },

  computed: {
    ...mapGetters('auth', ['isAdmin']),
    ...mapGetters('bots', ['currentBot', 'currentBotId', 'getBotSettings']),
  },
  created() {
    this.init();
  },
  watch: {
    active(val, oldVal) { this.handleChange(val, oldVal); },
  },
  methods: {
    ...mapActions('bots', ['setSettingsByKey']),
    localHasChanged() {
      if (this.initialConfig.active !== this.active) return true;
      return false;
    },
    handleChange(val, oldVal) {
      if (!this.isInitialized) return;
      if (val === oldVal) return;
      this.isChanged = this.localHasChanged();
    },
    reset() {
      this.active = this.initialConfig.active;
      this.isChanged = false;
    },
    parseConfig() {
      const config = { active: this.active };
      return config;
    },
    /**
     * Fetches archive configuration settings.
     * @returns {Promise<{ archive: { active?: boolean }, archiveQuota?: TSettingsArchive }>}
     */
    async fetchConfig() {
      const { archive, quota } = await this.getBotSettings(this.currentBotId) || {};
      this.initialConfig = _.cloneDeep(archive);
      const archiveQuota = quota?.archive;
      return { archive, archiveQuota };
    },
    async save() {
      this.isLoading = true;

      const success = await this.setSettingsByKey({
        botId: this.currentBotId,
        key: this.key,
        config: this.parseConfig(),
      });

      if (success) {
        this.initialConfig.active = this.active;
        this.isChanged = false;
        this.hasUndefinedSetting = false;
      }

      this.isLoading = false;
      this.trackIt('save');
    },
    async init() {
      this.isLoading = true;
      // ! In case if we will display this settings anyway. Add message that Archive feature is not active for this Bot (+ return to settings Button)
      /** @type {{ archive: { active?: boolean }, archiveQuota?: TSettingsArchive }} */
      const { archive, archiveQuota } = await this.fetchConfig();
      if (!archiveQuota) {
        console.log('quota not active Return')
        this.$router.push({ name: 'settings' });
        return;
      }
      // If config is not defined => then Disabled
      this.active = archive?.active ?? false;
      this.hasUndefinedSetting = !_.isBoolean(archive?.active);
      this.initialConfig = this.parseConfig();

      this.isLoading = false;
      this.$nextTick(() => { this.isInitialized = true; });
    },
    trackIt(type = 'view', payload) {
      const body = {
        botId: this.currentBotId,
        type: type,
        source: 'SettingsArchive.vue',
      };
      if (payload) body.payload = payload;
      TrackingService.trackSettingsArchive(body);
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.isChanged && !this.nextUrl) {
      this.isLostDialogOpen = true;
      this.nextUrl = to;
      return next(false);
    }
    return next(true);
  },
};
</script>
