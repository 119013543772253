<script>
export default {
  name: 'WidgetChatHistory',
  props: {
    "height": {
      type: String,
      default: '',
    },
    "minHeight": {
      type: String,
      default: '478px',
    },
    "maxWidth": {
      type: String,
      default: '461px',
    }
  }
};
</script>

<template>
  <div
    class="chat-history d-flex flex-column px-2"
    :style="{
      'height': height,
      'min-height': minHeight,
      'max-width': maxWidth,
    }"
  >
    <slot />
  </div>
</template>

<style scoped lang="scss">
.chat-history {
  background-color: #e2e2e2;
  font-size: 62.5%;
  /* From the widget itself */
  // 10px chat history padding top, + 10px margin top on first message
  padding-top: 20px;
}

</style>