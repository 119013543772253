var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._l(_vm.alerts, function (alert, i) {
        return [
          !alert.to
            ? _c(
                "v-snackbar",
                {
                  key: alert.id,
                  style: { "margin-bottom": `${i * 70}px` },
                  attrs: {
                    timeout: alert.duration,
                    bottom: "",
                    right: "",
                    vertical: "",
                    color: alert.type,
                  },
                  model: {
                    value: alert.visible,
                    callback: function ($$v) {
                      _vm.$set(alert, "visible", $$v)
                    },
                    expression: "alert.visible",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t(alert.message)) + " ")]
              )
            : _c(
                "RouterLink",
                { key: alert.id, attrs: { to: alert.to } },
                [
                  _c(
                    "v-snackbar",
                    {
                      style: { "margin-bottom": `${i * 70}px` },
                      attrs: {
                        timeout: alert.duration,
                        bottom: "",
                        right: "",
                        vertical: "",
                        color: alert.type,
                      },
                      model: {
                        value: alert.visible,
                        callback: function ($$v) {
                          _vm.$set(alert, "visible", $$v)
                        },
                        expression: "alert.visible",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t(alert.message)) + " ")]
                  ),
                ],
                1
              ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }