<script>
export default {
  name: 'LivechatStateCallout',
  props: {
    "icon": {
      type: String,
      required: true,
    },
    "title": {
      type: String,
      required: true,
    },
    "description": {
      type: String,
      default: null,
    },
    // Classes assigned to the elements, for coloring mostly
    "classes": {
      type: String,
      default: null,
    },
  }
};
</script>

<template>
  <div>
    <div class="text-h6 text-center" :class="classes">
      <v-icon size="64" :class="classes">
        {{ icon }}
      </v-icon>
      {{ title }}
    </div>
    <div v-if="description" class="text-body-1 text-center" :class="classes">
      {{ description }}
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>