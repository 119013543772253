var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "shortcodes-list-wrapper",
      class: { invisible: !_vm.panelIsVisible },
    },
    _vm._l(_vm.filteredShortcodes, function (shortcode, i) {
      return _c("div", { key: shortcode.code + shortcode.group }, [
        _c(
          "div",
          {
            staticClass: "shortcode",
            class: { "shortcode-selected": i === _vm.selected },
            on: {
              click: function ($event) {
                return _vm.onSelect(i)
              },
            },
          },
          [
            _c(
              "span",
              { staticClass: "shortcode-code" },
              [
                _vm._v(" " + _vm._s(`/${shortcode.code}`) + " "),
                _c(
                  "v-chip",
                  {
                    staticClass: "ml-1",
                    attrs: { "x-small": "", color: "secondary" },
                  },
                  [_vm._v(" " + _vm._s(shortcode.group) + " ")]
                ),
              ],
              1
            ),
            _c("span", { staticClass: "shortcode-description" }, [
              _vm._v(_vm._s(shortcode.text)),
            ]),
          ]
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }