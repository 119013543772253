<template>
  <div class="d-flex primary--text text-lighten-2">
    <v-icon small color="primary lighten-2">
      dynamic_form
    </v-icon>
    <div class="ml-2">
      {{ item.text }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'LivechatInfoFormTag',
  components: {},
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>
