var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "text-center d-flex flex-col gap-1",
      style: { minHeight: "110px" },
    },
    [
      _c(
        "div",
        {
          staticClass: "primary--text pb-0 text-no-wrap",
          class: [_vm.titleHeading ? "text-h6" : "text-body-1"],
        },
        [_vm._v(" " + _vm._s(_vm.title) + " ")]
      ),
      _vm.loading
        ? _c(
            "div",
            {
              staticClass: "d-flex justify-center",
              staticStyle: { height: "48px" },
            },
            [
              _c("v-skeleton-loader", {
                attrs: { type: "image", height: "25", width: "48" },
              }),
            ],
            1
          )
        : [
            _c(
              "div",
              { staticClass: "d-flex justify-center items-center pb-1" },
              [
                _c(
                  "v-icon",
                  {
                    attrs: {
                      color: _vm.noData ? "grey" : "primary",
                      size: "32",
                      left: "",
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.icon) + " ")]
                ),
                _vm.noData
                  ? _c(
                      "h4",
                      { staticClass: "pt-1 text-h4 grey--text text--darken-1" },
                      [_vm._v(" 0 ")]
                    )
                  : _vm.value !== null
                  ? _c(
                      "h4",
                      {
                        staticClass: "pt-1 text-h4",
                        class: {
                          "primary--text": !_vm.criticalValue,
                          "critical-value": _vm.criticalValue,
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.value) + " ")]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm.$slots.default ? _vm._t("default") : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }