var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex primary--text text-lighten-2" },
    [
      _c("v-icon", { attrs: { small: "", color: "primary lighten-2" } }, [
        _vm._v(" dynamic_form "),
      ]),
      _c("div", { staticClass: "ml-2" }, [
        _vm._v(" " + _vm._s(_vm.item.text) + " "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }