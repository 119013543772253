<template>
  <div>
    <v-timeline-item hide-dot color="grey lighten-3" fill-dot v-if="content">
      <v-row>
        <v-col>
          <v-text-field
            outlined
            class="font-weight-medium ma-0"
            @input="update"
            dense
            v-model="content.displayName"
            :label="$t('botFormEdit.whatIsAsked')"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-timeline-item>

    <ActionList
      v-if="content"
      :actions="content.question.actions"
      @hook:mounted="updateName"
      @onUpdate="update"
      :channelType="channelType"
    ></ActionList>

    <v-timeline-item color="white" v-if="content">
      <template v-slot:icon>
        <v-icon color="success">insert_drive_file</v-icon>
      </template>

      <span class="text-subtitle-1">
        Erlaubte Dateitypen
      </span>
      <p class="text-subtitle-2">
       <!-- vuetify select box that can have multiple selection of mimeTypes, model changed call allowedTypesChanged, if component mounted call initMimeTypes -->
        <v-select
          v-model="allowedTypes"
          :items="mimeTypes"
          item-text="title"
          item-value="mime"
          multiple
          chips
          label="Erlaubte Dateitypen"
          hint="Wählen Sie die Dateitypen aus, die der Benutzer hochladen darf."
          persistent-hint
          outlined
          @change="allowedTypesChanged"
          @hook:mounted="initMimeTypes"
        ></v-select>
      </p>
      <v-row
        v-if="isAdmin"
        class="ma-0 d-flex justify-start align-center moin-admin-box px-2"
      >
        <v-col>
          <label class="mr-3">{{ $t('botFormEdit.fileUpload.maxFilesTemplate') }}</label>
          <v-text-field
            :value="maxFileCount"
            :label="$t('botFormEdit.fileUpload.maxFilesLabel')"
            type="number"
            step="1"
            :min="fileCountLimits.min"
            :max="fileCountLimits.max"
            @input="setMaxFileCount"
          />
        </v-col>
      </v-row>
      <v-row v-if="isAdmin && fileUploadConfigured"
        class="ma-0 mt-4 d-flex justify-start align-center moin-admin-box px-2"
      >
        <v-col>
          <v-switch
            v-model="saveFileUrl"
            :label="$t('botFormEdit.fileUpload.saveUrls')"
            color="green"
            inset
            dense
            :disabled="false"
            @change="setSaveFileUrl"
          />
          <InfoBox class="my-4" :text="$t('botFormEdit.fileUpload.saveUrlsInfo')"/>
        </v-col>
      </v-row>
      <!-- If isSkipAllowed was added in case if perviously was activated. To be able to deactivate (ONE WAY Deactivation for !Whappodo) -->
      <v-row
        v-if="isSkipAllowed || skipFileUploadOption"
        class="ma-0 mt-4 d-flex justify-start align-center moin-admin-box px-2"
      >
        <v-col>
          <div class="d-flex align-center">
            <v-switch
              v-model="skipFileUploadOption"
              :label="$t('botFormEdit.fileUpload.skipOption')"
              color="green"
              inset
              dense
              @change="setSkipFileUploadOption"
              :disabled="!findLastTextStep().action"
            />

            <InfoHoverBox
              v-if="!findLastTextStep().action"
              class="ml-3"
              :top="-80"
              :left="-110"
              :title="$t('common.warning')"
              :body="$t('botFormEdit.fileUpload.skipOptionInfoHintNoText')"
              main-icon="mdi-information-outline"
              :bounce-animation="true"
            />
          </div>
          <InfoBox v-if="skipFileUploadOption" class="my-4" :text="$t('botFormEdit.fileUpload.skipOptionInfo')"/>
          <div v-if="skipFileUploadOption">
            <ActionTextInput
              class="flex-grow-1 mb-4"
              label="Button label"
              :value="quickButtonSkipString"
              @input="(v) => updateRelatedSkipButton(v)"
              :read-only="!findLastTextStep().action"
            />
          </div>
        </v-col>
      </v-row>
      
    </v-timeline-item>
    <v-timeline-item color="white" v-if="content">
      <template v-slot:icon>
        <v-icon color="error">cancel</v-icon>
      </template>

      <span class="text-subtitle-1">
        Datei Upload abgebrochen
      </span>
      <p class="text-subtitle-2">Formular wird abgebrochen.</p>
    </v-timeline-item>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ActionList from '@/components/ActionList.vue';
import ChannelsUtil from '@/utils/channels';
import ActionTextInput from '@/components/editor/components/ActionTextInput.vue';
import InfoHoverBox from '@/components/common/InfoHoverBox.vue';

import InfoBox from '@/components/common/InfoBox.vue';


export default {
  name: 'BotFormFileEdit',
  components: {
    ActionList,
    ActionTextInput,
    InfoBox,
    InfoHoverBox,
  },
  props: ['box', 'intent', 'channel', 'name'],
  data: () => ({
    tab: null,
    allowedTypes: [],
    maxFileCount: 1,
    uploadMultiple: false,
    saveFileUrl: false,
    skipFileUploadOption: false,
    skipAction: null,
    fileUploadConfigured: false,
    /**
     * Should be changed to use data from API?
     * It will tell us max possible files we can set
     * for the given context (context being channel, etc.)
     *
     * For now, we define hard limit to 10.
     */
    fileCountLimits: {
      min: 1,
      max: 10,
    },
  }),
  computed: {
    ...mapGetters('bots', ['currentBotId', 'currentChannels', 'getBotSettings']),
    ...mapGetters('intents', ['intents']),
    ...mapGetters('auth', ['isAdmin']),
    mimeTypes() {
      // Erstelle ein leeres Array
      let array = [];
      // Füge jedem Dateinamen den entsprechenden MIME-Typ und Titel hinzu
      array.push({filename: ".pdf", mime: "application/pdf", title: "PDF"});
      array.push({filename: ".zip", mime: "application/zip", title: "ZIP"});
      array.push({filename: ".xml", mime: "application/xml", title: "XML"});
      array.push({filename: ".txt", mime: "text/plain", title: "TXT"});
      array.push({filename: ".csv", mime: "text/csv", title: "CSV"});
      array.push({filename: ".doc/docx", mime: "application/msword / application/vnd.openxmlformats-officedocument.wordprocessingml.document", title: "MS Word"});
      array.push({filename: ".xls/xlsx", mime:"application/vnd.ms-excel / application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" , title:"MS Excel"});
      array.push({filename:".ppt/pptx" , mime:"application/vnd.ms-powerpoint / application/vnd.openxmlformats-officedocument.presentationml.presentation" , title:"MS PowerPoint"});
      array.push({filename:".png" , mime:"image/png" , title:"PNG"});
      array.push({filename:".jpg" , mime:"image/jpeg" , title:"JPEG"});
      array.push({filename:".gif" , mime:"image/GIF" , title:"GIF"});

      return array;
    },
    content() {
      return this.$store.getters['content/getContent'](
        this.currentBotId,
        this.intent,
        this.box,
        true,
        this.channel
      );
    },
    channelType() {
      return ChannelsUtil.getChannelType(
        this.content?.channel,
        this.currentChannels
      );
    },
    fileUploadAction() {
      return this.content.question.actions.find(action => action.name === "file_upload");
    },
    /** Checks if File Upload Skip is allowed for current channel */
    isSkipAllowed() {
      const allowedFor = new Set(['telegram', 'whatsapp']);
      return allowedFor.has(this.channelType);
    },
    /** Mutates original action */
    quickButtonSkipString() {
      const { action } = this.findLastTextStep();
      if (!action) return 'Skip is not available'; // ! If you see this in payload, then something went wrong

      let index = action.content.strings?.findIndex(e => e.name === 'qr0');
      if (index === -1) {
        if (!Array.isArray(action.content.strings)) action.content.strings = [];
        action.content.strings.push({ name: 'qr0', data: this.$t('botFormEdit.fileUpload.skipOptionMessageButtonText') });
        index = action.content.strings.length - 1;
      }

      return action.content.strings[index].data;
    },
  },
  methods: {
    updateName() {
      if (this.content && !this.content.displayName) {
        this.content.displayName = this.name;
      }
    },
    update() {
      this.handleSkipStep();
      this.$emit('onUpdated');
    },
    /** Handle duplicates. If remove this check, then quick_reply can be added to 2+ text messages */
    handleSkipStep() {
      const { action, index } = this.findLastTextStep();
      if (!action) return;

      // Remove Skip quick_reply from other texts
      this.content.question.actions.forEach((stepAction, i) => {
        if (index !== i) this.removeSkipFromAction(stepAction);
      });

      if (!this.skipFileUploadOption) this.removeSkipFromAction(action);
    },
    allowedTypesChanged() {
      const action = this.fileUploadAction;
      // set action.parameters.acceptedFiles to allowedTypes and create action.parameters if it does not exist
      action.parameters = action.parameters || {};
      action.parameters.acceptedFiles = this.allowedTypes;

      this.update();
    },
    initMimeTypes() {
      // if action is not null and action.parameters.acceptedFiles is not null
      if (this.fileUploadAction?.parameters?.acceptedFiles) {
        // set allowedTypes to action.parameters.acceptedFiles
        this.allowedTypes = this.fileUploadAction.parameters.acceptedFiles;
      }
    },
    /**
     * Captures the max files input, clamps it to min/max, then sets the value
     * @param {number} newCount
     */
    setMaxFileCount(newCount) {
      if (!newCount) newCount = this.fileCountLimits.min;

      this.maxFileCount = Math.max(Math.min(newCount, this.fileCountLimits.max), this.fileCountLimits.min);

      this.uploadMultiple = this.maxFileCount > 1 ? true : false;

      this.fileUploadAction.parameters.maxFiles = this.maxFileCount;
      this.fileUploadAction.parameters.uploadMultiple = this.uploadMultiple;

      this.update();
    },
    setSaveFileUrl() {
      if (this.content) this.content.saveFileUrl = this.saveFileUrl;
      this.update();
    },
    setSkipFileUploadOption() {
      const { action, index } = this.findLastTextStep();
      if (action && !this.setSkipFileUploadOption) this.removeSkipFromAction(action, index);
      if (action && this.setSkipFileUploadOption) this.addSkipIfNoQuick(action);
      this.update();
    },
    /** Adds 'Skip' quick_reply skip button if it doesn't exists */
    addSkipIfNoQuick(action) {
      if (!action?.content) return;
      const quickIndex = action.content?.quick?.findIndex((obj) => obj.intent === 'upload_skip');
      if (quickIndex === -1) {
        if (!Array.isArray(action.content.quick)) action.content.quick = [{ label: '{qr0}', intent: 'upload_skip' } ];
        else action.content.quick = [{ label: '{qr0}', intent: 'upload_skip' } ];
      }
    },
    /** Removes 'Skip' quick_button and string */
    removeSkipFromAction(action, index) {
      if (action?.content?.quick) {
        action.content.quick = action.content.quick.filter(e => {
          if (e.intent !== 'upload_skip') return true;
          const label = `${(e.label || '')}`?.replace(/^{|}$/gi, '') // Replace special symbols to match proper name
          if (action.content.strings) {
            action.content.strings = action.content.strings.filter(e => e.name !== label);
          };
          return false;
        });
      }
    },
    // Extended findLast function to include element index (for tracking duplicates) 
    findLastTextStep() {
      const index = this.content.question.actions?.findLastIndex((a) => a.content?.type === 'text');
      return {
        action: index !== -1 ? this.content.question.actions[index] : null,
        index,
      };
    },
    async fileUploadIsActive() {
      const botSettings = await this.getBotSettings(this.currentBotId);
      const fileSettings = !_.isArray(botSettings) && _.isObject(botSettings?.files)
        ? botSettings.files
        : {};

      return fileSettings.active && fileSettings.accessViaSecretAllowed;
    },
    async init() {
      this.saveFileUrl = this.content.saveFileUrl ?? false;
      this.skipFileUploadOption = this.content.skipFileUploadOption ?? false;
      this.fileUploadConfigured = await this.fileUploadIsActive();

      const found = this.fileUploadAction;
      if (!found) return;

      this.maxFileCount = found.parameters?.maxFiles || this.fileCountLimits.min;

      const skipExists = this.content?.question?.actions?.find((e) => e.content?.quick?.find(qr => qr.intent === 'upload_skip'));
      if (skipExists) this.skipFileUploadOption = true;
    },
    /**
     * Updates related Skip Action string value
     * @param {string} value - new value
     */
     updateRelatedSkipButton(value) {
      const { action } = this.findLastTextStep();
      if (!action) return;

      this.addSkipIfNoQuick(action);
      const stringIndex = action.content.strings.findIndex((obj) => obj.name === 'qr0');
      if (stringIndex !== -1) action.content.strings[stringIndex].data = value;
      else action.content.strings.push({ name: 'qr0', data: `${value}` });
      this.update();
    },
  },
  watch: {
    name() {
      this.content.displayName = this.name;
    },
  },
  created() {
    this.init();
  }
};
</script>
