// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/fonts/JosefinSans-VariableFont_wght.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("@/assets/fonts/Lato-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("@/assets/fonts/silka-semibold-webfont.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: \"Josefin Sans\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n@font-face {\n  font-family: \"Lato\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n@font-face {\n  font-family: \"silkaHeading\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n.pulse-boeble[data-v-76854568] {\n  position: relative;\n  width: 12px;\n  height: 12px;\n  border-radius: 50%;\n  background: #00c28a;\n}\n@-webkit-keyframes pulse-76854568 {\n0% {\n    -webkit-transform: scale(0.5);\n            transform: scale(0.5);\n    opacity: 0.8;\n}\n100% {\n    -webkit-transform: scale(1);\n            transform: scale(1);\n    opacity: 0;\n}\n}\n@keyframes pulse-76854568 {\n0% {\n    -webkit-transform: scale(0.5);\n            transform: scale(0.5);\n    opacity: 0.8;\n}\n100% {\n    -webkit-transform: scale(1);\n            transform: scale(1);\n    opacity: 0;\n}\n}\n.pulse-boeble[data-v-76854568]::after {\n  content: \" \";\n  position: absolute;\n  top: 0;\n  left: 0;\n  margin-left: -6px;\n  margin-top: -6px;\n  width: 24px;\n  height: 24px;\n  background: #00c28a;\n  opacity: 0.5;\n  border-radius: 50%;\n  -webkit-animation: pulse-76854568 1s infinite;\n          animation: pulse-76854568 1s infinite;\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
