var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "moin-bg" },
    [
      _c("div", { staticClass: "moin-bg-key-a" }, [
        _c(
          "div",
          { staticClass: "moin-bg-key-a-box" },
          [_c("HubBraces", { attrs: { color: _vm.colors[0] } })],
          1
        ),
      ]),
      _c("div", { staticClass: "moin-bg-key-b" }, [
        _c(
          "div",
          { staticClass: "moin-bg-key-b-box" },
          [_c("HubBraces", { attrs: { color: _vm.colors[1] } })],
          1
        ),
      ]),
      _c("div", { staticClass: "moin-bg-key-c" }, [
        _c(
          "div",
          { staticClass: "moin-bg-key-c-box" },
          [_c("HubBraces", { attrs: { color: _vm.colors[2] } })],
          1
        ),
      ]),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }