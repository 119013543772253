<template>
  <v-btn
    :text="opts.text"
    class="pa-0"
    :class="{
      'moin-admin-box': opts.colorBtn === 'admin' || opts.preset === 'admin',
      [`${opts.preset}-preset`]: !!opts.preset,
      'px-2': !!opts.preset,
    }"
    :style="opts.small ? 'min-width: 24px' : 'min-width: 36px'"
    v-on="$listeners"
    :disabled="opts.disabled"
    :to="opts.to"
    :append="opts.append"
    :exact="opts.exact"
    :color="opts.colorBtn"
    :small="opts.small"
    :outlined="opts.outlined"
    :tile="opts.tile"
  >
    <v-icon
      v-if="opts.icon && opts.iconFirst"
      :color="opts.colorIcon"
      :small="opts.small"
      :left="opts.withSlot"
    >
      {{ opts.icon }}
    </v-icon>

    <slot v-if="opts.withSlot" />

    <v-icon
      v-if="opts.icon && !opts.iconFirst"
      :color="opts.colorIcon"
      :small="opts.small"
      :right="opts.withSlot"
    >
      {{ opts.icon }}
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'BtnSquare',
  props: {
    /**
     * The icon to use. If icon and preset is not set, the default icon will be 'navigate_next'.
     */
    icon: {
      type: String,
    },
    /**
     * Vuetify: Removes the ability to click or target the component.
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Vuetify: A Vue-router link
     */
    to: {
      type: [String, Object],
      default: null,
    },
    /**
     * Vuetify: Setting append prop always appends the relative path to the current path.
     */
    append: {
      type: Boolean,
      default: false,
    },
    /**
     * Vuetify: Exactly match the 'to' link. without this, '/' will match every route.
     */
    exact: {
      type: Boolean,
      default: false,
    },
    /**
     * Vuetify: `color` property of the <v-icon>.
     */
    colorIcon: {
      type: String,
      default: 'primary lighten-3',
    },
    /**
     * Vuetify 'color'.
     * You can also pass 'moin-admin' to do Moin Admin button coloring.
     *
     * Applies specified color to the control - it can be the name of material
     * color (for example success or purple) or css color (#033 or rgba(255, 0, 0, 0.5)).
     * You can find a list of built-in classes on the colors page:
     * https://v2.vuetifyjs.com/styles/colors#material-colors
     */
    colorBtn: {
      type: String,
    },
    /**
     * If not preset: Required to use custom content in the button.
     * Will replace the icon.
     */
    withSlot: {
      type: Boolean,
      default: false,
    },
    /**
     * Vuetify + custom: Makes the component small.
     * It will also scale down any icons used, and minimum width.
     */
    small: {
      type: Boolean,
      default: false,
    },
    /**
     * Vuetify: Makes the background transparent and applies a thin border.
     */
    outlined: {
      type: Boolean,
      default: false,
    },
    /**
     * Vuetify: Makes the background transparent.
     * When using the colorBtn prop, the color will be applied to the button text instead of the background.
     */
		text: {
			type: Boolean,
			default: true,
		},
    /**
     * Vuetify: Removes border radius
     */
    tile: {
      type: Boolean,
      default: false,
    },
    /**
     * Applies a pre-determined set of options for a common use-case.
     * Some customization is allowed, but they are not guaranteed to not be overwritten.
     */
    preset: {
      type: String,
      default: null,
      enum: [
        'base',
        'assistant',
        'assistant-filled',
        'admin',
        'filled-white',
        'outlined-white',
      ],
    },
    /**
     * Lets you have the icon appear before any <slot> content.
     */
    iconFirst: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    /**
     * Passes the original properties back as an object, but possibly with some
     * predetermined modifications based on the preset you passed.
     */
    opts() {
      switch(this.preset) {
        /**
         * Lets you use icon + slot more easily
         */
        case "base":
          return {
            ...this.$props,
            withSlot: !!this.$slots.default,
          };
        /**
         * Ideal for AI assistant buttons.
         * Gives a purple outline, with solid purple fill and white text/icon on hover
         */
        case "assistant":
          return {
            ...this.$props,
            colorBtn: 'var(--v-assistant-base)',
            colorIcon: undefined, // This will use style="", we want to use class instead
            outlined: true,
            withSlot: !!this.$slots.default,
            text: false,
          };
        /**
         * Filled: Ideal for AI assistant buttons, but filled color.
         * On hover, color changes slightly, but mostly stays the same.
         */
        /**
         * Loading: Ideal for AI assistant buttons indicating loading.
         * Similar to 'assistant', but a color gradient sweeps across the button.
         * On hover, the seeping gradient becomes a bit more visible
         */
        case "assistant-filled":
        case "assistant-loading":
          return {
            ...this.$props,
            colorBtn: undefined,
            colorIcon: undefined,
            outlined: true,
            withSlot: !!this.$slots.default,
            text: false,
          };
        /**
         * Ideal for any button that is usable for Admins only.
         * Gives a punk outline, with solid punk fill.
         *
         * Alternative to using `colorBtn: 'admin'`, but allows you to use
         * the _default preset_ behavior instead of the _default_ behavior.
         */
        case "admin":
          return {
            ...this.$props,
            colorIcon: undefined,
            colorBtn: 'admin',
            withSlot: !!this.$slots.default,
          };
        /**
         * Gives a basic white filled BG button with dark gray text/icon.
         * On hover, the colors invert, giving a dark gray background with white text/icon.
         */
        case "filled-white":
          return {
            ...this.$props,
            colorIcon: undefined,
            outlined: false,
            withSlot: !!this.$slots.default,
          }
        case "outlined-white":
          return {
            ...this.$props,
            colorIcon: undefined,
            outlined: true,
            withSlot: !!this.$slots.default,
          }
        default:
          /**
           * Replicate original behavior:
           * - default icon is 'navigate_next'
           * - you cannot have a slot and icon at the same time
           */
          const base = { ...this.$props };
          if (!base.icon) base.icon = 'navigate_next';
          if (base.withSlot) base.icon = undefined;
          return base;
      }
    },
  }
};
</script>

<style scoped>
.assistant-preset:hover {
  border-radius: 4px;
  border: solid thin var(--v-assistant-base);
  background-color: var(--v-assistant-base);
  color: white !important;
  overflow: hidden;
}

.assistant-filled-preset {
  border-radius: 4px;
  border: solid thin var(--v-assistant-base);
  background-color: var(--v-assistant-base);
  color: white !important;
  overflow: hidden;
}

.assistant-loading-preset {
  border-radius: 4px;
  border: solid thin var(--v-assistant-base);
  background-color: transparent;
  color: var(--v-assistant-base);
  overflow: hidden;
  position: relative;
  transition: .2s ease;
}
.assistant-loading-preset::before {
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  animation: 1s linear infinite sweep;
  background: rgb(87,0,208);
  background: linear-gradient(90deg, rgba(87,0,208,0) 0%, rgba(87,0,208,.4) 50%, rgba(87,0,208,0) 100%);
  opacity: 1;
  transition: background .2s ease;
}
.assistant-loading-preset:hover::before {
  background: linear-gradient(90deg, rgba(87,0,208,0) 0%, rgba(87,0,208,.6) 50%, rgba(87,0,208,0) 100%);
  opacity: 1;
  transition: background .2s ease;
}

@keyframes sweep {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
}

.filled-white-preset {
  background-color: white;
  color: var(--v-grey-darken2);
}
.filled-white-preset:hover {
  background-color: var(--v-grey-darken2);
  color: white;
}
</style>