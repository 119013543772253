var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "mt-4" },
        [
          _vm.newIntent.displayName
            ? _c("v-col", { attrs: { cols: "12" } }, [
                _c("p", { staticClass: "body-2 grey--text mb-0" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("intentNew.stepSummary.titleName")) +
                      " "
                  ),
                ]),
                _vm.status === _vm.statusTypes.creatingWithoutTemplate
                  ? _c("p", { staticClass: "mb-0" }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("intentNew.stepName.displayName.title")
                          ) + ":"
                        ),
                      ]),
                      _vm._v(' "' + _vm._s(_vm.newIntent.displayName) + '" '),
                    ])
                  : _c("p", { staticClass: "mb-0" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.displayName(
                              _vm.newIntent.intent,
                              _vm.$vuetify.lang.current
                            )
                          ) +
                          " "
                      ),
                    ]),
              ])
            : _vm._e(),
          _vm.newIntent.description
            ? _c("v-col", { attrs: { cols: "12" } }, [
                _c("p", { staticClass: "body-2 grey--text mb-0" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("intentNew.stepSummary.titleDescription")) +
                      " "
                  ),
                ]),
                _vm.status === _vm.statusTypes.creatingWithoutTemplate
                  ? _c("p", { staticClass: "mb-0" }, [
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("intentNew.stepName.description.title")
                            ) +
                            " "
                        ),
                      ]),
                      _vm._v(" " + _vm._s(_vm.newIntent.description) + " "),
                    ])
                  : _c("p", { staticClass: "mb-0" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.description(
                              _vm.newIntent.intent,
                              _vm.$vuetify.lang.current
                            )
                          ) +
                          " "
                      ),
                    ]),
              ])
            : _vm._e(),
          _vm.newIntent.scenario
            ? _c("v-col", { attrs: { cols: "12" } }, [
                _c("p", { staticClass: "body-2 grey--text mb-0" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("intentNew.stepSummary.titleScenario")) +
                      " "
                  ),
                ]),
                _c("p", { staticClass: "mb-0" }, [
                  _vm.status === _vm.statusTypes.creatingWithoutTemplate
                    ? _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("intentNew.stepName.scenario.title")
                            ) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" " + _vm._s(_vm.newIntent.scenario) + " "),
                ]),
              ])
            : _vm._e(),
          _vm.newIntent.samples && _vm.newIntent.samples.length > 0
            ? _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("p", { staticClass: "body-2 grey--text mb-3" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("intentNew.stepSummary.typicalIntents")) +
                        " "
                    ),
                  ]),
                  _vm.status === _vm.statusTypes.creatingWithoutTemplate
                    ? _vm._l(_vm.newIntent.samples, function (sample, index) {
                        return _c(
                          "p",
                          { key: index, staticClass: "mb-2" },
                          [
                            _c(
                              "v-chip",
                              {
                                attrs: {
                                  color: "grey lighten-3",
                                  "text-color": "primary lighten-3",
                                },
                              },
                              [_vm._v(" " + _vm._s(sample) + " ")]
                            ),
                          ],
                          1
                        )
                      })
                    : _vm._l(
                        _vm
                          .samples(
                            _vm.newIntent.intent,
                            _vm.$vuetify.lang.current
                          )
                          .filter((sample) => sample !== ""),
                        function (sample, index) {
                          return _c(
                            "p",
                            { key: index, staticClass: "mb-2" },
                            [
                              _c(
                                "v-chip",
                                {
                                  attrs: {
                                    color: "grey lighten-3",
                                    "text-color": "primary lighten-3",
                                  },
                                },
                                [_vm._v(" " + _vm._s(sample) + " ")]
                              ),
                            ],
                            1
                          )
                        }
                      ),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }