var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-fade-transition",
    [
      _vm.contact
        ? _c(
            "v-list-item",
            [
              _c(
                "v-list-item-avatar",
                { staticClass: "mr-4", attrs: { size: _vm.avatarSize } },
                [_c("v-img", { attrs: { src: _vm.contact.profileImage } })],
                1
              ),
              _c(
                "v-list-item-content",
                [
                  _vm.title
                    ? _c("v-list-item-title", {
                        staticClass:
                          "primary--text text--lighten-2 font-weight-bold mb-2",
                        domProps: { innerHTML: _vm._s(_vm.title) },
                      })
                    : _vm._e(),
                  _c("v-list-item-title", { staticClass: "primary--text" }, [
                    _vm._v(" " + _vm._s(_vm.contact.name) + " "),
                  ]),
                  _vm.large
                    ? _c(
                        "v-list-item-title",
                        { staticClass: "primary--text" },
                        [
                          _c(
                            "a",
                            { attrs: { href: "mailto:" + _vm.contact.eMail } },
                            [_vm._v(_vm._s(_vm.contact.eMail))]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "v-list-item-title",
                    [
                      _c(
                        "BtnSquare",
                        {
                          staticClass: "grow-0 mt-2 px-2 font-weight-medium",
                          attrs: {
                            color: "gray",
                            outlined: "",
                            "with-slot": "",
                            small: "",
                          },
                          on: {
                            click: function ($event) {
                              _vm.contactFormShown = true
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "welcome.supportContact.contact.contactBtn"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _vm.contactFormStatus.message
                        ? _c(
                            "p",
                            {
                              class: _vm.contactFormStatus.success
                                ? "success--text"
                                : "error--text",
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    color: _vm.contactFormStatus.success
                                      ? "success"
                                      : "error",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.contactFormStatus.success
                                          ? "check"
                                          : "warning"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.contactFormStatus.message) +
                                  " "
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "HubDialog",
                    {
                      attrs: {
                        title: _vm.$t("welcome.supportContact.contact.title"),
                        "primary-label": _vm.$t("common.send"),
                        "async-dialog": "",
                        disabled: !_vm.contactFormContent.length,
                      },
                      on: {
                        onSecondary: function ($event) {
                          _vm.contactFormShown = false
                        },
                        onPrimary: _vm.sendForm,
                      },
                      model: {
                        value: _vm.contactFormShown,
                        callback: function ($$v) {
                          _vm.contactFormShown = $$v
                        },
                        expression: "contactFormShown",
                      },
                    },
                    [
                      _c("p", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "welcome.supportContact.contact.description"
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _c("v-textarea", {
                        attrs: {
                          placeholder: _vm.$t(
                            "welcome.supportContact.contact.placeholder"
                          ),
                          filled: "",
                          outlined: "",
                          "auto-grow": "",
                          autofocus: "",
                          maxlength: 4096,
                        },
                        model: {
                          value: _vm.contactFormContent,
                          callback: function ($$v) {
                            _vm.contactFormContent = $$v
                          },
                          expression: "contactFormContent",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }