<template>
    <EditModal
        :open="open"
        @save="onSave"
        @cancel="onCancel"
        :save-button-label="'Add'"
        maxWidth="800px"
    >
        <template #title>
            Files
        </template>
        <template #subtitle>
            <v-text-field
                v-model="filter"
                append-icon="mdi-magnify"
                label="Search files"
                single-line
                hide-details
            ></v-text-field>
        </template>
        
        <template #content>
            <v-data-table
                v-model="selected"
                :headers="headers"
                :items="filteredFiles"
                :search="filter"
                item-key="url"
                show-select
            >
            <template v-slot:item.name="{ item }">
                <a :href="item.url" target="_blank">{{ item.name }}</a>
            </template>
            </v-data-table>
        </template>
    </EditModal>
</template>
<script>
import { mapGetters } from 'vuex';
import EditModal from '@/components/common/EditModal.vue';

export default {
    name: 'LivechatFilesModal',
    components: {
        EditModal
    },
    props: {
        open: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            filter: '',
            selected: []
        }
    },
    computed: {
        ...mapGetters('livechat', ['activeChat', 'activeChatFiles', 'activeChatGroups', 'organization']),
        filteredFiles() {
            if (this.activeChatFiles?.length === 0) return [];

            return this.activeChatFiles?.filter(file => 
            file.name.toLowerCase().includes(this.filter.toLowerCase()) 
            || file.description.toLowerCase().includes(this.filter.toLowerCase())
            );
        },
        headers() {
            return [
                { text: 'Name', value: 'name' },
                { text: 'Description', value: 'description' },
                { text: 'Group', value: 'group'}
            ];
        }
    },
    methods: {
        onSave() {
            this.$emit('add', this.selected);
            this.clearFields();
        },
        onCancel() {
            this.$emit('cancel');
            this.clearFields();
        },
        clearFields() {
            this.selected = [];
            this.filter = '';
        }
    }
}
</script>