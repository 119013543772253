var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "header", class: { "with-shadow": _vm.shadow } },
    [
      _c("div", { staticClass: "operator-avatar-wrapper" }, [
        _c("img", {
          staticClass: "operator-avatar",
          attrs: { src: _vm.avatar, alt: "Operator avatar" },
        }),
      ]),
      _c("div", { staticClass: "header-wrapper" }, [
        _c("div", { staticClass: "opderator" }, [
          _c("div", { staticClass: "operator-name" }, [
            _c("span", [_vm._v(_vm._s(_vm.name))]),
          ]),
          _c("span", { staticClass: "operator-title" }, [
            _vm._v(_vm._s(_vm.title)),
          ]),
        ]),
        _c("div", { staticClass: "powered-by" }, [
          _c("span", [_vm._v("by")]),
          _c(
            "svg",
            {
              staticStyle: { fill: "#a6a6a6" },
              attrs: {
                width: "54",
                height: "17",
                viewBox: "0 0 54 17",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  "fill-rule": "evenodd",
                  "clip-rule": "evenodd",
                  d: "M20.8577 3.72746C21.5585 3.72746 22.176 3.8793 22.7097 4.18265C23.2433 4.48626 23.6592 4.89944 23.9574 5.42253C24.2556 5.94586 24.4047 6.53173 24.4047 7.18038C24.4047 7.82928 24.2556 8.41514 23.9574 8.93823C23.6592 9.4616 23.2433 9.87747 22.7097 10.186C22.176 10.4945 21.5534 10.649 20.8419 10.649C20.1408 10.649 19.5209 10.5051 18.9821 10.2174C18.4431 9.92974 18.0168 9.52677 17.7029 9.00884C17.389 8.49093 17.2321 7.88151 17.2321 7.18038C17.2321 6.53173 17.3863 5.94586 17.6951 5.42253C18.0036 4.89944 18.4327 4.48626 18.9821 4.18265C19.5314 3.8793 20.1565 3.72746 20.8577 3.72746ZM21.8935 9.14226C22.2074 8.94366 22.4559 8.67413 22.639 8.33398C22.822 7.99406 22.9137 7.61469 22.9137 7.19611C22.9137 6.77774 22.822 6.39834 22.639 6.0582C22.4559 5.7183 22.2074 5.44605 21.8935 5.24201C21.5796 5.03801 21.2186 4.93598 20.8106 4.93598C20.4128 4.93598 20.0545 5.0407 19.7354 5.24988C19.4161 5.45935 19.165 5.734 18.9821 6.07389C18.7989 6.414 18.7074 6.788 18.7074 7.19611C18.7074 7.61469 18.8016 7.99406 18.9899 8.33398C19.1783 8.67413 19.4294 8.94366 19.7433 9.14226C20.0572 9.34115 20.4128 9.44051 20.8106 9.44051C21.2186 9.44051 21.5796 9.34115 21.8935 9.14226Z",
                },
              }),
              _c("path", {
                attrs: {
                  d: "M26.1852 10.4872H27.6605V3.98938H26.1852V10.4872Z",
                },
              }),
              _c("path", {
                attrs: {
                  d: "M27.5271 0.719656C27.349 0.562706 27.1504 0.48423 26.9307 0.48423C26.7109 0.48423 26.5094 0.562706 26.3264 0.719656C26.1432 0.876607 26.0518 1.07035 26.0518 1.30038C26.0518 1.54117 26.1432 1.73737 26.3264 1.88892C26.5094 2.04073 26.7109 2.11652 26.9307 2.11652C27.1504 2.11652 27.349 2.04073 27.5271 1.88892C27.7049 1.73737 27.7939 1.54117 27.7939 1.30038C27.7939 1.07035 27.7049 0.876607 27.5271 0.719656",
                },
              }),
              _c("path", {
                attrs: {
                  d: "M35.8214 10.4872V5.68445C35.8109 5.10913 35.6098 4.64075 35.2172 4.27976C34.825 3.91877 34.3252 3.7383 33.7184 3.7383C33.0906 3.7383 32.5414 3.88223 32.0706 4.16989C31.6189 4.44595 31.2927 4.81107 31.0915 5.26474L31.0031 3.98938H29.6692V10.4872H31.1445V7.44234C31.1445 6.96116 31.2307 6.53742 31.4033 6.171C31.5762 5.8049 31.8165 5.51721 32.1253 5.30781C32.4338 5.09859 32.7712 4.9939 33.1377 4.9939C33.5458 4.9939 33.8463 5.10645 34.0403 5.33133C34.2335 5.55646 34.3357 5.84144 34.3462 6.18673V10.4872H35.8214",
                },
              }),
              _c("path", {
                attrs: {
                  "fill-rule": "evenodd",
                  "clip-rule": "evenodd",
                  d: "M47.4144 4.14339C47.8001 4.39582 48.0962 4.68939 48.3043 5.02402L48.4108 3.99431H49.7607V10.492H48.301V9.42742C48.2637 9.47342 48.2223 9.52206 48.1756 9.5739C48.0288 9.73625 47.8432 9.90106 47.6185 10.0683C47.393 10.2358 47.1291 10.3743 46.8257 10.4842C46.522 10.5941 46.1769 10.649 45.7898 10.649C45.183 10.649 44.631 10.518 44.134 10.2566C43.6371 9.9952 43.242 9.61069 42.9491 9.10304C42.6558 8.59565 42.5094 7.97568 42.5094 7.24316C42.5094 6.51089 42.6613 5.88308 42.9647 5.35975C43.2681 4.8367 43.6762 4.43377 44.1891 4.15126C44.7016 3.86875 45.2613 3.72746 45.8684 3.72746C46.4749 3.72746 46.9907 3.86628 47.4144 4.14339ZM47.9008 8.65572C48.0944 8.40459 48.2278 8.11179 48.301 7.77679V6.50549C48.2173 6.19162 48.0708 5.91694 47.8617 5.68148C47.6522 5.44605 47.401 5.26311 47.1081 5.13218C46.8152 5.00148 46.4962 4.93598 46.1508 4.93598C45.7532 4.93598 45.3921 5.03018 45.0677 5.21849C44.7433 5.40683 44.4845 5.6685 44.291 6.00324C44.097 6.33828 44.0006 6.73062 44.0006 7.18038C44.0006 7.599 44.0999 7.98083 44.2989 8.32612C44.4976 8.67145 44.7643 8.94366 45.0993 9.14226C45.4338 9.34115 45.7949 9.44051 46.1823 9.44051C46.5484 9.44051 46.8779 9.37258 47.1712 9.23647C47.4637 9.10062 47.7069 8.90684 47.9008 8.65572Z",
                },
              }),
              _c("path", {
                attrs: {
                  d: "M51.784 1.88892C51.9667 2.04073 52.1683 2.11652 52.3883 2.11652C52.6079 2.11652 52.8066 2.04073 52.9845 1.88892C53.1625 1.73737 53.2513 1.54117 53.2513 1.30038C53.2513 1.07035 53.1625 0.876607 52.9845 0.719656C52.8066 0.562706 52.6079 0.48423 52.3883 0.48423C52.1683 0.48423 51.9667 0.562706 51.784 0.719656C51.6006 0.876607 51.5093 1.07035 51.5093 1.30038C51.5093 1.54117 51.6006 1.73737 51.784 1.88892",
                },
              }),
              _c("path", {
                attrs: { d: "M51.6427 10.492H53.118V3.99431H51.6427V10.492Z" },
              }),
              _c("path", {
                attrs: {
                  d: "M13.389 3.72256C12.8971 3.72256 12.4289 3.85599 11.9843 4.12281C11.5774 4.36682 11.252 4.70103 11.0073 5.12439C10.9503 4.84605 10.837 4.60602 10.6659 4.40532C10.4827 4.19099 10.25 4.02349 9.96746 3.90308C9.68495 3.78292 9.36568 3.72256 9.01006 3.72256C8.42394 3.72256 7.90872 3.86654 7.46411 4.1542C7.05375 4.41978 6.73061 4.77221 6.4941 5.21117L6.38898 3.98938H5.05493V10.4872H6.53023V7.42661C6.53023 6.99769 6.61386 6.60017 6.78136 6.23382C6.94864 5.86768 7.17892 5.57216 7.47194 5.34703C7.76478 5.12215 8.09974 5.00956 8.47646 5.00956C8.85311 5.02011 9.12239 5.13784 9.28474 5.36272C9.44684 5.58785 9.53315 5.86768 9.54369 6.20243V10.4872H11.0191V7.41091C11.0294 6.982 11.1157 6.58694 11.278 6.22595C11.4401 5.86496 11.6704 5.57216 11.9686 5.34703C12.2668 5.12215 12.6042 5.00956 12.981 5.00956C13.3576 5.02011 13.6217 5.13784 13.7735 5.36272C13.9251 5.58785 14.0063 5.86768 14.0168 6.20243V10.4872H15.4921V5.66876C15.471 5.10373 15.2749 4.63828 14.9036 4.27193C14.5321 3.9058 14.0271 3.72256 13.389 3.72256",
                },
              }),
              _c("path", {
                attrs: {
                  d: "M40.1479 6.12565C39.9652 5.92745 39.8322 5.68188 39.7484 5.38936C39.6651 5.09703 39.623 4.7994 39.623 4.49634V1.89574C39.623 1.39436 39.5632 1.0159 39.4429 0.759817C39.3229 0.504019 39.1689 0.32387 38.9807 0.219369C38.793 0.115049 38.5762 0.0524136 38.3305 0.0313539C38.0854 0.0105117 37.8321 0 37.5707 0H35.8214V1.30037H37.2731C37.4823 1.30037 37.6468 1.34217 37.7668 1.42564C37.8868 1.50941 37.9702 1.66854 38.0173 1.90357C38.0644 2.13848 38.088 2.48073 38.088 2.92965V4.77834C38.088 5.26946 38.1452 5.66887 38.2601 5.97686C38.375 6.28507 38.5106 6.52517 38.6676 6.69757C38.8241 6.86988 38.9757 7.00299 39.1217 7.09697C38.9862 7.19107 38.8397 7.32417 38.6831 7.49649C38.5265 7.66892 38.3881 7.89601 38.2681 8.17798C38.1481 8.46009 38.088 8.81524 38.088 9.24343V11.2958C38.088 11.7449 38.0644 12.0869 38.0173 12.3219C37.9702 12.5569 37.8868 12.716 37.7668 12.7997C37.6468 12.8832 37.4823 12.925 37.2731 12.925H35.8214V14.2098H37.5707C37.8321 14.2098 38.0854 14.2019 38.3305 14.1862C38.5762 14.1706 38.7955 14.1079 38.9887 13.9982C39.1819 13.8885 39.336 13.6874 39.4509 13.3951C39.5658 13.1025 39.623 12.6795 39.623 12.126V9.65067C39.623 9.35845 39.6651 9.06317 39.7484 8.76548C39.8322 8.46785 39.9652 8.21988 40.1479 8.02135C40.3309 7.82304 40.5738 7.72369 40.8765 7.72369V6.43901C40.5738 6.42879 40.3309 6.32425 40.1479 6.12565",
                },
              }),
              _c("path", {
                attrs: {
                  d: "M6.53028 15.2495V12.925H3.60335C3.39431 12.925 3.22975 12.8832 3.10984 12.7997C2.98964 12.716 2.90617 12.5569 2.85919 12.3219C2.81218 12.0869 2.78865 11.7449 2.78865 11.2958V9.24343C2.78865 8.81524 2.72837 8.46009 2.60847 8.17798C2.48827 7.89601 2.35002 7.66892 2.19329 7.49649C2.03667 7.32417 1.89026 7.19107 1.75466 7.09697C1.90077 7.00299 2.05236 6.86988 2.20895 6.69757C2.36572 6.52517 2.50128 6.28507 2.61633 5.97686C2.73116 5.66887 2.78865 5.26946 2.78865 4.77834V2.92965C2.78865 2.48073 2.81218 2.13848 2.85919 1.90357C2.90617 1.66854 2.98964 1.50941 3.10984 1.42564C3.22975 1.34217 3.39431 1.30037 3.60335 1.30037H5.05498V0H3.30565C3.04441 0 2.79112 0.0105117 2.54583 0.0313539C2.30029 0.0524136 2.08361 0.115049 1.89563 0.219369C1.70761 0.32387 1.55338 0.504019 1.43347 0.759817C1.31328 1.0159 1.25332 1.39436 1.25332 1.89574V4.49634C1.25332 4.7994 1.21142 5.09703 1.12798 5.38936C1.04429 5.68188 0.911077 5.92745 0.728463 6.12565C0.545631 6.32425 0.302738 6.42879 0 6.43901V7.72369C0.302738 7.72369 0.545631 7.82304 0.728463 8.02135C0.911077 8.21988 1.04429 8.46785 1.12798 8.76548C1.21142 9.06317 1.25332 9.35845 1.25332 9.65067V12.126C1.25332 12.6795 1.31059 13.1025 1.42564 13.3951C1.54048 13.6874 1.69442 13.8885 1.8878 13.9982C2.08092 14.1079 2.30029 14.1706 2.54583 14.1862C2.79112 14.2019 3.04441 14.2098 3.30565 14.2098H5.05498V16.9844H6.53028L9.54374 14.9474V14.2098H8.06844L6.53028 15.2495",
                },
              }),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }