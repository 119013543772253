var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.items?.length
    ? _c(
        "BtnSquare",
        {
          staticClass: "DropDownItem",
          attrs: {
            id: _vm.id,
            disabled: _vm.disabled,
            "with-slot": true,
            tile: true,
            "color-btn": _vm.options?.colorBtn,
            "color-icon": _vm.iconColor || _vm.options?.colorIcon,
          },
          on: { click: _vm.handleButtonClick },
        },
        [
          _vm.icon && _vm.iconSide === "left"
            ? _c(
                "v-icon",
                {
                  attrs: {
                    small: "",
                    color: _vm.iconColor || _vm.options?.colorIcon,
                  },
                },
                [_vm._v(" " + _vm._s(_vm.icon) + " ")]
              )
            : _vm._e(),
          _vm._v(" " + _vm._s(_vm.label) + " "),
          _vm.icon && _vm.iconSide === "right"
            ? _c(
                "v-icon",
                {
                  attrs: {
                    small: "",
                    color: _vm.iconColor || _vm.options?.colorIcon,
                  },
                },
                [_vm._v(" " + _vm._s(_vm.icon) + " ")]
              )
            : _vm._e(),
        ],
        1
      )
    : _c(_vm.DropDown, {
        tag: "component",
        staticStyle: { width: "100%" },
        attrs: { items: _vm.items, options: _vm.options, inlined: "" },
        scopedSlots: _vm._u([
          {
            key: "actuator",
            fn: function ({ toggle }) {
              return [
                _c(
                  "BtnSquare",
                  {
                    staticClass: "DropDownItem",
                    attrs: {
                      id: _vm.id,
                      disabled: _vm.disabled,
                      "with-slot": true,
                      tile: true,
                      "color-btn": _vm.options?.colorBtn,
                      "color-icon": _vm.options?.colorIcon,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.handleDropdownClick(toggle)
                      },
                    },
                  },
                  [
                    _vm.giveAutomaticIcon(_vm.icon) && _vm.iconSide === "left"
                      ? _c(
                          "v-icon",
                          {
                            attrs: {
                              small: true,
                              color: _vm.iconColor || _vm.options?.colorIcon,
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.giveAutomaticIcon(_vm.icon)) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" " + _vm._s(_vm.label) + " "),
                    _vm.giveAutomaticIcon(_vm.icon) && _vm.iconSide === "right"
                      ? _c(
                          "v-icon",
                          {
                            attrs: {
                              small: true,
                              color: _vm.iconColor || _vm.options?.colorIcon,
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.giveAutomaticIcon(_vm.icon)) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }