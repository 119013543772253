var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c(
        "HubBg",
        [
          _c(
            "HubPanelLogo",
            { attrs: { loading: _vm.authenticating } },
            [
              _c(
                "v-form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", [
                            _c("h1", { staticClass: "text-h5 mb-4" }, [
                              _vm._v(_vm._s(_vm.$t("login.2fa.title"))),
                            ]),
                            _c("p", { staticClass: "text-body-1" }, [
                              _vm._v(
                                " " + _vm._s(_vm.$t("login.2fa.subTitle")) + " "
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  outlined: "",
                                  "hide-details": "",
                                  label: _vm.$t("login.2fa.inputLabel"),
                                  name: "twofa_token",
                                  type: "text",
                                },
                                on: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    $event.preventDefault()
                                    return _vm.handleSubmit()
                                  },
                                },
                                model: {
                                  value: _vm.twofa_token,
                                  callback: function ($$v) {
                                    _vm.twofa_token = $$v
                                  },
                                  expression: "twofa_token",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "text-right",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    depressed: "",
                                    color: "primary",
                                    large: "",
                                    loading: _vm.authenticating,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleSubmit()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("login.btnLabel")) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm.authenticationError
                            ? _c(
                                "v-col",
                                [
                                  _c(
                                    "v-alert",
                                    { attrs: { outlined: "", type: "error" } },
                                    [
                                      _c("span", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.$t("login.2fa.errorMessage")
                                          ),
                                        },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }