var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    { staticClass: "grey lighten-2 fill-height moin-fresh" },
    [
      _c(
        "v-container",
        { staticClass: "fill-height white", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { staticClass: "fill-height white" },
            [
              _c(
                "v-col",
                {
                  staticClass: "fill-height flex-column d-flex py-0",
                  attrs: { cols: "12" },
                },
                [
                  !_vm.hideHeader
                    ? _c(
                        "v-row",
                        { staticClass: "flex-grow-0" },
                        [
                          _c(
                            "v-col",
                            { staticClass: "pa-0", attrs: { cols: "12" } },
                            [
                              _c(
                                "v-card-title",
                                {
                                  staticClass: "pa-0",
                                  staticStyle: { height: "60px" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "flex-nowrap",
                                      attrs: { cols: "4" },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "pa-0 ma-0 flex-nowrap",
                                        },
                                        [
                                          _vm.back !== ""
                                            ? _c(
                                                "v-tooltip",
                                                {
                                                  attrs: {
                                                    bottom: "",
                                                    color: "primary lighten-3",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function ({
                                                          on,
                                                          attrs,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "span",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {},
                                                                  "span",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _c(
                                                                  "BtnSquare",
                                                                  {
                                                                    attrs: {
                                                                      icon: "arrow_back",
                                                                      to: _vm.back,
                                                                      exact: "",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    972746367
                                                  ),
                                                },
                                                [
                                                  _vm._t(
                                                    "back-button",
                                                    function () {
                                                      return [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.back"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                      ]
                                                    }
                                                  ),
                                                ],
                                                2
                                              )
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "pl-2 pt-1",
                                              class:
                                                _vm.isAdminOnly &&
                                                "admin--text",
                                            },
                                            [
                                              _vm._t(
                                                "title",
                                                function () {
                                                  return [
                                                    _vm.title
                                                      ? _c(
                                                          "span",
                                                          {
                                                            key: _vm.title,
                                                            staticClass:
                                                              "text-truncate text-h5",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.title
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      : _c(
                                                          "v-skeleton-loader",
                                                          {
                                                            staticClass:
                                                              "pt-2 grow",
                                                            attrs: {
                                                              boilerplate: "",
                                                              type: "heading",
                                                            },
                                                          }
                                                        ),
                                                  ]
                                                },
                                                { title: _vm.title }
                                              ),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "text-right",
                                      attrs: { cols: "8" },
                                    },
                                    [
                                      _vm._t("toolbar", null, {
                                        disabled: _vm.isLoading,
                                      }),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                              _c("v-divider"),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-row",
                    {
                      staticClass: "flex-grow-1 flex-nowrap",
                      staticStyle: { height: "0%" },
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "relative pa-0 fill-height",
                          class: [
                            _vm.contentClass,
                            _vm.assistantOpen
                              ? "moin-bg-skyblue"
                              : "moin-bg-grey-lightest",
                          ],
                          attrs: {
                            cols:
                              _vm.isSidebarOpen ||
                              (_vm.$vuetify.breakpoint.mdAndUp &&
                                _vm.assistantOpen)
                                ? 12 - _vm.sidebarWidth
                                : "12",
                          },
                        },
                        [
                          _c("v-divider", { staticClass: "grey lighten-4" }),
                          _vm.isLoading
                            ? _c("v-progress-linear", {
                                attrs: {
                                  indeterminate: "",
                                  absolute: "",
                                  color: "secondary",
                                },
                              })
                            : _vm._e(),
                          _c(
                            "v-row",
                            {
                              ref: "contentContainer",
                              staticClass: "overflow-y-auto ma-0 pa-0",
                              staticStyle: { height: "0%" },
                              attrs: { "data-cy": "hub-editor-frame" },
                            },
                            [
                              _c(
                                "v-col",
                                { staticClass: "pa-0", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticClass: "px-3 pb-0 pt-1 fill-height",
                                      attrs: {
                                        flat: "",
                                        tile: "",
                                        color: "transparent",
                                      },
                                    },
                                    [
                                      !_vm.hideEditorHeader
                                        ? _c(
                                            "v-card",
                                            {
                                              staticClass:
                                                "pa-0 mx-0 mt-2 mb-0 rounded-0 rounded-t",
                                              attrs: { flat: "" },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "intersect",
                                                      rawName: "v-intersect",
                                                      value:
                                                        _vm.onSaveButtonIntersect,
                                                      expression:
                                                        "onSaveButtonIntersect",
                                                    },
                                                  ],
                                                  staticClass: "pa-4",
                                                  class: [
                                                    _vm.$vuetify.breakpoint
                                                      .smAndUp &&
                                                      "d-flex justify-space-between",
                                                  ],
                                                },
                                                [
                                                  _vm.assistantOpen &&
                                                  _vm.assistantDirty
                                                    ? [_c("AssistantHubBtns")]
                                                    : [
                                                        _c(
                                                          "div",
                                                          [
                                                            !_vm.noChannelHandling &&
                                                            _vm.channels?.length
                                                              ? _c(
                                                                  "ChannelSelection",
                                                                  {
                                                                    class: [
                                                                      _vm
                                                                        .$vuetify
                                                                        .breakpoint
                                                                        .xs &&
                                                                        "mb-2",
                                                                    ],
                                                                    attrs: {
                                                                      intent:
                                                                        _vm.content,
                                                                      disabled:
                                                                        _vm.isLoading,
                                                                      firstIsNull:
                                                                        "",
                                                                      omitBlocked:
                                                                        "",
                                                                    },
                                                                    on: {
                                                                      input:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          _vm.selectedChannel =
                                                                            $event
                                                                        },
                                                                    },
                                                                  }
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "text-right",
                                                          },
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    !_vm.draft ||
                                                                    _vm.isLoading,
                                                                  text: "",
                                                                  color:
                                                                    "primary lighten-2",
                                                                },
                                                                on: {
                                                                  click:
                                                                    _vm.reset,
                                                                },
                                                              },
                                                              [
                                                                _vm._t(
                                                                  "discardbutton",
                                                                  function () {
                                                                    return [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "editor.common.discard"
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              right:
                                                                                "",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "delete_outline"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  }
                                                                ),
                                                              ],
                                                              2
                                                            ),
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                staticClass:
                                                                  "ml-2",
                                                                attrs: {
                                                                  disabled:
                                                                    !_vm.draft ||
                                                                    _vm.isLoading ||
                                                                    _vm.disabled,
                                                                  depressed: "",
                                                                  color:
                                                                    "secondary",
                                                                  "data-cy":
                                                                    "hub-editor-frame-save-btn",
                                                                },
                                                                on: {
                                                                  click:
                                                                    _vm.save,
                                                                },
                                                              },
                                                              [
                                                                _vm._t(
                                                                  "savebutton",
                                                                  function () {
                                                                    return [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              _vm.saveLabel
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              right:
                                                                                "",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.saveIcon
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  }
                                                                ),
                                                              ],
                                                              2
                                                            ),
                                                            _vm._t(
                                                              "belt-content-after"
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                ],
                                                2
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c("v-divider", {
                                        staticClass: "grey lighten-3",
                                      }),
                                      _vm._t("default", null, {
                                        channelId: _vm.selectedChannel,
                                        isLoading: _vm.isLoading,
                                      }),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "transition",
                            { attrs: { name: "slide" } },
                            [
                              _c(
                                "v-row",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.bottomBarShown,
                                      expression: "bottomBarShown",
                                    },
                                  ],
                                  staticClass: "ma-0 pa-0 shrink rounded-0",
                                  staticStyle: { "overflow-y": "scroll" },
                                  attrs: { color: "#f5f5f5" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0 rounded-0",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          staticClass:
                                            "pa-0 mx-3 mt-0 mb-0 rounded-0",
                                          attrs: { flat: "" },
                                        },
                                        [
                                          _c("v-divider", {
                                            staticClass: "grey lighten-3",
                                          }),
                                          _c(
                                            "v-row",
                                            {
                                              staticClass:
                                                "ma-0 px-0 pt-1 pb-2",
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "pb-3 grow text-right",
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass: "mr-4",
                                                      attrs: {
                                                        disabled:
                                                          !_vm.draft ||
                                                          _vm.isLoading,
                                                        text: "",
                                                        color:
                                                          "primary lighten-2",
                                                      },
                                                      on: { click: _vm.reset },
                                                    },
                                                    [
                                                      _vm._t(
                                                        "discardbutton",
                                                        function () {
                                                          return [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "editor.common.discard"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  right: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "delete_outline"
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        }
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        depressed: "",
                                                        disabled:
                                                          !_vm.draft ||
                                                          _vm.isLoading ||
                                                          _vm.disabled,
                                                        color: "secondary",
                                                        "data-cy":
                                                          "hub-editor-frame-save-btn",
                                                      },
                                                      on: { click: _vm.save },
                                                    },
                                                    [
                                                      _vm._t(
                                                        "savebutton",
                                                        function () {
                                                          return [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    _vm.saveLabel
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  right: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.saveIcon
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        }
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.assistantOpen
                        ? _c(
                            "v-col",
                            {
                              staticClass: "pa-0 hub-editor-frame-assistant",
                              class: _vm.$vuetify.breakpoint.smAndDown
                                ? "hub-editor-frame-assistant--fixed"
                                : "hub-editor-frame-assistant--col",
                              attrs: { cols: 4 },
                            },
                            [_vm._t("assistant")],
                            2
                          )
                        : _vm._e(),
                      _c("HubContentSidebar", {
                        attrs: {
                          items: _vm.sidebarItems,
                          width: _vm.sidebarWidth,
                          value: _vm.value,
                        },
                        on: { input: _vm.closeSidebar },
                        scopedSlots: _vm._u(
                          [
                            _vm._l(_vm.sidebarItems, function (sidebarItem) {
                              return {
                                key: sidebarItem.name,
                                fn: function ({ item }) {
                                  return [
                                    sidebarItem.name
                                      ? _vm._t(
                                          `sidebar.${sidebarItem.name}`,
                                          null,
                                          { item: item }
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              }
                            }),
                            _vm._l(
                              _vm.sidebarItems,
                              function (sidebarItem, index) {
                                return {
                                  key: index,
                                  fn: function ({ item }) {
                                    return [
                                      !sidebarItem.name
                                        ? _vm._t(`sidebar.${index}`, null, {
                                            item: item,
                                          })
                                        : _vm._e(),
                                    ]
                                  },
                                }
                              }
                            ),
                          ],
                          null,
                          true
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "HubDialog",
        {
          attrs: {
            title: _vm.$t(_vm.saveConfirmationDialogTitle),
            modal: "",
            "primary-icon": _vm.saveIcon,
            "primary-label": _vm.$t(_vm.saveLabel),
            disabled: _vm.disabled,
          },
          on: { onPrimary: _vm.saveCommit },
          model: {
            value: _vm.saveDialog,
            callback: function ($$v) {
              _vm.saveDialog = $$v
            },
            expression: "saveDialog",
          },
        },
        [
          _c("span", {
            domProps: {
              innerHTML: _vm._s(_vm.$t(_vm.saveConfirmationDialogDescription)),
            },
          }),
          _vm._t("saveDialog.content", null, { disabled: _vm.disabled }),
        ],
        2
      ),
      _c(
        "HubDialog",
        {
          attrs: {
            title: _vm.$t("editor.dialogs.discard.title"),
            modal: "",
            "primary-icon": "delete",
            "primary-label": _vm.$t("editor.common.discard"),
          },
          on: { onPrimary: _vm.resetCommit },
          model: {
            value: _vm.resetDialog,
            callback: function ($$v) {
              _vm.resetDialog = $$v
            },
            expression: "resetDialog",
          },
        },
        [
          _vm._v(
            " " + _vm._s(_vm.$t("editor.dialogs.discard.description")) + " "
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }