var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._l(_vm.buttons, function (button, index) {
        return _c(
          "v-row",
          { key: index, attrs: { "data-cy": "action-button" } },
          [
            _c(
              "v-col",
              { attrs: { cols: "12", md: "6" } },
              [
                _c("v-text-field", {
                  attrs: {
                    label: "Button Text",
                    placeholder: "Name",
                    "data-cy": "button-text-input",
                  },
                  on: { input: _vm.update },
                  model: {
                    value: button.title.data,
                    callback: function ($$v) {
                      _vm.$set(button.title, "data", $$v)
                    },
                    expression: "button.title.data",
                  },
                }),
              ],
              1
            ),
            _c(
              "v-col",
              { attrs: { cols: "12", md: "6" } },
              [
                _c("v-text-field", {
                  attrs: {
                    label: "Link",
                    placeholder: "Url",
                    "append-icon": "delete",
                    "data-cy": "button-link-input",
                  },
                  on: {
                    input: _vm.update,
                    "click:append": function ($event) {
                      return _vm.removeButton(index)
                    },
                  },
                  model: {
                    value: button.url.data,
                    callback: function ($$v) {
                      _vm.$set(button.url, "data", $$v)
                    },
                    expression: "button.url.data",
                  },
                }),
              ],
              1
            ),
          ],
          1
        )
      }),
      _vm.buttons.length < 3
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-btn",
                    { attrs: { text: "" }, on: { click: _vm.addButton } },
                    [
                      _c("v-icon", { attrs: { small: "" } }, [_vm._v("add")]),
                      _vm._v(" Link"),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }