var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "relative",
      staticStyle: { width: "25px", height: "6px", overflow: "hidden" },
    },
    [
      !_vm.isActive
        ? [
            _c(
              "v-tooltip",
              {
                attrs: { bottom: "", color: "primary lighten-3" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function ({ on: onTooltip, attrs: attrsTooltip }) {
                        return [
                          _c(
                            "div",
                            _vm._g(
                              _vm._b(
                                {
                                  staticStyle: {
                                    position: "absolute",
                                    top: "0",
                                    left: "0",
                                    width: "100%",
                                    height: "100%",
                                    border: "1px solid rgb(158, 158, 158, 0.4)",
                                  },
                                },
                                "div",
                                attrsTooltip,
                                false
                              ),
                              onTooltip
                            )
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2640906592
                ),
              },
              [_c("span", [_vm._v(_vm._s("Noch nicht verfügbar"))])]
            ),
          ]
        : [
            _c("div", {
              staticClass: "secondary darken-2",
              staticStyle: {
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                opacity: "0.3",
              },
            }),
            _c("div", {
              staticClass: "secondary darken-2",
              style: {
                position: "absolute",
                top: 0,
                left: 0,
                height: "100%",
                width: _vm.barWidth,
                maxWidth: "100% !import",
              },
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }