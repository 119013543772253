var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-tooltip",
    {
      attrs: { bottom: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "div",
                _vm._g({}, on),
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mx-2 px-2 primary--text font-weight-bold",
                      attrs: {
                        disabled:
                          !_vm.isDeployable(_vm.currentBotId) ||
                          _vm.isDeploying,
                        color: "white",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.show()
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "hidden-sm-and-down pl-2" }, [
                        _vm._v(_vm._s(_vm.$t("settings.deployment.deploy"))),
                      ]),
                      _c("v-icon", { staticClass: "pl-0 mx-2" }, [
                        _vm._v("mdi-truck-delivery"),
                      ]),
                      _c(
                        "v-dialog",
                        {
                          attrs: { "max-width": "500" },
                          model: {
                            value: _vm.showDialog,
                            callback: function ($$v) {
                              _vm.showDialog = $$v
                            },
                            expression: "showDialog",
                          },
                        },
                        [
                          _c(
                            "v-card",
                            {},
                            [
                              _c(
                                "v-card-title",
                                {
                                  staticClass: "text-h5 pt-6",
                                  staticStyle: { "word-break": "break-word" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("settings.deployment.dialog.title")
                                    )
                                  ),
                                ]
                              ),
                              _c("v-card-text", [
                                _c("div", { staticClass: "text--primary" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "settings.deployment.dialog.description"
                                        )
                                      ) +
                                      " "
                                  ),
                                  _c("br"),
                                  _c("br"),
                                  _vm.selfDeploymentAllowed
                                    ? _c(
                                        "span",
                                        { staticClass: "grey--text italic" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "settings.deployment.dialog.newdisclaimer"
                                              )
                                            )
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "span",
                                        { staticClass: "grey--text italic" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "settings.deployment.dialog.disclaimer"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                ]),
                              ]),
                              _c("v-divider", { staticClass: "mt-2" }),
                              _c(
                                "v-card-actions",
                                {
                                  staticClass:
                                    "flex-column flex-sm-row justify-space-between align-stretch",
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "px-4 grey--text d-block d-sm-inline-block my-4 my-sm-0",
                                      attrs: { text: "" },
                                      on: {
                                        click: function ($event) {
                                          _vm.showDialog = false
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("common.cancel")) +
                                          " "
                                      ),
                                      _c(
                                        "v-icon",
                                        { staticClass: "ml-2 grey--text" },
                                        [_vm._v("cancel")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "px-4 white--text ml-0 ml-sm-2 d-block d-sm-inline-block",
                                      attrs: {
                                        disabled:
                                          (!_vm.isSimpleEdit && !_vm.isAdmin) ||
                                          _vm.areChannelsBlocked,
                                        color: "primary",
                                        elevation: "0",
                                      },
                                      on: { click: _vm.deploy },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("settings.deployment.deploy")
                                          ) +
                                          " "
                                      ),
                                      _c("v-icon", { staticClass: "ml-2" }, [
                                        _vm._v("mdi-truck-delivery"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _vm.isDeployable(_vm.currentBotId)
        ? _c("span", [
            _vm._v(_vm._s(_vm.$t("settings.deployment.readytodeply"))),
          ])
        : _c("span", [_vm._v(_vm._s(_vm.$t("settings.deployment.nochanges")))]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }