<template>
  <div class="button-wrapper">
    <span
      class="widget-button"
    >
    <span>
      {{ label }}
      <svg
        v-if="isExternal"
        class="btn_link"
        width="10px"
        height="10px"
        viewBox="0 0 8 7"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="path-1"
          d="M4.42528125,0.57953125 C4.46698438,0.57953125 4.50120313,0.592890625
               4.5279375,0.61965625 C4.55467188,0.646375 4.56803125,0.680609375 4.56803125,0.722203125
                L4.56803125,0.722203125 L4.56803125,1.00775 C4.56803125,1.04942187 4.55467188,1.08359375
                4.5279375,1.11035937 C4.50120313,1.13709375 4.46698438,1.15051562 4.42528125,1.15051562
                L4.42528125,1.15051562 L1.28475,1.15051562 C1.08846875,1.15051562 0.920421875,1.220375
                0.780625,1.36017187 C0.64084375,1.49995312 0.570984375,1.6679375 0.570984375,1.86421875
                L0.570984375,1.86421875 L0.570984375,5.57579687 C0.570984375,5.77210937
                0.640859375,5.94010937
                0.780625,6.07989062 C0.920421875,6.21965625 1.08846875,6.28948437 1.28475,6.28948437
                L1.28475,6.28948437 L4.996375,6.28948437 C5.19267188,6.28948437 5.36073438,6.21965625
                5.5005,6.07989062 C5.64026563,5.94009375 5.71010938,5.77209375 5.71010938,5.57579687
                L5.71010938,5.57579687 L5.71010938,4.14828125 C5.71010938,4.106625 5.72346875,4.07242187
                5.7503125,4.04573437 C5.7769375,4.01889062 5.81115625,4.00553125 5.85285938,4.00553125
                L5.85285938,4.00553125 L6.13839063,4.00553125 C6.17998438,4.00553125
                6.21420313,4.01889062
                6.24096875,4.04573437 C6.2678125,4.07246875 6.2811875,4.1066875 6.2811875,4.14839062
                L6.2811875,4.14839062 L6.2811875,5.57585937 C6.2811875,5.92973437 6.15553125,6.23239062
                5.90420313,6.48359375 C5.65289063,6.73490625 5.35023438,6.860625 4.996375,6.860625
                L4.996375,6.860625 L1.28476563,6.860625 C0.930890625,6.860625 0.628265625,6.73490625
                0.376921875,6.48359375 C0.12565625,6.23239062 3.34399175e-13,5.92971875
                3.34399175e-13,5.57585937
                L3.34399175e-13,5.57585937 L3.34399175e-13,1.86426562 C3.34399175e-13,1.51039062
                0.12565625,1.20776562 0.37690625,0.956484375 C0.62825,0.7051875 0.930875,0.57953125
                1.28475,0.57953125 L1.28475,0.57953125 Z M7.70857812,0.008546875 C7.78584375,0.008546875
                7.85284375,0.03678125 7.90935937,0.093296875 C7.96582812,0.14978125
                7.99417187,0.21665625
                7.99417187,0.29403125 L7.99417187,0.29403125 L7.99417187,2.57809375
                C7.99417187,2.65542187 7.9659375,2.72232812 7.90942187,2.77882812 C7.85289062,2.83534375
                7.78595312,2.86360937 7.708625,2.86360937 C7.63129687,2.86360937 7.56435937,2.83534375
                7.50789062,2.77882812 L7.50789062,2.77882812 L6.72271875,1.99370312
                L3.81415625,4.90226562 C3.7844375,4.93201562 3.75020312,4.94682812 3.71154687,4.94682812
                C3.67289062,4.94682812 3.63867187,4.932 3.6089375,4.90226562 L3.6089375,4.90226562
                L3.100375,4.39376562 C3.07064062,4.36403125 3.05575,4.32982812 3.05575,4.29109375
                C3.05575,4.2525 3.07059375,4.21828125 3.100375,4.18854687 L3.100375,4.18854687
                L6.00892187,1.2799375 L5.2238125,0.4948125 C5.16729687,0.438296875 5.139,0.371359375
                5.139,0.29403125 C5.139,0.216703125 5.16729687,0.14978125 5.2238125,0.093296875
                C5.28028125,0.03678125 5.34723437,0.008546875 5.42454687,0.008546875
                L5.42454687,0.008546875 Z"
        ></path>
      </svg>
    </span>
  </span>
  </div>
</template>

<script>
export default {
  name: 'WidgetButtonMessage',
  props: {
    label: {
      type: String,
      required: true,
    },
    isExternal: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.button-wrapper {
  padding-left: calc(35px + 12px + 10px);
  padding-right: 35px;
}

.widget-button {
  line-height: 1em;

  /* From the widget itself */
  font-family: "Rubik", Helvetica, Arial, sans-serif;
  font-size: 1.6em;
  position: relative;
  text-decoration: none;
  background: #FFFFFF;
  color: #333333;
  box-shadow: none;
  border-radius: 6px;
  border: 1px solid #CCCCCC;
  width: 100%;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  box-sizing: content-box;
  text-align: center;
  text-transform: none;
  font-weight: 400;
}
</style>
