var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition",
    { attrs: { name: "slide-fade" } },
    [
      _vm.isOpen
        ? _c(
            "v-col",
            {
              staticClass: "flex-column d-flex px-0 fill-height white",
              attrs: { cols: "12", md: _vm.width },
            },
            [
              _c(
                "v-row",
                { staticClass: "overflow-y-auto mx-0" },
                [
                  _vm.items.length > 0
                    ? _c(
                        "v-tabs",
                        {
                          class: { "is-prominent": _vm.isProminent() },
                          attrs: {
                            "slider-color": "primary lighten-2",
                            "background-color": _vm.isProminent()
                              ? "secondary darken-2"
                              : "grey lighten-2",
                            grow: "",
                            "center-active": "",
                          },
                          model: {
                            value: _vm.sidebarContent,
                            callback: function ($$v) {
                              _vm.sidebarContent = $$v
                            },
                            expression: "sidebarContent",
                          },
                        },
                        [
                          _vm._l(_vm.items, function (sidebarItem, index) {
                            return [
                              _vm.isTabActive(sidebarItem, _vm.value)
                                ? _c(
                                    "v-tab",
                                    {
                                      key: index + "-sidebar",
                                      class: {
                                        "white--text":
                                          _vm.isProminent(sidebarItem),
                                        "primary--text text--lighten-2":
                                          !_vm.isProminent,
                                      },
                                      attrs: { ripple: false },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            left: "",
                                            color: _vm.isProminent(sidebarItem)
                                              ? "white"
                                              : "primary lighten-3",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(sidebarItem.icon) + " "
                                          ),
                                        ]
                                      ),
                                      _vm._t(
                                        "sidebar.title",
                                        function () {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(sidebarItem.title) +
                                                " "
                                            ),
                                          ]
                                        },
                                        { itemDef: sidebarItem }
                                      ),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                            ]
                          }),
                          _c(
                            "div",
                            { staticClass: "mr-2 mt-auto mb-auto" },
                            [
                              !_vm.openSidebar
                                ? _c("BtnSquare", {
                                    attrs: {
                                      icon: "close",
                                      colorIcon: _vm.isProminent()
                                        ? "white"
                                        : "primary lighten-2",
                                    },
                                    on: { click: _vm.closeSidebar },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "v-tabs-items",
                            {
                              model: {
                                value: _vm.sidebarContent,
                                callback: function ($$v) {
                                  _vm.sidebarContent = $$v
                                },
                                expression: "sidebarContent",
                              },
                            },
                            [
                              _vm._l(_vm.items, function (sidebarItem, index) {
                                return [
                                  _vm.isTabActive(sidebarItem, _vm.value)
                                    ? _c(
                                        "v-tab-item",
                                        {
                                          key: index + "-sidebar-content",
                                          class: {
                                            "pa-6": !sidebarItem.padlessItem,
                                          },
                                          style: { minHeight: "100%" },
                                          attrs: {
                                            "reverse-transition": "none",
                                            transition: "none",
                                          },
                                        },
                                        [
                                          _vm._t(
                                            `${sidebarItem.name || index}`,
                                            null,
                                            { item: _vm.value }
                                          ),
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                ]
                              }),
                            ],
                            2
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }