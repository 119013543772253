var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c(
        "HubBg",
        [
          _c(
            "HubPanelLogo",
            { key: _vm.error, attrs: { loading: _vm.loading } },
            [
              _vm.loading
                ? _c("p", { staticClass: "text-center" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("login.callback.verifying")) + "… "
                    ),
                  ])
                : _vm._e(),
              _vm.error
                ? _c("p", { staticClass: "text-center error--text" }, [
                    _vm._v(" " + _vm._s(_vm.error) + " "),
                  ])
                : _vm._e(),
              !_vm.loading && _vm.callbackType === "invite" && _vm.success
                ? [
                    _c(
                      "v-container",
                      [
                        _c(
                          "v-row",
                          [
                            _c("v-col", [
                              _c("h1", { staticClass: "text-h5 mb-4" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("login.completeInviteFinal.title")
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("p", { staticClass: "text-body-1" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "login.completeInviteFinal.subTitle"
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              [
                                _c(
                                  "v-alert",
                                  { attrs: { outlined: "", type: "success" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("login.callback.success")
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "text-right",
                                attrs: { cols: "12" },
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      depressed: "",
                                      color: "primary",
                                      large: "",
                                      to: "/",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("login.callback.gotoDashboard")
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }