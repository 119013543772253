var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "pa-6", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "d-flex items-center",
                  staticStyle: { "min-height": "70vh" },
                },
                [
                  _c("LivechatMarketingScreen", {
                    attrs: {
                      isLoading: _vm.isLoading,
                      ctaDisabled: !_vm.isAdmin && !_vm.isOwner,
                      labels: _vm.ctaLabels,
                    },
                    on: { cta: _vm.createLivechat },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }