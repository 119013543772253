var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { staticStyle: { "max-height": "100vh", height: "100vh" } },
    [
      _c("HubMenu"),
      _c("Appbar"),
      _c(
        "HubEditorFrame",
        {
          attrs: {
            value: false,
            title: _vm.$t("menu.myProfile"),
            draft: _vm.isChanged,
            loading: _vm.isLoading,
            noChannelHandling: "",
          },
          on: { onReset: _vm.resetInputs, onSave: _vm.saveConfig },
          scopedSlots: _vm._u([
            {
              key: "toolbar",
              fn: function () {
                return [
                  _c("small", { staticClass: "grey--text" }, [
                    _vm._v("Version " + _vm._s(_vm.appVersion)),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("UserProfileComp", {
            ref: "userProfileComp",
            on: {
              onChange: function ($event) {
                _vm.isChanged = $event
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }