var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("EditModal", {
    attrs: { open: _vm.open, "save-button-label": "Add", maxWidth: "800px" },
    on: { save: _vm.onSave, cancel: _vm.onCancel },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function () {
          return [_vm._v(" Files ")]
        },
        proxy: true,
      },
      {
        key: "subtitle",
        fn: function () {
          return [
            _c("v-text-field", {
              attrs: {
                "append-icon": "mdi-magnify",
                label: "Search files",
                "single-line": "",
                "hide-details": "",
              },
              model: {
                value: _vm.filter,
                callback: function ($$v) {
                  _vm.filter = $$v
                },
                expression: "filter",
              },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "content",
        fn: function () {
          return [
            _c("v-data-table", {
              attrs: {
                headers: _vm.headers,
                items: _vm.filteredFiles,
                search: _vm.filter,
                "item-key": "url",
                "show-select": "",
              },
              scopedSlots: _vm._u([
                {
                  key: "item.name",
                  fn: function ({ item }) {
                    return [
                      _c("a", { attrs: { href: item.url, target: "_blank" } }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.selected,
                callback: function ($$v) {
                  _vm.selected = $$v
                },
                expression: "selected",
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }