var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { staticStyle: { "max-height": "100vh", height: "100vh" } },
    [
      _c("HubMenu"),
      _c("Appbar"),
      _c("HubList", {
        attrs: {
          "sidebar-items": [
            {
              title: "",
              icon: "info",
              name: "info",
            },
          ],
          title: _vm.$t("forms.title"),
          items: _vm.forms || [],
          sidebar: true,
          "sidebar-width": 5,
          value: _vm.selectedForm,
        },
        on: { input: _vm.onSidebarChanged },
        scopedSlots: _vm._u(
          [
            {
              key: "item-icon",
              fn: function () {
                return [
                  _c(
                    "v-icon",
                    { attrs: { small: "", color: "primary lighten-3" } },
                    [_vm._v("list_alt")]
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: `sidebar.info`,
              fn: function ({ item }) {
                return [
                  item
                    ? _c("FormsGeneral", { attrs: { form: item } })
                    : _vm._e(),
                ]
              },
            },
            {
              key: "toolbar",
              fn: function () {
                return [
                  _vm.isAdmin
                    ? _c(
                        "v-btn",
                        {
                          attrs: { color: "admin lighten-1", text: "" },
                          on: {
                            click: function ($event) {
                              _vm.newFormularAlert = false
                              _vm.showNewFormDialog = true
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("forms.newForm.btn")) + " "
                          ),
                          _c(
                            "v-icon",
                            { staticClass: "me-1", attrs: { right: "" } },
                            [_vm._v("mdi-plus-circle")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
      _c(
        "HubDialog",
        {
          attrs: {
            title: _vm.$t("forms.newForm.title"),
            "primary-label": _vm.$t("common.create"),
            "secondary-label": _vm.$t("common.cancel"),
            "primary-icon": "add",
            disabled: _vm.newFormular === "",
            modal: "",
          },
          on: { onPrimary: _vm.newForm },
          model: {
            value: _vm.showNewFormDialog,
            callback: function ($$v) {
              _vm.showNewFormDialog = $$v
            },
            expression: "showNewFormDialog",
          },
        },
        [
          _c("v-text-field", {
            attrs: { autofocus: "", label: _vm.$t("common.name") },
            model: {
              value: _vm.newFormular,
              callback: function ($$v) {
                _vm.newFormular = $$v
              },
              expression: "newFormular",
            },
          }),
          _c("v-checkbox", {
            attrs: { label: "Rating Form" },
            model: {
              value: _vm.ratingForm,
              callback: function ($$v) {
                _vm.ratingForm = $$v
              },
              expression: "ratingForm",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }