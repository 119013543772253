var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mb-5" },
    [
      _c("v-divider", { staticClass: "my-5" }),
      _c(
        "div",
        { staticClass: "pb-5" },
        [
          _c(
            "h2",
            {
              staticClass: "d-flex text-h6 primary--text mb-3",
              staticStyle: { "font-size": "1.1rem !important" },
            },
            [
              _c(
                "v-icon",
                {
                  staticClass: "d-inline mr-3",
                  attrs: { color: "primary lighten-3" },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.typeValues[_vm.formStep.template].icon) +
                      " "
                  ),
                ]
              ),
              _c("span", [_vm._v(_vm._s(_vm.formStep.displayName))]),
              _c(
                "span",
                { staticClass: "primary--text text--lighten-3 ml-1" },
                [
                  _vm._v(
                    " (" +
                      _vm._s(_vm.typeValues[_vm.formStep.template].label) +
                      ") "
                  ),
                ]
              ),
            ],
            1
          ),
          _c("BarPercentage", {
            attrs: {
              value: _vm.conversionRate,
              backgroundColor: "grey lighten-3",
              "no-data": _vm.noData,
              buffer: 100,
              tooltip: _vm.$t("forms.analysis.tooltipStep", {
                started: String(_vm.formStep.started),
                finished: String(_vm.formStep.finished),
              }),
            },
          }),
          _vm.isAdmin && _vm.formStep.finishedUsers.length > 0
            ? _c(
                "div",
                { staticClass: "mt-6" },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "", color: "primary lighten-3" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({
                              on: onTooltip,
                              attrs: attrsTooltip,
                            }) {
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          outlined: "",
                                          "x-small": "",
                                          color: "admin",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.openLeads(
                                              _vm.formStep.finishedUsers
                                            )
                                          },
                                        },
                                      },
                                      "v-btn",
                                      attrsTooltip,
                                      false
                                    ),
                                    onTooltip
                                  ),
                                  [
                                    _vm._v(" Leads "),
                                    _c("v-icon", { attrs: { right: "" } }, [
                                      _vm._v("mdi-list-box-outline"),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3395464609
                      ),
                    },
                    [_c("span", [_vm._v(_vm._s("Resultate anzeigen"))])]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.formStep.values && _vm.formStep.values.length
            ? [
                _c("p", { staticClass: "pt-6" }, [
                  _vm._v(_vm._s(_vm.$t("forms.analysis.answerDistribution"))),
                ]),
                _vm._l(_vm.answersValuesSorted, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticStyle: { height: "54px" } },
                    [
                      _c("p", { staticClass: "body-2 mb-2" }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                      _c("BarPercentage", {
                        attrs: {
                          small: "",
                          noBackground: "",
                          buffer: 0,
                          value: item.chosenPercentage,
                          "no-data": _vm.noData,
                          tooltip: _vm.$t("forms.analysis.tooltipStepAnswer", {
                            answers: String(item.chosen),
                            allAnswers: String(_vm.formStep.finished),
                          }),
                        },
                      }),
                    ],
                    1
                  )
                }),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }