<script>
import LivechatStateCallout from '@/components/livechat/special-views/LivechatStateCallout.vue';
import LivechatSpecialViewWrapper from '@/components/livechat/special-views/LivechatSpecialViewWrapper.vue';

export default {
  name: 'LivechatUsingOtherTab',
  emits: ['useThisTab'],
  components: { LivechatSpecialViewWrapper, LivechatStateCallout },
};
</script>

<template>
  <LivechatSpecialViewWrapper id="agent-in-other-org">
    <!-- Header content: Decoration, "Use other tab" -->
    <template>
      <LivechatStateCallout
        class="mb-4"
        classes="text--lighten-2 primary--text"
        :title="$t('livechat.screens.otherTabActive.tabInactive')"
        icon="mdi-broadcast-off"
        :description="$t('livechat.screens.otherTabActive.tabInactiveDescription')"
      />
    </template>

    <!-- Secondary container: Information and CTA -->
    <template #secondary>
      <h3 class="tet-h3 mb-2">
        {{ $t('livechat.screens.otherTabActive.title') }}
      </h3>
      <p class="text-body-1 mb-8">
        {{ $t('livechat.screens.otherTabActive.description') }}
      </p>
      <div class="d-flex mt-3 justify-end">
        <v-btn
          color="secondary"
          depressed
          @click="$emit('useThisTab')"
        >
          {{ $t('livechat.screens.otherTabActive.ctaLabel') }}
          <v-icon class="ml-2">mdi-bell-ring</v-icon>
        </v-btn>
      </div>
    </template>
  </LivechatSpecialViewWrapper>
</template>