var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("EditModal", {
    attrs: {
      open: _vm.open,
      "save-button-label": _vm.$t("common.add"),
      maxWidth: "800px",
    },
    on: { save: _vm.onSave, cancel: _vm.onCancel },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function () {
          return [_vm._v(" Notes ")]
        },
        proxy: true,
      },
      {
        key: "content",
        fn: function () {
          return [
            _c("v-textarea", {
              attrs: {
                label: "Note",
                outlined: "",
                filled: "",
                "hide-details": "",
              },
              model: {
                value: _vm.text,
                callback: function ($$v) {
                  _vm.text = $$v
                },
                expression: "text",
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }