var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _vm.$vuetify.breakpoint.mdAndUp
            ? _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "mx-auto pt-10 pa-4 text-body-2 grey--text text--darken-2 text-center",
                    staticStyle: { "max-width": "300px" },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("editor.widget.integrationCode.subTitle")
                        ) +
                        " "
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6" } },
            [
              _c("v-card-title", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("editor.widget.integrationCode.title")) +
                    " "
                ),
              ]),
              _c(
                "div",
                { staticClass: "mx-4" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { outlined: "", color: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.isDialogOpen = true
                        },
                      },
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("editor.widget.integrationCode.btnGenerate")
                          )
                        ),
                      ]),
                      _c("v-icon", { attrs: { right: "" } }, [
                        _vm._v("integration_instructions"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-row", [_c("v-col", [_c("v-divider")], 1)], 1),
      _c(
        "HubDialog",
        {
          attrs: {
            title: _vm.$t("editor.widget.integrationCode.title"),
            hideSecondary: true,
            primaryLabel: _vm.$t("common.close"),
            primaryIcon: "close",
          },
          model: {
            value: _vm.isDialogOpen,
            callback: function ($$v) {
              _vm.isDialogOpen = $$v
            },
            expression: "isDialogOpen",
          },
        },
        [
          _c(
            "div",
            {},
            [
              _c(
                "SshPre",
                {
                  attrs: { language: "js", "copy-button": "" },
                  on: {
                    copied: function ($event) {
                      _vm.codeCopied = "check"
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "copy-button",
                      fn: function () {
                        return [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v(_vm._s(_vm.codeCopied)),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [[_vm._v(_vm._s(_vm.code))]],
                2
              ),
            ],
            1
          ),
          _c("div", { staticClass: "pt-3" }, [
            _c("div", {
              domProps: {
                innerHTML: _vm._s(
                  _vm.$t("editor.widget.integrationCode.dialogInfo")
                ),
              },
            }),
            _c("div", {
              staticClass: "mb-n6",
              domProps: {
                innerHTML: _vm._s(
                  _vm.$t("editor.widget.integrationCode.dialogInfo2")
                ),
              },
            }),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }