var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "DropDownMenu" },
    [
      _vm._l(_vm.items, function (item, i) {
        return [
          _vm._t(
            "item",
            function () {
              return [
                !item.title
                  ? _c("DropDownItem", {
                      key: _vm.itemId(item.id, i),
                      class: {
                        DropDownItemSeparated: _vm.separator,
                        [`dropdown-separated-${_vm.options.type}`]: true,
                      },
                      attrs: {
                        label: item.label,
                        id: _vm.itemId(item.id, i),
                        icon: item.icon,
                        "icon-side": item.iconSide,
                        "icon-color": item.iconColor,
                        disabled: item.disabled,
                        items: item.items,
                        options: _vm.options,
                        "on-click": item.onClick,
                      },
                    })
                  : _c("p", { staticClass: "dropdown-item-title" }, [
                      _vm._v(_vm._s(item.label)),
                    ]),
              ]
            },
            { item: item, index: i, options: _vm.options }
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }