var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.aiFeedbackAvailable
    ? _c(
        "v-card",
        { attrs: { outlined: "" } },
        [
          !_vm.isEmbbeded
            ? _c(
                "v-card-title",
                [
                  _c(
                    "v-icon",
                    {
                      attrs: {
                        large: "",
                        left: "",
                        color: "secondary darken-2",
                      },
                    },
                    [_vm._v(" school ")]
                  ),
                  _c("span", { staticClass: "ml-2 text-h6" }, [
                    _vm._v("Feedback"),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c("v-card-subtitle", { staticClass: "mt-1" }, [
            _vm._v(_vm._s(_vm.$t("ai.tool.requestLabel")) + ":"),
          ]),
          _c("v-textarea", {
            ref: "request",
            staticClass: "mx-4",
            attrs: {
              value: _vm.requestedText,
              color: "secondary darken-2",
              counter: "200",
              label: _vm.$t("ai.tool.request"),
              "auto-grow": "",
              solo: "",
              flat: "",
              outlined: "",
              autofocus: "",
            },
            on: { input: _vm.update },
          }),
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-space-between align-center mx-4 mb-8",
            },
            [
              _c(
                "div",
                [
                  _vm.aiFetching
                    ? _c("span", { staticClass: "grey--text" }, [
                        _vm._v("thinking..."),
                      ])
                    : [
                        _vm.aiFetched
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.displayNameIntent(_vm.detectedIntent)
                                )
                              ),
                            ])
                          : _c("span", { staticClass: "grey--text" }, [
                              _vm._v(_vm._s(_vm.$t("ai.tool.detectedintent"))),
                            ]),
                      ],
                ],
                2
              ),
              _c(
                "div",
                [
                  _c("BtnSquare", {
                    attrs: {
                      icon: "check",
                      colorIcon: "success",
                      colorBtn: "success",
                      disabled: !_vm.requestedText,
                    },
                    on: { click: () => (_vm.feedback = "pos") },
                  }),
                  _c("BtnSquare", {
                    attrs: {
                      icon: "close",
                      colorIcon: "error",
                      colorBtn: "error",
                      disabled: !_vm.requestedText,
                    },
                    on: { click: () => (_vm.feedback = "neg") },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "div",
                    { staticClass: "px-4" },
                    [
                      _c("v-autocomplete", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errorMode,
                            expression: "errorMode",
                          },
                        ],
                        ref: "aiintent",
                        attrs: {
                          items: _vm.allIntents,
                          "item-text": "displayName",
                          "item-value": "intent",
                          "cache-items": "",
                          flat: "",
                          "hide-no-data": "",
                          color: "secondary darken-2",
                          outlined: "",
                          label: _vm.$t("ai.tool.correctintent"),
                          disabled: !_vm.errorMode,
                        },
                        model: {
                          value: _vm.newIntent,
                          callback: function ($$v) {
                            _vm.newIntent = $$v
                          },
                          expression: "newIntent",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    { staticClass: "pa-4" },
                    [
                      !_vm.isEmbbeded
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                disabled: !_vm.requestedText,
                                color: "secondary darken-2",
                                text: "",
                              },
                              on: { click: _vm.cancel },
                            },
                            [_vm._v(_vm._s(_vm.$t("common.cancel")))]
                          )
                        : _vm._e(),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled: !_vm.completed,
                            color: "secondary darken-2",
                            text: "",
                          },
                          on: { click: _vm.sendFeedback },
                        },
                        [_vm._v("Feedback")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-snackbar",
                    {
                      attrs: { timeout: 3000 },
                      model: {
                        value: _vm.savingToast,
                        callback: function ($$v) {
                          _vm.savingToast = $$v
                        },
                        expression: "savingToast",
                      },
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.$t("ai.tool.success"))),
                      _c("v-spacer"),
                      _c("v-icon", { attrs: { color: "success" } }, [
                        _vm._v("check"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.isKnowledge
            ? _c(
                "v-row",
                { staticClass: "ml-4 mr-4 mb-4" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-divider"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mt-2",
                          attrs: {
                            disabled: !!(
                              _vm.generatedAnswer ||
                              _vm.generatedAnswerErrorNoKnowledge
                            ),
                            loading: _vm.answerGenerating,
                            outlined: "",
                            small: "",
                          },
                          on: { click: _vm.generateAnswer },
                        },
                        [
                          _c("v-icon", { attrs: { left: "", small: "" } }, [
                            _vm._v("auto_awesome"),
                          ]),
                          _vm._v("Antwort generieren"),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.generatedAnswer
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-divider"),
                          _c("v-textarea", {
                            attrs: {
                              readonly: "",
                              color: "secondary darken-2",
                              label: `Antwort`,
                              "auto-grow": "",
                              solo: "",
                              flat: "",
                              outlined: "",
                            },
                            model: {
                              value: _vm.generatedAnswer,
                              callback: function ($$v) {
                                _vm.generatedAnswer = $$v
                              },
                              expression: "generatedAnswer",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.generatedAnswerErrorNoKnowledge
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-divider"),
                          _c(
                            "v-alert",
                            {
                              attrs: {
                                outlined: "",
                                color: "error",
                                icon: "mdi-alert",
                              },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  "Es konnte keine Antwort generiert werden. Nicht genügend Wissen in der Knowledge Base."
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.generatedAnswerError
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-divider"),
                          _c(
                            "v-alert",
                            {
                              attrs: {
                                outlined: "",
                                color: "error",
                                icon: "mdi-alert",
                              },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut."
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }