var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { staticStyle: { "max-height": "100vh", height: "100vh" } },
    [
      _c("HubMenu"),
      _c("Appbar"),
      _c("HubContent", {
        attrs: { isSidebarOpen: _vm.isSidebarOpen, fixedHeight: "" },
        scopedSlots: _vm._u([
          {
            key: "navbar",
            fn: function () {
              return [
                _c("HubContentNavbar", {
                  attrs: { title: _vm.$t("widgetTeaser.title") },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "v-container",
                  { attrs: { fluid: "" } },
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          [
                            _c(
                              "v-list-item-group",
                              {
                                staticClass: "white",
                                attrs: { color: "primary", rounded: "" },
                              },
                              [
                                _vm._l(_vm.subPages, function (subPage, index) {
                                  return [
                                    _c(
                                      "v-list-item",
                                      {
                                        key: subPage.slug,
                                        attrs: {
                                          value: subPage,
                                          to: `/wt/${subPage.slug}`,
                                          disabled: subPage.isDisabled,
                                        },
                                      },
                                      [
                                        _c("v-list-item-content", [
                                          _c(
                                            "div",
                                            { staticClass: "d-sm-flex" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "flex-grow-auto d-flex align-center",
                                                },
                                                [
                                                  _c(
                                                    "v-card",
                                                    {
                                                      staticClass:
                                                        "flex-shrink-0 d-flex a-lign-center justify-center",
                                                      staticStyle: {
                                                        height: "70px",
                                                        width: "116px",
                                                      },
                                                      attrs: { outlined: "" },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color:
                                                              "primary lighten-3",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                subPage.icon
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "flex-grow-auto d-flex align-center pa-4",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "pt-2" },
                                                    [
                                                      _c(
                                                        "h3",
                                                        {
                                                          staticClass:
                                                            "text-h5 text-left",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                subPage.title
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "primary--text text--lighten-3 text-body-2 text-left",
                                                        },
                                                        [
                                                          _c("span", {
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                subPage.description
                                                              ),
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    index < _vm.subPages.length - 1
                                      ? _c("v-divider", { key: index })
                                      : _vm._e(),
                                  ]
                                }),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }