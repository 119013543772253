// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/fonts/JosefinSans-VariableFont_wght.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("@/assets/fonts/Lato-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("@/assets/fonts/silka-semibold-webfont.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: \"Josefin Sans\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n@font-face {\n  font-family: \"Lato\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n@font-face {\n  font-family: \"silkaHeading\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n.widget-btn-preview[data-v-59f8bb12] {\n  background: white;\n  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);\n          box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);\n  width: 100%;\n  max-width: 461px;\n}\n.widget-btn-preview-boebbles[data-v-59f8bb12] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  height: 20px;\n  padding: 8px;\n}\n.widget-btn-preview-boebbles .boebble[data-v-59f8bb12] {\n  width: 12px;\n  height: 12px;\n  border-radius: 50%;\n  background: #dedede;\n  margin-right: 4px;\n}\n.widget-btn-preview-address[data-v-59f8bb12] {\n  height: 20px;\n  margin: 8px;\n  border: 1px solid #e8e8e8;\n  background: #f5f5f5;\n  border-radius: 20px;\n}\n.widget-btn-preview-container[data-v-59f8bb12] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: end;\n      -ms-flex-align: end;\n          align-items: flex-end;\n  -webkit-box-pack: right;\n      -ms-flex-pack: right;\n          justify-content: right;\n  height: 200px;\n  width: 100%;\n  padding-top: 20px;\n  background: #dedede;\n}\n.widget-btn-preview-container--left[data-v-59f8bb12] {\n  -webkit-box-pack: start;\n      -ms-flex-pack: start;\n          justify-content: flex-start;\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
