var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "pa-0", attrs: { flat: "" } },
    [
      _vm.isChannelFilterAvailable
        ? _c(
            "v-card-subtitle",
            { staticClass: "pb-1 pt-0" },
            [
              _c("v-icon", { attrs: { left: "", small: "" } }, [
                _vm._v("filter_alt"),
              ]),
              _vm._v(" " + _vm._s(_vm.$t("chats.filter.channels")) + " "),
            ],
            1
          )
        : _vm._e(),
      _vm.isChannelFilterAvailable
        ? _c(
            "v-card-text",
            { staticClass: "pb-0" },
            [
              _c(
                "v-chip-group",
                {
                  attrs: {
                    column: "",
                    "active-class": "primary lighten-2",
                    mandatory: "",
                  },
                  model: {
                    value: _vm.selectedChannel,
                    callback: function ($$v) {
                      _vm.selectedChannel = $$v
                    },
                    expression: "selectedChannel",
                  },
                },
                _vm._l(_vm.channels, function (channel) {
                  return _c(
                    "v-chip",
                    {
                      key: channel.channelId,
                      attrs: {
                        disabled:
                          _vm.channelsCount[channel.channelId] === 0 ||
                          _vm.channelFilterDisable ||
                          channel.blocked,
                        value: channel.channelId,
                        small: "",
                        outlined: "",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            channel.displayName ||
                              channel.channelId ||
                              channel.channel
                          ) +
                          " "
                      ),
                      !channel.blocked
                        ? _c("v-avatar", { attrs: { right: "" } }, [
                            _vm._v(
                              _vm._s(_vm.channelsCount[channel.channelId])
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-card-subtitle",
        { staticClass: "mt-2 pb-1 pt-0" },
        [
          _c("v-icon", { attrs: { left: "", small: "" } }, [
            _vm._v("query_stats"),
          ]),
          _vm._v(" " + _vm._s(_vm.$t("chats.filter.analysis.title")) + " "),
        ],
        1
      ),
      _c(
        "v-card-text",
        { staticClass: "pb-0" },
        [
          _c(
            "v-chip-group",
            {
              attrs: {
                column: "",
                "active-class": "primary lighten-2",
                multiple: "",
              },
              model: {
                value: _vm.selectedEvents,
                callback: function ($$v) {
                  _vm.selectedEvents = $$v
                },
                expression: "selectedEvents",
              },
            },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            "v-chip",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    disabled:
                                      _vm.suggestCount === 0 ||
                                      _vm.eventFilterDisable,
                                    small: "",
                                    outlined: "",
                                    value: "suggest",
                                  },
                                },
                                "v-chip",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    small: "",
                                    color: "secondary darken-2",
                                  },
                                },
                                [_vm._v("auto_awesome")]
                              ),
                              _c("v-avatar", { attrs: { right: "" } }, [
                                _vm._v(_vm._s(_vm.suggestCount)),
                              ]),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  !_vm.isEvent("suggest")
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.$t("chats.filter.dreaming"))),
                      ])
                    : _c("span", [
                        _vm._v(_vm._s(_vm.$t("chats.filter.takeback"))),
                      ]),
                ]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            "v-chip",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    disabled:
                                      _vm.thumbUpCount === 0 ||
                                      _vm.eventFilterDisable,
                                    small: "",
                                    outlined: "",
                                    value: "pos",
                                  },
                                },
                                "v-chip",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c(
                                "v-icon",
                                { attrs: { small: "", color: "success" } },
                                [_vm._v("thumb_up")]
                              ),
                              _c("v-avatar", { attrs: { right: "" } }, [
                                _vm._v(_vm._s(_vm.thumbUpCount)),
                              ]),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  !_vm.isEvent("pos")
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.$t("chats.filter.positive"))),
                      ])
                    : _c("span", [
                        _vm._v(_vm._s(_vm.$t("chats.filter.takeback"))),
                      ]),
                ]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            "v-chip",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    disabled:
                                      _vm.thumbDownCount === 0 ||
                                      _vm.eventFilterDisable,
                                    small: "",
                                    outlined: "",
                                    value: "neg",
                                  },
                                },
                                "v-chip",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c(
                                "v-icon",
                                { attrs: { small: "", color: "error" } },
                                [_vm._v("thumb_down")]
                              ),
                              _c("v-avatar", { attrs: { right: "" } }, [
                                _vm._v(_vm._s(_vm.thumbDownCount)),
                              ]),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  !_vm.isEvent("neg")
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.$t("chats.filter.negative"))),
                      ])
                    : _c("span", [
                        _vm._v(_vm._s(_vm.$t("chats.filter.takeback"))),
                      ]),
                ]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            "v-chip",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    disabled:
                                      _vm.goalsCount === 0 ||
                                      _vm.eventFilterDisable,
                                    small: "",
                                    outlined: "",
                                    value: "goal",
                                  },
                                },
                                "v-chip",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c(
                                "v-icon",
                                { attrs: { small: "", color: "primary" } },
                                [_vm._v("flag")]
                              ),
                              _c("v-avatar", { attrs: { right: "" } }, [
                                _vm._v(_vm._s(_vm.goalsCount)),
                              ]),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  !_vm.isEvent("goal")
                    ? _c("span", [_vm._v(_vm._s(_vm.$t("chats.filter.goals")))])
                    : _c("span", [
                        _vm._v(_vm._s(_vm.$t("chats.filter.takeback"))),
                      ]),
                ]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            "v-chip",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    disabled:
                                      _vm.unknownCount === 0 ||
                                      _vm.eventFilterDisable,
                                    small: "",
                                    outlined: "",
                                    value: "unknown",
                                  },
                                },
                                "v-chip",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: { small: "", color: "grey lighten-2" },
                                },
                                [_vm._v("warning")]
                              ),
                              _c("v-avatar", { attrs: { right: "" } }, [
                                _vm._v(_vm._s(_vm.unknownCount)),
                              ]),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  !_vm.isEvent("unknown")
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.$t("chats.filter.unknown"))),
                      ])
                    : _c("span", [
                        _vm._v(_vm._s(_vm.$t("chats.filter.takeback"))),
                      ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-subtitle",
        { staticClass: "mt-2 pb-1 pt-0" },
        [
          _c("v-icon", { attrs: { left: "", small: "" } }, [
            _vm._v("mdi-folder-text"),
          ]),
          _vm._v(" " + _vm._s(_vm.$t("chats.filter.intents")) + " "),
        ],
        1
      ),
      _c(
        "v-card-text",
        { staticClass: "pb-0" },
        [
          _c(
            "v-chip-group",
            {
              attrs: {
                column: "",
                multiple: "",
                "active-class": "primary lighten-2",
              },
              model: {
                value: _vm.selectedIntents,
                callback: function ($$v) {
                  _vm.selectedIntents = $$v
                },
                expression: "selectedIntents",
              },
            },
            [
              _vm._l(
                _vm.intentsToFilter.slice(0, _vm.intentFilterLimit),
                function (intent) {
                  return _c(
                    "v-chip",
                    {
                      key: intent.intent,
                      attrs: {
                        disabled: _vm.intentFilterDisable,
                        value: intent.intent,
                        outlined: "",
                        small: "",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.displayName(
                              intent.intent,
                              _vm.$vuetify.lang.current
                            )
                          ) +
                          " "
                      ),
                      _c("v-avatar", { attrs: { right: "" } }, [
                        _vm._v(_vm._s(intent.count)),
                      ]),
                    ],
                    1
                  )
                }
              ),
              _vm.intentFilterLimit < _vm.intentsToFilter.length
                ? _c(
                    "v-btn",
                    {
                      staticClass: "text-caption my-auto",
                      attrs: { color: "grey", text: "", small: "" },
                      on: {
                        click: function ($event) {
                          _vm.intentFilterLimit += 5
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("common.more")) + "...")]
                  )
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }