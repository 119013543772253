var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          class: ["relative", _vm.circleStyle && "image-upload-circle-img"],
          style: { width: `${_vm.width}px`, height: `${_vm.width}px` },
        },
        [
          _c(
            "v-icon",
            {
              staticStyle: {
                position: "absolute",
                top: "50%",
                left: "50%",
                "margin-left": "-16px",
                "margin-top": "-16px",
              },
              attrs: { size: "32", color: "white" },
            },
            [_vm._v(" insert_photo ")]
          ),
          _c(
            "v-img",
            {
              attrs: {
                src: _vm.imageSrc,
                "aspect-ratio": _vm.circleStyle ? 1 : 4 / 3,
              },
              on: {
                dragover: function ($event) {
                  $event.preventDefault()
                },
                dragleave: _vm.dragleave,
                dragenter: _vm.dragenter,
                drop: _vm.drop,
              },
            },
            [
              _c(
                "v-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.hoveringContent,
                      expression: "hoveringContent",
                    },
                  ],
                  staticClass: "fill-height ma-0 success no-pointer-events",
                  attrs: { align: "center", justify: "center" },
                },
                [
                  _c("v-icon", { attrs: { large: "", color: "white" } }, [
                    _vm._v("insert_photo"),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.hoveringContent,
                      expression: "hoveringContent",
                    },
                  ],
                  staticClass: "fill-height ma-0 error no-pointer-events",
                  attrs: { align: "center", justify: "center" },
                },
                [
                  _c("v-icon", { attrs: { large: "", color: "white" } }, [
                    _vm._v("close"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-file-input", {
        attrs: {
          accept: "image/png, image/jpeg, image/bmp, image/gif",
          "append-outer-icon": "mdi-cloud-upload",
          "show-size": "",
          label: _vm.$t("botFormEdit.replaceImage"),
        },
        on: {
          "click:append-outer": function ($event) {
            return _vm.uploadImage()
          },
        },
        model: {
          value: _vm.imageFile,
          callback: function ($$v) {
            _vm.imageFile = $$v
          },
          expression: "imageFile",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }