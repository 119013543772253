var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "HubBox",
        {
          attrs: {
            title: _vm.$t("botSettings.sso.title"),
            "title-sub": _vm.$t("botSettings.sso.subTitle"),
            icon: "mdi-login",
            expandedInitial: "",
          },
        },
        [
          _c("div", { staticClass: "text-body-2" }, [
            _vm._v(" " + _vm._s(_vm.$t("botSettings.sso.description")) + " "),
          ]),
          _c(
            "div",
            { staticClass: "py-2 d-flex align-center" },
            [
              _vm.originalProvider
                ? [
                    _c(
                      "v-icon",
                      { staticClass: "mr-2", attrs: { color: "green" } },
                      [_vm._v("mdi-checkbox-marked-circle")]
                    ),
                    _c("div", { staticClass: "green--text" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("botSettings.sso.isActive", {
                              name: _vm.originalProvider,
                            })
                          ) +
                          " "
                      ),
                    ]),
                  ]
                : [
                    _c(
                      "v-icon",
                      { staticClass: "mr-2", attrs: { color: "grey" } },
                      [_vm._v("mdi-cancel")]
                    ),
                    _c("div", { staticClass: "grey--text" }, [
                      _vm._v(
                        " " + _vm._s(_vm.$t("botSettings.sso.isInactive")) + " "
                      ),
                    ]),
                  ],
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "py-2 d-flex align-center" },
            [
              _vm.originalUse2FA
                ? [
                    _c(
                      "v-icon",
                      { staticClass: "mr-2", attrs: { color: "green" } },
                      [_vm._v("mdi-checkbox-marked-circle")]
                    ),
                    _c("div", { staticClass: "green--text" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("botSettings.sso.is2FAActive")) +
                          " "
                      ),
                    ]),
                  ]
                : [
                    _c(
                      "v-icon",
                      { staticClass: "mr-2", attrs: { color: "grey" } },
                      [_vm._v("mdi-cancel")]
                    ),
                    _c("div", { staticClass: "grey--text" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("botSettings.sso.is2FAInactive")) +
                          " "
                      ),
                    ]),
                  ],
            ],
            2
          ),
          _c("InfoBox", {
            attrs: { text: _vm.$t("botSettings.sso.changeHint") },
          }),
          _c(
            "v-btn",
            {
              staticClass: "mt-6",
              attrs: { outlined: "", color: "primary lighten-2" },
              on: {
                click: function ($event) {
                  _vm.dialogIsOpen = true
                },
              },
            },
            [
              _vm._v(" " + _vm._s(_vm.$t("common.edit")) + " "),
              _c("v-icon", { attrs: { right: "" } }, [_vm._v("edit")]),
            ],
            1
          ),
          _c(
            "HubDialog",
            {
              attrs: {
                title: _vm.$t("botSettings.sso.dialog.title"),
                modal: "",
                "async-dialog": "",
                primaryIcon: "cloud_upload",
                primaryLabel: _vm.$t("editor.common.deploy"),
                isLoading: _vm.isLoading,
                disabled: !_vm.isChanged,
              },
              on: {
                onSecondary: function ($event) {
                  _vm.dialogIsOpen = false
                },
                onPrimary: _vm.saveSettings,
              },
              model: {
                value: _vm.dialogIsOpen,
                callback: function ($$v) {
                  _vm.dialogIsOpen = $$v
                },
                expression: "dialogIsOpen",
              },
            },
            [
              _c("p", [
                _vm._v(_vm._s(_vm.$t("botSettings.sso.dialog.description"))),
              ]),
              _c("v-select", {
                attrs: {
                  items: _vm.providers,
                  label: _vm.$t("botSettings.sso.dialog.selectProvider"),
                  outlined: "",
                  dense: "",
                  clearable: "",
                  "hide-details": "",
                },
                model: {
                  value: _vm.provider,
                  callback: function ($$v) {
                    _vm.provider = $$v
                  },
                  expression: "provider",
                },
              }),
              _c("v-switch", {
                attrs: {
                  color: _vm.use2FA ? "green" : "grey",
                  inset: "",
                  label: _vm.$t("botSettings.sso.dialog.use2FA"),
                },
                model: {
                  value: _vm.use2FA,
                  callback: function ($$v) {
                    _vm.use2FA = $$v
                  },
                  expression: "use2FA",
                },
              }),
              _c("InfoBox", {
                attrs: {
                  text: _vm.$t(
                    `botSettings.sso.dialog.${
                      _vm.use2FA === _vm.originalUse2FA
                        ? "hintIs2FAUnchanged"
                        : "hintIs2FAChanged"
                    }`
                  ),
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }