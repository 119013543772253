<template>
  <div class="d-flex flex-column justify-space-between full-height">
    <LivechatInfo />
    <LivechatEmpty />
    <LivechatChat />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import LivechatInfo from '@/components/livechat/LivechatInfo.vue';
import LivechatChat from '@/components/livechat/LivechatChat.vue';
import LivechatEmpty from '@/components/livechat/LivechatEmpty.vue';

export default {
  name: 'LivechatMain',
  components: {
    LivechatInfo,
    LivechatChat,
    LivechatEmpty,
  },
  computed: {
    ...mapGetters('livechat', ['agent', 'activeChat']),
  },
};
</script>
