<script>
import HubBox from '@/components/hub/HubBox.vue';
import TrackingService from '@/services/tracking';
import { isEqual, debounce } from 'lodash';
import { mapGetters } from 'vuex';
import ProductService from '@/services/product';

export default {
  name: 'IntentTags',
  components: {
    HubBox,
  },
  props: {
    intent: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      maxTagsPer: 10,
      isSaving: false,
      // Used to compare changes
      modifiedTags: [],
      originalTags: [],
      // Debounced save tag changes
      saveChanges: debounce(this._saveChanges, 1000),
      // Used to avoid first save when mounted/populated
      initialized: false,
      // Force saving is being called
      forceSaving: false,
    };
  },
  computed: {
    ...mapGetters('intents', ['intents']),
    /**
     * Returns a list of all existing tags
     * @returns {string[]}
     */
    allExistingTags() {
      const tags = new Set();
      for (const intent of this.intents) {
        for (const tag of intent?.tags || []) {
          tags.add(tag);
        }
      }

      return Array.from(tags);
    },
    /**
     * Returns true when you have reached the limit of tags per intent
     * @returns {boolean}
     */
    maxTagCountReached() {
      return this.modifiedTags.length >= this.maxTagsPer;
    },
  },
  methods: {
    /**
     * Removes a tag from the list
     * @param {string} tag
     */
    remove(tag) {
      this.modifiedTags = this.modifiedTags.filter((t) => t !== tag);
    },
    /**
     * Tracks opening/closing of the hub box
     * @param type
     */
    trackIt(type) {
      TrackingService.trackIntentsView({
        botId: this.currentBotId,
        type,
        source: 'IntentTags',
      });
    },
    /**
     * Updates the cached intent with the new tag list
     */
    updateCachedIntent() {
      this.$store.commit('intents/setIntent', this.intent);
    },
    forceSave() {
      this.forceSaving = true;
      this._saveChanges(true);
    },
    /**
     * Saves your browser changes to tags to the server
     * @returns {Promise<void>}
     */
    async _saveChanges(forceSave) {
      if (forceSave !== true && this.forceSaving) return;

      this.isSaving = true;
      const r = await ProductService.updateIntent({
        tags: this.modifiedTags,
        intent: this.intent.intent,
        box: this.intent.box,
        botId: this.intent.botId,
      });

      this.isSaving = false;
      if (!r.intent) return;

      this.originalTags = structuredClone(r.intent.tags);
      this.modifiedTags = structuredClone(r.intent.tags);
      this.intent.tags = r.intent.tags;
      this.updateCachedIntent();
      this.isModified = false;
      this.forceSaving = false;
    },
  },
  mounted() {
    this.initialized = false;
    if (!this.intent.tags) this.intent.tags = [];

    // Original
    this.originalTags = structuredClone(this.intent.tags);

    // New state
    this.modifiedTags = structuredClone(this.intent.tags);
  },
  watch: {
    modifiedTags(newList, oldList) {
      if (!this.initialized) {
        this.initialized = true;
        return;
      }

      if (isEqual(newList, oldList)) return;

      this.saveChanges();
    },
  },
};
</script>

<template>
  <HubBox
    :title="$t('intents.tags.title')"
    icon=""
    boxId="intents-tags"
    expandedInitial
    @onExpand="(isExpanded) => isExpanded && trackIt('hub-box-expanded')"
  >
    <p class="text-body-2">
      {{ $t('intents.tags.description') }}
    </p>

    <v-combobox
      v-model="modifiedTags"
      :items="allExistingTags"
      chips
      outlined
      :label="$t('intents.tags.tagSearchLabel')"
      multiple
      :disabled="maxTagCountReached"
      :hint="$t('intents.tags.maxHint', { max: maxTagsPer })"
      :menu-props="{closeOnContentClick: true, closeOnClick: true}"
      persistent-hint
      maxlength="32"
      @blur="forceSave"
    >
      <template v-slot:selection="{ attrs, item, select, selected }">
        <v-chip
          v-bind="attrs"
          :input-value="selected"
          close
          @click="select"
          @click:close="remove(item)"
        >
          {{ item }}
        </v-chip>
      </template>

      <template #[`no-data`]>
        <p class="px-4">
          {{ $t('intents.tags.notFound') }}
          {{ $t('intents.tags.createHint.press') }}
          <kbd>{{ $t('intents.tags.createHint.enter') }}</kbd>
          {{ $t('intents.tags.createHint.toCreate') }}
        </p>
      </template>
    </v-combobox>
  </HubBox>
</template>
