<template>
  <v-app style="max-height: 100vh; height: 100vh">
    <HubMenu />
    <Appbar />
    <HubContent :isSidebarOpen="isSidebarOpen">
      <template v-slot:navbar>
        <HubContentNavbar :title="$t('settingsLivechat.livechatStats.title')">
          <template v-slot:toolbar>
            <BtnSquare
              icon="view_sidebar"
              @click="() => (isSidebarOpen = !isSidebarOpen)"
            />
          </template>
        </HubContentNavbar>
      </template>
      <template v-slot:sidebar>
        <HubContentSidebarSimple
          :isOpen="isSidebarOpen"
          @onClose="() => (isSidebarOpen = false)"
        >
          <StatsOverviewSidebar />
        </HubContentSidebarSimple>
      </template>
      <template v-slot:content>
      <v-container>
        <v-row>
          <v-col cols="3">
            <v-select
              v-model="selectedOption"
              :items="dropdownOptions"
              item-text="label"
              item-value="value"
              @change="onDateRangeChange"
              :label="$t('settingsLivechat.livechatStats.timePeriod')"
              outlined
            ></v-select>
          </v-col>
          <v-col cols="3">
            <div class="d-flex" v-if="showDates">
              <v-menu
                v-model="datePickerA"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fromDate"
                    :label="$t('settingsLivechat.livechatStats.from')"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fromDate"
                  @change="dateChanged"
                  :allowed-dates="allowedDates"
                  @input="datePickerA = false"
                ></v-date-picker>
              </v-menu>
              <v-menu
                v-model="datePickerB"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="toDate"
                    :label="$t('settingsLivechat.livechatStats.to')"
                    readonly
                    class="ml-2"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="toDate"
                  @change="dateChanged"
                  :allowed-dates="allowedDates"
                  @input="datePickerB = false"
                ></v-date-picker>
              </v-menu>
            </div>
          </v-col>
          <v-col cols="6">
              <v-text-field
                v-model="searchText"
                :label="$t('common.search')"
              ></v-text-field>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="filteredChats"
          item-key="chatId"
          :search="searchText"
          :loading="loading"
          show-group-by
          :hide-default-footer="true"
          :items-per-page="-1"
        >
          <template v-slot:item.chatDuration="{ item }">
            {{ formatDuration(item.chatDuration) }}
          </template>
          <template v-slot:item.waitingTime="{ item }">
            {{ formatDuration(item.waitingTime) }}
          </template>
          <template v-slot:item.agentName="{ item }">
            <ProfileAvatar
              :profilePicture="item.agentPicture"
              placeholderIcon="mdi-face-agent"
              small
            />
            <span class="ml-1">{{ item.agentName }}</span>
          </template>
          <template v-slot:group.header="{ items, groupBy, group, isOpen, toggle, remove }">
            <td :colspan="headers.length - 1">
              <v-icon @click="toggle">
                {{ isOpen ? 'mdi-menu-down' : 'mdi-menu-right' }}
              </v-icon>
              <ProfileAvatar
                v-if="groupBy[0] === 'agentName'"
                :profilePicture="items[0].agentPicture"
                placeholderIcon="mdi-face-agent"
                small
                class="mr-1"
              />
              {{ group }} ({{ $t('settingsLivechat.livechatStats.avgChatDuration') }}: {{ averageDurationForField(group, groupBy[0]) }})
              <v-icon @click="remove">mdi-close</v-icon>
            </td>
          </template>
        </v-data-table>
      </v-container>
      </template>
    </HubContent>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import HubMenu from '@/components/hub/HubMenu.vue';
import Appbar from '@/components/Appbar.vue';
import HubContent from '@/components/hub/HubContent.vue';
import HubContentNavbar from '@/components/hub/HubContentNavbar.vue';
import HubContentSidebarSimple from '@/components/hub/HubContentSidebarSimple.vue';
import BtnSquare from '@/components/common/BtnSquare.vue';
import StatsOverview from '@/components/stats/StatsOverview.vue';
import StatsOverviewSidebar from '@/components/stats/StatsOverviewSidebar.vue';
import LivechatService from '@/services/livechat';
import ProfileAvatar from '@/components/common/ProfileAvatar.vue';
import moment from 'moment';

export default {
  name: 'LivechatStats',
  components: {
    HubMenu,
    Appbar,
    HubContent,
    HubContentNavbar,
    HubContentSidebarSimple,
    BtnSquare,
    StatsOverview,
    StatsOverviewSidebar,
    ProfileAvatar,
  },
  data() {
    return {
      isSidebarOpen: false,
      datePickerA: false,
      datePickerB: false,
      fromDate: null,
      toDate: moment().format('YYYY-MM-DD'),
      statsData: null,
      showDates: false,
      searchText: '',
      loading: false,
      selectedOption: 30,
      dropdownOptions: [
        { label: this.$t('settingsLivechat.livechatStats.last30Days'), value: 30 },
        { label: this.$t('settingsLivechat.livechatStats.last60Days'), value: 60 },
        { label: this.$t('settingsLivechat.livechatStats.last90Days'), value: 90 },
        { label: this.$t('settingsLivechat.livechatStats.custom'), value: 0 },
      ]
    };
  },
  computed: {
    ...mapGetters('bots', ['currentBotId', 'viewableBotId']),
    ...mapGetters('livechatManagement', ['organization']),
    ...mapGetters('livechat', ['botHasLivechat']),
    headers() {
      return [
        { text: this.$t('settingsLivechat.livechatStats.chatId'), value: 'chatId', groupable: false },
        { text: this.$t('settingsLivechat.livechatStats.date'), value: 'chatDate', groupable: false, },
        { text: `${this.$t('settingsLivechat.livechatStats.duration')} (${this.$t('settingsLivechat.livechatStats.avg')}: ${this.averageChatDuration})`, value: 'chatDuration', groupable: false },
        { text: this.$t('settingsLivechat.livechatStats.waitingTime'), value: 'waitingTime', groupable: false },
        { text: this.$t('settingsLivechat.livechatStats.agent'), value: 'agentName' },
        { text: this.$t('settingsLivechat.livechatStats.formId'), value: 'chatFormId' },
      ];
    },
    chats() {
      if (!this.statsData) return [];
      return this.statsData.map(chat => ({
        chatId: chat.uuid,
        chatDuration: chat.statistics?.duration ?? 0,
        chatDate: moment(chat.created_at).format('YYYY-MM-DD').toString(),
        waitingTime: chat.statistics?.assignedAt ? moment(chat.statistics?.assignedAt).diff(moment(chat.created_at), 'seconds') : 'N/A',
        agentName: chat.agent?.name ?? 'N/A',
        agentPicture: chat.agent?.profilePicture,
        chatFormId: chat.currentBox || 'N/A',
      }));
    },
    filteredChats() {
      // if (!this.fromDate || !this.toDate) return this.chats;

      // const start = moment(this.fromDate);
      // const end = moment(this.toDate);

      // return this.chats.filter(chat => {
      //   const chatDate = moment(chat.chatDate);
      //   return chatDate.isBetween(start, end, 'day', '[]');
      // });

      return this.chats;
    },
    averageChatDuration() {
      const totalDuration = this.chats.reduce((acc, chat) => acc + chat.chatDuration, 0);
      const averageSeconds = (totalDuration / this.chats.length) || 0;
      const averageMinutes = Math.floor(averageSeconds / 60);
      const averageSecondsRemaining = Math.floor(averageSeconds % 60);
      return `${averageMinutes}m${averageSecondsRemaining}s`;
    },
  },
  async mounted() {
    if (this.botHasLivechat) {
      this.setDatesFromOption();
      if (this.organization) {
        this.loadStats();
      } else {
        await this.loadOrganization();
        await this.loadStats();
      }
    }
  },
  methods: {
    async loadOrganization() {
      await this.$store.dispatch('livechatManagement/init', this.currentBotId);

    },
    async loadStats() {
      if (!this.organization) await this.loadOrganization();

      this.loading = true;
      this.statsData = await LivechatService.getStatsForOrganization(
        this.organization.uuid,
        this.fromDate, 
        moment(this.toDate).add(1, 'day').format('YYYY-MM-DD'), // add 1 day to include the selected date
        this.viewableBotId || this.currentBotId,
      );
      this.loading = false;
    },
    allowedDates: val => moment(val).isBefore(moment()),
    dateChanged() {
      this.loadStats();
    },
    onDateRangeChange() {
      if (this.selectedOption === 0) {
        this.showDates = true;
      } else {
        this.showDates = false;
        this.setDatesFromOption();
        this.loadStats();
      }
    },
    setDatesFromOption() {
      if (this.selectedOption === 0) return;
      const fromDate = moment().subtract(this.selectedOption, 'days').format('YYYY-MM-DD');
      this.fromDate = fromDate;
    },
    formatDuration(seconds) {
      if (typeof seconds !== 'number') return 'N/A';
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes}m${remainingSeconds.toString().padStart(2, '0')}s`;
    },
    averageDurationForField(name, field) {
      const filteredChats = this.chats.filter(chat => chat[field] === name);
      const totalDuration = filteredChats.reduce((acc, chat) => acc + chat.chatDuration, 0);
      const averageSeconds = totalDuration / filteredChats.length;
      const averageMinutes = Math.floor(averageSeconds / 60);
      const averageSecondsRemaining = Math.floor(averageSeconds % 60);
      return `${averageMinutes}m${averageSecondsRemaining}s`;
    }
  },
  watch: {
    viewableBotId() {
      this.loadStats();
    },
  }
};
</script>
