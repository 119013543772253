var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { staticStyle: { "max-height": "100vh", height: "100vh" } },
    [
      _c("HubMenu"),
      _c("Appbar"),
      _c("HubContent", {
        scopedSlots: _vm._u(
          [
            {
              key: "navbar",
              fn: function () {
                return [
                  _c("HubContentNavbar", {
                    attrs: { title: "QM Dashboard", isLoading: _vm.isLoading },
                    scopedSlots: _vm._u([
                      {
                        key: "toolbar",
                        fn: function () {
                          return [
                            _c(
                              "v-tooltip",
                              {
                                attrs: {
                                  bottom: "",
                                  color: "primary lighten-3",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c(
                                          "span",
                                          _vm._g({}, on),
                                          [
                                            _c("BtnSquare", {
                                              attrs: { icon: "mdi-chart-arc" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.exportAnalysis()
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    "Download der Analyse als ZIP mit mehreren Dateien."
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "v-tooltip",
                              {
                                attrs: {
                                  bottom: "",
                                  color: "primary lighten-3",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c(
                                          "span",
                                          _vm._g({}, on),
                                          [
                                            _c(
                                              "BtnSquare",
                                              _vm._g(
                                                {
                                                  attrs: {
                                                    icon: "mdi-thumb-up",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.exportFeedback(
                                                        true
                                                      )
                                                    },
                                                  },
                                                },
                                                on
                                              )
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    "Download der positiven Bewertungen als CSV."
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "v-tooltip",
                              {
                                attrs: {
                                  bottom: "",
                                  color: "primary lighten-3",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c(
                                          "span",
                                          _vm._g({}, on),
                                          [
                                            _c(
                                              "BtnSquare",
                                              _vm._g(
                                                {
                                                  attrs: {
                                                    icon: "mdi-thumb-down",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.exportFeedback(
                                                        false
                                                      )
                                                    },
                                                  },
                                                },
                                                on
                                              )
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    "Download der negativen Bewertungen als CSV."
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ]
              },
              proxy: true,
            },
            _vm.currentBotId
              ? {
                  key: "content",
                  fn: function () {
                    return [
                      _c("HubFrameSimple", {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "content",
                              fn: function () {
                                return [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "text-h6" },
                                            [_vm._v("KPI")]
                                          ),
                                          _c("v-divider"),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "4" } },
                                        [
                                          _c("QmDashboardKpi", {
                                            attrs: {
                                              value: `${_vm.kpi.days30}%`,
                                              text: "KPI letzten 30 Tage",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "4" } },
                                        [
                                          _c("QmDashboardKpi", {
                                            attrs: {
                                              value: `${_vm.kpi.days30Before}%`,
                                              text: "KPI 30 Tage davor",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "4" } },
                                        [
                                          _c("QmDashboardKpi", {
                                            attrs: {
                                              value: `${_vm.kpi.days30LastYear}%`,
                                              text: "KPI 30 Tage letztes Jahr",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [_c("QmDashboardKpiChart")],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            md: "6",
                                            lg: "3",
                                          },
                                        },
                                        [_c("QmDashboardModel")],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            md: "6",
                                            lg: "3",
                                          },
                                        },
                                        [_c("QmDashboardFeatures")],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            md: "6",
                                            lg: "3",
                                          },
                                        },
                                        [_c("QmDashboardLanguages")],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            md: "6",
                                            lg: "3",
                                          },
                                        },
                                        [_c("QmDashboardAmounts")],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", lg: "6" } },
                                        [_c("QmDashboardListVariants")],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", lg: "6" } },
                                        [_c("QmDashboardListIntents")],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          4064348342
                        ),
                      }),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }