var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "bot-message-wrapper",
      class: { "with-avatar": !!_vm.avatar },
    },
    [
      _vm.avatar
        ? _c("div", { staticClass: "avatar-container" }, [
            _c("img", {
              staticClass: "bot-avatar",
              attrs: { src: _vm.avatar, alt: "Avatar" },
            }),
          ])
        : _vm._e(),
      _vm.arrow || !!_vm.avatar
        ? _c(
            "svg",
            {
              attrs: {
                width: "10",
                height: "10",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("polygon", {
                attrs: { points: "0,0 100,100 100,0", fill: "white" },
              }),
            ]
          )
        : _vm._e(),
      _c(
        "span",
        {
          staticClass: "bot-message",
          class: { "with-arrow": _vm.arrow || !!_vm.avatar },
        },
        [_vm._v(" " + _vm._s(_vm.text) + " ")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }