import DemoService from '@/services/demo';
import { stepSlugs } from '@/components/demo/stepSlugs';
import { getFingerprintVisitorId } from '@/utils/fingerprint';

const state = {
  step: stepSlugs.stepCompany,
  companyUrl: '',
  stepsAmount: 4,
  loading: false,
  questions: [],
  isQuestionsDirty: true,
  intents: [],
  features: null,
  contact: {
    company: '',
    name: '',
    email: '',
    phone: '',
    privacyAccepted: false,
    marketing: false,
  },
  fingerprintId: null,
  queryParams: {},
  hasError: false,
  event: null, //String if set E.g. 'CCW 2023'
};

const getters = {
  step: (state) => state.step,
  hasError: (state) => state.hasError,
  stepsAmount: (state) => state.stepsAmount,
  loading: (state) => state.loading,
  test: (state) => state.test,
  contact: (state) => state.contact,
  companyUrl: (state) => state.companyUrl,
  questions: (state) => state.questions,
  isQuestionsDirty: (state) => state.isQuestionsDirty,
  intents: (state) => state.intents,
  fingerprintId: (state) => state.fingerprintId,
  userId: (state) => state.userId,
  queryParams: (state) => state.queryParams,
  event: (state) => state.event,
  features: (state) => state.features,
};

const actions = {
  async initializeUserIds({ state, rootGetters }) {
    const localUserId = DemoService.getLocalUserId();
    state.fingerprintId = await getFingerprintVisitorId();

    if (localUserId) {
      return;
    }

    const username = rootGetters['auth/user']?.username;

    const res = await DemoService.getUserIdWithFingerprintId({
      fingerprintId: username || state.fingerprintId,
    });

    const newUserId = res?.userId || null;

    state.userId = newUserId;

    DemoService.setLocalUserId(newUserId);

    return true;
  },
  async handleStepCompany({ state }, { language, dirty }) {
    // Go to next step directly
    if (!dirty) {
      state.step = stepSlugs.stepQuestions;
      return;
    }

    state.loading = true;
    state.hasError = false;

    state.step = stepSlugs.stepCompanyLoading;

    const { status, queries, message } = await DemoService.getQuestionsWithCompany({
      site: state.companyUrl,
      language,
      fingerprintId: state.fingerprintId,
    });

    if (status !== 'OK') {
      if (message === '404') {
        state.step = stepSlugs.stepCompany;
      }
      
      state.hasError = true;
      state.loading = false;
      return;
    }

    state.questions = queries.map((q, index) => ({
      id: index,
      text: q,
      checked: true,
    }));
    state.loading = false;
    state.step = stepSlugs.stepQuestions;
  },
  async handleStepQuestions({ state }, { dirty, language }) {
    if (!dirty) {
      state.loading = false;
      state.step = stepSlugs.stepIntents;
      return;
    }
    state.loading = true;
    state.step = stepSlugs.stepQuestionsLoading;

    const excludedQueries = state.questions
      .filter((q) => !q.checked)
      .map((i) => i.text);

    const res = await DemoService.getFeaturesWithQuestions({
      excludedQueries,
      fingerprintId: state.fingerprintId,
      language
    });

    if (res.status !== 'OK') {
      state.loading = false;
      state.hasError = true;
      return;
    }

    state.features = res.features || null;
    state.loading = false;
    state.step = stepSlugs.stepIntents;
  },
  async handleStepContact({ state }, { language }) {
    state.loading = true;
    const contact = {...state.contact};
    if (state.event) {
      contact.event = state.event;
    }
    const res = await DemoService.createDemoAccount({
      contact,
      fingerprintId: state.fingerprintId,
      language,
    });
    if (res.status !== 'OK') {
      console.error('DemoService.createDemoAccount failed');
      state.loading = false;
      state.hasError = true;
      return false;
    }

    state.loading = false;
    return true;
  },
};

const mutations = {
  setValue(state, { key, value }) {
    state[key] = value;
  },
  setQueryParams(state, params) {
    state.queryParams = params;
  },
  resetAll(state) {
    state.isQuestionsDirty = true;
    state.questions = [];
    state.intents = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
