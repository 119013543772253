var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-tab-item",
    {
      attrs: {
        transition: "none",
        color: "black",
        "reverse-transition": "none",
        value: "editor",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "px-8 pt-4", attrs: { color: "#f0f0f0" } },
        [
          _c("h2", [
            _vm._v(
              _vm._s(
                _vm.$t("channelSettings.appearance.themetype.sectionheadline")
              )
            ),
          ]),
          _c("ThemeSelection", {
            model: {
              value: _vm.themetype,
              callback: function ($$v) {
                _vm.themetype = $$v
              },
              expression: "themetype",
            },
          }),
          _c("v-divider", { staticClass: "ma-8", attrs: { color: "#666" } }),
          _c("h2", [
            _vm._v(
              _vm._s(
                _vm.$t("channelSettings.appearance.themecolors.sectionheadline")
              )
            ),
          ]),
          _c("ColorSelection", {
            model: {
              value: _vm.colorKeys,
              callback: function ($$v) {
                _vm.colorKeys = $$v
              },
              expression: "colorKeys",
            },
          }),
          _c("v-divider", { staticClass: "ma-8", attrs: { color: "#666" } }),
          _c("h2", [
            _vm._v(
              _vm._s(
                _vm.$t(
                  "channelSettings.appearance.widgetelements.sectionheadline"
                )
              )
            ),
          ]),
          _c("SettingsEditor", {
            attrs: {
              title: _vm.$t(
                "channelSettings.appearance.widgetelements.button.title"
              ),
              subtitle: _vm.$t(
                `channelSettings.appearance.widgetelements.button.subtitle`
              ),
              explaination: _vm.$t(
                `channelSettings.appearance.widgetelements.button.explanantion`
              ),
              colors: _vm.getThemeColors,
            },
            model: {
              value: _vm.widgetbutton,
              callback: function ($$v) {
                _vm.widgetbutton = $$v
              },
              expression: "widgetbutton",
            },
          }),
          _c("v-divider"),
          _c("SettingsEditor", {
            attrs: {
              title: _vm.$t(
                "channelSettings.appearance.widgetelements.widgetheader.title"
              ),
              subtitle: _vm.$t(
                `channelSettings.appearance.widgetelements.widgetheader.subtitle`
              ),
              explaination: _vm.$t(
                `channelSettings.appearance.widgetelements.widgetheader.explanantion`
              ),
              colors: _vm.getThemeColors,
              demoelement: "DemoHeader",
            },
            model: {
              value: _vm.widgetheader,
              callback: function ($$v) {
                _vm.widgetheader = $$v
              },
              expression: "widgetheader",
            },
          }),
          _c("v-divider"),
          _c("SettingsEditor", {
            attrs: {
              title: _vm.$t(
                "channelSettings.appearance.widgetelements.usermessage.title"
              ),
              subtitle: _vm.$t(
                `channelSettings.appearance.widgetelements.usermessage.subtitle`
              ),
              explaination: _vm.$t(
                `channelSettings.appearance.widgetelements.usermessage.explanantion`
              ),
              colors: _vm.getThemeColors,
              demoelement: "DemoMessage",
            },
            model: {
              value: _vm.usermessage,
              callback: function ($$v) {
                _vm.usermessage = $$v
              },
              expression: "usermessage",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }