var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "infoBlock", staticClass: "relative" }, [
    _c(
      "div",
      {
        staticClass: "d-flex moin-cursor-pointer",
        on: {
          mouseenter: function ($event) {
            return _vm.showHideWithDelay(true)
          },
          mouseleave: function ($event) {
            return _vm.showHideWithDelay(false, 150)
          },
        },
      },
      [
        _vm.mainIconShow
          ? _c(
              "v-icon",
              {
                staticClass: "box",
                class: _vm.bounceAnimation ? "bounce" : "",
                attrs: { color: _vm.mainIconColor },
              },
              [_vm._v(_vm._s(_vm.mainIcon))]
            )
          : _vm._e(),
        _vm._t("default"),
      ],
      2
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.hovering,
            expression: "hovering",
          },
        ],
        ref: "infoBox",
        staticClass: "info-box",
        style: {
          width: `${_vm.width}px`,
          top: `${_vm.top}px`,
          left: `${_vm.left ?? -_vm.width / 2}px`,
        },
        on: {
          mouseenter: function ($event) {
            return _vm.showHideWithDelay(true)
          },
          mouseleave: function ($event) {
            return _vm.showHideWithDelay(false, 150)
          },
        },
      },
      [
        _c("InfoBoxOutline", {
          staticClass: "mb-0",
          attrs: {
            block: "",
            title: _vm.title,
            body: _vm.body,
            "color-classes": _vm.iconColor,
            icon: _vm.icon,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }