var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex justify-space-between",
      style: {
        "background-color": _vm.headerColor,
        color: _vm.headerContrast === "light" ? "#FFFFFF" : "#484848",
      },
    },
    [
      _c("h3", { staticClass: "title" }, [_vm._v(_vm._s(_vm.headline))]),
      _c(
        "div",
        { staticClass: "title-close" },
        [
          _c(
            "v-icon",
            {
              attrs: {
                color: _vm.headerContrast === "light" ? "#FFFFFF" : "#484848",
              },
            },
            [_vm._v("close")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }