var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "action-text-input pt-1",
      class: [
        _vm.typeInput && "action-text-input--is-input",
        _vm.white && "action-text-input-white",
      ],
    },
    [
      _c("label", {
        staticClass: "ml-3 mt-1 action-text-label",
        domProps: { innerHTML: _vm._s(_vm.label) },
      }),
      _c(
        "div",
        { staticClass: "input-wrapper" },
        [
          _c("v-text-field", {
            staticStyle: { height: "40px" },
            attrs: {
              readonly: _vm.readOnly,
              rules: _vm.validationRules,
              outlined: "",
              dense: "",
              type: _vm.number ? "number" : "text",
              autofocus: "",
            },
            on: {
              input: function ($event) {
                return _vm.$emit("input", _vm.input)
              },
            },
            model: {
              value: _vm.input,
              callback: function ($$v) {
                _vm.input = $$v
              },
              expression: "input",
            },
          }),
          _c(
            "div",
            { staticClass: "mb-2 mr-1" },
            [_vm._t("action-buttons")],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }