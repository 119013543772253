var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.open,
        scrollable: _vm.scrollable,
        "max-width": _vm.maxWidth,
      },
      on: { "click:outside": _vm.cancel },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _vm._t("title", function () {
                return [_vm._v(_vm._s(_vm.title))]
              }),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { disabled: _vm.isDisabled, icon: "" },
                  on: { click: _vm.cancel },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            2
          ),
          _c("v-divider"),
          _vm.loading
            ? _c("v-progress-linear", { attrs: { indeterminate: "" } })
            : _vm._e(),
          _c(
            "v-card-subtitle",
            [
              _vm._t("subtitle", function () {
                return [_vm._v(_vm._s(_vm.subtitle))]
              }),
            ],
            2
          ),
          _c(
            "v-card-text",
            [
              _vm._t("content", function () {
                return [_vm._v(_vm._s(_vm.content))]
              }),
              _vm._t("error", function () {
                return [
                  _vm.error
                    ? _c(
                        "v-alert",
                        { attrs: { type: "error", dense: "", text: "" } },
                        [_vm._v(" " + _vm._s(_vm.error) + " ")]
                      )
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
          _c(
            "v-card-actions",
            { staticClass: "d-flex flex-row justify-space-between" },
            [
              _vm._t("actions", function () {
                return [
                  _c(
                    "v-btn",
                    {
                      attrs: { disabled: _vm.isDisabled, outlined: "" },
                      on: { click: _vm.cancel },
                    },
                    [_vm._v(_vm._s(_vm.$t("common.cancel")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { disabled: _vm.isDisabled, color: "primary" },
                      on: { click: _vm.save },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.saveButtonLabel || _vm.$t("common.save"))
                      ),
                    ]
                  ),
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }