var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mb-4 chat-item moin-fade-in" }, [
    _vm.item.sender?.role === "system"
      ? _c(
          "div",
          {
            staticClass:
              "text-center grey--text text--lighten-1 chat-system-message-wrapper",
          },
          [
            _c("span", { staticClass: "chat-system-message" }, [
              _c("span", { staticClass: "chat-system-message-text" }, [
                _vm._v(_vm._s(_vm.$t(`livechat.chats.${_vm.item.type}`))),
              ]),
            ]),
          ]
        )
      : _vm.item.sender?.role === "user"
      ? _c(
          "div",
          { staticClass: "d-flex chat-item-customer items-end" },
          [
            _c("div", { staticClass: "chat-item-time pt-2 grey--text" }, [
              _vm._v(
                " " + _vm._s(_vm.getTimeString(_vm.item.created_at)) + " "
              ),
            ]),
            _c(
              "div",
              { staticClass: "px-2 pt-2" },
              [
                _c("LivechatAvatar", {
                  attrs: { avatar: _vm.chat.user.avatar, small: "" },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "chat-item-text pa-2 relative" }, [
              _c("div", {
                staticClass: "chat-item-text-bg",
                style: { background: _vm.chat.user?.avatar.color?.hex },
              }),
              _c("div", { staticClass: "relative" }, [
                _vm.showTranslating
                  ? _c(
                      "span",
                      { staticClass: "translating" },
                      [
                        _c("v-icon", { staticClass: "blinking" }, [
                          _vm._v("mdi-creation"),
                        ]),
                        _vm._v(_vm._s(_vm.$t("livechat.chats.translating"))),
                      ],
                      1
                    )
                  : _c("span", [_vm._v(_vm._s(_vm.messageString))]),
              ]),
            ]),
            _vm.isTranslated
              ? _c(
                  "v-icon",
                  {
                    staticClass: "ml-2 align-self-center",
                    attrs: { small: "", title: _vm.item.data },
                  },
                  [_vm._v("mdi-web")]
                )
              : _vm._e(),
          ],
          1
        )
      : _c("div", [
          _vm.item.type === "note"
            ? _c("div", { staticClass: "d-flex justify-center" }, [
                _c("div", { staticClass: "note-container pa-2 mb-2" }, [
                  _c(
                    "div",
                    { staticClass: "note-header d-flex align-center mb-1" },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "mr-1",
                          attrs: { small: "", color: "primary" },
                        },
                        [_vm._v("mdi-note-text")]
                      ),
                      _c(
                        "span",
                        { staticClass: "font-weight-medium primary--text" },
                        [_vm._v(_vm._s(_vm.$t("livechat.chats.note")))]
                      ),
                    ],
                    1
                  ),
                  _c("span", { staticStyle: { "text-align": "center" } }, [
                    _vm._v(" " + _vm._s(_vm.item.text) + " "),
                  ]),
                  _c(
                    "span",
                    { staticClass: "d-flex justify-end" },
                    [
                      _c("span", { staticClass: "align-self-center" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.getTimeString(_vm.item.created_at)) +
                            " "
                        ),
                      ]),
                      _c(
                        "v-btn",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isAgentsChat,
                              expression: "isAgentsChat",
                            },
                          ],
                          attrs: { icon: "" },
                          on: {
                            click: function ($event) {
                              return _vm.deleteNote(_vm.item._id)
                            },
                          },
                        },
                        [
                          _c(
                            "v-icon",
                            { attrs: { small: "", color: "grey" } },
                            [_vm._v("mdi-trash-can-outline")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ])
            : _c(
                "div",
                { staticClass: "d-flex justify-end chat-item-self items-end" },
                [
                  _vm.isTranslated && !_vm.isAttachment
                    ? _c(
                        "v-icon",
                        {
                          staticClass: "mr-2 align-self-center",
                          attrs: { small: "", title: _vm.item.data },
                        },
                        [_vm._v("mdi-web")]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass:
                        "chat-item-text pa-2 relative primary--text text--lighten-2",
                      class: {
                        "agent-chat-item-bg": _vm.item.sender?.role === "agent",
                      },
                      style: {
                        border:
                          _vm.item.sender?.role === "bot"
                            ? "1px solid rgb(158, 167, 179)"
                            : "",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          style: {
                            color:
                              _vm.item.sender?.role === "bot"
                                ? "rgb(116, 123, 134)"
                                : "inherit",
                          },
                        },
                        [
                          _vm.isAttachment
                            ? _c(
                                "span",
                                _vm._l(
                                  _vm.item.attachments,
                                  function (attachment) {
                                    return _c(
                                      "a",
                                      {
                                        key: attachment.url,
                                        attrs: {
                                          href: attachment.url,
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _c("v-icon", { attrs: { small: "" } }, [
                                          _vm._v("mdi-paperclip"),
                                        ]),
                                        _vm._v(
                                          " " + _vm._s(attachment.name) + " "
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              )
                            : _c("span", [
                                _vm.showTranslating
                                  ? _c(
                                      "span",
                                      { staticClass: "translating" },
                                      [
                                        _c(
                                          "v-icon",
                                          { staticClass: "blinking" },
                                          [_vm._v("mdi-creation")]
                                        ),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "livechat.chats.translating"
                                              )
                                            )
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "white-space": "preserve-breaks",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.messageString))]
                                    ),
                              ]),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "px-2 pt-2" },
                    [
                      _vm.item.sender?.role === "bot"
                        ? _c(
                            "v-icon",
                            {
                              attrs: {
                                color:
                                  _vm.item.sender?.role === "bot"
                                    ? "rgb(140, 146, 158)"
                                    : "primary lighten-3",
                              },
                            },
                            [_vm._v(" mdi-robot ")]
                          )
                        : _c(
                            "v-icon",
                            { attrs: { color: "primary lighten-3" } },
                            [_vm._v("mdi-face-agent")]
                          ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "chat-item-time pt-2 grey--text" }, [
                    _vm._v(
                      " " + _vm._s(_vm.getTimeString(_vm.item.created_at)) + " "
                    ),
                  ]),
                ],
                1
              ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }