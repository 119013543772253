var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { staticStyle: { "max-height": "100vh", height: "100vh" } },
    [
      _c("HubContent", {
        staticClass: "moin-fresh",
        attrs: { fixedHeight: "" },
        scopedSlots: _vm._u([
          {
            key: "navbar",
            fn: function () {
              return [
                _c("HubNavbar", {
                  attrs: { isLoading: _vm.loading, title: "Livechat Profile" },
                  scopedSlots: _vm._u([
                    {
                      key: "toolbar",
                      fn: function () {
                        return [_c("LivechatHeaderMenu")]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "fill-height" },
                  [
                    _c("HubEditorFrame", {
                      attrs: { title: "Profil bearbeiten", hideHeader: "" },
                      on: {
                        onSave: _vm.saveProfile,
                        onReset: _vm.resetProfile,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({}) {
                            return [
                              _c(
                                "div",
                                { staticClass: "white pa-6" },
                                [
                                  _c(
                                    "v-container",
                                    [
                                      _c("LivechatProfile"),
                                      _c("LivechatProfileAgent"),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }