var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "chat-divider" }, [
    _c("div", { staticClass: "d-flex flex-col items-center" }, [
      _c("div", { staticClass: "chat-divider-text" }, [
        _vm._v(_vm._s(_vm.textA)),
      ]),
      _c(
        "div",
        { staticClass: "chat-divider-icon" },
        [
          _c("v-icon", { attrs: { color: "primary lighten-5", size: "20" } }, [
            _vm._v("mdi-sync"),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "chat-divider-text" }, [
        _vm._v(_vm._s(_vm.textB)),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }