var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        {
          staticClass: "white",
          attrs: {
            extended: _vm.$vuetify.breakpoint.smAndDown,
            app: "",
            elevation: "0",
            "extension-height": _vm.$vuetify.breakpoint.smAndDown ? 50 : 64,
          },
          scopedSlots: _vm._u(
            [
              _vm.$vuetify.breakpoint.smAndDown ||
              (_vm.$vuetify.breakpoint.mdAndUp && _vm.notification)
                ? {
                    key: "extension",
                    fn: function () {
                      return [
                        _vm.$vuetify.breakpoint.smAndDown
                          ? [
                              _c(
                                "v-toolbar-title",
                                {
                                  staticClass:
                                    "hidden-md-and-up subline mr-2 mt-0 pl-0",
                                },
                                [
                                  _vm.loggedIn && false
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "mr-1 mb-1 hidden-sm-and-down",
                                          attrs: {
                                            to: "/",
                                            small: "",
                                            fab: "",
                                            "rounded-4": "",
                                            icon: "",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                color: "primary lighten-2",
                                              },
                                            },
                                            [_vm._v("arrow_back")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" Preview "),
                                ],
                                1
                              ),
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "mx-2 px-2 mb-2 white--text font-weight-bold hidden-md-and-up",
                                  attrs: { color: "secondary darken-2" },
                                  on: { click: _vm.showTeaser },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "pl-2 hidden-xs-only" },
                                    [_vm._v("Teaser")]
                                  ),
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "pl-0 mx-2",
                                      attrs: { right: "" },
                                    },
                                    [_vm._v("play_circle")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "mr-0 ml-2 mx-2 px-2 mb-2 white--text font-weight-bold",
                                  attrs: { depressed: "", color: "primary" },
                                  on: { click: _vm.restart },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "pl-2 hidden-xs-only" },
                                    [_vm._v(_vm._s(_vm.$t("preview.restart")))]
                                  ),
                                  _c("v-icon", { staticClass: "pl-0 mx-2" }, [
                                    _vm._v("mdi-replay"),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          : _c(
                              "div",
                              {
                                staticClass:
                                  "white flex-grow-1 mt-n4 mx-n2 pt-2 px-2",
                              },
                              [_c("HubNotification")],
                              1
                            ),
                      ]
                    },
                    proxy: true,
                  }
                : null,
            ],
            null,
            true
          ),
        },
        [
          _vm.$vuetify.breakpoint.mdAndDown
            ? [
                _c("BtnSquare", {
                  staticClass: "mt-2 mr-2",
                  attrs: { icon: "menu" },
                  on: {
                    click: function ($event) {
                      return _vm.changeDrawer()
                    },
                  },
                }),
                _c("div", { staticClass: "mt-4 mr-4" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/moin-logo.svg"),
                      height: "38px",
                    },
                  }),
                ]),
              ]
            : _vm._e(),
          _c(
            "v-toolbar-title",
            { staticClass: "hidden-sm-and-down text-h5 mr-2 mt-2 pl-0" },
            [
              _vm.loggedIn && false
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mr-1 mb-1 hidden-sm-and-down",
                      attrs: { to: "/", small: "", tile: "", icon: "" },
                    },
                    [
                      _c("span", { staticClass: "pl-2 hidden-xs-only" }, [
                        _vm._v("Teaser"),
                      ]),
                      _c(
                        "v-icon",
                        { staticClass: "pl-0 mx-2", attrs: { right: "" } },
                        [_vm._v("play_circle")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" Preview "),
            ],
            1
          ),
          _c("v-spacer"),
          false
            ? _c(
                "v-btn",
                {
                  staticClass: "mx-2 px-2 primary--text font-weight-bold",
                  attrs: { color: "primary", outlined: "" },
                },
                [
                  _c("span", { staticClass: "hidden-sm-and-down pl-2" }, [
                    _vm._v("Teilen"),
                  ]),
                  _c("v-icon", { staticClass: "pl-0 mx-2" }, [
                    _vm._v("mdi-share"),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-btn",
            {
              staticClass:
                "mx-2 px-2 white--text font-weight-bold hidden-sm-and-down",
              attrs: { color: "secondary darken-2", depressed: "" },
              on: { click: _vm.showTeaser },
            },
            [
              _c("span", { staticClass: "pl-2" }, [_vm._v("Teaser")]),
              _c("v-icon", { staticClass: "pl-0 mx-2", attrs: { right: "" } }, [
                _vm._v("play_circle"),
              ]),
            ],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass:
                "mx-2 px-2 white--text font-weight-bold hidden-sm-and-down",
              attrs: { color: "primary", depressed: "" },
              on: { click: _vm.restart },
            },
            [
              _c("span", { staticClass: "pl-2" }, [
                _vm._v(_vm._s(_vm.$t("preview.restart"))),
              ]),
              _c("v-icon", { staticClass: "pl-0 mx-2" }, [
                _vm._v("mdi-replay"),
              ]),
            ],
            1
          ),
          !_vm.isNoBeamer
            ? _c(
                "v-btn",
                {
                  staticClass:
                    "mr-0 ml-2 mr-md-2 notification-trigger text-grey text-lighten-3",
                  attrs: { elevation: "0" },
                },
                [
                  _c("span", { staticClass: "pl-2 hidden-xs-only" }, [
                    _vm._v(_vm._s(_vm.$t("beamer.label"))),
                  ]),
                  _c("span", { staticClass: "hidden-md-and-up pl-2" }),
                  _c(
                    "v-icon",
                    {
                      staticClass: "pl-0 mx-1",
                      attrs: { right: "", color: "primary lighten-2" },
                    },
                    [_vm._v(" notifications ")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }