var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "chat-info-tag d-flex items-center",
      class: [_vm.item.href && "chat-info-tag--link"],
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return (() => null).apply(null, arguments)
        },
      },
    },
    [
      _c("v-icon", { attrs: { small: "", color: "primary lighten-2" } }, [
        _vm._v(" " + _vm._s(_vm.icon) + " "),
      ]),
      _vm.item.href
        ? _c(
            "a",
            {
              staticClass:
                "text-decoration-none text-body-2 primary--text text--lighten-2 ml-1",
              attrs: { href: _vm.item.href, target: "_blank", rel: "noopener" },
            },
            [_vm._v(" " + _vm._s(_vm.item.text) + " ")]
          )
        : _c(
            "div",
            { staticClass: "text-body-2 primary--text text--lighten-2 ml-1" },
            [_vm._v(" " + _vm._s(_vm.item.text) + " ")]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }