var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "moin-fresh" },
    [
      _c(
        "HubDialog",
        {
          attrs: {
            value: _vm.open,
            title: "Wirklich approven?",
            loading: _vm.isLoading,
            primaryLabel: "Approval",
            "primary-icon": "check",
          },
          on: {
            input: function ($event) {
              return _vm.handleClose()
            },
            onPrimary: function ($event) {
              return _vm.handleApproval()
            },
          },
        },
        [
          _c("div", { staticClass: "moin-fresh" }, [
            _c("div", { staticClass: "text-h3 mb-2" }, [
              _vm._v('"' + _vm._s(_vm.intent.displayName) + '"'),
            ]),
            _c(
              "div",
              [
                _c(
                  "v-tabs",
                  [
                    _c(
                      "v-tab",
                      {
                        on: {
                          click: function ($event) {
                            _vm.selectedTab = "inclusive"
                          },
                        },
                      },
                      [_vm._v("Inklusive")]
                    ),
                    _c(
                      "v-tab",
                      {
                        on: {
                          click: function ($event) {
                            _vm.selectedTab = "exclusive"
                            _vm.initPoll(true)
                          },
                        },
                      },
                      [_vm._v(" Exklusive ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              _vm._l(_vm.inclusiveExclusive, function (item) {
                return _c("div", { key: item.tab }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: item.tab === _vm.selectedTab,
                          expression: "item.tab === selectedTab",
                        },
                      ],
                      staticClass: "py-4",
                    },
                    [
                      _c("div", { staticClass: "text-body-2" }, [
                        _vm._v(_vm._s(item.advice)),
                      ]),
                      item.refVariants && item.refVariants.length
                        ? _c(
                            "div",
                            { staticClass: "pt-10" },
                            [
                              _c("v-divider"),
                              _c(
                                "v-expansion-panels",
                                { attrs: { flat: "" } },
                                [
                                  _c(
                                    "v-expansion-panel",
                                    [
                                      _c(
                                        "v-expansion-panel-header",
                                        [
                                          _c(
                                            "v-card-title",
                                            { staticClass: "pa-0" },
                                            [
                                              _c(
                                                "h2",
                                                { staticClass: "text-h3" },
                                                [_vm._v("Varianten")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-expansion-panel-content",
                                        _vm._l(
                                          item.refVariants,
                                          function (variant, variantIndex) {
                                            return _c(
                                              "v-card",
                                              {
                                                key: variantIndex,
                                                staticClass: "pa-3 mb-4",
                                                attrs: { outlined: "" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex justify-space-between",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "text-h4",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              variant.name
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      [
                                                        variant.isNewintent
                                                          ? _c(
                                                              "v-chip",
                                                              {
                                                                staticClass:
                                                                  "mt-n2",
                                                                attrs: {
                                                                  "x-small": "",
                                                                  color:
                                                                    "green",
                                                                  "text-color":
                                                                    "white",
                                                                },
                                                              },
                                                              [_vm._v(" NEU ")]
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "v-card",
                                                  {
                                                    staticClass:
                                                      "rounded-lg elevation-0 ma-1",
                                                    attrs: {
                                                      color: "grey lighten-3",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-card-text",
                                                      {
                                                        staticClass:
                                                          "pa-2 primary--text",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              variant.text
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      item.newIntents && item.newIntents.length
                        ? _c(
                            "div",
                            [
                              _c("v-divider"),
                              _c(
                                "v-expansion-panels",
                                { attrs: { flat: "" } },
                                [
                                  _c(
                                    "v-expansion-panel",
                                    [
                                      _c(
                                        "v-expansion-panel-header",
                                        [
                                          _c(
                                            "v-card-title",
                                            { staticClass: "pa-0" },
                                            [
                                              _c(
                                                "h2",
                                                { staticClass: "text-h3" },
                                                [
                                                  _vm._v(" Intent Vorschläge "),
                                                  _c(
                                                    "v-chip",
                                                    {
                                                      attrs: {
                                                        "x-small": "",
                                                        color: "green",
                                                        "text-color": "white",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.newIntents
                                                              .length
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-expansion-panel-content",
                                        _vm._l(
                                          item.newIntents,
                                          function (newIntent, intentIndex) {
                                            return _c(
                                              "v-card",
                                              {
                                                key: intentIndex,
                                                staticClass: "pa-3 mb-4",
                                                attrs: { outlined: "" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "text-h4" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(newIntent.name) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "my-2 text-body-2",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          newIntent.description
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("v-divider"),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                ])
              }),
              0
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }