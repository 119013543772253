var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c(
        "HubBg",
        [
          _c(
            "HubPanelLogo",
            { attrs: { loading: false } },
            [
              _c(
                "v-form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "12" } }, [
                            _c("h1", [
                              _vm._v(_vm._s(_vm.$t("maintenance.title"))),
                            ]),
                            _c("p", { staticClass: "mt-4 mb-4 mr-4" }, [
                              _vm._v(_vm._s(_vm.$t("maintenance.text"))),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }