<template>
  <v-app-bar app flat color="primary" dark :extension-height="60">
    <BtnSquare
      icon="menu"
      colorBtn="white"
      colorIcon="white"
      class="hidden-lg-and-up ml-n2 mr-2"
      @click="toggleMenuOpen()"
      data-cy="drawer"
    />

    <HubBotSelect
      v-if="isMultiBot"
      class="mr-2"
      :style="{ width: $vuetify.breakpoint.smAndDown ? '80px' : '250px' }"
      data-cy="app-bar-bot-select"
    />
    <template v-else>
      <v-toolbar-title
        v-if="currentBot"
        class="text-h6 hidden-sm-and-down mr-2 pl-lg-2"
        data-cy="app-bar-bot-title"
      >
        {{ currentBot.name }}
      </v-toolbar-title>
    </template>

    <v-spacer />
    <SearchComponent style="width: 320px" />

    <v-divider vertical class="ml-4 mr-2" inset />

    <LivechatHeaderBtn />

    <v-btn
      class="mx-2 px-2 white--text font-weight-bold"
      v-if="hasWebchatChannel"
      outlined
      dark
      target="_blank"
      :to="'/preview/' + currentBot.stagingBot"
      @click="handleBtnClick('preview')"
      data-cy="app-bar-preview-btn"
    >
      <span class="hidden-sm-and-down pl-2">Preview</span>
      <v-icon class="pl-0 mx-2">mdi-comment-processing-outline</v-icon>
    </v-btn>

    <DeploymentButton />

    <v-btn
      v-if="hasFacebookChannel"
      v-bind:href="facebookChannel"
      icon
      target="_blank"
    >
      <v-icon>mdi-facebook-messenger</v-icon>
    </v-btn>

    <BtnSquare
      v-if="false && !isNoBeamer"
      icon="info"
      colorBtn="white"
      colorIcon="white"
      class="notification-trigger mr-n2"
      @click="toggleMenuOpen()"
    />

    <template v-slot:extension v-if="notification">
      <div class="white flex-grow-1 mx-n4 pt-2 px-2">
        <HubNotification />
      </div>
    </template>
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import BotService from '../services/bot';
import DeploymentButton from '@/components/DeploymentButton.vue';
import SearchComponent from '@/components/SearchComponent.vue';
import { TrackingService } from '@/services/tracking';
import HubNotification from '@/components/hub/HubNotification.vue';
import HubBotSelect from '@/components/hub/HubBotSelect.vue';
import LivechatHeaderBtn from '@/components/livechat/LivechatHeaderBtn.vue';
import BtnSquare from '@/components/common/BtnSquare.vue';
import OsUtil from '@/utils/os';

export default {
  name: 'Appbar',
  methods: {
    initializeBeamer() {
      if (!this.isNoBeamer) {
        let beamerScript = document.createElement('script');
        beamerScript.setAttribute('id', 'beamer_script');
        beamerScript.setAttribute(
          'src',
          'https://app.getbeamer.com/js/beamer-embed.js'
        );
        beamerScript.setAttribute('defer', 'defer');

        let user_id = this.isAdmin
          ? this.user.username
          : this.user.username.split('@')[0] + this.currentBotId;

        const elem = document.getElementById('beamer_script');
        if (elem) elem.parentNode.removeChild(elem);

        window.beamer_config = {
          product_id: 'BcbAFjeZ25353', //DO NOT CHANGE: This is your product code on Beamer
          selector:
            '.notification-trigger' /*Optional: Id, class (or list of both) of the HTML element to use as a button*/,
          //display : 'right', /*Optional: Choose how to display the Beamer panel in your site*/
          //top: 0, /*Optional: Top position offset for the notification bubble*/
          //right: 0, /*Optional: Right position offset for the notification bubble*/
          //bottom: 0, /*Optional: Bottom position offset for the notification bubble*/
          //left: 0, /*Optional: Left position offset for the notification bubble*/
          //button_position: 'bottom-right', /*Optional: Position for the notification button that shows up when the selector parameter is not set*/
          //icon: 'bell_lines', /*Optional: Alternative icon to display in the notification button*/
          language:
            this.$vuetify.lang
              .current /*Optional: Bring news in the language of choice*/,
          //filter: 'admin', /*Optional : Bring the news for a certain role as well as all the public news*/
          //lazy: false, /*Optional : true if you want to manually start the script by calling Beamer.init()*/
          //alert : true, /*Optional : false if you don't want to initialize the selector*/
          //delay : 0, /*Optional : Delay (in milliseconds) before initializing Beamer*/
          //embed : false, /*Optional : true if you want to embed and display the feed inside the element selected by the 'selector' parameter*/
          //mobile : true, /*Optional : false if you don't want to initialize Beamer on mobile devices*/
          //notification_prompt : 'sidebar', /*Optional : override the method selected to prompt users for permission to receive web push notifications*/
          //callback : test, /*Optional : Beamer will call this function, with the number of new features as a parameter, after the initialization*/
          //onclick : your_onclick_function(url, openInNewWindow), /*Optional : Beamer will call this function when a user clicks on a link in one of your posts*/
          //onopen : your_onopen_function, /*Optional : Beamer will call this function when opening the panel*/
          //onclose : your_onclose_function, /*Optional : Beamer will call this function when closing the panel*/
          //---------------Visitor Information---------------
          //user_firstname : "firstname", /*Optional : Input your user firstname for better statistics*/
          //user_lastname : "lastname", /*Optional : Input your user lastname for better statistics*/
          //user_email : "email", /*Optional : Input your user email for better statistics*/
          user_id /*Optional : Input your user ID for better statistics*/,
        };

        document.head.appendChild(beamerScript);
      }
    },
    openSearch() {
      this.searchMenu = true;
    },
    toggleMenuOpen() {
      // change navigation drawer state
      this.$store.commit('hubUi/setValue', {
        key: 'isMenuOpen',
        value: !this.isMenuOpen,
      });
    },
    setBot(bot) {
      this.$store.dispatch('bots/changeBot', {
        uniqueBotId: bot.uniqueBotId,
        router: this.$router,
      });
    },
    handleBtnClick(routerPath) {
      TrackingService.trackMenu({
        botId: this.currentBotId,
        type: 'click',
        source: 'Appbar',
        payload: {
          routerPath,
        },
      });
    },
  },
  components: {
    DeploymentButton,
    SearchComponent,
    HubNotification,
    HubBotSelect,
    LivechatHeaderBtn,
    BtnSquare,
  },
  data: () => ({
  }),
  mounted() {
    // this.initializeBeamer();
  },
  created() {
    if (this.isOnlyStaging) {
      this.$store.dispatch('bots/setViewStaging', true);
    } else {
      this.$store.dispatch('bots/setViewStaging', false);
    }
  },
  watch: {
    userLang() {
      // this.initializeBeamer();
    },
  },
  computed: {
    userLang() {
      return this.$vuetify.lang.current;
    },
    getOS() {
      return OsUtil.getOs();
    },
    ...mapGetters('bots', [
      'currentBot',
      'bots',
      'currentBotId',
    ]),
    ...mapGetters('auth', [
      'isAdmin',
      'isOnlyStaging',
      'isNoBeamer',
      'user',
      'isMultiBot',
    ]),
    ...mapGetters('notifications', ['notification']),
    ...mapGetters('hubUi', ['isMenuOpen']),
    facebookChannel() {
      if (!this.currentBot) {
        return false;
      }

      return BotService.linkToChannel(this.currentBot, 'facebook');
    },
    webchatChannel() {
      if (!this.currentBot) {
        return false;
      }

      return BotService.linkToChannel(this.currentBot, 'widget');
    },
    hasFacebookChannel() {
      if (!this.currentBot) {
        return false;
      }

      return BotService.hasActiveChannel(this.currentBot, 'facebook');
    },
    hasWebchatChannel() {
      if (!this.currentBot) {
        return false;
      }

      return BotService.hasActiveChannel(this.currentBot, 'widget');
    },
  },
};
</script>
