var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isHistory
    ? _c("v-hover", {
        attrs: { "open-delay": "300", "close-delay": "5000" },
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function ({ hover }) {
                return [
                  _c(
                    "v-toolbar",
                    {
                      attrs: { flat: "", dense: "", extended: _vm.isExtended },
                      scopedSlots: _vm._u(
                        [
                          (_vm.isIntent || _vm.forward) && _vm.isSimpleEdit
                            ? {
                                key: "extension",
                                fn: function () {
                                  return [
                                    _c(
                                      "v-fade-transition",
                                      [
                                        hover
                                          ? _c(
                                              "v-btn",
                                              {
                                                staticClass: "mt-n6",
                                                class: {
                                                  "ml-10 ": _vm.isSuggest,
                                                  "ml-2": !_vm.isSuggest,
                                                },
                                                attrs: {
                                                  to: "/intent/" + _vm.intent,
                                                  color: "primary lighten-2",
                                                  small: "",
                                                  tile: "",
                                                  outlined: "",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "chats.history.intent.edit"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      right: "",
                                                      small: "",
                                                    },
                                                  },
                                                  [_vm._v("edit")]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-fade-transition",
                                      [
                                        hover && _vm.isSuggest && _vm.isAdmin
                                          ? _c(
                                              "v-btn",
                                              {
                                                staticClass: "mt-n6 ml-4",
                                                attrs: {
                                                  disabled: "",
                                                  color: "primary lighten-2",
                                                  small: "",
                                                  tile: "",
                                                  outlined: "",
                                                },
                                              },
                                              [
                                                _vm._v(" Vorschlag annehmen "),
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      right: "",
                                                      small: "",
                                                    },
                                                  },
                                                  [_vm._v("auto_awesome")]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-fade-transition",
                                      [
                                        hover && _vm.isAdmin
                                          ? _c(
                                              "v-btn",
                                              {
                                                staticClass: "ml-4 mt-n6",
                                                attrs: {
                                                  disabled: "",
                                                  color: "primary lighten-2",
                                                  small: "",
                                                  tile: "",
                                                  outlined: "",
                                                },
                                              },
                                              [
                                                _vm._v(" Merken "),
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      right: "",
                                                      small: "",
                                                    },
                                                  },
                                                  [_vm._v("star")]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              }
                            : null,
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _vm.isSuggest
                                      ? _c(
                                          "v-icon",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "mt-1",
                                                attrs: {
                                                  color: "secondary darken-2",
                                                  left: "",
                                                },
                                              },
                                              "v-icon",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [_vm._v(" auto_awesome ")]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "chats.conversation.suggestions.dreaming",
                                    {
                                      name: _vm.displayName(
                                        _vm.suggest,
                                        _vm.$vuetify.lang.current
                                      ),
                                    }
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      ),
                      _c("v-divider", { staticClass: "ml-2 mr-2" }),
                      _vm.isSuggest && _vm.forward
                        ? _c(
                            "span",
                            { staticClass: "ml-2" },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      color:
                                                        "primary lighten-3",
                                                      left: "",
                                                      "x-small": "",
                                                    },
                                                  },
                                                  "v-icon",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [_vm._v(" directions ")]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "chats.conversation.suggestions.forward"
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "strong",
                                {
                                  staticClass:
                                    "text-subtitle-2 primary--text text--lighten-2",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.displayName(
                                          _vm.forward,
                                          _vm.$vuetify.lang.current
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm.isIntent
                        ? _c(
                            "span",
                            { staticClass: "ml-2" },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      color:
                                                        "primary lighten-3",
                                                      left: "",
                                                      "x-small": "",
                                                    },
                                                  },
                                                  "v-icon",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [_vm._v(" mdi-folder-text ")]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "chats.conversation.intent.label"
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "strong",
                                {
                                  staticClass:
                                    "text-subtitle-2 primary--text text--lighten-2",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.displayName(
                                          _vm.intent,
                                          _vm.$vuetify.lang.current
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm.isUnknown
                        ? _c("span", [
                            _c(
                              "strong",
                              {
                                staticClass:
                                  "text-subtitle-2 primary--text text--lighten-2",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("chats.conversation.unknown.label")
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ])
                        : _vm.isTakeover
                        ? _c("span", [
                            _c(
                              "strong",
                              {
                                staticClass:
                                  "text-subtitle-2 primary--text text--lighten-2",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "chats.conversation.takeover.label"
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _c("v-divider", { staticClass: "ml-2 mr-2" }),
                      _vm.isUnknown
                        ? _c(
                            "span",
                            { staticClass: "primary--text text--lighten-2" },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "mt-1",
                                                    attrs: {
                                                      color: "grey lighten-1",
                                                      left: "",
                                                    },
                                                  },
                                                  "v-icon",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [_vm._v(" warning ")]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "chats.conversation.unknown.label"
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm.isGoal
                        ? _c(
                            "span",
                            { staticClass: "primary--text text--lighten-2" },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "mt-1",
                                                    attrs: {
                                                      color: "primary",
                                                      left: "",
                                                    },
                                                  },
                                                  "v-icon",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [_vm._v(" flag ")]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("chats.conversation.goal.label")
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm.isRatingPositive
                        ? _c(
                            "span",
                            { staticClass: "primary--text text--lighten-2" },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "mt-1",
                                                    attrs: {
                                                      color: "success",
                                                      left: "",
                                                    },
                                                  },
                                                  "v-icon",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [_vm._v(" thumb_up ")]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "chats.conversation.rating.positive.label"
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm.isRatingNegative
                        ? _c(
                            "span",
                            { staticClass: "primary--text text--lighten-2" },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "mt-1",
                                                    attrs: {
                                                      color: "error",
                                                      left: "",
                                                    },
                                                  },
                                                  "v-icon",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [_vm._v(" thumb_down ")]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "chats.conversation.rating.negative.label"
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm.isTakeover
                        ? _c(
                            "span",
                            { staticClass: "primary--text text--lighten-2" },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "mt-1",
                                                    attrs: {
                                                      color: "primary",
                                                      left: "",
                                                    },
                                                  },
                                                  "v-icon",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [_vm._v(" emoji_people ")]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "chats.conversation.takeover.label"
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          )
                        : _c(
                            "span",
                            { staticClass: "primary--text text--lighten-2" },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "mt-1",
                                                    attrs: {
                                                      color: "grey lighten-2",
                                                      left: "",
                                                    },
                                                  },
                                                  "v-icon",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [_vm._v(" change_history ")]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("chats.conversation.noreaction")
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                ]
              },
            },
          ],
          null,
          false,
          246636718
        ),
      })
    : _vm.isLabel
    ? _c(
        "v-tooltip",
        {
          attrs: { bottom: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _vm.isRatingPositive
                    ? _c(
                        "v-chip",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "mr-1",
                              staticStyle: { "max-height": "8px" },
                              attrs: {
                                label: "",
                                color: "success",
                                "x-small": "",
                              },
                            },
                            "v-chip",
                            attrs,
                            false
                          ),
                          on
                        )
                      )
                    : _vm.isRatingNegative
                    ? _c(
                        "v-chip",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "mr-1",
                              staticStyle: { "max-height": "8px" },
                              attrs: {
                                label: "",
                                color: "error",
                                "x-small": "",
                              },
                            },
                            "v-chip",
                            attrs,
                            false
                          ),
                          on
                        )
                      )
                    : _vm.isGoal
                    ? _c(
                        "v-chip",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "mr-1",
                              staticStyle: { "max-height": "8px" },
                              attrs: {
                                label: "",
                                color: "primary lighten-2",
                                "x-small": "",
                              },
                            },
                            "v-chip",
                            attrs,
                            false
                          ),
                          on
                        )
                      )
                    : _vm.isSuggest
                    ? _c(
                        "v-icon",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "mr-1",
                              attrs: { small: "", color: "secondary darken-2" },
                            },
                            "v-icon",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [_vm._v(" auto_awesome ")]
                      )
                    : _vm.isIntent || _vm.isUnknown
                    ? _c(
                        "v-chip",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "mr-1",
                              staticStyle: { "max-height": "8px" },
                              attrs: {
                                outlined: _vm.isUnknown,
                                color: "grey lighten-1",
                                "x-small": "",
                                label: "",
                              },
                            },
                            "v-chip",
                            attrs,
                            false
                          ),
                          on
                        )
                      )
                    : _vm.isTakeover
                    ? _c(
                        "v-chip",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "mr-1",
                              staticStyle: { "max-height": "8px" },
                              attrs: {
                                "x-small": "",
                                color: "primary lighten-2",
                                label: "",
                              },
                            },
                            "v-chip",
                            attrs,
                            false
                          ),
                          on
                        )
                      )
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        [
          _vm.isIntent
            ? _c("span", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.displayName(_vm.intent, _vm.$vuetify.lang.current)
                    ) +
                    " "
                ),
              ])
            : _vm.isSuggest
            ? _c("span", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.displayName(_vm.suggest, _vm.$vuetify.lang.current)
                    ) +
                    " "
                ),
              ])
            : _vm.isUnknown
            ? _c("span", [
                _vm._v(
                  " " + _vm._s(_vm.$t("chats.conversation.unknown.label")) + " "
                ),
              ])
            : _vm.isTakeover
            ? _c("span", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("chats.conversation.takeover.label")) +
                    " "
                ),
              ])
            : _vm._e(),
        ]
      )
    : _vm.isListItem
    ? _c(
        "v-card",
        {
          staticClass: "pt-0 pb-0 pl-6 pr-2 selectborder",
          attrs: { tile: "", flat: "" },
        },
        [
          _c("v-card-text", [
            _vm.isRatingPositive
              ? _c(
                  "span",
                  [
                    _c(
                      "v-icon",
                      { attrs: { small: "", left: "", color: "success" } },
                      [_vm._v("thumb_up")]
                    ),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.displayName(_vm.intent, _vm.$vuetify.lang.current)
                        ) +
                        " "
                    ),
                  ],
                  1
                )
              : _vm.isRatingNegative
              ? _c(
                  "span",
                  [
                    _c(
                      "v-icon",
                      { attrs: { small: "", left: "", color: "error" } },
                      [_vm._v("thumb_down")]
                    ),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.displayName(_vm.intent, _vm.$vuetify.lang.current)
                        ) +
                        " "
                    ),
                  ],
                  1
                )
              : _vm.isGoal
              ? _c(
                  "span",
                  [
                    _c(
                      "v-icon",
                      {
                        attrs: {
                          left: "",
                          small: "",
                          color: "primary lighten-2",
                        },
                      },
                      [_vm._v("flag")]
                    ),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.displayName(_vm.intent, _vm.$vuetify.lang.current)
                        ) +
                        " "
                    ),
                  ],
                  1
                )
              : _vm.isSuggest
              ? _c(
                  "span",
                  [
                    _c(
                      "v-icon",
                      {
                        attrs: {
                          left: "",
                          small: "",
                          color: "secondary darken-2",
                        },
                      },
                      [_vm._v("auto_awesome")]
                    ),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.displayName(
                            _vm.suggest,
                            _vm.$vuetify.lang.current
                          )
                        ) +
                        " "
                    ),
                    _vm.isStandardIntent(_vm.suggest)
                      ? _c("span", { staticClass: "ml-2" }, [
                          _vm._v(
                            " (" +
                              _vm._s(
                                _vm.$t("chats.conversation.common.fromTemplate")
                              ) +
                              ") "
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                )
              : _vm.isIntent
              ? _c(
                  "span",
                  [
                    _c(
                      "v-icon",
                      {
                        attrs: { left: "", small: "", color: "grey lighten-2" },
                      },
                      [_vm._v("mdi-folder-text")]
                    ),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.displayName(_vm.intent, _vm.$vuetify.lang.current)
                        ) +
                        " "
                    ),
                  ],
                  1
                )
              : _vm.isUnknown
              ? _c(
                  "span",
                  [
                    _c(
                      "v-icon",
                      {
                        attrs: { small: "", left: "", color: "grey lighten-2" },
                      },
                      [_vm._v("warning")]
                    ),
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("chats.conversation.unknown.label")) +
                        " "
                    ),
                  ],
                  1
                )
              : _vm.isTakeover
              ? _c(
                  "span",
                  [
                    _c(
                      "v-icon",
                      {
                        attrs: {
                          small: "",
                          left: "",
                          color: "primary lighten-2",
                        },
                      },
                      [_vm._v("emoji_people")]
                    ),
                    _vm._v(" Takeover "),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }