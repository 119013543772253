<script>
import LivechatSpecialViewWrapper from '@/components/livechat/special-views/LivechatSpecialViewWrapper.vue';
import LivechatStateCallout from '@/components/livechat/special-views/LivechatStateCallout.vue';

export default {
  name: 'LivechatNotAnAgent',
  components: { LivechatStateCallout, LivechatSpecialViewWrapper },
  props: {
    "canManageAgents": {
      type: Boolean,
      default: false,
    }
  },
};
</script>

<template>
  <LivechatSpecialViewWrapper id="not-an-agent">
    <!-- Header content: Decoration, "Not an agent" -->
    <template>
      <LivechatStateCallout
        class="mb-4"
        classes="text--lighten-2 primary--text"
        :title="$t('livechat.screens.notAnAgent.notAnAgent')"
        icon="mdi-face-agent"
        :description="$t('livechat.screens.notAnAgent.notAnAgentDescription')"
      />
    </template>

    <!-- Secondary container: Information and CTA -->
    <template #secondary>
      <!-- No access to manage agent assignment -->
      <template v-if="!canManageAgents">
        <h3 class="tet-h3 mb-2">{{ $t('livechat.screens.notAnAgent.notAdmin.title') }}</h3>
        <p class="text-body-1 mb-8">
          {{ $t('livechat.screens.notAnAgent.notAdmin.description') }}
        </p>
      </template>
      <!-- Can manage agent assignments -->
      <template v-else>
        <h3 class="tet-h3 mb-2">{{ $t('livechat.screens.notAnAgent.admin.title') }}</h3>
        <p class="text-body-1 mb-8">
          {{ $t('livechat.screens.notAnAgent.admin.description') }}
        </p>
      </template>

      <!-- ... with button to goto management page -->
      <div v-if="canManageAgents" class="d-flex mt-3 justify-end">
        <v-btn color="secondary" :to="{ name: 'settings-livechat' }" depressed>
          {{ $t('livechat.manageAgents') }}
          <v-icon class="ml-2">settings</v-icon>
        </v-btn>
      </div>
    </template>
  </LivechatSpecialViewWrapper>
</template>