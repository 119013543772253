const { LivechatProvidersEnum } = require("./livechatProvider.enum");
const { rolesTypes } = require('@/utils/userRoles');

/**
 * @typedef {string} IntegrationEnum
 * @enum {IntegrationEnum}
 */
const IntegrationEnum = {
  crisp: LivechatProvidersEnum.crisp,
  freshchat: LivechatProvidersEnum.freshchat,
  greyhound: LivechatProvidersEnum.greyhound,
  hubspot: 'hubspot',
  livechat: LivechatProvidersEnum.livechat,
  moinLivechat: LivechatProvidersEnum.moinLivechat,
  salesforce: LivechatProvidersEnum.salesforce,
  snapengage: LivechatProvidersEnum.snapengage,
  userLike: LivechatProvidersEnum.userLike,
  webhook: 'webhook_push',
  zendeskLivechat: 'zChat',
  zendeskTicket: 'zendesk',
};

/**
 * Admin has all of these by default.
 * The ones everyone has access to are not in here.
 */
const perTypeAccess = {
  [IntegrationEnum.crisp]: [],
  [IntegrationEnum.freshchat]: [],
  [IntegrationEnum.greyhound]: [],
  [IntegrationEnum.hubspot]: [ rolesTypes.OWNER ],
  [IntegrationEnum.livechat]: [],
  [IntegrationEnum.moinLivechat]: [],
  [IntegrationEnum.salesforce]: [],
  [IntegrationEnum.snapengage]: [],
  [IntegrationEnum.userLike]: [],
  [IntegrationEnum.webhook]: [ rolesTypes.OWNER ],
  [IntegrationEnum.zendeskLivechat]: [],
  [IntegrationEnum.zendeskTicket]: [],
}

/**
 * Checks if a user has access to the named integration
 * @param {string} integrationName
 * @param {{admin: boolean, owner: boolean}} accessMap The access the current user has
 * @returns {boolean}
 */
const canAccessIntegration = (integrationName, accessMap) => {
  if (accessMap.admin) return true;

  for (const role in accessMap) {
    if (!accessMap[role]) continue; // User does not have this role.
    if (perTypeAccess[integrationName]?.includes(role)) return true;
  }

  return false;
};

module.exports = { IntegrationEnum, canAccessIntegration };
