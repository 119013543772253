var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-select", {
        staticClass: "moin-cursor-pointer full-width",
        attrs: {
          items: _vm.bots,
          "item-text": "name",
          value: _vm.currentBot,
          "return-object": "",
          dense: "",
          solo: "",
          "hide-details": "",
          "prepend-inner-icon": "mdi-robot-outline",
        },
        on: { change: _vm.changeBot },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }