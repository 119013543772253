var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "LivechatSpecialViewWrapper",
    {
      attrs: { id: "agent-offline" },
      scopedSlots: _vm._u([
        {
          key: "secondary",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "d-flex gap-4 justify-space-between" },
                [
                  _c("h3", { staticClass: "tet-h3 mb-2" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("livechat.screens.agentOffline.otherAgentsTitle")
                      )
                    ),
                  ]),
                  _vm.canManageAgents
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            small: "",
                            outlined: "",
                            color: "primary",
                            to: { name: "settings-livechat" },
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("livechat.manageAgents")) + " "
                          ),
                          _c("v-icon", { attrs: { right: "" } }, [
                            _vm._v("settings"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.agentsOnline.length
                ? _c("p", { staticClass: "text-body-1 mb-8" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$tc(
                            "livechat.screens.agentOffline.otherAgentsDescription",
                            _vm.agentsOnline.length,
                            { count: _vm.agentsOnline.length }
                          )
                        ) +
                        " "
                    ),
                  ])
                : _vm._e(),
              _vm.agentsOnline.length
                ? _c(
                    "div",
                    { attrs: { id: "agents-online" } },
                    _vm._l(_vm.agentsOnline, function (agent) {
                      return _c(
                        "div",
                        { staticClass: "d-flex gap-2 items-center mb-2" },
                        [
                          _c("ProfileAvatar", {
                            attrs: {
                              small: "",
                              profilePicture: agent.profilePicture,
                            },
                          }),
                          _c("span", { staticClass: "ml-2" }, [
                            _vm._v(_vm._s(agent.name)),
                          ]),
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _c("LivechatStateCallout", {
                    staticClass: "py-8",
                    attrs: {
                      classes: "grey--text text--lighten-1",
                      title: _vm.$t(
                        "livechat.screens.agentOffline.noAgentsOnline"
                      ),
                      icon: "mdi-face-agent",
                      description: _vm.$t(
                        "livechat.screens.agentOffline.noAgentsOnlineDescription"
                      ),
                    },
                  }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      [
        _c("h2", { staticClass: "text-h2 primary--text text--lighten-2" }, [
          _vm._v(_vm._s(_vm.$t("livechat.screens.agentOffline.title"))),
        ]),
        _c("p", { staticClass: "mb-0 primary--text text--lighten-2" }, [
          _vm._v(_vm._s(_vm.$t("livechat.screens.agentOffline.description"))),
        ]),
        _c(
          "v-btn",
          {
            staticClass: "mt-3",
            attrs: { color: "secondary", depressed: "" },
            on: {
              click: function ($event) {
                return _vm.$emit("goOnline")
              },
            },
          },
          [
            _vm._v(
              " " +
                _vm._s(_vm.$t("livechat.screens.agentOffline.ctaLabel")) +
                " "
            ),
            _c("v-icon", { staticClass: "ml-2" }, [_vm._v("mdi-face-agent")]),
          ],
          1
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }