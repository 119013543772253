var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-tooltip",
    {
      attrs: { bottom: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on: onTooltip, attrs: attrsTooltip }) {
            return [
              _c(
                "div",
                _vm._g(
                  _vm._b(
                    { staticClass: "d-flex justify-center" },
                    "div",
                    attrsTooltip,
                    false
                  ),
                  onTooltip
                ),
                [
                  _vm.newValue < _vm.oldValue
                    ? _c(
                        "v-icon",
                        { attrs: { size: "20", color: _vm.colorUp } },
                        [_vm._v(" arrow_downward ")]
                      )
                    : _vm._e(),
                  _vm.newValue === _vm.oldValue
                    ? _c(
                        "v-icon",
                        { attrs: { size: "20", color: "primary lighten-2" } },
                        [_vm._v(" arrow_forward ")]
                      )
                    : _vm._e(),
                  _vm.newValue > _vm.oldValue
                    ? _c(
                        "v-icon",
                        { attrs: { size: "20", color: _vm.colorDown } },
                        [_vm._v(" arrow_upward ")]
                      )
                    : _vm._e(),
                  _c("span", { staticClass: "primary--text" }, [
                    _vm._v(" " + _vm._s(_vm.changedPercent) + " "),
                  ]),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [_c("span", [_vm._v(_vm._s(_vm.changedToolTip))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }