var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.intent.suggested
    ? _c(
        "HubBox",
        {
          attrs: {
            title: "Status",
            icon:
              _vm.intent.status === _vm.statusTypes.IN_REVIEW
                ? _vm.statusIcons.inReview.icon
                : "mdi-play-circle",
            boxId: "'intents-status'",
          },
          on: {
            onExpand: (isExpanded) =>
              isExpanded && _vm.trackIt("hub-box-expanded"),
          },
        },
        [
          _c("div", { staticClass: "text-body-2" }, [
            _vm.currentChannels.length > 1
              ? _c("span", [
                  _vm._v(
                    " " + _vm._s(_vm.$t("intentStatus.infoTextChannels")) + " "
                  ),
                ])
              : _c("span", [
                  _vm._v(
                    " " + _vm._s(_vm.$t("intentStatus.infoTextChannel")) + " "
                  ),
                ]),
          ]),
          _vm.intent.status === _vm.statusTypes.IN_REVIEW
            ? _c("div", { staticClass: "my-8" }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.$t(`intentStatus.inReview.label2`))),
                ]),
              ])
            : _c(
                "div",
                { staticClass: "my-8" },
                [
                  _vm.statusList.length > 1
                    ? _c("div", { staticClass: "text-body-1 mb-2" }, [
                        _vm._v(" " + _vm._s(_vm.$t("common.channels")) + " "),
                      ])
                    : _c("div", { staticClass: "text-body-1 mb-2" }, [
                        _vm._v("Status"),
                      ]),
                  _vm.statusList.length === 1
                    ? _c(
                        "div",
                        { staticClass: "d-flex mb-4" },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "mr-2",
                              attrs: {
                                color:
                                  _vm.statusIcons[_vm.statusList[0].status]
                                    .iconColor,
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.statusIcons[_vm.statusList[0].status]
                                      .icon
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  `intentStatus.${_vm.statusList[0].status}.label2`
                                )
                              )
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._l(_vm.statusList, function (item, index) {
                        return _c("div", { key: index }, [
                          _c(
                            "div",
                            { staticClass: "d-flex mb-4" },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: {
                                    bottom: "",
                                    color: "primary lighten-3",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({
                                          on: onTooltip,
                                          attrs: attrsTooltip,
                                        }) {
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "mr-2",
                                                    attrs: {
                                                      color:
                                                        _vm.statusIcons[
                                                          item.status
                                                        ].iconColor,
                                                    },
                                                  },
                                                  "v-icon",
                                                  attrsTooltip,
                                                  false
                                                ),
                                                onTooltip
                                              ),
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.statusIcons[
                                                        item.status
                                                      ].icon
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          `intentStatus.${item.status}.label2`
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                              _c("div", { staticClass: "text-body-2 mb-1" }, [
                                _vm._v(_vm._s(item.displayName)),
                              ]),
                            ],
                            1
                          ),
                        ])
                      }),
                ],
                2
              ),
          _vm.isEditBtnShown
            ? _c(
                "div",
                { staticClass: "mt-6" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        outlined: "",
                        color: "primary lighten-3",
                        "data-cy": "intent-status-edit-btn",
                      },
                      on: { click: _vm.goToEditSelected },
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.$t("common.edit")) + " "),
                      _c("v-icon", { attrs: { right: "" } }, [_vm._v("edit")]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }