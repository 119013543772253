var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("InfoBox", {
        staticClass: "mb-4",
        attrs: { text: _vm.$t("intentNew.stepName.infoNameScenario") },
      }),
      _c(
        "v-row",
        { staticClass: "mt-4" },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("div"),
              _c("v-text-field", {
                staticClass: "mb-2",
                attrs: {
                  outlined: "",
                  color: "secondary darken-2",
                  autofocus: "",
                  counter: "",
                  maxlength: "50",
                },
                on: { input: _vm.validateInputs },
                model: {
                  value: _vm.displayName,
                  callback: function ($$v) {
                    _vm.displayName = $$v
                  },
                  expression: "displayName",
                },
              }),
              _c(
                "div",
                { staticClass: "mt-n8 pr-6" },
                [
                  _c("InfoBox", {
                    attrs: {
                      text: _vm.$t("intentNew.stepName.displayName.hint"),
                      icon: null,
                    },
                  }),
                ],
                1
              ),
              _vm.displayNameDuplicate
                ? _c(
                    "div",
                    [
                      _c(
                        "v-alert",
                        {
                          staticClass: "mt-2",
                          attrs: {
                            outlined: "",
                            color: "red darken-2",
                            dense: "",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "text-body-2 error--text" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "intentNew.stepName.displayName.error"
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      false
        ? _c(
            "v-row",
            { staticClass: "mt-4" },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-text-area", {
                    staticClass: "mb-2",
                    attrs: {
                      outlined: "",
                      color: "secondary darken-2",
                      "hide-details": "",
                    },
                    on: { input: _vm.validateInputs },
                    model: {
                      value: _vm.description,
                      callback: function ($$v) {
                        _vm.description = $$v
                      },
                      expression: "description",
                    },
                  }),
                  _c("InfoBox", {
                    attrs: {
                      text: _vm.$t("intentNew.stepName.description.hint"),
                      icon: null,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        { staticClass: "mt-4" },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("v-textarea", {
                staticClass: "mb-2",
                attrs: {
                  outlined: "",
                  color: "secondary darken-2",
                  "hide-details": "",
                },
                on: { input: _vm.validateInputs },
                model: {
                  value: _vm.scenario,
                  callback: function ($$v) {
                    _vm.scenario = $$v
                  },
                  expression: "scenario",
                },
              }),
              _c("InfoBox", {
                attrs: {
                  text: _vm.$t("intentNew.stepName.scenario.hint"),
                  icon: null,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }