var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isOpenFadeA
    ? _c(
        "v-col",
        {
          staticClass: "pa-0 fill-height white",
          attrs: { cols: "12", md: _vm.width },
        },
        [
          _c(
            "div",
            {
              staticClass: "d-flex flex-column",
              staticStyle: { "max-height": "100%" },
            },
            [
              _c("div", [
                _c(
                  "div",
                  {
                    staticClass: "d-flex pa-1",
                    class: _vm.isProminent
                      ? "secondary darken-2"
                      : "grey lighten-2",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "grow d-flex align-center text-center justify-center",
                      },
                      [
                        _vm._t("title", function () {
                          return [
                            _c(
                              "div",
                              { staticClass: "d-flex" },
                              [
                                _vm.icon
                                  ? _c(
                                      "v-icon",
                                      {
                                        attrs: {
                                          color: _vm.isProminent
                                            ? "white"
                                            : "primary lighten-3",
                                        },
                                      },
                                      [_vm._v(" " + _vm._s(_vm.icon) + " ")]
                                    )
                                  : _vm._e(),
                                _vm.title
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "ml-2",
                                        class: _vm.isProminent
                                          ? "white--text"
                                          : "primary--text text--lighten-3",
                                      },
                                      [_vm._v(" " + _vm._s(_vm.title) + " ")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        }),
                      ],
                      2
                    ),
                    _c("BtnSquare", {
                      attrs: {
                        icon: "close",
                        colorIcon: _vm.isProminent
                          ? "white"
                          : "primary lighten-3",
                      },
                      on: { click: _vm.onClose },
                    }),
                  ],
                  1
                ),
                _c("div", {
                  class: _vm.isProminent
                    ? "secondary darken-3"
                    : "primary lighten-3",
                  staticStyle: { height: "3px" },
                }),
              ]),
              _c(
                "div",
                {
                  staticClass: "white mx-0 fill-height",
                  staticStyle: { overflow: "hidden", "overflow-y": "auto" },
                },
                [
                  _c("v-fade-transition", [
                    _vm.isOpenFadeB
                      ? _c("div", [_vm._t("default")], 2)
                      : _vm._e(),
                  ]),
                ],
                1
              ),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }