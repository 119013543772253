var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    [
      _c("v-col", { attrs: { cols: "12", md: "6" } }, [
        _c(
          "div",
          {
            staticClass: "pa-4",
            class: { "text-center": _vm.$vuetify.breakpoint.mdAndUp },
          },
          [
            _c(
              "div",
              { staticClass: "text-body-2 font-weight-bold" },
              [
                _vm.widgetActive
                  ? _c(
                      "v-icon",
                      {
                        staticClass: "green--text mr-1",
                        attrs: { size: "32" },
                      },
                      [_vm._v(" visibility ")]
                    )
                  : _c(
                      "v-icon",
                      {
                        staticClass: "error--text mr-1",
                        attrs: { size: "32" },
                      },
                      [_vm._v(" visibility_off ")]
                    ),
                _c("div", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.widgetActive
                          ? _vm.$t("editor.widget.activation.on.title")
                          : _vm.$t("editor.widget.activation.off.title")
                      ) +
                      " "
                  ),
                ]),
              ],
              1
            ),
            _c(
              "span",
              { staticClass: "text-body-2 grey--text text--darken-2" },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.widgetActive
                        ? _vm.$t("editor.widget.activation.on.description")
                        : _vm.$t("editor.widget.activation.off.description")
                    ) +
                    " "
                ),
              ]
            ),
          ]
        ),
      ]),
      _c(
        "v-col",
        {
          class: [_vm.$vuetify.breakpoint.smAndDown && "mt-n4 pt-0"],
          attrs: { cols: "12", md: "6" },
        },
        [
          _c("v-card-title", [
            _vm._v(
              " " + _vm._s(_vm.$t("editor.widget.activation.title")) + " "
            ),
          ]),
          _c(
            "div",
            { staticClass: "px-4" },
            [
              _c("v-switch", {
                attrs: {
                  "input-value": _vm.widgetActive,
                  color: _vm.widgetActive ? "green" : "grey",
                  inset: "",
                  label: _vm.$t("editor.widget.activation.switchLabel"),
                },
                on: { change: (v) => _vm.$emit("onChange", v) },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }