var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { attrs: { cols: "3" } },
        [
          _c(
            "v-card",
            {
              staticClass: "pa-4 d-flex justify-center",
              attrs: { color: "#fafafa" },
            },
            [
              _c("ThemeSVG", {
                attrs: { width: "72", colors: this.theme[this.selected] },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "7" } },
        [
          _c(
            "v-card",
            { attrs: { color: "transparent", flat: "" } },
            [
              _c("v-card-title", { staticClass: "pt-0" }, [
                _vm._v(
                  _vm._s(_vm.$t("channelSettings.appearance.themetype.title"))
                ),
              ]),
              _c("v-card-subtitle", [
                _vm._v(
                  _vm._s(
                    _vm.$t("channelSettings.appearance.themetype.subtitle")
                  )
                ),
              ]),
              _c("v-card-text", [
                _vm._v(
                  _vm._s(
                    _vm.$t("channelSettings.appearance.themetype.explanantion")
                  )
                ),
              ]),
              _c(
                "v-card-actions",
                {
                  staticClass:
                    "px-16 d-flex flex-column justify-start align-start",
                },
                [
                  _c(
                    "v-radio-group",
                    {
                      attrs: { column: "" },
                      on: { change: _vm.selectedTheme },
                      model: {
                        value: _vm.selected,
                        callback: function ($$v) {
                          _vm.selected = $$v
                        },
                        expression: "selected",
                      },
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "d-flex justify-start align-center mb-4",
                        },
                        [
                          _c("ThemeSVG", {
                            staticClass: "mr-4",
                            attrs: {
                              colors: this.theme["regular"],
                              width: "20",
                            },
                          }),
                          _c("v-radio", {
                            attrs: {
                              label: _vm.$t(
                                `channelSettings.appearance.themetype.themename.regular`
                              ),
                              value: "regular",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }