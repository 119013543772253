var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("ActionList", {
        attrs: {
          actions: _vm.actions,
          schema: "*",
          quickreplies: _vm.ratingValue === null,
        },
        on: { onUpdate: _vm.update },
      }),
      _vm.isAdmin
        ? _c(
            "v-timeline-item",
            {
              attrs: { color: "white" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "icon",
                    fn: function () {
                      return [_c("v-icon", [_vm._v("star")])]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                4055989573
              ),
            },
            [
              _c("v-select", {
                attrs: {
                  items: _vm.ratingForms,
                  "item-text": "displayName",
                  "item-value": "value",
                  value: _vm.ratingValue,
                  disabled: _vm.ratingForms.length === 1,
                },
                on: { change: _vm.changeRating },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }