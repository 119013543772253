var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "moin-fade-in mt-10 d-flex justify-space-between" },
    [
      _c(
        "div",
        [
          _c(
            "v-btn",
            {
              staticClass: "mr-2",
              attrs: { outlined: "", disabled: _vm.disabled },
              on: {
                click: function ($event) {
                  return _vm.$emit("onBack")
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("common.back")) + " ")]
          ),
          !_vm.hideNextBtn
            ? _c(
                "v-btn",
                {
                  attrs: {
                    color: "secondary",
                    depressed: "",
                    disabled: _vm.disabled,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("onNext")
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("common.next")) + " ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }