var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-alert",
    {
      staticClass: "info-box-outline",
      class: [
        _vm.color === _vm.colorTypes.warning
          ? "warning--text"
          : "primary--text text--lighten-3",
        _vm.block ? "d-block" : "d-inline-block",
      ],
      attrs: {
        outlined: "",
        color:
          _vm.color === _vm.colorTypes.warning
            ? "warning"
            : "primary lighten-3",
      },
    },
    [
      _c("div", { staticClass: "d-flex" }, [
        _c(
          "div",
          { staticClass: "mr-2" },
          [
            _c(
              "v-icon",
              {
                staticClass: "mt-n1",
                attrs: { color: _vm.colorClasses, size: "20" },
              },
              [_vm._v(" " + _vm._s(_vm.icon) + " ")]
            ),
          ],
          1
        ),
        _c("div", [
          _vm.title
            ? _c("div", { staticClass: "text-body-2 font-weight-bold" }, [
                _vm._v(" " + _vm._s(_vm.title) + " "),
              ])
            : _vm._e(),
          _c("div", {
            staticClass: "text-body-2",
            staticStyle: { "word-break": "break-word" },
            domProps: { innerHTML: _vm._s(_vm.body) },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }