var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("IntentNewStepSummary"),
      _c(
        "v-row",
        {
          staticClass: "mt-4",
          attrs: { align: "center", justify: "space-between" },
        },
        [
          _c(
            "v-col",
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "primary lighten-2", text: "" },
                  on: { click: _vm.cancelCreation },
                },
                [
                  _c("v-icon", { attrs: { left: "", size: "24" } }, [
                    _vm._v("mdi-arrow-left"),
                  ]),
                  _vm._v(" " + _vm._s(_vm.$t("common.back")) + " "),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "text-right" },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "secondary darken-2", depressed: "" },
                  on: {
                    "~click": function ($event) {
                      return _vm.openCreateDialog.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("intentNew.newWithoutTemplate.addIntent")) +
                      " "
                  ),
                  _c("v-icon", { attrs: { right: "" } }, [
                    _vm._v("mdi-plus-circle"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "HubDialog",
        {
          attrs: {
            value: _vm.createDialogIsOpen,
            disabled: _vm.isLoading || !_vm.isQuotaOk,
            "primary-label": _vm.$t("common.save"),
            "primary-icon": "save",
            "secondary-label": _vm.$t("common.cancel"),
            "secondary-icon": "close",
            title: _vm.$t("intentNew.dialogNewWithTemplate.title", {
              displayName: _vm.displayName(
                _vm.newIntent.intent,
                _vm.$vuetify.lang.current
              ),
            }),
            modal: "",
            asyncDialog: "",
          },
          on: {
            onPrimary: _vm.createFromStandard,
            onSecondary: _vm.closeCreateDialog,
          },
        },
        [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("intentNew.dialogNewWithTemplate.body", {
                  displayName: _vm.displayName(
                    _vm.newIntent.intent,
                    _vm.$vuetify.lang.current
                  ),
                })
              ) +
              " "
          ),
          _vm.isAdmin
            ? _c(
                "div",
                { staticClass: "pt-6" },
                [
                  _vm.newIntent.template === "Smalltalks"
                    ? _c("InfoBox", {
                        attrs: {
                          text: _vm.$t("intentsSuggestion.quotaSmalltalks"),
                        },
                      })
                    : _vm.quota
                    ? _c("InfoBox", {
                        attrs: {
                          text: _vm.isQuotaOk
                            ? _vm.$t("intentsSuggestion.quotaInfoOk", {
                                available: _vm.availableQuota,
                                all: _vm.quota.intents,
                              })
                            : _vm.$t("intentsSuggestion.quotaInfoNotOk", {
                                all: _vm.quota.intents,
                              }),
                          icon: _vm.isQuotaOk
                            ? "mdi-comment-check"
                            : "mdi-comment-alert-outline",
                        },
                      })
                    : _c("InfoBox", {
                        attrs: {
                          text: "Es sind noch keine Quota eingestellt.",
                          icon: "mdi-comment-alert-outline",
                        },
                      }),
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "pt-6" },
                [
                  _vm.newIntent.template === "Smalltalks"
                    ? _c("InfoBox", {
                        attrs: {
                          text: _vm.$t("intentsSuggestion.quotaSmalltalks"),
                        },
                      })
                    : _vm.quota
                    ? _c("InfoBox", {
                        attrs: {
                          text: _vm.isQuotaOk
                            ? _vm.$t("intentsSuggestion.quotaInfoOk", {
                                available: _vm.availableQuota,
                                all: _vm.quota.intents,
                              })
                            : _vm.$t("intentsSuggestion.quotaInfoNotOk", {
                                all: _vm.quota.intents,
                              }),
                          icon: _vm.isQuotaOk
                            ? "mdi-comment-check"
                            : "mdi-comment-alert-outline",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }