// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/fonts/JosefinSans-VariableFont_wght.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("@/assets/fonts/Lato-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("@/assets/fonts/silka-semibold-webfont.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: \"Josefin Sans\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n@font-face {\n  font-family: \"Lato\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n@font-face {\n  font-family: \"silkaHeading\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n.moin-avatar[data-v-601a7c40] {\n  position: relative;\n  width: 146px;\n  height: 146px;\n}\n.moin-avatar img[data-v-601a7c40] {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  top: 0;\n  left: 0;\n}\n@-webkit-keyframes eyesThinking-601a7c40 {\n0% {\n    -webkit-transform: scaleX(1);\n            transform: scaleX(1);\n}\n50% {\n    -webkit-transform: scaleX(-1);\n            transform: scaleX(-1);\n}\n100% {\n    -webkit-transform: scaleX(1);\n            transform: scaleX(1);\n}\n}\n@keyframes eyesThinking-601a7c40 {\n0% {\n    -webkit-transform: scaleX(1);\n            transform: scaleX(1);\n}\n50% {\n    -webkit-transform: scaleX(-1);\n            transform: scaleX(-1);\n}\n100% {\n    -webkit-transform: scaleX(1);\n            transform: scaleX(1);\n}\n}\n.moin-avatar .moin-avatar-eyes-thinking[data-v-601a7c40] {\n  -webkit-transform: scaleX(-1);\n          transform: scaleX(-1);\n  -webkit-animation: eyesThinking-601a7c40 1.5s steps(1) infinite;\n          animation: eyesThinking-601a7c40 1.5s steps(1) infinite;\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
