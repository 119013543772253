var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "hub-loader", class: [_vm.fixed && "hub-loader--fixed"] },
    [
      _c("div", { staticStyle: { "margin-top": "-3%" } }, [
        _c(
          "div",
          { staticStyle: { width: "36px" } },
          [
            _c("v-img", {
              staticClass: "logo-bounce-in",
              attrs: { src: require("@/assets/moin-logo-mini.svg") },
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "mt-2",
            staticStyle: {
              overflow: "hidden",
              "border-radius": "3px",
              height: "2px",
              width: "36px",
            },
          },
          [
            _c("v-progress-linear", {
              staticClass: "moin-fresh",
              attrs: { indeterminate: "", color: "secondary", height: "2" },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }