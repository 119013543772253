var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { staticStyle: { "max-height": "100vh", height: "100vh" } },
    [
      _c("HubMenu"),
      _c("Appbar"),
      _c("HubEditorFrame", {
        attrs: {
          title: _vm.$t("botSettings.archive.title"),
          loading: _vm.isLoading,
          draft: _vm.isChanged || _vm.hasUndefinedSetting,
          saveIcon: "cloud_upload",
          back: "/settings",
          "sidebar-items": [{ title: "", icon: "info", name: "info" }],
        },
        on: { onReset: _vm.reset, onSave: _vm.save },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ isLoading }) {
              return [
                _c(
                  "v-container",
                  { staticClass: "white pa-6", attrs: { fluid: "" } },
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12", lg: "6", xl: "4" } },
                          [
                            _c("h2", { staticClass: "text-h6" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("botSettings.archive.active.title")
                                )
                              ),
                            ]),
                            _c("div", { staticClass: "text-body-2" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "botSettings.archive.active.description"
                                  )
                                )
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12", md: "6" } },
                          [
                            _c("v-switch", {
                              attrs: {
                                label: _vm.$t(
                                  "botSettings.archive.active.label"
                                ),
                                color: "green",
                                inset: "",
                                dense: "",
                                disabled: isLoading,
                              },
                              on: {
                                change: (v) => {
                                  _vm.handleChange(v, _vm.active)
                                  return (_vm.active = v)
                                },
                              },
                              model: {
                                value: _vm.active,
                                callback: function ($$v) {
                                  _vm.active = $$v
                                },
                                expression: "active",
                              },
                            }),
                            _c("InfoBox", {
                              staticClass: "my-4",
                              attrs: {
                                text: _vm.$t("botSettings.archive.active.info"),
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("v-divider"),
              ]
            },
          },
        ]),
        model: {
          value: _vm.isSidebarOpen,
          callback: function ($$v) {
            _vm.isSidebarOpen = $$v
          },
          expression: "isSidebarOpen",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }