// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/fonts/JosefinSans-VariableFont_wght.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("@/assets/fonts/Lato-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("@/assets/fonts/silka-semibold-webfont.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: \"Josefin Sans\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n@font-face {\n  font-family: \"Lato\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n@font-face {\n  font-family: \"silkaHeading\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n[data-v-2882937e] .v-application--wrap {\n  max-height: 100vh;\n}\n.demo-main[data-v-2882937e] {\n  height: 100%;\n  max-height: 100%;\n  position: relative;\n  overflow: hidden;\n}\n.demo[data-v-2882937e] {\n  background-color: white;\n  height: 100%;\n}\n.demo-sidebar[data-v-2882937e] {\n  width: 30vw;\n  background-color: var(--v-primary-base);\n  height: 100%;\n}\n.demo-content[data-v-2882937e] {\n  height: 100%;\n  max-height: 100%;\n  position: relative;\n  overflow: hidden;\n}\n.demo-slider[data-v-2882937e] {\n  overflow: hidden;\n  overflow-y: auto;\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n}\n.demo-ccw-logo[data-v-2882937e] {\n  display: inline-block;\n  overflow: hidden;\n}\n.demo-ccw-logo--xs[data-v-2882937e] {\n  height: 44px;\n  width: 44px;\n  -webkit-transform: scale(0.7);\n          transform: scale(0.7);\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
